import React from "react";
import classNames from "classnames";
import MainTravelStyle from "../../../../mainTravel.module.css";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import * as Yup from "yup";
import { Checkbox } from "@mui/material";
import { orange } from "@mui/material/colors";
import style from "./hotelInfo.module.css";
import CustomSelect from "shared/components/CustomSelect";
import LocationInput from "shared/components/LocationInput";
import { useFormik } from "formik";
import { hotelAddRoomHotelInfo } from "shared/utils";
import { setHotelB_B } from "shared/redux";
import { useDispatch } from "react-redux";
const Rating = ["2 Star", "3 Star", "4 Star", "5 Star"];
interface Props {
  handleClickNext: any;
}
export default function HotelInfo(props: Partial<Props>) {
  const dispatch = useDispatch();
  const { handleClickNext } = props;
  const StarRating = (selectedOption: string) => {
    formik.setFieldValue("startRating", selectedOption);
  };
  const formik = useFormik({
    initialValues: {
      propertyName: "",
      startRating: "",
      userName: "",
      contactNo: "",
      alternativeCellNo: "",
      postCode: "",
      propertyAddress: "",
    },
    validationSchema: Yup.object(hotelAddRoomHotelInfo),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    dispatch(setHotelB_B(formik.values));
    handleClickNext();
  };
  return (
    <div className={commonstyles.col5}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Your property  Name"
              id="propertyName"
              name="propertyName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.propertyName}
            />
            {formik.touched.propertyName && formik.errors.propertyName ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.propertyName}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <CustomSelect
              options={Rating}
              placeholder="Start Rating"
              onSelect={StarRating}
            />
            {formik.touched.startRating && formik.errors.startRating ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.startRating}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Name"
              id="userName"
              name="userName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.userName}
            />
            {formik.touched.userName && formik.errors.userName ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.userName}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <CustomInput
              placeholder="Contact No."
              id="contactNo"
              name="contactNo"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.contactNo}
            />
            {formik.touched.contactNo && formik.errors.contactNo ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.contactNo}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Alternative Cell No."
              id="alternativeCellNo"
              name="alternativeCellNo"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.alternativeCellNo}
            />
            {formik.touched.alternativeCellNo &&
            formik.errors.alternativeCellNo ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.alternativeCellNo}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <CustomInput
              placeholder="Post Code"
              id="postCode"
              name="postCode"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.postCode}
            />
            {formik.touched.postCode && formik.errors.postCode ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.postCode}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col12)}>
            <CustomInput
              placeholder="Property Address"
              id="propertyAddress"
              name="propertyAddress"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.propertyAddress}
            />
            {formik.touched.propertyAddress && formik.errors.propertyAddress ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.propertyAddress}
              </div>
            ) : null}
          </div>
        </div>

        {/* <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
        Do you own multiples hotels, or are you part of property management
        company or a group?
      </p>
      <div className={classNames(commonstyles.flx, style.mt32)}>
        <Checkbox
          sx={{
            color: orange[800],
            "&.Mui-checked": {
              color: orange[800],
            },
          }}
          className={style.checkbox}
        />
        <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
          Yes
        </p>
      </div>
      <div className={classNames(commonstyles.flx)}>
        <Checkbox
          sx={{
            color: orange[800],
            "&.Mui-checked": {
              color: orange[800],
            },
          }}
          className={style.checkbox}
        />
        <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
          No
        </p>
      </div> */}
        <div
          style={{ marginTop: "32px" }}
          className={classNames(MainTravelStyle.buttonWidth)}
        >
          <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
}
