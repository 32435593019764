import classNames from "classnames";
import React, { useState } from "react";
import style from "./HomeGuestbook.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import Checkbox from "@mui/material/Checkbox";
import { PrimaryButton } from "shared/components";
import { orange } from "@mui/material/colors";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { setHotelAddRoom } from "shared/redux";
function HomeGuestbook() {
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const navigate = useNavigate(); // Declare once

  const handleGoBack = () => {
    navigate("/hotel/property/listingProperty");
  };
  const handleGoEnd = () => {
    navigate("/hotel/property/mainProperty");
  };
  const handleGoToNext = () => {
    if (selectedOption === null) {
      setError("Required");
    } else {
      dispatch(setHotelAddRoom({ guestBook: selectedOption }));
      navigate("/hotel/property/homeBreadcrum");
    }
  };
  const handleCheckboxChange = (value: string) => {
    setSelectedOption(value);
  };

  return (
    <div className={classNames(commonstyles.colorBlue)}>
      <SearchBar />
      <div className={classNames(commonstyles.mr87)}>
        <div className={classNames(commonstyles.flx)}>
          <IoArrowBack className={style.back} onClick={handleGoEnd} />
          <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
            Back
          </p>
        </div>
        <div className={style.mt32}>
          <p className={classNames(commonstyles.fs24, commonstyles.semiBold)}>
            What can guest book?
          </p>
        </div>
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.col6,
            commonstyles.mt56
          )}
        >
          <Checkbox
            checked={selectedOption === "entire place"}
            onChange={() => handleCheckboxChange("entire place")}
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={style.checkbox}
          />
          <p className={classNames(commonstyles.fs16)}>Entire place</p>
        </div>
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.col6,
            style.mt16
          )}
        >
          <Checkbox
            checked={selectedOption === "a private room"}
            onChange={() => handleCheckboxChange("a private room")}
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={style.checkbox}
          />
          <p className={classNames(commonstyles.fs16)}>A private room</p>
        </div>
        {error && (
          <div className={classNames(commonStyles.error)}>*{error}</div>
        )}
        <div className={style.btnwidth}>
          <PrimaryButton
            children={"Next"}
            colorType={"blue"}
            onClick={handleGoToNext}
          />
        </div>
      </div>
    </div>
  );
}

export default HomeGuestbook;
