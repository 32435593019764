import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import commonstyle from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./Paramedicappointment.module.css";
import {
  IoArrowBack,
  IoChatboxEllipses,
  IoClose,
  IoVideocam,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaRegClock } from "react-icons/fa";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GoDeviceCameraVideo } from "react-icons/go";
import { HiOutlineChatAlt } from "react-icons/hi";
import { LuClipboardEdit } from "react-icons/lu";
import { LuCalendarDays } from "react-icons/lu";
import Refer from "assets/images/doctorRefer 1.png";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LuClipboardList } from "react-icons/lu";
import { RiShareForwardLine } from "react-icons/ri";
import { FaStethoscope } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import commonStyles from "shared/utils/common.module.css";
import Typography from "@mui/material/Typography";
import {
  CustomInput,
  CustomModal,
  LoadingModal,
  PrimaryButton,
} from "shared/components";
import CustomSelect from "shared/components/CustomSelect";
import {
  PeraAddAppointmentHistory,
  PeraAddAppointmentPrescription,
  PeraGetSingleAppointment,
  PeraReferPatientToDoctor,
  PeragetDoctorSearch,
} from "shared/services/Paramedic";
import {
  doctorAppointmentAddTestSchema,
  doctorAppointmentAddmedicineSchema,
  doctorAppointmentHistory,
} from "shared/utils";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  setPeraAppointmentWithFullData,
  setPeramedicAppointmentFlag,
} from "shared/redux";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import CustomSelectResetValue from "shared/components/CustomSelect/indextwo";
import DoctorReferModel from "shared/components/DoctorEmpty/DoctorReferModel";
import { Avatar, Checkbox } from "@mui/material";

export default function PrescriptionParamedic() {
  const [showPrescriptionModel, setShowPrescriptionModel] = useState(false);
  const { peramedicAppointments } = useSelector(
    (state: any) => state.root.paramedic
  );
  console.log("::::::::::::::::;", peramedicAppointments);

  const initialSelectedOption = null;
  const [selectedMedicineBrand, setSelectedMedicineBrand] = useState<
    string | null
  >(null);
  const [selectedMedicineStrength, setSelectedMedicineStrength] = useState<
    string | null
  >(null);
  // console.log("::::::::::::::::::::", selectedMedicineBrand);
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doctorid, setDoctorid] = useState<string | undefined>();
  const [pateintid, setPateintid] = useState<string | undefined>();
  const [medicinedata, setMedicineData] = useState<Medicine[]>([]);
  const [testdata, setTestData] = useState<Test[]>([]);

  const { id, index } = useParams();
  console.log("index", index);
  const indexNumber = parseInt(index || "0");
  console.log(
    "Data of Precription",
    peramedicAppointments[indexNumber]?.prescriptionData?.medicines
  );
  console.log(
    "Data of Test",
    peramedicAppointments[indexNumber]?.prescriptionData?.tests
  );

  // setMedicineData(doctorAppointments[indexNumber]?.prescriptionData?.medicines);
  useEffect(() => {
    // Fetch prescription data from doctorAppointments and update medicinedata state
    setMedicineData(
      peramedicAppointments[indexNumber]?.prescriptionData?.medicines
    );
    setTestData(peramedicAppointments[indexNumber]?.prescriptionData?.tests);
  }, [peramedicAppointments, indexNumber]);

  console.log(
    "......................,,,,,,,,,,,,,,,,,,,,,,,,,,,",
    medicinedata
  );
  const [medicineInput, setMedicineInput] = useState("");
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isMedicineOpen, setMedicineOpen] = useState(false);
  const [isTestOpen, setTestOpen] = useState(false);
  const [error, setError] = React.useState("");
  interface Appointment {
    appointmentDateAndTime?: string;
    date?: string;
    appointmentType?: string;
  }
  const [appointment, setAppointment] = useState<Appointment>({});
  interface Medicine {
    medicineName: string;
    medicineBrand: string;
    medicineStrength: string;
    dosage: string;
  }
  const [addmedicineArray, setAddMedicineArray] = useState<Medicine[]>([]);
  interface Test {
    testName: string;
  }
  const [addtestArray, setAddTestArray] = useState<Test[]>([]);

  const [fullData, setFullData] = useState<{
    medicines: Medicine[];
    tests: Test[];
  }>({ medicines: [], tests: [] });

  console.log("Full Data:", fullData);

  const Strength = ["50mg", "100mg", "500mg"];
  const Brand = [
    "GSK",
    "Getz Pharma",
    "Pfizer ",
    "Ferozsons",
    "Hilton ",
    "Sami",
    "Bayer",
    "Sanofi",
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGoBack = () => {
    navigate("/paramedicStaff/appointment");
  };
  const [value, setValue] = React.useState(4);
  const [symptoms, setSymptoms] = useState<string[]>([]);
  const [description, setDescription] = useState<string[]>([]);
  interface FullPatient {
    _id: string;
    name: string;
    email: string;
    gender: string;
    dateOfBirth: string;
    userImage: string;
  }
  const [fullpatient, setFullpatient] = useState<FullPatient | null>(null);
  interface FullDoctor {
    name: string;
    averageRating: number;
    qualifications: any;
    speciality: string;
    startTime: string;
  }
  const [fulldoctor, setFullDoctor] = useState<FullDoctor | null>(null);

  // const handleAccordionChange = (
  //   event: React.ChangeEvent<{}>,
  //   isExpanded: boolean
  // ) => {
  //   setIsAccordionOpen(isExpanded);
  // };

  function calculateAge(dateOfBirthString: string) {
    if (!dateOfBirthString) return "Date of birth not provided";
    const [day, month, year] = dateOfBirthString.split("/").map(Number);
    const dateOfBirth = new Date(year, month - 1, day);
    const ageDate = new Date(Date.now() - dateOfBirth.getTime());
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  }

  const handleAccordionChange = (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setIsAccordionOpen(isExpanded);
  };

  const handleMedicineClick = () => {
    setMedicineOpen(!isMedicineOpen);
    setTestOpen(false);
  };

  const handleTestClick = () => {
    setTestOpen(!isTestOpen);
    setMedicineOpen(false);
  };

  const handleMedicineInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMedicineInput(event.target.value);
  };

  const fetchSingleAppointment = (id: string) => {
    setLoading(true);
    PeraGetSingleAppointment(id)
      .then((res) => {
        console.log("response Single Appointment.......................", res);
        setAppointment(res.data.appointment);
        setDoctorid(res.data.appointment.doctorId);
        setPateintid(res.data.appointment.patientId._id);
        setFullpatient(res.data.appointment.patientId);
        setFullDoctor(res.data.appointment.doctorId);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // console.log("FULL patent ", fullpatient);
  const formik = useFormik({
    initialValues: {
      symptoms: "",
      description: "",
    },
    validationSchema: Yup.object(doctorAppointmentHistory),
    onSubmit: (values, { resetForm }) => {
      handleSubmit();
      resetForm();
    },
  });

  const handleSubmit = () => {
    // console.log("submit");
    let currentdata = formik.values;
    let symptomsArray = [currentdata.symptoms];
    let descriptionArray = [currentdata.description];
    setSymptoms(symptomsArray);
    setDescription(descriptionArray);
    let params = {
      doctorId: doctorid,
      symptoms: currentdata.symptoms,
      description: currentdata.description,
    };
    PeraAddAppointmentHistory(params)
      .then((res) => {
        console.log("response", res);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formikAddMedicine = useFormik({
    initialValues: {
      medicineName: "",
      medicineBrand: "",
      medicineStrength: "",
      dosage: "",
    },
    validationSchema: Yup.object(doctorAppointmentAddmedicineSchema),
    onSubmit: (values, { resetForm }) => {
      handleSubmittwo();
      resetForm();
      formikAddMedicine.resetForm();
      setSelectedMedicineBrand(null);
      setSelectedMedicineStrength(null);
    },
  });
  const handleMedicineBrand = (selectedOption: string) => {
    // console.log("Selected Option:", selectedOption);
    setSelectedMedicineBrand(selectedOption);
    formikAddMedicine.setFieldValue("medicineBrand", selectedOption);
  };
  const handleMedicineStrength = (selectedOption: string) => {
    // console.log("Selected Option:", selectedOption);
    setSelectedMedicineStrength(selectedOption);
    formikAddMedicine.setFieldValue("medicineStrength", selectedOption);
  };

  const handleSubmittwo = () => {
    const { medicineName, medicineStrength, dosage } = formikAddMedicine.values;
    const newMedicine = {
      medicineName,
      medicineBrand: selectedMedicineBrand || "",
      medicineStrength,
      dosage,
    };
    setFullData((prevData) => ({
      ...prevData,
      medicines: [...prevData.medicines, newMedicine],
    }));
    let datatodispatch = {
      ...fullData,
      medicines: [...fullData.medicines, newMedicine],
    };
    console.log("ABCDEFGH......................", datatodispatch);
    dispatch(setPeraAppointmentWithFullData({ index, datatodispatch }));
  };

  const formikAddTest = useFormik({
    initialValues: {
      testName: "",
    },
    validationSchema: Yup.object(doctorAppointmentAddTestSchema),
    onSubmit: (values, { resetForm }) => {
      handleSubmitAddTest();
      resetForm();
    },
  });
  const handleSubmitAddTest = () => {
    const { testName } = formikAddTest.values;
    const newTest = {
      testName,
    };
    setFullData((prevData) => ({
      ...prevData,
      tests: [...prevData.tests, newTest],
    }));
    let datatodispatch = {
      ...fullData,
      tests: [...fullData.tests, newTest],
    };
    console.log("ABCDEFGH......................", datatodispatch);

    dispatch(setPeraAppointmentWithFullData({ index, datatodispatch }));
  };

  interface PrescriptionParams {
    medicines?: Medicine[];
    test?: Test[];
  }

  // const AddPrescription = () => {
  //   if (medicinedata?.length === 0 && testdata?.length === 0) {
  //     setError("Required Test or Medicine");
  //     return; // Exit the function without hitting the API
  //   }
  //   let params: PrescriptionParams = {};
  //   if (medicinedata?.length > 0) {
  //     params.medicines = medicinedata;
  //   }
  //   if (testdata?.length > 0) {
  //     params.test = testdata;
  //   }
  //   if (id && pateintid) {
  //     DoctorAddAppointmentPrescription(id, pateintid, params)
  //       .then((res) => {
  //         setError("");
  //         console.log("response", res);
  //         setMedicineData([]);
  //         setTestData([]);
  //         dispatch(setDoctorAppointmentWithFullData(() => {}));
  //       })
  //       .catch((err) => {
  //         console.log("send error.nnnnn..", err.response.data);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // };
  const AddPrescription = () => {
    if ((medicinedata?.length ?? 0) === 0 && (testdata?.length ?? 0) === 0) {
      setError("Required Test or Medicine");
      return; // Exit the function without hitting the API
    }
    let params: PrescriptionParams = {};
    if (medicinedata?.length > 0) {
      params.medicines = medicinedata;
    }
    if (testdata?.length > 0) {
      params.test = testdata;
    }

    console.log("PARAMS", params);
    if (id && pateintid) {
      PeraAddAppointmentPrescription(id, pateintid, params)
        .then((res) => {
          setError("");
          console.log("response", res);
          dispatch(setPeramedicAppointmentFlag(true));
          handleOpenModel();
          setMedicineData([]);
          setTestData([]);
          dispatch(setPeraAppointmentWithFullData(() => {}));
        })
        .catch((err) => {
          console.log("EEEEEEEEEEEEEEEEEEEEEEEE", err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (id) {
      fetchSingleAppointment(id);
    }
  }, [id]);

  const handleOpenModel = () => {
    setShowPrescriptionModel(true);
  };
  useEffect(() => {
    // Agar modal chal raha hai, to 3 seconds ke baad band kar do
    if (showPrescriptionModel) {
      const timer = setTimeout(() => {
        setShowPrescriptionModel(false);
        setTimeout(() => {
          handleGoBack();
        }, 100);
      }, 2000);
      // Clean up function to clear the timer when component unmounts or showPrescriptionModel changes
      return () => clearTimeout(timer);
    }
  }, [showPrescriptionModel]);
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      {loading ? (
        <LoadingModal showModal={loading} />
      ) : (
        <div className={commonstyles.mr87}>
          <div className={commonstyles.outerContainer}>
            <div className={classNames(commonstyles.flx, commonstyles.mb32)}>
              <div>
                <IoArrowBack className={styles.back} onClick={handleGoBack} />
              </div>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Appoinment Details
              </p>
            </div>
            <div className={styles.docInfoCard}>
              <div style={{ textAlign: "center" }}>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  {fulldoctor?.name}
                </p>
                <p
                  className={classNames(
                    commonstyles.fs12,
                    commonstyles.medium,
                    styles.mt8,
                    styles.colorGray
                  )}
                >
                  {fulldoctor?.speciality}
                </p>
                <p
                  className={classNames(
                    commonstyles.fs12,
                    commonstyles.medium,
                    styles.mt8
                  )}
                >
                  {fulldoctor?.qualifications}
                </p>
                <div
                  className={classNames(styles.mt16)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="read-only"
                    value={fulldoctor?.averageRating}
                    readOnly
                  />
                  <p
                    className={classNames(
                      commonstyles.fs12,
                      commonstyles.semiBold,
                      styles.colorGray,
                      styles.ml16
                    )}
                  >
                    {fulldoctor?.averageRating + ".0"}
                  </p>
                </div>
              </div>
              <div>
                <div className={styles.flxIcons}>
                  <LuCalendarDays className={styles.iconxx} />
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold
                    )}
                  >
                    {appointment?.appointmentDateAndTime
                      ? new Date(appointment.appointmentDateAndTime)
                          .toISOString()
                          .split("T")[0]
                      : ""}
                  </p>
                </div>

                <div className={classNames(styles.flxIcons, styles.mt8)}>
                  <FaRegClock className={styles.iconxx} />
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold
                    )}
                  >
                    {appointment &&
                      appointment.appointmentDateAndTime &&
                      new Date(
                        appointment.appointmentDateAndTime
                      ).toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: true,
                      })}
                  </p>
                </div>
                <div style={{ textAlign: "center" }} className={styles.mt32}>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold,
                      commonstyles.colorOrange
                    )}
                    style={{ textTransform: "capitalize" }}
                  >
                    {appointment?.appointmentType}
                  </p>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                  style={{ textTransform: "capitalize" }}
                >
                  Patient Name: {fullpatient?.name}
                </p>
                <div
                  className={classNames(commonstyles.flxBetween, styles.mt16)}
                >
                  <div className={classNames(commonstyles.flx, styles.mr32)}>
                    <p className={classNames(commonstyles.fs12, styles.mr8)}>
                      Age:
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.medium
                      )}
                    >
                      {calculateAge(fullpatient?.dateOfBirth ?? "")}
                    </p>
                  </div>
                  <div className={classNames(commonstyles.flx, styles.mr16)}>
                    {" "}
                    <p className={classNames(commonstyles.fs12, styles.mr8)}>
                      Gender:
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.medium
                      )}
                    >
                      {fullpatient?.gender}
                    </p>
                  </div>
                </div>
                <div className={classNames(commonstyles.flxEvenly)}>
                  <div className={styles.appCards}>
                    <IoVideocam className={styles.iccons} />
                  </div>
                  <div className={styles.appCards}>
                    <IoChatboxEllipses className={styles.iccons} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Accordion className={styles.dropdownApp}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#00276D" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs18,
                          commonstyles.colorBlue,
                          commonstyles.semiBold
                        )}
                      >
                        History
                      </p>
                    </div>
                  </Typography>
                </AccordionSummary>
                <div className={styles.border}></div>
                <AccordionDetails>
                  <Typography>
                    <div className={commonstyles.colorBlue}>
                      <form onSubmit={formik.handleSubmit}>
                        <div>
                          <p
                            className={classNames(
                              commonstyles.fs16,
                              commonstyles.semiBold
                            )}
                          >
                            Symptoms
                          </p>
                          <CustomInput
                            placeholder="Write symptoms here"
                            id="symptoms"
                            name="symptoms"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.symptoms}
                          />
                          {formik.touched.symptoms && formik.errors.symptoms ? (
                            <div className={classNames(commonStyles.error)}>
                              *{formik.errors.symptoms}
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.mt16}>
                          <p
                            className={classNames(
                              commonstyles.fs16,
                              commonstyles.semiBold
                            )}
                          >
                            Description about patient
                          </p>
                          <CustomInput
                            placeholder="Write description here"
                            id="description"
                            name="description"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.description}
                          />
                          {formik.touched.description &&
                          formik.errors.description ? (
                            <div className={classNames(commonStyles.error)}>
                              *{formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                        <div
                          style={{
                            marginTop: "32px",
                            width: "160px",
                            cursor: "pointer",
                          }}
                        >
                          <PrimaryButton
                            children={"Save"}
                            colorType={"green"}
                            type="submit"
                          />
                        </div>
                      </form>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className={styles.dropdownApp2}
                style={{ marginBottom: "16px" }}
                expanded={isAccordionOpen}
                onChange={handleAccordionChange}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#00276D" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <div className={commonstyles.flx}>
                      <p
                        className={classNames(
                          commonstyles.fs18,
                          commonstyles.colorBlue,
                          commonstyles.semiBold
                        )}
                      >
                        Prescription
                      </p>
                      {isAccordionOpen && (
                        <>
                          <div className={commonstyles.flx}>
                            <BiSolidMessageSquareAdd
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent event propagation
                                handleMedicineClick(); // Call your function
                              }}
                              className={styles.iconOrange}
                            />
                            <p
                              className={classNames(
                                commonstyles.fs14,
                                commonstyles.colorOrange,
                                commonstyles.pointer // Add pointer class for cursor
                              )}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent event propagation
                                handleMedicineClick(); // Call your function
                              }} // Correct assignment here
                            >
                              Add Medicine
                            </p>
                          </div>
                          <div className={commonstyles.flx}>
                            <BiSolidMessageSquareAdd
                              onClick={(e) => {
                                e.stopPropagation();
                                handleTestClick();
                              }}
                              className={styles.iconBlue}
                            />

                            <p
                              className={classNames(
                                commonstyles.fs14,
                                commonstyles.colorBlue
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleTestClick();
                              }}
                            >
                              Add Test
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </Typography>
                </AccordionSummary>
                <div className={styles.border}></div>
                <AccordionDetails className={styles.PrescriptionColor}>
                  <Typography>
                    <div className={commonstyles.colorBlue}>
                      <div className={styles.flxalignStart}>
                        <div
                          className={commonstyles.col7}
                          style={{
                            borderRight: "4px solid #00276d",
                          }}
                        >
                          {isMedicineOpen && (
                            <div className={commonstyles.col10}>
                              <form onSubmit={formikAddMedicine.handleSubmit}>
                                <CustomInput
                                  placeholder="Write medicine here"
                                  id="medicineName"
                                  name="medicineName"
                                  type="text"
                                  onChange={formikAddMedicine.handleChange}
                                  value={formikAddMedicine.values.medicineName}
                                />
                                {formikAddMedicine.touched.medicineName &&
                                formikAddMedicine.errors.medicineName ? (
                                  <div
                                    className={classNames(commonStyles.error)}
                                  >
                                    *{formikAddMedicine.errors.medicineName}
                                  </div>
                                ) : null}

                                <>
                                  <div className={styles.mt8}>
                                    <CustomSelectResetValue
                                      options={Brand}
                                      placeholder="Medicine brands"
                                      onSelect={handleMedicineBrand}
                                      setvalue={selectedMedicineBrand || ""}
                                    />
                                    {formikAddMedicine.touched.medicineBrand &&
                                    formikAddMedicine.errors.medicineBrand ? (
                                      <div
                                        className={classNames(
                                          commonStyles.error
                                        )}
                                      >
                                        *
                                        {formikAddMedicine.errors.medicineBrand}
                                      </div>
                                    ) : null}
                                  </div>

                                  <div className={styles.my8}>
                                    <CustomInput
                                      placeholder="Medicine strength"
                                      id="medicineStrength"
                                      name="medicineStrength"
                                      type="text"
                                      onChange={formikAddMedicine.handleChange}
                                      value={
                                        formikAddMedicine.values
                                          .medicineStrength
                                      }
                                    />
                                    {formikAddMedicine.touched
                                      .medicineStrength &&
                                    formikAddMedicine.errors
                                      .medicineStrength ? (
                                      <div
                                        className={classNames(
                                          commonStyles.error
                                        )}
                                      >
                                        *
                                        {
                                          formikAddMedicine.errors
                                            .medicineStrength
                                        }
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className={styles.mt16}>
                                    <p
                                      className={classNames(
                                        commonstyles.fs16,
                                        commonstyles.colorBlue,
                                        commonstyles.semiBold
                                      )}
                                    >
                                      Dose
                                    </p>
                                    <textarea
                                      className={styles.DoseText}
                                      style={{ resize: "none" }}
                                      name="dosage"
                                      id="dosage"
                                      onChange={formikAddMedicine.handleChange}
                                      value={formikAddMedicine.values.dosage}
                                    />
                                    {formikAddMedicine.touched.dosage &&
                                    formikAddMedicine.errors.dosage ? (
                                      <div
                                        className={classNames(
                                          commonStyles.error
                                        )}
                                      >
                                        *{formikAddMedicine.errors.dosage}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className={classNames(
                                      commonstyles.mt56,
                                      commonstyles.BtnWidth
                                    )}
                                  >
                                    <PrimaryButton
                                      colorType={"green"}
                                      children={"Save"}
                                      type="submit"
                                    />
                                  </div>
                                </>
                              </form>
                            </div>
                          )}

                          {isTestOpen && (
                            <>
                              <div>
                                <form onSubmit={formikAddTest.handleSubmit}>
                                  <div className={commonstyles.col8}>
                                    <CustomInput
                                      placeholder="Write Test Here"
                                      id="testName"
                                      name="testName"
                                      type="text"
                                      onChange={formikAddTest.handleChange}
                                      value={formikAddTest.values.testName}
                                    />
                                    {formikAddTest.touched.testName &&
                                    formikAddTest.errors.testName ? (
                                      <div
                                        className={classNames(
                                          commonStyles.error
                                        )}
                                      >
                                        *{formikAddTest.errors.testName}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className={classNames(
                                      commonstyles.mt56,
                                      commonstyles.BtnWidth
                                    )}
                                  >
                                    <PrimaryButton
                                      colorType={"green"}
                                      children={"Save"}
                                      type="submit"
                                    />
                                  </div>
                                </form>
                              </div>
                            </>
                          )}
                          <div className={styles.logboxx}>
                            <p
                              className={classNames(
                                commonstyles.fs24,
                                commonStyles.semiBold,
                                styles.mt16
                              )}
                            >
                              Medicine
                            </p>
                            {medicinedata?.length > 0 &&
                              medicinedata?.map((medicine, index) => (
                                <div key={index}>
                                  <p>
                                    {medicine?.medicineName}
                                    {medicine?.medicineBrand &&
                                      `, ${medicine?.medicineBrand}`}
                                    {medicine?.medicineStrength &&
                                      `, ${medicine?.medicineStrength}`}
                                    {medicine?.dosage &&
                                      `, ${medicine?.dosage}`}
                                  </p>
                                </div>
                              ))}

                            <p
                              className={classNames(
                                commonstyles.fs24,
                                commonStyles.semiBold,
                                styles.mt16
                              )}
                            >
                              Test
                            </p>

                            {testdata?.length > 0 &&
                              testdata?.map((test, index) => (
                                <div key={index}>
                                  <p>{test?.testName}</p>
                                </div>
                              ))}
                          </div>
                          {error && (
                            <div className={classNames(commonStyles.error)}>
                              *{error}
                            </div>
                          )}
                          <div className={classNames(styles.mt32)}>
                            <div className={commonstyles.col3}>
                              <PrimaryButton
                                children={"Add Prescription"}
                                colorType={"green"}
                                onClick={() => {
                                  AddPrescription();
                                }}
                              />
                            </div>
                            <CustomModal showModal={showPrescriptionModel}>
                              <div style={{ width: "420px" }}>
                                <FaCheckCircle className={styles.done} />
                                <p
                                  className={classNames(
                                    commonStyles.fs16,
                                    commonStyles.semiBold,
                                    commonStyles.colorBlue,
                                    styles.textcenter
                                  )}
                                >
                                  Prescription has been successfully added.
                                </p>
                              </div>
                            </CustomModal>

                            {/* <div
                              className={classNames(
                                commonStyles.flx,
                                styles.mt32
                              )}
                            >
                              <FaStethoscope
                                className={styles.Stethoscopeicons}
                              />
                              <p
                                className={classNames(
                                  commonstyles.fs14,
                                  commonstyles.semiBold,
                                  styles.Refer
                                )}
                                onClick={() => setShowAddModal(true)}
                              >
                                Refer to Another Doctor / Hospital
                              </p>
                            </div> */}
                          </div>
                        </div>
                        <div className={commonstyles.col5}>
                          {/* <div className={styles.notePad}>
                            <div className={styles.notePadHeader}>
                              <LuClipboardList
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  marginRight: "16px",
                                }}
                              />
                              <p
                                className={classNames(
                                  commonstyles.fs14,
                                  commonstyles.semiBold
                                )}
                              >
                                Note
                              </p>
                            </div>
                            <div className={styles.notePadd}>
                              <textarea
                                style={{
                                  backgroundColor: "transparent",
                                  width: "100%",
                                  border: "none",
                                  resize: "none",
                                  boxSizing: "border-box",
                                  padding: "10px",
                                  height: "200px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#00276d",
                                }}
                              ></textarea>
                            </div>
                          </div> */}
                          <div style={{ marginLeft: "16px" }}>
                            <p
                              className={classNames(
                                commonstyles.fs18,
                                commonstyles.semiBold
                              )}
                            >
                              Symptoms
                            </p>
                            <div
                              className={classNames(
                                commonstyles.fs16,
                                commonstyles.flx,
                                styles.mt8
                              )}
                            >
                              {symptoms?.map((symptom, index) => (
                                <p key={index} className={styles.mr16}>
                                  {symptom}
                                </p>
                              ))}
                            </div>
                            <div
                              className={classNames(
                                commonstyles.fs18,
                                commonstyles.semiBold,
                                styles.mt16
                              )}
                            >
                              Description about patient
                            </div>
                            {description.map((des, index) => (
                              <p key={index} className={styles.mr16}>
                                {description}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <CustomModal
            showModal={showAddModal}
            children={
              <ReferDoctor
                fullpatient={fullpatient}
                setShowAddModal={setShowAddModal}
                pateintid={pateintid}
              />
            }
          />
        </div>
      )}
    </div>
  );
}

interface RefProps {
  setShowAddModal: any;
  pateintid: any;
  fullpatient: any;
}
interface Doctor {
  name: string;
  _id: string;
  doctorImage: string;
}

const ReferDoctor = (props: Partial<RefProps>) => {
  const [error, setError] = React.useState("");
  const [confirmmodal, setConfirmmodal] = useState(false);
  const { setShowAddModal, pateintid, fullpatient } = props;
  console.log("Patient Name is", fullpatient.name);
  const [loading, setLoading] = useState(false);
  const [selectedDoctorName, setSelectedDoctorName] = useState<string | null>(
    null
  );
  const [selectedDoctorImage, setSelectedDoctorImage] = useState<string | null>(
    null
  );
  const [refdoctor, setReferdoctor] = useState<Doctor[]>([]);
  const [selectedDoctorId, setSelectedDoctorId] = useState<string | null>(null);

  const [searchInput, setSearchInput] = useState("");
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("WORDS SEARCH", event.target.value);
    setSearchInput(event.target.value);
  };
  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  const handleHospitalSelection = (
    id: string,
    name: string,
    doctorImage: string
  ) => {
    console.log("NAMEEEEEEEEEEEEEEEEEEEEEEEEEEEEE", name);
    setSelectedDoctorId((prevId) => (prevId === id ? null : id));
    setSelectedDoctorName(name);
    setSelectedDoctorImage(doctorImage);
    // Toggle selection
  };

  const getDoctorSearching = (searchInput: string) => {
    // console.log("Search WORDS BEFORE API HIT", searchInput);
    setLoading(true);
    PeragetDoctorSearch(searchInput)
      .then((res: any) => {
        console.log("search", res);
        setReferdoctor(res.data.doctors);
      })
      .catch((err: any) => {
        console.log("API Error:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getDoctorSearching(searchInput);
  }, [searchInput]);

  const ReferTheDoctor = () => {
    if (selectedDoctorId && pateintid) {
      setConfirmmodal(true);
      setError("");
    } else {
      if (!selectedDoctorId) {
        setError("Please select Doctor");
      }
    }
  };

  return (
    <div style={{ width: "400px" }}>
      <div className={styles.DoctorSearch}>
        <input
          type="Search by Name"
          placeholder="Search"
          value={searchInput}
          onChange={handleInputChange}
        />
        <IoClose className={styles.close} onClick={handleCloseModal} />
      </div>

      {loading ? (
        <DoctorReferModel showModal={loading} />
      ) : (
        <div className={styles.loader}>
          {refdoctor.map((refdoctor) => (
            <div className={classNames(commonStyles.flx, styles.doctorcard)}>
              <Avatar src={refdoctor.doctorImage} className={styles.avatar} />
              <p
                className={classNames(
                  commonStyles.fs14,
                  commonStyles.medium,
                  commonStyles.colorBlue
                )}
                style={{ textTransform: "capitalize" }}
              >
                {refdoctor.name}
              </p>
              <div className={styles.end}>
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={
                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                  }
                  onChange={() =>
                    handleHospitalSelection(
                      refdoctor._id,
                      refdoctor.name,
                      refdoctor.doctorImage
                    )
                  }
                  checked={selectedDoctorId === refdoctor._id}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      <div className={styles.mt16}>
        <PrimaryButton
          children={"Refer"}
          colorType={"green"}
          onClick={ReferTheDoctor}
        />
        {error && (
          <div className={classNames(commonStyles.error)}>*{error}</div>
        )}
      </div>

      <CustomModal
        showModal={confirmmodal}
        children={
          <Confirm
            selectedDoctorName={selectedDoctorName}
            selectedDoctorImage={selectedDoctorImage}
            fullpatient={fullpatient}
            setConfirmmodal={setConfirmmodal}
            setShowAddModal={setShowAddModal}
            pateintid={pateintid}
            selectedDoctorId={selectedDoctorId}
          />
        }
      />
    </div>
  );
};

interface ConfirmProps {
  fullpatient: any;
  setConfirmmodal: any;
  setShowAddModal: any;
  selectedDoctorName: string | null;
  selectedDoctorImage: string | null;
  pateintid: any;
  selectedDoctorId: any;
}

const Confirm = (props: Partial<ConfirmProps>) => {
  const [successmodal, setSuccessmodal] = useState(false);
  const {
    fullpatient,
    setConfirmmodal,
    setShowAddModal,
    selectedDoctorName,
    selectedDoctorImage,
    pateintid,
    selectedDoctorId,
  } = props;
  console.log("DOCTOR NAME is", selectedDoctorImage);

  const ReferAPI = () => {
    console.log("KDKFKFKFV", selectedDoctorId, pateintid);
    if (selectedDoctorId && pateintid) {
      PeraReferPatientToDoctor(selectedDoctorId, pateintid)
        .then((res: any) => {
          setSuccessmodal(true);
          console.log(
            "Refer API Response Refer Doctor...........................................:",
            res
          );

          setConfirmmodal(false);
          setShowAddModal(false);
        })
        .catch((err: any) => {
          console.log("Refer API Error:", err?.response?.data);
        })
        .finally(() => {
          setSuccessmodal(true);
        });
    } else {
      // setError("PLEASE SELECT");
      console.log("first hvhbbhb");
    }
  };

  useEffect(() => {
    if (successmodal) {
      const timer = setTimeout(() => {
        setSuccessmodal(false);
      }, 2200);
      return () => clearTimeout(timer);
    }
  }, [successmodal]);
  return (
    <>
      <div style={{ width: "400px" }}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IoClose
            className={styles.closefinal}
            onClick={() => setConfirmmodal(false)}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Avatar
              src={fullpatient.userImage || undefined}
              sx={{ height: "82px", width: "82px" }}
            />
            <p
              className={classNames(
                commonstyle.fs14,
                commonstyle.semiBold,
                styles.mt8,
                commonstyle.colorBlue,
                styles.textcenter
              )}
            >
              {fullpatient.name}
            </p>
          </div>
          <img src={Refer} className={styles.referImg} />
          <div>
            <Avatar
              src={selectedDoctorImage || undefined}
              sx={{ height: "82px", width: "82px" }}
            />
            <p
              className={classNames(
                commonstyle.fs14,
                commonstyle.semiBold,
                styles.mt8,
                commonstyle.colorBlue,
                styles.textcenter
              )}
            >
              {selectedDoctorName}
            </p>
          </div>
        </div>
        <p
          className={classNames(
            commonstyle.fs16,
            commonstyle.semiBold,
            styles.mt24,
            commonstyle.colorBlue,
            styles.textcenter
          )}
        >
          Are you sure {fullpatient.name} refer to Dr.{selectedDoctorName}
        </p>
        <div className={classNames(commonstyle.flxEvenly, styles.mt32)}>
          <div style={{ width: "104px" }}>
            <PrimaryButton
              children={"No"}
              colorType={"Red"}
              onClick={() => {
                setConfirmmodal(false);
              }}
            />
          </div>
          <div style={{ width: "104px" }}>
            <PrimaryButton
              children={"Yes"}
              colorType={"green"}
              onClick={() => {
                ReferAPI();
                setSuccessmodal(true);
              }}
            />
          </div>
        </div>
      </div>
      <CustomModal showModal={successmodal}>
        <div style={{ width: "420px" }}>
          <FaCheckCircle className={styles.done} />
          <p
            className={classNames(
              commonStyles.fs16,
              commonStyles.semiBold,
              commonStyles.colorBlue,
              styles.textcenter
            )}
          >
            Prescription has been successfully added.
          </p>
        </div>
      </CustomModal>
    </>
  );
};
