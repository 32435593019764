import React, { useState } from "react";
import classNames from "classnames";
import phrMainStyles from "../../pharmacyMain.module.css";
import commonStyles from "shared/utils/common.module.css";
import { pharmacyBankSchema } from "shared/utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "shared/components";
import { ArrowBack, CustomInput } from "shared/components";
import ImgPicker from "shared/components/Img-picker";
import { useDispatch, useSelector } from "react-redux";
import { pharmacySignup } from "shared/services/PharmacyService";
import { setPhUser_id, setPharmacyUserFormData } from "shared/redux";
interface Props {
  handleClickNext: any;
}
const PharmacyBankdetail = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const [TaxFileError, setTaxFileError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { pharmacyUserFormData } = useSelector(
    (state: any) => state.root.pharmacy
  );
  const { fcmToken } = useSelector((state: any) => state.root.common);

  const formik = useFormik({
    initialValues: {
      pharmacyincomeTaxNo: "",
      pharmacysaleTaxNo: "",
      pharmacybankName: "",
      pharmacyaccountHolderName: "",
      pharmacyaccountNumber: "",
      TaxFile: "",
    },
    validationSchema: Yup.object(pharmacyBankSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleTaxFileUrl = (url: any) => {
    setTaxFileError(false);

    formik.setFieldValue("TaxFile", url);
  };

  const handleSubmit = async () => {
    let currentData = formik.values;

    let params = {
      name: pharmacyUserFormData.name,
      pharmacyLicenseNumber: pharmacyUserFormData.pharmacyLicenseNumber,
      licenseExpiry: pharmacyUserFormData.pharmacyLicenseExpiry,
      ownerFirstName: pharmacyUserFormData.pharmacyOwnerFirstName,
      ownerLastName: pharmacyUserFormData.pharmacyOwnerLastName,
      emergencyNo: pharmacyUserFormData.pharmacyEmergencyNumber,
      cnicOrPassportNo: pharmacyUserFormData.pharmacyCnicNumber,
      cnicOrPassportExpiry: pharmacyUserFormData.pharmacyCnicExpiry,
      openTime: pharmacyUserFormData.pharamcyOpenTime,
      closeTime: pharmacyUserFormData.pharmacyCloseTime,
      description: pharmacyUserFormData.pharmacyDescription,
      location: {
        lat: pharmacyUserFormData.lat,
        lng: pharmacyUserFormData.lng,
        address: pharmacyUserFormData.address,
        city: pharmacyUserFormData.city,
      },
      logo: pharmacyUserFormData.pharmacyLogo,
      pharmacyLicenseImage: pharmacyUserFormData.pharmacyLicenseImg,
      cnicImage: pharmacyUserFormData.pharmacyCnicImg,
      ...(pharmacyUserFormData.pharmacywebUrl && {
        website: pharmacyUserFormData.pharmacywebUrl,
      }),
      ...(pharmacyUserFormData.pharmacytwitterUrl && {
        twitter: pharmacyUserFormData.pharmacytwitterUrl,
      }),
      ...(pharmacyUserFormData.pharmacyfbUrl && {
        facebook: pharmacyUserFormData.pharmacyfbUrl,
      }),
      ...(pharmacyUserFormData.pharmacyinstaUrl && {
        instagram: pharmacyUserFormData.pharmacyinstaUrl,
      }),
      incomeTaxNo: currentData.pharmacyincomeTaxNo,
      salesTaxNo: currentData.pharmacysaleTaxNo,
      bankName: currentData.pharmacybankName,
      accountHolderName: currentData.pharmacyaccountHolderName,
      accountNumber: currentData.pharmacyaccountNumber,
      taxFileImage: currentData.TaxFile,
      fcmToken: fcmToken,
    };

    if (!formik.values.TaxFile) {
      setTaxFileError(true);
    } else {
      setLoading(true);
      pharmacySignup(params)
        .then((res: any) => {
          if (res?.data?.auth) {
            handleClickNext();
            dispatch(setPharmacyUserFormData({}));
            dispatch(setPhUser_id(res?.data?.pharmacy._id));
          }
        })
        .catch((err: any) => {
          console.log("send error.nnnnn..", err?.response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className={classNames(commonStyles.col12)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, phrMainStyles.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              {" "}
              <CustomInput
                placeholder="Income Tax No."
                id="pharmacyincomeTaxNo"
                name="pharmacyincomeTaxNo"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.pharmacyincomeTaxNo}
              />
              {formik.touched.pharmacyincomeTaxNo &&
              formik.errors.pharmacyincomeTaxNo ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.pharmacyincomeTaxNo}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="Sales Tax No."
                id="pharmacysaleTaxNo"
                name="pharmacysaleTaxNo"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.pharmacysaleTaxNo}
              />

              {formik.touched.pharmacysaleTaxNo &&
              formik.errors.pharmacysaleTaxNo ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.pharmacysaleTaxNo}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              {" "}
              <ImgPicker
                placeholder=" Attach Tax File"
                useImagePicker={false}
                setData={handleTaxFileUrl}
              />
              {TaxFileError && (
                <div className={classNames(commonStyles.error)}>*Required</div>
              )}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, phrMainStyles.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              {" "}
              <CustomInput
                placeholder="Bank Name"
                id="pharmacybankName"
                name="pharmacybankName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.pharmacybankName}
              />
              {formik.touched.pharmacybankName &&
              formik.errors.pharmacybankName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.pharmacybankName}
                </div>
              ) : null}
            </div>
          </div>

          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="Account Holder Name"
                id="pharmacyaccountHolderName"
                name="pharmacyaccountHolderName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.pharmacyaccountHolderName}
              />

              {formik.touched.pharmacyaccountHolderName &&
              formik.errors.pharmacyaccountHolderName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.pharmacyaccountHolderName}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(phrMainStyles.flx)}>
          <div
            className={classNames(
              commonStyles.col12,
              commonStyles.colsm11,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              {" "}
              <CustomInput
                placeholder="Account Number for Amount Received"
                id="pharmacyaccountNumber"
                name="pharmacyaccountNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.pharmacyaccountNumber}
              />
              {formik.touched.pharmacyaccountNumber &&
              formik.errors.pharmacyaccountNumber ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.pharmacyaccountNumber}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={phrMainStyles.buttonWidth}>
          <PrimaryButton
            disabled={!loading ? false : true}
            children={loading ? "loading..." : "Next"}
            colorType={"SkyBlueOutine"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default PharmacyBankdetail;
