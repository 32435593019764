import React, { useState } from "react";
import { ArrowBack, CustomStepper, PrimaryButton } from "shared/components";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import MainhomeStyles from "./mainHomeServices.module.css";
import DoctorBasicInfo from "./BasicInfo";
import DoctorSocial from "./Social";
import DoctorBankDetail from "./BankDetail";
import DoctorVerification from "./Verification";
import { useNavigate } from "react-router-dom";
// import Logo from "assets/images/physiotherapy 1.png";
import { IoArrowBack } from "react-icons/io5";

import source1 from "assets/images/physiotherapy 1.png";
import source2 from "assets/images/Doctor 1.png";
import source3 from "assets/images/Phsycologist 1.png";
import source4 from "assets/images/Nutrationist 1.png";
import { useSelector } from "react-redux";

const steps = [
  {
    id: "1",
    lable: "Basic Info",
  },
  {
    id: "2",
    lable: "Social",
  },
  {
    id: "3",
    lable: "Bank Details",
  },
  {
    id: "4",
    lable: "Verification",
  },
];
const DoctorSignup = () => {
  const navigate = useNavigate();
  const [screenName, setScreenName] = useState("BasicInfo");
  const [selectedStep, setSelectedStep] = useState(0);
  const { systemType } = useSelector((state: any) => state.root.common);
  const sourceToShow =
    systemType == "physiotherapist"
      ? source1
      : systemType == "doctor"
      ? source2
      : systemType == "psychologist"
      ? source3
      : systemType == "nutritionist"
      ? source4
      : "";

  const handleGoBack = () => {
    navigate(`/${systemType}/login`);
  };

  const handleClickNext = () => {
    if (screenName == "BasicInfo") {
      setScreenName("Social");
    }

    if (screenName == "Social") {
      setScreenName("BankDetail");
    }

    if (screenName == "BankDetail") {
      setScreenName("Verification");
    }
    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "BasicInfo") {
      handleGoBack();
    }
    if (screenName == "Social") {
      setScreenName("BasicInfo");
    }

    if (screenName == "BankDetail") {
      setScreenName("Social");
    }

    if (screenName == "Verification") {
      setScreenName("BankDetail");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };
  return (
    <div className={classNames(MainhomeStyles.flxSign, commonStyles.container)}>
      <div
        className={classNames(
          commonStyles.col7,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <div className={classNames(commonStyles.flx)}>
          <div className={classNames(commonStyles.mr24)}>
            <IoArrowBack
              onClick={handleClicKPrev}
              className={MainhomeStyles.BackIcon}
            />
          </div>
          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs14,
              MainhomeStyles.mr203,
              commonStyles.colorBlue
            )}
          >
            Previous
          </p>

          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs24,
              commonStyles.m0
            )}
          >
            Sign Up
          </p>
        </div>
        <div className={classNames(commonStyles.col12, commonStyles.mt32)}>
          <CustomStepper steps={steps} selectedStep={selectedStep} />
        </div>
        <div className={classNames(commonStyles.col12)}>
          {screenName == "BasicInfo" && (
            <DoctorBasicInfo handleClickNext={handleClickNext} />
          )}
          {screenName == "Social" && (
            <DoctorSocial handleClickNext={handleClickNext} />
          )}
          {screenName == "BankDetail" && (
            <DoctorBankDetail handleClickNext={handleClickNext} />
          )}
          {screenName == "Verification" && (
            <DoctorVerification handleClickNext={handleClickNext} />
          )}
        </div>
      </div>
      <div
        className={classNames(
          commonStyles.col4,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <img src={sourceToShow} className={commonStyles.loginVector} />
      </div>
    </div>
  );
};

export default DoctorSignup;
