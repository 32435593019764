import React from "react";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "./myFamily.module.css";

export default function MyFamilyDetail() {
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={commonstyles.outerContainer}>
          <h1>MyFamily Detail</h1>
        </div>
      </div>
    </div>
  );
}
