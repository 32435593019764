import React, { useEffect, useState } from "react";
import classNames from "classnames";
import MainTravelStyle from "../../../../mainTravel.module.css";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import { CustomInput, CustomModal, PrimaryButton } from "shared/components";
import { MdDeleteOutline } from "react-icons/md";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FaRegEdit } from "react-icons/fa";
import style from "./roomPrice.module.css";
import { useDispatch, useSelector } from "react-redux";
import { FaSquarePlus } from "react-icons/fa6";
import CustomSelect from "shared/components/CustomSelect";
import { IoClose } from "react-icons/io5";
import ImgPicker from "shared/components/Img-picker";
import { hotelAddRoom } from "shared/utils";
import { setHotelB_B } from "shared/redux";

const RoomType = [
  "Luxury Room",
  "Economy Room",
  "Executive Room",
  "King Room",
  "deluxe room ",
];
const RoomName = ["", "Karachi", "Multan", "Islamabad"];
const NoofallRoom = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
];
const Smoking = ["Smoking", "Non-Smoking"];
const breakFAST = ["Yes", "No"];
const Bed = ["Single", "Double"];
const NoBed = ["1", "2", "3", "4", "5"];
const Guest = ["1", "2", "3", "4", "5"];
interface Props {
  handleClickNext: any;
}
export default function RoomsPrice(props: Partial<Props>) {
  const { handleClickNext } = props;
  const dispatch = useDispatch();
  const [error, setError] = React.useState("");
  const { hotelB_B } = useSelector((state: any) => state.root.hotel);

  console.log("Screen in Rooms", hotelB_B);
  const [roomquantity, setRoomquantity] = useState(hotelB_B.rooms);
  const [showAddRoomModel, setShowAddRoomModel] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  console.log("Room quantity is", roomquantity);
  const handleModelOpen = () => {
    if (isSaved) {
      setIsSaved(false);
    }
    setShowAddRoomModel(true);
  };

  const handleSave = () => {
    setShowAddRoomModel(false);
    setIsSaved(true);
  };
  const handleDeleteRoom = (index: number) => {
    console.log("Index no", index);
    const updatedRooms = [...roomquantity];
    updatedRooms.splice(index, 1);
    setRoomquantity(updatedRooms);

    dispatch(
      setHotelB_B({
        ...hotelB_B,
        rooms: updatedRooms,
      })
    );
  };
  const NextPage = () => {
    if (!roomquantity || roomquantity.length === 0) {
      setError("Please Add Room");
    } else {
      handleClickNext();
    }
  };
  useEffect(() => {
    console.log("Rooms..............", hotelB_B.rooms);
    setRoomquantity(hotelB_B.rooms);
  }, [hotelB_B.rooms]);
  return (
    <div>
      {!roomquantity || roomquantity.length === 0 ? (
        <div className={classNames(style.container)}>
          <FaSquarePlus className={style.AddIcon} />
          <p
            className={classNames(
              commonstyles.fs24,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
            onClick={handleModelOpen}
          >
            Add Room
          </p>
        </div>
      ) : null}
      <CustomModal
        showModal={showAddRoomModel}
        children={
          <AddRoomModel
            setShowAddRoomModel={setShowAddRoomModel}
            setIsSaved={setIsSaved}
            hotelB_B={hotelB_B}
            roomquantity={roomquantity}
          />
        }
      />

      {roomquantity &&
        roomquantity?.map((room: any, index: number) => (
          <div>
            <div className={style.DataCard}>
              <div>
                <div
                  className={classNames(commonstyles.flx, commonstyles.mb16)}
                >
                  <MdDeleteOutline
                    className={style.delete}
                    onClick={() => handleDeleteRoom(index)}
                  />
                </div>
                <div
                  className={classNames(style.flxBetween, commonstyles.mb16)}
                >
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.col4
                    )}
                  >
                    Room Type
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.semiBold,
                      commonstyles.col4
                    )}
                  >
                    {room?.roomType}
                  </p>
                </div>
                <div
                  className={classNames(style.flxBetween, commonstyles.mb16)}
                >
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.col4
                    )}
                  >
                    Room Name
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.semiBold,
                      commonstyles.col4
                    )}
                  >
                    {room?.roomName}
                  </p>
                </div>
                <div
                  className={classNames(style.flxBetween, commonstyles.mb16)}
                >
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.col5
                    )}
                  >
                    No of all Room this Type
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.semiBold,
                      commonstyles.col4
                    )}
                  >
                    {room?.noOfRoomType}
                  </p>
                </div>
                <div
                  className={classNames(style.flxBetween, commonstyles.mb16)}
                >
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.col5
                    )}
                  >
                    Number of guests
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.semiBold,
                      commonstyles.col4
                    )}
                  >
                    {room?.noOfGuestsStay}
                  </p>
                </div>
                <div
                  className={classNames(style.flxBetween, commonstyles.mb16)}
                >
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.col4
                    )}
                  >
                    Price Per Night
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.semiBold,
                      commonstyles.col4
                    )}
                  >
                    {room?.pricePerNight}
                  </p>
                </div>
                <div className={classNames(style.flxBetween)}>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.col4
                    )}
                  >
                    Price For Meditour
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.semiBold,
                      commonstyles.col4
                    )}
                  >
                    {room?.priceForMeditour}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}

      {roomquantity?.length > 0 && (
        <div className={style.anotherRoom}>
          <FaSquarePlus className={style.AddIcon2} />
          <p
            className={classNames(
              commonstyles.fs14,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
            onClick={handleModelOpen}
          >
            Add another kind of Room
          </p>
        </div>
      )}
      <div
        style={{ marginTop: "32px" }}
        className={classNames(MainTravelStyle.buttonWidth)}
      >
        <PrimaryButton
          children={"Next"}
          colorType={"blue"}
          onClick={NextPage}
        />
      </div>
      {error && <div className={classNames(commonStyles.error)}>*{error}</div>}
    </div>
  );
}
interface Props {
  hotelB_B: any;
  setIsSaved: any;
  setShowAddRoomModel: any;
  roomquantity: any;
}
const AddRoomModel = (props: Partial<Props>) => {
  const { setShowAddRoomModel, setIsSaved, hotelB_B, roomquantity } = props;
  console.log("roomquantity.............", roomquantity);
  const dispatch = useDispatch();

  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };

  const handleCloseModal = () => {
    setShowAddRoomModel(false);
  };

  const handleSave = () => {
    handleCloseModal();
    setIsSaved(true);
  };

  const RoomTypeSelector = (selectedOption: string) => {
    formik.setFieldValue("roomType", selectedOption);
  };
  const SmokingPolicySelector = (selectedOption: string) => {
    formik.setFieldValue("smokingPolicy", selectedOption);
  };
  const NoOFRoomTypeSelector = (selectedOption: string) => {
    const numericValue = parseFloat(selectedOption);
    formik.setFieldValue("roomThisType", numericValue);
  };
  const BedSelector = (selectedOption: string) => {
    formik.setFieldValue("kindOfBeds", selectedOption);
  };
  const NoOfBeds = (selectedOption: string) => {
    formik.setFieldValue("numberOfBeds", selectedOption);
  };
  const NoOfGuest = (selectedOption: string) => {
    formik.setFieldValue("numberOfGuest", selectedOption);
  };
  const imageURL = (url: any) => {
    console.log("URL IS>>>>", url);
    formik.setFieldValue("roomImage", url);
  };
  const breakfastincluding = (selectedOption: string) => {
    formik.setFieldValue("breakfastInclude", selectedOption);
  };
  const formik = useFormik({
    initialValues: {
      roomType: "",
      roomName: "", ///
      smokingPolicy: "",
      roomThisType: "",
      kindOfBeds: "",
      numberOfBeds: "",
      numberOfGuest: "",
      roomSize: "",
      breakfastInclude: "",
      pricePerNight: "",
      pricePerNightforMeditour: "",
      roomImage: "",
      roomDescription: "",
    },
    validationSchema: Yup.object(hotelAddRoom),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    console.log("values of Formik is", formik.values);
    const newRoom = {
      roomType: formik.values.roomType,
      roomName: formik.values.roomName,
      smokingPolicy: formik.values.smokingPolicy,
      noOfRoomType: Number(formik.values.roomThisType),
      bedKinds: formik.values.kindOfBeds,
      noOfBeds: Number(formik.values.numberOfBeds),
      noOfGuestsStay: Number(formik.values.numberOfGuest),
      roomSize: formik.values.roomSize,
      breakfast: formik.values.breakfastInclude,
      pricePerNight: Number(formik.values.pricePerNight),
      priceForMeditour: formik.values.pricePerNightforMeditour,
      roomImages: formik.values.roomImage,
      roomDescription: formik.values.roomDescription,
    };
    const updatedRooms = [...(roomquantity || []), newRoom];
    console.log("Updated rooms..........", updatedRooms);
    dispatch(
      setHotelB_B({
        ...hotelB_B,
        rooms: updatedRooms,
      })
    );
    setShowAddRoomModel(false);
  };
  return (
    <div style={{ width: "500px" }}>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p
            className={classNames(
              commonstyles.fs24,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Add Room
          </p>
          <IoClose className={style.close} onClick={handleCloseModal} />
        </div>
        <div className={commonstyles.col12}>
          <div className={classNames(commonstyles.flx, commonstyles.mb16)}>
            <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
              <CustomSelect
                options={RoomType}
                placeholder="Room Type"
                onSelect={RoomTypeSelector}
              />
              {formik.touched.roomType && formik.errors.roomType ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.roomType}
                </div>
              ) : null}
            </div>
            <div className={commonstyles.col6}>
              <CustomInput
                placeholder="Room Name"
                id="roomName"
                name="roomName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.roomName}
              />
              {formik.touched.roomName && formik.errors.roomName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.roomName}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.mb16)}>
            <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
              <CustomSelect
                options={Smoking}
                placeholder="Smoking Policy"
                onSelect={SmokingPolicySelector}
              />
              {formik.touched.smokingPolicy && formik.errors.smokingPolicy ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.smokingPolicy}
                </div>
              ) : null}
            </div>
            <div className={commonstyles.col6}>
              <CustomSelect
                options={NoofallRoom}
                placeholder="No of all Room this Type"
                onSelect={NoOFRoomTypeSelector}
              />
              {formik.touched.roomThisType && formik.errors.roomThisType ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.roomThisType}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.mb16)}>
            <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
              <CustomSelect
                options={Bed}
                placeholder="What Kind Of Beds "
                onSelect={BedSelector}
              />
              {formik.touched.kindOfBeds && formik.errors.kindOfBeds ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.kindOfBeds}
                </div>
              ) : null}
            </div>
            <div className={commonstyles.col6}>
              <CustomSelect
                options={NoBed}
                placeholder="Number of Beds"
                onSelect={NoOfBeds}
              />
              {formik.touched.numberOfBeds && formik.errors.numberOfBeds ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.numberOfBeds}
                </div>
              ) : null}
            </div>
          </div>

          <div className={classNames(commonstyles.flx, commonstyles.mb16)}>
            <div className={classNames(commonstyles.col12)}>
              <CustomSelect
                options={Guest}
                placeholder="How many guest can stay in this room "
                onSelect={NoOfGuest}
              />
              {formik.touched.numberOfGuest && formik.errors.numberOfGuest ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.numberOfGuest}
                </div>
              ) : null}
            </div>
          </div>

          <div className={classNames(commonstyles.flx, commonstyles.mb16)}>
            <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
              <CustomInput
                placeholder="Room Size"
                id="roomSize"
                name="roomSize"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.roomSize}
              />
              {formik.touched.roomSize && formik.errors.roomSize ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.roomSize}
                </div>
              ) : null}
            </div>
            <div className={classNames(commonstyles.col6)}>
              <CustomSelect
                placeholder="Breakfast Include"
                onSelect={breakfastincluding}
                options={breakFAST}
              />
              {formik.touched.breakfastInclude &&
              formik.errors.breakfastInclude ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.breakfastInclude}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classNames(commonstyles.mb32)}>
            <div className={classNames(commonstyles.flx, commonstyles.mb16)}>
              <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
                <CustomInput
                  placeholder="Price Per Night"
                  id="pricePerNight"
                  name="pricePerNight"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.pricePerNight}
                />
                {formik.touched.pricePerNight && formik.errors.pricePerNight ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pricePerNight}
                  </div>
                ) : null}
              </div>
              <div className={classNames(commonstyles.col6)}>
                <CustomInput
                  placeholder="Price Per Night for Meditour"
                  id="pricePerNightforMeditour"
                  name="pricePerNightforMeditour"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.pricePerNightforMeditour}
                />
                {formik.touched.pricePerNightforMeditour &&
                formik.errors.pricePerNightforMeditour ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pricePerNightforMeditour}
                  </div>
                ) : null}
              </div>
            </div>

            <div className={classNames(commonstyles.col12, commonstyles.mb16)}>
              <ImgPicker placeholder="Rooms Images" setData={imageURL} />
              {formik.touched.roomImage && formik.errors.roomImage ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.roomImage}
                </div>
              ) : null}
            </div>
            <div className={classNames(commonstyles.col12, commonstyles.mb28)}>
              <CustomInput
                placeholder="Rooms Description"
                id="roomDescription"
                name="roomDescription"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.roomDescription}
              />
              {formik.touched.roomDescription &&
              formik.errors.roomDescription ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.roomDescription}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classNames(commonstyles.col5)}>
            <PrimaryButton
              children={"Save"}
              colorType={"green"}
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
};
