import * as React from "react";
import tablepay from "./ParamedicStaffPayment.module.css";
import commonstyle from "../../../utils/common.module.css";
import classNames from "classnames";
import { PiDownloadSimpleBold } from "react-icons/pi";
import ImgPicker from "shared/components/Img-picker";
import Downloader from "shared/components/Downloader";
import { Navigate, useNavigate } from "react-router-dom";

const data = [
  {
    patientId: "RI694JF4",
    patientname: "Bilal Hassan",
    servicename: "Video Consultancy",
    paymentid: "IF045J45E",
    paymentamount: "432646/-",
    receipt: <Downloader />,
  },
  {
    patientId: "RI694JF4",
    patientname: "Bilal Hassan",
    servicename: "Video Consultancy",
    paymentid: "IF045J45E",
    paymentamount: "432646/-",
    receipt: <Downloader />,
  },
  {
    patientId: "RI694JF4",
    patientname: "Bilal Hassan",
    servicename: "Video Consultancy",
    paymentid: "IF045J45E",
    paymentamount: "432646/-",
    receipt: <Downloader />,
  },
  {
    patientId: "RI694JF4",
    patientname: "Bilal Hassan",
    servicename: "Video Consultancy",
    paymentid: "IF045J45E",
    paymentamount: "432646/-",
    receipt: <Downloader />,
  },
  {
    patientId: "RI694JF4",
    patientname: "Bilal Hassan",
    servicename: "Video Consultancy",
    paymentid: "IF045J45E",
    paymentamount: "432646/-",
    receipt: <Downloader />,
  },
];

function ParamedicStaffPayment() {
  const navigate = useNavigate();
  const handleGoToDetail = () => {
    navigate("/payment/Description");
  };
  return (
    <div className={tablepay.payment}>
      <div className={tablepay.headerOuter}>
        <p className={tablepay.headerclass}>Patient ID</p>
        <p className={tablepay.headerclass}>Patient Name</p>
        <p className={tablepay.headerclass}>Service Name</p>
        <p className={tablepay.headerclass}>Payment id</p>
        <p className={tablepay.headerclass}>Payment amount</p>
        <p className={tablepay.headerclass}>Receipt</p>
      </div>
      <div className={tablepay.tableData}>
        <table
          style={{
            margin: "0px",
          }}
        >
          <tbody className={tablepay.wapper}>
            {data.map((val, key) => {
              return (
                <tr
                  className={tablepay.tableRow}
                  key={key}
                  onClick={handleGoToDetail}
                >
                  <td className={tablepay.w20}>{val.patientId}</td>
                  <td className={tablepay.w20}>{val.patientname}</td>
                  <td className={tablepay.w20}>{val.servicename}</td>
                  <td className={tablepay.w20}>{val.paymentid}</td>
                  <td className={tablepay.w20}>{val.paymentamount}</td>
                  <td className={tablepay.w20}>{val.receipt}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ParamedicStaffPayment;
