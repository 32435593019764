import React from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./TravelPayment.module.css";
import { Vendor_PaymentDetails } from "shared/components";
export default function PaymentDescritionTravel() {
  return (
    <div className={classNames(commonstyles.col12, styles.outer)}>
      <SearchBar />
      <Vendor_PaymentDetails />
    </div>
  );
}
