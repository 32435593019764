import React from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import Style from "./ParamedicPayment.module.css";
import Payment from "assets/images/PaymentSS.png";
export default function ParaPaymentDetail() {
  return (
    <div className={classNames(commonstyles.col12, Style.colorBlue)}>
      <SearchBar />
      <div className={classNames(commonstyles.mr87)}>
        <div className={classNames(Style.outerContainer)}>
          <div className={commonstyles.flx}>
            <div>
              <p
                className={classNames(commonstyles.fs24, commonstyles.semiBold)}
              >
                Payment Description
              </p>
            </div>
            <div className={classNames(Style.end, Style.mt32)}>
              <button className={Style.btnn}>Recevied Amount: 999/-</button>
            </div>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.mt56)}>
            <p className={classNames(commonstyles.fs14)}>Payment Id: </p>
            <p
              style={{ marginLeft: "46px" }}
              className={classNames(commonstyles.fs14, commonstyles.semiBold)}
            >
              321558
            </p>
          </div>
          <div className={classNames(Style.flx, commonstyles.mt56)}>
            <div className={classNames(commonstyles.flx, commonstyles.mt56)}>
              <div className={Style.borderRight}>
                <p className={classNames(commonstyles.fs14, commonstyles.bold)}>
                  Payment Id
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  DFR34F
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  DAS53VD
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  VFD54T
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  Z56F5YF
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  ER56FE
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  HY56J9G
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  FGIO87H
                </p>
              </div>
              <div className={Style.borderRight}>
                {" "}
                <p className={classNames(commonstyles.fs14, commonstyles.bold)}>
                  Customer Id
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  DFR34F
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  DAS53VD
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  VFD54T
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  Z56F5YF
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  ER56FE
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  HY56J9G
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  FGIO87H
                </p>
              </div>
              <div className={Style.textcenter}>
                {" "}
                <p className={classNames(commonstyles.fs14, commonstyles.bold)}>
                  Package name
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  DFEF
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  YHTRTR
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  VGRBTT
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  BRSTR4
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  BDSTRBT
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  BRTBTB
                </p>
                <p className={classNames(commonstyles.fs14, Style.mt16)}>
                  RTBTRBT
                </p>
              </div>
            </div>
            <div className={Style.end}>
              <div className={classNames(commonstyles.flx, Style.mt16)}>
                <div>
                  {" "}
                  <p className={classNames(commonstyles.fs14)}>
                    Total amount:{" "}
                  </p>
                  <p className={classNames(commonstyles.fs14, Style.mt16)}>
                    Total Tax:
                  </p>
                  <p className={classNames(commonstyles.fs14, Style.mt16)}>
                    Total Amount:{" "}
                  </p>
                  <p
                    className={classNames(commonstyles.fs14, commonstyles.mt56)}
                  >
                    After Tax Dedaction{" "}
                  </p>
                </div>
                <div style={{ marginLeft: "100px" }}>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold
                    )}
                  >
                    321558
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold,
                      Style.mt16
                    )}
                  >
                    321558
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold,
                      Style.mt16
                    )}
                  >
                    321558
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold,
                      commonstyles.mt56
                    )}
                  >
                    321558
                  </p>
                </div>
              </div>
              <div>
                <img src={Payment} className={Style.PaymentSS} />
                <p>Transaction slip.png</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
