import React, { useEffect, useState } from "react";
import styles from "../PharmcyOrder/order.module.css";
import classNames from "classnames";
import commomstyles from "shared/utils/common.module.css";
import PhrmacyTableorder from "../../../shared/components/PharmcyTables/phrarmayorder/";
import SearchBar from "../../../shared/components/Searchbar/index";
import commonstyle from "../../../shared/utils/common.module.css";
import { getAllPharmOrders } from "shared/services";
import { colors } from "@mui/material";
import { Loader, LoadingModal } from "shared/components";
import Pagination from "shared/components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { GrRefresh } from "react-icons/gr";
import {
  setPharmacyOrder,
  setPharmacyOrderLength,
  setrenderpharmacyOrderFlag,
} from "shared/redux";
import { TbRefresh } from "react-icons/tb";
function PharmcyOrder() {
  const [loading, setLoading] = useState(false);
  // const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const { orders, renderpharmacyOrderFlag, orderLength } = useSelector(
    (state: any) => state.root.pharmacy
  );
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const totalItems = orderLength;
  const fetchPharOrders = (pageno: number) => {
    setLoading(true);
    getAllPharmOrders(pageno)
      .then((res: any) => {
        console.log("Api Hit Response", res?.data);

        dispatch(setPharmacyOrder(res?.data?.orders));
        dispatch(setPharmacyOrderLength(res?.data?.totalPharms));
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
        // setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleStatusChange = () => {
    fetchPharOrders(currentPage);
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (orderLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setrenderpharmacyOrderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setrenderpharmacyOrderFlag(true));
    }
  };
  useEffect(() => {
    if (renderpharmacyOrderFlag) {
      setLoading(true);
      fetchPharOrders(currentPage);
      dispatch(setrenderpharmacyOrderFlag(false));
    }
  }, [renderpharmacyOrderFlag, currentPage]);

  return (
    // <div className={classNames(styles.boxm)}>
    //   <div className={classNames(styles.center1)}>
    //     <h1>Still not Received any Order</h1>
    //   </div>
    // </div>
    <div className={classNames(commonstyle.col12, styles.outer)}>
      <SearchBar />
      <div className={commonstyle.mr87}>
        <div className={styles.outerContainer}>
          <div className={classNames(styles.flxBetween, commomstyles.mb32)}>
            <div className={classNames(commomstyles.flx)}>
              <p
                className={classNames(
                  commonstyle.fs24,
                  commonstyle.semiBold,
                  commonstyle.colorBlue
                )}
              >
                All Medicines Order
              </p>
              <div className={styles.outerRefresh}>
                <TbRefresh
                  className={styles.RefreshIcon}
                  onClick={() => fetchPharOrders(pageno)}
                />
              </div>
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>

          {loading ? (
            // <Loader height={50} width={50} color="#001F57" />
            <LoadingModal
              showModal={loading}
              // hanldeCloseModal={handleCloseSuccessModal}
            />
          ) : (
            <PhrmacyTableorder
              orders={orders}
              onStatusChange={handleStatusChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PharmcyOrder;
