import React, { useState } from "react";
import { ArrowBack, CustomStepper, PrimaryButton } from "shared/components";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import labMainStyles from "../../../../mainInsurance.module.css";
import SearchBar from "shared/components/Searchbar";
import FamilyPrice from "./FamilyPrice";
import FamilyTravelBenefits from "./FamilyTravelBenefits";
import FamilyPolicy from "./FamilyPolicy";
import FamilyBasicInfoCovering from "./FamilyBasicInfoLimits";
import FamilyMedicalBenefits from "./FamilyMedicalBenefits";
import { IoArrowBack } from "react-icons/io5";
import { Navigate, useNavigate } from "react-router-dom";
const steps = [
  {
    id: "1",
    lable: "Basic Info & Covering",
  },
  {
    id: "2",
    lable: "Medical Benefits",
  },
  {
    id: "3",
    lable: "Travel Benefits",
  },
  {
    id: "4",
    lable: "Policy",
  },
  {
    id: "5",
    lable: "Price",
  },
];
const FamilyComponent = () => {
  const navigate = useNavigate(); // Declare once

  const handleGoBack = () => {
    navigate("/insurance/Travel");
  };
  const [screenName, setScreenName] = useState("Basic Info & Covering");
  const [selectedStep, setSelectedStep] = useState(0);
  console.log("screenName....", screenName);

  const handleClickNext = () => {
    if (screenName == "Basic Info & Covering") {
      setScreenName("Medical Benefits");
    }

    if (screenName == "Medical Benefits") {
      setScreenName("Travel Benefits");
    }

    if (screenName == "Travel Benefits") {
      setScreenName("Policy");
    }
    if (screenName == "Policy") {
      setScreenName("Price");
    }
    if (selectedStep < 4) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "Basic Info & Covering") {
      handleGoBack(); // Navigate to "/insurance/TravelingWith"
    }
    if (screenName == "Medical Benefits") {
      setScreenName("Basic Info & Covering");
    }

    if (screenName == "Travel Benefits") {
      setScreenName("Medical Benefits");
    }

    if (screenName == "Policy") {
      setScreenName("Travel Benefits");
    }

    if (screenName == "Price") {
      setScreenName("Policy");
    }
    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };
  return (
    <div className={classNames(commonStyles.col12)}>
      <SearchBar />
      <div className={classNames(commonStyles.mr87)}>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col6,
            commonStyles.colsm10,
            commonStyles.colmd10
          )}
        >
          <div className={classNames(commonStyles.mr24)}>
            <IoArrowBack
              style={{
                color: "#00276D",
                height: "24px",
                width: "24px",
                cursor: "pointer",
              }}
              onClick={handleClicKPrev}
            />
          </div>
          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs16,
              labMainStyles.mr203,
              commonStyles.colorBlue
            )}
          >
            Back
          </p>
          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs20,
              commonStyles.colorBlue
            )}
          >
            Travel With Family
          </p>
        </div>
        <div
          className={classNames(
            commonStyles.col8,
            commonStyles.colmd10,
            commonStyles.colsm10,
            commonStyles.mt40
          )}
        >
          <CustomStepper steps={steps} selectedStep={selectedStep} />
        </div>
        <div
          className={classNames(
            commonStyles.col12,
            commonStyles.colmd9,
            commonStyles.colsm11,
            commonStyles.mt40
          )}
        >
          {screenName == "Basic Info & Covering" && (
            <FamilyBasicInfoCovering handleClickNext={handleClickNext} />
          )}
          {screenName == "Medical Benefits" && (
            <FamilyMedicalBenefits handleClickNext={handleClickNext} />
          )}
          {screenName == "Travel Benefits" && (
            <FamilyTravelBenefits handleClickNext={handleClickNext} />
          )}
          {screenName == "Policy" && (
            <FamilyPolicy handleClickNext={handleClickNext} />
          )}
          {screenName == "Price" && <FamilyPrice />}
        </div>
        {/* <div style={{ width: "210px", marginTop: "56px" }}>
          <PrimaryButton
            children={"Next"}
            colorType={"Insurance"}
            onClick={handleClickNext}
          />
        </div> */}
      </div>
    </div>
  );
};

export default FamilyComponent;
