import React, { useState } from "react";
import { ArrowBack, CustomStepper, PrimaryButton } from "shared/components";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import MainDonatioStyle from "../MainDonation.module.css";
import BasicInfoDonation from "./BasicInfoDonation";
import DonactionVerification from "./VerificationDonation";
import DonationSocial from "./DonationSocial";
import DonationBankDetail from "./DonationBankDetail";
import { useNavigate } from "react-router-dom";
import Logo from "assets/images/Donation 1.png";
import { IoArrowBack } from "react-icons/io5";

const steps = [
  {
    id: "1",
    lable: "Basic Info",
  },
  {
    id: "2",
    lable: "Social",
  },
  {
    id: "3",
    lable: "Bank Detail",
  },
  {
    id: "4",
    lable: "Verification",
  },
];
const DonationSignup = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/donation/login");
  };

  const [screenName, setScreenName] = useState("Basic Info");
  const [selectedStep, setSelectedStep] = useState(0);
  console.log("screenName....", screenName);

  const handleClickNext = () => {
    if (screenName == "Basic Info") {
      setScreenName("Social");
    }

    if (screenName == "Social") {
      setScreenName("Bank Detail");
    }

    if (screenName == "Bank Detail") {
      setScreenName("Verification");
    }
    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "Basic Info") {
      handleGoBack();
    }
    if (screenName == "Social") {
      setScreenName("Basic Info");
    }

    if (screenName == "Bank Detail") {
      setScreenName("Social");
    }

    if (screenName == "Verification") {
      setScreenName("Bank Detail");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };
  return (
    <div
      className={classNames(MainDonatioStyle.flxSign, commonStyles.container)}
    >
      <div
        className={classNames(
          commonStyles.col7,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <div className={classNames(commonStyles.flx)}>
          <div className={classNames(commonStyles.mr24)}>
            <IoArrowBack
              onClick={handleClicKPrev}
              className={MainDonatioStyle.BackIcon}
            />
          </div>
          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs14,
              MainDonatioStyle.mr203,
              commonStyles.colorBlue
            )}
          >
            Previous
          </p>

          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs24,
              commonStyles.m0
            )}
          >
            Sign Up
          </p>
        </div>
        <div
          className={classNames(
            commonStyles.col12,
            commonStyles.colmd10,
            commonStyles.colsm10,
            commonStyles.mt40
          )}
        >
          <CustomStepper steps={steps} selectedStep={selectedStep} />
        </div>
        <div
          className={classNames(
            commonStyles.col12,
            commonStyles.colmd9,
            commonStyles.colsm11,
            commonStyles.mt40
          )}
          
        >
          {screenName == "Basic Info" && (
            <BasicInfoDonation handleClickNext={handleClickNext} />
          )}
          {screenName == "Social" && (
            <DonationSocial handleClickNext={handleClickNext} />
          )}
          {screenName == "Bank Detail" && (
            <DonationBankDetail handleClickNext={handleClickNext} />
          )}
          {screenName == "Verification" && <DonactionVerification />}
        </div>
      </div>
      <div
        className={classNames(
          commonStyles.col4,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <img src={Logo} className={commonStyles.loginVector} />
      </div>
    </div>
  );
};

export default DonationSignup;
