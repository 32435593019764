import React, { useState } from "react";
import { Doctor_Login } from "shared/components";
import Logo from "assets/images/Pharmacy  1.png";
import { useDispatch, useSelector } from "react-redux";
import { pharmaceutical_Login } from "shared/services";
const Pharmaceutical_Login = () => {
  const { systemType } = useSelector((state: any) => state.root.common);
  const { user } = useSelector((state: any) => state.root.common);

  const handle_login = (
    body: any,
    setLoading: any,
    setError: any,
    handleDispatchData: any
  ) => {
    pharmaceutical_Login(body)
      .then((res: any) => {
        handleDispatchData(res?.data?.token, res?.data?.pharmacuetical);
      })
      .catch((err: any) => {
        setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Doctor_Login
        dashboard_url={`/${systemType}/dashboard`}
        type={systemType}
        image_source={Logo}
        handle_login={handle_login}
      />
    </>
  );
};

export default Pharmaceutical_Login;
