import React, { useEffect } from "react";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import MainTravelStyle from "../../../mainTravel.module.css";
import { setHotelUserFormData } from "shared/redux";
import { CustomInput } from "shared/components";
import { PrimaryButton } from "shared/components";
import { hotelSocialSchema } from "shared/utils";
import labMainStyles from "../../../../Laboratory/laboratoryMain.module.css";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  handleClickNext: any;
}
function HotelSocial(props: Partial<Props>) {
  const dispatch = useDispatch();
  const { hotelUserFormData } = useSelector((state: any) => state.root.hotel);

  const { handleClickNext } = props;
  const formik = useFormik({
    initialValues: {
      fbUrl: "",
      instaUrl: "",
      twitterUrl: "",
      webUrl: "",
    },
    validationSchema: Yup.object(hotelSocialSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  useEffect(() => {
    formik.setValues(hotelUserFormData);
  }, []);
  const handleSubmit = async () => {
    await dispatch(setHotelUserFormData({ ...formik.values }));
    handleClickNext();
  };

  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };
  return (
    <div className={classNames(commonStyles.col12, commonStyles.mt56)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, MainTravelStyle.flx)}>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <CustomInput
              placeholder="Website Link "
              id="webUrl"
              name="webUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.webUrl}
            />
            {formik.touched.webUrl && formik.errors.webUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.webUrl}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <CustomInput
              placeholder="Instagram Link"
              id="instaUrl"
              name="instaUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.instaUrl}
            />
            {formik.touched.instaUrl && formik.errors.instaUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.instaUrl}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(MainTravelStyle.flx)}>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <CustomInput
              placeholder="Twitter Link"
              id="twitterUrl"
              name="twitterUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.twitterUrl}
            />
            {formik.touched.twitterUrl && formik.errors.twitterUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.twitterUrl}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <CustomInput
              placeholder="Facebook Link"
              id="fbUrl"
              name="fbUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.fbUrl}
            />
            {formik.touched.fbUrl && formik.errors.fbUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.fbUrl}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(labMainStyles.buttonWidth)}>
          <PrimaryButton children={"Next"} type="submit" colorType={"Travel"} />
        </div>
      </form>
    </div>
  );
}

export default HotelSocial;
