import React from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./TravelPayment.module.css";
import { Vendor_Payments } from "shared/components";
import { useSelector } from "react-redux";
const TravelPayment = () => {
  const { systemType } = useSelector((state: any) => state.root.common);
  const { travelagency } = useSelector((state: any) => state.root.travelagency);

  let vendor_type = systemType === "travelagency" ? "Travel Agency" : "";

  return (
    <div className={classNames(commonstyles.col12, styles.outer)}>
      <SearchBar />
      <Vendor_Payments type={vendor_type} id={travelagency?._id} />
    </div>
  );
};

export default TravelPayment;
