import classNames from "classnames";
import React, { useState } from "react";
import Style from "./Amenities.module.css";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import MainTravelStyle from "../../../../mainTravel.module.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Checkbox } from "@mui/material";
import { orange } from "@mui/material/colors";
import { CustomInput, CustomModal, PrimaryButton } from "shared/components";
import { IoClose } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { hotelAmenities } from "shared/utils";
import { setHotelB_B } from "shared/redux";
interface Props {
  handleClickNext: any;
}
export default function Amenities(props: Partial<Props>) {
  const [isChecked, setIsChecked] = useState(null);
  const [error, setError] = React.useState("");
  const [extraBed, setExtraBed] = useState("");
  const { handleClickNext } = props;
  const { hotelB_B } = useSelector((state: any) => state.root.hotel);
  console.log(",,,,,,,,,,,Amenities", hotelB_B);
  const dispatch = useDispatch();
  const [showAddTicketsDetail, setShowAddTicketsDetail] = useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [markAmenities, setMarkAmenities] = useState<string[]>([]);
  const handleModelOpen = () => {
    setShowAddTicketsDetail(true);
  };
  const handleSelectChange = (event: any) => {
    setExtraBed(event.target.value);
    console.log(setExtraBed(event.target.value));
  };
  console.log("Extra bed", extraBed);
  const handleCheckboxChange = (value: any) => {
    console.log("Check........", value);
    setIsChecked(value);
  };
  console.log("value.........", isChecked);
  const handleCheckboxChangeMulti = (value: string) => {
    if (!checkedItems.includes(value)) {
      setCheckedItems([...checkedItems, value]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    }
  };
  const handleSubmitone = () => {
    if (
      isChecked === null ||
      extraBed === "" ||
      checkedItems.length === 0 ||
      markAmenities.length === 0
    ) {
      setError("Please select");
    } else {
      console.log("DONE");
      dispatch(
        setHotelB_B({
          ...hotelB_B,
          extraBedAvailability: isChecked ? "Yes" : "No",
          noOfExtraBeds: extraBed,
          guestsInExtraBeds: [...checkedItems],
          amenities: [...markAmenities],
        })
      );
      handleClickNext();
    }
  };

  return (
    <div className={commonstyles.col6}>
      <div>
        <p className={commonstyles.fs14}>Can You Provide Extra Bed?</p>
      </div>
      <div className={classNames(commonstyles.flx, Style.mt8)}>
        <div className={classNames(commonstyles.flx, commonstyles.col2)}>
          <Checkbox
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
            checked={isChecked === true}
            onChange={() => handleCheckboxChange(true)}
          />
          <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
            Yes
          </p>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.col2)}>
          <Checkbox
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
            checked={isChecked === false}
            onChange={() => handleCheckboxChange(false)}
          />
          <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
            No
          </p>
        </div>
      </div>
      {isChecked == null && error && (
        <div className={classNames(commonStyles.error)}>*{error}</div>
      )}
      <div className={Style.mt32}>
        <p className={commonstyles.fs14}>
          Select The Number Of Extra bed That Can be Added
        </p>
      </div>
      <div className={classNames(Style.mt8, Style.uu)}>
        <select onChange={handleSelectChange}>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
        </select>
      </div>
      {extraBed == "" && error && (
        <div className={classNames(commonStyles.error)}>*{error}</div>
      )}
      <div className={Style.mt32}>
        <p>
          Check the boxes if you can accommodate the following guests in extra
          beds.
        </p>
      </div>
      <div className={Style.mt24}>
        <div className={classNames(commonstyles.flx, commonstyles.col6)}>
          <Checkbox
            value="Children upto 2 years old in cribs"
            onChange={() =>
              handleCheckboxChangeMulti("Children upto 2 years old in cribs")
            }
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
          />
          <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
            Children up to 2 years old in cribs
          </p>
        </div>

        <div
          className={classNames(commonstyles.flx, commonstyles.col6, Style.mt8)}
        >
          <Checkbox
            value="Children"
            onChange={() => handleCheckboxChangeMulti("Children")}
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
          />
          <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
            Children
          </p>
        </div>
        <div
          className={classNames(commonstyles.flx, commonstyles.col6, Style.mt8)}
        >
          <Checkbox
            value="Adults"
            onChange={() => handleCheckboxChangeMulti("Adults")}
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
          />
          <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
            Adults
          </p>
        </div>
      </div>
      {checkedItems.length == 0 && error && (
        <div className={classNames(commonStyles.error)}>*{error}</div>
      )}
      <div className={classNames(commonstyles.flx, Style.mt32)}>
        <div className={commonstyles.col8}>
          <CustomInput
            placeholder={markAmenities === null ? "Select" : ""}
            value={markAmenities !== null ? markAmenities.join(", ") : ""}
          />
        </div>
        <p className={Style.texts} onClick={handleModelOpen}>
          Show all Amenities{" "}
        </p>

        <CustomModal
          showModal={showAddTicketsDetail}
          children={
            <AddTicketsDetail
              setShowAddTicketsDetail={setShowAddTicketsDetail}
              markAmenities={markAmenities}
              setMarkAmenities={setMarkAmenities}
            />
          }
        />
      </div>
      {markAmenities.length == 0 && error && (
        <div className={classNames(commonStyles.error)}>*{error}</div>
      )}
      <div
        style={{ marginTop: "32px" }}
        className={classNames(MainTravelStyle.buttonWidth)}
      >
        <PrimaryButton
          children={"Next"}
          colorType={"blue"}
          onClick={handleSubmitone}
        />
      </div>
    </div>
  );
}
const AddTicketsDetail = (props: any) => {
  const { setShowAddTicketsDetail, setMarkAmenities, markAmenities } = props;

  const handleCheckboxChange = (value: string) => {
    if (!markAmenities.includes(value)) {
      setMarkAmenities([...markAmenities, value]);
    } else {
      setMarkAmenities(markAmenities.filter((item: any) => item !== value));
    }
  };
  const handleCloseModal = () => {
    setShowAddTicketsDetail(false);
  };

  return (
    <div style={{ width: "400px" }}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <IoClose className={Style.close} onClick={handleCloseModal} />
      </div>
      <div className={classNames(commonstyles.flx, commonstyles.colorBlue)}>
        <div className={classNames(commonstyles.flx, commonstyles.col6)}>
          <Checkbox
            value="Air Conditioning"
            onChange={() => handleCheckboxChange("Air Conditioning")}
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
          />
          <p className={classNames(commonstyles.fs14)}>Air conditioning</p>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.col6)}>
          <Checkbox
            value="Bath Tub"
            onChange={() => handleCheckboxChange("Bath Tub")}
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
          />
          <p className={classNames(commonstyles.fs14)}>Bath Tub</p>
        </div>
      </div>
      <div className={classNames(commonstyles.flx, commonstyles.colorBlue)}>
        <div className={classNames(commonstyles.flx, commonstyles.col6)}>
          <Checkbox
            value="Spa Tub"
            onChange={() => handleCheckboxChange("Spa Tub")}
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
          />
          <p className={classNames(commonstyles.fs14)}>Spa Tub</p>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.col6)}>
          <Checkbox
            value="Flat Screen TV"
            onChange={() => handleCheckboxChange("Flat Screen TV")}
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
          />
          <p className={classNames(commonstyles.fs14)}>Flat-screen TV</p>
        </div>
      </div>
      <div className={classNames(commonstyles.flx, commonstyles.colorBlue)}>
        <div className={classNames(commonstyles.flx, commonstyles.col6)}>
          <Checkbox
            value="Electric Kettle"
            onChange={() => handleCheckboxChange("Electric Kettle")}
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
          />
          <p className={classNames(commonstyles.fs14)}>Electric Kettle</p>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.col6)}>
          <Checkbox
            value="Balcony"
            onChange={() => handleCheckboxChange("Balcony")}
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
          />
          <p className={classNames(commonstyles.fs14)}>Balcony</p>
        </div>
      </div>

      <div className={classNames(commonstyles.flx, commonstyles.colorBlue)}>
        <div className={classNames(commonstyles.flx, commonstyles.col6)}>
          <Checkbox
            value="Towels"
            onChange={() => handleCheckboxChange("Towels")}
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
          />
          <p className={classNames(commonstyles.fs14)}>Towels</p>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.col6)}>
          <Checkbox
            value="Fan"
            onChange={() => handleCheckboxChange("Fan")}
            sx={{
              color: orange[800],
              "&.Mui-checked": {
                color: orange[800],
              },
            }}
            className={Style.checkbox}
          />
          <p className={classNames(commonstyles.fs14)}>Fan</p>
        </div>
      </div>
    </div>
  );
};
