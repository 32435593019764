import React, { useState } from "react";
import pharmacyMainStyles from "../pharmacyMain.module.css";
import { ArrowBack, CustomStepper, PrimaryButton } from "shared/components";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import PhramcyVerification from "./Verification";
import Phramcyinfo from "./PharmacyInfo";
import PharmacySocial from "./Social";
import PharmacyBankdetail from "./Bankdetail";
import PharmacyInfo from "./PharmacyInfo";
import labMainStyles from "../../Laboratory/laboratoryMain.module.css";
import { useNavigate } from "react-router-dom";
import Logo from "assets/images/Pharmacy  1.png";
import { IoArrowBack } from "react-icons/io5";
const steps = [
  {
    id: "1",
    lable: "Pharmacy Info",
  },
  {
    id: "2",
    lable: "Social",
  },
  {
    id: "3",
    lable: "Bank Details",
  },
  {
    id: "4",
    lable: "Verification",
  },
];
const Signup = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/pharmacy/login");
  };

  const [screenName, setScreenName] = useState("PharmacyInfo");
  const [selectedStep, setSelectedStep] = useState(0);

  const handleClickNext = () => {
    if (screenName == "PharmacyInfo") {
      setScreenName("Social");
    }

    if (screenName == "Social") {
      setScreenName("BankDetail");
    }

    if (screenName == "BankDetail") {
      setScreenName("Verification");
    }
    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "PharmacyInfo") {
      handleGoBack();
    }

    if (screenName == "Social") {
      setScreenName("PharmacyInfo");
    }

    if (screenName == "BankDetail") {
      setScreenName("Social");
    }

    if (screenName == "Verification") {
      setScreenName("BankDetail");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };

  return (
    <div
      className={classNames(labMainStyles.flxBetween, commonStyles.container)}
    >
      <div
        className={classNames(
          commonStyles.col6,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <div className={classNames(commonStyles.flx)}>
          <div className={classNames(commonStyles.mr24)}>
            <IoArrowBack
              onClick={handleClicKPrev}
              className={pharmacyMainStyles.BackIcon}
            />
          </div>
          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs14,
              pharmacyMainStyles.mr203,
              commonStyles.colorBlue
            )}
          >
            Previous
          </p>

          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs24,
              commonStyles.m0
            )}
          >
            Sign Up
          </p>
        </div>
        <div className={classNames(commonStyles.col11, commonStyles.mt40)}>
          <CustomStepper steps={steps} selectedStep={selectedStep} />
        </div>
        <div className={classNames(commonStyles.col12, commonStyles.mt40)}>
          {screenName == "PharmacyInfo" && (
            <PharmacyInfo handleClickNext={handleClickNext} />
          )}
          {screenName == "Social" && (
            <PharmacySocial handleClickNext={handleClickNext} />
          )}
          {screenName == "BankDetail" && (
            <PharmacyBankdetail handleClickNext={handleClickNext} />
          )}
          {screenName == "Verification" && (
            <PhramcyVerification handleClickNext={handleClickNext} />
          )}
        </div>
      </div>
      <div
        className={classNames(
          commonStyles.col4,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <img src={Logo} className={commonStyles.loginVector} />
      </div>
    </div>
  );
};

export default Signup;
