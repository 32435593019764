import React from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./PaymentBookingComplete.module.css";

import commonStyles from "shared/utils/common.module.css";
import { Checkbox } from "@mui/material";
import classNames from "classnames";
import moment from "moment";
const data = [
  {
    PAYMENTAT: "4 Feb 2019 05:30 PM",
    PAYMENTid: "KL02365",
    VENDORID: "56489",
    VENDORNAME: "Zubair Ahmed",
    TOTALORDERS: "34",
    DOCTORNAME: "bILAL",
    TOTALPAYMENTS: "500/-",
    PAIDAMOUNT: "10000/-",
  },
  {
    PAYMENTAT: "4 Feb 2019 05:30 PM",
    PAYMENTid: "KL02365",
    VENDORID: "56489",
    VENDORNAME: "Zubair Ahmed",
    TOTALORDERS: "34",
    DOCTORNAME: "bILAL",
    TOTALPAYMENTS: "500/-",
    PAIDAMOUNT: "10000/-",
  },
  {
    PAYMENTAT: "4 Feb 2019 05:30 PM",
    PAYMENTid: "KL02365",
    VENDORID: "56489",
    VENDORNAME: "Zubair Ahmed",
    TOTALORDERS: "34",
    DOCTORNAME: "bILAL",
    TOTALPAYMENTS: "500/-",
    PAIDAMOUNT: "10000/-",
  },
  {
    PAYMENTAT: "4 Feb 2019 05:30 PM",
    PAYMENTid: "KL02365",
    VENDORID: "56489",
    VENDORNAME: "Zubair Ahmed",
    TOTALORDERS: "34",
    DOCTORNAME: "bILAL",
    TOTALPAYMENTS: "500/-",
    PAIDAMOUNT: "10000/-",
  },
  {
    PAYMENTAT: "4 Feb 2019 05:30 PM",
    PAYMENTid: "KL02365",
    VENDORID: "56489",
    VENDORNAME: "Zubair Ahmed",
    TOTALORDERS: "34",
    DOCTORNAME: "bILAL",
    TOTALPAYMENTS: "500/-",
    PAIDAMOUNT: "10000/-",
  },
  {
    PAYMENTAT: "4 Feb 2019 05:30 PM",
    PAYMENTid: "KL02365",
    VENDORID: "56489",
    VENDORNAME: "Zubair Ahmed",
    TOTALORDERS: "34",
    DOCTORNAME: "bILAL",
    TOTALPAYMENTS: "500/-",
    PAIDAMOUNT: "10000/-",
  },
  {
    PAYMENTAT: "4 Feb 2019 05:30 PM",
    PAYMENTid: "KL02365",
    VENDORID: "56489",
    VENDORNAME: "Zubair Ahmed",
    TOTALORDERS: "34",
    DOCTORNAME: "bILAL",
    TOTALPAYMENTS: "500/-",
    PAIDAMOUNT: "10000/-",
  },
  {
    PAYMENTAT: "4 Feb 2019 05:30 PM",
    PAYMENTid: "KL02365",
    VENDORID: "56489",
    VENDORNAME: "Zubair Ahmed",
    TOTALORDERS: "34",
    DOCTORNAME: "bILAL",
    TOTALPAYMENTS: "500/-",
    PAIDAMOUNT: "10000/-",
  },
  {
    PAYMENTAT: "4 Feb 2019 05:30 PM",
    PAYMENTid: "KL02365",
    VENDORID: "56489",
    VENDORNAME: "Zubair Ahmed",
    TOTALORDERS: "34",
    DOCTORNAME: "bILAL",
    TOTALPAYMENTS: "500/-",
    PAIDAMOUNT: "10000/-",
  },
  {
    PAYMENTAT: "4 Feb 2019 05:30 PM",
    PAYMENTid: "KL02365",
    VENDORID: "56489",
    VENDORNAME: "Zubair Ahmed",
    TOTALORDERS: "34",
    DOCTORNAME: "bILAL",
    TOTALPAYMENTS: "500/-",
    PAIDAMOUNT: "10000/-",
  },
  {
    PAYMENTAT: "4 Feb 2019 05:30 PM",
    PAYMENTid: "KL02365",
    VENDORID: "56489",
    VENDORNAME: "Zubair Ahmed",
    TOTALORDERS: "34",
    DOCTORNAME: "bILAL",
    TOTALPAYMENTS: "500/-",
    PAIDAMOUNT: "10000/-",
  },
  {
    PAYMENTAT: "4 Feb 2019 05:30 PM",
    PAYMENTid: "KL02365",
    VENDORID: "56489",
    VENDORNAME: "Zubair Ahmed",
    TOTALORDERS: "34",
    DOCTORNAME: "bILAL",
    TOTALPAYMENTS: "500/-",
    PAIDAMOUNT: "10000/-",
  },
];
interface Props {
  Data: any;
}
const PaymentBookingCompleteAmbulance = (props: Partial<Props>) => {
  const { Data } = props;
  console.log("......Ambulance", Data);
  const navigate = useNavigate();

  const handleGoToDetailTABLE = (val: any, index: any) => {
    // const result = Data.map((v: any, i: any) => {
    //   if (i == index) {
    //     return v;
    //   }
    // });
    navigate("/admin/Payments/PaymentComplete/Detail", {
      state: {
        heading: "Payment Booking Ambulance",
        type: "Booking",
        Data: val,
      },
    });
  };
  return (
    <div className={Styles.payment}>
      <div className={classNames(Styles.headerOuter, commonStyles.bold)}>
        <p className={Styles.headerclass}>SUBMITTED AT</p>
        <p className={Styles.headerclass}>PAYMENT ID</p>
        <p className={Styles.headerclass}>VENDOR ID</p>
        <p className={Styles.headerclass}>VENDOR NAME</p>
        <p className={Styles.headerclass}>TOTAL BOOKING</p>{" "}
        <p className={Styles.headerclass}>PAID AMOUNT</p>
      </div>
      <div className={Styles.tableData}>
        <table
          style={{
            margin: "0px",
            borderCollapse: "separate",
            borderSpacing: "0 4px",
            fontFamily: '"Poppins", sans-serif',
          }}
        >
          {" "}
          <tbody className={Styles.wapper}>
            {Data?.map((val: any, key: any) => {
              const Date = moment(val?.createdAt).format("MM-DD-YYYY,  h:mm a");
              return (
                <tr
                  className={Styles.tableRow}
                  key={key}
                  onClick={() => handleGoToDetailTABLE(val, key)}
                >
                  <td className={Styles.w20}>{val?.paymentId}</td>
                  <td className={Styles.w20}>{val?.paymentId}</td>
                  <td className={Styles.w20}>{val?.paymentId}</td>
                  <td className={Styles.w20}>{val?.paymentId}</td>
                  <td className={Styles.w20}>{val?.paymentId}</td>{" "}
                  <td className={Styles.w20}>{val?.paymentId}</td>{" "}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default PaymentBookingCompleteAmbulance;
