import React, { useEffect, useState } from "react";
import classNames from "classnames";
import LabMainStyles from "../../../Laboratory/laboratoryMain.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { hotelConfirmPasswordSchema } from "shared/utils";
import { newPasswordHotel } from "shared/services/Hotel";
import commonStyles from "shared/utils/common.module.css";
import Check from "assets/check.png";
import { PrimaryButton } from "shared/components";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { ArrowBack, CustomInput } from "shared/components";
import styles from "./forgetPassword.module.css";

const HotelUpdatepassword = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const formik = useFormik({
    initialValues: {
      desiredPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object(hotelConfirmPasswordSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");
    if (token) {
      localStorage.setItem("token", token);
    }
    console.log(token);
  }, []);

  const handleSubmit = () => {
    console.log("formik values", formik.values);
    setLoading(true);
    let params = {
      newPassword: formik.values.confirmPassword,
    };
    const token = localStorage.getItem("token");

    if (token !== null) {
      newPasswordHotel(params, token)
        .then((res: any) => {
          console.log("hotel Confirm Password Link API Hit", res);
          if (res.status === 200 && res.statusText === "OK") {
            if (res?.data?.auth) {
              setShowSuccessModal(true);
            }
          }
        })
        .catch((err: any) => {
          console.log("send error of Confirm API", err?.response?.data);
          setError(err?.response?.data?.message || "An unknown error occurred");
          console.log(token);
          console.log(params);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // Handle the case where the token is null
      console.error("Token is null");
    }
  };

  return (
    <div className={classNames(commonStyles.MianOuter)}>
      {error && <div className={classNames(commonStyles.error)}>*{error}</div>}
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.col4)}>
          <div className={classNames(commonStyles.mb28, commonStyles.col12)}>
            <CustomInput
              placeholder="Desired Password"
              id="desiredPassword"
              name="desiredPassword"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.desiredPassword}
            />
            {formik.touched.desiredPassword && formik.errors.desiredPassword ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.desiredPassword}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonStyles.mb28, commonStyles.col12)}>
            <CustomInput
              placeholder="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.confirmPassword}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonStyles.mb56)}>
            <PrimaryButton children={"Password Update"} colorType={"Travel"} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default HotelUpdatepassword;
