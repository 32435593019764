import React from "react";
import styles from "./contact.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { CiMail } from "react-icons/ci";
import { CiClock2 } from "react-icons/ci";
import { PiPhoneThin } from "react-icons/pi";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import { IoMdArrowRoundForward } from "react-icons/io";
export default function ContactUs() {
  return (
    <div>
      <div className={classNames(styles.flxBetween)}>
        <div
          className={classNames(
            commonstyles.col4,
            commonstyles.colsm12,
            commonstyles.colmd12
          )}
        >
          <p
            className={classNames(
              commonstyles.fs32,
              commonstyles.semiBold,
              styles.colorBlue
            )}
          >
            <span style={{ color: "#0e54a3" }}> Get in </span>
            <span className={styles.colororange}> touch with us</span>
          </p>
          <p
            className={classNames(
              commonstyles.fs16,
              styles.mt16,
              commonstyles.col11,
              styles.textJustify,
              styles.textcolor
            )}
          >
            Welcome to MediTour! We’re here to answer all your questions and
            assist you in planning your medical tourism journey. We’re here to
            make your medical journey as smooth and stress-free as possible.
            Don’t hesitate to get in touch with us for any assistance.
          </p>
          <div className={classNames(commonstyles.flx, styles.mt24)}>
            <div className={styles.SocialIconOuter}>
              <CiMail className={styles.Socialicon} />
            </div>
            <div className={styles.textcolor}>
              <p className={commonstyles.fs16}>Email</p>
              <p className={commonstyles.fs16}>info@meditour.global</p>
            </div>
          </div>
          <div className={classNames(commonstyles.flx, styles.mt24)}>
            <div className={styles.SocialIconOuter}>
              <PiPhoneThin className={styles.Socialicon} />
            </div>
            <div className={styles.textcolor}>
              <p className={commonstyles.fs16}>Call Us </p>
              <p className={commonstyles.fs16}>USA: 813-550-4999</p>
              <p className={commonstyles.fs16}>CANADA: 437-259-5662</p>
              <p className={commonstyles.fs16}>
                PAK: 0092-42-37885101-4, <br />
                {"\t"}0092-42-35191168
              </p>
            </div>
          </div>
          <div className={classNames(commonstyles.flx, styles.mt24)}>
            <div className={styles.SocialIconOuter}>
              <CiClock2 className={styles.Socialicon} />
            </div>
            <p className={classNames(commonstyles.fs16, styles.textcolor)}>
              24/7
            </p>
          </div>
        </div>
        <div
          className={classNames(
            commonstyles.col7,
            commonstyles.colsm12,
            commonstyles.colmd12,
            styles.mtmd50,
            styles.mtsm50
          )}
        >
          <div className={styles.flxBetween}>
            <div className={classNames(styles.Contactt)}>
              <input placeholder="Your name*" />
            </div>
            <div className={classNames(styles.Contactt)}>
              <input placeholder="Email*" />
            </div>
          </div>
          <div className={classNames(styles.flxBetween, styles.mtt24)}>
            <div className={classNames(styles.Contactt)}>
              <input placeholder="Phone Number*" />
            </div>
            <div className={classNames(styles.Contactt)}>
              <input placeholder="City*" />
            </div>
          </div>
          <div className={classNames(styles.textAera, styles.mt24)}>
            <textarea placeholder="Your Message" style={{ resize: "none" }} />
          </div>
          <button className={styles.sendBttn}>
            <p>Submit</p>
            <div className={styles.iconOuter}>
              <IoMdArrowRoundForward className={styles.Iconm2} />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
