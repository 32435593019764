import React, { useEffect, useState } from "react";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getOrderDetails } from "shared/services";
import { LabOrderItems, LoadingModal, Order_Details } from "shared/components";

interface UserDetails {
  phone: number;
}

interface Item {
  testName: string;
  testCode: number;
  price: number;
}
export default function OrderDetail() {
  const [orderdetail, setOrderdetail] = useState<any>(null);
  const [user, setuser] = useState<UserDetails | null>(null);
  const [itemAmount, setItemAmount] = useState<Item[]>([]);
  const [quantities, setQuantities] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const handleGoToBack = () => {
    navigate("/laboratory/order");
  };
  const orderDetail = () => {
    setLoading(true);
    if (id === undefined) {
      console.error("ID is undefined");
      setLoading(false);
      return;
    }
    if (id) {
      getOrderDetails(id)
        .then((res: any) => {
          setOrderdetail(res.data.order);
          setuser(res.data.order.userId);
          const itemIds = res.data.order.items.map((item: any) => item.itemId);

          setItemAmount(itemIds);

          const quantitiesArray = res.data.order.items.map(
            (item: any) => item.quantity
          );
          setQuantities(quantitiesArray);
        })

        .catch((err: any) => {
          console.log("API Error:", err?.response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
    }
  };

  useEffect(() => {
    orderDetail();
  }, []);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <Order_Details
        data={orderdetail}
        handleGoToBack={handleGoToBack}
        title={"Order"}
      />
    </div>
  );
}
