import React, { useState, ChangeEvent, useEffect } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import { Checkbox } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { orange } from "@mui/material/colors";
import style from "./Facilitiesservices.module.css";
import CustomSelect from "shared/components/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { hotelAddHomefecServices } from "shared/utils";
import { setHotelAddRoom } from "shared/redux";
import { PiPlusSquareFill } from "react-icons/pi";
const Availability = ["Yes,Free", "No", "Yes,Paid"];
const Language = ["English", "Urdu"];
const Private = ["Private", "Public"];
const Onsite = ["onsite", "offsite"];
const Reservation = ["Reservation Needed", "No Reservation Needed"];
interface Props {
  handleClickNext: any;
}
export default function Facilitiesservices(props: Partial<Props>) {
  const dispatch = useDispatch();
  const { handleClickNext } = props;
  const { hotelAddRoom } = useSelector((state: any) => state.root.hotel);
  console.log("Fertility  screen...................", hotelAddRoom);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  // const handleSelect = (selectedOption: string) => {
  //   setSelectedLanguage(selectedOption);
  // };
  const parkingSelect = (selectedOption: string) => {
    setSelectedLanguage(selectedOption);
    formik.setFieldValue("parking", selectedOption);
  };
  const handlePriceSelect = (selectedOption: string) => {
    formik.setFieldValue("private", selectedOption);
  };
  const handleOnsiteSelect = (selectedOption: string) => {
    formik.setFieldValue("onSite", selectedOption);
  };
  const handlereservationNeededSelect = (selectedOption: string) => {
    formik.setFieldValue("reservationNeeded", selectedOption);
  };
  const handleLanguage = (selectedOption: string) => {
    formik.setFieldValue("LanguageSpoken", selectedOption);
  };
  const handleCheckboxChange = (value: string) => {
    if (!checkedItems.includes(value)) {
      setCheckedItems([...checkedItems, value]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    }
  };
  const formik = useFormik({
    initialValues: {
      parking: "",
      private: "",
      onSite: "",
      reservationNeeded: "",
      price: "",
      LanguageSpoken: "",
    },
    validationSchema: Yup.object(hotelAddHomefecServices),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    console.log("Submit....................");
    dispatch(
      setHotelAddRoom({
        ...hotelAddRoom,
        ...formik.values,
        facilities: checkedItems,
        propertySurroundings: properties,
      })
    );
    handleClickNext();
  };

  interface Property {
    propertyName: string;
    distance: string;
  }

  const [properties, setProperties] = useState<Property[]>([
    { propertyName: "", distance: "" },
  ]);

  const addProperty = () => {
    if (properties.length < 6) {
      setProperties([...properties, { propertyName: "", distance: "" }]);
    }
  };

  const handlePropertyChange = (
    index: number,
    field: keyof Property,
    value: string
  ) => {
    const newProperties = properties.map((property, i) => {
      if (i === index) {
        return { ...property, [field]: value };
      }
      return property;
    });
    setProperties(newProperties);
  };

  useEffect(() => {
    console.log("properties...............", checkedItems);
  }, [checkedItems]);
  return (
    <div className={classNames(commonstyles.col5, commonstyles.colorBlue)}>
      <form onSubmit={formik.handleSubmit}>
        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.colorBlue
          )}
        >
          Parking
        </p>

        <div className={classNames(commonstyles.flx, commonstyles.mb32)}>
          <div className={classNames(commonstyles.col12, style.mt8)}>
            <CustomSelect
              placeholder="Is Parking Availability to Guest"
              options={Availability}
              onSelect={parkingSelect}
            />
            {formik.touched.parking && formik.errors.parking ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.parking}
              </div>
            ) : null}
          </div>
        </div>
        {selectedLanguage === "Yes,Paid" && (
          <div>
            <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
              <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
                <CustomSelect
                  options={Private}
                  placeholder="Private"
                  onSelect={handlePriceSelect}
                />
              </div>
              <div className={commonstyles.col6}>
                <CustomSelect
                  options={Onsite}
                  placeholder="On site"
                  onSelect={handleOnsiteSelect}
                />
              </div>
            </div>
            <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
              <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
                <CustomSelect
                  options={Reservation}
                  placeholder="Reservation Needed"
                  onSelect={handlereservationNeededSelect}
                />
              </div>
              <div className={commonstyles.col6}>
                <CustomInput
                  placeholder="Price"
                  id="price"
                  name="price"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.price}
                />
                {formik.touched.price && formik.errors.price ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.price}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.colorBlue
          )}
        >
          Language Spoken
        </p>
        <div className={classNames(commonstyles.flx, commonstyles.mb32)}>
          <div className={classNames(commonstyles.col12, style.mt8)}>
            <CustomSelect
              options={Language}
              placeholder="Choose Language"
              onSelect={handleLanguage}
            />
            {formik.touched.LanguageSpoken && formik.errors.LanguageSpoken ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.LanguageSpoken}
              </div>
            ) : null}
          </div>
        </div>
        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.mb32,
            commonstyles.mt56,
            commonstyles.colorBlue
          )}
        >
          Facilities that are popular with guests
        </p>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Free Wifi"
              onChange={() => handleCheckboxChange("Free Wifi")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Free WIFI
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Bar"
              onChange={() => handleCheckboxChange("Bar")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Bar
            </p>
          </div>
        </div>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Non-smoking rooms"
              onChange={() => handleCheckboxChange("Non-smoking rooms")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Non-smoking rooms
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Swimming Pool"
              onChange={() => handleCheckboxChange("Swimming Pool")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Swimming Pool
            </p>
          </div>
        </div>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Garden"
              onChange={() => handleCheckboxChange("Garden")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Garden
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col6)}>
            <Checkbox
              value="Non-smoking rooms"
              onChange={() => handleCheckboxChange("Non-smoking rooms")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Non-smoking rooms
            </p>
          </div>
        </div>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Sauna"
              onChange={() => handleCheckboxChange("Sauna")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Sauna
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Water Park"
              onChange={() => handleCheckboxChange("Water Park")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Water Park
            </p>
          </div>
        </div>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Terrace"
              onChange={() => handleCheckboxChange("Terrace")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Terrace
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Hottub"
              onChange={() => handleCheckboxChange("Hottub")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Hot Tub
            </p>
          </div>
        </div>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Gym"
              onChange={() => handleCheckboxChange("Gym")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Gym
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Madrassah"
              onChange={() => handleCheckboxChange("Madrassah")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Madrassah
            </p>
          </div>
        </div>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Mosque"
              onChange={() => handleCheckboxChange("Mosque")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Mosque
            </p>
          </div>
        </div>

        <div className={classNames(commonstyles.flx)}>
          <p
            className={classNames(
              commonstyles.fs20,
              commonstyles.semiBold,
              commonstyles.colorBlue,
              style.mt6
            )}
          >
            Property Surroundings
          </p>
          <p style={{ marginLeft: "8px", marginTop: "6px" }}>(Max Six)</p>
        </div>

        <div className={classNames(style.flex)}>
          {properties.map((property, index) => (
            <React.Fragment key={index}>
              <div className={classNames(commonstyles.flx)}>
                <div
                  className={classNames(commonstyles.col6, commonstyles.mr32)}
                >
                  <CustomInput
                    placeholder="Property Name"
                    value={property.propertyName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handlePropertyChange(
                        index,
                        "propertyName",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div
                  className={classNames(commonstyles.col6, commonstyles.mr32)}
                >
                  <CustomInput
                    placeholder="Distance"
                    value={property.distance}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handlePropertyChange(index, "distance", e.target.value)
                    }
                  />
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        {properties.length <= 6 && (
          <div className={classNames(commonstyles.flx, style.mt8, style.jus)}>
            <PiPlusSquareFill className={style.plus} onClick={addProperty} />
            <p
              className={classNames(
                commonstyles.fs12,
                commonstyles.semiBold,
                style.colorOrange
              )}
              onClick={addProperty}
              style={{ cursor: "pointer" }}
            >
              Add Another Property
            </p>
          </div>
        )}

        <div
          style={{ marginTop: "32px", width: "204px", marginBottom: "16px" }}
        >
          <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
}
