import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./treatmentDetail.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import NavBarr from "../../NavBarr";
import { treatmentsData } from "shared/utils";
import { MdArrowForwardIos, MdOutlineKeyboardArrowDown } from "react-icons/md";
import Footerr from "../../Footer";
export default function TreatmentDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { state } = useLocation();
  console.log("..........", state.item);
  const { item, mainIndex, mainTitle } = state;
  const navigate = useNavigate();

  const handleGoDetails = (item: any, mainIndex: number) => {
    // navigate("/treatment/Details", { state: arrayOfObjects[0] });
    navigate("/treatment/Details", {
      state: { item: item, mainIndex: mainIndex, mainTitle: mainTitle },
    });
  };
  return (
    <div>
      <div
        style={{ backgroundImage: `url(${item.img})` }}
        className={styles.navIMG}
      >
        <NavBarr />
        <p
          className={classNames(
            styles.fs48,
            commonstyles.semiBold,
            styles.mianheading
          )}
        >
          {item.title}
        </p>
        <div className={styles.flxcenter}>
          <p className={styles.headingpathOrange}>Home</p>
          <MdArrowForwardIos className={styles.headingpath} />
          <p className={styles.headingpathOrange}>Medical Treaments</p>{" "}
          <MdArrowForwardIos className={styles.headingpath} />
          <p className={styles.headingpath}> {mainTitle}</p>
          <MdArrowForwardIos className={styles.headingpath} />
          <p className={styles.headingpath}>{item.title}</p>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.flxBetween}>
          <div className={styles.col7}>
            <p
              className={classNames(
                styles.fs36,
                commonstyles.semiBold,
                styles.colorBlue,
                styles.textsmcenter
              )}
            >
              About {item.title}
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.medium,
                styles.colorGray,
                styles.mt8
              )}
              style={{ textAlign: "justify" }}
            >
              {item.about}
            </p>
            <div className={commonstyles.mt56}>
              <p
                className={classNames(
                  commonstyles.fs1,
                  commonstyles.semiBold,
                  styles.colorBlue
                )}
              >
                {mainTitle}
              </p>
              <ul className={styles.list}>
                {treatmentsData[mainIndex].list.map((d: any, ind: any) => {
                  if (d.title !== item.title) {
                    return (
                      <li onClick={() => handleGoDetails(d, mainIndex)}>
                        {d.title}
                      </li>
                    );
                  }
                })}
                {/* <li>HIGH RISK PREGNANCY </li>
                <li>OBSTETRICS</li> <li>HYSTEROSCOPY</li> <li>LAPAROTOMY</li> */}
              </ul>
            </div>
          </div>
          <div className={classNames(styles.col4)}>
            <div className={classNames(styles.ConsultancyBox, styles.mtsm32)}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.medium,
                  styles.colorBlue
                )}
              >
                Get Free Consultancy
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.colorGray,
                  styles.mt8
                )}
              >
                Please fill out the form below and we will contact you shortly
              </p>
              <div className={classNames(styles.flexCol, styles.mt24)}>
                <label className={styles.labels}>Name:*</label>
                <input className={styles.inputs} />
              </div>{" "}
              <div className={classNames(styles.flexCol, styles.mt24)}>
                <label className={styles.labels}>Phone Number:*</label>
                <input className={styles.inputs} />
              </div>{" "}
              <div className={classNames(styles.flexCol, styles.mt24)}>
                <label className={styles.labels}>Email:*</label>
                <input className={styles.inputs} />
              </div>
              <div className={classNames(styles.flexCol, styles.mt24)}>
                <label className={styles.labels}>Treatment:*</label>
                <select className={styles.select}>
                  <option></option> <option>ABCD</option> <option>ABCD</option>
                </select>
              </div>
              <div className={classNames(styles.flexCol, styles.mt24)}>
                <label className={styles.labels}>Name:*</label>
                <textarea className={styles.textaera} />
              </div>
              <button className={styles.btnSubmit}>Submit</button>
            </div>
          </div>
        </div>
      </div>
      <Footerr />
    </div>
  );
}
