import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styles from "./pagination.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";

interface PaginationProps {
  onNext?: () => void;
  onPrevious?: () => void;
  startItem?: number;
  endItem?: number;
  totalItems?: number;
}

const NewPagination: React.FC<PaginationProps> = ({
  onNext,
  onPrevious,
  startItem,
  endItem,
  totalItems,
}) => {
  return (
    <div className={styles.pagination}>
      <div className={styles.gray}>
        <p className={classNames(commonstyles.fs14, commonstyles.semiBold)}>
          {startItem}-{endItem} of {totalItems}
        </p>
      </div>
      <button
        style={{ backgroundColor: "transparent" }}
        className={classNames(styles.Paginationicons)}
        onClick={onPrevious}
        disabled={startItem === 1}
      >
        <FaChevronLeft />
      </button>
      <button
        style={{ backgroundColor: "transparent" }}
        className={classNames(styles.Paginationicons)}
        onClick={onNext}
        disabled={endItem === totalItems}
      >
        <FaChevronRight />
      </button>
    </div>
  );
};

export default NewPagination;
