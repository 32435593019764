import React from "react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import styles from "./ParamedicRequests.module.css";
import commonStyles from "shared/utils/common.module.css";
import commonstyles from "shared/utils/common.module.css";
import commomstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import { CustomModal, LoadingModal, PrimaryButton } from "shared/components";
import { LuUser2 } from "react-icons/lu";
import { MdDone, MdPhone } from "react-icons/md";
import { BsClock } from "react-icons/bs";
import { LuCalendarDays } from "react-icons/lu";
import { FaCheckCircle, FaRegCheckCircle } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import empty from "assets/images/empty2.png";
import Avatar from "@mui/material/Avatar";
import CustomSelect from "shared/components/CustomSelect";
import { colors } from "@mui/material";
import { getRequests } from "../../../../shared/services/DoctorService";
import {
  paraAcceptRequest,
  paraGetAllRequest,
  paraRejectRequest,
} from "shared/services/Paramedic";
import { RxCross2 } from "react-icons/rx";
import { TbRefresh } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import NewPagination from "shared/components/NewPagination/NewPagination";
import DoctorEmpty from "shared/components/DoctorEmpty";
import {
  setPeramedicAppointmentFlag,
  setPeramedicRequests,
  setPeramedicRequestsFlag,
  setperamedicRequestLength,
} from "shared/redux";

const Request = ["idioe", "udiwnui", "bdiwojo"];
function ParamedicRequests() {
  const [acceptmodal, setAcceptModal] = useState(false);
  const [rejectMmodal, setRejectModal] = useState(false);
  const [rejectId, setRejectId] = useState("");
  console.log("IDDDDDDDDDDDDDDDDDDDDD", rejectId);
  const dispatch = useDispatch();
  const { peramedicRequests, peramedicRequestsFlag, peramedicRequestLength } =
    useSelector((state: any) => state.root.paramedic);
  const [loading, setLoading] = useState(false);
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = peramedicRequestLength;
  // const [data, setData] = useState([]);

  const handleRotate = () => {
    setRotation(rotation - rotationIncrement);
    fetchPatientHistory(currentPage);
  };
  const fetchPatientHistory = (pageno: number) => {
    setLoading(true);
    console.log("PAGE NO BEFORE API", pageno);
    paraGetAllRequest(pageno)
      .then((res: any) => {
        console.log("Doctor getrequests Api Hit Response", res);
        // setData(res.data.AppointmentRequests);
        if (res?.data?.AppointmentRequests.length >= 0) {
          dispatch(setPeramedicRequests(res?.data?.AppointmentRequests));
          dispatch(setperamedicRequestLength(res.data.requestsLength));
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const AcceptRequest = (id: string) => {
    setAcceptModal(true);
    console.log("id", id);
    paraAcceptRequest(id)
      .then((res: any) => {
        dispatch(setPeramedicAppointmentFlag(true));

        setLoading(true);
        if (res?.status === 200) {
          fetchPatientHistory(pageno);
          console.log("Send ID");
          console.log("response", res);
        }
      })
      .catch((err: any) => {
        console.log("API Error:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const RejectRequest = (rejectId: string) => {
    setLoading(true);
    console.log("id", rejectId);
    paraRejectRequest(rejectId)
      .then((res: any) => {
        fetchPatientHistory(pageno);
        if (res?.status === 200) {
          dispatch(setPeramedicAppointmentFlag(true));
          console.log("Send ID");
        }
      })
      .catch((err: any) => {
        console.log("API Error:", err);
      })
      .finally(() => {
        setLoading(false);
        setRejectId("");
        setRejectModal(false);
      });
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (peramedicRequestLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setPeramedicRequestsFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setPeramedicRequestsFlag(true));
    }
  };
  useEffect(() => {
    if (peramedicRequestsFlag) {
      setLoading(true);
      fetchPatientHistory(currentPage);
      dispatch(setPeramedicRequestsFlag(false));
    }
  }, [peramedicRequestsFlag, currentPage]);

  // useEffect(() => {
  //   fetchPatientHistory(currentPage);
  // }, [currentPage]);
  // console.log("LLLLLLLLLL", data);
  // console.log("kkkkkkkkkkkkkk", doctorsRequests);

  // const VideoCallHandle = () => {
  //   console.log("Chal");
  //   <VideoCall />;
  // };

  useEffect(() => {
    if (acceptmodal) {
      const timer = setTimeout(() => {
        setAcceptModal(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [acceptmodal]);
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={styles.outerContainer}>
          <div
            className={classNames(commonstyles.mb32, commonstyles.flxBetween)}
          >
            <div className={commonstyles.flx}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                All Request
              </p>
              <div className={styles.outerRefresh}>
                <TbRefresh
                  className={styles.RefreshIcon}
                  style={{ transform: `rotate(${rotation}deg)` }}
                  onClick={handleRotate}
                />
              </div>
              {/* <div>
                <VideoCall />
              </div> */}
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          <CustomModal showModal={acceptmodal}>
            <div style={{ width: "420px" }}>
              <FaCheckCircle className={styles.done} />
              <p
                className={classNames(
                  commonStyles.fs16,
                  commonStyles.semiBold,
                  commonStyles.colorBlue,
                  styles.textcenter
                )}
              >
                Request Accepted
              </p>
            </div>
          </CustomModal>
          <CustomModal showModal={rejectMmodal}>
            <div style={{ width: "300px" }}>
              <p
                className={classNames(
                  commomstyles.fs14,
                  commomstyles.semiBold,
                  commonStyles.colorBlue,
                  styles.textAlignCenter
                )}
              >
                Are you sure to Reject This Request
              </p>
              <div className={commonStyles.flxBetween}>
                <div className={styles.btnwidth}>
                  <PrimaryButton
                    children={"Cancel"}
                    colorType={"blue"}
                    onClick={() => setRejectModal(false)}
                  />{" "}
                </div>
                <div className={styles.btnwidth}>
                  <PrimaryButton
                    children={"Delete"}
                    colorType={"Red"}
                    onClick={() => {
                      RejectRequest(rejectId);
                    }}
                  />
                </div>
              </div>
            </div>
          </CustomModal>
          {loading ? (
            <LoadingModal showModal={loading} />
          ) : peramedicRequests && peramedicRequests.length > 0 ? (
            peramedicRequests.map((val: any, key: any) => (
              <>
                <div className={styles.card} key={key}>
                  <div className={classNames(commonstyles.flx)}>
                    <div
                      style={{ width: "15%", marginLeft: "10px" }}
                      className={commonstyles.flx}
                    >
                      <LuUser2 className={styles.icon} />
                      <p className={classNames(commonstyles.fs16)}>
                        {val.patientId?.phone}
                      </p>
                    </div>

                    <div style={{ width: "12%" }} className={commonstyles.flx}>
                      <LuCalendarDays className={styles.icon} />
                      <p className={classNames(commonstyles.fs16)}>
                        {val?.createdAt
                          ? new Date(val.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }
                            )
                          : "__"}
                      </p>
                    </div>

                    <div
                      style={{ marginLeft: "32px", width: "12%" }}
                      className={commonstyles.flx}
                    >
                      <BsClock className={styles.icon} />
                      <p className={classNames(commonstyles.fs16)}>
                        {val &&
                          val.createdAt &&
                          new Date(val.createdAt).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })}
                      </p>
                    </div>
                    <div style={{ marginLeft: "32px" }} className={styles.mt32}>
                      <p
                        className={classNames(commonstyles.fs16)}
                        style={{ textTransform: "capitalize" }}
                      >
                        {val?.status}
                      </p>
                    </div>
                    <div className={classNames(commonstyles.flx, styles.end)}>
                      <RxCross2
                        className={styles.cross}
                        onClick={() => {
                          setRejectModal(true);
                          setRejectId(val._id);
                        }}
                        // onClick={() => RejectRequest(val._id)}
                      />
                      <MdDone
                        className={styles.check}
                        onClick={() => AcceptRequest(val._id)}
                      />
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div className={classNames(styles.emptyOuter)}>
              <DoctorEmpty />
              {/* <img src={empty} className={classNames(styles.empty3)} /> */}
            </div>
          )}
        </div>
      </div>{" "}
    </div>
  );
}
export default ParamedicRequests;
