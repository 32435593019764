import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const rentCarSignup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.RENTCAR_SIGNUP, params);
};

export const rentCarSendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.RENTCAR_SEND_CODE_TO_EMAIL, params);
};

export const rentCarConfirmEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.RENTCAR_CONFIRM_EMAIL, params);
};

export const rentCarCompleteSignup = (params: any, id: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.RENTCAR_COMPLETE_SIGNUP}?id=${id}`,
    params
  );
};

export const rentCarLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.RENTCAR_LOGIN, params);
};

export const resetLinkRentCar = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.RENTCAR_RESET_LINK, params);
};

export const newPasswordRentCar = (params: any, token: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.RENTCAR_NEWPASSWORD}?token=${token}`,
    params
  );
};
export const rentCarLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.RENTCAR_LOGOUT);
};
export const rentCarGetAllVahicle = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.RENTCAR_GETALLVAHICLE}?page=${pageno}`);
};
export const rentCarGetVahicleDetail = (id: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.RENTCAR_GETVAHICLEDETAIL}?vehicleId=${id}`
  );
};
export const rentCarDeleteVahicle = (id: string) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.RENTCAR_DELETEVAHICLE}?vehicleId=${id}`
  );
};
export const rentCarAddVahicle = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.RENTCAR_ADDVAHICLE, params);
};

export const rentCarGetAllRequest = () => {
  return HTTP_CLIENT.get(ENDPOINTS.RENTCAR_GETALLREQUESTS);
};

export const rentCarAcceptRequest = (id: string) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.RENTCAR_ACCEPTREQUEST}?requestId=${id}`);
};
export const rentCarRejectRequest = (id: string) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.RENTCAR_REJECTREQUEST}?requestId=${id}`);
};
export const rentCarVahicleEdit = (id: string, params: any) => {
  return HTTP_CLIENT.put(
    `${ENDPOINTS.RENTCAR_EDITVAHICLE}?vehicleId=${id}`,
    params
  );
};

export const rentCarUpdateProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.RENTCAR_UPDATEPROFILE, params);
};

export const rentCarDashboardDetail = () => {
  return HTTP_CLIENT.get(ENDPOINTS.RENTCAR_DASHBOARDDETAILS);
};

export const rentCarGraph = () => {
  return HTTP_CLIENT.get(ENDPOINTS.RENTCAR_GRAPH);
};

export const rentcarREQUESTDETAIL = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.RENTCAR_REQUESTDETAIL}?requestId=${id}`);
};

export const rentcarCUSTOMERLIST = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.RENTCAR_CUSTOMERLIST}?page=${pageno}`);
};
export const getCompletedCustomers = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.RENTCAR_CUSTOMERLIST}?page=${pageno}`);
};
export const rentcarCompletedHistory = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.RENTACAR_HISTORY}?page=${pageno}`);
};
export const rentcarCUSTOMERDETAIL = (id: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.RENCAR_CUSTOMERDETAIL}?acceptedRequestId=${id}`
  );
};

export const rentCarChangeStatus = (id: string, params: any) => {
  return HTTP_CLIENT.put(
    `${ENDPOINTS.RENTCAR_CHANGESTATUS}?acceptedRequestId=${id}`,
    params
  );
};

export const rentCarCOMPLETEDORDER = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.RENTCAR_COMPLETEDORDER}?page=${pageno}`);
};
