import classNames from "classnames";
import React, { useEffect, useState } from "react";
import style from "./mainPageProperty.module.css";
import SearchBar from "shared/components/Searchbar";
import Apartment from "assets/images/apartment.png";
import Home from "assets/images/house.png";
import Buliding from "assets/images/buildings.png";
import commonstyles from "shared/utils/common.module.css";
import PropertyIMG from "assets/images/tours3.jpg";
import { CustomModal, LoadingModal, PrimaryButton } from "shared/components";
import { useNavigate } from "react-router-dom";
import {
  hotelGETALLAPPARTMENT,
  hotelGETALLBNB,
  hotelGETALLHOMES,
} from "shared/services/Hotel";
import { setProperties, setPropertiesLength } from "shared/redux";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import NewPagination from "shared/components/NewPagination/NewPagination";
export default function MianPageProperty() {
  const { properties, propertieslength } = useSelector(
    (state: any) => state.root.hotel
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  console.log("hotel property is..............", properties);
  console.log("LENGTH", propertieslength);
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const totalItems = propertieslength;

  const [selectedOption, setSelectedOption] = useState("Homes");
  const [showMessageCard, setShowMessageCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Declare once
  const handleGoToDetail = () => {
    navigate("/hotel/property/Description");
  };

  const handleNext = () => {
    setShowMessageCard(true);
  };

  const handleGoToHotel = () => {
    navigate("/hotel/property/categoryProperty");
  };
  const handleGoApart = () => {
    navigate("/hotel/property/apartmentBreadcrum");
  };
  const handleGoHome = () => {
    navigate("/hotel/property/homeGuestbook");
  };

  const handleSelectionChange = (type: any) => {
    setCurrentPage(1);
    console.log("event target", type);
    setSelectedOption(type);

    if (type === "Appartments") {
      setLoading(true);

      hotelGETALLAPPARTMENT(currentPage)
        .then((res: any) => {
          console.log("response of all appartments", res);
          dispatch(setPropertiesLength(res?.data?.totalAppartments));
          dispatch(setProperties(res?.data?.appartments));
        })
        .catch((err: any) => {
          console.log("API Error:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (type === "Homes") {
      setLoading(true);

      hotelGETALLHOMES(currentPage)
        .then((res: any) => {
          console.log("response of all hotelGETALLHOMES", res);
          dispatch(setPropertiesLength(res?.data?.totalHomes));
          dispatch(setProperties(res?.data?.homes));
        })
        .catch((err: any) => {
          console.log("API Error:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (type === "Hotel&BNB") {
      setLoading(true);

      hotelGETALLBNB(currentPage)
        .then((res: any) => {
          console.log("response of all hotelGETALLBNB", res);
          dispatch(setPropertiesLength(res?.data?.totalBnb));
          const bnbList = res.data.bnbs.map((item: any) => item.bnb);
          dispatch(setProperties(bnbList));
        })
        .catch((err: any) => {
          console.log("API Error:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      alert("No type is Select");
    }
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (propertieslength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      handleSelectionChange(selectedOption);

      // dispatch(setRenderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      handleSelectionChange(selectedOption);

      // dispatch(setRenderFlag(true));
    }
  };

  return (
    <div className={classNames(commonstyles.col12, commonstyles.colorBlue)}>
      <SearchBar />
      <div className={classNames(commonstyles.mr87)}>
        <div className={classNames(commonstyles.flx)}>
          <p className={classNames(commonstyles.fs24, commonstyles.semiBold)}>
            Properties
          </p>
          <div className={style.outerRefresh}>
            <BiSolidMessageSquareAdd
              className={style.addIcon}
              onClick={handleNext}
            />
          </div>
          <div className={classNames(commonstyles.flx, style.end)}>
            <select
              className={style.mianSeletion}
              onChange={(e: any) => {
                handleSelectionChange(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value="Appartments"> Appartments</option>
              <option value="Homes"> Homes</option>
              <option value="Hotel&BNB"> Hotel&BNB</option>
            </select>
          </div>
          <NewPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            startItem={(currentPage - 1) * itemsPerPage + 1}
            endItem={Math.min(currentPage * itemsPerPage, totalItems)}
            totalItems={totalItems}
          />
        </div>
        <div className={classNames(style.flxWrap)}>
          {loading ? (
            <LoadingModal showModal={loading} />
          ) : (
            properties.map((data: any) => (
              <div className={style.propertyCard}>
                <img
                  src={
                    data?.propertyphoto?.[0] ||
                    data?.homeImages?.[0] ||
                    data?.homeimages?.[0] ||
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                  }
                  className={style.cardImg}
                />
                <div className={style.p20}>
                  <div className={classNames(commonstyles.flx)}>
                    <p
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.semiBold
                      )}
                    >
                      {data?.customName}
                    </p>
                    <p className={classNames(commonstyles.fs14, style.end)}>
                      {data?.starRating}
                    </p>
                  </div>
                  <div className={classNames(commonstyles.flx, style.mt8)}>
                    <p
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.semiBold
                      )}
                    >
                      {data?.rooms?.length
                        ? `${data.rooms.length} Room`
                        : data?.apartments?.length
                        ? `${data.apartments.length} Apartment`
                        : ""}
                    </p>
                    <p className={classNames(commonstyles.fs14, style.end)}></p>
                  </div>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      style.mt8,
                      commonstyles.semiBold
                    )}
                  >
                    {data?.location?.address} {data?.location?.city}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <CustomModal showModal={showMessageCard}>
        <div
          style={{
            width: "1100px",
            textAlign: "center",
          }}
        >
          <div className={commonstyles.colorBlue}>
            <p className={classNames(commonstyles.fs24, commonstyles.semiBold)}>
              Book with us, and unlock your dream stay on MediTour!"
            </p>
            <p className={classNames(commonstyles.fs16, style.mt8)}>
              Select Your Comfort Zone On <strong>MediTour</strong>
            </p>
          </div>
          <div className={classNames(style.flxw)}>
            <div className={style.ccard} onClick={handleGoApart}>
              <img src={Apartment} className={style.homes} />
              <p
                className={classNames(commonstyles.fs24, commonstyles.semiBold)}
              >
                Apartments
              </p>
              <div className={style.mt16}>
                <p>
                  Indulge in the luxury of spacious apartments tailored to your
                  needs.
                </p>
              </div>
            </div>
            <div className={style.ccard} onClick={handleGoHome}>
              <img src={Home} className={style.homes} />
              <p
                className={classNames(commonstyles.fs24, commonstyles.semiBold)}
              >
                Homes
              </p>
              <div className={style.mt16}>
                <p>
                  Experience the warmth and comfort of a home away from home
                </p>
              </div>
            </div>
            <div className={style.ccard} onClick={handleGoToHotel}>
              <img src={Buliding} className={style.homes} />
              <p
                className={classNames(commonstyles.fs24, commonstyles.semiBold)}
              >
                Hotels, B&Bs & More
              </p>
              <div className={style.mt16}>
                <p>
                  Indulge in luxury and comfort with our curated hotel
                  selection.
                </p>
              </div>
              {/* <div className={style.mt20}>
                <button className={style.btnbottom}>Select</button>
              </div> */}
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
