import React, { useState, ChangeEvent } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import { Checkbox } from "@mui/material";
import { orange } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import style from "./Facilitiesservices.module.css";
import CustomSelect from "shared/components/CustomSelect";
import { setHotelAddAppartment } from "shared/redux";
import {
  hotelAddAppartmentFacilities,
  hotelAddHomefecServices,
} from "shared/utils";
import { PiPlusSquareFill } from "react-icons/pi";
const Availability = ["No", "Yes,Paid", "Yes,Free"];
const Language = ["What language your staff speak?", "Karachi", "Multan"];
const Private = ["Private", "Public"];
const Onsite = ["Onsite", "Offsite"];
const Reservation = ["Reservation Needed", "No Reservation Needed"];
interface Props {
  handleClickNext: any;
}
export default function Facilitiesservices(props: Partial<Props>) {
  const dispatch = useDispatch();
  const { handleClickNext } = props;
  const { hotelAddAppartment } = useSelector((state: any) => state.root.hotel);
  console.log("Fertility  screen", hotelAddAppartment);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  // const handleSelect = (selectedOption: string) => {
  //   setSelectedLanguage(selectedOption);
  // };
  const parkingSelect = (selectedOption: string) => {
    setSelectedLanguage(selectedOption);
    formik.setFieldValue("parking", selectedOption);
  };
  const handlePriceSelect = (selectedOption: string) => {
    formik.setFieldValue("private", selectedOption);
  };
  const handleOnsiteSelect = (selectedOption: string) => {
    formik.setFieldValue("onSite", selectedOption);
  };
  const handlereservationNeededSelect = (selectedOption: string) => {
    formik.setFieldValue("reservationNeeded", selectedOption);
  };
  const handleLanguage = (selectedOption: string) => {
    formik.setFieldValue("LanguageSpoken", selectedOption);
  };
  const handleCheckboxChange = (value: string) => {
    if (!checkedItems.includes(value)) {
      setCheckedItems([...checkedItems, value]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    }
  };
  const formik = useFormik({
    initialValues: {
      parking: "",
      PriceOfParking: "",
      LanguageSpoken: "",
    },
    validationSchema: Yup.object(hotelAddAppartmentFacilities),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    console.log("Submit....................");
    dispatch(
      setHotelAddAppartment({
        ...hotelAddAppartment,
        ...formik.values,
        facilities: checkedItems,
        propertySurroundings: properties,
      })
    );
    handleClickNext();
  };

  interface Property {
    propertyName: string;
    propertyDitance: string;
  }

  const [properties, setProperties] = useState<Property[]>([
    { propertyName: "", propertyDitance: "" },
  ]);

  const addProperty = () => {
    if (properties.length < 6) {
      setProperties([...properties, { propertyName: "", propertyDitance: "" }]);
    }
  };

  const handlePropertyChange = (
    index: number,
    field: keyof Property,
    value: string
  ) => {
    const newProperties = properties.map((property, i) => {
      if (i === index) {
        return { ...property, [field]: value };
      }
      return property;
    });
    setProperties(newProperties);
  };

  return (
    <div className={classNames(commonstyles.col5, commonstyles.colorBlue)}>
      <form onSubmit={formik.handleSubmit}>
        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.colorBlue
          )}
        >
          Parking
        </p>

        <div className={classNames(commonstyles.flx, commonstyles.mb32)}>
          <div className={classNames(commonstyles.col12, style.mt8)}>
            <CustomSelect
              placeholder="Is Parking Availability to Guest"
              options={Availability}
              onSelect={parkingSelect}
            />
            {formik.touched.parking && formik.errors.parking ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.parking}
              </div>
            ) : null}
          </div>
        </div>
        {selectedLanguage === "Yes,Paid" && (
          <div>
            <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
              <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
                <CustomInput
                  name="PriceOfParking"
                  placeholder="Price Of Parking"
                  id="PriceOfParking"
                  onChange={formik.handleChange}
                  value={formik.values.PriceOfParking}
                />
              </div>
              {/* <div className={commonstyles.col6}>
                <CustomSelect
                  options={Onsite}
                  placeholder="On site"
                  onSelect={handleOnsiteSelect}
                />
              </div> */}
            </div>
            {/* <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
              <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
                <CustomSelect
                  options={Reservation}
                  placeholder="Reservation Needed"
                  onSelect={handlereservationNeededSelect}
                />
              </div>
              <div className={commonstyles.col6}>
                <CustomInput
                  placeholder="Price"
                  id="price"
                  name="price"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.price}
                />
                {formik.touched.price && formik.errors.price ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.price}
                  </div>
                ) : null}
              </div>
            </div> */}
          </div>
        )}
        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.colorBlue
          )}
        >
          Language Spoken
        </p>
        <div className={classNames(commonstyles.flx, commonstyles.mb32)}>
          <div className={classNames(commonstyles.col12, style.mt8)}>
            <CustomSelect
              options={Language}
              placeholder="Add  Another Language"
              onSelect={handleLanguage}
            />
            {formik.touched.LanguageSpoken && formik.errors.LanguageSpoken ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.LanguageSpoken}
              </div>
            ) : null}
          </div>
        </div>
        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.mb32,
            commonstyles.mt56,
            commonstyles.colorBlue
          )}
        >
          Facilities that are popular with guests
        </p>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Free Wifi"
              onChange={() => handleCheckboxChange("Free Wifi")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Free WIFI
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Wifi in Common Areas"
              onChange={() => handleCheckboxChange("Wifi in Common Areas")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Wifi in Common Areas
            </p>
          </div>
        </div>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Family rooms"
              onChange={() => handleCheckboxChange("Family rooms")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Family rooms
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Co-Working Spaces"
              onChange={() => handleCheckboxChange("Co-Working Spaces")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Co-Working Spaces
            </p>
          </div>
        </div>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Garden"
              onChange={() => handleCheckboxChange("Garden")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Garden
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col6)}>
            <Checkbox
              value="Green Space"
              onChange={() => handleCheckboxChange("Green Space")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Green Space
            </p>
          </div>
        </div>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Concierge"
              onChange={() => handleCheckboxChange("Concierge")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Concierge
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="On-Site Restaurant"
              onChange={() => handleCheckboxChange("On-Site Restaurant")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              On-Site Restaurant
            </p>
          </div>
        </div>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="On-Site Retail"
              onChange={() => handleCheckboxChange("On-Site Retail")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              On-Site Retail
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Mosque"
              onChange={() => handleCheckboxChange("Mosque")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Mosque
            </p>
          </div>
        </div>
        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.flx, commonstyles.col5)}>
            <Checkbox
              value="Conference Rooms"
              onChange={() => handleCheckboxChange("Conference Rooms")}
              sx={{
                color: orange[800],
                "&.Mui-checked": {
                  color: orange[800],
                },
              }}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Conference Rooms
            </p>
          </div>
        </div>

        <div className={classNames(commonstyles.flx)}>
          <p
            className={classNames(
              commonstyles.fs20,
              commonstyles.semiBold,
              commonstyles.colorBlue,
              style.mt6
            )}
          >
            Property Surroundings
          </p>
          <p style={{ marginLeft: "8px", marginTop: "6px" }}>(Max Six)</p>
        </div>

        <div className={classNames(style.flex)}>
          {properties.map((property, index) => (
            <React.Fragment key={index}>
              <div className={classNames(commonstyles.flx)}>
                <div
                  className={classNames(commonstyles.col6, commonstyles.mr32)}
                >
                  <CustomInput
                    placeholder="Property Name"
                    value={property.propertyName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handlePropertyChange(
                        index,
                        "propertyName",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div
                  className={classNames(commonstyles.col6, commonstyles.mr32)}
                >
                  <CustomInput
                    placeholder="Distance"
                    value={property.propertyDitance}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handlePropertyChange(
                        index,
                        "propertyDitance",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        {properties.length <= 6 && (
          <div className={classNames(commonstyles.flx, style.mt8, style.jus)}>
            <PiPlusSquareFill className={style.plus} onClick={addProperty} />
            <p
              className={classNames(
                commonstyles.fs12,
                commonstyles.semiBold,
                style.colorOrange
              )}
              onClick={addProperty}
              style={{ cursor: "pointer" }}
            >
              Add Another Property
            </p>
          </div>
        )}

        <div
          style={{ marginTop: "32px", width: "204px", marginBottom: "16px" }}
        >
          <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
}
