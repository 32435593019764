import React, { useEffect } from "react";
import classNames from "classnames";
import NavBarr from "../HomeNavBar/NavBarr";
import ContactUs from "../HomeNavBar/ContactUs";
import commonstyles from "shared/utils/common.module.css";
import HomeSlider from "../HomeNavBar/HomeSlider/TopPlaces";
import styles from "./contactUsPage.module.css";
import Footerr from "../HomeNavBar/Footer";
import { IoIosArrowForward } from "react-icons/io";
export default function ContactUsPage() {
  useEffect(() => {
    document.title = "MediTour Global | Contact Us";
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <div className={styles.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            styles.mianheading
          )}
        >
          Contact Us
        </p>
        <div className={styles.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              styles.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, styles.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              styles.mianheading
            )}
          >
            Contact Us
          </p>
        </div>
      </div>
      <div className={styles.Heading}>
        <ContactUs />
      </div>
      <Footerr />
    </div>
  );
}
