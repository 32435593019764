import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import {
  Pharmaceutical_Login,
  Pharmaceutical_Signup,
  Pharmaceutical_Update_Password,
  Phramceutical_ForgetPassword,
} from "pages";

const PharmaceuticalAuthRoutes = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<Pharmaceutical_Login />} />
      <Route path="/signup" element={<Pharmaceutical_Signup />} />
      <Route
        path="/forgot-password"
        element={<Phramceutical_ForgetPassword />}
      />
      <Route
        path="/update-password"
        element={<Pharmaceutical_Update_Password />}
      />
    </Routes>
  );
};

export default PharmaceuticalAuthRoutes;
