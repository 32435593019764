import React, { useRef } from "react";
import PropTypes from "prop-types";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { SlLocationPin } from "react-icons/sl";

const LocationInput = ({ placeholder = "Enter Your Location", setData }) => {
  const autocompleteRef = useRef();

  const handleSelect = async (value) => {
    console.log("Selected value:", value);

    const {
      label,
      value: { place_id },
    } = value;

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&key=AIzaSyBrNjsUsrJ0Mmjhe-WUKDKVaIsMkZ8iQ4A`
    );
    const data = await response.json();

    const { lat, lng } = data.results[0].geometry.location;
    let city = "";
    for (let component of data.results[0].address_components) {
      for (let type of component.types) {
        if (type === "locality") {
          city = component.long_name;
          break;
        }
      }
      if (city !== "") break;
    }
    setData({ label, lat, lng, city });
  };

  const handleIconClick = () => {
    if (autocompleteRef.current) {
      autocompleteRef.current.focus();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #6F6F72",
      }}
    >
      <div style={{ width: "100%", zIndex: 10 }}>
        <GooglePlacesAutocomplete
          apiKey="AIzaSyBrNjsUsrJ0Mmjhe-WUKDKVaIsMkZ8iQ4A"
          selectProps={{
            ref: autocompleteRef,
            placeholder,
            styles: {
              placeholder: (provided) => ({
                ...provided,
                color: "#00276d",
                fontStyle: "italic",
                fontSize: "14px",
                fontFamily: '"Poppins", sans-serif',
              }),
              input: (provided) => ({
                ...provided,
                color: "#00276d",
              }),
              option: (provided) => ({
                ...provided,
                color: "#00276d",
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "#00276d",
                fontStyle: "italic",
                fontSize: "14px",
                fontFamily: '"Poppins", sans-serif',
              }),
              control: (provided) => ({
                ...provided,
                borderTop: "none",
                borderRight: "none",
                borderLeft: "none",
                borderBottom: "none",
                alignItems: "end",
                fontFamily: '"Poppins", sans-serif',
                backgroundColor: "transparent",
                borderRadius: "0px",
                borderColor: "rgba(0, 0, 0, 0.5)",
                marginTop: "8px",
                boxShadow: "0px",
                padding: "0px",
                minHeight: "32px",
                borderColor: "#6F6F72",
                "&:hover": {
                  borderBottom: "none",
                },
                "&:focus": {
                  borderBottom: "none",
                },
              }),
              indicatorsContainer: (provided) => ({
                ...provided,
                display: "none",
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: "0px",
              }),
              container: (provided) => ({
                ...provided,
                "&:active": {
                  border: "none",
                },
                "&:hover": {
                  border: "none",
                },
              }),
            },
            onChange: handleSelect,
          }}
        />
      </div>
      <SlLocationPin
        style={{
          fontSize: "28px",
          color: "#00276d",
        }}
        onClick={handleIconClick}
      />
    </div>
  );
};

LocationInput.propTypes = {
  placeholder: PropTypes.string,
  setData: PropTypes.func.isRequired,
};

export default LocationInput;
