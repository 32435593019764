import classNames from "classnames";
import React, { useEffect, useState } from "react";
import style from "./hotelDashboard.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import { Avatar } from "@mui/material";
import { GoDotFill } from "react-icons/go";
import HotelPieChart from "shared/components/HotelCharts/HotelPieChart";
import HotelLineChart from "shared/components/HotelCharts/HotelLineChart";
import { FaGreaterThan } from "react-icons/fa";
import User from "assets/images/Userr2.jpeg";
import User22 from "assets/images/Avatar.png";
import {
  hotelDASHBOARDGRAPH,
  hotelGETLATESTBOOKINGS,
  hotelLASTESTRESERVATION,
  hotelPROPERTYCOUNTS,
} from "shared/services/Hotel";
import {
  setDashboardHotelPropertyCounts,
  setDashboardReservation,
  setHotelDashboardBookings,
  setHotelGraphArray,
  setHotelGraphDetail,
} from "shared/redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const data = [
  { name: "Jan", Booked: 0, Visited: 0 },
  { name: "Feb", Booked: 0, Visited: 0 },
  { name: "Mar", Booked: 0, Visited: 0 },
  { name: "Apr", Booked: 0, Visited: 0 },
  { name: "May", Booked: 0, Visited: 0 },
  { name: "Jun", Booked: 0, Visited: 0 },
  { name: "Jul", Booked: 0, Visited: 0 },
  { name: "Aug", Booked: 0, Visited: 0 },
  { name: "Sep", Booked: 0, Visited: 0 },
  { name: "Oct", Booked: 0, Visited: 0 },
  { name: "Nov", Booked: 0, Visited: 0 },
  { name: "Dec", Booked: 0, Visited: 0 },
];
function HotelDashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    hotelGraphArray,
    hotelGraphDetail,
    dashboardReservation,
    dashboardHotelPropertyCounts,
    hotelDashboardBookings,
  } = useSelector((state: any) => state.root.hotel);

  // console.log("hotelGraphArray.................", hotelGraphArray);

  hotelGraphArray.forEach(
    (
      item: {
        month: string;
        reservationsCount: number;
        confirmedBookings: number;
      },
      index: number
    ) => {
      data[index].name = item.month;
      data[index].Booked = item.confirmedBookings;
      data[index].Visited = item.reservationsCount;
    }
  );

  interface MonthDataItem {
    month: string;
    reservationsCount: number;
    confirmedBookings: number;
  }
  const fetchMonthGraph = () => {
    hotelDASHBOARDGRAPH()
      .then((res: any) => {
        console.log(".hotelDASHBOARDGRAPH............", res);
        dispatch(
          setHotelGraphDetail({
            totalReservationsCount: res?.data?.totalReservationsCount,
            totalConfirmedBookings: res?.data?.totalConfirmedBookings,
          })
        );
        const currentMonthData: MonthDataItem[] = res.data.months;
        // Use map to convert date to day in currentWeekData
        const MonthArray = currentMonthData.map((item: MonthDataItem) => {
          const dateObject = new Date(item.month);
          const monthName = dateObject.toLocaleDateString("en-US", {
            month: "short",
          });
          return {
            month: monthName,
            reservationsCount: item.reservationsCount,
            confirmedBookings: item.confirmedBookings,
          };
        });
        let popped = MonthArray.pop();
        dispatch(setHotelGraphArray(MonthArray));
        console.log("MonthArray.....................", MonthArray);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchLatestReservations = () => {
    hotelLASTESTRESERVATION()
      .then((res: any) => {
        // console.log("hotelLASTESTRESERVATION.....................", res);
        dispatch(setDashboardReservation(res?.data?.bookings));
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleNavigate = () => {
    navigate("/hotel/reserve");
  };

  const fetchpropertyCounts = () => {
    hotelPROPERTYCOUNTS()
      .then((res: any) => {
        // console.log("hotelPROPERTYCOUNTS.....................", res);
        dispatch(
          setDashboardHotelPropertyCounts({
            totalHomes: res?.data?.totalHomes,
            totalApartments: res?.data?.totalApartments,
            totalBnbs: res?.data?.totalBnbs,
          })
        );
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchlastestBooking = () => {
    hotelGETLATESTBOOKINGS()
      .then((res: any) => {
        console.log("fetchlastestBooking.....................", res);
        dispatch(setHotelDashboardBookings(res?.data?.bookings));
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMonthGraph();
    fetchLatestReservations();
    fetchpropertyCounts();
    fetchlastestBooking();
  }, []);

  return (
    <div className={classNames(commonstyles.col12, commonstyles.colorBlue)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.flx}>
          <div
            className={classNames(
              commonstyles.col8,
              commonstyles.colmd12,
              commonstyles.colsm12
            )}
          >
            <div className={style.ChartCard}>
              <div className={commonstyles.flx}>
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold
                  )}
                >
                  Property Overall
                </p>
                {/* <div className={style.end}>
                  <select>
                    <option value="0">Last Month</option>
                    <option value="7">Land Rover</option>
                    <option value="8">Mercedes</option>
                  </select>
                </div> */}
              </div>
              <div>
                <HotelLineChart
                  data={data}
                  booked={hotelGraphDetail?.totalConfirmedBookings}
                  visited={hotelGraphDetail?.totalConfirmedBookings}
                />
              </div>
            </div>
            <div className={commonstyles.flx}>
              <div className={style.Reservation}>
                <p
                  style={{ marginBottom: "8px" }}
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Reservation
                </p>
                <div className={style.flages22}>
                  {dashboardReservation.map((data: any) => (
                    <div
                      className={classNames(commonstyles.flx, style.mt16)}
                      onClick={handleNavigate}
                    >
                      <Avatar
                        className={style.avatarr}
                        src={data?.userId?.userImage}
                      />
                      <div>
                        <p
                          style={{ fontWeight: "400" }}
                          className={classNames(commonstyles.fs16)}
                        >
                          {data?.userId?.name}
                        </p>
                        <div
                          className={classNames(
                            commonstyles.fs12,
                            style.textcolor,
                            commonstyles.flx
                          )}
                        >
                          <p>{data?.serviceModelType} </p>{" "}
                          <GoDotFill className={style.dot} />{" "}
                          <p>
                            {" "}
                            {data?.arrivalTime?.from
                              ? new Date(
                                  data?.arrivalTime?.from
                                ).toLocaleDateString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                })
                              : "__"}
                            -{" "}
                            {data?.arrivalTime?.to
                              ? new Date(
                                  data?.arrivalTime?.to
                                ).toLocaleDateString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                })
                              : "__"}
                          </p>
                        </div>
                      </div>

                      <FaGreaterThan className={style.greater} />
                    </div>
                  ))}
                </div>
              </div>
              <div className={style.PropertyAvailable}>
                <p
                  style={{ marginBottom: "8px" }}
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Property Available
                </p>
                <div className={style.flages22}>
                  <div className={classNames(commonstyles.flx, style.mt16)}>
                    <Avatar className={style.avatarr} src={User22} />
                    <div>
                      <p
                        style={{ fontWeight: "400" }}
                        className={classNames(commonstyles.fs16)}
                      >
                        Total Homes
                      </p>
                      <p
                        className={classNames(
                          commonstyles.fs12,
                          style.textcolor
                        )}
                      >
                        {dashboardHotelPropertyCounts?.totalHomes}
                      </p>
                    </div>
                  </div>
                  <div className={classNames(commonstyles.flx, style.mt16)}>
                    <Avatar className={style.avatarr} src={User22} />
                    <div>
                      <p
                        style={{ fontWeight: "400" }}
                        className={classNames(commonstyles.fs16)}
                      >
                        Total Appartments
                      </p>
                      <p
                        className={classNames(
                          commonstyles.fs12,
                          style.textcolor
                        )}
                      >
                        {dashboardHotelPropertyCounts?.totalApartments}
                      </p>
                    </div>
                  </div>
                  <div className={classNames(commonstyles.flx, style.mt16)}>
                    <Avatar className={style.avatarr} src={User22} />
                    <div>
                      <p
                        style={{ fontWeight: "400" }}
                        className={classNames(commonstyles.fs16)}
                      >
                        Total Bnb
                      </p>
                      <p
                        className={classNames(
                          commonstyles.fs12,
                          style.textcolor
                        )}
                      >
                        {dashboardHotelPropertyCounts?.totalBnbs}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col4,
              commonstyles.colmd12,
              commonstyles.colsm12
            )}
          >
            <div className={style.PieCard}>
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Revenue Overall
                </p>
                <div className={style.mt16}>
                  <HotelPieChart />
                </div>
                <div className={style.mt16}>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold
                    )}
                  >
                    Total revenue this month{" "}
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.bold,
                      style.mt8
                    )}
                  >
                    50,12350/-
                  </p>
                </div>
              </p>
            </div>
            <div className={style.PropertyBooked}>
              <p
                style={{ marginBottom: "8px" }}
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Property Booked
              </p>
              <div className={style.flages}>
                {hotelDashboardBookings.map((data: any) => (
                  <div className={classNames(commonstyles.flx, style.mt16)}>
                    <Avatar className={style.avatarr} src={User} />
                    <div>
                      <p
                        style={{ fontWeight: "500" }}
                        className={classNames(commonstyles.fs16)}
                      >
                        {data?.userId?.name}
                      </p>
                      <div
                        className={classNames(
                          commonstyles.fs12,
                          style.textcolor,
                          commonstyles.flx
                        )}
                      >
                        <p>Room type </p>
                        <GoDotFill className={style.dot2} />{" "}
                        <p>
                          {" "}
                          {data?.arrivalTime?.from
                            ? new Date(
                                data?.arrivalTime?.from
                              ).toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              })
                            : "__"}{" "}
                          -{" "}
                          {data?.arrivalTime?.to
                            ? new Date(
                                data?.arrivalTime?.to
                              ).toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              })
                            : "__"}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotelDashboard;
