import React, { useState } from "react";
import classNames from "classnames";
import CommonStyles from"shared/utils/common.module.css"
import LoginStyles from "./Uselogin.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import PasswordInput from "shared/components/PasswordInput";
import Datepicker from "shared/components/DatePicker";
import PhoneNumberInput from "shared/components/PhoneNumberInput";
import CustomSelect from "shared/components/CustomSelect";
import VerifyModal from "shared/components/VerifyModal";
import Logo from "assets/images/UserForm.png";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Full Name is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  dateOfBirth: Yup.date().required("Date of Birth is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  gender: Yup.string().required("Gender is required"),
  password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
});

const Register_Form = () => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [loading, setLoading] = useState(false);
  const inputType = passwordVisible ? "text" : "password";

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      dateOfBirth: "",
      phoneNumber: "",
      gender: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      // Simulate a server request
      setTimeout(() => {
        setLoading(false);
        setShowModal(true);
      }, 2000);
    },
  });

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSendCodeToEmail = () => {
    console.log("Resend code to email");
  };

  return (
    <div
      className={classNames(
        LoginStyles.paretns,
        CommonStyles.flx,
        CommonStyles.flxWrap
      )}
    >
      <div
        className={classNames(
          CommonStyles.col6,
          CommonStyles.colmd12,
          CommonStyles.colsm12,
          LoginStyles.centerContent
        )}
      >
        <form className={LoginStyles.loginFormContainer} onSubmit={formik.handleSubmit}
        style={{
          padding:'20px'
        }}
        >
          <p
            className={classNames(
              CommonStyles.fs40,
              CommonStyles.semiBold,
              CommonStyles.colorBlue
            )}
          >
            Register
          </p>
          <p
            className={classNames(
              CommonStyles.fs16,
              CommonStyles.semiBold,
              CommonStyles.colorBlue
            )}
          >
            Please get register to continue
          </p>
          <div className={CommonStyles.mt8}>
            <CustomInput
              placeholder="Full Name"
              id="name"
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? (
                 <div className={classNames(CommonStyles.error)}>
                *{formik.errors.name}
              </div>
            ) : null}
          </div>
          <div className={CommonStyles.mt8}>
            <CustomInput
              placeholder="Enter your Email"
              id="email"
              name="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className={classNames(CommonStyles.error)}>
                *{formik.errors.email}
              </div>
            ) : null}
          </div>
          <div className={CommonStyles.mt8}>
            <Datepicker

              // value={formik.values.dateOfBirth}
              // onChange={(date) => formik.setFieldValue("dateOfBirth", date)}
            />
            {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
              <div className={classNames(CommonStyles.error)}>
                *{formik.errors.dateOfBirth}
              </div>
            ) : null}
          </div>
          <div className={CommonStyles.mt8}>
            <PhoneNumberInput

              // value={formik.values.phoneNumber}
              // onChange={(e) => formik.setFieldValue("phoneNumber", e.target.value)}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className={classNames(CommonStyles.error)}>
                *{formik.errors.phoneNumber}
              </div>
            ) : null}
          </div>
          <div className={CommonStyles.mt8}>
            <CustomSelect
              options={["male", "female", "other"]}
              placeholder="Select Gender"
              onSelect={(option) => formik.setFieldValue("gender", option)}
              value={formik.values.gender}
            />
            {formik.touched.gender && formik.errors.gender ? (
              <div className={classNames(CommonStyles.error)}>
                *{formik.errors.gender}
              </div>
            ) : null}
          </div>
          <div className={CommonStyles.mt8}>
            <PasswordInput
              placeholder="Enter Your Password"
              id="password"
              name="password"
              type={inputType}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            
            {formik.touched.password && formik.errors.password ? (
              <div className={classNames(CommonStyles.error)}>
                *{formik.errors.password}
              </div>
            ) : null}
          </div>
          <div className={CommonStyles.mt16}>
            <PrimaryButton
              type="submit"
              children={loading ? "Registering..." : "Register"}
              colorType={"btnBlue"}
            />
          </div>
        </form>
      </div>
      <div
        className={classNames(
          LoginStyles.imgBackgrond,
          CommonStyles.col6,
          CommonStyles.colmd12,
          CommonStyles.colsm12
        )}
      >
        <div className={classNames(LoginStyles.centerContent)}>
          <img src={Logo} alt="Logo" className={LoginStyles.logoImage} />
        </div>
      </div>
      <VerifyModal
        showModal={showModal}
        hanldeCloseModal={handleCloseModal}
        code={code}
        setCode={setCode}
        codeError={codeError}
        setCodeError={setCodeError}
        loading={loading}
        minutes={2}
        seconds={0}
        handleSendCodeToEmail={handleSendCodeToEmail}
        successMessage={""}
      />
    </div>
  );
};

export default Register_Form;
