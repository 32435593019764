import React from "react";
import style from "./mianslider.module.css";
import { Fade } from "react-slideshow-image";
import classNames from "classnames";
import "react-slideshow-image/dist/styles.css";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import { useState } from "react";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebookF, FaYoutube } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const MianSlider = () => {
  const navigate = useNavigate();
  const handleGoTreatment = () => {
    navigate("/treatment");
  };
  const handleGoConatctUs = () => {
    navigate("/freeOPD");
  };
  const [index, setIndex] = useState(0);

  const handleIndChage = (i: number) => {
    setIndex(i);
  };

  const sliderButton = () => {
    return (
      <div className={style.flxcenter}>
        <button className={style.Treatment} onClick={handleGoTreatment}>
          <p>Find Treatments</p>
          <IoArrowForwardCircleSharp className={style.Icon} />
        </button>
        <button className={style.OPd} onClick={handleGoConatctUs}>
          <p>Free OPD</p>
          <IoArrowForwardCircleSharp className={style.Iconm2} />
        </button>
      </div>
    );
  };
  const fadeImages = [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FFirstSlide.jpg?alt=media&token=b6aa9cc1-8b6d-4fc9-8c87-8b7da7f7570b",
      heading: (
        <div className={style.flxCenter}>
          <p className={style.Nature1}>Nature, </p>
          <p className={classNames(style.orange, style.heals)}> Heals</p>
        </div>
      ),
      text: "Nature's Heals and discover the healing power of Pakistan’s natural beauty, where everyone Experiences the synergy of medical excellence and natural beauty in the heart of Pakistan, as we redefine the boundaries of medical tourism and wellness retreats ",
      weight: "500",
      color: "#FF7631",
      family: "Nature",
      botton: sliderButton(),
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FSecondSlide.jpg?alt=media&token=e9ddcf35-223e-4220-9d34-0ebfc8f82e9d",
      heading: (
        <div className={classNames(style.flxh)}>
          <p className={classNames(style.white, style.headingSizetoExcel)}>
            Committed{" "}
          </p>
          <p className={classNames(style.orange, style.headingSizetoExcel)}>
            to Excellence
          </p>
        </div>
      ),
      text: "MediTour Global, excellence is not just a goal, it is our promise to you. We are dedicated to transforming your healthcare journey into a positive, empowering experience, helping you achieve the best possible health outcomes with the utmost care and professionalism. From arranging travel and accommodation to providing cultural and language support, we aim to make your experience as smooth and stress-free as possible. Experience the MediTour difference – where excellence in healthcare meets unparalleled patient care.",
      weight: "400",
      color: "#FF7631",
      family: "Poppins",
      botton: sliderButton(),
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FThirdSlide.jpg?alt=media&token=e06c063c-18e6-4b38-ae20-5f5f581741af",
      heading: (
        <div className={classNames(style.flxh, style.headingSize)}>
          <p className={style.white}>WE</p>
          <p className={style.orange}>CARE</p>
        </div>
      ),
      text: "We want to make sure that our patients travel a great distance to receive medical care and at the same time, they also can enjoy the breathtaking tourist places of PAKISTAN for pleasure. We are committed to high-quality medical care and then recuperating with supervision to a reasonable level before traveling back home. We will be committed to high-quality service and customer satisfaction.",
      weight: "400",
      color: "#FF7631",
      family: "Poppins",
      botton: sliderButton(),
    },
  ];

  return (
    <div className={style.slidecontainer}>
      <Fade
        autoplay={true}
        duration={10000}
        transitionDuration={2000}
        pauseOnHover={false}
        arrows={false}
        onChange={(e) => handleIndChage(e)}
      >
        {fadeImages.map((item: any, idx: number) => (
          <div key={index}>
            <div className={style.imagecontainer}>
              <img
                src={item.image}
                className={style.imgsss}
                alt={`slide ${index}`}
              />
              <div className={style.textoverlay}>
                <p
                  className={style.overlayHeading}
                  style={{ fontFamily: item.family }}
                >
                  {item.heading}
                </p>
                <p
                  className={style.overlaytext}
                  style={{ fontSize: item.size, fontWeight: item.weight }}
                >
                  {item.text}
                </p>
                <div>{item.botton}</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className={style.iconsGroup}>
                    <div className={style.iconsOuter}>
                      <a
                        href="https://www.instagram.com/themeditour.global/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <RiInstagramFill className={style.SocialIcon} />
                      </a>
                    </div>
                    <div className={style.borderright}></div>
                    <a
                      href="https://www.facebook.com/profile.php?id=61560461618333&viewas=&show_switched_toast=false&show_switched_tooltip=false&is_tour_dismissed=false&is_tour_completed=false&show_podcast_settings=false&show_community_review_changes=false&should_open_composer=false&badge_type=NEW_MEMBER&show_community_rollback_toast=false&show_community_rollback=false&show_follower_visibility_disclosure=false&bypass_exit_warning=true"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <FaFacebookF className={style.SocialIcon} />
                    </a>
                    <div className={style.borderright}></div>
                    <a
                      href="https://www.youtube.com/@themeditour.global"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaYoutube className={style.SocialIcon} />
                    </a>
                  </div>

                  <div className={style.dotsGroup}>
                    <div className={style.dots}>
                      <div
                        className={index === 2 ? style.dotsFillActive : ""}
                      ></div>
                    </div>
                    <div className={style.borderright}></div>
                    <div className={style.dots}>
                      <div
                        className={index === 0 ? style.dotsFillActive : ""}
                      ></div>
                    </div>
                    <div className={style.borderright}></div>{" "}
                    <div className={style.dots}>
                      <div
                        className={index === 1 ? style.dotsFillActive : ""}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Fade>
    </div>
  );
};

export default MianSlider;
