import React, { useEffect } from "react";

import { Route, Routes, useNavigate } from "react-router-dom";
import { SideBar } from "shared/components";
import Chat from "shared/components/Chat";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
import { Doctor_Menu } from "shared/utils";
import {
  AddTreatment,
  DoctorAppointmentDetails,
  DoctorAppointments,
  DoctorAvailability,
  DoctorAvailabilityDetail,
  DoctorDashboard,
  DoctorHospitalAvailability,
  DoctorPatientHistory,
  DoctorPatientHistoryDetails,
  DoctorPaymentDetails,
  DoctorPayments,
  DoctorProfile,
  DoctorTreatments,
} from "pages";
import { useSelector } from "react-redux";
const Doc_Main_Routes = (props: any) => {
  const { systemType } = useSelector((state: any) => state.root.common);

  const navigate = useNavigate();
  useEffect(() => {
    navigate(`/${systemType}/dashboard`);
  }, []);
  let Menu = Doctor_Menu(systemType);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: "0px",
          height: "100vh",
        }}
      >
        <SideBar menuItem={Menu} />
      </div>
      <Routes>
        <Route
          path={`/${systemType}/dashboard`}
          element={<DoctorDashboard />}
        />
        <Route
          path={`/${systemType}/availability`}
          element={<DoctorAvailability />}
        />
        <Route
          path={`/${systemType}/treatments`}
          element={<DoctorTreatments />}
        />
        <Route
          path={`/${systemType}/addTreatment`}
          element={<AddTreatment />}
        />
        <Route
          path={`/${systemType}/appointment`}
          element={<DoctorAppointments />}
        />
        <Route
          path={`/${systemType}/appointmentDetails`}
          element={<DoctorAppointmentDetails />}
        />
        <Route
          path={`/${systemType}/availability/Clinic`}
          element={<DoctorAvailabilityDetail />}
        />
        <Route
          path={`/${systemType}/availability/inHouse`}
          element={<DoctorAvailabilityDetail />}
        />
        <Route
          path={`/${systemType}/availability/hospital`}
          element={<DoctorHospitalAvailability />}
        />
        <Route
          path={`/${systemType}/availability/hospital/detail`}
          element={<DoctorAvailabilityDetail />}
        />
        <Route
          path={`/${systemType}/availability/videoConsultancy`}
          element={<DoctorAvailabilityDetail />}
        />
        <Route
          path={`/${systemType}/patientHistory`}
          element={<DoctorPatientHistory />}
        />
        <Route
          path={`/${systemType}/patientHistory/details`}
          element={<DoctorPatientHistoryDetails />}
        />
        <Route path={`/${systemType}/payments`} element={<DoctorPayments />} />
        <Route
          path={`/${systemType}/paymentDetails`}
          element={<DoctorPaymentDetails />}
        />
        <Route
          path={`/${systemType}/doctorProfile`}
          element={<DoctorProfile />}
        />
      </Routes>
    </div>
  );
};

export default Doc_Main_Routes;
