import React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import style from "./PatientHistory.module.css";
import SearchBar from "shared/components/Searchbar";
import {
  DocGetAllPateints,
  getAllPatients,
} from "../../../shared/services/DoctorService";
import PatientHistoryTable from "shared/components/DoctorTables/patientHistoryTable";
import { useNavigate } from "react-router-dom";
import PerviousNextButton from "shared/components/Pagination";
import { LoadingModal, RingLoader } from "shared/components";
import Pagination from "shared/components/Pagination/Pagination";
import { TbRefresh } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import {
  setDoctorPatientHistory,
  setDoctorPatientHistoryFlag,
  setDoctorPatientLength,
} from "shared/redux";
import NewPagination from "shared/components/NewPagination/NewPagination";
function DoctorPatientHistory() {
  const dispatch = useDispatch();
  const {
    doctorPatientHistory,
    doctorPatientLength,
    doctorPatientHistoryFlag,
  } = useSelector((state: any) => state.root.doctor);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = doctorPatientLength;
  const navigate = useNavigate();
  const { systemType } = useSelector((state: any) => state.root.common);

  const fetchPatienHistory = (page: number) => {
    setLoading(true);

    DocGetAllPateints(page)
      .then((res: any) => {
        console.log("Doctor...........................................", res);
        if (res?.data?.Patients.length > 0) {
          dispatch(setDoctorPatientHistory(res?.data?.Patients));
          dispatch(setDoctorPatientLength(res.data.patientsLength));
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (doctorPatientLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      fetchPatienHistory(currentPage + 1);
      // dispatch(setDoctorPatientHistoryFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      fetchPatienHistory(currentPage - 1);
      // dispatch(setDoctorPatientHistoryFlag(true));
    }
  };
  useEffect(() => {
    fetchPatienHistory(1);
  }, []);
  const handleRefresh = (): void => {
    setPageno(1);
    fetchPatienHistory(1);
  };

  const GoToDetail = (id: string) => {
    navigate(`/${systemType}/patientHistory/details`, {
      state: { id },
    });
  };
  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <div
            className={classNames(commonstyles.mb32, commonstyles.flxBetween)}
          >
            <div className={commonstyles.flx}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                All Patient
              </p>
              <div className={style.outerRefresh}>
                {loading ? (
                  <div>
                    <RingLoader color={"#0D47A1"} size={24} />
                  </div>
                ) : (
                  <TbRefresh
                    className={style.Refresh}
                    onClick={handleRefresh}
                  />
                )}
              </div>
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>

          <div>
            <PatientHistoryTable
              patientHistory={doctorPatientHistory}
              GoToDetail={GoToDetail}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorPatientHistory;
