import React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import { getAllPatients } from "../../../../shared/services/DoctorService";
import style from "./PatientHistory.module.css";
import PatientHistoryTable from "shared/components/DoctorTables/patientHistoryTable";
import { useNavigate } from "react-router-dom";
import HospitalPatientTable from "shared/components/HospitalTables/HospitalPatienttable";
import PerviousNextButton from "shared/components/Pagination";
import Pagination from "shared/components/Pagination/Pagination";
import { hospitalgetPatients } from "shared/services/HospitalService";
import { useDispatch, useSelector } from "react-redux";
import {
  setHosPatientHistory,
  setHosPatientHistoryRenderFlag,
  setHosPatientLength,
} from "shared/redux";
import { TbRefresh } from "react-icons/tb";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { LoadingModal } from "shared/components";
function PatientHistory() {
  const dispatch = useDispatch();
  const { hosPatientHistory, hosPatientLength, hosPatientHistoryRenderFlag } =
    useSelector((state: any) => state.root.hospital);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = hosPatientLength;

  const fetchPatienHistory = (pagono: number) => {
    setLoading(true);

    hospitalgetPatients(pageno)
      .then((res: any) => {
        console.log(
          "hospitalgetPatients...........................................",
          res
        );
        if (res?.data?.Patients.length > 0) {
          dispatch(setHosPatientHistory(res?.data?.Patients));
          dispatch(setHosPatientLength(res.data.patientsLength));
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (hosPatientLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setHosPatientHistoryRenderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setHosPatientHistoryRenderFlag(true));
    }
  };
  useEffect(() => {
    if (hosPatientHistoryRenderFlag) {
      setLoading(true);
      fetchPatienHistory(currentPage);
      dispatch(setHosPatientHistoryRenderFlag(false));
    }
  }, [hosPatientHistoryRenderFlag, currentPage]);
  const handleRotate = (): void => {
    setRotation(rotation - rotationIncrement);
    fetchPatienHistory(currentPage);
  };

  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <div
            className={classNames(commonstyles.mb32, commonstyles.flxBetween)}
          >
            <div className={commonstyles.flx}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                All Patient
              </p>
              <div className={style.outerRefresh}>
                <TbRefresh
                  className={style.RefreshIcon}
                  style={{ transform: `rotate(${rotation}deg)` }}
                  onClick={handleRotate}
                />
              </div>
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          {loading ? (
            <LoadingModal showModal={loading} />
          ) : (
            <>
              <div>
                <HospitalPatientTable patientHistory={hosPatientHistory} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PatientHistory;
