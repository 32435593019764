import React, { useState } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import style from "./filght.module.css";
import commonstyles from "shared/utils/common.module.css";
import OnewayImg from "assets/images/OneWay.png";
import TwoWayImg from "assets/images/TwoWay.png";
import MultiWayImg from "assets/images/MultiWay.png";
import MultiWay from "./MultiWay";
import OneWay from "./OneWay";
import TwoWay from "./TwoWay";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function FlightCategory() {
  const navigate = useNavigate(); // Declare once
  const handleGoOneWay = () => {
    navigate("/travelAgency/Travel/oneWay");
  };

  const handleGoBack = () => {
    navigate("/travelAgency/TravelingPlan");
  };

  const handleGoTwoWay = () => {
    navigate("/travelAgency/Travel/TwoWay");
  };
  const handleGoMultiWay = () => {
    navigate("/travelAgency/Travel/MultiWay");
  };

  return (
    <div className={classNames(commonstyles.col12, style.doctorss)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <div className={commonstyles.flx}>
            <div>
              <IoArrowBack className={style.back} onClick={handleGoBack} />
            </div>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Traveling Plan / Flights
            </p>
          </div>
          <div className={commonstyles.flx}>
            <div className={style.OneWayCard} onClick={handleGoOneWay}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                One Way Trip
              </p>
              <p className={classNames(commonstyles.fs16, style.mt16)}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
              <div>
                <img src={OnewayImg} className={style.CardImges} />
              </div>
            </div>
            <div className={style.TwoWayCard} onClick={handleGoTwoWay}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Two Way Trip
              </p>
              <p className={classNames(commonstyles.fs16, style.mt16)}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
              <div>
                <img src={TwoWayImg} className={style.CardImges} />
              </div>
            </div>
            <div className={style.MultiWayCard} onClick={handleGoMultiWay}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Multi Way Trip
              </p>
              <p className={classNames(commonstyles.fs16, style.mt16)}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
              <div>
                <img src={MultiWayImg} className={style.CardImges} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
