import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const paraSignup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PARAMEDIC_SIGNUP, params);
};

export const paraSendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PARAMEDIC_SEND_CODE_TO_EMAIL, params);
};

export const paraVerifyEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PARAMEDIC_CONFIRM_EMAIL, params);
};
export const paraCompleteSignup = (params: any, id: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.PARAMEDIC_COMPLETE_SIGNUP}?id=${id}`,
    params
  );
};

export const paraLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PARAMEDIC_LOGIN, params);
};

export const resetLinkPara = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PARAMEDIC_RESET_LINK, params);
};

export const newPasswordPara = (params: any, token: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.PARAMEDIC_NEWPASSWORD}?token=${token}`,
    params
  );
};

export const paraLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.PARAMEDIC_LOGOUT);
};
export const paraGetAllRequest = (pagenoo: number) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.PARAMEDIC_GETALLREQUEST}?page=${pagenoo}`
  );
};
export const paraAcceptRequest = (id: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.PARAMEDIC_ACCEPTREQUEST}?bookingId=${id}`
  );
};

export const paraRejectRequest = (id: string) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.PARAMEDIC_REJECTREQUEST}?bookingId=${id}`
  );
};

export const paraGetALLAppointments = (pageno: number) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.PARAMEDIC_GETALLAPPOINTMENT}?page=${pageno}`
  );
};

export const getAllPatientHistory = (pageno: number) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.PARAMEDIC_GETALLPATIENTS}?page=${pageno}`
  );
};

export const parsPatientHistory = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.PARAMEDIC_PATIENTHISTORY}?id=${id}`);
};

export const paraGraphDETAILSUpperPortion = (dur: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.PARAMEDIC_GRAPHDETAILS}?duration=${dur}`);
};

export const paraGRAPH = () => {
  return HTTP_CLIENT.get(ENDPOINTS.PARAMEDIC_GRAPHHHH);
};

export const paraGetAvailibility = () => {
  return HTTP_CLIENT.get(ENDPOINTS.PARAMEDIC_GETAVAILIBILITY);
};

export const paraAddAvailability = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PARAMEDIC_ADDAVAILABILITY, params);
};

export const paraUpdateProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.PARAMEDIC_UPDATEPROFILE, params);
};

export const PeraGetSingleAppointment = (id: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.PARAMEDIC_GETSINGLEAPPOINMENT}?appointmentId=${id}`
  );
};
export const PeraAddAppointmentHistory = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PARAMEDIC_ADDHISTORY, params);
};
export const PeraAddAppointmentPrescription = (
  id: string,
  patientid: string,
  params: any
) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.PARAMEDIC_ADDPRESCRIPTION}?appointmentId=${id}&patientId=${patientid}`,
    params
  );
};
export const PeraReferPatientToDoctor = (id: string, patientid: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.PARAMEDIC_REFERDOCTOR}?referredDoctorId=${id}&patientId=${patientid}`
  );
};
export const PeragetDoctorSearch = (searchInput: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.PARAMEDIC_SEARCHDOCTOR}?name=${searchInput}`
  );
};
export const PeraGetPrescription = (appointmentId: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.PARAMEDIC_GETPRESCRIPTION}?prescriptionId=${appointmentId}`
  );
};
export const PeraGetAllHospitals = () => {
  return HTTP_CLIENT.get(ENDPOINTS.PARAMEDIC_GETALLHOSPITALS);
};
export const PeraGetSingleHospitalAvailability = (id: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.PARAMEDIC_GETSINGLEHOSPITALAVAILABILITY}?hospitalId=${id}`
  );
};
