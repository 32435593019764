import React, { useState } from "react";
import { ArrowBack, CustomStepper, PrimaryButton } from "shared/components";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import mainMedicalService from "../mainMedicalService.module.css";
import HospitalBasicInfo from "./BasicInfo";
import HospitalSocial from "./Social";
import HospitalBankDetail from "./BankDetail";
import HospitalVerification from "./Verification";
import { useNavigate } from "react-router-dom";
import Logo from "assets/images/Hospital 1.png";
import { IoArrowBack } from "react-icons/io5";

const steps = [
  {
    id: "1",
    lable: "Hospital Info",
  },
  {
    id: "2",
    lable: "Social",
  },
  {
    id: "3",
    lable: "Bank Details",
  },
  {
    id: "4",
    lable: "Verification",
  },
];
const Hospital_Signup = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/hospital/login");
  };

  const [screenName, setScreenName] = useState("HospitalInfo");
  const [selectedStep, setSelectedStep] = useState(0);

  const handleClickNext = () => {
    if (screenName == "HospitalInfo") {
      setScreenName("Social");
    }

    if (screenName == "Social") {
      setScreenName("BankDetail");
    }

    if (screenName == "BankDetail") {
      setScreenName("Verification");
    }
    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "HospitalInfo") {
      handleGoBack();
    }
    if (screenName == "Social") {
      setScreenName("HospitalInfo");
    }

    if (screenName == "BankDetail") {
      setScreenName("Social");
    }

    if (screenName == "Verification") {
      setScreenName("BankDetail");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };
  return (
    <div
      className={classNames(mainMedicalService.flxSign, commonStyles.container)}
    >
      <div
        className={classNames(
          commonStyles.col7,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <div className={classNames(commonStyles.flx)}>
          <div className={classNames(commonStyles.mr24)}>
            <IoArrowBack
              onClick={handleClicKPrev}
              className={mainMedicalService.BackIcon}
            />
          </div>

          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs14,
              mainMedicalService.mr203,
              commonStyles.colorBlue
            )}
          >
            Previous
          </p>

          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs24,
              commonStyles.m0
            )}
          >
            Sign Up
          </p>
        </div>
        <div className={classNames(commonStyles.col11, commonStyles.mt40)}>
          <CustomStepper steps={steps} selectedStep={selectedStep} />
        </div>
        <div className={classNames(commonStyles.col12)}>
          {screenName == "HospitalInfo" && (
            <HospitalBasicInfo handleClickNext={handleClickNext} />
          )}
          {screenName == "Social" && (
            <HospitalSocial handleClickNext={handleClickNext} />
          )}
          {screenName == "BankDetail" && (
            <HospitalBankDetail handleClickNext={handleClickNext} />
          )}
          {screenName == "Verification" && (
            <HospitalVerification handleClickNext={handleClickNext} />
          )}
        </div>
      </div>
      <div
        className={classNames(
          commonStyles.col4,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <img src={Logo} className={commonStyles.loginVector} />
      </div>
    </div>
  );
};

export default Hospital_Signup;
