import React from "react";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import empty from "assets/images/empty2.png";
import style from "../Oneway.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import styles from "../Oneway.module.css";
interface Props {
  flights?: any;
  trips?: any;
}
export default function OnWayTable(props: Partial<Props>) {
  const { flights, trips } = props;
  const navigate = useNavigate();
  const handleGoToDetail = () => {
    navigate("/flightDetail");
  };
  return (
    <div className={classNames(style.App)}>
      {flights.length > 0 ? (
        <div className={styles.payment}>
          <div className={styles.headerOuter}>
            <p className={styles.headerclass}>Depart</p>
            <p className={styles.headerclass}>From</p>
            <p className={styles.headerclass}>Depart Time</p>
            <p className={styles.headerclass}>To</p>
            <p className={styles.headerclass}>Arrival Time</p>
            <p className={styles.headerclass}>Actual Price</p>
            <p className={styles.headerclass}>Meditour Price</p>
            <p className={styles.headerclass}>Class</p>
          </div>

          <div className={styles.tableData}>
            <table
              style={{
                margin: "0px",
                // borderCollapse: "separate",
                // borderSpacing: "0 10px",
              }}
            >
              <tbody className={styles.wapper}>
                {flights.map((val: any, key: any) => {
                  return (
                    <tr
                      className={style.tableRow}
                      key={key}
                      onClick={handleGoToDetail}
                    >
                      <td className={style.w20}>{val?.updatedAt}</td>
                      <td className={style.w20}>{val?.Form}</td>
                      <td className={style.w20}>{val?.DepartTime}</td>
                      <td className={style.w20}>{val?.To}</td>
                      <td className={style.w20}>{val?.Arrival}</td>
                      <td className={style.w20}>{val?.ActualPrice}</td>
                      <td className={style.w20}>{val?.MeditourPrice}</td>
                      <td className={style.w20}>{val?.Class}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className={classNames(styles.center)}>
          <img src={empty} className={classNames(styles.empty3)} />
        </div>
      )}
    </div>
  );
}
