import AmbulanceForget from "pages/HomeServices/AmbulanceServices/Forgetpassword/AmbulanceForget";
import AmbulanceUpdatePassword from "pages/HomeServices/AmbulanceServices/Forgetpassword/AmbulanceUpdatePassword";
import Ambulancelogin from "pages/HomeServices/AmbulanceServices/login";
import AmbulanceSignup from "pages/HomeServices/AmbulanceServices/signup";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

const Ambulanceauth = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<Ambulancelogin />} />
      <Route path="/signup" element={<AmbulanceSignup />} />
      <Route path="/forgot-password" element={<AmbulanceForget />} />
      <Route path="/update-password" element={<AmbulanceUpdatePassword />} />
    </Routes>
  );
};

export default Ambulanceauth;
