import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { Wrapper } from "shared/components";
import { Home } from "pages";
import LaboratoryAuthRoutes from "./Laboratory/authRoutes";
import LaboratoryMainRoutes from "./Laboratory/mainRoutes";
import PharmacyAuthRoutes from "./Pharmacy/authRoutes";
import PharmacyMainRoutes from "./Pharmacy/MainRoutes";
import HomeServicesAuthRoutes from "./HomeServices";
import TravelTourismAuthRoutes from "./TravelTourism/traveltourism";
import DonationAuthRoutes from "./Donation/Donationauthroutes";
import InsuranceAuthRoutes from "./Insurance/InsuranAuthRoutes";
import { useDispatch, useSelector } from "react-redux";
import HospitalMainRoutes from "./Hospital_Routes/hospital_main";
import AmbulanceMainRoutes from "./HomeServices/AmbulanceRoutes/AmbulanceMainRoutes";
import ParamedicStaffMainRoutes from "./HomeServices/ParamedicStaffRoutes/ParamedicStaffMain";
import InsuranceMainRoutes from "./Insurance/InsuranceMianRoutes";
import DonationMainRoutes from "./Donation/DonationMainRoutes";
import RentACarMainRoutes from "./TravelTourism/RentCar/RentCarMainRoutes";
import HotelMainRoutes from "./TravelTourism/Hotel/HotelMainRoutes";
import TravelAgencyMainRoutes from "./TravelTourism/TravelAgency/TravelAgencyMain";
import RefundPolicy from "shared/components/PrivacyPolicy/RefundPolicy";
import PrivactPolicy2 from "shared/components/PrivacyPolicy/PrivactPolicy";
import LaboratoryAgreement from "shared/components/PrivacyPolicy/LaboratoryAgreement";
import TermsofUse from "shared/components/PrivacyPolicy/Terms of Use";
import Vender from "pages/Home/HomeNavBar/JoinVender";
import AdminLogin from "pages/AdminPanel/Login";
import AdminMianRoutes from "pages/AdminPanel";
import OurSevices from "pages/Home/HomeNavBar/OurServices";
import AboutUs from "pages/Home/HomeNavBar/AboutUs";
import ContactUs from "pages/Home/ContactUs";
import UserLogin from "pages/Home/Userlogin";
import Treatment from "pages/Home/HomeNavBar/Treatment";
import PatientGuide from "pages/Home/HomeNavBar/PatientGuide";
import TreatmentDetails from "pages/Home/HomeNavBar/Treatment/TreatmentDetails";
import FreeOpd from "pages/Home/FreeOpd";
import PrivactPolicys from "pages/Home/HomeNavBar/PrivactPolicy";
import FAQpage from "pages/Home/HomeNavBar/FAQ'Spage";
import Doc_Auth_Routes from "./Doctor_Routes/doc_auth";
import Doc_Main_Routes from "./Doctor_Routes/doc_main";
import { setIsLoggedIn, setToken, setUser } from "shared/redux";
import Hospital_Auth_Routes from "./Hospital_Routes/hospital_auth";
import PharmaceuticalAuthRoutes from "./Pharmaceutical/authRoutes";
import Pharmaceutical_MainRoutes from "./Pharmaceutical/MainRoutes";
import UserAuthRoutes from "./UserLogin/UserLoginAuth";
import ServiceRoutes from "./Services_Routes/ServiceRoutes";

const AppRoutes = (props: any) => {
  const { isLoggedIn } = useSelector((state: any) => state.root.common);
  const { logOutFlag, setLogOutFlag } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Router>
      {isLoggedIn ? (
        <AfterLogin logOutFlag={logOutFlag} setLogOutFlag={setLogOutFlag} />
      ) : (
        <BeforLogin />
      )}
    </Router>
  );
};

export default AppRoutes;
const BeforLogin = () => {
  const { systemType } = useSelector((state: any) => state.root.common);
  return (
    <Wrapper>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="laboratory/*" element={<LaboratoryAuthRoutes />} />
        <Route path="pharmacy/*" element={<PharmacyAuthRoutes />} />
        <Route path="pharmaceutical/*" element={<PharmaceuticalAuthRoutes />} />

        {systemType === "doctor" && (
          <Route path={`${systemType}/*`} element={<Doc_Auth_Routes />} />
        )}
        {systemType === "physiotherapist" && (
          <Route path={`${systemType}/*`} element={<Doc_Auth_Routes />} />
        )}
        {systemType === "nutritionist" && (
          <Route path={`${systemType}/*`} element={<Doc_Auth_Routes />} />
        )}

        {systemType === "psychologist" && (
          <Route path={`${systemType}/*`} element={<Doc_Auth_Routes />} />
        )}
        {systemType === "hospital" && (
          <Route path={`hospital/*`} element={<Hospital_Auth_Routes />} />
        )}
        {/* <Route path="hospital/*" element={<Hospital_Auth_Routes />} /> */}
        <Route path="homeservices/*" element={<HomeServicesAuthRoutes />} />
        <Route path="traveltourism/*" element={<TravelTourismAuthRoutes />} />
        <Route path="donation/*" element={<DonationAuthRoutes />} />
        {systemType == "insurance" && (
          <Route path="insurance/*" element={<InsuranceAuthRoutes />} />
        )}

        {/* <Route path="refundPolicy/" element={<RefundPolicy />} />
        <Route path="privacyPolicies/" element={<PrivactPolicy2 />} />
        <Route path="laboratoryAgreement/" element={<LaboratoryAgreement />} />
        <Route path="termsofUse/" element={<TermsofUse />} /> */}
        <Route path="joinVender/" element={<Vender />} />
        <Route path="ourSevices/*" element={<OurSevices />} />
        <Route path="services/*" element={<ServiceRoutes />} />
        <Route path="aboutUs/" element={<AboutUs />} />
        <Route path="FAQs/" element={<FAQpage />} />
        <Route path="contactUs/" element={<ContactUs />} />
        <Route path="user/*" element={<UserAuthRoutes />} />
        <Route path="treatment/" element={<Treatment />} />
        <Route path="privactpolicys/" element={<PrivactPolicys />} />
        <Route path="freeOPD/" element={<FreeOpd />} />
        <Route path="treatment/Details" element={<TreatmentDetails />} />
        <Route path="patientGuide/" element={<PatientGuide />} />
        <Route path="admin/login" element={<AdminLogin />} />
      </Routes>
    </Wrapper>
  );
};

const AfterLogin = (props: any) => {
  const { systemType } = useSelector((state: any) => state.root.common);
  const { logOutFlag, setLogOutFlag } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (logOutFlag) {
      handleLogout();
    }
  }, [logOutFlag]);

  const handleLogout = () => {
    dispatch(setIsLoggedIn(false));
    dispatch(setToken(null));
    dispatch(setUser(null));
    navigate(`/${systemType}/login`);
    setLogOutFlag(false);
  };
  return (
    <>
      {systemType === "laboratory" && <LaboratoryMainRoutes />}
      {systemType === "pharmacy" && <PharmacyMainRoutes />}
      {systemType === "hospital" && <HospitalMainRoutes />}
      {systemType === "doctor" && <Doc_Main_Routes systemType={systemType} />}
      {systemType === "ambulance" && <AmbulanceMainRoutes />}
      {systemType === "physiotherapist" && (
        <Doc_Main_Routes systemType={systemType} />
      )}
      {systemType === "nutritionist" && (
        <Doc_Main_Routes systemType={systemType} />
      )}
      {systemType === "paramedic" && <ParamedicStaffMainRoutes />}
      {systemType === "psychologist" && (
        <Doc_Main_Routes systemType={systemType} />
      )}
      {systemType === "hotel" && <HotelMainRoutes />}
      {systemType === "rentacar" && <RentACarMainRoutes />}
      {systemType === "travelagency" && <TravelAgencyMainRoutes />}
      {systemType === "donation" && <DonationMainRoutes />}
      {systemType === "insurance" && <InsuranceMainRoutes />}
      {systemType === "admin" && <AdminMianRoutes />}
      {systemType === "pharmaceutical" && <Pharmaceutical_MainRoutes />}
    </>
  );
};
