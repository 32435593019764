import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonStyles from "shared/utils/common.module.css";
import labMainStyles from "../../laboratoryMain.module.css";
import { ArrowBack, CustomInput, PrimaryButton } from "shared/components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { labInfoSchema } from "shared/utils";
import { useDispatch, useSelector } from "react-redux";
import { setLabUserFormData } from "shared/redux";
import ImgPicker from "shared/components/Img-picker";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";
import LocationInput from "shared/components/LocationInput";
import Datepicker from "shared/components/DatePicker";
import dayjs from "dayjs";

interface Props {
  handleClickNext: any;
}
const LabInfo = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const dispatch = useDispatch();
  const { labUserFormData } = useSelector((state: any) => state.root.lab);
  const [logoError, setLogoError] = useState(false);
  const [licenseError, setLicenseError] = useState(false);
  const [cnicError, setCnicError] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      labLogo: "",
      labLicenseNumber: "",
      labLicenseImage: "",
      labLicenseExpiry: "",
      labOwnerFirstName: "",
      labOwnerLastName: "",
      labEmergencyNumber: "",
      labCnicNumber: "",
      labCnicImage: "",
      labCnicExpiryDate: "",
      address: "", //////////////
      lat: "", /////////////
      lng: "", /////////////
      city: "", /////////////
      labDescription: "",
      labOpenTime: "",
      labCloseTime: "",
    },
    validationSchema: Yup.object(labInfoSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleLabLogoUrl = (url: any) => {
    formik.setFieldValue("labLogo", url);
  };

  const handlelicenseUrl = (url: any) => {
    formik.setFieldValue("labLicenseImage", url);
  };

  const handleLicenseExpiry = (date: any) => {
    const formattedDate = date.toISOString().split("T")[0];
    console.log("handleLicenseExpiry", formattedDate);
    formik.setFieldValue("labLicenseExpiry", formattedDate);
  };

  const handleCnicUrl = (url: any) => {
    formik.setFieldValue("labCnicImage", url);
  };

  const handleSetAddressData = (value: any) => {
    // console.log("CITYYY................", value.city);
    // console.log("LAT................", value.lat);
    // console.log("LONG..........", value.lng);
    // console.log("LABEL.............", value.label);
    formik.setFieldValue("address", value.label);
    formik.setFieldValue("lat", value.lat);
    formik.setFieldValue("lng", value.lng);
    formik.setFieldValue("city", value.city);
  };

  // formik.setFieldValue("location", url);
  const handleLabOpenTime = (time: any) => {
    console.log("handleLabOpenTime", time);
    const formattedTime = dayjs(time).format("hh:mm A");
    console.log("Setting", formattedTime);
    formik.setFieldValue("labOpenTime", formattedTime);
  };
  const handleLabCloseTime = (time: any) => {
    const formattedTime = dayjs(time).format("hh:mm A");
    console.log("Setting", formattedTime);
    formik.setFieldValue("labCloseTime", formattedTime);
  };
  const handleLabEpiryDate = (date: any) => {
    const formattedDate = date.toISOString().split("T")[0];
    console.log("labCnicExpiryDate", formattedDate);
    formik.setFieldValue("labCnicExpiryDate", formattedDate);
  };
  useEffect(() => {
    formik.setValues(labUserFormData);
  }, []);

  const handleSubmit = async () => {
    console.log("Submit", formik.values);
    if (!formik.values.labLogo) {
      setLogoError(true);
    } else if (!formik.values.labLicenseImage) {
      setLicenseError(true);
    } else if (!formik.values.labCnicImage) {
      setCnicError(true);
    } else {
      await dispatch(setLabUserFormData(formik.values));
      handleClickNext();
      setLogoError(false);
      setLicenseError(false);
      setCnicError(false);
    }
  };

  return (
    <div className={classNames()}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, labMainStyles.flx)}>
          <div
            className={classNames(
              commonStyles.col8,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Lab Full Name"
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              {formik.touched.name && formik.errors.name ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.name}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <ImgPicker placeholder="Lab Logo" setData={handleLabLogoUrl} />
              {formik.touched.labLogo && formik.errors.labLogo ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.labLogo}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, labMainStyles.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Laboratory License Number"
                id="labLicenseNumber"
                name="labLicenseNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.labLicenseNumber}
              />
              {formik.touched.labLicenseNumber &&
              formik.errors.labLicenseNumber ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.labLicenseNumber}
                </div>
              ) : null}
            </div>
          </div>

          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <ImgPicker
                placeholder="License image "
                setData={handlelicenseUrl}
              />
              {formik.touched.labLicenseImage &&
              formik.errors.labLicenseImage ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.labLicenseImage}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <Datepicker
                placeholder="License Expiry "
                setData={handleLicenseExpiry}
                value={dayjs(formik.values.labLicenseExpiry)}
              />
              {formik.touched.labLicenseExpiry &&
              formik.errors.labLicenseExpiry ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.labLicenseExpiry}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, labMainStyles.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Owner First Name"
                id="labOwnerFirstName"
                name="labOwnerFirstName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.labOwnerFirstName}
              />
              {formik.touched.labOwnerFirstName &&
              formik.errors.labOwnerFirstName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.labOwnerFirstName}
                </div>
              ) : null}
            </div>
          </div>

          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Owner Last Name"
                id="labOwnerLastName"
                name="labOwnerLastName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.labOwnerLastName}
              />
              {formik.touched.labOwnerLastName &&
              formik.errors.labOwnerLastName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.labOwnerLastName}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Emergency No."
                id="labEmergencyNumber"
                name="labEmergencyNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.labEmergencyNumber}
              />
              {formik.touched.labEmergencyNumber &&
              formik.errors.labEmergencyNumber ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.labEmergencyNumber}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, labMainStyles.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="CNIC / Passport Number"
                id="labCnicNumber"
                name="labCnicNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.labCnicNumber}
              />
              {/* {formik.touched.labCnicNumber && formik.errors.labCnicNumber ? ( */}
                <div className={classNames(commonStyles.error)}>
                  {/* *{formik.errors.labCnicNumber} */}
                </div>
              {/* ) : null} */}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <ImgPicker
                placeholder="CNIC / Passport Image "
                setData={handleCnicUrl}
              />
              {/* {formik.touched.labCnicImage && formik.errors.labCnicImage ? ( */}
                <div className={classNames(commonStyles.error)}>
                  {/* *{formik.errors.labCnicImage} */}
                </div>
              {/* ) : null} */}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <Datepicker
                placeholder="CNIC/Passport Expiry "
                setData={handleLabEpiryDate}
                value={dayjs(formik.values.labCnicExpiryDate)}
              />
              {formik.touched.labCnicExpiryDate &&
              formik.errors.labCnicExpiryDate ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.labCnicExpiryDate}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div
          className={classNames(
            commonStyles.mb16,
            labMainStyles.flx,
            commonStyles.col12
          )}
        >
          <div
            className={classNames(
              commonStyles.col12,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <LocationInput
                placeholder="Location"
                setData={handleSetAddressData}
                
              />
              {formik.touched.address && formik.errors.address ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.address}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.mb16,
            commonStyles.col12
          )}
        >
          <div
            className={classNames(
              commonStyles.col12,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Description"
                id="labDescription"
                name="labDescription"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.labDescription}
              />
              {formik.touched.labDescription && formik.errors.labDescription ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.labDescription}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(labMainStyles.flx, commonStyles.col12)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <CustomTimePicker
                placeholder="Lab Open Time"
                setData={handleLabOpenTime}
                value={formik.values.labOpenTime}
              />
              {formik.touched.labOpenTime && formik.errors.labOpenTime ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.labOpenTime}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <CustomTimePicker
                placeholder="Lab Close Time"
                setData={handleLabCloseTime}
                value={formik.values.labCloseTime}
              />
              {formik.touched.labCloseTime && formik.errors.labCloseTime ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.labCloseTime}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className={classNames(labMainStyles.buttonWidth)}>
          <PrimaryButton children={"Next"} type="submit" />
        </div>
      </form>
    </div>
  );
};

export default LabInfo;
