import React from "react";
import style from "./PhyAvailability.module.css";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaClinicMedical } from "react-icons/fa";
import { FaHospitalSymbol } from "react-icons/fa";
import { IoVideocam } from "react-icons/io5";
import { FaHouseChimney } from "react-icons/fa6";

function DoctorAvailability() {
  const navigate = useNavigate();
  const { systemType } = useSelector((state: any) => state.root.common);

  const handleGoToDetail = (type: any) => {
    let availabilityType =
      type === "Clinic"
        ? "clinic"
        : type === "inHouse"
        ? "in-house"
        : type === "hospital"
        ? "hospital"
        : type === "videoConsultancy"
        ? "video"
        : "";

    let availabilityTitle =
      type === "Clinic"
        ? "Clinic"
        : type === "inHouse"
        ? "In-house"
        : type === "hospital"
        ? "Hospital"
        : type === "videoConsultancy"
        ? "Video Consultancy"
        : "";
    navigate(`/${systemType}/availability/${type}`, {
      state: { availabilityType, availabilityTitle },
    });
  };

  return (
    <div className={classNames(commonstyles.col12, style.doctorss)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <div className={commonstyles.flx}>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Availability category
            </p>
          </div>
          <div className={style.flxWrap}>
            <div
              className={style.cardContainer}
              onClick={() => handleGoToDetail("Clinic")}
            >
              <div className={style.cardIconContainer}>
                <FaClinicMedical className={style.cardIcon} />
              </div>

              <div className={style.textContainer}>
                <p
                  className={classNames(
                    commonstyles.fs28,
                    commonstyles.semiBold,
                    style.cardHeading
                  )}
                >
                  Clinic
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.medium,
                    style.cardSubHeading
                  )}
                >
                  Add your clinic availability
                </p>
              </div>
            </div>

            <div
              className={style.cardContainer}
              onClick={() => handleGoToDetail("inHouse")}
            >
              <div className={style.cardIconContainer}>
                <FaHouseChimney className={style.cardIcon} />
              </div>

              <div className={style.textContainer}>
                <p
                  className={classNames(
                    commonstyles.fs28,
                    commonstyles.semiBold,
                    style.cardHeading
                  )}
                >
                  In-House
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.medium,
                    style.cardSubHeading
                  )}
                >
                  Add your In-House availability
                </p>
              </div>
            </div>

            <div
              className={style.cardContainer}
              onClick={() => handleGoToDetail("hospital")}
            >
              <div className={style.cardIconContainer}>
                <FaHospitalSymbol className={style.cardIcon} />
              </div>

              <div className={style.textContainer}>
                <p
                  className={classNames(
                    commonstyles.fs28,
                    commonstyles.semiBold,
                    style.cardHeading
                  )}
                >
                  Hospital
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.medium,
                    style.cardSubHeading
                  )}
                >
                  Add your Hospital availability
                </p>
              </div>
            </div>

            <div
              className={style.cardContainer}
              onClick={() => handleGoToDetail("videoConsultancy")}
            >
              <div className={style.cardIconContainer}>
                <IoVideocam className={style.cardIcon} />
              </div>

              <div className={style.textContainer}>
                <p
                  className={classNames(
                    commonstyles.fs28,
                    commonstyles.semiBold,
                    style.cardHeading
                  )}
                >
                  Video-Consultancy
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.medium,
                    style.cardSubHeading
                  )}
                >
                  Add your availability for Video calls
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorAvailability;
