import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setHospitalFormData } from "shared/redux";
import { Doctor_SocialInfo } from "shared/components";

interface Props {
  handleClickNext: any;
}

const HospitalSocial = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const dispatch = useDispatch();

  const handleNext = (data: any) => {
    dispatch(setHospitalFormData(data));
    handleClickNext();
  };
  return <Doctor_SocialInfo handleNext={handleNext} />;
};

export default HospitalSocial;
