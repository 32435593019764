import React, { useEffect, useState } from "react";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "../booking.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { travelGETTICKETBOOKINGS } from "shared/services/TravelAgency";

interface Props {
  bookingTour?: any;
}

export default function BookingTour(props: Partial<Props>) {
  const { bookingTour } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleGoToDetail = (id: string) => {
    navigate(`/travelAgency/Booking/TourDetail/${id}`);
  };
  return (
    <div className={classNames(style.App)}>
      <div className={style.payment}>
        <div className={style.headerOuter}>
          <p className={style.headerclass2}>Name</p>
          <p className={style.headerclass2}>packageName</p>
          <p className={style.headerclass2}>From</p>
          <p className={style.headerclass2}>To</p>
          <p className={style.headerclass2}>Actual Price</p>
          <p className={style.headerclass2}>Total User</p>
        </div>
        <div className={style.tableData}>
          <table
            style={{
              margin: "0px",
              // borderCollapse: "separate",
              // borderSpacing: "0 10px",
            }}
          >
            <tbody className={style.wapper}>
              {bookingTour?.map((val: any, key: any) => {
                return (
                  <tr
                    className={style.tableRow}
                    key={key}
                    onClick={() => handleGoToDetail(val?._id)}
                  >
                    <td className={style.w16}>{val?.name}</td>
                    <td className={style.w16}>{val?.tourId?.packageName}</td>
                    <td className={style.w16}>{val?.tourId?.from}</td>
                    <td className={style.w16}>{val?.tourId?.to}</td>
                    <td className={style.w16}>
                      {val?.tourId?.actualPricePerHead}
                    </td>
                    <td className={style.w16}>{val?.totalUser}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
