import React from "react";
import classNames from "classnames";
import styles from "./social.module.css";
import MainHomeServices from "../../../mainHomeServices.module.css";
import { paramedicStaffSocialSchema } from "shared/utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import commonStyles from "shared/utils/common.module.css";
import Check from "assets/check.png";
import { PrimaryButton } from "shared/components";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { CustomInput } from "shared/components";
interface Props {
  handleClickNext: any;
}
const ParamedicStaffSocial = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const handleSubmit = async () => {
    console.log("test.....");

    handleClickNext();
  };

  const formik = useFormik({
    initialValues: {
      paramedicStafffbUrl: "",
      paramedicStaffinstaUrl: "",
      paramedicStafftwitterUrl: "",
      paramedicStaffwebUrl: "",
    },
    validationSchema: Yup.object(paramedicStaffSocialSchema),
    onSubmit: (values) => {
      console.log("hey");
      handleSubmit();
    },
  });

  return (
    <div
      className={classNames(
        commonStyles.col8,
        commonStyles.colsm12,
        commonStyles.mt56
      )}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Facebook Link             optional"
              id="paramedicStafffbUrl"
              name="paramedicStafffbUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.paramedicStafffbUrl}
            />
            {formik.touched.paramedicStafffbUrl &&
            formik.errors.paramedicStafffbUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.paramedicStafffbUrl}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Instagram Link             optional"
              id="paramedicStaffinstaUrl"
              name="paramedicStaffinstaUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.paramedicStaffinstaUrl}
            />
            {formik.touched.paramedicStaffinstaUrl &&
            formik.errors.paramedicStaffinstaUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.paramedicStaffinstaUrl}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(MainHomeServices.flx, commonStyles.mb16)}>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Twitter Link               optional"
              id="paramedicStafftwitterUrl"
              name="paramedicStafftwitterUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.paramedicStafftwitterUrl}
            />
            {formik.touched.paramedicStafftwitterUrl &&
            formik.errors.paramedicStafftwitterUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.paramedicStafftwitterUrl}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Website Link              optional"
              id="paramedicStaffwebUrl"
              name="paramedicStaffwebUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.paramedicStaffwebUrl}
            />
            {formik.touched.paramedicStaffwebUrl &&
            formik.errors.paramedicStaffwebUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.paramedicStaffwebUrl}
              </div>
            ) : null}
          </div>
        </div>
        <div className={MainHomeServices.buttonWidth}>
          {" "}
          <PrimaryButton
            children={"Next"}
            type="submit"
            colorType={"ParamedicStaff"}
          />
        </div>
      </form>
    </div>
  );
};

export default ParamedicStaffSocial;
