import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { NavBar, SideBar } from "shared/components";
import { Dashboard, Tests, Orders, Results, Payments } from "pages/Laboratory";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { laboratoryMenu } from "shared/utils";
import Setting from "pages/Laboratory/LabSetting";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
import Chat from "shared/components/Chat";
import { LabTestDetails } from "shared/services";
import TestDetail from "pages/Laboratory/Tests/TestDetails";
import OrderDetail from "pages/Laboratory/Orders/OrderDetail";
import SearchBar from "shared/components/Searchbar";
import Profile from "shared/components/Profile";
import ResultDetail from "pages/Laboratory/Results/resultDetails";
import { AddTest, Laboratory_Payments_Details } from "pages";

const LaboratoryMainRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/laboratory/dashboard");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: "0px",
          height: "100vh",
        }}
      >
        <SideBar menuItem={laboratoryMenu} />
      </div>

      <Routes>
        <Route path="laboratory/dashboard" Component={Dashboard} />
        <Route path="laboratory/test" Component={Tests} />
        <Route path="laboratory/AddTest" Component={AddTest} />
        <Route path="/testDetail/:id" Component={TestDetail} />
        <Route path="laboratory/order" Component={Orders} />
        <Route path="/laboratory/order/Detail/:id" Component={OrderDetail} />
        <Route path="laboratory/result" Component={Results} />
        <Route path="/laboratory/Result/Detail/:id" Component={ResultDetail} />
        <Route path="laboratory/payment" Component={Payments} />
        <Route
          path="laboratory/paymentDetails"
          Component={Laboratory_Payments_Details}
        />
        <Route path="laboratory/setting" Component={Setting} />
        <Route path="laboratory/policy" Component={PrivacyPolicy} />
        <Route path="chat/message" Component={Chat} />
        <Route path="/profile" Component={Profile} />
      </Routes>
    </div>
  );
};

export default LaboratoryMainRoutes;
