import React, { useEffect } from "react";
import labMainStyles from "../../../Laboratory/laboratoryMain.module.css";
import classNames from "classnames";
import MainInsuranceStyle from "../../mainInsurance.module.css";
import commonStyles from "shared/utils/common.module.css";
import { ArrowBack, CustomInput, PrimaryButton } from "shared/components";
import { PiUploadSimpleFill } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";
import Upload from "../../../../assets/images/upload.png";
import Loaction from "../../../../assets/images/loaction.png";
import { styled } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { insuranceSocialSchema } from "shared/utils";
import { setInsuranceUserFormData } from "shared/redux";

interface Props {
  handleClickNext: any;
}
const InsuranceSocial = (props: Partial<Props>) => {
  const dispatch = useDispatch();
  const { insuranceUserFormData } = useSelector(
    (state: any) => state.root.insurance
  );

  const { handleClickNext } = props;
  const formik = useFormik({
    initialValues: {
      fbUrl: "",
      instaUrl: "",
      twitterUrl: "",
      webUrl: "",
    },
    validationSchema: Yup.object(insuranceSocialSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  useEffect(() => {
    formik.setValues(insuranceUserFormData);
  }, []);
  const handleSubmit = async () => {
    await dispatch(setInsuranceUserFormData({ ...formik.values }));
    handleClickNext();
  };
  return (
    <div className={classNames(commonStyles.col12, commonStyles.mt56)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, MainInsuranceStyle.flx)}>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <CustomInput
              placeholder="Website Link "
              id="webUrl"
              name="webUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.webUrl}
            />
            {formik.touched.webUrl && formik.errors.webUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.webUrl}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <CustomInput
              placeholder="Instagram Link"
              id="instaUrl"
              name="instaUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.instaUrl}
            />
            {formik.touched.instaUrl && formik.errors.instaUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.instaUrl}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(MainInsuranceStyle.flx)}>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <CustomInput
              placeholder="Twitter Link"
              id="twitterUrl"
              name="twitterUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.twitterUrl}
            />
            {formik.touched.twitterUrl && formik.errors.twitterUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.twitterUrl}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <CustomInput
              placeholder="Facebook Link"
              id="fbUrl"
              name="fbUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.fbUrl}
            />
            {formik.touched.fbUrl && formik.errors.fbUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.fbUrl}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(labMainStyles.buttonWidth)}>
          <PrimaryButton children={"Next"} type="submit"  colorType={"Insurance"}/>
        </div>
      </form>
    </div>
  );
};

export default InsuranceSocial;
