import React from "react";
import styles from "../PharmayPayment/payment.module.css";
import classNames from "classnames";
import commonstyles from "../../../shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import { useSelector } from "react-redux";
import { Vendor_Payments } from "shared/components";
const PharmcyPayment = () => {
  const { pharmacy } = useSelector((state: any) => state.root.pharmacy);
  return (
    <div className={classNames(commonstyles.col12, styles.outer)}>
      <SearchBar />
      <Vendor_Payments type={"Pharmacy"} id={pharmacy?._id} />
    </div>
  );
};

export default PharmcyPayment;
