import React, { useState } from "react";
import classNames from "classnames";
import style from "./PropertyPhoto.module.css";
import commonStyles from "shared/utils/common.module.css";
import { PrimaryButton } from "shared/components";
import Picss from "assets/images/aa.png";
import { useSelector, useDispatch } from "react-redux";
import Delete from "assets/images/delete2.png";
import PropertyPhotoImgPicker from "../../Breadcrum/PropertyPhoto/PropertyPhotoImgPicker";
import { setHotelAddRoom } from "shared/redux";
interface Props {
  handleClickNext: any;
}
export default function PropertyPhoto(props: Partial<Props>) {
  const [error, setError] = React.useState("");
  const { handleClickNext } = props;
  const dispatch = useDispatch();
  const [imagearray, setImagearray] = useState<any[]>([]);

  const ImageSelector = (url: any) => {
    setImagearray((prevImages) => [...prevImages, url]);
  };

  console.log("URL IS", imagearray);
  const { hotelAddRoom } = useSelector((state: any) => state.root.hotel);
  console.log("data is.............", hotelAddRoom);

  const handleDelete = (index: number) => {
    const updatedImages = [...imagearray];
    updatedImages.splice(index, 1);
    setImagearray(updatedImages);
  };

  const handleSubmit = () => {
    if (imagearray.length === 0) {
      setError("Please ADD Image");
    } else {
      dispatch(
        setHotelAddRoom({
          ...hotelAddRoom,
          propertyphotos: imagearray,
        })
      );
      handleClickNext();
    }
  };
  return (
    <div className={commonStyles.colorBlue}>
      <div>
        <p className={classNames(commonStyles.fs16, commonStyles.semiBold)}>
          we need some photos of your property. we will displays these photos on
          your property page on Meditour Website
        </p>
      </div>
      <div className={style.flx}>
        <div className={style.ccardd}>
          <p className={classNames(commonStyles.fs16, commonStyles.semiBold)}>
            Upload At Least 1 Photo
          </p>
          <p
            className={classNames(
              commonStyles.fs16,
              commonStyles.bold,
              style.mt24
            )}
          >
            Drag & Drop Your Photo Here
          </p>
          <div className={classNames(style.btnwidth)}>
            <PropertyPhotoImgPicker setData={ImageSelector} />
          </div>
        </div>

        {imagearray.map((imageUrl, index) => (
          <div key={index}>
            <img src={imageUrl} className={style.Picss} />
            <img
              src={Delete}
              className={style.delte}
              onClick={() => handleDelete(index)}
            />
          </div>
        ))}
      </div>
      <div
        className={classNames(
          commonStyles.mt56,
          commonStyles.fs16,
          commonStyles.semiBold,
          style.lists
        )}
      >
        <p>Tips:</p>
        <ul className={style.mt16}>
          <li>
            <strong> Find Good light: </strong>
            Use naturals light for soft, even Illumination.{" "}
          </li>
          <li>
            <strong>Compose Well:</strong> Apply the rule of thirds and clean
            backgrounds.
          </li>
          <li>
            <strong>Focus on Subject:</strong> Capture emotion or a compelling
            story.
          </li>
          <li>
            <strong>Practice and Edit:</strong> Keep practicing and use simple
            edits.
          </li>
        </ul>
      </div>

      <div style={{ marginTop: "32px", width: "204px", marginBottom: "16px" }}>
        <PrimaryButton
          children={"Next"}
          colorType={"blue"}
          onClick={handleSubmit}
        />
      </div>
      {error && <div className={classNames(commonStyles.error)}>*{error}</div>}
    </div>
  );
}
