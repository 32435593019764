import classNames from "classnames";
import React, { useState } from "react";
import style from "./HotelGuest.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import Apartment from "assets/images/apartment.png";
import Home from "assets/images/house.png";
import Buliding from "assets/images/buildings.png";
import Cards from "assets/images/hotelCard.jpg";
import { CustomModal, PrimaryButton } from "shared/components";
import { Navigate, useNavigate } from "react-router-dom";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
function YourListing() {
  const [showMessageCard, setShowMessageCard] = useState(false);
  const handleShowMessage = () => {
    setShowMessageCard(true);
  };
  const navigate = useNavigate();

  const handleGoToHotel = () => {
    navigate("/hotel/property/categoryProperty");
  };
  const handleGoApart = () => {
    navigate("/hotel/property/apartmentBreadcrum");
  };
  const handleGoHome = () => {
    navigate("/hotel/property/homeGuestbook");
  };

  return (
    <div className={classNames(commonstyles.colorBlue)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={commonstyles.outerContainer}>
          <div className={commonstyles.flxBetween}>
            <div className={classNames(commonstyles.flx, style.mb24)}>
              <p
                className={classNames(
                  commonstyles.semiBold,
                  commonstyles.fs24,
                  commonstyles.colorBlue
                )}
              >
                Properties
              </p>
              <div className={style.outerRefresh}>
                <BiSolidMessageSquareAdd
                  className={style.RefreshIcon}
                  onClick={handleShowMessage}
                />
              </div>
            </div>
          </div>
          <div className={classNames(style.flxw)}>
            <div className={classNames(style.CardProperty)}>
              <img src={Cards} className={style.imgsize} />
              <div className={style.body}>
                <div className={commonstyles.flxBetween}>
                  {" "}
                  <div>
                    <p className={classNames(commonstyles.medium)}>
                      Park Lane Hotel
                    </p>
                    <p className={classNames(commonstyles.medium, style.mt8)}>
                      25 Rooms
                    </p>
                  </div>
                  <div
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue
                    )}
                  >
                    <p>3 star</p>
                    <p className={style.mt8}>4 Types</p>
                  </div>
                </div>{" "}
                <p className={classNames(commonstyles.medium, style.mt16)}>
                  154A, Architect Socity, Lahore
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal showModal={showMessageCard}>
        <div
          style={{
            width: "1100px",
            textAlign: "center",
          }}
        >
          <div className={commonstyles.colorBlue}>
            <p className={classNames(commonstyles.fs24, commonstyles.semiBold)}>
              Book with us, and unlock your dream stay on MediTour!"
            </p>
            <p className={classNames(commonstyles.fs16, style.mt8)}>
              Select Your Comfort Zone On <strong>MediTour</strong>
            </p>
          </div>
          <div className={classNames(style.flxw)}>
            <div className={style.ccard} onClick={handleGoApart}>
              <img src={Apartment} className={style.homes} />
              <p
                className={classNames(commonstyles.fs24, commonstyles.semiBold)}
              >
                Apartments
              </p>
              <div className={style.mt16}>
                <p>
                  Indulge in the luxury of spacious apartments tailored to your
                  needs.
                </p>
              </div>
            </div>
            <div className={style.ccard} onClick={handleGoHome}>
              <img src={Home} className={style.homes} />
              <p
                className={classNames(commonstyles.fs24, commonstyles.semiBold)}
              >
                Homes
              </p>
              <div className={style.mt16}>
                <p>
                  Experience the warmth and comfort of a home away from home
                </p>
              </div>
            </div>
            <div className={style.ccard} onClick={handleGoToHotel}>
              <img src={Buliding} className={style.homes} />
              <p
                className={classNames(commonstyles.fs24, commonstyles.semiBold)}
              >
                Hotels, B&Bs & More
              </p>
              <div className={style.mt16}>
                <p>
                  Indulge in luxury and comfort with our curated hotel
                  selection.
                </p>
              </div>
              {/* <div className={style.mt20}>
                <button className={style.btnbottom}>Select</button>
              </div> */}
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

export default YourListing;
