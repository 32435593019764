import React, { useState, useEffect } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import styles from "./VehicleDetail.module.css";
import commonstyle from "shared/utils/common.module.css";
import commomstyles from "shared/utils/common.module.css";
import commonstyles from "shared/utils/common.module.css";
import style from "./VehicleDetail.module.css";
import * as Yup from "yup";
import Uplaod from "assets/images/uil_image-upload.png";
import { useFormik } from "formik";
import commonStyles from "shared/utils/common.module.css";
import RentacarVehicleList from "shared/components/RentacarTables/RentacarVehicleList";
import Cross from "../../../../assets/images/cross.png";

import {
  CustomInput,
  CustomModal,
  LoadingModal,
  PrimaryButton,
  RingLoader,
} from "shared/components";

import CustomSelect from "shared/components/CustomSelect";
import {
  rentCarAddVahicle,
  rentCarGetAllVahicle,
} from "shared/services/RentaCar";
import { rentACarAddVahicleSchema } from "shared/utils";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { TbRefresh } from "react-icons/tb";
import {
  setVahicle,
  setVehicleLength,
  setVehicleRenderFlag,
} from "shared/redux";
import NewPagination from "shared/components/NewPagination/NewPagination";
import PropertyPhotoImgPicker from "pages/TravelTourism/Hotels/HotelProperty/Breadcrum/PropertyPhoto/PropertyPhotoImgPicker";
import Datepicker from "shared/components/DatePicker";
const Vehicletype = ["Vehicle type", "SUV", "Sedan", "HatchBacks", "Truck"];

export default function VehicleDetail() {
  const { vehicle, vehicleLength, vehicleRenderFlag } = useSelector(
    (state: any) => state.root.rentcar
  );
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = vehicleLength;
  const [loading, setLoading] = useState(false);
  const [rotation, setRotation] = useState(0);
  const rotationIncrement = 90;

  const handleRotate = () => {
    setRotation(rotation - rotationIncrement);
  };
  const fetchVahicleDetail = (pageno: number) => {
    setLoading(true);
    rentCarGetAllVahicle(pageno)
      .then((res: any) => {
        if (res?.data?.auth) {
          dispatch(setVahicle(res?.data?.vehicles));
          dispatch(setVehicleLength(res?.data?.totalLength));
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
        // setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchVahicleDetail(1);
  }, [vehicleRenderFlag]);

  const [showAddVehicleModel, setShowAddVehicleModel] = useState(false);
  const handleModelOpen = () => {
    setShowAddVehicleModel(true);
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;
    if (vehicleLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      fetchVahicleDetail(currentPage + 1);
      dispatch(setVehicleRenderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      fetchVahicleDetail(currentPage - 1);
      dispatch(setVehicleRenderFlag(true));
    }
  };

  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={styles.outerContainer}>
          <div
            className={classNames(commonstyles.mb32, commonstyles.flxBetween)}
          >
            <div className={classNames(commomstyles.flx)}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Vehicle List
              </p>

              {vehicle?.length >= 0 && (
                <div className={styles.outerRefresh}>
                  <BiSolidMessageSquareAdd
                    className={styles.addIcon}
                    onClick={() => setShowAddVehicleModel(true)}
                  />
                </div>
              )}
              <div className={styles.outerRefresh}>
                <TbRefresh
                  className={styles.RefreshIcon}
                  style={{ transform: `rotate(${rotation}deg)` }}
                  onClick={() => {
                    fetchVahicleDetail(1);
                    setCurrentPage(1);
                    handleRotate();
                  }}
                />
              </div>
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>

          <div className={style.tabless}>
            <RentacarVehicleList vahicle={vehicle} />
          </div>

          <CustomModal
            showModal={showAddVehicleModel}
            children={
              <AddVehicleModel
                setShowAddVehicleModel={setShowAddVehicleModel}
                vehicle={vehicle}
                vehicleLength={vehicleLength}
                setVahicle={setVahicle}
              />
            }
          />
        </div>
      </div>

      {loading && <LoadingModal showModal={loading} />}
    </div>
  );
}

const AddVehicleModel = (props: any) => {
  const { setShowAddVehicleModel, vehicle, setVahicle, vehicleLength } = props;
  const [imagearray, setImagearray] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      vahicleType: "",
      vehicleName: "",
      vehicleModal: "",
      vehicleYear: "",
      vehicleColour: "",
      vehicleVIN: "",
      perDayRent: "",
      vehicleRegistrationNum: "",
      vahicleRegistrationDate: "",
      vehicleImages: [],
    },
    validationSchema: Yup.object(rentACarAddVahicleSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  let tempArray: any = [];
  const ImageSelector = (url: string) => {
    tempArray = [...tempArray, url];
    console.log("🚀 ~ ImageSelector ~ tempArray:", tempArray);

    formik.setFieldValue("vehicleImages", tempArray);
  };

  const handleSelect = (selectedOption: string) => {
    formik.setFieldValue("vahicleType", selectedOption);
  };

  const handleSubmit = () => {
    const currentData = formik.values;
    console.log("Form Values:", formik.values);
    let veh_clone = JSON.parse(JSON.stringify(vehicle));

    let params = {
      vehicleType: currentData.vahicleType,
      vehicleImages: currentData.vehicleImages,
      vehicleName: currentData.vehicleName,
      vehicleModel: currentData.vehicleModal,
      vehicleYear: currentData.vehicleYear,
      vehicleColour: currentData.vehicleColour,
      vehicleVinNo: currentData.vehicleVIN,
      vehicleRegisterationNo: currentData.vehicleRegistrationNum,
      vehicleRegisterationDate: currentData.vahicleRegistrationDate,
      actualPricePerDay: currentData.perDayRent,
    };

    setLoading(true);

    rentCarAddVahicle(params)
      .then((res: any) => {
        veh_clone.unshift(res?.data?.vehicle);
        dispatch(setVahicle(veh_clone));
        dispatch(setVehicleLength(vehicleLength + 1));
        dispatch(setVehicleRenderFlag(true));
        setShowAddVehicleModel(false);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLicenseExpiry = (date: any) => {
    const formattedDate = date.toISOString().split("T")[0];
    console.log("vahicleRegistrationDate", formattedDate);
    formik.setFieldValue("vahicleRegistrationDate", formattedDate);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            alignItems: "center",
          }}
          onClick={() => setShowAddVehicleModel(false)}
        >
          <img src={Cross} style={{ height: 30, width: 30 }} />
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomSelect
              onSelect={handleSelect}
              options={Vehicletype}
              placeholder="Select Type"
            />
            {formik.touched.vahicleType && formik.errors.vahicleType ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.vahicleType}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <CustomInput
              placeholder="Vehicle Name "
              id="vehicleName"
              name="vehicleName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleName}
            />

            {formik.touched.vehicleName && formik.errors.vehicleName ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.vehicleName}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Vehicle Model "
              id="vehicleModal"
              name="vehicleModal"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleModal}
            />

            {formik.touched.vehicleModal && formik.errors.vehicleModal ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.vehicleModal}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <CustomInput
              placeholder="Vehicle Year"
              id="vehicleYear"
              name="vehicleYear"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleYear}
            />

            {formik.touched.vehicleYear && formik.errors.vehicleYear ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.vehicleYear}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Registration Number"
              id="vehicleRegistrationNum"
              name="vehicleRegistrationNum"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleRegistrationNum}
            />

            {formik.touched.vehicleRegistrationNum &&
            formik.errors.vehicleRegistrationNum ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.vehicleRegistrationNum}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <Datepicker
              placeholder="Registration Date"
              setData={handleLicenseExpiry}
            />
            {formik.touched.vahicleRegistrationDate &&
            formik.errors.vahicleRegistrationDate ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.vahicleRegistrationDate}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Per Day Rent"
              id="perDayRent"
              name="perDayRent"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.perDayRent}
            />

            {formik.touched.perDayRent && formik.errors.perDayRent ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.perDayRent}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <CustomInput
              placeholder="VIN"
              id="vehicleVIN"
              name="vehicleVIN"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleVIN}
            />

            {formik.touched.vehicleVIN && formik.errors.vehicleVIN ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.vehicleVIN}
              </div>
            ) : null}
          </div>
        </div>

        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Vehicle Color"
              id="vehicleColour"
              name="vehicleColour"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleColour}
            />

            {formik.touched.vehicleColour && formik.errors.vehicleColour ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.vehicleColour}
              </div>
            ) : null}
          </div>
        </div>

        <div className={classNames(commonstyles.col10, style.upload)}>
          <div className={classNames(commonstyles.flxCenter)}>
            <img src={Uplaod} className={style.imgs} />
          </div>
          <div
            className={classNames(
              commonstyle.fs14,
              style.mb16,
              commonstyle.medium,
              style.justifycenter
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <PropertyPhotoImgPicker setData={ImageSelector} />
          </div>
          <div className={style.center}>
            {imagearray.map((imageUrl, index) => (
              <div key={index}>
                <img src={imageUrl} className={style.Picss} />
              </div>
            ))}
          </div>
          {formik.touched.vehicleImages && formik.errors.vehicleImages ? (
            <div className={classNames(commonStyles.error)}>
              *{formik.errors.vehicleImages}
            </div>
          ) : null}
          {/* {validationErrors.length > 0 && (
            <div className={classNames(commonStyles.error, commomstyles.mt8)}>
              {validationErrors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          )} */}
        </div>
        <div style={{ width: "204px", marginTop: "32px" }}>
          <PrimaryButton
            children={
              loading ? <RingLoader size={35} color={"#fff"} /> : "Submit"
            }
            colorType={"blue"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
