import classNames from "classnames";
import commonStyles from "shared/utils/common.module.css";
import Styles from "./styles.module.css";
import { MdDeleteForever } from "react-icons/md";

export const Medicine_Table = (props: any) => {
  const { data, handleDelete, type } = props;
  console.log("dd.....", data);

  return (
    <div className={Styles.payment}>
      <div className={classNames(Styles.headerOuter, commonStyles.bold)}>
        <p className={Styles.headerclass}>Medicine</p>
        <p className={Styles.headerclass}>Dosage</p>
        <p className={Styles.headerclass}>Route</p>
        <p className={Styles.headerclass}>Frequency</p>
        <p className={Styles.headerclass}>Instructions</p>
        <p className={Styles.headerclass}>Days</p>
        <p className={Styles.headerclass}>Quantity</p>
        {type === "Bid" ? (
          <p className={Styles.headerclass}>Availabilty</p>
        ) : (
          <p className={Styles.headerclass}>Action</p>
        )}
      </div>
      <div className={Styles.tableData}>
        <table
          style={{
            margin: "0px",
            borderCollapse: "separate",
            borderSpacing: "0 4px",
            tableLayout: "fixed",
          }}
        >
          <tbody className={Styles.wapper}>
            {data?.map((val: any, rowIndex: any) => {
              return (
                <tr
                  className={Styles.tableRow}
                  key={rowIndex}
                  // onClick={() => handleGoToDetail(val)}
                >
                  <td className={Styles.w20}>{val?.medicineName}</td>
                  <td className={Styles.w20}>{val?.dosage}</td>
                  <td className={Styles.w20}>{val?.route}</td>
                  <td className={Styles.w20}>{val?.frequency}</td>
                  <td className={Styles.w20}>{val?.instruction}</td>
                  <td className={Styles.w20}>{val?.days}</td>
                  <td className={Styles.w20}>{val?.quantity}</td>

                  {type === "Bid" ? (
                    <td className={Styles.w20}>Yes</td>
                  ) : (
                    <td className={Styles.w20}>
                      <MdDeleteForever
                        className={Styles.deleteIcon}
                        onClick={() => handleDelete(rowIndex)}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Medicine_Table;
