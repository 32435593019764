import React from "react";
import { GoDotFill } from "react-icons/go";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface Props {
  data: { name: string; Health: number; Travel: number }[];
  totalRevenue: number;
}

const InsuranceDashboard: React.FC<Props> = ({ data, totalRevenue }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <p style={{ fontSize: "20px", fontWeight: "600" }}>Last week Revenue</p>
        <p style={{ fontSize: "20px", fontWeight: "600", marginLeft: "auto" }}>
          {totalRevenue}
        </p>
      </div>
      <ResponsiveContainer width="97%" height={220}>
        <BarChart data={data}>
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <YAxis axisLine={false} tickLine={false} />
          <Bar dataKey="Health" fill="#0095FF" barSize={10} />
          <Bar dataKey="Travel" fill="#00E096" barSize={10} />
          <Legend iconType="circle" />
        </BarChart>
      </ResponsiveContainer>
      <div
        style={{
          borderTop: "1px solid #EDF2F6",
          display: "flex",
          justifyContent: "center",
          paddingTop: "24px",
        }}
      >
        <div
          style={{
            width: "50%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <GoDotFill
              style={{
                marginRight: "8px",
                color: "#0095FF",
                fontSize: "16px",
              }}
            />
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#96A5B8",
              }}
            >
              {" "}
              Health
            </p>
          </div>{" "}
          <p
            style={{
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            45k
          </p>
        </div>
        <div
          style={{
            borderLeft: "1px solid #EDF2F6",
            width: "50%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GoDotFill
              style={{
                marginRight: "8px",
                color: "#07E098",
                fontSize: "16px",
              }}
            />

            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#96A5B8",
              }}
            >
              Travel
            </p>
          </div>
          <p
            style={{
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            45k
          </p>
        </div>
      </div>
    </div>
  );
};

export default InsuranceDashboard;
