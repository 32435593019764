import classNames from "classnames";
import React, { useEffect, useState } from "react";
import commonstyles from "shared/utils/common.module.css";
import styles from "./styles.module.css";
import SearchBar from "shared/components/Searchbar";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CustomInput, CustomModal, PrimaryButton } from "shared/components";
import { Checkbox } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSelect from "shared/components/CustomSelect";
import {
  addTreatmentPackage,
  DocGetMainCategories,
  getAllTreatmentCategories,
} from "shared/services/DoctorService";

const validationSchema: any = {
  treatment: Yup.object()
    .shape({
      procedure: Yup.boolean(),
      medicines: Yup.boolean(),
      labService: Yup.boolean(),
      hospitalization: Yup.boolean(),
      other: Yup.string().optional(),
    })
    .test(
      "at-least-one-true-or-other",
      'At least one treatment option must be selected or "other" must be filled out',
      function (value: any) {
        const { procedure, medicines, labService, hospitalization, other } =
          value;
        return (
          procedure ||
          medicines ||
          labService ||
          hospitalization ||
          (other && other.trim().length > 0)
        );
      }
    ),
  totalAmount: Yup.number()
    .typeError("Total amount must be a number")
    .required("Required")
    .min(0, "Total amount must be at least 0"),
  categoryId: Yup.string(),
  subCategory: Yup.string().required("Required"),
};

function AddTreatment() {
  const navigate = useNavigate();
  const { systemType } = useSelector((state: any) => state.root.common);
  const [suggestions, setSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [cost, setCost] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [treatmentId, setTreatmentId] = useState("");
  const [categoryFound, setCategoryFound] = useState(false);

  const formik = useFormik({
    initialValues: {
      treatment: {
        procedure: false,
        medicines: false,
        labService: false,
        hospitalization: false,
        other: "",
      },
      totalAmount: "",
      categoryId: "",
      subCategory: "",
    },
    validationSchema: Yup.object(validationSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    const currentData = formik.values;
    let params = {
      categoryFound: categoryFound,
      ...(categoryFound
        ? {
            treatmentId,
          }
        : {
            categoryId: currentData?.categoryId,
            subCategory: currentData?.subCategory,
          }),
      treatment: {
        procedure: true,
        medicines: true,
        labService: true,
        hospitalization: true,
        other: "lk",
      },
      totalAmount: 25000,
    };

    setLoading(true);

    addTreatmentPackage(params)
      .then((res: any) => {
        navigate(`/${systemType}/treatments`);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoback = () => {
    navigate(`/${systemType}/treatments`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query) {
      fetchAllTreatments(query);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion: any) => {
    setCategoryFound(true);
    setSearchQuery(suggestion?.subCategory);
    formik.setFieldValue("subCategory", suggestion?.subCategory);
    setTreatmentId(suggestion?._id);
    setSuggestions([]);
  };

  const fetchAllTreatments = (search: string) => {
    getAllTreatmentCategories(search)
      .then((res: any) => {
        setSuggestions(res?.data);
      })
      .catch((err: any) => {});
  };

  const handleSelectCategory = (category: string, name: string) => {
    setSearchQuery(name);
    setCategoryFound(false);

    let selectedOne: any = null;
    categoriesData?.map((c: any, ind: number) => {
      if (c?.categoryName == category) {
        selectedOne = c;
      }
    });

    formik.setFieldValue("subCategory", name);
    formik.setFieldValue("categoryId", selectedOne?._id);
    setShowAddModal(false);
  };

  const fetchAllCategories = (page: number) => {
    DocGetMainCategories(page)
      .then((res: any) => {
        setCategoriesData(res?.data?.categories);
        const categoryNames = res?.data?.categories.map(
          (category: any) => category.categoryName
        );
        setCategories(categoryNames);
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    fetchAllCategories(1);
  }, []);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={classNames(commonstyles.flxBetween, commonstyles.mb32)}>
        <div className={classNames(commonstyles.flx)}>
          <div className={styles.backOuter}>
            <IoArrowBack className={styles.addIcon} onClick={handleGoback} />
          </div>
          <p
            className={classNames(
              commonstyles.fs24,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Add Treatment Package
          </p>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={commonstyles.mr87}>
          <div className={classNames(commonstyles.col6)}>
            <div className={classNames(commonstyles.flxBetween)}>
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                Available Treatments
              </p>
              <p
                className={classNames(
                  commonstyles.fs14,
                  commonstyles.colorOrange
                )}
                onClick={() => setShowAddModal(true)}
                style={{
                  marginLeft: "16px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Add Custom
              </p>
            </div>
            {/* <div
            style={{
              width: "98%",
            }}
          > */}

            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search Here"
              // className={classNames(styles.customInput)}
              style={{
                width: "100%",
                padding: "10px",
                marginBottom: "10px",
                border: "2px solid rgb(0, 39, 109)",
                borderRadius: "3px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />

            {formik.touched.subCategory && formik.errors.subCategory ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.subCategory}
              </div>
            ) : null}

            {/* </div> */}

            {suggestions?.length > 0 && (
              <div
                className={classNames(styles.searchResults, commonstyles.col12)}
                style={{
                  backgroundColor: "white",
                  zIndex: 10,
                  marginTop: -10,
                }}
              >
                <ul
                // className={styles.suggestionList}
                >
                  {suggestions.map((suggestion: any, index) => (
                    <li
                      key={index}
                      className={classNames(
                        styles.suggestionItem,
                        commonstyles.col12
                      )}
                      onClick={() => handleSuggestionClick(suggestion)}
                      style={{
                        listStyleType: "none",
                      }}
                    >
                      {suggestion?.subCategory}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.colorBlue,
              commonstyles.semiBold,
              commonstyles.mt32
            )}
          >
            What's included?
          </p>

          {formik.touched.treatment && formik.errors.treatment ? (
            <div className={classNames(commonstyles.error)}>
              <>*{formik.errors.treatment}</>
            </div>
          ) : null}

          <div className={classNames(commonstyles.flx)}>
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={
                <CheckCircleOutlineIcon style={{ color: "green" }} />
              }
              onChange={() => {
                formik.setFieldValue(
                  "treatment.procedure",
                  !formik.values.treatment.procedure
                );
              }}
              checked={formik.values.treatment.procedure}
            />
            <p
              className={classNames(commonstyles.fs16, commonstyles.colorBlue)}
            >
              Procedure
            </p>
          </div>
          <div className={classNames(commonstyles.flx)}>
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={
                <CheckCircleOutlineIcon style={{ color: "green" }} />
              }
              onChange={() => {
                formik.setFieldValue(
                  "treatment.hospitalization",
                  !formik.values.treatment.hospitalization
                );
              }}
              checked={formik.values.treatment.hospitalization}
            />
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.colorBlue
                // commonstyles.semiBold,
              )}
            >
              Hospitalization
            </p>
          </div>

          <div className={classNames(commonstyles.flx)}>
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={
                <CheckCircleOutlineIcon style={{ color: "green" }} />
              }
              onChange={() => {
                formik.setFieldValue(
                  "treatment.medicines",
                  !formik.values.treatment.medicines
                );
              }}
              checked={formik.values.treatment.medicines}
            />
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.colorBlue
                // commonstyles.semiBold,
              )}
            >
              Medicines
            </p>
          </div>

          <div className={classNames(commonstyles.flx)}>
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={
                <CheckCircleOutlineIcon style={{ color: "green" }} />
              }
              onChange={() => {
                formik.setFieldValue(
                  "treatment.labService",
                  !formik.values.treatment.labService
                );
              }}
              checked={formik.values.treatment.labService}
            />
            <p
              className={classNames(commonstyles.fs16, commonstyles.colorBlue)}
            >
              Lab Services
            </p>
          </div>

          <div className={classNames(commonstyles.flx)}>
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={
                <CheckCircleOutlineIcon style={{ color: "green" }} />
              }
            />
            <p
              className={classNames(commonstyles.fs16, commonstyles.colorBlue)}
            >
              Others
            </p>
          </div>

          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.colorBlue,
              commonstyles.semiBold,
              commonstyles.mt32
            )}
          >
            Total Cost?
          </p>

          <div className={classNames(commonstyles.col2)}>
            <CustomInput
              placeholder="Enter Here"
              id="totalAmount"
              name="totalAmount"
              type="text"
              onChange={(e: any) =>
                formik.setFieldValue("totalAmount", e.target.value)
              }
              value={formik.values.totalAmount}
            />

            {formik.touched.totalAmount && formik.errors.totalAmount ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.totalAmount}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ marginTop: "56px", width: "210px" }}>
          <PrimaryButton
            disabled={loading}
            children={loading ? "loading..." : "Save"}
            type="submit"
            colorType={"Linear"}
          />
        </div>
      </form>
      <CustomModal
        showModal={showAddModal}
        children={
          <AddCustomTreatment
            setShowAddModal={setShowAddModal}
            categories={categories}
            handleSelectCategory={handleSelectCategory}
          />
        }
      />
    </div>
  );
}

export default AddTreatment;

const AddCustomTreatment = (props: any) => {
  const { setShowAddModal, categories, handleSelectCategory } = props;
  const formik = useFormik({
    initialValues: {
      category: "",
      name: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().trim().required("Required"),
      name: Yup.string().trim().required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    handleSelectCategory(formik.values.category, formik.values.name);
  };

  const handleSelect = (selectedOption: string) => {
    formik.setFieldValue("category", selectedOption);
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
  };
  return (
    <div style={{ width: "500px" }}>
      <div className={styles.end}>
        <button className={styles.close} onClick={handleCloseModal}>
          &#10006;
        </button>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ width: "233px" }}>
          <CustomSelect
            options={categories}
            placeholder="Treatment Category"
            onSelect={handleSelect}
          />
          {formik.touched.category && formik.errors.category ? (
            <div className={classNames(commonstyles.error)}>
              *{formik.errors.category}
            </div>
          ) : null}
        </div>
        <div style={{ marginTop: "56px" }} className={commonstyles.flxBetween}>
          <div style={{ width: "233px" }}>
            <CustomInput
              placeholder="Treatment Name"
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
            />

            {formik.touched.name && formik.errors.name ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.name}
              </div>
            ) : null}
          </div>
        </div>

        <div style={{ marginTop: "56px", width: "210px" }}>
          <PrimaryButton children={"Save"} type="submit" colorType={"Linear"} />
        </div>
      </form>
    </div>
  );
};
