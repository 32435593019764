import * as React from "react";
import Booking from "./HotelBookingTable.module.css";
import commonstyle from "../../../utils/common.module.css";
import classNames from "classnames";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { Navigate, useNavigate } from "react-router-dom";
import { FaRegCheckCircle } from "react-icons/fa";

const data = [
  {
    guestid: "2372498",
    guestname: "guestid",
    number: "0344444444",
    checkin: "19 Oct 2023",
    checkOut: "01:00PM",
    NOOfGuest: "4",
    Room: "1 x Deluxe",
    size: "King Room",
    payment: <FaRegCheckCircle className={Booking.icons} />,
  },
];

interface Props {
  bookings: any;
}
function HotelBookingTable(props: Partial<Props>) {
  const { bookings } = props;
  const navigate = useNavigate();
  const handleGoToDetail = (id: string) => {
    navigate(`/hotel/guestDescription/${id}`);
  };
  return (
    <div className={classNames(Booking.App)}>
      <div className={Booking.payment}>
        <div className={Booking.headerOuter}>
          <p className={Booking.headerclass}>Guest id</p>
          <p className={Booking.headerclass}>Guest Name</p>
          <p className={Booking.headerclass}>Phone No.</p>
          <p className={Booking.headerclass}>Check In</p>
          <p className={Booking.headerclass}>Check out</p>
          <p className={Booking.headerclass}>NO. Of Guest</p>
          <p className={Booking.headerclass}>Room</p>
        </div>
        <div className={Booking.tableData}>
          <table
            style={{
              margin: "0px",
            }}
          >
            <tbody className={Booking.wapper}>
              {bookings.map((val: any, key: any) => {
                return (
                  <tr
                    className={Booking.tableRow}
                    key={key}
                    onClick={() => handleGoToDetail(val?._id)}
                  >
                    <td className={Booking.w20}>{val?.userId?.name}</td>
                    <td className={Booking.w20}>{val?.userId?.phone}</td>
                    <td className={Booking.w20}>
                      {" "}
                      {val?.entryDate
                        ? new Date(val?.entryDate).toLocaleDateString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          })
                        : "__"}
                    </td>
                    <td className={Booking.w20}>
                      {val?.arrivalTime?.from
                        ? new Date(val?.arrivalTime?.from).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "__"}
                      -{" "}
                      {val?.arrivalTime?.to
                        ? new Date(val?.arrivalTime?.to).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "__"}
                    </td>
                    <td className={Booking.w20}>{val?.noOfGuest}</td>
                    <td className={Booking.w20}>
                      {val.Room}
                      <br />
                      {val.size}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default HotelBookingTable;
