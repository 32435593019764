import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./ticketRequest.module.css";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { TbRefresh } from "react-icons/tb";
import { BsClock } from "react-icons/bs";
import { LuCalendarDays, LuUser2 } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { travelFLIGHTGETREQUEST } from "shared/services/TravelAgency";
import {
  setTicketRequestLength,
  setTicketRequestRenderFlag,
  setTicketRequests,
} from "shared/redux";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModal } from "shared/components";
import TravelEmpty from "shared/components/TravelEmpty";
export default function TicketRequest() {
  const { ticketRequests, ticketRequestRenderFlag, ticketRequestLength } =
    useSelector((state: any) => state.root.travelagency);
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = ticketRequestLength;

  const navigate = useNavigate();
  const handleGoToDetail = (id: string) => {
    navigate(`/travelAgency/ticketRequests/Detail/${id}`);
  };

  console.log("..............ticketRequests.............", ticketRequests);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchticketRequest = (pageno: number) => {
    setLoading(true);
    travelFLIGHTGETREQUEST(pageno)
      .then((res: any) => {
        console.log(
          "travelFLIGHTGETREQUEST,,,,,,,,,,>>>>>>>>>>>>>>>>>>>.",
          res
        );
        dispatch(setTicketRequests(res?.data?.requests));
        dispatch(setTicketRequestLength(res?.data?.totalRequests));
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   fetchticketRequest();
  // }, []);

  useEffect(() => {
    if (ticketRequestRenderFlag) {
      setLoading(true);
      fetchticketRequest(currentPage);
      dispatch(setTicketRequestRenderFlag(false));
    }
  }, [ticketRequestRenderFlag, currentPage]);

  const handleRotate = (): void => {
    setRotation(rotation - rotationIncrement);
    fetchticketRequest(currentPage);
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (ticketRequestLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setTicketRequestRenderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setTicketRequestRenderFlag(true));
    }
  };

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={styles.outerContainer}>
          <div
            className={classNames(commonstyles.flxBetween, commonstyles.mb32)}
          >
            <div className={commonstyles.flx}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Ticket Request
              </p>
              <div className={styles.outerRefresh}>
                <TbRefresh
                  className={styles.RefreshIcon}
                  style={{ transform: `rotate(${rotation}deg)` }}
                  onClick={handleRotate}
                />
              </div>
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          {loading ? (
            <LoadingModal showModal={loading} />
          ) : ticketRequests && ticketRequests.length > 0 ? (
            ticketRequests.map((val: any) => (
              <div
                className={styles.card}
                onClick={() => handleGoToDetail(val._id)}
                key={val.id}
              >
                <div className={classNames(commonstyles.flx)}>
                  <div
                    style={{ width: "15%", marginLeft: "10px" }}
                    className={commonstyles.flx}
                  >
                    <LuUser2 className={styles.icon} />
                    <p className={classNames(commonstyles.fs16)}>
                      {val?.userId?.name}
                    </p>
                  </div>

                  <div style={{ width: "33%", marginLeft: "2%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <LuCalendarDays className={styles.icon} />
                      <span
                        className={classNames(commonstyles.fs16)}
                        style={{ marginLeft: "8px" }}
                      >
                        {val?.createdAt
                          ? new Date(val.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }
                            )
                          : "__"}
                      </span>
                    </div>
                  </div>

                  <div style={{ width: "33%", marginLeft: "2%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <BsClock className={styles.icon} />
                      <span
                        className={classNames(commonstyles.fs16)}
                        style={{ marginLeft: "8px" }}
                      >
                        {val &&
                          val.createdAt &&
                          new Date(val.createdAt).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })}
                      </span>
                    </div>
                  </div>

                  <div style={{ width: "33%", marginLeft: "2%" }}>
                    <p
                      className={classNames(commonstyles.fs16)}
                      style={{ textTransform: "capitalize" }}
                    >
                      {val?.requestType}
                    </p>
                  </div>

                  <div style={{ width: "33%", marginLeft: "2%" }}>
                    <p
                      className={classNames(commonstyles.fs16)}
                      style={{ textTransform: "capitalize" }}
                    >
                      {val?.status}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={classNames(styles.emptyOuter)}>
              <TravelEmpty />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
