import classNames from "classnames";
import React, { useEffect, useState } from "react";
import style from "./RentAcarDashBoard.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import Icon1 from "assets/images/CarIcon1.png";
import Icon2 from "assets/images/CarIcon2.png";
import RentAcarstackBarChart from "shared/components/RentAcarChart/RentBarchart/LineStockchart";
import RentAcarPieChart from "shared/components/RentAcarChart/RentPieChart";
import RentACarLineChart from "shared/components/RentAcarChart/LineChartRent";
import RentAcarBarChart from "shared/components/RentAcarChart/RentBarChart2";
import { rentCarDashboardDetail, rentCarGraph } from "shared/services/RentaCar";
import { LoadingModal } from "shared/components";
import { setDashboardCustomerGraph, setDashboardDetails } from "shared/redux";
import { useDispatch, useSelector } from "react-redux";
const data = [
  { name: "0", value1: 0, value2: 0 },
  { name: "Jan", value1: 10, value2: 15 },
  { name: "Feb", value1: 52, value2: 8 },
  { name: "Mar", value1: 25, value2: 25 },
  { name: "Apr", value1: 42, value2: 4 },
  { name: "May", value1: 37, value2: 29 },
  { name: "Jun", value1: 22, value2: 19 },
  { name: "Jul", value1: 21, value2: 18 },
  { name: "Aug", value1: 37, value2: 41 },
  { name: "Sep", value1: 27, value2: 29 },
  { name: "Oct", value1: 22, value2: 9 },
  { name: "Nov", value1: 23, value2: 28 },
  { name: "Dec", value1: 40, value2: 15 },
  // Add more data as needed
];
// const data2 = [
//   { name: "Total Request", value: parsedTotalRequest },
//   { name: "Total Canceled", value: 200 },
//   { name: "Total Pending", value: parsedPending },
// ];
const data3 = [
  { name1: "Jan", Profit: 900 },
  { name1: "Feb", Profit: 200 },
  { name1: "Mar", Profit: 300 },
  { name1: "Apr", Profit: 100 },
  { name1: "May", Profit: 200 },
  { name1: "Jun", Profit: 300 },
  { name1: "Jul", Profit: 300 },
  { name1: "Aug", Profit: 300 },
  { name1: "Sep", Profit: 100 },
  { name1: "Aug", Profit: 200 },
  { name1: "Nov", Profit: 300 },
  { name1: "Dec", Profit: 300 },
];

function RentAcarDashBoard() {
  const dispatch = useDispatch();
  const { dashboardCustomerGraph, dashboardDetails } = useSelector(
    (state: any) => state.root.rentcar
  );
  console.log("...........dashboardDetails.........", dashboardDetails);
  dashboardCustomerGraph.forEach(
    (item: { month: string; customerCount: number }, index: number) => {
      data3[index].name1 = item.month;
      data3[index].Profit = item.customerCount;
    }
  );

  const [loading, setLoading] = useState(false);
  // const [dashboardDetails, setDashboardDetails] = useState({
  //   todayRequestCount: "",
  //   totalRequest: "",
  //   totalPending: "",
  // });
  const parsedTotalRequest = parseFloat(
    dashboardDetails?.totalRequest.replace("%", "")
  );
  const parsedPending = parseFloat(
    dashboardDetails?.totalPending.replace("%", "")
  );
  // console.log("....", parsedTotalRequest);
  // console.log("....", parsedPending);
  const GetDashboard = () => {
    setLoading(true);
    rentCarDashboardDetail()
      .then((res: any) => {
        console.log("Response of DashboardDetails are", res);
        dispatch(
          setDashboardDetails({
            todayRequestCount: res.data.todayRequestCount,
            totalRequest: res.data.requestPercentageChange,
            totalPending: res.data.requestPenPercentage,
          })
        );
        // console.log("::::::::::;", dashboardDetails);
      })
      .catch((err: any) => {
        console.log("Error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  interface MonthDataItem {
    month: string;
    customersCount: number;
  }
  const GetGraph = () => {
    setLoading(true);
    rentCarGraph()
      .then((res: any) => {
        if (res?.status === 200) {
          console.log("Graph API Response...................", res);
          const last12MonthsData: MonthDataItem[] = res.data.last12MonthsData;
          // Use map to convert date to day in last12MonthsData
          const monthArray = last12MonthsData.map((item: MonthDataItem) => {
            const dateObject = new Date(item.month);
            const monthName = dateObject.toLocaleDateString("en-US", {
              month: "short",
            });
            return { month: monthName, customerCount: item.customersCount };
          });

          // console.log("monthARRAY.............", monthArray);
          dispatch(setDashboardCustomerGraph(monthArray));
        }
      })
      .catch((err: any) => {
        console.log("API Error:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    GetDashboard();
    GetGraph();
  }, []);
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      {loading ? (
        <LoadingModal showModal={loading} />
      ) : (
        <div className={commonstyles.mr87}>
          <div className={classNames(commonstyles.flx)}>
            <div className={classNames(commonstyles.col5)}>
              <div className={classNames(commonstyles.flx)}>
                <div className={style.TotalRequestCard}>
                  <div className={style.requestIconOuter}>
                    <img src={Icon2} className={style.icon} />
                  </div>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold,
                      style.mt16
                    )}
                  >
                    Today Request
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.bold,
                      style.mt8
                    )}
                  >
                    {dashboardDetails?.todayRequestCount}
                  </p>
                </div>
                <div className={style.TotalRevenueCard}>
                  <div className={style.RevenueIconOuter}>
                    <img src={Icon1} className={style.icon} />
                  </div>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold,
                      style.mt16
                    )}
                  >
                    Total Revenue
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.bold,
                      style.mt8
                    )}
                  >
                    25K
                  </p>
                </div>
              </div>
              <div className={classNames(commonstyles.col12)}>
                <div className={style.HireCancelCard}>
                  <div className={style.flxBetween}>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        commonstyles.colorBlue
                      )}
                    >
                      Hire vs Cancel
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.colorBlue
                      )}
                    >
                      Last Week
                    </p>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <RentAcarPieChart
                      data2={[
                        {
                          name: "Total Request",
                          value: dashboardDetails?.totalRequest,
                        },
                        { name: "Total Canceled", value: 300 },
                        {
                          name: "Total Pending",
                          value: dashboardDetails?.totalPending,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames(commonstyles.col12)}>
                {/* <div className={style.RevenueweekChart}>
                  <RentAcarstackBarChart />
                </div> */}
              </div>
            </div>
            <div className={classNames(commonstyles.col7)}>
              <div className={style.TotalCustomerchart}>
                <RentAcarBarChart data3={data3} />
              </div>
              {/* <div className={style.TotalRevenueChart}>
                <RentACarLineChart data={data} />
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RentAcarDashBoard;
