import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Doctor_BasicInfo } from "shared/components";
import { setDoctorFormData } from "shared/redux";

interface Props {
  handleClickNext: any;
}
const DoctorBasicInfo = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const dispatch = useDispatch();
  const handleNext = (data: any) => {
    dispatch(setDoctorFormData(data));
    handleClickNext();
  };

  return (
    <>
      <Doctor_BasicInfo handleNext={handleNext} />
    </>
  );
};

export default DoctorBasicInfo;
