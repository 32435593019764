import React, { useState } from "react";
import { ArrowBack, CustomStepper, PrimaryButton } from "shared/components";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import MainhomeStyles from "../../mainHomeServices.module.css";
import AmbulanceBasicInfo from "./AmbulanceBasicInfo/index";
import AmbulanceBankDetail from "./AmbulanceBankDetail/index";
import AmbulanceSocial from "./AmbulanceSocial";
import AmbulanceVerification from "./AmbulanceVerification";
import { useNavigate } from "react-router-dom";
import Logo from "assets/images/Ambulance 1.png";
import { IoArrowBack } from "react-icons/io5";
const steps = [
  {
    id: "1",
    lable: "BasicInfo",
  },
  {
    id: "2",
    lable: "Social",
  },
  {
    id: "3",
    lable: "Bank Details",
  },
  {
    id: "4",
    lable: "Verification",
  },
];
const AmbulanceSignup = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/homeservices/ambulanceservices/login");
  };
  const [screenName, setScreenName] = useState("BasicInfo");
  const [selectedStep, setSelectedStep] = useState(0);
  console.log("screenName....", screenName);

  const handleClickNext = () => {
    if (screenName == "BasicInfo") {
      setScreenName("Social");
    }

    if (screenName == "Social") {
      setScreenName("BankDetail");
    }

    if (screenName == "BankDetail") {
      setScreenName("Verification");
    }
    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "BasicInfo") {
      handleGoBack();
    }
    if (screenName == "Social") {
      setScreenName("BasicInfo");
    }

    if (screenName == "BankDetail") {
      setScreenName("Social");
    }

    if (screenName == "Verification") {
      setScreenName("BankDetail");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };
  return (
    <div className={classNames(MainhomeStyles.flxSign, commonStyles.container)}>
      <div
        className={classNames(
          commonStyles.col7,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <div className={classNames(commonStyles.flx)}>
          <div className={classNames(commonStyles.mr24)}>
            <IoArrowBack
              onClick={handleClicKPrev}
              className={MainhomeStyles.BackIcon}
            />
          </div>
          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs14,
              MainhomeStyles.mr203,
              commonStyles.colorBlue
            )}
          >
            Previous
          </p>

          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs24,
              commonStyles.m0
            )}
          >
            Sign Up
          </p>
        </div>
        <div className={classNames(commonStyles.col12, commonStyles.mt40)}>
          <CustomStepper steps={steps} selectedStep={selectedStep} />
        </div>
        <div className={classNames(commonStyles.col12)}>
          {screenName == "BasicInfo" && (
            <AmbulanceBasicInfo handleClickNext={handleClickNext} />
          )}
          {screenName == "Social" && (
            <AmbulanceSocial handleClickNext={handleClickNext} />
          )}
          {screenName == "BankDetail" && (
            <AmbulanceBankDetail handleClickNext={handleClickNext} />
          )}
          {screenName == "Verification" && (
            <AmbulanceVerification handleClickNext={handleClickNext} />
          )}
        </div>
      </div>
      <div
        className={classNames(
          commonStyles.col4,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <img src={Logo} className={commonStyles.loginVector} />
      </div>
    </div>
  );
};

export default AmbulanceSignup;
