import React, { useEffect, useState } from "react";
import classNames from "classnames";
import MainInsurance from "../../mainInsurance.module.css";
import commonStyles from "shared/utils/common.module.css";
import labMainStyles from "../../../Laboratory/laboratoryMain.module.css";
import { ArrowBack, CustomInput, PrimaryButton } from "shared/components";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import ImgPicker from "shared/components/Img-picker";
import { insuranceBankSchema } from "shared/utils";
import { setInsuranceUserFormData, setInsuranceUser_ID } from "shared/redux";
import { insuranceSignup } from "shared/services/Insurance";
interface Props {
  handleClickNext: any;
}
const InsuranceBankDetail = (props: Partial<Props>) => {
  const [TaxFileError, setTaxFileError] = useState(false);
  const { handleClickNext } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { insuranceUserFormData } = useSelector(
    (state: any) => state.root.insurance
  );
  const { fcmToken } = useSelector((state: any) => state.root.common);
  const formik = useFormik({
    initialValues: {
      incomeTaxNo: "",
      saleTaxNo: "",
      bankName: "",
      accountHolderName: "",
      accountNumber: "",
      TaxFile: "",
    },
    validationSchema: Yup.object(insuranceBankSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleTaxFileUrl = (url: any) => {
    formik.setFieldValue("TaxFile", url);
  };
  const handleSubmit = async () => {
    console.log("hitt........");
    dispatch(setInsuranceUserFormData({ ...formik.values }));
    handleClickNext();
    setLoading(true);
    let currentData = formik.values;
    let params = {
      name: insuranceUserFormData.name,
      logo: insuranceUserFormData.logo,
      companyLicenseNo: insuranceUserFormData.licenseNumber,
      licenseExpiry: insuranceUserFormData.licenseExpiry,
      licenseImage: insuranceUserFormData.licenseImage,
      emergencyNo: insuranceUserFormData.emergencyNumber,
      ownerFirstName: insuranceUserFormData.ownerFirstName,
      ownerLastName: insuranceUserFormData.ownerLastName,
      cnicOrPassportNo: insuranceUserFormData.cnicNumber,
      cnicOrPassportExpiry: insuranceUserFormData.cnicExpiry,
      cnicImage: insuranceUserFormData.cnicImage,
      location: {
        lat: insuranceUserFormData.lat,
        lng: insuranceUserFormData.lng,
        address: insuranceUserFormData.address,
        city: insuranceUserFormData.city,
      },

      ...(insuranceUserFormData.webUrl && {
        website: insuranceUserFormData.webUrl,
      }),
      ...(insuranceUserFormData.twitterUrl && {
        twitter: insuranceUserFormData.twitterUrl,
      }),

      ...(insuranceUserFormData.instaUrl && {
        instagram: insuranceUserFormData.instaUrl,
      }),

      incomeTaxNo: currentData.incomeTaxNo,
      salesTaxNo: currentData.saleTaxNo,
      bankName: currentData.bankName,
      accountHolderName: currentData.accountHolderName,
      accountNumber: currentData.accountNumber,
      taxFileImage: currentData.TaxFile,
      fcmToken,
    };

    insuranceSignup(params)
      .then((res: any) => {
        if (res.data && res.data.auth) {
          console.log("response.....", res.data);
          handleClickNext();
          dispatch(
            setInsuranceUserFormData({
              name: "",
              logo: "",
              licenseNumber: "",
              licenseExpiry: "",
              licenseImage: "",
              emergencyNumber: "",
              ownerFirstName: "",
              ownerLastName: "",
              cnicNumber: "",
              cnicExpiry: "",
              cnicImage: "",
              address: "",
              lat: "",
              lng: "",
              city: "",
              //........social....
              fbUrl: "",
              instaUrl: "",
              twitterUrl: "",
              webUrl: "",
            })
          );
          dispatch(setInsuranceUser_ID(res.data.insurance._id));
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.col12, commonStyles.mt56)}>
          <div className={classNames(commonStyles.mb16, MainInsurance.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Income Tax Number"
                  id="incomeTaxNo"
                  name="incomeTaxNo"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.incomeTaxNo}
                />
                {formik.touched.incomeTaxNo && formik.errors.incomeTaxNo ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.incomeTaxNo}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Sales Tax Reg. No"
                  id="saleTaxNo"
                  name="saleTaxNo"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.saleTaxNo}
                />
                {formik.touched.saleTaxNo && formik.errors.saleTaxNo ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.saleTaxNo}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <ImgPicker
                  placeholder="Attach Tax File"
                  setData={handleTaxFileUrl}
                />
              </div>
            </div>
          </div>
          <div className={classNames(MainInsurance.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Bank Name "
                  id="bankName"
                  name="bankName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.bankName}
                />
                {formik.touched.bankName && formik.errors.bankName ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.bankName}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Account Holder Name"
                  id="accountHolderName"
                  name="accountHolderName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.accountHolderName}
                />
                {formik.touched.accountHolderName &&
                formik.errors.accountHolderName ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.accountHolderName}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Account Number"
                  id="accountNumber"
                  name="accountNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.accountNumber}
                />
                {formik.touched.accountNumber && formik.errors.accountNumber ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.accountNumber}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(labMainStyles.buttonWidth)}>
          <PrimaryButton
            children={"Next"}
            type="submit"
            colorType={"Insurance"}
          />
        </div>
      </form>
    </div>
  );
};

export default InsuranceBankDetail;
