import React from "react";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "./Multi.module.css";
import SearchBar from "shared/components/Searchbar";
import Logos from "assets/images/flys.png";
import { FaEdit, FaRegCalendarAlt } from "react-icons/fa";
import { MdDeleteOutline, MdFlightTakeoff } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";
import { Navigate, useNavigate } from "react-router-dom";
export default function FlightDetailMulyiWay() {
  const navigate = useNavigate();
  const handleGoToBack = () => {
    navigate("/travelAgency/Travel/TwoWay");
  };
  return (
    <div className={classNames(commonstyles.col12, commonstyles.colorBlue)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer2}>
          <div className={classNames(style.mt24, commonstyles.colorBlue)}>
            <div className={commonstyles.flx}>
              <div className={commonstyles.flx}>
                <IoArrowBack className={style.back} onClick={handleGoToBack} />
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold,
                    commonstyles.colorBlue
                  )}
                >
                  Flight Detail
                </p>
              </div>
              <div style={{ marginLeft: "auto" }} className={commonstyles.flx}>
                <FaEdit className={style.editIcon} />
                <MdDeleteOutline className={style.deleteIcon} />
              </div>
            </div>
            <p
              className={classNames(
                commonstyles.mt32,
                commonstyles.fs20,
                commonstyles.semiBold,
                commonstyles.mb32
              )}
            >
              Flights Info
            </p>
          </div>
          <div className={classNames(style.flx, commonstyles.mb32)}>
            <div className={commonstyles.col6}>
              <div className={style.detailbox}>
                <div className={commonstyles.flx}>
                  <div className={commonstyles.flx}>
                    <FaRegCalendarAlt className={style.iconss} />
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.semiBold
                        )}
                      >
                        20 Dec 2023
                      </p>
                    </div>
                  </div>
                  <div className={classNames(commonstyles.flx, style.end)}>
                    <img src={Logos} className={style.logo} />
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.semiBold
                        )}
                      >
                        Fly Emirates
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classNames(style.flxb, style.mt32)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold
                      )}
                    >
                      Lahore
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs12,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      Allama Iqbal Airport
                    </p>
                  </div>
                  <div>
                    <MdFlightTakeoff className={style.flyy} />
                    <div className={style.border77}></div>
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold
                      )}
                    >
                      Hunza
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs12,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      Allama Iqbal Airport
                    </p>
                  </div>
                </div>
                <div className={classNames(style.flxb, style.mt32)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs12,
                        commonstyles.semiBold
                      )}
                    >
                      Depart Time
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      16:30
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      Wed, 20 Dec
                    </p>
                  </div>

                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs12,
                        commonstyles.semiBold
                      )}
                    >
                      Arrival Time
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      20:45
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      Wed, 20 Dec
                    </p>
                  </div>
                </div>
                <div className={classNames(style.flxb, style.mt32)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs12,
                        commonstyles.semiBold
                      )}
                    >
                      Flight Duration
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      2 Hour 55 Minutes
                    </p>
                  </div>

                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs12,
                        commonstyles.semiBold
                      )}
                    >
                      Class
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      Economy
                    </p>
                  </div>
                </div>
                <div className={classNames(commonstyles.flx, style.mt32)}>
                  <div className={commonstyles.flx}>
                    <FaRegCalendarAlt className={style.iconss} />
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.semiBold
                        )}
                      >
                        20 Dec 2023
                      </p>
                    </div>
                  </div>
                  <div className={classNames(commonstyles.flx, style.end)}>
                    <img src={Logos} className={style.logo} />
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.semiBold
                        )}
                      >
                        Fly Emirates
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classNames(style.flxb, style.mt32)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold
                      )}
                    >
                      Lahore
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs12,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      Allama Iqbal Airport
                    </p>
                  </div>
                  <div>
                    <MdFlightTakeoff className={style.flyy} />
                    <div className={style.border77}></div>
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold
                      )}
                    >
                      Hunza
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs12,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      Allama Iqbal Airport
                    </p>
                  </div>
                </div>
                <div className={classNames(style.flxb, style.mt32)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs12,
                        commonstyles.semiBold
                      )}
                    >
                      Depart Time
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      16:30
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      Wed, 20 Dec
                    </p>
                  </div>

                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs12,
                        commonstyles.semiBold
                      )}
                    >
                      Arrival Time
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      20:45
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      Wed, 20 Dec
                    </p>
                  </div>
                </div>
                <div className={classNames(style.flxb, style.mt32)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs12,
                        commonstyles.semiBold
                      )}
                    >
                      Flight Duration
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      2 Hour 55 Minutes
                    </p>
                  </div>

                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs12,
                        commonstyles.semiBold
                      )}
                    >
                      Class
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mt8
                      )}
                    >
                      Economy
                    </p>
                  </div>
                </div>
              </div>
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.semiBold,
                  commonstyles.mt56
                )}
              >
                Tickets Info
              </p>
              <div
                className={classNames(
                  commonstyles.flx,
                  commonstyles.col12,
                  style.mt24
                )}
              >
                <div
                  className={classNames(
                    commonstyles.col8,
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  <p className={classNames(style.mt8)}>Total Tickets</p>
                  <p className={classNames(style.mt8)}>Total Tickets Booked</p>
                  <p className={classNames(style.mt8)}>Total Tickets Refund</p>
                </div>
                <div
                  className={classNames(commonstyles.col4, commonstyles.fs16)}
                >
                  <p className={classNames(style.mt8)}>20</p>
                  <p className={classNames(style.mt8)}>25</p>
                  <p className={classNames(style.mt8)}>40</p>
                </div>
              </div>
            </div>
            <div className={commonstyles.col6}>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold
                  )}
                >
                  Amenities
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold,
                    style.mt24
                  )}
                >
                  Winglets
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold,
                    style.mt8
                  )}
                >
                  Basic Web Browsing (Free)
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold,
                    style.mt8
                  )}
                >
                  Streaming Entertainment
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold,
                    style.mt8
                  )}
                >
                  Light meal{" "}
                </p>
              </div>
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.semiBold,
                  commonstyles.mt56
                )}
              >
                Carry & Polices{" "}
              </p>
              <div
                className={classNames(
                  commonstyles.flx,
                  commonstyles.col12,
                  style.mt24
                )}
              >
                <div
                  className={classNames(
                    commonstyles.col6,
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  <p className={classNames(style.mt8)}>Hand Bag</p>
                  <p className={classNames(style.mt8)}>Baggage Weight</p>
                  <p className={classNames(style.mt8)}>Cancelation Duration</p>
                  <p className={classNames(style.mt8)}>Cancelation Deduction</p>
                </div>
                <div
                  className={classNames(commonstyles.col6, commonstyles.fs16)}
                >
                  <p className={classNames(style.mt8)}>One</p>
                  <p className={classNames(style.mt8)}>20KG</p>
                  <p className={classNames(style.mt8)}>In 3 Days</p>
                  <p className={classNames(style.mt8)}>20%</p>
                </div>
              </div>
              <p
                className={classNames(
                  commonstyles.fs20,
                  style.mt24,
                  commonstyles.semiBold
                )}
              >
                Cancel policy
              </p>
              <p className={classNames(commonstyles.fs16, style.mt16)}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
