import * as React from "react";
import tablepay from "./RentacarVehicleList.module.css";
import commonstyle from "../../../utils/common.module.css";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import empty from "assets/images/empty2.png";

interface Props {
  vahicle: any;
  setShowModal: any;
}

function RentacarVehicleList(props: Partial<Props>) {
  const { vahicle } = props;
  const navigate = useNavigate();

  const handleGoToDetail = (id: string) => {
    navigate(`/vehicleDescription/${id}`);
  };

  return (
    <div className={classNames(tablepay.App)}>
      {vahicle && vahicle.length > 0 ? (
        <div className={tablepay.tableWrapper}>
          <div className={tablepay.headerOuter}>
            <div className={tablepay.header}>
              <p className={tablepay.headerItem}>Reg. Number</p>
              <p className={tablepay.headerItem}>Reg Date</p>
              <p className={tablepay.headerItem}>Vehicle Name</p>
              <p className={tablepay.headerItem}>Model</p>
              <p className={tablepay.headerItem}>Year</p>
              <p className={tablepay.headerItem}>Color</p>
              <p className={tablepay.headerItem}>Type</p>
            </div>
          </div>
          <div className={classNames(tablepay.tableData, commonstyle.fs14)}>
            {vahicle.map((val: any, key: any) => (
              <div
                className={classNames(tablepay.dataRow, tablepay.bordr)}
                key={key}
                onClick={() => handleGoToDetail(val._id)}
              >
                <p className={tablepay.dataItem}>{val?.vehicleRegisterationNo}</p>
                <p className={tablepay.dataItem}>{val?.vehicleRegisterationDate}</p>
                <p className={tablepay.dataItem}>{val?.vehicleName}</p>
                <p className={tablepay.dataItem}>{val?.vehicleModel}</p>
                <p className={tablepay.dataItem}>{val?.vehicleYear}</p>
                <p className={tablepay.dataItem}>{val?.vehicleColour}</p>
                <p className={tablepay.dataItem}>{val?.vehicleType}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={classNames(tablepay.center)}>
          <div className={classNames(tablepay.boxm)}>
            <img src={empty} className={classNames(tablepay.empty3)} alt="No Data" />
          </div>
        </div>
      )}
    </div>
  );
}

export default RentacarVehicleList;
