import React, { useEffect, useState } from "react";
import classNames from "classnames";
import labMainStyles from "../../../../Laboratory/laboratoryMain.module.css";
import MainTravelStyle from "../../../mainTravel.module.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { travelAgencyBankSchema } from "shared/utils";
import {
  setTravelAgencyUserFormData,
  setTravelAgencyUser_ID,
} from "shared/redux";
import { CustomInput, PrimaryButton } from "shared/components";
import { travelAgencySignup } from "shared/services/TravelAgency";
import * as Yup from "yup";
import commonStyles from "shared/utils/common.module.css";
import ImgPicker from "shared/components/Img-picker";
interface Props {
  handleClickNext: any;
}
export default function TravelAgencyBankDetail(props: Partial<Props>) {
  const [TaxFileData, setTaxFileData] = useState();
  const [TaxFileError, setTaxFileError] = useState(false);
  const { handleClickNext } = props;
  const [loading, setLoading] = useState(false);
  const { fcmToken } = useSelector((state: any) => state.root.common);
  const dispatch = useDispatch();
  const { travelagencyUserFormData } = useSelector(
    (state: any) => state.root.travelagency
  );
  const formik = useFormik({
    initialValues: {
      incomeTaxNo: "",
      saleTaxNo: "",
      bankName: "",
      accountHolderName: "",
      accountNumber: "",
      TaxFile: "",
    },
    // validationSchema: Yup.object(travelAgencyBankSchema),
    onSubmit: (values) => {
      console.log(values, "here values ...............");
      handleSubmit();
    },
  });
  const handleTaxFileUrl = (url: any) => {
    setTaxFileError(false);
    formik.setFieldValue("TaxFile", url);
  };
  const handleSubmit = async () => {
    dispatch(setTravelAgencyUserFormData({ ...formik.values }));
    setLoading(true);
    let currentData = formik.values;
    let params = {
      name: travelagencyUserFormData.name,
      logo: travelagencyUserFormData.logo,
      companyLicenseNo: travelagencyUserFormData.licenseNumber,
      comapnyLicenseImage: travelagencyUserFormData.licenseImage,
      licenseExpiry: travelagencyUserFormData.licenseExpiry,
      emergencyNo: travelagencyUserFormData.emergencyNumber,
      ownerFirstName: travelagencyUserFormData.ownerFirstName,
      ownerLastName: travelagencyUserFormData.ownerLastName,
      cnicOrPassportNo: travelagencyUserFormData.cnicNumber,
      cnicOrPassportExpiry: travelagencyUserFormData.cnicExpiry,
      cnicImage: travelagencyUserFormData.cnicImage,
      location: {
        lng: travelagencyUserFormData.lng,
        lat: travelagencyUserFormData.lat,
        address: travelagencyUserFormData.address,
        city: travelagencyUserFormData.city,
      },
      ...(travelagencyUserFormData.webUrl && {
        website: travelagencyUserFormData.webUrl,
      }),
      ...(travelagencyUserFormData.twitterUrl && {
        twitter: travelagencyUserFormData.twitterUrl,
      }),
      ...(travelagencyUserFormData.fbUrl && {
        facebook: travelagencyUserFormData.fbUrl,
      }),
      ...(travelagencyUserFormData.instaUrl && {
        instagram: travelagencyUserFormData.instaUrl,
      }),

      incomeTaxNo: currentData.incomeTaxNo,
      salesTaxNo: currentData.saleTaxNo,
      bankName: currentData.bankName,
      accountHolderName: currentData.accountHolderName,
      accountNumber: currentData.accountNumber,
      taxFileImage: currentData.TaxFile,
      fcmToken,
    };
    console.log("Params before API call.........:", params);

    if (!formik.values.TaxFile) {
      setTaxFileError(true);
      setLoading(false);
      return;
    } else {
      travelAgencySignup(params)
        .then((res: any) => {
          if (res.data && res.data.auth) {
            console.log("response.....", res.data);
            handleClickNext();
            dispatch(setTravelAgencyUserFormData({}));
            dispatch(setTravelAgencyUser_ID(res.data.travelAgency._id));
          }
        })
        .catch((err: any) => {
          console.log("send error.nnnnn..", err.response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };
  console.log("Formik values before submit:", formik.values);

  return (
    <div className={classNames(commonStyles.col12, commonStyles.mt56)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, MainTravelStyle.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="Income Tax Number (Optional)"
                id="incomeTaxNo"
                name="incomeTaxNo"
                type="text"
                // onChange={formik.handleChange}
                // value={formik.values.incomeTaxNo}
              />
              {/* {formik.touched.incomeTaxNo && formik.errors.incomeTaxNo ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.incomeTaxNo}
                </div>
              ) : null} */}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="Sales Tax Reg. No (Optional)"
                id="saleTaxNo"
                name="saleTaxNo"
                // type="text"
                // onChange={formik.handleChange}
                // value={formik.values.saleTaxNo}
              />
              {/* {formik.touched.saleTaxNo && formik.errors.saleTaxNo ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.saleTaxNo}
                </div>
              ) : null} */}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <div className={commonStyles.mr32}>
              <ImgPicker
                placeholder="Attach Tax File (Optional)"
                setData={handleTaxFileUrl}
              />
              {/* {formik.touched.TaxFile && formik.errors.TaxFile ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.TaxFile}
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, MainTravelStyle.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="Bank Name (Optional)"
                id="bankName"
                name="bankName"
                // type="text"
                // onChange={formik.handleChange}
                // value={formik.values.bankName}
              />
              {/* {formik.touched.bankName && formik.errors.bankName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.bankName}
                </div>
              ) : null} */}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="Account Holder Name (Optional)"
                id="accountHolderName"
                name="accountHolderName"
                // type="text"
                // onChange={formik.handleChange}
                // value={formik.values.accountHolderName}
              />
              {/* {formik.touched.accountHolderName &&
              formik.errors.accountHolderName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.accountHolderName}
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
        <div className={classNames(MainTravelStyle.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.mtsm28,
              commonStyles.colsm12
            )}
          >
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="Account Number (Optional)"
                id="accountNumber"
                name="accountNumber"
                // type="text"
                // onChange={formik.handleChange}
                // value={formik.values.accountNumber}
              />
              {/* {formik.touched.accountNumber && formik.errors.accountNumber ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.accountNumber}
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
        <div className={classNames(labMainStyles.buttonWidth)}>
          <PrimaryButton children={"Next"} type="submit" colorType={"Travel"} />
        </div>
      </form>
    </div>
  );
}
