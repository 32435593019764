import React from "react";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "./MultiTable.module.css";
import { Navigate, useNavigate } from "react-router-dom";
const data = [
  {
    Depart: "28/10/2023",
    Form: "Lahore",
    DepartTime: "5:30 PM",
    To: "London",
    Arrival: "9:30 PM",
    ActualPrice: "10,7899 rs/-",
    MeditourPrice: "10,0899 rs/-",
    Class: "Business Class",
  },
  {
    Depart: "28/10/2023",
    Form: "Lahore",
    DepartTime: "5:30 PM",
    To: "London",
    Arrival: "9:30 PM",
    ActualPrice: "10,7899 rs/-",
    MeditourPrice: "10,0899 rs/-",
    Class: "Business Class",
  },
  {
    Depart: "28/10/2023",
    Form: "Lahore",
    DepartTime: "5:30 PM",
    To: "London",
    Arrival: "9:30 PM",
    ActualPrice: "10,7899 rs/-",
    MeditourPrice: "10,0899 rs/-",
    Class: "Business Class",
  },
  {
    Depart: "28/10/2023",
    Form: "Lahore",
    DepartTime: "5:30 PM",
    To: "London",
    Arrival: "9:30 PM",
    ActualPrice: "10,7899 rs/-",
    MeditourPrice: "10,0899 rs/-",
    Class: "Business Class",
  },
  {
    Depart: "28/10/2023",
    Form: "Lahore",
    DepartTime: "5:30 PM",
    To: "London",
    Arrival: "9:30 PM",
    ActualPrice: "10,7899 rs/-",
    MeditourPrice: "10,0899 rs/-",
    Class: "Business Class",
  },
  {
    Depart: "28/10/2023",
    Form: "Lahore",
    DepartTime: "5:30 PM",
    To: "London",
    Arrival: "9:30 PM",
    ActualPrice: "10,7899 rs/-",
    MeditourPrice: "10,0899 rs/-",
    Class: "Business Class",
  },
  {
    Depart: "28/10/2023",
    Form: "Lahore",
    DepartTime: "5:30 PM",
    To: "London",
    Arrival: "9:30 PM",
    ActualPrice: "10,7899 rs/-",
    MeditourPrice: "10,0899 rs/-",
    Class: "Business Class",
  },
  {
    Depart: "28/10/2023",
    Form: "Lahore",
    DepartTime: "5:30 PM",
    To: "London",
    Arrival: "9:30 PM",
    ActualPrice: "10,7899 rs/-",
    MeditourPrice: "10,0899 rs/-",
    Class: "Business Class",
  },

  {
    Depart: "28/10/2023",
    Form: "Lahore",
    DepartTime: "5:30 PM",
    To: "London",
    Arrival: "9:30 PM",
    ActualPrice: "10,7899 rs/-",
    MeditourPrice: "10,0899 rs/-",
    Class: "Business Class",
  },
];

export default function MultiWayTable() {
  const navigate = useNavigate();
  const handleGoToDetail = () => {
    navigate("/flightDetailMultiway");
  };
  return (
    <div className={classNames(style.App)}>
      <div className={style.payment}>
        <div className={style.headerOuter}>
          <p className={style.headerclass}>Depart</p>
          <p className={style.headerclass}>From</p>
          <p className={style.headerclass}>Depart Time</p>
          <p className={style.headerclass}>To</p>
          <p className={style.headerclass}>Arrival Time</p>
          <p className={style.headerclass}>Actual Price</p>
          <p className={style.headerclass}>Meditour Price</p>
          <p className={style.headerclass}>Class</p>
        </div>

        <div className={style.tableData}>
          <table
            style={{
              margin: "0px",
              // borderCollapse: "separate",
              // borderSpacing: "0 10px",
            }}
          >
            <tbody className={style.wapper}>
              {data.map((val, key) => {
                return (
                  <tr
                    className={style.tableRow}
                    key={key}
                    onClick={handleGoToDetail}
                  >
                    <td className={style.w20}>{val.Depart}</td>
                    <td className={style.w20}>{val.Form}</td>
                    <td className={style.w20}>{val.DepartTime}</td>
                    <td className={style.w20}>{val.To}</td>
                    <td className={style.w20}>{val.Arrival}</td>
                    <td className={style.w20}>{val.ActualPrice}</td>
                    <td className={style.w20}>{val.MeditourPrice}</td>
                    <td className={style.w20}>{val.Class}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
