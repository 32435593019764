import React, { useState } from "react";
import { ArrowBack, CustomStepper, PrimaryButton } from "shared/components";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import MainhomeStyles from "../../mainHomeServices.module.css";
import ParamedicStaffBasicInfo from "./ParamedicStafftBasicInfo";
import ParamedicStaffSocial from "./ParamedicStaffSocial";
import ParamedicStaffBankDetail from "./ParamedicStaffStaffBankDetail";
import ParamedicStaffVerification from "./ParamedicStaffVerification";
import { useNavigate } from "react-router-dom";
import Logo from "assets/images/First Aid 1.png";
import { IoArrowBack } from "react-icons/io5";
const steps = [
  {
    id: "1",
    lable: "Basic Info",
  },
  {
    id: "2",
    lable: "Social",
  },
  {
    id: "3",
    lable: "Bank Details",
  },
  {
    id: "4",
    lable: "Verification",
  },
];
const ParamedicStaffSignup = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/homeservices/paramedicstaff/login");
  };

  const [screenName, setScreenName] = useState("Basic Info");
  const [selectedStep, setSelectedStep] = useState(0);
  console.log("screenName....", screenName);

  const handleClickNext = () => {
    if (screenName == "Basic Info") {
      setScreenName("Social");
    }

    if (screenName == "Social") {
      setScreenName("Bank Details");
    }

    if (screenName == "Bank Details") {
      setScreenName("Verification");
    }
    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "Basic Info") {
      handleGoBack();
    }
    if (screenName == "Social") {
      setScreenName("Basic Info");
    }

    if (screenName == "Bank Details") {
      setScreenName("Social");
    }

    if (screenName == "Verification") {
      setScreenName("Bank Details");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };
  return (
    <div className={classNames(MainhomeStyles.flxSign, commonStyles.container)}>
      <div
        className={classNames(
          commonStyles.col7,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <div className={classNames(commonStyles.flx)}>
          <div className={classNames(commonStyles.mr24)}>
            <IoArrowBack
              onClick={handleClicKPrev}
              className={MainhomeStyles.BackIcon}
            />
          </div>
          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs14,
              MainhomeStyles.mr203,
              commonStyles.colorBlue
            )}
          >
            Previous
          </p>

          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs24,
              commonStyles.m0
            )}
          >
            Sign Up
          </p>
        </div>
        <div
          className={classNames(
            commonStyles.col12,

            commonStyles.mt40
          )}
        >
          <CustomStepper steps={steps} selectedStep={selectedStep} />
        </div>
        <div className={classNames(commonStyles.col12)}>
          {screenName == "Basic Info" && (
            <ParamedicStaffBasicInfo handleClickNext={handleClickNext} />
          )}
          {screenName == "Social" && (
            <ParamedicStaffSocial handleClickNext={handleClickNext} />
          )}
          {screenName == "Bank Details" && (
            <ParamedicStaffBankDetail handleClickNext={handleClickNext} />
          )}
          {screenName == "Verification" && (
            <ParamedicStaffVerification handleClickNext={handleClickNext} />
          )}
        </div>
      </div>
      <div
        className={classNames(
          commonStyles.col4,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <img src={Logo} className={commonStyles.loginVector} />
      </div>
    </div>
  );
};

export default ParamedicStaffSignup;
