import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SideBar } from "shared/components";
import classNames from "classnames";
import { HotelMenu } from "shared/utils";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
import HotalSetting from "pages/TravelTourism/Hotels/HotalSetting";
import HotalPayment from "pages/TravelTourism/Hotels/HotalPayment";
import HotelDashboard from "pages/TravelTourism/Hotels/HotelDashboard";
import HotelProperty from "pages/TravelTourism/Hotels/HotelProperty/index";
import HotelReserve from "pages/TravelTourism/Hotels/HotelReserve/index";
import HotelBooking from "pages/TravelTourism/Hotels/HotelBooking";
import GuestDiscription from "pages/TravelTourism/Hotels/HotelBooking/GuestDiscription";
import PaymentDescription from "pages/TravelTourism/Hotels/HotalPayment/PaymentDescription";
import Chat from "shared/components/Chat";
const HotelMainRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/hotel/dashboard");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: "0px",
          height: "100vh",
        }}
      >
        <SideBar menuItem={HotelMenu} />
      </div>
      <Routes>
        <Route path="hotel/dashboard" element={<HotelDashboard />} />
        <Route path="hotel/property/*" element={<HotelProperty />} />
        <Route path="hotel/reserve" element={<HotelReserve />} />
        <Route path="hotel/booking" element={<HotelBooking />} />
        <Route
          path="hotel/guestDescription/:id"
          element={<GuestDiscription />}
        />
        <Route path="hotel/payment" element={<HotalPayment />} />
        <Route path="hotel/paymentDetails" element={<PaymentDescription />} />
        <Route path="hotel/setting" element={<HotalSetting />} />
        <Route path="hotel/privacy" element={<PrivacyPolicy />} />
        <Route path="chat/message" element={<Chat />} />
      </Routes>
    </div>
  );
};

export default HotelMainRoutes;
