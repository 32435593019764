import React from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import Style from "./ambulancePayment.module.css";
import { Vendor_PaymentDetails } from "shared/components";
const Ambulance_Payments_Details = () => {
  return (
    <div className={classNames(commonstyles.col12, Style.outer)}>
      <SearchBar />
      <Vendor_PaymentDetails />
    </div>
  );
};

export default Ambulance_Payments_Details;
