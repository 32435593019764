import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hotelCompleteSignup,
  hotelSendCodeToEmail,
  hotelConfirmEmail,
} from "shared/services/Hotel";

import { Doctor_Verification } from "shared/components";
import { set_IsEmailVerified } from "shared/redux";

interface Props {
  handleClickNext: any;
}
const HotelVerification = (props: Partial<Props>) => {
  const { hoteluser_id } = useSelector((state: any) => state.root.hotel);
  const dispatch = useDispatch();

  const handleSendCodeToEmail = (
    params: any,
    setVerifyLoading: any,
    setVerifyError: any,
    handleTimer: any
  ) => {
    hotelSendCodeToEmail(params)
      .then((res: any) => {
        handleTimer();
      })
      .catch((err: any) => {
        setVerifyError(err.response.data.message);
      })
      .finally(() => {
        setVerifyLoading(false);
      });
  };

  const handleSubmitCode = (
    params: any,
    setVerifyLoading: any,
    setCodeError: any,
    handleVerifySuccess: any
  ) => {
    hotelConfirmEmail(params)
      .then((res: any) => {
        handleVerifySuccess(res);
      })
      .catch((err: any) => {
        setCodeError(err.response.data.message);
      })
      .finally(() => {
        setVerifyLoading(false);
      });
  };

  const handleSubmit = async (params: any, handleSignupSuccess: any) => {
    hotelCompleteSignup(params, hoteluser_id)
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          handleSignupSuccess();
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        dispatch(set_IsEmailVerified(false));
      });
  };

  return (
    <>
      <Doctor_Verification
        handle_SendCodeToEmail={handleSendCodeToEmail}
        handle_SubmitCode={handleSubmitCode}
        handle_CompleteSignup={handleSubmit}
      />
    </>
  );
};

export default HotelVerification;
