import { Hospital_Login, Hospital_Signup } from "pages";
import HospitalForget from "pages/Hospital/Forgetpassword/HospitalForget";
import HospitalUpdatepassword from "pages/Hospital/Forgetpassword/HospitalUpdatepassword";
import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

const Hospital_Auth_Routes = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<Hospital_Login />} />
      <Route path="/signup" element={<Hospital_Signup />} />
      <Route path="/forgot-password" element={<HospitalForget />} />
      <Route path="/update-password" element={<HospitalUpdatepassword />} />
    </Routes>
  );
};

export default Hospital_Auth_Routes;
