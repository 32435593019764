import React, { useState } from "react";
import AdminNavBar from "../Components/AdminNavBar";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import Styles from "./percentage.module.css";
import { PrimaryButton } from "shared/components";
import { Style } from "@mui/icons-material";
export default function Percentage() {
  return (
    <div className={classNames(commonStyles.col12)}>
      <div className={Styles.Navouter}>
        <AdminNavBar labelText="Percentage" />
      </div>
      <div className={classNames(Styles.mainOuter)}>
        <div className={Styles.flx}>
          <p
            className={classNames(
              commonStyles.fs18,
              commonStyles.medium,
              Styles.textcolor,
              Styles.textwidth
            )}
          >
            Doctor:
          </p>
          <div className={Styles.inputFlx}>
            <input className={Styles.Inputs} defaultValue="0" type="number" />
            <p
              className={classNames(
                commonStyles.fs20,
                commonStyles.medium,
                Styles.textcolor
              )}
            >
              %
            </p>
          </div>
          <div className={Styles.BtnWidth}>
            <PrimaryButton children={"Save"} colorType={"blue"} />
          </div>
        </div>
        <div className={classNames(Styles.flx, Styles.mt32)}>
          <p
            className={classNames(
              commonStyles.fs18,
              commonStyles.medium,
              Styles.textcolor,
              Styles.textwidth
            )}
          >
            Hospital:
          </p>
          <div className={Styles.inputFlx}>
            <input className={Styles.Inputs} defaultValue="0" type="number" />
            <p
              className={classNames(
                commonStyles.fs20,
                commonStyles.medium,
                Styles.textcolor
              )}
            >
              %
            </p>
          </div>
          <div className={Styles.BtnWidth}>
            <PrimaryButton children={"Save"} colorType={"blue"} />
          </div>
        </div>
        <div className={classNames(Styles.flx, Styles.mt32)}>
          <p
            className={classNames(
              commonStyles.fs18,
              commonStyles.medium,
              Styles.textcolor,
              Styles.textwidth
            )}
          >
            Hotels:
          </p>
          <div className={Styles.inputFlx}>
            <input className={Styles.Inputs} defaultValue="0" type="number" />
            <p
              className={classNames(
                commonStyles.fs20,
                commonStyles.medium,
                Styles.textcolor
              )}
            >
              %
            </p>
          </div>
          <div className={Styles.BtnWidth}>
            <PrimaryButton children={"Save"} colorType={"blue"} />
          </div>
        </div>{" "}
        <div className={classNames(Styles.flx, Styles.mt32)}>
          <p
            className={classNames(
              commonStyles.fs18,
              commonStyles.medium,
              Styles.textcolor,
              Styles.textwidth
            )}
          >
            Travel Agency:
          </p>
          <div className={Styles.inputFlx}>
            <input className={Styles.Inputs} defaultValue="0" type="number" />
            <p
              className={classNames(
                commonStyles.fs20,
                commonStyles.medium,
                Styles.textcolor
              )}
            >
              %
            </p>
          </div>
          <div className={Styles.BtnWidth}>
            <PrimaryButton children={"Save"} colorType={"blue"} />
          </div>
        </div>{" "}
        <div className={classNames(Styles.flx, Styles.mt32)}>
          <p
            className={classNames(
              commonStyles.fs18,
              commonStyles.medium,
              Styles.textcolor,
              Styles.textwidth
            )}
          >
            Rent a Car:
          </p>
          <div className={Styles.inputFlx}>
            <input className={Styles.Inputs} defaultValue="0" type="number" />
            <p
              className={classNames(
                commonStyles.fs20,
                commonStyles.medium,
                Styles.textcolor
              )}
            >
              %
            </p>
          </div>
          <div className={Styles.BtnWidth}>
            <PrimaryButton children={"Save"} colorType={"blue"} />
          </div>
        </div>{" "}
        <div className={classNames(Styles.flx, Styles.mt32)}>
          <p
            className={classNames(
              commonStyles.fs18,
              commonStyles.medium,
              Styles.textcolor,
              Styles.textwidth
            )}
          >
            Ambulance:
          </p>
          <div className={Styles.inputFlx}>
            <input className={Styles.Inputs} defaultValue="0" type="number" />
            <p
              className={classNames(
                commonStyles.fs20,
                commonStyles.medium,
                Styles.textcolor
              )}
            >
              %
            </p>
          </div>
          <div className={Styles.BtnWidth}>
            <PrimaryButton children={"Save"} colorType={"blue"} />
          </div>
        </div>{" "}
        <div className={classNames(Styles.flx, Styles.mt32)}>
          <p
            className={classNames(
              commonStyles.fs18,
              commonStyles.medium,
              Styles.textcolor,
              Styles.textwidth
            )}
          >
            Insurance:
          </p>
          <div className={Styles.inputFlx}>
            <input className={Styles.Inputs} defaultValue="0" type="number" />
            <p
              className={classNames(
                commonStyles.fs20,
                commonStyles.medium,
                Styles.textcolor
              )}
            >
              %
            </p>
          </div>
          <div className={Styles.BtnWidth}>
            <PrimaryButton children={"Save"} colorType={"blue"} />
          </div>
        </div>{" "}
        <div className={classNames(Styles.flx, Styles.mt32)}>
          <p
            className={classNames(
              commonStyles.fs18,
              commonStyles.medium,
              Styles.textcolor,
              Styles.textwidth
            )}
          >
            Donation:
          </p>
          <div className={Styles.inputFlx}>
            <input className={Styles.Inputs} defaultValue="0" type="number" />
            <p
              className={classNames(
                commonStyles.fs20,
                commonStyles.medium,
                Styles.textcolor
              )}
            >
              %
            </p>
          </div>
          <div className={Styles.BtnWidth}>
            <PrimaryButton children={"Save"} colorType={"blue"} />
          </div>
        </div>{" "}
      </div>
    </div>
  );
}
