import React, { useEffect, useState } from "react";
import style from "../single.module.css";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { CustomInput, PrimaryButton } from "shared/components";
import commonstyle from "shared/utils/common.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import ImgPicker from "shared/components/Img-picker";
import CustomSelect from "shared/components/CustomSelect";
import { insuranceTravelIndividualSchema } from "shared/utils";
import { setInsuranceTravelIndividualPackage } from "shared/redux";
import { Checkbox } from "@mui/material";

const Medical = ["10,000", "20,000", "30,000", "40,000", "50,000"];
const PackageCategory = ["Silver", "Gold", "Platinum"];
const Covering = [
  "1 Day",
  "2 Days",
  "3 Days",
  "4 Days",
  "5 Days",
  "6 Days",
  "7 Days",
  "8 Days",
  "9 Days",
  "10 Days",
];

interface Props {
  handleClickNext: any;
}

export default function BasicInfoCovering(props: Partial<Props>) {
  const [error, setError] = React.useState("");
  const { handleClickNext } = props;
  const dispatch = useDispatch();
  const [isSpecificCountryChecked, setIsSpecificCountryChecked] = useState<
    boolean | undefined
  >(undefined);

  const formik = useFormik({
    initialValues: {
      packageName: "",
      packageLogo: "",
      medicalCover: "",
      coveringUpTo: "",
      packageDescription: "",
      packageCategory: "",
      CountryName: "",
    },
    validationSchema: Yup.object(insuranceTravelIndividualSchema),
    onSubmit: (values) => {
      console.log("onsubmit vlues", formik.values);
      handleSubmit();
    },
  });

  const handleCheckboxChange = (isSpecific: boolean) => {
    setIsSpecificCountryChecked(isSpecific);
    if (!isSpecific) {
      formik.setFieldValue("CountryName", "package for world wide");
    } else {
      formik.setFieldValue("CountryName", "");
    }
  };

  const handleCountryNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isSpecificCountryChecked) {
      formik.setFieldValue("CountryName", e.target.value);
    }
  };

  const handleLogoUrl = (url: any) => {
    formik.setFieldValue("packageLogo", url);
  };

  const handleMedicalCover = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("medicalCover", selectedOption);
  };

  const handleCovering = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("coveringUpTo", selectedOption);
  };

  const packageCategoryhandle = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("packageCategory", selectedOption);
  };
  const handleSubmit = () => {
    console.log("done");
    if (!formik.values.CountryName && isSpecificCountryChecked) {
      setError(
        "Please enter a Country Name when 'Specific Country' is selected."
      );
    } else {
      console.log("..........values", formik.values);
      dispatch(setInsuranceTravelIndividualPackage({ ...formik.values }));
      handleClickNext();
    }
  };

  useEffect(() => {
    console.log("Formikvalues are", formik.values);
  }, [formik.values]);
  return (
    <div className={classNames(commonStyles.col6)}>
      <form onSubmit={formik.handleSubmit}>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col10,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6, commonStyles.mr24)}>
            <CustomInput
              placeholder="Package Name"
              id="packageName"
              name="packageName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.packageName}
            />
            {formik.touched.packageName && formik.errors.packageName ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.packageName}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonStyles.col6)}>
            <ImgPicker placeholder="Package Logo" setData={handleLogoUrl} />
            {formik.touched.packageLogo && formik.errors.packageLogo ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.packageLogo}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col10,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col12)}>
            <CustomSelect
              onSelect={handleMedicalCover}
              placeholder="Medical cover"
              options={Medical}
            />
            {formik.touched.medicalCover && formik.errors.medicalCover ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.medicalCover}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col10,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col12)}>
            <CustomSelect
              onSelect={handleCovering}
              placeholder="Covering up to"
              options={Covering}
            />
            {formik.touched.coveringUpTo && formik.errors.coveringUpTo ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.coveringUpTo}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col10,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col12)}>
            <CustomInput
              placeholder="Package Description"
              id="packageDescription"
              name="packageDescription"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.packageDescription}
            />
            {formik.touched.packageDescription &&
            formik.errors.packageDescription ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.packageDescription}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col10,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col12)}>
            <CustomSelect
              onSelect={packageCategoryhandle}
              placeholder="Package Category"
              options={PackageCategory}
            />
            {formik.touched.packageCategory && formik.errors.packageCategory ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.packageCategory}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <p className={style.bold}>Traveling</p>
        </div>
        <div className={commonstyle.flx}>
          <div>
            <Checkbox
              className={style.checkbox}
              checked={isSpecificCountryChecked === false}
              onChange={() => handleCheckboxChange(false)}
            />
          </div>
          <p>Package for Worldwide (multi tour)</p>
        </div>
        <div className={commonstyle.flx}>
          <div>
            <Checkbox
              className={style.checkbox}
              checked={isSpecificCountryChecked === true}
              onChange={() => handleCheckboxChange(true)}
            />
          </div>
          <p>Specific Country</p>
        </div>
        {isSpecificCountryChecked && (
          <div className={classNames(commonStyles.col12)}>
            <CustomInput
              placeholder="Country Name"
              id="CountryName"
              name="CountryName"
              type="text"
              onChange={(e: any) => {
                formik.handleChange(e);
                handleCountryNameChange(e);
              }}
              value={formik.values.CountryName}
            />
            {formik.touched.CountryName && formik.errors.CountryName ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.CountryName}
              </div>
            ) : null}
          </div>
        )}
        {error && (
          <div className={classNames(commonStyles.error)}>*{error}</div>
        )}
        <div style={{ width: "210px", marginTop: "56px" }}>
          <PrimaryButton
            children={"Next"}
            colorType={"Insurance"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
