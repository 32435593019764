import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import { CheckBox } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Checkbox } from "@mui/material";
import { orange } from "@mui/material/colors";
import style from "./layout&Price.module.css";
import { PiPlusSquareFill } from "react-icons/pi";
import CustomSelect from "shared/components/CustomSelect";
import { useSelector, useDispatch } from "react-redux";
import { hotelAddHomeLayout } from "shared/utils";
import { setHotelAddRoom } from "shared/redux";
import ImagePickerNew from "shared/components/FilePickeInsurance/ImagePickerNew";
import ShortImagePicker from "shared/components/FilePickeInsurance/ShortImageFilePicker";
const Apartments = ["Luxury", "Standard ", "Economy"];
const Numberofbed = ["Number of bed rooms", "1", "2", "3", "4", "5"];
const livingrooms = ["Number of living rooms", "1", "2", "3", "4", "5"];
const bathroom = ["Number of bathroom", "1", "2", "3", "4", "5"];
const rooms = ["Number of rooms", "1", "2", "3", "4", "5"];
const BedsOptions = [
  "Single bed(s)  / 90-130 cm wide ",
  "Double bed(s) / 131-150 cm wide",
  "Large bed(s) (King size) / 151-180 cm wide",
  "Extra-large double bed(s) (Super-King size) / 181-210 cm wide",
  "Bunk bed(s) / Variable Size",
  "Sofa bed(s) / Variable Size",
  "Futon Mat(s) / Variable Size",
];
const numberofbed = ["Select the number of bed", "1", "2", "3", "4", "5"];
const guest = ["How many guest can stay in this room ", "1", "2", "3"];
const Square = ["0 Square metres", "1", "2", "3", "4", "5"];
const Sofabeds = ["Number of Sofa beds in the room", "1", "2", "3", "4", "5"];
const manyguest = [
  "How many guest can stay in this room",
  "1",
  "2",
  "3",
  "4",
  "5",
];
interface Props {
  handleClickNext: any;
}
export default function LayoutPrice(props: Partial<Props>) {
  const { handleClickNext } = props;
  const [imageURLs, setImageURLs] = useState<string[]>([]);
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();
  const [selectedBeds, setSelectedBeds] = useState<
    { availableBeds: string; noOfBeds: string }[]
  >([]);
  const { hotelAddRoom } = useSelector((state: any) => state.root.hotel);
  console.log("layout Price screen.....................", hotelAddRoom);
  const [isPrivateBathroom, setIsPrivateBathroom] = useState(false);
  const handleCheckboxChange = (event: any) => {
    console.log("evenet call", event.target.checked);
    setIsPrivateBathroom(event.target.checked);
    formik.setFieldValue("privateBatroom", isPrivateBathroom);
  };

  const firstImageURL = (url: any) => {
    console.log("......URL IS......", url);
    if (imageURLs.length < 3) {
      setImageURLs((prevURLs) => [...prevURLs, url]);
    } else {
      setError("Maximum limit reached. Can't add more Image.");
    }
  };

  const handleRoomType = (selectedOption: string) => {
    formik.setFieldValue("homeType", selectedOption);
  };

  const handleBedRoomSelect = (selectedOption: string) => {
    formik.setFieldValue("noofBedRoom", Number(selectedOption));
  };
  const handleDiningRooms = (selectedOption: string) => {
    formik.setFieldValue("noofDiningRoom", Number(selectedOption));
  };
  const handleBathRoomSelect = (selectedOption: string) => {
    formik.setFieldValue("noofBathRoom", Number(selectedOption));
  };

  const handleGuestSelect = (selectedOption: string) => {
    formik.setFieldValue("guestno", Number(selectedOption));
  };
  const handleCommonSofaSelect = (selectedOption: string) => {
    formik.setFieldValue("commonSofaBed", Number(selectedOption));
  };
  const handleCommonGuestSelect = (selectedOption: string) => {
    formik.setFieldValue("commonGuest", Number(selectedOption));
  };
  const handleAppartmentSizeSelect = (selectedOption: string) => {
    formik.setFieldValue("appartmentSize", selectedOption);
  };

  const handleKitchens = (selectedOption: string) => {
    formik.setFieldValue("noofKitchens", Number(selectedOption));
  };
  const handlenoofFloor = (selectedOption: string) => {
    formik.setFieldValue("noofFloor", Number(selectedOption));
  };
  const handleAvailableBeds = (selectedOption: string) => {
    formik.setFieldValue("availableBeds", Number(selectedOption));
  };
  const handlenoofBEDS = (selectedOption: string) => {
    formik.setFieldValue("noofBEDS", Number(selectedOption));
  };
  // const handleBedsOptions = (selectedOption: string) => {
  //   console.log("Selected Option:", selectedOption);
  //   formik.setFieldValue("selectBeds", selectedOption);
  // };
  // const handlenoofBeds = (selectedOption: string) => {
  //   formik.setFieldValue("noOfBeds", selectedOption);
  // };
  const handleAddBed = () => {
    if (selectedBeds.length < 2) {
      setSelectedBeds((prevSelectedBeds) => [
        ...prevSelectedBeds,
        { availableBeds: "", noOfBeds: "" },
      ]);
    }
  };

  const handleBedKindSelect = (selectedOption: string, index: number) => {
    const updatedBeds = [...selectedBeds];
    updatedBeds[index].availableBeds = selectedOption;
    setSelectedBeds(updatedBeds);
  };

  const handleNoOfBedsSelect = (selectedOption: string, index: number) => {
    const updatedBeds = [...selectedBeds];
    updatedBeds[index].noOfBeds = selectedOption;
    setSelectedBeds(updatedBeds);
  };
  const formik = useFormik({
    initialValues: {
      homeType: "",
      customName: "",
      noofBedRoom: "",
      noofDiningRoom: "",
      noofBathRoom: "",
      noofKitchens: "",
      noofFloor: "",

      guestno: "",
      privateBatroom: true,
      //
      commonSofaBed: "",
      commonGuest: "",
      appartmentSize: "",
      basicpricePerNight: "",
      pricepernightformeditour: "",
    },
    validationSchema: Yup.object(hotelAddHomeLayout),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    dispatch(
      setHotelAddRoom({
        ...hotelAddRoom,
        ...formik.values,
        beds: selectedBeds,
        homeImages: imageURLs,
      })
    );
    handleClickNext();
    console.log("............", selectedBeds);
    console.log("private bathroom ", isPrivateBathroom);
    // console.log("Formik values", formik.values);
  };

  useEffect(() => {
    console.log("imageURLs", imageURLs);
  }, [imageURLs]);
  return (
    <div className={classNames(commonstyles.col6)}>
      <form onSubmit={formik.handleSubmit}>
        <p
          className={classNames(
            commonstyles.fs24,
            commonstyles.semiBold,
            commonstyles.colorBlue,
            commonstyles.mb28
          )}
        >
          Homes
        </p>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomSelect
              options={Apartments}
              placeholder="Home Type"
              onSelect={handleRoomType}
            />
            {formik.touched.homeType && formik.errors.homeType ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.homeType}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonstyles.col6)}>
            <CustomInput
              placeholder="Custom Name"
              id="customName"
              name="customName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.customName}
            />
            {formik.touched.customName && formik.errors.customName ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.customName}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomSelect
              options={Numberofbed}
              placeholder="Number of bed rooms"
              onSelect={handleBedRoomSelect}
            />
            {formik.touched.noofBedRoom && formik.errors.noofBedRoom ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.noofBedRoom}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonstyles.col6)}>
            <CustomSelect
              options={Numberofbed}
              placeholder="Number of Dinings rooms"
              onSelect={handleDiningRooms}
            />
            {formik.touched.noofDiningRoom && formik.errors.noofDiningRoom ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.noofDiningRoom}
              </div>
            ) : null}
          </div>
        </div>

        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomSelect
              options={bathroom}
              placeholder="Number of bathroom"
              onSelect={handleBathRoomSelect}
            />
            {formik.touched.noofBathRoom && formik.errors.noofBathRoom ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.noofBathRoom}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <CustomSelect
              options={rooms}
              placeholder="How Many Kitchens "
              onSelect={handleKitchens}
            />
            {formik.touched.noofKitchens && formik.errors.noofKitchens ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.noofKitchens}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomSelect
              options={bathroom}
              placeholder="Number of Floor"
              onSelect={handlenoofFloor}
            />
            {formik.touched.noofFloor && formik.errors.noofFloor ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.noofFloor}
              </div>
            ) : null}
          </div>
          {/* <div className={commonstyles.col6}>
            <CustomSelect
              options={rooms}
              placeholder="Available Beds"
              onSelect={handleAvailableBeds}
            />
            {formik.touched.availableBeds && formik.errors.availableBeds ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.availableBeds}
              </div>
            ) : null}
          </div> */}
        </div>

        {/* <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6)}>
            <CustomSelect
              options={bathroom}
              placeholder="Set the number of Bed"
              onSelect={handlenoofBEDS}
            />
            {formik.touched.noofBEDS && formik.errors.noofBEDS ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.noofBEDS}
              </div>
            ) : null}
          </div>
        </div> */}

        <div>
          <p
            className={classNames(
              commonstyles.fs24,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Bed options
          </p>
          <p
            className={classNames(
              commonstyles.fs15,
              style.mt16,
              commonstyles.colorBlue
            )}
          >
            What kind of beds are available in this room?
          </p>
        </div>
        <div>
          {/* <div className={classNames(commonstyles.col12, style.mt16)}>
            <CustomSelect
              options={BedsOptions}
              placeholder="Available Beds"
              onSelect={handlenoofFloor}
            />
          </div>

          <div className={classNames(commonstyles.col12, style.mt16)}>
            <CustomSelect
              options={numberofbed}
              placeholder="Select the number of bed"
              onSelect={handlenoofFloor}
            />
          </div> */}
          <div
            className={classNames(commonstyles.flx, style.mt8)}
            onClick={handleAddBed}
          >
            <PiPlusSquareFill className={style.plus} />{" "}
            <p
              className={classNames(
                commonstyles.fs12,
                commonstyles.semiBold,
                style.colorOrange
              )}
            >
              Add kind of beds
            </p>
          </div>
        </div>

        {selectedBeds.map((bed, index) => (
          <>
            <div>
              <div className={classNames(commonstyles.col12, style.mt16)}>
                <CustomSelect
                  options={BedsOptions}
                  placeholder="Available Beds"
                  onSelect={(selectedOption) =>
                    handleBedKindSelect(selectedOption, index)
                  }
                />
                {formik.touched[
                  selectedBeds[index]
                    ?.availableBeds as keyof typeof formik.touched
                ] &&
                formik.errors[
                  selectedBeds[index]
                    ?.availableBeds as keyof typeof formik.errors
                ] ? (
                  <div className={classNames(commonstyles.error)}>
                    *
                    {
                      formik.errors[
                        selectedBeds[index]
                          ?.availableBeds as keyof typeof formik.errors
                      ]
                    }
                  </div>
                ) : null}
              </div>

              <div className={classNames(commonstyles.col12, style.mt16)}>
                <CustomSelect
                  options={numberofbed}
                  placeholder="Select the number of bed"
                  onSelect={(selectedOption) =>
                    handleNoOfBedsSelect(selectedOption, index)
                  }
                />
                {formik.touched[
                  selectedBeds[index]?.noOfBeds as keyof typeof formik.touched
                ] &&
                formik.errors[
                  selectedBeds[index]?.noOfBeds as keyof typeof formik.errors
                ] ? (
                  <div className={classNames(commonstyles.error)}>
                    *
                    {
                      formik.errors[
                        selectedBeds[index]
                          ?.noOfBeds as keyof typeof formik.errors
                      ]
                    }
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ))}

        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={commonstyles.col12}>
            <CustomSelect
              options={guest}
              placeholder="Total guest can stay in this home"
              onSelect={handleGuestSelect}
            />
            {formik.touched.guestno && formik.errors.guestno ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.guestno}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.col8)}>
          <Checkbox
            checked={isPrivateBathroom}
            onChange={handleCheckboxChange}
            className={style.checkbox}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Private bathroom in this room
          </p>
        </div>
        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.colorBlue,
            commonstyles.mb28,
            commonstyles.mt32
          )}
        >
          Common space 1
        </p>
        <div className={classNames(commonstyles.mb28)}>
          <div className={commonstyles.col12}>
            <CustomSelect
              options={Sofabeds}
              placeholder="Number of Sofa beds in the room"
              onSelect={handleCommonSofaSelect}
            />
            {formik.touched.commonSofaBed && formik.errors.commonSofaBed ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.commonSofaBed}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.mb28)}>
          <div className={commonstyles.col12}>
            <CustomSelect
              placeholder="Total guest can stay "
              options={manyguest}
              onSelect={handleCommonGuestSelect}
            />
            {formik.touched.commonGuest && formik.errors.commonGuest ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.commonGuest}
              </div>
            ) : null}
          </div>
        </div>
        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.colorBlue,
            commonstyles.mb28,
            commonstyles.mt32
          )}
        >
          Apartment size(optional)
        </p>
        <div className={classNames(commonstyles.mb28)}>
          <div className={commonstyles.col12}>
            <CustomSelect
              placeholder="0 Square metres"
              options={Square}
              onSelect={handleAppartmentSizeSelect}
            />
            {formik.touched.appartmentSize && formik.errors.appartmentSize ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.appartmentSize}
              </div>
            ) : null}
          </div>
        </div>

        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.colorBlue,
            commonstyles.mb28,
            commonstyles.mt32
          )}
        >
          Base price per night
        </p>
        <div className={classNames(commonstyles.mb28, commonstyles.flx)}>
          <div className={classNames(commonstyles.col4, commonstyles.mr32)}>
            <CustomInput
              placeholder="Basic per night "
              id="basicpricePerNight"
              name="basicpricePerNight"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.basicpricePerNight}
            />
            {formik.touched.basicpricePerNight &&
            formik.errors.basicpricePerNight ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.basicpricePerNight}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonstyles.col4, commonstyles.mr32)}>
            <CustomInput
              placeholder="Price per night for mediTour "
              id="pricepernightformeditour"
              name="pricepernightformeditour"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.pricepernightformeditour}
            />
            {formik.touched.pricepernightformeditour &&
            formik.errors.pricepernightformeditour ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.pricepernightformeditour}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonstyles.col4, commonstyles.flx)}>
            <ShortImagePicker setData={firstImageURL} />
            {imageURLs.map((url: string, index: number) => (
              <div key={index}>
                <img
                  src={url}
                  alt={`Image ${index}`}
                  style={{ width: "56px", height: "56px" }}
                />
              </div>
            ))}
          </div>
        </div>
        <div
          style={{ marginTop: "32px", width: "204px", marginBottom: "16px" }}
        >
          <PrimaryButton
            children={"Next"}
            colorType={"blue"}
            // onClick={handleSubmit}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
