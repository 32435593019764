import React, { useState } from "react";
import classNames from "classnames";
import styles from "./PharmacyLogin.module.css";
import phrMainStyles from "../pharmacyMain.module.css";
import commonStyles from "shared/utils/common.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import { SuccessModal, LoadingModal } from "shared/components";
import { pharmacyLogin } from "shared/services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { pharmacyLoginSchema } from "shared/utils";
import { setIsLoggedIn, setToken } from "shared/redux/reducers/commonReducer";
import { setPharmacy, setSystemType } from "shared/redux";
import { useNavigate } from "react-router-dom";
import PasswordInput from "shared/components/PasswordInput";
import { FaChevronLeft } from "react-icons/fa6";
import Logo from "assets/images/Pharmacy  1.png";
import { Checkbox } from "@mui/material";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { fcmToken } = useSelector((state: any) => state.root.common);
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
   const [notificationError] = useState("");
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleGoToHome = () => {
    navigate("/joinVender");
  };
  const formik = useFormik({
    initialValues: {
      Email: "",
      Password: "",
    },
    validationSchema: Yup.object(pharmacyLoginSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async () => {
  setLoading(true);
  setError(""); // Clear previous errors

  try {
    // Check notification permission
    const permission = await checkNotificationPermission();
    if (permission === "denied") {
      // If permission is denied, alert and stop
      alert("To continue with the login process, please enable notifications.");
      setLoading(false);
      return;
    }

    // Proceed with login process
    let params = {
      email: formik.values.Email,
      password: formik.values.Password,
      fcmToken: fcmToken,
    };

    pharmacyLogin(params)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(setToken(res?.data?.token));
          dispatch(setIsLoggedIn(true));
          dispatch(setPharmacy(res?.data?.pharm));
          navigate("/pharmacy/dashboard");

          setShowSuccessModal(true);
        }
      })
      .catch((err: any) => {
        setError(err?.response?.data?.message || "An unexpected error occurred.");
      })
      .finally(() => {
        setLoading(false);
      });
  } catch (err: any) {
    console.error("Error during notification permission check:", err);
    setError("An error occurred while checking notification permissions.");
    setLoading(false);
  }
};

// Function to check notification permission
const checkNotificationPermission = () => {
  return new Promise<string>((resolve) => {
    if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        resolve(permission);
      });
    } else {
      resolve(Notification.permission);
    }
  });
};

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const inputType = passwordVisible ? "text" : "password";

  return (
    <div className={classNames(commonStyles.container)}>
      <div className={classNames(phrMainStyles.flx)}>
        <div
          className={classNames(
            commonStyles.col7,
            commonStyles.colmd7,
            commonStyles.colsm12
          )}
        >
          <div
            className={classNames(
              commonStyles.col8,
              commonStyles.colsm12,
              commonStyles.colmd12
            )}
          >
            <div
              className={classNames(commonStyles.flx, styles.cursor)}
              onClick={handleGoToHome}
            >
              <FaChevronLeft className={styles.BackIcon} />
              <p
                className={classNames(
                  commonStyles.colorBlue,
                  commonStyles.fs14,
                  commonStyles.semiBold,
                  styles.cursor
                )}
              >
                Back to Home
              </p>
            </div>
            <p
              className={classNames(
                commonStyles.fs40,
                styles.mt100,
                commonStyles.semiBold,
                commonStyles.colorBlue
              )}
            >
              Login
            </p>
            <p
              className={classNames(
                commonStyles.fs16,
                styles.mt16,
                commonStyles.semiBold,
                commonStyles.colorBlue
              )}
            >
              Login to access at Pharmacy
            </p>
            {error && (
              <div className={classNames(commonStyles.error)}>*{error}</div>
            )}
            {notificationError && (
              <div className={classNames(commonStyles.error)}>*{notificationError}</div>
            )}
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.mt50}>
                <CustomInput
                  placeholder="Enter Your Email"
                  id="Email"
                  name="Email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.Email}
                />
                {formik.touched.Email && formik.errors.Email ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.Email}
                  </div>
                ) : null}
              </div>
              <div className={styles.mt24}>
                <PasswordInput
                  placeholder="Enter your Password"
                  id="Password"
                  name="Password"
                  type={inputType}
                  onChange={formik.handleChange}
                  value={formik.values.Password}
                />
                {formik.touched.Password && formik.errors.Password ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.Password}
                  </div>
                ) : null}
              </div>

              <div
                className={classNames(
                  styles.flxBetween,
                  commonStyles.mb40,
                  styles.mt8
                )}
              >
                <div className={classNames(commonStyles.flx)}>
                  <Checkbox className={styles.checkBoxx} />
                  <p
                    className={classNames(
                      commonStyles.colorBlue,
                      commonStyles.fs14
                    )}
                  >
                    Remember me
                  </p>
                </div>
                <a
                  className={classNames(
                    commonStyles.colorOrange,
                    commonStyles.fs14,
                    commonStyles.flxEnd,
                    styles.cursor
                  )}
                  onClick={() => navigate("/pharmacy/forgot-password")}
                >
                  Forgot Password
                </a>
              </div>

              <div className={classNames(commonStyles.mb16)}>
                <PrimaryButton
                  children={"Sign In"}
                  type="submit"
                  colorType={"SkyBlueOutine"}
                />
              </div>

              <div>
                <p
                  className={classNames(
                    commonStyles.regular,
                    commonStyles.fs14,
                    styles.textCenter
                  )}
                >
                  If you haven't account{" "}
                  <a
                    className={classNames(
                      commonStyles.colorOrange,
                      styles.cursor
                    )}
                    onClick={() => navigate("/pharmacy/signup")}
                  >
                    Sign Up
                  </a>{" "}
                </p>
              </div>
            </form>
            <SuccessModal
              showModal={showSuccessModal}
              successMessage={"LogIn Successful!"}
              hanldeCloseModal={handleCloseSuccessModal}
            />
            <LoadingModal showModal={loading} />
          </div>
        </div>
        <div className={classNames(commonStyles.colsm12, commonStyles.col4)}>
          <img src={Logo} className={commonStyles.loginVector} />
        </div>
      </div>
    </div>
  );
};
export default Login;
