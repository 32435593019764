import * as React from "react";
import tablepay from "./tablepay.module.css";
import classNames from "classnames";
import Downloader from "shared/components/Downloader";
import moment from "moment";
const DATA = [
  {
    paymentid: "56256256",
    paymentdate: "19 Oct 2023",
    orderQuantity: "32",
    paymentamount: "432646/-",
    receipt: <Downloader />,
  },
  {
    paymentid: "6756774",
    paymentdate: "19 Oct 2023",
    orderQuantity: "46",
    paymentamount: "763573/-",
    receipt: <Downloader />,
  },
  {
    paymentid: "246567",
    paymentdate: "19 Oct 2023",
    orderQuantity: "23",
    paymentamount: "565665/-",
    receipt: <Downloader />,
  },

  {
    paymentid: "2372498",
    paymentdate: "19 Oct 2023",
    orderQuantity: "22",
    paymentamount: "40332/-",
    receipt: <Downloader />,
  },
];

interface Props {
  data: any;
  onPressItem?: any;
}

const TablePay = (props: Props) => {
  const { data, onPressItem } = props;
  return (
    <div className={classNames(tablepay.App)}>
      <div className={tablepay.payment}>
        <div className={tablepay.headerOuter}>
          <p className={tablepay.headerclass}>Payment Id</p>
          <p className={tablepay.headerclass}>Payment Date</p>
          <p className={tablepay.headerclass}>Quantity</p>
          <p className={tablepay.headerclass}>Received Amount</p>
        </div>
        <div className={tablepay.tableData}>
          <table
            style={{
              margin: "0px",
            }}
          >
            <tbody className={tablepay.wapper}>
              {data?.map((val: any, key: number) => {
                return (
                  <tr
                    className={tablepay.tableRow}
                    key={key}
                    onClick={() => onPressItem(val)}
                  >
                    <td className={tablepay.w20}>{val?.paymentId}</td>
                    <td className={tablepay.w20}>
                      {moment(val?.createdAt).format("MM-DD-YYYY")}
                    </td>
                    <td className={tablepay.w20}>{val?.noOfitems}</td>
                    <td className={tablepay.w20}>{val?.payableAmount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TablePay;
