import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SideBar } from "shared/components";
import { HospitalMenu } from "shared/utils";
import Documents from "pages/MedicalServices/Hospital/Documents";
import PatientDetail from "pages/MedicalServices/Hospital/PatientHistory/PatientDetail";
import PatientHistory from "pages/MedicalServices/Hospital/PatientHistory/index";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
import HospitalSetting from "pages/MedicalServices/Hospital/HospitalSetting";
import Chat from "shared/components/Chat";
import Profile from "shared/components/Profile";
import {
  Doctors,
  Hospital_AppointmentDetails,
  Hospital_Appointments,
  Hospital_Dashboard,
  Hospital_Departments,
  Hospital_Payments,
  Hospital_Payments_Details,
} from "pages";

const HospitalMainRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/hospital/dashboard");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: "0px",
          height: "100vh",
        }}
      >
        <SideBar menuItem={HospitalMenu} />
      </div>
      <Routes>
        <Route path="hospital/dashboard" element={<Hospital_Dashboard />} />
        <Route path="hospital/departments" element={<Hospital_Departments />} />
        <Route path="hospital/doctors" element={<Doctors />} />
        <Route
          path="hospital/appointment"
          element={<Hospital_Appointments />}
        />
        <Route path="hospital/patienthistory" element={<PatientHistory />} />
        <Route path="/patientsDetails/:id" element={<PatientDetail />} />
        <Route
          path="/hospital/appointmentDetails"
          element={<Hospital_AppointmentDetails />}
        />
        <Route path="hospital/documents" element={<Documents />} />
        <Route path="hospital/payments" element={<Hospital_Payments />} />
        <Route
          path="hospital/paymentDetails"
          element={<Hospital_Payments_Details />}
        />
        <Route path="hospital/seting" element={<HospitalSetting />} />
        <Route path="hospital/privacy" element={<PrivacyPolicy />} />
        <Route path="chat/message" element={<Chat />} />
        <Route path="/profile" Component={Profile} />
      </Routes>
    </div>
  );
};

export default HospitalMainRoutes;
