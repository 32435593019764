import {
  LuBadgePercent,
  LuCalendarPlus,
  LuClipboardList,
} from "react-icons/lu";
import { PiTestTube, PiUsers } from "react-icons/pi";
import styles from "../components/SideBar/sidebar.module.css";
import { BsCapsulePill } from "react-icons/bs";
import classNames from "classnames";
import { TbGripVertical } from "react-icons/tb";
import { FaUserDoctor } from "react-icons/fa6";
import { FcDepartment } from "react-icons/fc";
import { FiUsers } from "react-icons/fi";
import { TbAmbulance } from "react-icons/tb";
import { FaRegCalendarAlt, FaRegFileAlt } from "react-icons/fa";
import * as Yup from "yup";
import { TbUsersPlus } from "react-icons/tb";
import { IoCardOutline, IoSettingsOutline } from "react-icons/io5";
import { AiOutlineCodeSandbox, AiOutlineUserAdd } from "react-icons/ai";
import { BsCardChecklist } from "react-icons/bs";
import { FaCar } from "react-icons/fa";
import { MdOutlineHomeWork } from "react-icons/md";
import { BsCalendarCheck } from "react-icons/bs";
import { PiClipboardText } from "react-icons/pi";
import { GrHomeRounded } from "react-icons/gr";
import { GoCreditCard } from "react-icons/go";
import { BsClipboardPulse } from "react-icons/bs";
import { BsFileEarmarkText } from "react-icons/bs";
import { BsCardList } from "react-icons/bs";
import { BsCalendar2Check } from "react-icons/bs";
import { BiDonateHeart, BiHomeAlt2 } from "react-icons/bi";
import { LuStethoscope } from "react-icons/lu";
import { PiCalendarPlus } from "react-icons/pi";
import { SlCalender } from "react-icons/sl";
import {
  MdOutlinePrivacyTip,
  MdOutlineAirplaneTicket,
  MdOutlineFlightTakeoff,
  MdOutlineMedicalInformation,
} from "react-icons/md";
import {} from "react-icons/md";
import { BiCheckShield } from "react-icons/bi";
import { VscPersonAdd } from "react-icons/vsc";
import { RiLogoutCircleRLine } from "react-icons/ri";

export const laboratoryMenu = [
  {
    path: "laboratory/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "laboratory/test",
    name: "Tests",
    icon: <PiTestTube className={classNames(styles.menuIcons)} />,
  },
  {
    path: "laboratory/Order",
    name: "Orders",
    icon: <TbGripVertical className={classNames(styles.menuIcons)} />,
  },
  {
    path: "laboratory/Result",
    name: "Results",
    icon: <BsCardList className={classNames(styles.menuIcons)} />,
  },

  {
    path: "laboratory/Payment",
    name: "Payments",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "laboratory/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "laboratory/policy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];

//  .....................................

export const pharmacyMenu = [
  {
    path: "pharmacy/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "pharmacy/medicine",
  //   name: "Medicines",
  //   icon: <BsCapsulePill className={classNames(styles.menuIcons)} />,
  // },
  {
    path: "pharmacy/Request",
    name: "Request",
    icon: <BsCapsulePill className={classNames(styles.menuIcons)} />,
  },

  {
    path: "pharmacy/order",
    name: "Orders",
    icon: <TbGripVertical className={classNames(styles.menuIcons)} />,
  },
  {
    path: "pharmacy/payment",
    name: "Payments",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "pharmacy/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "pharmacy/privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];
//................
export const pharmaceutical_Menu = [
  {
    path: "pharmaceutical/dashboard",
    name: "Medicines",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
];
//................
export const DoctorMenu = [
  {
    path: "doctor/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "doctor/AvailabilityCategory",
    name: "Availability",
    icon: <BsCalendar2Check className={classNames(styles.menuIcons)} />,
  },

  // {
  //   path: "doctor/requests",
  //   name: "Requests",
  //   icon: <FiUsers className={classNames(styles.menuIcons)} />,
  // },
  {
    path: "doctor/appointment",
    name: "Appointments",
    icon: <BsClipboardPulse className={classNames(styles.menuIcons)} />,
  },
  {
    path: "doctor/history",
    name: "Patient History",
    icon: <BsFileEarmarkText className={classNames(styles.menuIcons)} />,
  },
  {
    path: "doctor/payment",
    name: "Payments",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "doctor/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "doctor/privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];
export const HospitalMenu = [
  {
    path: "hospital/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hospital/departments",
    name: "Departments",
    icon: <FcDepartment className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hospital/doctors ",
    name: "Doctors",
    icon: <FaUserDoctor className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hospital/appointment ",
    name: "Appointment",
    icon: <LuClipboardList className={classNames(styles.menuIcons)} />,
  },

  // {
  //   path: "hospital/patienthistory ",
  //   name: "Patients ",
  //   icon: (
  //     <MdOutlineMedicalInformation className={classNames(styles.menuIcons)} />
  //   ),
  // },
  // {
  //   path: "hospital/documents",
  //   name: "Documents",
  //   icon: <FaRegFileAlt className={classNames(styles.menuIcons)} />,
  // },
  {
    path: "hospital/payments",
    name: "Payments",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "hospital/seting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "hospital/privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];
export const AmbulanceMenu = [
  {
    path: "ambulance/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "ambulance/ambulances",
  //   name: "Ambulances",
  //   icon: <TbAmbulance className={classNames(styles.menuIcons)} />,
  // },
  {
    path: "ambulance/request",
    name: "Request",
    icon: <TbUsersPlus className={classNames(styles.menuIcons)} />,
  },
  {
    path: "ambulance/onroutes",
    name: "On Route",
    icon: <LuClipboardList className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "ambulance/documents",
  //   name: "Documents",
  //   icon: <FaRegFileAlt className={classNames(styles.menuIcons)} />,
  // },

  {
    path: "ambulance/payments",
    name: "Payment ",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "ambulance/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "ambulance/privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];

export const Doctor_Menu = (systemType: any) => {
  return [
    {
      path: `${systemType}/dashboard`,
      name: "Dashboard",
      icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
    },
    {
      path: `${systemType}/availability`,
      name: "Availability",
      icon: <BsCalendar2Check className={classNames(styles.menuIcons)} />,
    },
    // /${systemType}/treatments
    {
      path: `${systemType}/treatments`,
      name: "Treatments",
      icon: <FaUserDoctor className={classNames(styles.menuIcons)} />,
    },
    {
      path: `${systemType}/appointment`,
      name: "Appointment",
      icon: <BsClipboardPulse className={classNames(styles.menuIcons)} />,
    },
    {
      path: `${systemType}/patientHistory`,
      name: "Patient History",
      icon: <BsFileEarmarkText className={classNames(styles.menuIcons)} />,
    },
    {
      path: `${systemType}/payments`,
      name: "Payments",
      icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
    },
    // {
    //   path: `${systemType}/setting`,
    //   name: "Settings",
    //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
    // },
    // {
    //   path: `${systemType}/privacy`,
    //   name: "Privacy & Policy",
    //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
    // },
  ];
};

export const PhysiotherapistMenu = [
  {
    path: "physiotherapist/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "physiotherapist/availability",
    name: "Availability",
    icon: <BsCalendar2Check className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "physiotherapist/requests",
  //   name: "Requests",
  //   icon: <FiUsers className={classNames(styles.menuIcons)} />,
  // },
  {
    path: "physiotherapist/appointment",
    name: "Appointment",
    icon: <BsClipboardPulse className={classNames(styles.menuIcons)} />,
  },
  {
    path: "physiotherapist/history",
    name: "Patient History",
    icon: <BsFileEarmarkText className={classNames(styles.menuIcons)} />,
  },
  {
    path: "physiotherapist/payment",
    name: "Payment",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "physiotherapist/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "physiotherapist/privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];
export const NutritionistMenu = [
  {
    path: "nutritionist/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "nutritionist/availabilityCategory",
    name: "Availability",
    icon: <BsCalendar2Check className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "nutritionist/requests",
  //   name: "Requests",
  //   icon: <FiUsers className={classNames(styles.menuIcons)} />,
  // },
  {
    path: "nutritionist/appointment",
    name: "Appiontment",
    icon: <BsClipboardPulse className={classNames(styles.menuIcons)} />,
  },
  {
    path: "nutritionist/history",
    name: "Patient History",
    icon: <BsFileEarmarkText className={classNames(styles.menuIcons)} />,
  },

  {
    path: "nutritionist/payment",
    name: "Payment ",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "nutritionist/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "nutritionist/Privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];
export const ParamedicStaffMenu = [
  {
    path: "paramedicStaff/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "paramedicStaff/availabilityCategory",
    name: "Availability",
    icon: <BsCalendar2Check className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "paramedicStaff/requests",
  //   name: "Requests",
  //   icon: <FiUsers className={classNames(styles.menuIcons)} />,
  // },
  {
    path: "paramedicStaff/appointment",
    name: "Appiontment",
    icon: <BsClipboardPulse className={classNames(styles.menuIcons)} />,
  },
  {
    path: "paramedicStaff/history",
    name: "Patient History",
    icon: <BsFileEarmarkText className={classNames(styles.menuIcons)} />,
  },

  {
    path: "paramedicStaff/payment",
    name: "Payment",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "paramedicStaff/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "paramedicStaff/Privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];
export const PsychologistsMenu = [
  {
    path: "psychologists/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "psychologists/availability/Category",
    name: "Availability",
    icon: <BsCalendar2Check className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "psychologists/requests",
  //   name: "Requests",
  //   icon: <FiUsers className={classNames(styles.menuIcons)} />,
  // },
  {
    path: "psychologists/appointment",
    name: "Appiontment",
    icon: <BsClipboardPulse className={classNames(styles.menuIcons)} />,
  },
  {
    path: "psychologists/history",
    name: "Patient History",
    icon: <BsFileEarmarkText className={classNames(styles.menuIcons)} />,
  },

  {
    path: "psychologists/payment",
    name: "Payment ",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "psychologists/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "psychologists/Privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];
export const CarARentMenu = [
  {
    path: "rentacar/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "rentacar/vehicleDetail",
    name: "Vehicle Details",
    icon: <FaCar className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "rentacar/Request",
  //   name: "Request",
  //   icon: <TbUsersPlus className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "rentacar/CustomerDetail",
  //   name: "Customer Detail",
  //   icon: <TbUsersPlus className={classNames(styles.menuIcons)} />,
  // },
  {
    path: "rentacar/Order",
    name: "Order",
    icon: <TbGripVertical className={classNames(styles.menuIcons)} />,
  },
  {
    path: "rentacar/history",
    name: "History",
    icon: <TbGripVertical className={classNames(styles.menuIcons)} />,
  },
  {
    path: "rentacar/Payment",
    name: "Payment",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },

  // {
  //   path: "rentacar/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "rentacar/privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];
export const HotelMenu = [
  {
    path: "hotel/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hotel/property/Main/Page",
    name: "Property",
    icon: <MdOutlineHomeWork className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hotel/reserve",
    name: "Reserve",
    icon: <BsCalendarCheck className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hotel/booking",
    name: "Booking",
    icon: <PiClipboardText className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hotel/Payment",
    name: "Payment",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "hotel/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "hotel/privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];
export const TravelMenu = [
  {
    path: "travelAgency/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "travelAgency/tourPakages",
    name: "Tour plan",
    icon: <MdOutlineFlightTakeoff className={classNames(styles.menuIcons)} />,
  },
  {
    path: "travelAgency/ticketRequests",
    name: "Ticket Requests",
    icon: <AiOutlineUserAdd className={classNames(styles.menuIcons)} />,
  },
  {
    path: "travelAgency/Booking",
    name: "Booking",
    icon: <MdOutlineAirplaneTicket className={classNames(styles.menuIcons)} />,
  },

  {
    path: "travelAgency/Payment",
    name: "Payment",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "travelAgency/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "travelAgency/privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];
export const InsuranceMenu = [
  {
    path: "insurance/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "insurance/Category",
    name: "Insurances",
    icon: <BiCheckShield className={classNames(styles.menuIcons)} />,
  },
  {
    path: "insurance/request",
    name: "Request",
    icon: <TbUsersPlus className={classNames(styles.menuIcons)} />,
  },
  {
    path: "insurance/insuredperson",
    name: "Insured person",
    icon: <VscPersonAdd className={classNames(styles.menuIcons)} />,
  },
  {
    path: "insurance/payments",
    name: "Payment ",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "insurance/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "insurance/Privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];
export const DonationMenu = [
  {
    path: "donation/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "donation/criteria",
    name: "Criteria",
    icon: <BsCardChecklist className={classNames(styles.menuIcons)} />,
  },
  {
    path: "donation/packages",
    name: "Packages",
    icon: <AiOutlineCodeSandbox className={classNames(styles.menuIcons)} />,
  },
  {
    path: "donation/donors",
    name: "Donors",
    icon: <TbUsersPlus className={classNames(styles.menuIcons)} />,
  },
  {
    path: "donation/payment",
    name: "Payment ",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
  // {
  //   path: "donation/setting",
  //   name: "Settings",
  //   icon: <IoSettingsOutline className={classNames(styles.menuIcons)} />,
  // },
  // {
  //   path: "donation/Privacy",
  //   name: "Privacy & Policy",
  //   icon: <MdOutlinePrivacyTip className={classNames(styles.menuIcons)} />,
  // },
];

export const AdminMenu = [
  {
    path: "admin/dashboard",
    name: "Dashboard",
    icon: <BiHomeAlt2 className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/OPD",
    name: "OPD",
    icon: <LuStethoscope className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/Resquests",
    name: "Apt. Requests",
    icon: <LuCalendarPlus className={classNames(styles.menuIcons)} />,
  },
  {
    path: "AdminPanel/AdminPharmacyRequest",
    name: "Pharmacy Requests",
    icon: <LuCalendarPlus className={classNames(styles.menuIcons)} />,
  },

  {
    path: "admin/appointments",
    name: "Appointments",
    icon: <FaRegCalendarAlt className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/Orders",
    name: "Orders",
    icon: <TbGripVertical className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/hotelBookingRequests",
    name: "Hotel Requests",
    icon: <BsCardList className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/booking",
    name: "Booking",
    icon: <BsCardList className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/donation",
    name: "Donation",
    icon: <BiDonateHeart className={classNames(styles.menuIcons)} />,
  },

  {
    path: "admin/Payments",
    name: "Payments",
    icon: <IoCardOutline className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/Users",
    name: "Users",
    icon: <PiUsers className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/percentage",
    name: "Percentage",
    icon: <LuBadgePercent className={classNames(styles.menuIcons)} />,
  },
];
//..................Validations..........................//

export const speciality = [
  "Gynecologists",
  "Cosmetic Surgery",
  "Bariatric Surgery",
  "Transplants",
  "Ophthalmology",
  "Cancer",
  "Orthopedics",
  "Stem Cell",
  "Pediatrics",
  "Dental",
  "Aesthetic Treatments",
  "Psychiatry",
  "Lungs",
  "Urology",
  "Gastroenterology",
  "Neurology",
  "Fertility",
  "Nephrology",
  "E.N.T",
  "Cardiovascular & Metabolic",
];

export const Experiences = [
  "1 year",
  "2 year",
  "3 year",
  "4 year",
  "5 year",
  "6 year",
  "7 year",
  "8 year",
  "9 year",
  "10 year",
  "11 year",
  "12 year",
  "13 year",
  "14 year",
  "15 year",
  "16 year",
  "17 year",
  "18 year",
  "19 year",
  "20 year",
];
//...........Laboratory...........//
const dateFormat = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;
export const labInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  labLogo: Yup.string().required("Required"),

  labLicenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  labLicenseImage: Yup.string().required("Required"),
  labLicenseExpiry: Yup.string().required("Required"),
  labOwnerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  labOwnerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  labEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),

  // labCnicNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // labCnicImage: Yup.string().required("Required"),
  // labCnicExpiryDate: Yup.string().required("Required"),

  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),

  labDescription: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  labOpenTime: Yup.string().required("Required"),
  labCloseTime: Yup.string().required("Required"),
};

const url =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const labSocialSchema = {
  fbUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  instaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  twitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  webUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const labBankSchema = {
  // saleTaxNo: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // bankName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // incomeTaxNo: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // accountHolderName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // accountNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // TaxFile: Yup.string().required("Required"),
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,25}$/;

const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&()_+{}\[\]:;<>,.?/\\|-])[A-Za-z\d!@#$%*^&()_+{}\[\]:;<>,.?/\\|-]{8,25}$/;

export const labVerifySchema = {
  phoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
};

export const labLoginSchema = {
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    // .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const labResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const labConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};

export const labLabInfoUpdateProfileSchema = {
  companyName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  ownerName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),

  cnic: Yup.string()
    .min(13, "Must be at least 13 Digits long")
    .max(13, "Must be 13 Digits")
    .required("Required"),
  state: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};

export const labBankUpdateProfileSchema = {
  bankName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(30, "Must be 30 characters or less")
    .required("Required"),
  accountHolderName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(25, "Must be 25 characters or less")
    .required("Required"),
  accountNumber: Yup.string()
    .min(9, "Must be at least 9 Digits long")
    .max(18, "Must be 18 Digits or less")
    .required("Required"),
};

export const labTaxUpdateProfileSchema = {
  incomeTaxNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  salesTaxNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};

export const labSocialUpdateeProfileSchema = {
  website: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  instagram: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  twitter: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  facebook: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const labPasswordUpdateProfileSchema = {
  currentPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  NewPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  ConfirmPassword: Yup.string()
    .oneOf([Yup.ref("NewPassword")], "Passwords must match")
    .required("Required"),
};
//..................Validations..........................//

//.................Test-Addtest..........................//

export const labAddTestInfo = {
  testName: Yup.string()
    .trim()
    .min(2, "Please enter at least 2 characters.")
    .max(64, "Test name should not exceed 64 characters.")
    .required("Required"),
  testDescription: Yup.string()
    .trim()
    .min(2, "Please enter at least 2 characters.")
    .max(1000, "Description should not exceed 1000 characters.")
    .required("Required"),
  testPrice: Yup.string()
    // .min(2, "Must be at least 2 characters long")
    .matches(/^\d+$/, "Please enter a valid number")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  testDuration: Yup.string()
    // .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  priceForMeditour: Yup.string()
    // .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required")
    .test(
      "is-less-than-actualPrice",
      "MediTour price should be less than actual price",
      function (value) {
        const { testPrice } = this.parent;
        return value < testPrice;
      }
    ),
};

export const labAddTestPrice = {
  testprice_TestPrice: Yup.string()
    // .min(2, "Must be at least 2 characters long")
    .matches(/^\d+$/, "Please enter a valid number")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  testprice_TestDuration: Yup.string()
    // .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  testprice_PriceForMeditour: Yup.string()
    // .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required")
    .test(
      "is-less-than-actualPrice",
      "MediTour price should be less than actual price",
      function (value) {
        const { testprice_TestPrice } = this.parent;
        return value < testprice_TestPrice;
      }
    ),
};

//..................Validations..........................//

//...........Pharmacy...........//

export const pharmacyInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  pharmacyLogo: Yup.string().required("Required"),
  pharmacyLicenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  pharmacyLicenseImg: Yup.string().required("Required"),
  pharmacyLicenseExpiry: Yup.string().required("Required"),
  pharmacyOwnerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  pharmacyOwnerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  pharmacyEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),

  // pharmacyCnicNumber: Yup.string()
  //   .min(2, "Must be at least 2 numbers long")
  //   .required("Required"),
  // pharmacyCnicImg: Yup.string().required("Required"),
  // pharmacyCnicExpiry: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
  pharmacyDescription: Yup.string()
    .min(2, "Must be at least 2 numbers long")
    .required("Required"),
  pharamcyOpenTime: Yup.string().required("Required"),
  pharmacyCloseTime: Yup.string().required("Required"),
};

export const pharmaceuticalInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  logo: Yup.string().required("Required"),
  ownerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  emergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  address: Yup.string().required("Required"),
};

export const AddFormulaSchema = {
  productType: Yup.string()
    .trim()
    .min(2, "Please enter at least 2 characters.")
    .required("Required"),
  generic: Yup.string()
    .trim()
    .min(2, "Please enter at least 2 characters.")
    .required("Required"),
  brand: Yup.string().trim().required("Required"),
  strength: Yup.string()
    .trim()
    .min(2, "Please enter at least 2 characters.")
    .required("Required"),
  content: Yup.string().trim().required("Required"),
  packSize: Yup.string().trim().required("Required"),
  tpPrice: Yup.string().trim().required("Required"),
  mrpPrice: Yup.string().trim().required("Required"),
};

export const pharmacySocialSchema = {
  pharmacyfbUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  pharmacyinstaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  pharmacytwitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  pharmacywebUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const pharmacyBankSchema = {
  // pharmacyincomeTaxNo: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // pharmacysaleTaxNo: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // // pharmacyTaxexemotion: Yup.string()
  // // .min(2, "Must be at least 2 characters long")
  // // .max(15, "Must be 15 characters or less")
  // // .required("Required"),
  // pharmacybankName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // pharmacyaccountHolderName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // pharmacyaccountNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
};
export const pharmacyVerifySchema = {
  pharmacyPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  pharmacyEmail: Yup.string().email().required("Required"),
  pharmacyPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  pharmacyConfirmPassword: Yup.string()
    .oneOf([Yup.ref("pharmacyPassword")], "Passwords must match")
    .required("Required"),
};
export const pharmacyLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const pharmacyResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const pharmacyConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};

export const pharmAddMedicineMedicineInfo = {
  pharmMedicineGeneric: Yup.string().required("Required"),
  pharmMedicineImage: Yup.string().required("Required"),
  pharmMedicineBrand: Yup.string().required("Required"),
  pharmMedicineType: Yup.string().required("Required"),
  pharmMedicineStrength: Yup.string().required("Required"),
  pharmMedicinePackSize: Yup.string().required("Required"),
};

export const pharmAddMedicineMedicinePrice = {
  actualPrice: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .required("Required"),
  priceForMediTour: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .required("Required")
    .test(
      "is-less-than-actualPrice",
      "MediTour price should be less than actual price",
      function (value) {
        const { actualPrice } = this.parent;
        return value < actualPrice;
      }
    ),
};

export const pharmPasswordUpdateProfileSchema = {
  currentPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  NewPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  ConfirmPassword: Yup.string()
    .oneOf([Yup.ref("NewPassword")], "Passwords must match")
    .required("Required"),
};

export const pharmLabInfoUpdateProfileSchema = {
  companyName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  cnic: Yup.string()
    .min(13, "Must be at least 13 Digits long")
    .max(13, "Must be 13 Digits")
    .required("Required"),
  state: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};

export const pharTaxUpdateProfileSchema = {
  incomeTaxNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  salesTaxNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};

export const pharBankUpdateProfileSchema = {
  bankName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  accountHolderName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  accountNumber: Yup.string()
    .min(9, "Must be at least 9 Digits long")
    .max(18, "Must be 18 Digits or less")
    .required("Required"),
};

export const pharSocialUpdateeProfileSchema = {
  website: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  instagram: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  twitter: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  facebook: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};
//..................Validations..........................//

//...........Medicalservice-Doctor...........//

export const doctorBasicInfoSchema = {
  doctortype: Yup.string().required("Required"),
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  // doctorCnicNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // doctorCnicImage: Yup.string().required("Required"),
  // doctorCnicExpiry: Yup.string().required("Required"),
  doctorQualification: Yup.string().required("Required"),
  doctorSpeciality: Yup.array()
    .of(Yup.string())
    .required("At least one speciality is required"),
  doctorClinicName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  // doctorExperience: Yup.string().required("Required"),
  // doctorPmdcNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(50, "Must be 50 characters or less"),
  // doctorPmdcImage: Yup.string(),
  // doctorPmdcExpiry: Yup.string(),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
};
export const doctor_BasicInfoSchema = {
  type: Yup.string().required("Required"),
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  // cnicNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // cnicImage: Yup.string().required("Required"),
  // cnicExpiry: Yup.string().required("Required"),
  qualification: Yup.string().required("Required"),
  speciality: Yup.array()
    .of(Yup.string())
    .required("At least one speciality is required"),
  clinicName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  experience: Yup.string().required("Required"),
  // pmdcNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(50, "Must be 50 characters or less")
  //   .required("Required"),
  // pmdcImage: Yup.string().required("Required"),
  // pmdcExpiry: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
};
export const doctorSocialSchema = {
  doctorfbUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  doctorinstaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  doctortwitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  doctorwebUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};
export const doctor_SocialSchema = {
  fbUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  instaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  twitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  webUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const doctor_BankSchema = {
  incomeTaxNumber: Yup.string(),
  // .min(2, "Must be at least 2 characters long")
  // .required("Required"),
  saleTaxNumber: Yup.string(),
  // .min(2, "Must be at least 2 characters long")
  // .required("Required"),
  // taxFile: Yup.string().required("Required"),
  bankName: Yup.string(),
  // .min(2, "Must be at least 2 characters long")
  // .required("Required"),
  accountHolderName: Yup.string(),
  // .min(2, "Must be at least 2 characters long")
  // .required("Required"),
  accountNumber: Yup.string(),
  // .min(2, "Must be at least 2 characters long")
  // .required("Required"),
};
export const doctorVerifySchema = {
  doctorPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  doctorEmail: Yup.string().email().required("Required"),
  doctorPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  doctorConfirmPassword: Yup.string()
    .oneOf([Yup.ref("doctorPassword")], "Passwords must match")
    .required("Required"),
};

export const doctor_VerifySchema = {
  phoneNo: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
};

export const doctorLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    // .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const doctorResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const doctorConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};
export const doctorOnsiteAvailability = {
  onsiteDay: Yup.string().required("Required"),
  morningfrom: Yup.string()
    .required("Required")
    .test(
      "is-valid-time",
      "Time must be between 02:00-14:00",
      function (value) {
        if (!value) return true;
        const enteredTime = new Date(`2000-01-01T${value}`);
        const lowerLimitTime = new Date(`2000-01-01T02:00:00`);
        const upperLimitTime = new Date(`2000-01-01T14:00:00`);
        return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
      }
    ),
  morningto: Yup.string()
    .required("Required")
    .test(
      "is-valid-time",
      "Time must be after Morning From",
      function (value, { parent }) {
        if (!value) return true;
        const enteredTime = new Date(`2000-01-01T${value}`);
        const fromTime = new Date(`2000-01-01T${parent.morningfrom}`);
        const isAfterMorningFrom = fromTime < enteredTime;
        const isNotEqualMorningFrom =
          fromTime.getTime() !== enteredTime.getTime();
        return isAfterMorningFrom && isNotEqualMorningFrom;
      }
    ),

  eveningfrom: Yup.string()
    .required("Required")
    .test(
      "is-valid-time",
      "Time must be between 14:00 and 02:00",
      function (value) {
        if (!value) return true;
        const enteredTime = new Date(`2000-01-01T${value}`);
        const lowerLimitTime = new Date(`2000-01-01T14:00:00`);
        const upperLimitTime = new Date(`2000-01-01T02:00:00`);
        return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
      }
    ),
  eveningto: Yup.string()
    .required("Required")
    .test(
      "is-valid-time",
      "Time must be after evening From",
      function (value, { parent }) {
        if (!value) return true;
        const enteredTime = new Date(`2000-01-01T${value}`);
        const fromTime = new Date(`2000-01-01T${parent.eveningfrom}`);
        const isAfterEveningFrom = fromTime < enteredTime;
        const isNotEqualEveningFrom =
          fromTime.getTime() !== enteredTime.getTime();
        return isAfterEveningFrom && isNotEqualEveningFrom;
      }
    ),
};
export const doctorAvailibilityPrice = {
  actualPrice: Yup.string()
    .matches(/^\d+$/, "Please enter a valid number")
    .min(2, "Must be at least 2 Digit long")
    .max(15, "Must be 15 Digit or less")
    .required("Please enter price."),
  // priceforMeditour: Yup.string()
  //   .matches(/^\d+$/, "Please enter a valid number")
  //   .min(2, "Must be at least 2 Digit long")
  //   .max(15, "Must be 15 Digit or less")
  //   .required("Please enter Meditour price."),
};

// export const doctorInhouseAvailability = {
//   onsiteDay: Yup.string().required("Required"),
//   morningfrom: Yup.string()
//     .required("Required")
//     .test(
//       "is-valid-time",
//       "Time must be between 08:00-14:00",
//       function (value) {
//         if (!value) return true;
//         const enteredTime = new Date(`2000-01-01T${value}`);
//         const lowerLimitTime = new Date(`2000-01-01T08:00:00`);
//         const upperLimitTime = new Date(`2000-01-01T14:00:00`);
//         return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
//       }
//     ),
//   morningto: Yup.string()
//     .required("Required")
//     .test(
//       "is-valid-time",
//       "Time must be after Morning From",
//       function (value, { parent }) {
//         if (!value) return true;
//         const enteredTime = new Date(`2000-01-01T${value}`);
//         const fromTime = new Date(`2000-01-01T${parent.morningfrom}`);
//         const isAfterMorningFrom = fromTime < enteredTime;
//         const isNotEqualMorningFrom =
//           fromTime.getTime() !== enteredTime.getTime();
//         return isAfterMorningFrom && isNotEqualMorningFrom;
//       }
//     ),

//   // eveningfrom: Yup.string().test(
//   //   "is-valid-time",
//   //   "Time must be between 12:00 and 24:00",
//   //   function (value) {
//   //     if (!value) return true; // Allow empty value
//   //     const enteredTime = new Date(`2000-01-01T${value}`);
//   //     const lowerLimitTime = new Date(`2000-01-01T12:00:00`);
//   //     const upperLimitTime = new Date(`2000-01-01T24:00:00`);
//   //     return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
//   //   }
//   // ),
//   // eveningto: Yup.string().test(
//   //   "is-valid-time",
//   //   "Time must be after evening From",
//   //   function (value, { parent }) {
//   //     if (!value) return true; // Allow empty value
//   //     const enteredTime = new Date(`2000-01-01T${value}`);
//   //     const fromTime = new Date(`2000-01-01T${parent.eveningfrom}`);
//   //     const isAfterEveningFrom = fromTime < enteredTime;
//   //     const isNotEqualEveningFrom =
//   //       fromTime.getTime() !== enteredTime.getTime();
//   //     return isAfterEveningFrom && isNotEqualEveningFrom;
//   //   }
//   // ),

//   eveningfrom: Yup.string().test(
//     "is-valid-time",
//     "Time must be between 12:00 and 24:00",
//     function (value, { parent }) {
//       if (!value) return true; // Allow empty value
//       if (value === "00:00" && parent.eveningto === "00:00") {
//         return true; // Both evening from and to are "00:00", no validation needed
//       }
//       const enteredTime = new Date(`2000-01-01T${value}`);
//       const lowerLimitTime = new Date(`2000-01-01T12:00:00`);
//       const upperLimitTime = new Date(`2000-01-01T24:00:00`);
//       return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
//     }
//   ),
//   eveningto: Yup.string().test(
//     "is-valid-time",
//     "Time must be after evening From",
//     function (value, { parent }) {
//       if (!value) return true; // Allow empty value
//       const enteredTime = new Date(`2000-01-01T${value}`);
//       const fromTime = new Date(`2000-01-01T${parent.eveningfrom}`);
//       const isAfterEveningFrom = fromTime < enteredTime;
//       const isNotEqualEveningFrom =
//         fromTime.getTime() !== enteredTime.getTime();
//       return isAfterEveningFrom && isNotEqualEveningFrom;
//     }
// };

export const doctor_Availability = {
  onsiteDay: Yup.string().required("Please select a day."),
  morningfrom: Yup.string().test(
    "is-valid-time",
    "Time must be between 02:00-14:00",
    function (value) {
      if (!value) return true;
      // if (value === "00:00") return true; // Allow "00:00" time
      const enteredTime = new Date(`2000-01-01T${value}`);
      const lowerLimitTime = new Date(`2000-01-01T02:00:00`);
      const upperLimitTime = new Date(`2000-01-01T14:00:00`);
      return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
    }
  ),

  morningto: Yup.string().test(
    "is-valid-time",
    "End time must be after the Start time.",
    function (value, { parent }) {
      if (!value) return true;
      // if (value === "00:00") return true; // Allow "00:00" time
      const enteredTime = new Date(`2000-01-01T${value}`);
      const fromTime = new Date(`2000-01-01T${parent.morningfrom}`);
      const isAfterMorningFrom = fromTime < enteredTime;
      const isNotEqualMorningFrom =
        fromTime.getTime() !== enteredTime.getTime();
      return isAfterMorningFrom && isNotEqualMorningFrom;
    }
  ),

  eveningfrom: Yup.string().test(
    "is-valid-time",
    "Time must be between 14:00 and 24:00",
    function (value, { parent }) {
      if (!value) return true; // Allow empty value
      // if (!value === "00:00" && parent.eveningto === "00:00") {
      //   return true; // Both evening from and to are "00:00", no validation needed
      // }
      const enteredTime = new Date(`2000-01-01T${value}`);
      const lowerLimitTime = new Date(`2000-01-01T14:00:00`);
      const upperLimitTime = new Date(`2000-01-01T24:00:00`);
      return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
    }
  ),
  eveningto: Yup.string().test(
    "is-valid-time",
    "End time must be after the Start time.",
    function (value, { parent }) {
      if (!value) return true; // Allow empty value
      // if (value === "00:00" && parent.eveningfrom === "00:00") {
      //   return true; // Both evening from and to are "00:00", no validation needed
      // }
      const enteredTime = new Date(`2000-01-01T${value}`);
      const fromTime = new Date(`2000-01-01T${parent.eveningfrom}`);
      return fromTime < enteredTime;
    }
  ),
  // actualPrice: Yup.string()
  //   .matches(/^\d+$/, "Please enter a valid number")
  //   .min(2, "Must be at least 2 Digit long")
  //   .max(15, "Must be 15 Digit or less")
  //   .required("Required"),
};
export const doctorInhouseAvailability = {
  onsiteDay: Yup.string().required("Please select a day."),
  morningfrom: Yup.string().test(
    "is-valid-time",
    "Time must be between 02:00-14:00",
    function (value) {
      if (!value) return true;
      // if (value === "00:00") return true; // Allow "00:00" time
      const enteredTime = new Date(`2000-01-01T${value}`);
      const lowerLimitTime = new Date(`2000-01-01T02:00:00`);
      const upperLimitTime = new Date(`2000-01-01T14:00:00`);
      return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
    }
  ),
  morningto: Yup.string().test(
    "is-valid-time",
    "End time must be after the Start time.",
    function (value, { parent }) {
      if (!value) return true;
      // if (value === "00:00") return true; // Allow "00:00" time
      const enteredTime = new Date(`2000-01-01T${value}`);
      const fromTime = new Date(`2000-01-01T${parent.morningfrom}`);
      const isAfterMorningFrom = fromTime < enteredTime;
      const isNotEqualMorningFrom =
        fromTime.getTime() !== enteredTime.getTime();
      return isAfterMorningFrom && isNotEqualMorningFrom;
    }
  ),

  eveningfrom: Yup.string().test(
    "is-valid-time",
    "Time must be between 14:00 and 24:00",
    function (value, { parent }) {
      if (!value) return true; // Allow empty value
      // if (!value === "00:00" && parent.eveningto === "00:00") {
      //   return true; // Both evening from and to are "00:00", no validation needed
      // }
      const enteredTime = new Date(`2000-01-01T${value}`);
      const lowerLimitTime = new Date(`2000-01-01T14:00:00`);
      const upperLimitTime = new Date(`2000-01-01T24:00:00`);
      return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
    }
  ),
  eveningto: Yup.string().test(
    "is-valid-time",
    "End time must be after the Start time.",
    function (value, { parent }) {
      if (!value) return true; // Allow empty value
      // if (value === "00:00" && parent.eveningfrom === "00:00") {
      //   return true; // Both evening from and to are "00:00", no validation needed
      // }
      const enteredTime = new Date(`2000-01-01T${value}`);
      const fromTime = new Date(`2000-01-01T${parent.eveningfrom}`);
      return fromTime < enteredTime;
    }
  ),
  actualPrice: Yup.string()
    .matches(/^\d+$/, "Please enter a valid number")
    .min(2, "Must be at least 2 Digit long")
    .max(15, "Must be 15 Digit or less")
    .required("Required"),
};

export const doctorOnsitePrice = {
  onsiteActualPrice: Yup.string()
    .matches(/^\d+$/, "Please enter a valid number")
    .min(2, "Must be at least 2 Digit long")
    .max(15, "Must be 15 Digit or less")
    .required("Required"),
  onsiteMeditourPrice: Yup.string()
    .matches(/^\d+$/, "Please enter a valid number")
    .min(2, "Must be at least 2 Digit long")
    .max(15, "Must be 15 Digit or less")
    .required("Required"),
};
export const doctorVideoConsultAvailability = {
  videoConsultDay: Yup.string().required("Required"),
};

export const doctorVideoConsultPrice = {
  videoConsultActualPrice: Yup.string()
    .min(2, "Must be at least 2 Digit long")
    .max(15, "Must be 15 Digit or less")
    .required("Required"),
  videoConsultMeditourPrice: Yup.string()
    .min(2, "Must be at least 2 Digit long")
    .max(15, "Must be 15 Digit or less")
    .required("Required"),
};
export const doctorAppointmentHistory = {
  systolicPressure: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  diastolicPressure: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  weight: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  symptoms: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),

  description: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};
export const doctorAppointmentAddmedicineSchema = {
  // type: Yup.string().trim().required("Required"),
  medicineName: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  medicineBrand: Yup.string().required("Required"),
  medicineStrength: Yup.string()
    .trim()
    .required("Required")
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  dosage: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};
export const doctorAppointmentAddTestSchema = {
  testName: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  testId: Yup.string().trim().required("Required"),
};
//..................Validations..........................//

//...........Medicelservice-Hospitals...........//

export const hospital_BasicInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  logo: Yup.string().required("Required"),
  registrationNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  registrationImage: Yup.string().required("Required"),
  registrationExpiry: Yup.string().required("Required"),
  ownerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  emergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  // cnicNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(50, "Must be 50 characters or less")
  //   .required("Required"),
  // cnicImage: Yup.string().required("Required"),
  // cnicExpiryDate: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
  openTime: Yup.string().required("Required"),
  closeTime: Yup.string().required("Required"),
};
export const hospitalSocialSchema = {
  hospitalfbUrl: Yup.string().matches(url, "URL is not valid"),
  //.required("Required"),
  hospitalinstaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  hosiptaltwitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  hospitalwebUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const hospitalBankSchema = {
  // hospitalIncomeTextNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // hospitalTaxRegistrationNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // hospitalAttachTaxFile: Yup.string().required("Required"),
  // hospitalBankName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // hospitalAccountHolderName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // hospitalAccountNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
};
export const hospitalVerifySchema = {
  hospitalPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  hospitalEmail: Yup.string().email().required("Required"),
  hospitalPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  hospitalConfirmPassword: Yup.string()
    .oneOf([Yup.ref("hospitalPassword")], "Passwords must match")
    .required("Required"),
};
export const hospitalLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    // .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const hospitalResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const hospitalConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};

export const hospitalAddDepartmentSchema = {
  departmentName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  departmentLogo: Yup.string().required("Required"),
};

export const hospitalHospitalInfoUpdateProfileSchema = {
  hospitalName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(25, "Must be 25 characters or less")
    .required("Required"),
  cnic: Yup.string()
    .min(13, "Must be at least 13 Digits long")
    .max(13, "Must be 13 Digits")
    .required("Required"),
  // state: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
};

export const hospitalAddDoctorEnterCode = {
  code: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(25, "Must be 25 characters or less")
    .required("Required"),
};

//..................Validations..........................//

//...........Homeservice-Ambulance...........//

export const ambulanceBasicInfoSchema = {
  ambulanceAmbulanceName: Yup.string()
    .min(3, "Must be at least 3 characters long")
    .required("Required"),
  ambulanceLogo: Yup.string().required("Required"),
  ambulanceRegistrationNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  ambulanceRegistrationExpiry: Yup.string().required("Required"),
  ambulanceRegistrationImage: Yup.string().required("Required"),
  ambulanceFirstOwnerName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  ambulanceLastOwnerName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  ambulanceEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),

  // ambulanceCnicNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),

  // ambulanceCnicExpiryDate: Yup.string().required("Required"),
  // ambulanceCnicImage: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
};

export const ambulanceSocialSchema = {
  ambulancefbUrl: Yup.string().matches(url, "URL is not valid"),
  //.required("Required"),
  ambulanceistinstaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  ambulancetwitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  ambulancewebUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const ambulanceBankSchema = {
  // ambulanceIncomeTextNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // ambulanceTaxRegistrationNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // ambulanceTaxFile: Yup.string().required("Required"),
  // ambulanceBankName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // ambulanceAccountHolderName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // ambulanceAccountNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
};
export const ambulanceVerifySchema = {
  ambulancePhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  ambulanceEmail: Yup.string().email().required("Required"),
  ambulancePassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  ambulanceConfirmPassword: Yup.string()
    .oneOf([Yup.ref("ambulancePassword")], "Passwords must match")
    .required("Required"),
};

export const ambulanceLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const ambulanceInfoSchema = {
  vehicleType: Yup.string().required("Required"),
  vehicleName: Yup.string()
    .matches(/^[^0-9]*$/, "Vehicle Name cannot contain numbers")
    .trim()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  vehicleModel: Yup.string()
    .trim()
    // .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  vehicleYear: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(2, "Must be at least 2 Digits long")
    .max(4, "Must be 4 Digits or less")
    .required("Required"),
  vehicleColor: Yup.string()
    .trim()
    .matches(/^[^0-9]*$/, "Color cannot contain numbers")
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  // vehiclefacilitiesForPatient: Yup.string()
  //   .matches(/^[0-9]+$/, "Must contain only numbers")
  //   .min(2, "Must be at least 2 Digits long")
  //   .max(15, "Must be 15 Digits or less")
  //   .required("Required"),
  vehicleRegistrationNumber: Yup.string()
    .trim()
    // .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(5, "Must be at least 5 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  vehicleRegistrationDate: Yup.string().required("Required"),
};

export const ambulancePriceSchema = {
  actualPrice: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  priceForMeditour: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
};
export const ambulanceResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const ambulanceConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};

export const ambulanceInfoUpdateProfileSchema = {
  ambulanceName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  cnic: Yup.string()
    .min(13, "Must be at least 13 Digits long")
    .max(13, "Must be 13 Digits")
    .required("Required"),
  state: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};
export const ambulanceAddbidSchema = {
  ambulancename: Yup.string().required("Required"),
  ambulanceno: Yup.string().required("Required"),
  price: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")

    .required("Required"),
};

//..................Validations..........................//

//...........Homeservice-Physiotherapist...........//

export const physiotherapistBasicInfoSchema = {
  physiotherapistDoctorName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  physiotherapistImage: Yup.string().required("Required"),
  physiotherapistCnic: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  physiotherapistCnicImage: Yup.string().required("Required"),
  physiotherapistQualification: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  physiotherapistSpeciality: Yup.string().required("Required"),
  physiotherapistServices: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  physiotherapistClinicName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  physiotherapistClinicLogo: Yup.string().required("Required"),
  physiotherapistPmdcNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  physiotherapistLicenceImage: Yup.string().required("Required"),
  physiotherapistClinicAddress: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  physiotherapistClinicExperiences: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  physiotherapistEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(50, "Must be 12 Digits or less")
    .required("Required"),
};
export const physiotherapistSocialSchema = {
  physiotherapistfbUrl: Yup.string().matches(url, "URL is not valid"),
  //.required("Required"),
  physiotherapistinstaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  physiotherapisttwitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  physiotherapistwebUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const physiotherapistBankSchema = {
  // physiotherapistIncomeTextNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // physiotherapistTaxRegistrationNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // physiotherapistTaxFile: Yup.string().required("Required"),
  // physiotherapistBankName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // physiotherapistAccountHolderName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // physiotherapistAccountNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
};
export const physiotherapistVerifySchema = {
  physiotherapistPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  physiotherapistEmail: Yup.string().email().required("Required"),
  physiotherapistPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  physiotherapistConfirmPassword: Yup.string()
    .oneOf([Yup.ref("physiotherapistPassword")], "Passwords must match")
    .required("Required"),
};
export const physiotherapistLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const Login_Schema = {
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};
export const physiotherapistResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const physiotherapistConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};

export const commonDoctorInfoUpdateProfileSchema = {
  clinicName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  cnic: Yup.string()
    .min(13, "Must be at least 13 Digits long")
    .max(13, "Must be 13 Digits")
    .required("Required"),
  state: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};
//..................Validations..........................//

//...........Homeservice-Nutrutionist...........//

export const nutrutionistBasicInfoSchema = {
  nutritionistDrName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  nutiritionistDrImage: Yup.string().required("Required"),

  // nutritionistCnic: Yup.string(),
  //    .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // nutritionCnicImage: Yup.string().required("Required"),
  nutritionistQualification: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  nutritionistSpeciality: Yup.string().required("Required"),
  nutritionServices: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  nutritionClinicExperiences: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  nutritionistClinicName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  nutritionClinicLogo: Yup.string().required("Required"),
  nutritionistClinicAddress: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  nutritionPmdcNumber: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(50, "Must be 50 Digits or less")
    .required("Required"),
  nutritionLicenseImage: Yup.string().required("Required"),
  nutritionDrEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(50, "Must be 50 Digits or less")
    .required("Required"),
};
export const nutritionistSocialSchema = {
  nutritionistfbUrl: Yup.string().matches(url, "URL is not valid"),
  //.required("Required"),
  nutritionistinstaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  nutritionisttwitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  nutritionistwebUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const nutritionistBankSchema = {
  // nutritionistIncomeTextNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // nutritionistTaxRegistrationNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // nutritionistAttachTaxFile: Yup.string().required("Required"),
  // nutritionistBankName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // nutritionistAccountHolderName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // nutritionistAccountNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
};
export const nutritionistVerifySchema = {
  nutritionistPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  nutritionistEmail: Yup.string().email().required("Required"),
  nutritionistPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  nutritionistConfirmPassword: Yup.string()
    .oneOf([Yup.ref("nutritionistPassword")], "Passwords must match")
    .required("Required"),
};

export const nutLoginSchema = {
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};
export const nutResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const nutConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};
//..................Validations..........................//

//...........Homeservice-Paramedicstaff...........//

export const paramedicStaffBasicInfoSchema = {
  paramedicdrName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  paramedicdrImage: Yup.string().required("Required"),
  // paramedicCnic: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // paramedicCnicImage: Yup.string().required("Required"),
  paramedicQualification: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  paramedicSpeciality: Yup.string().required("Required"),
  paramedicService: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  paramedicClinicExperiences: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  paramedicClinicName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  paramedicClinicLogo: Yup.string().required("Required"),
  paramedicClinicAddress: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  paramedicPmdcNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  paramedicPmdcImage: Yup.string().required("Required"),
  paramedicEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
};
export const paramedicStaffSocialSchema = {
  paramedicStafffbUrl: Yup.string().matches(url, "URL is not valid"),
  //.required("Required"),
  paramedicStaffinstaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  paramedicStafftwitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  paramedicStaffwebUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const paramedicStaffBankSchema = {
  // paramedicStaffIncomeTextNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // paramedicStaffTaxRegistrationNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // paramedicAttachTaxFile: Yup.string().required("Required"),
  // paramedicStaffBankName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // paramedicStaffAccountHolderName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // paramedicStaffAccountNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
};
export const paramedicStaffVerifySchema = {
  paramedicStaffPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  paramedicStaffEmail: Yup.string().email().required("Required"),
  paramedicStaffPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  paramedicStaffConfirmPassword: Yup.string()
    .oneOf([Yup.ref("paramedicStaffPassword")], "Passwords must match")
    .required("Required"),
};
export const paramedicLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const paramedicResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const paraConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};
//..................Validations..........................//

//...........Homeservice-Psychologist...........//

export const PsychologistBasicInfoSchema = {
  psychologistDrName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  psychologistDrImage: Yup.string().required("Required"),
  // psychologistCnicNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // psychologistCnicImage: Yup.string().required("Required"),
  psychologistQualification: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  psychologistSpeciality: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  psychologistService: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  psychologistExperience: Yup.string().required("Required"),
  psychologistClinicName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  psychologistClinicLogo: Yup.string().required("Required"),
  psychologistClinicAddress: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  psychologistPmdcNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  psychologistPmdcImage: Yup.string().required("Required"),
  psychologistEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
};
export const psychologistSocialSchema = {
  psychologistfbUrl: Yup.string().matches(url, "URL is not valid"),
  //.required("Required"),
  psychologistinstaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  psychologisttwitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  psychologistwebUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const psychologistBankSchema = {
  //   psychologistIncomeTextNumber: Yup.string()
  //     .min(2, "Must be at least 2 characters long")
  //     .max(15, "Must be 15 characters or less")
  //     .required("Required"),
  //   psychologistTaxRegistrationNumber: Yup.string()
  //     .min(2, "Must be at least 2 characters long")
  //     .max(15, "Must be 15 characters or less")
  //     .required("Required"),
  //   psychologistAttachTaxFile: Yup.string().required("Required"),
  //   psychologistBankName: Yup.string()
  //     .min(2, "Must be at least 2 characters long")
  //     .max(15, "Must be 15 characters or less")
  //     .required("Required"),
  //   psychologistAccountHolderName: Yup.string()
  //     .min(2, "Must be at least 2 characters long")
  //     .max(15, "Must be 15 characters or less")
  //     .required("Required"),
  //   psychologistAccountNumber: Yup.string()
  //     .min(2, "Must be at least 2 characters long")
  //     .max(15, "Must be 15 characters or less")
  //     .required("Required"),
  // };
  // export const psychologistVerifySchema = {
  //   psychologistPhoneNo: Yup.string()
  //     .matches(phoneRegExp, "Phone number is not valid")
  //     .required("Required"),
  //   psychologistEmail: Yup.string().email().required("Required"),
  //   psychologistPassword: Yup.string()
  //     .matches(passwordPattern, "Password is not valid")
  //     .required("Required"),
  //   psychologistConfirmPassword: Yup.string()
  //     .oneOf([Yup.ref("psychologistPassword")], "Passwords must match")
  //     .required("Required"),
};

export const psychologistLoginSchema = {
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const psychologistResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const psychologistConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};
//........Hotel.........//

export const hotelInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),

  logo: Yup.string().required("Required"),

  licenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  licenseExpiry: Yup.string().required("Required"),
  licenseImage: Yup.string().required("Required"),
  emergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),

  // cnicNumber: Yup.string()
  //   .min(2, "Must be at least 2 Digits long")
  //   .required("Required"),
  // cnicExpiry: Yup.string().required("Required"),
  // cnicImage: Yup.string().required("Required"),
  address: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};

export const hotelSocialSchema = {
  fbUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  instaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  twitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  webUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const hotelBankSchema = {
  // saleTaxNo: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // bankName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // incomeTaxNo: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // accountHolderName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // accountNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // TaxFile: Yup.string().required("Required"),
};

export const hotelVerifySchema = {
  hotelPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  hotelEmail: Yup.string().email().required("Required"),
  hotelPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  hotelConfirmPassword: Yup.string()
    .oneOf([Yup.ref("hotelPassword")], "Passwords must match")
    .required("Required"),
};

export const hotelLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const hotelResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const hotelConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};
export const hotelAddRoomHotelInfo = {
  propertyName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  startRating: Yup.string().required("Required"),
  userName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  contactNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  alternativeCellNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  postCode: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  propertyAddress: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};
export const hotelAddRoom = {
  roomType: Yup.string().required("Required"),
  roomName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  smokingPolicy: Yup.string().required("Required"),
  roomThisType: Yup.string().required("Required"),
  kindOfBeds: Yup.string().required("Required"),
  numberOfBeds: Yup.string().required("Required"), ///

  numberOfGuest: Yup.string().required("Required"),
  roomSize: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  breakfastInclude: Yup.string().required("Required"),
  pricePerNight: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  pricePerNightforMeditour: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  roomImage: Yup.string().required("Required"),
  roomDescription: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};
export const hotelFacilitites = {
  parkingAvailability: Yup.string().required("Required"),
  priceForParking: Yup.string(),
  spokenLanguageYourStaff: Yup.string().required("Required"),
};
export const hotelAmenities = {
  provideExtraBed: Yup.string().required("Required"),
  numberofExtraBed: Yup.string().required("Required"),
};
export const hotelPrivacy = {
  advanceCancelfreeofCharge: Yup.string().required("Required"),
  switch: Yup.boolean().required("Required"),
  checkinFrom: Yup.string().required("Required"),
  checkinTo: Yup.string().required("Required"),
  checkOutFrom: Yup.string().required("Required"),
  checkOutTo: Yup.string().required("Required"),
  children: Yup.string().required("Required"),
  childrenAgeFrom: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  childrenAgeTo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  chargesMayApplyorNot: Yup.string().required("Required"),
  chargesofChild: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  pets: Yup.string().required("Required"),
  chargesofPetApply: Yup.string().required("Required"),
  chargesofPets: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};

export const hotelAddHomesHomeInfo = {
  propertyName: Yup.string()
    .min(2, "Must be at least 2 characters long")

    .required("Required"),
  name: Yup.string().required("Required"),
  contactNo: Yup.string().required("Required"),
  alternativeContactNo: Yup.string().required("Required"),
  postCode: Yup.string().required("Required"),
  propertyAddress: Yup.string().required("Required"),
  // lat: Yup.number(),
  // lng: Yup.number(),
  // city: Yup.string(),
  partOfCompany: Yup.string().required("Required"),
  channelManager: Yup.string().required("Required"),
  companyName: Yup.string(),
  managerName: Yup.string(),
};
export const hotelAddHomeLayout = {
  homeType: Yup.string().required("Required"),
  customName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  noofBedRoom: Yup.number().required("Required"),
  noofDiningRoom: Yup.number().required("Required"),
  noofBathRoom: Yup.number().required("Required"),
  noofKitchens: Yup.number().required("Required"),
  noofFloor: Yup.number().required("Required"),

  guestno: Yup.number().required("Required"),
  privateBatroom: Yup.boolean().required("Required"),
  commonSofaBed: Yup.number().required("Required"),
  commonGuest: Yup.number().required("Required"),
  appartmentSize: Yup.string(),
  basicpricePerNight: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")

    .required("Required"),
  pricepernightformeditour: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")

    .required("Required"),
};

export const hotelAddHomefecServices = {
  parking: Yup.string().required("Required"),
  LanguageSpoken: Yup.string().required("Required"),
  private: Yup.string(),
  onSite: Yup.string(),
  reservationNeeded: Yup.string(),
  price: Yup.string().min(2, "Must be at least 2 characters long"),
};
export const hotelAddHomePrivacy = {
  advanceCancelfreeofCharge: Yup.string().required("Required"),
  accidentalBookingPolicy: Yup.boolean().required("Required"),
  checkInFrom: Yup.string().required("Required"),
  checkInTo: Yup.string().required("Required"),
  checkOutFrom: Yup.string().required("Required"),
  checkOutTo: Yup.string().required("Required"),
  smoking: Yup.string().required("Required"),
  accomodateChildren: Yup.string().required("Required"),
  childrenAgeTo: Yup.string(),
  childrenAgeFrom: Yup.string(),
  chargesMayApply: Yup.string(),
  pets: Yup.string().required("Required"),
  chargesOfPets: Yup.string(),

  minimumStay: Yup.string().required("Required"),
};
export const hotelAddAppartmentPrivacy = {
  advanceCancelfreeofCharge: Yup.string().required("Required"),
  accidentalBookingPolicy: Yup.boolean().required("Required"),
  checkInFrom: Yup.string().required("Required"),
  checkInTo: Yup.string().required("Required"),
  checkOutFrom: Yup.string().required("Required"),
  checkOutTo: Yup.string().required("Required"),
  smoking: Yup.string().required("Required"),
  accomodateChildren: Yup.string().required("Required"),
  childrenAgeTo: Yup.string(),
  childrenAgeFrom: Yup.string(),
  chargesMayApply: Yup.string(),
  pets: Yup.string().required("Required"),
  stayeofPets: Yup.string(),
  chargesOfPets: Yup.number(),

  minimumStay: Yup.string().required("Required"),
};

export const hotelAddAppartmentFacilities = {
  parking: Yup.string().required("Required"),
  PriceOfParking: Yup.string(),
  LanguageSpoken: Yup.string().required("Required"),
};

export const hotelAddAppartmentSchema = {
  appartmentNo: Yup.string().required("Required"),
  customName: Yup.string().required("Required"),
  numberofBedRoom: Yup.string().required("Required"),
  numberofDiningRoom: Yup.string().required("Required"),
  numberofBathRoom: Yup.string().required("Required"),
  howmanyKitchens: Yup.string().required("Required"),
  noofApartments: Yup.string().required("Required"),
  guestStayinthisAppartment: Yup.number().required("Required"),
  breakfastInclude: Yup.string().required("Required"),
  privateBathroom: Yup.boolean().required("Required"),
  numberofSofabedinAppartment: Yup.string().required("Required"),
  totalGuestStay: Yup.string().required("Required"),
  appartmentSize: Yup.string(),
  basicpricePerNight: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")

    .required("Required"),
  // pricepernightformeditour: Yup.string()
  //   .matches(/^[0-9]+$/, "Must contain only numbers")

  //   .required("Required"),
};
//...........Rent A Car................//

export const rentACarInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  logo: Yup.string().required("Required"),
  licenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  licenseExpiry: Yup.string().required("Required"),
  licenseImage: Yup.string().required("Required"),
  emergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  ownerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerLastName: Yup.string().min(2, "Must be at least 2 characters long"),
  //   .required("Required"),
  // //cnicNumber: Yup.string()
  // //min(2, "Must be at least 2 Digits long")
  // //.required("Required"),

  // // cnicExpiry: Yup.string().required("Required"),

  // // cnicImage: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
};

export const rentACarSocialSchema = {
  fbUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  instaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  twitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  webUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const rentACarBankSchema = {
  // saleTaxNo: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // bankName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // incomeTaxNo: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // accountHolderName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // accountNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .max(15, "Must be 15 characters or less")
  //   .required("Required"),
  // TaxFile: Yup.string().required("Required"),
};

export const rentACarVerifySchema = {
  rentcarPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  rentcarEmail: Yup.string().email().required("Required"),
  rentcarPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  rentcarConfirmPassword: Yup.string()
    .oneOf([Yup.ref("rentcarPassword")], "Passwords must match")
    .required("Required"),
};

export const rentACarLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    // .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const rentACarResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const rentACarConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};
export const rentACarAddVahicleSchema = {
  vahicleType: Yup.string().required("Required"),
  vehicleName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  vehicleModal: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  vehicleYear: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  vehicleColour: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  vehicleVIN: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  perDayRent: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  vehicleRegistrationNum: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  vahicleRegistrationDate: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  vehicleImages: Yup.array()
    .min(1, "At least one image is required.")
    .max(3, "A maximum of 3 images are allowed.")
    .required("Vehicle images are required."),
};
export const rentCarPriceSchema = {
  actualPrice: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  priceForMediTour: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};
//..............TRAVEL AGENCY.........//

export const travelAgencyInfoSchema = {
  AgencyName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),

  AgencyLogo: Yup.string().required("Required"),

  AgencyLicenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  LicenseImage: Yup.string().required("Required"),
  // licenseExpiry: Yup.string().required("Required"),
  AgencyEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  OwnerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),

  cnicNumber: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  cnicExpiry: Yup.string().required("Required"),

  cnicImage: Yup.string().required("Required"),

  AgencyAddress: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
};

export const travelAgencySocialSchema = {
  fbUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  instaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  twitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  webUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const travelAgencyBankSchema = {
  saleTaxNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less"),
  // .required("Required"),
  bankName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less"),
  // .required("Required"),
  incomeTaxNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less"),
  // .required("Required"),
  accountHolderName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less"),
  // .required("Required"),
  accountNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  TaxFile: Yup.string(),
  // .required("Required"),
};

export const travelAgencyVerifySchema = {
  travelAgencyPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  travelAgencyEmail: Yup.string().email().required("Required"),
  travelAgencyPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  travelAgencyConfirmPassword: Yup.string()
    .oneOf([Yup.ref("travelAgencyPassword")], "Passwords must match")
    .required("Required"),
};

export const travelAgencyLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string().required("Required"),
};

export const travelAgencyResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const travelAgencyConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};
//..........Donation.........../////

export const donationInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  logo: Yup.string().required("Required"),
  licenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  licenseExpiry: Yup.string().required("Required"),
  licenseImage: Yup.string().required("Required"),
  emergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  ownerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  // cnicNumber: Yup.string().min(2, "Must be at least 2 Digits long"),
  // .required("Required"),
  // cnicExpiry: Yup.string().required(""),
  // cnicImage: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
};

export const donationSocialSchema = {
  fbUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  instaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  twitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  webUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const donationBankSchema = {
  // saleTaxNo: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // bankName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // incomeTaxNo: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // accountHolderName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // accountNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // TaxFile: Yup.string().required("Required"),
};

export const donationVerifySchema = {
  donationPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  donationEmail: Yup.string().email().required("Required"),
  donationPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  donationConfirmPassword: Yup.string()
    .oneOf([Yup.ref("donationPassword")], "Passwords must match")
    .required("Required"),
};

export const donationLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    // .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const donationResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const donationConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};

export const donationAddCriteriaSchema = {
  criteriaName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),

  image: Yup.string().required("Required"),

  description: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(45, "Must be 45 characters or less")
    .required("Required"),
};

export const donationAddPackageSchema = {
  donationTitle: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(45, "Must be 45 characters or less")
    .required("Required"),
  targetAudience: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(500, "Must be 500 characters or less")
    .required("Required"),
  totalRequiredAmount: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .required("Required"),
  totalDays: Yup.string().required("Required"),
  // image: Yup.array()
  // .of(Yup.string().url("Invalid URL").required("Required"))
  // .min(1, "At least one image is required"),

  description: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(500, "Must be 500 characters or less")
    .required("Required"),
};
export const insuranceInfoSchema = {
  CompanyName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  CompanyLogo: Yup.string().required("Required"),
  CompanyLicenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  LicenseImage: Yup.string().required("Required"),
  CompanyEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  OwnerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  // CnicNumber: Yup.string()
  //   .min(2, "Must be at least 2 Digits long")
  //   .max(15, "Must be 15 Digits or less")
  //   .required("Required"),
  CnicImage: Yup.string().required("Required"),
  CompanyAddress: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
};

export const insuranceSocialSchema = {
  fbUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  instaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  twitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  webUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const insuranceBankSchema = {
  // saleTaxNo: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // bankName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // incomeTaxNo: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // accountHolderName: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // accountNumber: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
  // TaxFile: Yup.string().required("Required"),
};

export const insuranceVerifySchema = {
  insurancePhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  insuranceEmail: Yup.string().email().required("Required"),
  insurancePassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  insuranceConfirmPassword: Yup.string()
    .oneOf([Yup.ref("insurancePassword")], "Passwords must match")
    .required("Required"),
};

export const insuranceLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const insuranceResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const insuranceConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};
export const insuranceMySelfPackageSchema = {
  insuranceAgestart: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  insuranceAgeend: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  hospitalizationStartLimit: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  hospitalizationEndLimit: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  gender: Yup.string().required("Required"),
};

export const insuranceFamilyPackageSchema = {
  insuranceAgestart: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  insuranceAgeend: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  spouseAgeStart: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digis long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  spouseAgeEnd: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  kidsAgeStart: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")

    .required("Required"),
  kidsAgeEnd: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")

    .required("Required"),
  hospitalizationStartLimit: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  hospitalizationEndLimit: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};

export const insuranceParentsPackageSchema = {
  parentsAgeStart: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  parentsAgeEnd: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  hospitalizationStartLimit: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  hospitalizationEndLimit: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
};
export const insurancePackageBasicinfo = {
  packageName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  packageLogo: Yup.string().required("Required"),
  hospitalizationPerPerson: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  dailyRoomBoardLimit: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  claimPayoutRatio: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  packageDescription: Yup.string()
    .min(2, "Must be at least 2 characters long")

    .required("Required"),
};
export const insuranceHospitalSchema = {
  hospitals: Yup.array().of(
    Yup.string()
      .min(2, "Must be at least 2 characters long")
      .max(15, "Must be 15 characters or less")
      .required("Required")
  ),
};

export const insuranceMedicalBenefits = {
  icu: Yup.string().required("Required"),
  additionalLimit: Yup.string().required("Required"),
  ambulanceService: Yup.string().required("Required"),
  coverageSpecializedInvestigation: Yup.string().required("Required"),
  weeks: Yup.string().required("Required"),
  maternity: Yup.string().required("Required"),
  policyDocument: Yup.string().required("Required"),
  claimProcess: Yup.string().required("Required"),
  heading: Yup.string()
    .min(2, "Must be at least 2 characters long")

    .required("Required"),
  description: Yup.string()
    .min(10, "Must be at least 10 characters long")

    .required("Required"),
};

export const insurancePriceSchema = {
  actualPrice: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(5, "Must be 5 Digits or less")
    .required("Required"),
  // meditourPrice: Yup.string()
  //   .min(2, "Must be at least 2 Digits long")
  //   .max(5, "Must be 5 Digits or less")
  //   .required("Required"),
  perYear: Yup.string()
    .min(2, "Must be at least 2 Digits long")

    .required("Required"),
};

export const insuranceHealthMySelfPackageSchema = {
  yourAgeStart: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  yourAgeEnd: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  spouseAgeStart: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  spouseAgeEnd: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  kidAgeStart: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  kidAgeEnd: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  hospitalizationAgeStart: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  hospitalizationAgeEnd: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};

export const insuranceHealthFamilyMedicalBenefits = {
  icu: Yup.string().required("Required"),
  additionalLimit: Yup.string().required("Required"),
  ambulanceService: Yup.string().required("Required"),
  opd: Yup.string().required("Required"),
  weeks: Yup.string().required("Required"),
  policyDocument: Yup.string().required("Required"),
  claimProcess: Yup.string().required("Required"),
  heading: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
};

export const insuranceHealthParentsPackageSchema = {
  theirAgeStart: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  theirAgeEnd: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  hospitalizationAgeStart: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  hospitalizationAgeEnd: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
};

export const insuranceHealthParentsMedicalBenefits = {
  icu: Yup.string().required("Required"),
  additionalLimit: Yup.string().required("Required"),
  ambulanceService: Yup.string().required("Required"),
  maternity: Yup.string().required("Required"),
  investigation: Yup.string().required("Required"),
  weeks: Yup.string().required("Required"),
  policyDocument: Yup.string().required("Required"),
  claimProcess: Yup.string().required("Required"),
  heading: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
};

export const insuranceTravelIndividualSchema = {
  packageName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  packageLogo: Yup.string().required("Required"),
  medicalCover: Yup.string().required("Required"),
  coveringUpTo: Yup.string().required("Required"),
  packageDescription: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  CountryName: Yup.string().required("Required"),
  packageCategory: Yup.string().required("Required"),
};

export const insuranceTravelIndividualMedicalInfoSchema = {
  medicalExpense: Yup.string().required("Required"),
  repatriationofMortalRemains: Yup.string().required("Required"),
  repatriationInCaseIllness: Yup.string().required("Required"),
  returnofDependentChildren: Yup.string().required("Required"),
  deliveryOfMedicine: Yup.string().required("Required"),
  emergencyReturnHome: Yup.string().required("Required"),
};

export const insuranceTravelFamilyMedicalInfoSchema = {
  accidentalDisability: Yup.string().required("Required"),
  repatriationofMortalRemains: Yup.string().required("Required"),
  expensesHospitalization: Yup.string().required("Required"),
  emergencyReturnHome: Yup.string().required("Required"),
};

export const insuranceTravelIndividualBenefitsSchema = {
  flghtDelay: Yup.string().required("Required"),
  lossofPassport: Yup.string().required("Required"),
  delayinArrival: Yup.string().required("Required"),
  lossOfBaggage: Yup.string().required("Required"),
};

export const insuranceTravelFamilyBenefitsSchema = {
  tripCancel: Yup.string().required("Required"),
  delayinArrival: Yup.string().required("Required"),
  flightDelay: Yup.string().required("Required"),
  travelandStay: Yup.string().required("Required"),
  lossofPassport: Yup.string().required("Required"),
  lossOfBaggage: Yup.string().required("Required"),
};
export const insuranceTravelPriceSchema = {
  actualPrice: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  // meditourPrice: Yup.string()
  //   .min(2, "Must be at least 2 Digits long")
  //   .max(15, "Must be 15 Digits or less")
  //   .required("Required"),
  perYear: Yup.string()
    .min(2, "Must be at least 2 characters long")

    .required("Required"),
};
export const insuranceTravelPolicyDocuments = {
  PolicyDocument: Yup.string().required("Required"),
};

export const travelAgencyAddTourSchema = {
  packageName: Yup.string()
    .min(2, "Must be at least 2 Digits long")

    .required("Required"),
  packageDuration: Yup.string().required("Required"),
  from: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  to: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  departDate: Yup.string().required("Required"),
  departTime: Yup.string().required("Required"),
  returnDate: Yup.string().required("Required"),
  returnTime: Yup.string().required("Required"),
  seatLimit: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  class: Yup.string().required("Required"),
};

export const travelAgencyAddTourFacilitiesSchema = {
  breakfast: Yup.string().required("Required"),
  lunch: Yup.string().required("Required"),
  dinner: Yup.string().required("Required"),
  dayByDay: Yup.string()
    .min(2, "Must be at least 2 Character long")

    .required("Required"),
};

export const travelAgencyAddTourPoliciesSchema = {
  tourPolicy: Yup.string()
    .min(2, "Must be at least 2 Character long")

    .required("Required"),
};

export const travelAgencyAddTourPriceSchema = {
  priceperHead: Yup.string()
    .min(2, "Must be at least 2 Character long")
    .max(15, "Must be 15 Character or less")
    .required("Required"),
  priceperCouple: Yup.string()
    .min(2, "Must be at least 2 Character long")
    .max(15, "Must be 15 Character or less")
    .required("Required"),
};

export const travelAgencyBidSchema = {
  companyLogo: Yup.string().required("Required"),
  companyName: Yup.string().required("Required"),
  totalpriceforTraveler: Yup.string().required("Required"),

  noofHandbag: Yup.string().required("Required"),

  baggageWeight: Yup.string().required("Required"),

  cancelationDuration: Yup.string().required("Required"),

  cancelationDetuct: Yup.string().required("Required"),

  ticketPrice: Yup.string().required("Required"),
};

export const flightAddSchema = {
  flightType: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  from: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  to: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  departDate: Yup.string().required("Required"),
  departTime: Yup.string().required("Required"),
  arrivalDate: Yup.string().required("Required"),
  arrivalTime: Yup.string().required("Required"),
  flightNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
};

export const AddminLoginSchema = {
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
};
