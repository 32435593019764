import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Doctor_Verification } from "shared/components";

import {
  insuranceCompleteSignup,
  insuranceConfrimEmail,
  insuranceSendCodeToEmail,
} from "shared/services/Insurance";
import { set_IsEmailVerified } from "shared/redux";

interface Props {
  handleClickNext: any;
}
const InsuranceVerification = (props: Partial<Props>) => {
  const dispatch = useDispatch();
  const { insuranceuser_id } = useSelector(
    (state: any) => state.root.insurance
  );

  const handleSendCodeToEmail = (
    params: any,
    setVerifyLoading: any,
    setVerifyError: any,
    handleTimer: any
  ) => {
    insuranceSendCodeToEmail(params)
      .then((res: any) => {
        handleTimer();
      })
      .catch((err: any) => {
        setVerifyError(err.response.data.message);
      })
      .finally(() => {
        setVerifyLoading(false);
      });
  };

  const handleSubmitCode = (
    params: any,
    setVerifyLoading: any,
    setCodeError: any,
    handleVerifySuccess: any
  ) => {
    insuranceConfrimEmail(params)
      .then(async (res: any) => {
        handleVerifySuccess(res);
      })
      .catch((err: any) => {
        setCodeError(err.response.data.message);
      })
      .finally(() => {
        setVerifyLoading(false);
      });
  };
  const handleSubmit = async (params: any, handleSignupSuccess: any) => {
    insuranceCompleteSignup(params, insuranceuser_id)
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          handleSignupSuccess();
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        dispatch(set_IsEmailVerified(false));
      });
  };

  return (
    <>
      <Doctor_Verification
        handle_SendCodeToEmail={handleSendCodeToEmail}
        handle_SubmitCode={handleSubmitCode}
        handle_CompleteSignup={handleSubmit}
      />
    </>
  );
};

export default InsuranceVerification;
