import React from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "./ParamedicPayment.module.css";
import PerviousNextButton from "shared/components/Pagination";
import ParamedicStaffPayment from "shared/components/ParamedicStaffTables/ParamedicStaffPayment";
function ParamedicPayment() {
  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <p
            className={classNames(
              commonstyles.fs24,
              commonstyles.semiBold,
              commonstyles.colorBlue,
              commonstyles.mb32
            )}
          >
            All Patient Payments
          </p>
          <div className={style.tabless}>
            <ParamedicStaffPayment />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParamedicPayment;
