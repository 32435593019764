import React, { useState } from "react";
import AdminNavBar from "../Components/AdminNavBar";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import Styles from "./opd.module.css";
import { CustomModal, PrimaryButton, RingLoader } from "shared/components";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";
import Datepicker from "shared/components/DatePicker";
import { FaCheckCircle } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { acceptAppointmentRequest } from "shared/services";
import dayjs from "dayjs";
export default function OPDdetail() {
  const [showSendModel, setShowSendModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aptDate, setAptDate] = useState<any>("");
  const [aptTime, setAptTime] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { state } = useLocation();
  console.log("ss...", state);
  let date = moment(state?.createdAt).format("MM-DD-YYYY h:mm a");

  const handleOpenModel = () => {
    setShowSendModel(true);

    setTimeout(() => {
      setShowSendModel(false);
      navigate("/admin/OPD");
    }, 1500);
  };
  // const handleOpenModel = () => {
  //   setShowSendModel(true);

  //   setTimeout(() => {
  //     setShowSendModel(false);
  //   }, 1500);
  // };
  const [showMessageCard, setShowMessageCard] = useState(false);

  const handleShowMessage = () => {
    setShowMessageCard(true);
  };

  const handleAptDate = (date: any) => {
    const selectedDate = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    setAptDate(formattedDate);
  };

  const handleCreateAppointment = () => {
    let isoString = "";
    if (aptDate && aptTime) {
      setError("");
      const combined = moment(`${aptDate} ${aptTime}`, "YYYY-MM-DD HH:mm:ss");

      isoString = combined.toISOString();

      let params = {
        appointmentDateAndTime: isoString,
      };
      setLoading(true);
      acceptAppointmentRequest(params, state?._id)
        .then((res: any) => {
          console.log("res.ssss...create..", res?.data);
          handleOpenModel();
        })
        .catch((err: any) => {
          alert(err?.response?.data?.message);
        })
        .finally(() => setLoading(false));
    } else {
      setError("Fill all fields!");
    }
  };
  return (
    <div className={classNames(commonStyles.col12)}>
      <div className={Styles.Navouter}>
        <AdminNavBar labelText="Request" />
      </div>
      <div className={classNames(Styles.mainOuter)}>
        <div className={Styles.flxBetween}>
          <p
            className={classNames(
              commonStyles.fs22,
              Styles.primarycolor,
              commonStyles.semiBold
            )}
          >
            OPD Patient Request Details
          </p>{" "}
          <p
            className={classNames(
              commonStyles.fs18,
              Styles.primarycolor,
              commonStyles.medium
            )}
          >
            ID: REQ1234
          </p>
        </div>
        <div className={classNames(commonStyles.flx, Styles.mt24)}>
          <div className={classNames(Styles.DetailCard)}>
            <div className={Styles.headerCard}>
              <p
                className={classNames(commonStyles.fs18, commonStyles.semiBold)}
              >
                DOCTOR
              </p>
            </div>
            <div className={Styles.headerBody}>
              <div className={classNames(commonStyles.flxBetween)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Dr ID:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.doctorId?.vendorId}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Name:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.doctorId?.name}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Email
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.doctorId?.email}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Number:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.doctorId?.phoneNumber}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Specialties:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.doctorId?.speciality}
                </p>
              </div>
            </div>
          </div>
          <div className={classNames(Styles.DetailCard)}>
            <div className={Styles.headerCard}>
              <p
                className={classNames(commonStyles.fs18, commonStyles.semiBold)}
              >
                PATIENT
              </p>
            </div>
            <div className={Styles.headerBody}>
              <div className={classNames(commonStyles.flxBetween)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Submitted at:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {date}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  MR No.:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.patientId?.mrNo}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Name:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.patientId?.name}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Email:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.patientId?.email}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Appointment:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.appointmentType}
                </p>
              </div>
            </div>
          </div>
        </div>
        {!showMessageCard && (
          <div className={classNames(Styles.mt40, Styles.Btnwidth)}>
            <PrimaryButton
              children={"Generate Appointment"}
              colorType={"admin"}
              onClick={handleShowMessage}
            />
          </div>
        )}
        {showMessageCard && (
          <div className={classNames(Styles.MessageCard)}>
            <div className={Styles.headerCard}>
              <p
                className={classNames(commonStyles.fs18, commonStyles.semiBold)}
              >
                MESSAGE
              </p>
            </div>
            <div className={Styles.messageBody} style={{ gap: "70px" }}>
              <div className={classNames(commonStyles.col6)}>
                <div className={commonStyles.col12}>
                  <div className={classNames(commonStyles.flxBetween)}>
                    <p
                      className={classNames(
                        commonStyles.fs14,
                        commonStyles.semiBold
                      )}
                    >
                      Patient Name:
                    </p>
                    <p
                      className={classNames(
                        commonStyles.fs14,
                        Styles.colorGray
                      )}
                    >
                      {state?.patientId?.name}
                    </p>
                  </div>
                  <div
                    className={classNames(commonStyles.flxBetween, Styles.mt16)}
                  >
                    <p
                      className={classNames(
                        commonStyles.fs14,
                        commonStyles.semiBold
                      )}
                    >
                      Appointment:
                    </p>
                    <p
                      className={classNames(
                        commonStyles.fs14,
                        Styles.colorGray
                      )}
                    >
                      {state?.appointmentType}
                    </p>
                  </div>
                  <div
                    className={classNames(commonStyles.flxBetween, Styles.mt16)}
                  >
                    <p
                      className={classNames(
                        commonStyles.fs14,
                        commonStyles.semiBold
                      )}
                    >
                      Doctor Name:
                    </p>
                    <p
                      className={classNames(
                        commonStyles.fs14,
                        Styles.colorGray
                      )}
                    >
                      {state?.doctorId?.name}
                    </p>
                  </div>
                  <div
                    className={classNames(commonStyles.flxBetween, Styles.mt16)}
                  >
                    <p
                      className={classNames(
                        commonStyles.fs14,
                        commonStyles.semiBold
                      )}
                    >
                      Specialties:
                    </p>
                    <p
                      className={classNames(
                        commonStyles.fs14,
                        Styles.colorGray
                      )}
                    >
                      {state?.doctorId?.speciality}
                    </p>
                  </div>
                </div>
              </div>
              <div className={commonStyles.col6}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Availability:
                </p>
                <div className={classNames(commonStyles.col12, Styles.mt8)}>
                  <div className={commonStyles.col8}>
                    <Datepicker placeholder="Date" setData={handleAptDate} />
                  </div>
                  <div className={classNames(commonStyles.col8, Styles.mt8)}>
                    <CustomTimePicker placeholder="Time" setData={setAptTime} />
                  </div>
                </div>
                {error ? (
                  <div
                    style={{ marginBottom: "8px" }}
                    className={classNames(commonStyles.error)}
                  >
                    *{error}
                  </div>
                ) : null}
                <div className={Styles.sendOuter}>
                  <div style={{ width: "100px" }}>
                    <PrimaryButton
                      children={
                        loading ? (
                          <RingLoader size={35} color={"#fff"} />
                        ) : (
                          "Submit"
                        )
                      }
                      colorType={"admin"}
                      // onClick={handleOpenModel}
                      onClick={handleCreateAppointment}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <CustomModal showModal={showSendModel}>
          <div style={{ width: "420px" }}>
            <FaCheckCircle className={Styles.done} />
            <p
              className={classNames(
                commonStyles.fs16,
                commonStyles.semiBold,
                commonStyles.colorBlue
              )}
              style={{ textAlign: "center" }}
            >
              Your Message has been Successfully Sent
            </p>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}
