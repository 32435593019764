import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const hotelSignup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOTEL_SIGNUP, params);
};

export const hotelSendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOTEL_SEND_CODE_TO_EMAIL, params);
};

export const hotelConfirmEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOTEL_CONFIRM_EMAIL, params);
};

export const hotelCompleteSignup = (params: any, id: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.HOTEL_COMPLETE_SIGNUP}?id=${id}`,
    params
  );
};

export const hotelLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOTEL_LOGIN, params);
};

export const resetLinkHotel = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOTEL_RESET_LINK, params);
};

export const newPasswordHotel = (params: any, token: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.HOTEL_NEWPASSWORD}?token=${token}`,
    params
  );
};

export const hotelLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.HOTEL_LOGOUT);
};

export const hotelUpdateProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.HOTEL_UPDATEPROFILE, params);
};

export const hotelAddRoomMM = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOTEL_ADD_ROOM, params);
};

export const hotelAddBnB = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOTEL_ADD_BNB, params);
};

export const hotelDASHBOARDGRAPH = () => {
  return HTTP_CLIENT.get(ENDPOINTS.HOTEL_DASHBOARDGRAPH);
};

export const hotelLASTESTRESERVATION = () => {
  return HTTP_CLIENT.get(ENDPOINTS.HOTEL_LASTESTRESERVATION);
};

export const hotelPROPERTYCOUNTS = () => {
  return HTTP_CLIENT.get(ENDPOINTS.HOTEL_PROPERTYCOUNTS);
};

export const hotelGETLATESTBOOKINGS = () => {
  return HTTP_CLIENT.get(ENDPOINTS.HOTEL_GETLATESTBOOKINGS);
};

export const hotelGETALLRESERVATION = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.HOTEL_GETALLRESERVATION}?page=${pageno}`);
};

export const hotelGETALLBOOKINGS = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.HOTEL_GETALLBOOKINGS}?page=${pageno}`);
};

export const hotelADDAPPARTMENT = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOTEL_ADDAPPARTMENT, params);
};

export const hotelGETALLAPPARTMENT = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.HOTEL_GETALLAPPARTMENT}?page=${pageno}`);
};

export const hotelGETALLHOMES = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.HOTEL_GETALLHOMES}?page=${pageno}`);
};

export const hotelGETALLBNB = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.HOTEL_GETALLBNB}?page=${pageno}`);
};
