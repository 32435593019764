import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Doctor_BankDetail } from "shared/components";

interface Props {
  handleClickNext: any;
}

const RentACarBankDetail = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const { systemType } = useSelector((state: any) => state.root.common);

  return (
    <>
      <Doctor_BankDetail kind={systemType} handleNext={handleClickNext} />
    </>
  );
};

export default RentACarBankDetail;
