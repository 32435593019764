import React, { useEffect } from "react";
import commonStyles from "shared/utils/common.module.css";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { CustomInput, PrimaryButton } from "shared/components";
import ImgPicker from "shared/components/Img-picker";
import CustomSelect from "shared/components/CustomSelect";
import { insurancePriceSchema } from "shared/utils";
import { insuranceAddHealth_Individual } from "shared/services/Insurance";
import { setInsuranceMySelfPackage } from "shared/redux";
const Price = ["Hospitalization Per person ", "Karachi", "Multan", "Islambad"];
export default function MYselfPriceInsurance() {
  const { insuranceMySelfPackage } = useSelector(
    (state: any) => state.root.insurance
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("perYear", selectedOption);
  };
  const formik = useFormik({
    initialValues: {
      actualPrice: "",
      // meditourPrice: "",
      perYear: "",
    },
    validationSchema: Yup.object(insurancePriceSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    console.log("Submit");
    let currentData = formik.values;

    let params = {
      ageCriteria: {
        startAge: insuranceMySelfPackage.insuranceAgestart,
        endAge: insuranceMySelfPackage.insuranceAgeend,
      },
      hospitalizationLimit: {
        startLimit: insuranceMySelfPackage.hospitalizationStartLimit,
        endLimit: insuranceMySelfPackage.hospitalizationEndLimit,
      },
      packageName: insuranceMySelfPackage.packageName,
      packageLogo: insuranceMySelfPackage.packageLogo,
      hospitalizationPerPerson: insuranceMySelfPackage.hospitalizationPerPerson,
      dailyRoomAndBoardLimit: insuranceMySelfPackage.dailyRoomBoardLimit,
      claimPayoutRatio: insuranceMySelfPackage.claimPayoutRatio,
      hospitals: insuranceMySelfPackage.hospitals,
      laboratories: insuranceMySelfPackage.labs,
      icuCcuLimits: insuranceMySelfPackage.icu,
      accidentalEmergencyLimits: insuranceMySelfPackage.additionalLimit,
      ambulanceCoverage: insuranceMySelfPackage.ambulanceService,
      specializedInvestigationCoverage:
        insuranceMySelfPackage.coverageSpecializedInvestigation,
      waitingPeriod: insuranceMySelfPackage.weeks,
      maternity: insuranceMySelfPackage.maternity,
      policyDocument: insuranceMySelfPackage.policyDocument,
      claimProcess: insuranceMySelfPackage.claimProcess,
      heading: insuranceMySelfPackage.heading,
      description: insuranceMySelfPackage.description,
      //CurrentData
      actualPrice: currentData.actualPrice,
      // meditourPrice: currentData.meditourPrice,
      perYear: currentData.perYear,
    };

    insuranceAddHealth_Individual(params)
      .then((res: any) => {
        console.log("Final response", res);
        navigate("/insurance/MySelfMian");
        dispatch(
          setInsuranceMySelfPackage({
            insuranceAgestart: "",
            insuranceAgeend: "",
            hospitalizationStartLimit: "",
            hospitalizationEndLimit: "",
            gender: "",
            //Basic info
            packageName: "",
            packageLogo: "",
            hospitalizationPerPerson: "",
            dailyRoomBoardLimit: "",
            claimPayoutRatio: "",
            packageDescription: "",
            // Add Hopital
            hospitals: [],
            //Add Laboratory
            labs: [],
            //Medical Services
            icu: "",
            additionalLimit: "",
            ambulanceService: "",
            coverageSpecializedInvestigation: "",
            weeks: "",
            maternity: "",
            policyDocument: "",
            claimProcess: "",
            heading: "",
            description: "",
          })
        );
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        console.log("....");
      });
  };
  // useEffect(() => {
  //   formik.setValues(insuranceMySelfPackage);
  //   console.log(insuranceMySelfPackage);
  // }, []);
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.col6)}>
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="Price "
                id="actualPrice"
                name="actualPrice"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.actualPrice}
              />
              {formik.touched.actualPrice && formik.errors.actualPrice ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.actualPrice}
                </div>
              ) : null}
            </div>
          </div>
          {/* <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="MediTour Price"
                id="meditourPrice"
                name="meditourPrice"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.meditourPrice}
              />
              {formik.touched.meditourPrice && formik.errors.meditourPrice ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.meditourPrice}
                </div>
              ) : null}
            </div>
          </div> */}
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="Per Year"
                id="perYear"
                name="perYear"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.perYear}
              />
              {formik.touched.perYear && formik.errors.perYear ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.perYear}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={commonStyles.BtnWidth}>
          <PrimaryButton children={"Submit"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
}
