import { useSelector } from "react-redux";
import Doctor_BankDetail from "shared/components/Doctor_BankDetail";

interface Props {
  handleClickNext: any;
}
const DoctorBankDetail = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const { systemType } = useSelector((state: any) => state.root.common);

  return (
    <>
      <Doctor_BankDetail kind={systemType} handleNext={handleClickNext} />
    </>
  );
};

export default DoctorBankDetail;
