import React, { useState, ChangeEvent, useEffect } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "./booking.module.css";
import BookingTickets from "./BookingTickets";
import BookingTour from "./BookingTour";
import PerviousNextButton from "shared/components/Pagination";
import { TbRefresh } from "react-icons/tb";
import { travelGETTICKETBOOKINGS } from "shared/services/TravelAgency";

function Booking() {
  const [booking, setBookingData] = useState([]);
  const [bookingTour, setBookingTour] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const [selectedOption, setSelectedOption] = useState("0");

  const [refresh, setRefresh] = useState(0);

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value);
    setPageno(1);
    setCurrentPage(1);
    handleFetchData(e.target.value, 1);
  };

  const handleFetchData = (selected: any, page: number) => {
    if (selected === "0") {
      fetchBookingFlight(page);
    }
    if (selected === "1") {
      fetchBookingTickets(page);
    }
  };

  const fetchBookingFlight = (page: number) => {
    setLoading(true);
    const requestType = "flight";
    travelGETTICKETBOOKINGS(requestType, page)
      .then((res: any) => {
        console.log("Data....fetchBookingTickets...", res);
        setBookingData(res?.data?.requests);
      })
      .catch((err: any) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchBookingTickets = (page: number) => {
    const requestType = "tour";
    travelGETTICKETBOOKINGS(requestType, page)
      .then((res: any) => {
        console.log("Data....fetchBookingTickets TOUR...", res);
        setBookingTour(res?.data?.requests);
      })
      .catch((err: any) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefresh = () => {
    setPageno(1);
    setCurrentPage(1);
    handleFetchData(selectedOption, 1);
  };

  useEffect(() => {
    setPageno(1);
    setCurrentPage(1);
    handleFetchData(selectedOption, 1);
  }, []);

  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <div
            className={classNames(commonstyles.flxBetween, commonstyles.mb32)}
          >
            <div className={commonstyles.flx}>
              <select
                className={style.bookingSelection}
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="0">Booking Tickets List</option>
                <option value="1">Booking Tour List</option>
              </select>
              <div className={style.outerRefresh}>
                <TbRefresh
                  className={style.RefreshIcon}
                  onClick={handleRefresh}
                />
              </div>
            </div>
          </div>
          <div>
            {selectedOption === "0" ? (
              <BookingTickets booking={booking} />
            ) : null}
            {selectedOption === "1" ? (
              <BookingTour bookingTour={bookingTour} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Booking;
