import React, { useEffect } from "react";
import style from "./vender.module.css";
import { useNavigate } from "react-router-dom";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import NavBarr from "../NavBarr";
import Doctor from "assets/images/doctor.png";
import Hospitals from "assets/images/hospital.png";
import Laboratory from "assets/images/lab.png";
import Pharmacy from "assets/images/pharmacyLogo.png";
import Ambulance from "assets/images/Ambulance.png";
import Physiotherapist from "assets/images/physiotherapist.png";
import Paramedic from "assets/images/paramedic.png";
import Nutritionist from "assets/images/nutritionist.png";
import Psyschologist from "assets/images/psychologist.png";
import Hotels from "assets/images/hotels.png";
import Rent from "assets/images/Rent.png";
import Donation from "assets/images/Donation.png";
import Insurance from "assets/images/Insurance.png";
import Travel from "assets/images/travel-agency.png";
import Admin from "assets/images/AdminLogo.png";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setSystemType } from "shared/redux";
export default function Vender() {
  const { systemType } = useSelector((state: any) => state.root.common);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "MediTour Global | Vendors";
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();

  const handleGoToAmbulance = () => {
    navigate("/homeservices/ambulanceservices/login");
  };
  const handleGoToParamedic = () => {
    navigate("/homeservices/paramedicstaff/login");
  };
  const handleGoTo_Doc = (type: any) => {
    dispatch(setSystemType(type));
    if (type === "rentacar") {
      navigate("/traveltourism/rentAcar/login");
    } else {
      navigate(`/${type}/login`);
    }
  };
  const handleGoToHotel = () => {
    dispatch(setSystemType("hotel"));
    navigate("/traveltourism/hotel/login");
  };

  const handleGoToTravel = () => {
    dispatch(setSystemType("travelagency"));
    navigate("/traveltourism/travelAgency/login");
  };
  const handleGoToDonation = () => {
    dispatch(setSystemType("donation"));

    navigate("/donation/login");
  };
  const handleGoToInsurance = (type: any) => {
    dispatch(setSystemType(type));
    navigate(`/${type}/login`);
  };
  const handleGoToAdmin = () => {
    navigate("/admin/login");
  };
  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Vendors
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Vendors
          </p>
        </div>
      </div>
      <div className={style.container} >
        <div className={style.RegisterOuter} >
          <div
            className={style.RegisterCard}
            onClick={() => handleGoTo_Doc("doctor")}
          >
            <div className={style.logoOuter}>
              <img src={Doctor} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Doctor
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Doctors
              </p>
            </div>
          </div>
          <div
            className={style.RegisterCard}
            onClick={() => handleGoTo_Doc("hospital")}
          >
            <div className={style.logoOuter}>
              <img src={Hospitals} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Hospital
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Hospital
              </p>
            </div>
          </div>{" "}
          <div
            className={style.RegisterCard}
            onClick={() => handleGoTo_Doc("laboratory")}
          >
            <div className={style.logoOuter}>
              <img src={Laboratory} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Laboratory
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Laboratory
              </p>
            </div>
          </div>
          <div
            className={style.RegisterCard}
            onClick={() => handleGoTo_Doc("pharmaceutical")}
          >
            <div className={style.logoOuter}>
              <img src={Pharmacy} className={style.phrlog} />
            </div>

            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Pharmaceutical
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Pharmaceutical
              </p>
            </div>
          </div>
          <div
            className={style.RegisterCard}
            onClick={() => handleGoTo_Doc("pharmacy")}
          >
            <div className={style.logoOuter}>
              <img src={Pharmacy} className={style.phrlog} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Pharmacy
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Pharmacy
              </p>
            </div>
          </div>
          <div className={style.RegisterCard} onClick={handleGoToHotel}>
            <div className={style.logoOuter}>
              <img src={Hotels} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Hotels
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Hotels
              </p>
            </div>
          </div>
          <div className={style.RegisterCard} onClick={handleGoToParamedic}>
            <div className={style.logoOuter}>
              <img src={Paramedic} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Paramedic staff
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Paramedic staff
              </p>
            </div>
          </div>
          <div
            className={style.RegisterCard}
            onClick={() => handleGoTo_Doc("physiotherapist")}
          >
            <div className={style.logoOuter}>
              <img src={Physiotherapist} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Physiotherapist
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Physiotherapist
              </p>
            </div>
          </div>
          <div
            className={style.RegisterCard}
            onClick={() => handleGoTo_Doc("psychologist")}
          >
            <div className={style.logoOuter}>
              <img src={Psyschologist} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Psychologist
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Psychologist
              </p>
            </div>
          </div>
          <div
            className={style.RegisterCard}
            onClick={() => handleGoTo_Doc("nutritionist")}
          >
            <div className={style.logoOuter}>
              <img src={Nutritionist} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Nutritionist
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Nutritionist
              </p>
            </div>
          </div>
          <div className={style.RegisterCard} onClick={handleGoToAmbulance}>
            <div className={style.logoOuter}>
              <img src={Ambulance} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Ambulance
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as an Ambulance
              </p>
            </div>
          </div>
          <div className={style.RegisterCard} onClick={handleGoToTravel}>
            <div className={style.logoOuter}>
              <img src={Travel} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Travel Agency
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Company
              </p>
            </div>
          </div>
          <div
            className={style.RegisterCard}
            onClick={() => handleGoTo_Doc("rentacar")}
          >
            <div className={style.logoOuter}>
              <img src={Rent} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Rent a Car
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Company
              </p>
            </div>
          </div>
          <div
            className={style.RegisterCard}
            onClick={() => handleGoToInsurance("insurance")}
          >
            <div className={style.logoOuter}>
              <img src={Insurance} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Insurance
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Company
              </p>
            </div>
          </div>
          <div className={style.RegisterCard} onClick={handleGoToDonation}>
            <div className={style.logoOuter}>
              <img src={Donation} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Donation
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Company
              </p>
            </div>
          </div>{" "}
          {/* <div className={style.RegisterCard} onClick={handleGoToAdmin}>
            <div className={style.logoOuter}>
              <img src={Admin} className={style.iconimg} />
            </div>
            <div className={style.ml16}>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
              >
                Admin
              </p>
              <p className={classNames(commonstyles.fs12, style.mt4)}>
                Registration as a Company
              </p>
            </div>
          </div> */}
          <div className={style.EMPTY}></div>
        </div>
      </div>
    </div>
  );
}
