import React, { useState, useEffect } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "./Insuredperson.module.css";
import InsuredpersonTable from "shared/components/InsuranceTables/Insuredperson";
import PerviousNextButton from "shared/components/Pagination";
import { insuranceGetInsuredPersons } from "shared/services/Insurance";
import { LoadingModal } from "shared/components";
import Pagination from "shared/components/Pagination/Pagination";
import { TbRefresh } from "react-icons/tb";
import {
  setInsuranceInsuredPersons,
  setInsuredPeronsLength,
  setInsuredPersonsRenderFlag,
} from "shared/redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NewPagination from "shared/components/NewPagination/NewPagination";

function InsuranceRequest() {
  const {
    insuranceInsuredPersons,
    insuredPersonsRenderFlag,
    insuredPeronsLength,
  } = useSelector((state: any) => state.root.insurance);

  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = insuredPeronsLength;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const fetchInsuredPersons = (pageno: number) => {
    setLoading(true);
    insuranceGetInsuredPersons(pageno)
      .then((res: any) => {
        console.log("RESPONSE", res);
        dispatch(setInsuranceInsuredPersons(res?.data?.insurances));
        dispatch(setInsuredPeronsLength(res?.data?.totalLength));
      })
      .catch((err: any) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRotate = () => {
    setRotation(rotation - rotationIncrement);
    fetchInsuredPersons(currentPage);
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (insuredPeronsLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setInsuredPersonsRenderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setInsuredPersonsRenderFlag(true));
    }
  };
  useEffect(() => {
    if (insuredPersonsRenderFlag) {
      setLoading(true);
      fetchInsuredPersons(currentPage);
      dispatch(setInsuredPersonsRenderFlag(false));
    }
  }, [insuredPersonsRenderFlag, currentPage]);

  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div className={classNames(commonstyles.mr87)}>
        <div className={classNames(commonstyles.outerContainer)}>
          <div
            className={classNames(commonstyles.mb32, commonstyles.flxBetween)}
          >
            <div className={commonstyles.flx}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Insured Listing
              </p>
              <div className={style.outerRefresh}>
                <TbRefresh
                  className={style.RefreshIcon}
                  style={{ transform: `rotate(${rotation}deg)` }}
                  onClick={handleRotate}
                />
              </div>
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          {loading ? (
            <LoadingModal showModal={loading} />
          ) : (
            <>
              <div>
                <InsuredpersonTable insurance={insuranceInsuredPersons} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default InsuranceRequest;
