import React, { useEffect } from "react";
import HomePage from "./HomeNavBar";

export default function Home() {
  useEffect(() => {
    document.title = "MediTour Global | Home";
  });
  return (
    <>
      <div>
        <HomePage />
      </div>
    </>
  );
}
