import { initializeApp } from "firebase/app";

import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAbUA738bCnZRCVgnEhSKS_oE_4Rmx91Lk",
  authDomain: "meditourglobal-ea15d.firebaseapp.com",
  projectId: "meditourglobal-ea15d",
  storageBucket: "meditourglobal-ea15d.appspot.com",
  messagingSenderId: "975483364699",
  appId: "1:975483364699:web:d9eb154bab34809e1fe67e",
  measurementId: "G-NJX7T10Y5D",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);

// API_KEY="AIzaSyAbUA738bCnZRCVgnEhSKS_oE_4Rmx91Lk",
// APP_AUTH_DOMAIN="meditourglobal-ea15d.firebaseapp.com",
// APP_PROJECT_ID="meditourglobal-ea15d",
// APP_STORAGE_BUCKET="meditourglobal-ea15d.appspot.com",
// APP_MESSAGING_SENDER_ID="975483364699",
// APP_APP_ID="1:975483364699:web:d9eb154bab34809e1fe67e",
// APP_MEASUREMENT_ID="G-NJX7T10Y5D",
// APP_VAPID_KEY="BIF9ZdobPcwXfIIPSOgr_GCvI60_kchw3CHog3uXNj-RYDlNJFUnEVgW_iip-A6QxyysS8192cJFNT8IpnWoons",
