import React from "react";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import style from "./hotelOrder.module.css";
import { useParams } from "react-router-dom";
export default function GuestDiscription() {
  const { id } = useParams();
  return (
    <div className={classNames(commonstyle.col12)}>
      <SearchBar />
      <div className={commonstyle.mr87}>
        <div className={style.outerContainer}>
          <p
            className={classNames(
              commonstyle.colorBlue,
              commonstyle.fs24,
              commonstyle.semiBold
            )}
          >
            Guest Detail
          </p>
          <div
            className={classNames(
              commonstyle.colorBlue,
              commonstyle.flx,
              commonstyle.mt56
            )}
          >
            <div
              className={classNames(
                commonstyle.fs16,
                commonstyle.semiBold,
                style.mr56
              )}
            >
              <p>No. Of Guests:</p>
              <p className={style.mt20}>Name of Guests:</p>
              <p className={style.mt20}>No. Of Child or Infant:</p>
              <p className={style.mt20}>CNIC:</p>
              <p className={style.mt20}>Check In:</p>
              <p className={style.mt20}>Check Out:</p>
              <p className={style.mt20}>Phone No:</p>
            </div>
            <div className={classNames(commonstyle.fs14)}>
              <p>3</p>
              <p className={style.mt20}>
                Wajhat Ali Khan,Wajhat Ali Khan & Wajhat Ali Khan,
              </p>
              <p className={style.mt20}>1</p>
              <p className={style.mt20}>12365-15963247-2</p>
              <p className={style.mt20}>07 Dec 2023 - 07:00AM</p>
              <p className={style.mt20}>1 Hour 55 Minutes</p>
              <p className={style.mt20}>01234546789</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
