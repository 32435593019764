import React, { FC } from "react";
import commonstyle from "shared/utils/common.module.css";
import { GoDotFill } from "react-icons/go";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import style from "./lablinechart.module.css";

// Define types for data and tooltip
interface DataItem {
  name: string;
  value1: number;
  value2: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

interface CustomLineChartDonationProps {
  data: DataItem[];
}

const LabLineChart: FC<CustomLineChartDonationProps> = ({ data }) => {
  // Calculate dynamic Y-axis domain
  const minYValue = Math.min(
    ...data.map((item) => [item.value1, item.value2]).flat()
  );
  const maxYValue = Math.max(
    ...data.map((item) => [item.value1, item.value2]).flat()
  );

  const CustomTooltip: FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      const date = label;
      const value1 = payload[0].payload.value1;
      const value2 = payload[0].payload.value2;

      return (
        <div style={{ padding: "20px" }} className={style.customTooltip}>
          <div>
            <p className={style.value}>{`Current Week : ${value1}`}</p>
            <p className={style.value}>{`Previous Week : ${value2}`}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <div
        style={{ marginBottom: "24px", padding: "0 40px" }}
        className={commonstyle.flx}
      >
        <p style={{ color: "#00276d", fontSize: "20px", fontWeight: "700" }}>
          Total Users
        </p>
        <p
          style={{
            color: " rgba(0, 39, 109, 0.4)",
            fontSize: "16px",
            fontWeight: "700",
            marginLeft: "16px",
          }}
        >
          Tests
        </p>
        <p
          style={{
            color: " rgba(0, 39, 109, 0.4)",
            fontSize: "20px",
            fontWeight: "700",
            margin: "0 24px",
          }}
        >
          |
        </p>

        <div className={commonstyle.flx}>
          <GoDotFill className={style.iconss} />
          <p
            style={{
              color: "#ff7617",
              fontSize: "14px",
              fontWeight: "700",
            }}
          >
            Current Week
          </p>
        </div>
        <div className={commonstyle.flx}>
          <GoDotFill className={style.iconss2} />
          <p
            style={{
              color: "#f0c09d",
              fontSize: "14px",
              fontWeight: "700",
            }}
          >
            Previous Week
          </p>
        </div>
      </div>
      <ResponsiveContainer width="97%" height={280}>
        <LineChart data={data}>
          <CartesianGrid strokeDashoffset="3 3" vertical={false} />
          <XAxis dataKey="name" axisLine={false} tickLine={false} />

          <YAxis
            domain={[minYValue, maxYValue]}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip content={<CustomTooltip />} />

          {/* First Line */}
          <Line
            strokeWidth={4}
            type="monotone"
            dataKey="value1"
            stroke="#f0c09d"
            activeDot={{ r: 8, stroke: "#ff7617", fill: "#f0c09d" }}
            dot={{ r: 5, stroke: "#ff7617", fill: "#f0c09d" }}
          />

          {/* Second Line */}
          <Line
            strokeWidth={4}
            type="monotone"
            dataKey="value2"
            stroke="#ff7617"
            activeDot={{ r: 8, stroke: "#f0c09d", fill: "#ff7617" }}
            dot={{ r: 5, stroke: "#f0c09d", fill: "#ff7617" }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LabLineChart;
