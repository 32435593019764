import React, { useState } from "react";
import classNames from "classnames";
import CommonStyles from "shared/utils/common.module.css";
import Styles from "./Doctor.module.css";
import CardStyles from "./Cards.module.css";
import img from "assets/images/Avatar.png";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { MdOutlineYoutubeSearchedFor } from "react-icons/md";
import { IoMdArrowForward } from "react-icons/io";
const cardData = [
  {
    name: "Savannah Nguyen",
    title: "Pediatrics",
    detail: "MBBS(Pb), FRCP (UK), MD (USA), MACP...",
    experience: "2 Years Experience",
    rating: "4.5",
  },
  {
    name: "John Doe",
    title: "Cardiology",
    detail: "MBBS, MD",
    experience: "3 Years Experience",
    rating: "4.0",
  },
  {
    name: "Jane Smith",
    title: "Orthopedics",
    detail: "MBBS, MD, FRCS",
    experience: "5 Years Experience",
    rating: "4.7",
  },
  {
    name: "Emily Johnson",
    title: "Neurology",
    detail: "MD, PhD",
    experience: "4 Years Experience",
    rating: "4.6",
  },
  {
    name: "Michael Brown",
    title: "Oncology",
    detail: "MBBS, MD, FACP",
    experience: "6 Years Experience",
    rating: "4.8",
  },
  {
    name: "Sarah Davis",
    title: "Dermatology",
    detail: "MD, FAAD",
    experience: "3 Years Experience",
    rating: "4.3",
  },
];

const DoctorServices = () => {
  const [activeTab, setActiveTab] = useState("ALL");

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div>header</div>
      <div className={classNames(CommonStyles.container)}>
        <div className={classNames(CommonStyles.flx, CommonStyles.flxBetween)}>
          <div className={classNames(Styles.tabContainer, CommonStyles.flx)}>
            <button
              className={classNames(
                Styles.tab,
                activeTab === "ALL" && Styles.activeTab
              )}
              onClick={() => handleTabClick("ALL")}
            >
              ALL
            </button>
            <button
              className={classNames(
                Styles.tab,
                activeTab === "Near by me" && Styles.activeTab
              )}
              onClick={() => handleTabClick("Near by me")}
            >
              Near by me
            </button>
            <button
              className={classNames(
                Styles.tab,
                activeTab === "Search by city" && Styles.activeTab
              )}
              onClick={() => handleTabClick("Search by city")}
            >
              Search by city
            </button>
            <button
              className={classNames(
                Styles.tab,
                activeTab === "Recommended" && Styles.activeTab
              )}
              onClick={() => handleTabClick("Recommended")}
            >
              Recommended
            </button>
          </div>
          <div className={Styles.searchBarContainer}>
            <MdOutlineYoutubeSearchedFor className={Styles.searchIcon} />
            <input
              type="text"
              placeholder="Search..."
              className={Styles.searchInput}
            />
          </div>
        </div>
        <div className={Styles.tabContent}>
          {activeTab === "ALL" && (
            <div className={classNames(CardStyles.cardContainer)}>
              {cardData.map((card, index) => (
                <div key={index} className={CardStyles.cardWrapper}>
                  <div className={CardStyles.cardImageWrapper}>
                    <img
                      src={img}
                      alt={card.name}
                      className={CardStyles.cardImage}
                    />
                  </div>
                  <div className={CardStyles.cardBody}>
                    <div
                      className={classNames(
                        CardStyles.cardName,
                        CommonStyles.colorBlue
                      )}
                    >
                      {card.name}
                    </div>
                    <div
                      className={classNames(
                        CardStyles.cardName,
                        CommonStyles.colorGreen
                      )}
                    >
                      {card.title}
                    </div>
                    <div className={CardStyles.cardDetail}>{card.detail}</div>
                    <div className={CardStyles.cardExperience}>
                      {card.experience}
                    </div>
                    <div className={CardStyles.cardFooter}>
                      <span
                        className={classNames(
                          CardStyles.cardRating,
                          CommonStyles.fs10
                        )}
                      >
                        *****
                      </span>
                      <span className={CardStyles.cardRatingValue}>
                        {card.rating}
                      </span>
                      <span
                        className={classNames(
                          CommonStyles.flx,
                          Styles.viewMore
                        )}
                      >
                        <a href="#" className={CardStyles.cardViewMore}>
                          View more
                        </a>
                        <span
                        //  style={{
                        //   width: "16px",                 // Set width of the arrow
                        //   height: "16px",                // Set height of the arrow
                        //   backgroundColor: "blue",       // Blue background
                        //   color: "white",                // White color for the arrow
                        //   borderRadius: "50%",           // Fully rounded shape
                        //   padding: "10px",               // Padding around the icon
                        //   cursor: "pointer",             // Pointer cursor on hover
                        //   textDecoration: "none",        // Remove underline from link

                        //   display: "flex",               // Ensure icon is centered
                        //   alignItems: "center",          // Center vertically
                        //   justifyContent: "center"
                        //  }}
                        >
                          <IoMdArrowForward />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {activeTab === "Near by me" && <div>Services Near by Me</div>}
          {activeTab === "Search by city" && <div>Search by City</div>}
          {activeTab === "Recommended" && <div>Recommended Services</div>}
        </div>
      </div>
      <div>
        <Footerr />
      </div>
    </>
  );
};

export default DoctorServices;
