import React, { useState, ChangeEvent, useEffect } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import MainTravelStyle from "../../../../mainTravel.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import { Checkbox } from "@mui/material";
import { orange } from "@mui/material/colors";
import * as Yup from "yup";
import { useFormik } from "formik";
import style from "./Facilitiesservices.module.css";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "shared/components/CustomSelect";
import { hotelFacilitites } from "shared/utils";
import { setHotelB_B } from "shared/redux";
import { PiPlusSquareFill } from "react-icons/pi";
const Availability = ["Yes,Paid", "Yes,Free", "No"];
const Parking = ["100/-", "300/-", "500/-"];
const Language = ["English", "Urdu"];
interface Props {
  handleClickNext: any;
}
export default function Facilitiesservices(props: Partial<Props>) {
  const dispatch = useDispatch();
  const [error, setError] = React.useState("");
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const { hotelB_B } = useSelector((state: any) => state.root.hotel);
  const [parkingPrice, setParkingPrice] = useState<string | null>(null);
  console.log(",,,,,,,,,,,facilities Service", hotelB_B);
  const { handleClickNext } = props;
  const handleSelect = (selectedOption: string) => {
    setParkingPrice(selectedOption);
    formik.setFieldValue("parkingAvailability", selectedOption);
  };
  const parkingSelector = (selectedOption: string) => {
    formik.setFieldValue("priceForParking", selectedOption);
    console.log("Selected Option:", selectedOption);
  };
  const spokenSelector = (selectedOption: string) => {
    formik.setFieldValue("spokenLanguageYourStaff", selectedOption);
    console.log("Selected Option:", selectedOption);
  };
  const handleCheckboxChange = (value: string) => {
    if (!checkedItems.includes(value)) {
      setCheckedItems([...checkedItems, value]);
      setError("");
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== value));
      setError("");
    }
  };
  const formik = useFormik({
    initialValues: {
      parkingAvailability: "",
      priceForParking: "",
      spokenLanguageYourStaff: "",
    },
    validationSchema: Yup.object(hotelFacilitites),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  interface Property {
    propertyName: string;
    propertyDistance: string;
  }
  const [properties, setProperties] = useState<Property[]>([
    { propertyName: "", propertyDistance: "" },
  ]);

  const addProperty = () => {
    if (properties.length < 6) {
      setProperties([
        ...properties,
        { propertyName: "", propertyDistance: "" },
      ]);
    }
  };

  const handlePropertyChange = (
    index: number,
    field: keyof Property,
    value: string
  ) => {
    const newProperties = properties.map((property, i) => {
      if (i === index) {
        return { ...property, [field]: value };
      }
      return property;
    });
    setProperties(newProperties);
  };

  const handleSubmit = () => {
    if (checkedItems.length === 0) {
      setError("Please Select Facility");
    } else if (properties.length === 0) {
      setError("Please Add");
    } else {
      console.log("Checked Items are:", checkedItems);
      dispatch(
        setHotelB_B({
          ...hotelB_B,
          ...formik.values,
          facilities: checkedItems,
          propertySurroundings: properties,
        })
      );

      handleClickNext();
    }
  };

  useEffect(() => {
    console.log("properties.....................", properties);
  }, [properties]);
  return (
    <div className={commonstyles.col5}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomSelect
              options={Availability}
              placeholder="Parking Availability"
              onSelect={handleSelect}
            />
            {formik.touched.parkingAvailability &&
            formik.errors.parkingAvailability ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.parkingAvailability}
              </div>
            ) : null}
          </div>
          {parkingPrice === "Yes,Paid" && (
            <div className={commonstyles.col6}>
              <CustomSelect
                options={Parking}
                placeholder="Price for Parking (Per Day)"
                onSelect={parkingSelector}
              />
              {formik.touched.priceForParking &&
              formik.errors.priceForParking ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.priceForParking}
                </div>
              ) : null}
            </div>
          )}
        </div>

        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col12)}>
            <CustomSelect
              options={Language}
              placeholder="What Spoken Language your staff"
              onSelect={spokenSelector}
            />
            {formik.touched.spokenLanguageYourStaff &&
            formik.errors.spokenLanguageYourStaff ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.spokenLanguageYourStaff}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <div className={classNames(commonstyles.flx)}>
            <div className={classNames(commonstyles.flx, commonstyles.col5)}>
              <Checkbox
                value="Free Wifi"
                onChange={() => handleCheckboxChange("Free Wifi")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Free WIFI
              </p>
            </div>
            <div className={classNames(commonstyles.flx, commonstyles.col5)}>
              <Checkbox
                value="Swimming Pool"
                onChange={() => handleCheckboxChange("Swimming Pool")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Swimming Pool
              </p>
            </div>
          </div>
          <div className={classNames(commonstyles.flx)}>
            <div className={classNames(commonstyles.flx, commonstyles.col5)}>
              <Checkbox
                value="Spa"
                onChange={() => handleCheckboxChange("Spa")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Spa
              </p>
            </div>
            <div className={classNames(commonstyles.flx, commonstyles.col5)}>
              <Checkbox
                value="Non-smoking rooms"
                onChange={() => handleCheckboxChange("Non-smoking rooms")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Non-smoking rooms
              </p>
            </div>
          </div>
          <div className={classNames(commonstyles.flx)}>
            <div className={classNames(commonstyles.flx, commonstyles.col5)}>
              <Checkbox
                value="Garden"
                onChange={() => handleCheckboxChange("Garden")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Garden
              </p>
            </div>
            <div className={classNames(commonstyles.flx, commonstyles.col5)}>
              <Checkbox
                value="Water Park"
                onChange={() => handleCheckboxChange("Water Park")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Water Park
              </p>
            </div>
          </div>
          <div className={classNames(commonstyles.flx)}>
            <div className={classNames(commonstyles.flx, commonstyles.col5)}>
              <Checkbox
                value="Mosque"
                onChange={() => handleCheckboxChange("Mosque")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Mosque
              </p>
            </div>
            <div className={classNames(commonstyles.flx, commonstyles.col5)}>
              <Checkbox
                value="Beach"
                onChange={() => handleCheckboxChange("Beach")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Beach
              </p>
            </div>
          </div>
          <div className={classNames(commonstyles.flx)}>
            <div className={classNames(commonstyles.flx, commonstyles.col5)}>
              <Checkbox
                value="Night Club"
                onChange={() => handleCheckboxChange("Night Club")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Night Club
              </p>
            </div>
            <div className={classNames(commonstyles.flx, commonstyles.col5)}>
              <Checkbox
                value="Gym"
                onChange={() => handleCheckboxChange("Gym")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Gym
              </p>
            </div>
          </div>
        </div>
        {error && <div className={classNames(style.error)}>*{error}</div>}

        <div className={classNames(commonstyles.flx)}>
          <p
            className={classNames(
              commonstyles.fs20,
              commonstyles.semiBold,
              commonstyles.colorBlue,
              style.mt6
            )}
          >
            Property Surroundings
          </p>
          <p style={{ marginLeft: "8px", marginTop: "6px" }}>(Max Six)</p>
        </div>

        <div className={classNames(style.flex)}>
          {properties.map((property, index) => (
            <React.Fragment key={index}>
              <div className={classNames(commonstyles.flx)}>
                <div
                  className={classNames(commonstyles.col6, commonstyles.mr32)}
                >
                  <CustomInput
                    placeholder="Property Name"
                    value={property.propertyName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handlePropertyChange(
                        index,
                        "propertyName",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div
                  className={classNames(commonstyles.col6, commonstyles.mr32)}
                >
                  <CustomInput
                    placeholder="Distance"
                    value={property.propertyDistance}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handlePropertyChange(
                        index,
                        "propertyDistance",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        {properties.length <= 6 && (
          <div className={classNames(commonstyles.flx, style.mt8, style.jus)}>
            <PiPlusSquareFill className={style.plus} onClick={addProperty} />
            <p
              className={classNames(
                commonstyles.fs12,
                commonstyles.semiBold,
                style.colorOrange
              )}
              onClick={addProperty}
              style={{ cursor: "pointer" }}
            >
              Add Another Property
            </p>
          </div>
        )}

        <div
          style={{ marginTop: "32px" }}
          className={classNames(MainTravelStyle.buttonWidth)}
        >
          <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
}
