import React from "react";
import styles from "./footer.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaLinkedinIn } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { treatmentsData } from "shared/utils";
export default function Footerr() {
  const navigate = useNavigate();
  const handleGoContactus = () => {
    navigate("/contactUs");
  };
  const handleGoUserLogin = () => {
    navigate("/UserLogin");
  };
  const handleGoAboutUs = () => {
    navigate("/aboutUs");
  };

  const handleGoTotreatment = () => {
    navigate("/treatment");
  };
  const handleGoToprivactpolicys = () => {
    navigate("/privactpolicys");
  };
  const handleGoDetails = (title: any, ind: number) => {
    if (title == "more") {
      navigate("/treatment", {});
      return;
    }
    treatmentsData?.map((item: any, index: any) => {
      if (item?.mainTitle === title) {
        navigate("/treatment/Details", {
          state: {
            item: item?.list[ind],
            mainIndex: index,
            mainTitle: item.mainTitle,
          },
        });
      }
    });
  };
  return (
    <div className={styles.mianFooterOuter}>
      <div className={styles.Footer}>
        <div className={styles.flxBetween}>
          <div
            className={classNames(
              styles.textstart,
              commonstyles.col2,
              styles.mtsm20
            )}
          >
            <p
              className={classNames(styles.fs18, commonstyles.bold)}
              onClick={handleGoTotreatment}
            >
              Treatments
            </p>
            <p
              className={classNames(styles.mt32, styles.cursor, styles.fs16)}
              onClick={() => handleGoDetails("AESTHETIC TREATMENTS", 0)}
            >
              Aesthetic
            </p>
            <p
              className={classNames(styles.mt16, styles.cursor, styles.fs16)}
              onClick={() => handleGoDetails("TRANSPLANTS", 3)}
            >
              Hair Transplant
            </p>
            <p
              className={classNames(styles.mt16, styles.cursor, styles.fs16)}
              onClick={() => handleGoDetails("STEM CELL", 0)}
            >
              Stem Cell
            </p>
            <p
              className={classNames(styles.mt16, styles.cursor, styles.fs16)}
              onClick={() => handleGoDetails("FERTILITY", 0)}
            >
              Fertility
            </p>
            {/* <p
              className={classNames(styles.mt16, styles.cursor, styles.fs16)}
              onClick={() => handleGoDetails("BARIATRIC SURGERY", 0)}
            >
              Bariatric Surgery
            </p> */}
            <p
              className={classNames(styles.mt16, styles.cursor, styles.fs14)}
              style={{ color: "#0e54a3" }}
              onClick={() => handleGoDetails("more", 0)}
            >
              See More...
            </p>
          </div>
          <div
            className={classNames(
              styles.textstart,
              commonstyles.col2,
              styles.mtsm20
            )}
          >
            <p className={classNames(styles.fs18, commonstyles.bold)}>
              Company
            </p>
            <p
              className={classNames(styles.mt32, styles.cursor, styles.fs16)}
              onClick={handleGoAboutUs}
            >
              About Us
            </p>
            <p
              className={classNames(styles.mt16, styles.cursor, styles.fs16)}
              onClick={handleGoContactus}
            >
              Contact us
            </p>
            <p className={classNames(styles.mt16, styles.cursor, styles.fs16)}>
              Careers
            </p>
            {/* <p className={classNames(styles.mt16, styles.cursor, styles.fs16)}>
              Culture
            </p> */}
            <p
              className={classNames(styles.mt16, styles.cursor, styles.fs16)}
              onClick={handleGoToprivactpolicys}
            >
              Privacy Policy
            </p>
          </div>
          <div
            className={classNames(
              styles.textstart,
              commonstyles.col2,
              styles.mtsm20
            )}
          >
            <p className={classNames(styles.fs18, commonstyles.bold)}>
              Support
            </p>
            <p className={classNames(styles.mt32, styles.cursor, styles.fs16)}>
              Getting started
            </p>
            <p className={classNames(styles.mt16, styles.cursor, styles.fs16)}>
              Help center
            </p>
            <p
              className={classNames(styles.mt16, styles.cursor, styles.fs16)}
              onClick={handleGoContactus}
            >
              Support
            </p>
          </div>
          <div
            className={classNames(
              styles.textstart,
              commonstyles.col2,
              styles.mtsm20
            )}
          >
            <p
              className={classNames(
                styles.fs18,
                styles.cursor,
                commonstyles.bold
              )}
            >
              Downloads
            </p>{" "}
            <p className={classNames(styles.mt32, styles.fs16)}>iOS</p>
            <p className={classNames(styles.mt16, styles.cursor, styles.fs16)}>
              Android
            </p>
          </div>

          <div
            className={classNames(
              commonstyles.col3,
              styles.mtsm20,
              commonstyles.colsm12
            )}
          >
            <p
              className={classNames(
                commonstyles.fs18,
                commonstyles.bold,
                styles.textsmCenter
              )}
            >
              Subscribe to our newsletter
            </p>{" "}
            <p
              className={classNames(
                styles.mt32,
                commonstyles.fs16,
                styles.textsmCenter
              )}
            >
              Stay updated with exclusive offers and news by subscribing to our
              newsletter today. Don't miss out, join our community now!
            </p>
            <div className={styles.mt24}>
              <input placeholder="Enter Your Email" />
            </div>
            <div className={styles.mt24}>
              <button className={styles.SubscribeBtn}>
                <p>Subscribe</p>
                <IoArrowForwardCircleSharp className={styles.aRROW} />{" "}
              </button>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className={classNames(styles.FooterFlx, styles.mt100)}>
        <p
          className={classNames(
            commonstyles.colsm12,
            commonstyles.fs16,
            styles.textsmCenter
          )}
        >
          Copyright © 2024 MediTour | All Rights Reserved
        </p>
        <div
          className={classNames(
            styles.flxIcons,
            styles.mtsm20,
            commonstyles.colsm12
          )}
        >
          <a
            className={styles.iconOuter}
            href="https://www.facebook.com/profile.php?id=61560461618333&viewas=&show_switched_toast=false&show_switched_tooltip=false&is_tour_dismissed=false&is_tour_completed=false&show_podcast_settings=false&show_community_review_changes=false&should_open_composer=false&badge_type=NEW_MEMBER&show_community_rollback_toast=false&show_community_rollback=false&show_follower_visibility_disclosure=false&bypass_exit_warning=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF className={styles.FooterICONS} />
          </a>
          <a
            className={styles.iconOuter}
            href="https://twitter.com/MediTourglobal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className={styles.FooterICONS} />
          </a>
          <a
            className={styles.iconOuter}
            href="https://www.instagram.com/theMediTour.global/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PiInstagramLogoFill className={styles.FooterICONS} />
          </a>
          <a
            className={styles.iconOuter}
            href="https://www.linkedin.com/company/theMediTourglobal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn className={styles.FooterICONS} />
          </a>
          <a
            className={styles.iconOuter}
            href="https://www.youtube.com/@theMediTour.global"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoYoutube className={styles.FooterICONS} />
          </a>
        </div>
      </div>
    </div>
  );
}
