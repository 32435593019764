import classNames from "classnames";
import React, { useEffect, useState } from "react";
import style from "./RentAcarOrder.module.css";
import commomstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import RentacarOrdertable from "shared/components/RentacarTables/RentacarOrder";
import { rentcarCUSTOMERLIST } from "shared/services/RentaCar";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomerList,
  setRentcarOrderRenderFlag,
  setrentcarOrderLength,
  setrentcarOrders,
} from "shared/redux";
import { TbRefresh } from "react-icons/tb";
import { LoadingModal } from "shared/components";
import NewPagination from "shared/components/NewPagination/NewPagination";
function RentAcarOrder() {
  const { rentcarorders, rentcarorderLength, rentcarOrderRenderFlag } =
    useSelector((state: any) => state.root.rentcar);
  const itemsPerPage = 10;
  const totalItems = rentcarorderLength;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const fetchOrders = (pageno: number) => {
    setLoading(true);
    rentcarCUSTOMERLIST(pageno)
      .then((res: any) => {
        dispatch(setrentcarOrders(res?.data?.acceptedRequestsList));
        dispatch(setrentcarOrderLength(res?.data?.totalRequestsCount));
      })
      .catch((err: any) => {
        console.error("Error fetching appointments:", err);
      })
      .finally(() => setLoading(false));
  };

  const handleStatusChange = () => {
    fetchOrders(currentPage);
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (rentcarorderLength > itemTorender) {
      fetchOrders(currentPage + 1);
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setRentcarOrderRenderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      fetchOrders(currentPage - 1);
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setRentcarOrderRenderFlag(true));
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchOrders(1);
  }, []);

  return (
    <div className={classNames(commomstyles.col12)}>
      <SearchBar />
      <div className={commomstyles.mr87}>
        <div className={style.outerContainer}>
          <div className={classNames(style.flxBetween, commomstyles.mb32)}>
            <div className={classNames(commomstyles.flx)}>
              <p
                className={classNames(
                  commomstyles.fs24,
                  commomstyles.semiBold,
                  commomstyles.colorBlue
                )}
              >
                All Order
              </p>
              <div className={style.outerRefresh}>
                <TbRefresh
                  className={style.RefreshIcon}
                  onClick={() => {
                    fetchOrders(1);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>

          {loading ? (
            <LoadingModal showModal={loading} />
          ) : (
            <div className={commomstyles.mb32}>
              <RentacarOrdertable
                orders={rentcarorders}
                onStatusChange={handleStatusChange}
                type="order"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RentAcarOrder;
