import classNames from "classnames";
import React, { useEffect, useState } from "react";
import style from "./TravelDashBoard.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import { useDispatch, useSelector } from "react-redux";
import { LuUsers } from "react-icons/lu";
import Flight from "assets/images/flightIcon.png";

import TravelLineChart from "shared/components/TravelAgencyChart";
import TravelBarChart from "shared/components/TravelAgencyChart/TravelBarChart";
import {
  travelAgencyDASHDETAILS,
  travelAgencyGRAPHBYMONTH,
  travelAgencyGRAPHBYWEEK,
} from "shared/services/TravelAgency";
import {
  setFlightMonthArray,
  setFlightPaymentsArray,
  setTourMonthArray,
  setTourPaymentsArray,
  setTravelDashboardDetails,
} from "shared/redux";
const data = [
  { name: "0", value1: 0, value2: 0 },
  { name: "Jan", value1: 1000, value2: 2015 },
  { name: "Feb", value1: 2231, value2: 3440 },
  { name: "Mar", value1: 5785, value2: 4355 },
  { name: "Apr", value1: 1290, value2: 4160 },
  { name: "May", value1: 1610, value2: 1500 },
  { name: "Jun", value1: 2231, value2: 5876 },
  { name: "Jul", value1: 5100, value2: 2554 },
  { name: "Aug", value1: 2000, value2: 5000 },
  { name: "Sep", value1: 4110, value2: 2150 },
  { name: "Oct", value1: 1722, value2: 8000 },
  { name: "Nov", value1: 7885, value2: 2500 },
  { name: "Dec", value1: 2050, value2: 7125 },
];
const data2 = [
  { name: "Mon", Flights: 50, Tourists: 75 },
  { name: "Tue", Flights: 75, Tourists: 100 },
  { name: "Wed", Flights: 100, Tourists: 125 },
  { name: "Thu", Flights: 125, Tourists: 150 },
  { name: "Fri", Flights: 75, Tourists: 100 },
  { name: "Sat", Flights: 35, Tourists: 25 },
  { name: "Sun", Flights: 55, Tourists: 72 },
];
function TravelDashBoard() {
  const [totalrevenue, settotalRevenue] = useState();
  const [flightPayments, setFlightPayments] = useState();
  const [tourPayments, setTourPayments] = useState();
  const {
    travelDashboardDetails,
    flightMonthArray,
    tourMonthArray,
    flightPaymentsArray,
    tourPaymentsArray,
  } = useSelector((state: any) => state.root.travelagency);

  flightPaymentsArray.forEach(
    (item: { day: string; totalAmount: number }, index: number) => {
      data2[index].name = item.day;
      data2[index].Flights = item.totalAmount;
    }
  );
  tourPaymentsArray.forEach(
    (item: { day: string; totalAmount: number }, index: number) => {
      data2[index].Tourists = item.totalAmount;
    }
  );

  flightMonthArray.forEach(
    (item: { name: string; totalAmount: number }, index: number) => {
      data[index + 1].name = item.name;
      data[index + 1].value1 = item.totalAmount;
    }
  );

  tourMonthArray.forEach(
    (item: { name: string; totalAmount: number }, index: number) => {
      data[index + 1].value2 = item.totalAmount;
    }
  );

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchdashdetails = () => {
    setLoading(true);
    travelAgencyDASHDETAILS()
      .then((res: any) => {
        console.log("RESPONSE..............", res);
        dispatch(
          setTravelDashboardDetails({
            lastMonthFlightBooking: res?.data?.lastMonthFlightBooking,
            lastMonthTourBooking: res?.data?.lastMonthTourBooking,
            recentTourSchedule: res?.data?.recentTourSchedule,
            todayFlightBooking: res?.data?.todayFlightBooking,
            todayTourBooking: res?.data?.todayTourBooking,
          })
        );
      })
      .catch((err: any) => {
        console.log("ERROR..............", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatDuration = (decimalHours: number) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return `${hours} Hours ${minutes} Minutes`;
  };
  interface WeekDataItem {
    _id: string;
    totalAmount: number;
  }
  const fetchgraphbyweek = () => {
    setLoading(true);
    travelAgencyGRAPHBYWEEK()
      .then((res: any) => {
        console.log("travelAgencyGRAPHBYWEEKRESPONSE..............", res);
        settotalRevenue(res?.data?.totalRevenue);
        setFlightPayments(res?.data?.totalFlightPayments);
        setTourPayments(res?.data?.totalTourPayments);

        const flightsPayments: WeekDataItem[] = res.data.flightPayments;
        const travelAgencyPayments: WeekDataItem[] = res.data.tourPayments;
        const flightsPaymentsArray = flightsPayments.map(
          (item: WeekDataItem) => {
            const dateObject = new Date(item._id);
            const dayOfWeek = dateObject.toLocaleDateString("en-US", {
              weekday: "long",
            });
            return { day: dayOfWeek, totalAmount: item.totalAmount };
          }
        );
        dispatch(setFlightPaymentsArray(flightsPaymentsArray));
        const travelAgencyPaymentsArray = travelAgencyPayments.map(
          (item: WeekDataItem) => {
            const dateObject = new Date(item._id);
            const dayOfWeek = dateObject.toLocaleDateString("en-US", {
              weekday: "long",
            });
            return { day: dayOfWeek, totalAmount: item.totalAmount };
          }
        );
        dispatch(setTourPaymentsArray(travelAgencyPaymentsArray));
      })
      .catch((err: any) => {
        console.log("ERROR..............", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  interface WeekDataItem {
    _id: string;
    totalAmount: number;
  }
  const fetchgraphbyMonth = () => {
    travelAgencyGRAPHBYMONTH()
      .then((res: any) => {
        console.log("travelAgencyGRAPHBYMONTH..............", res);
        const currentTourData: WeekDataItem[] = res.data.tourPayments;
        const currentWeekData: WeekDataItem[] = res.data.flightPayments;
        const flightArray = currentWeekData.map((item: WeekDataItem) => {
          const dateObject = new Date(item._id);
          const nameofMonth = dateObject.toLocaleDateString("en-US", {
            month: "short",
          });
          return { name: nameofMonth, totalAmount: item.totalAmount };
        });
        dispatch(setFlightMonthArray(flightArray));

        const TourArray = currentTourData.map((item: WeekDataItem) => {
          const dateObject = new Date(item._id);
          const nameofMonth = dateObject.toLocaleDateString("en-US", {
            month: "short",
          });
          return { name: nameofMonth, totalAmount: item.totalAmount };
        });
        dispatch(setTourMonthArray(TourArray));
      })

      .catch((err: any) => {
        console.log("ERROR..............", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchdashdetails();
    fetchgraphbyweek();
    fetchgraphbyMonth();
  }, []);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div>
          <div className={classNames(style.Mainflx)}>
            <div className={classNames(commonstyles.col5)}>
              <div className={classNames(commonstyles.flx)}>
                <div className={style.FlightCard}>
                  <div className={commonstyles.flx}>
                    <div className={style.FlightIcon}>
                      <img src={Flight} className={style.iconss} />
                    </div>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold
                      )}
                    >
                      Flights
                    </p>
                  </div>
                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.bold,
                      style.mt16
                    )}
                  >
                    {travelDashboardDetails?.todayFlightBooking}
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.bold,
                      style.mt8
                    )}
                  >
                    Today’s Booked
                  </p>
                </div>
                <div className={style.TourCard}>
                  <div className={commonstyles.flx}>
                    <div className={style.FlightIcon}>
                      <LuUsers className={style.iconss} />
                    </div>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.bold
                      )}
                    >
                      Tour
                    </p>
                  </div>
                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.bold,
                      style.mt16
                    )}
                  >
                    {travelDashboardDetails?.todayTourBooking}
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.bold,
                      style.mt8
                    )}
                  >
                    Today’s Booked
                  </p>
                </div>
              </div>
              <div className={style.TourFlight}>
                <div className={style.flx}>
                  <div
                    className={classNames(commonstyles.col6, style.BorderRight)}
                  >
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs16,

                          style.colorGreen,
                          style.textCenter
                        )}
                      >
                        Flights
                      </p>
                      <p
                        className={classNames(
                          commonstyles.fs12,
                          style.colorGreen,
                          style.mt8,
                          style.textCenter
                        )}
                      >
                        Total Tickets booked this month
                      </p>
                      <p
                        className={classNames(
                          commonstyles.fs24,
                          style.colorGreen,
                          commonstyles.bold,
                          style.mt16,
                          style.textCenter
                        )}
                      >
                        {travelDashboardDetails?.lastMonthFlightBooking}
                      </p>
                    </div>
                  </div>
                  <div className={commonstyles.col6}>
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs16,

                          commonstyles.colorBlue,
                          style.textCenter
                        )}
                      >
                        Tourists
                      </p>
                      <p
                        className={classNames(
                          commonstyles.fs12,
                          commonstyles.colorBlue,
                          style.mt8,
                          style.textCenter
                        )}
                      >
                        Total Tourists booked this month{" "}
                      </p>
                      <p
                        className={classNames(
                          commonstyles.fs24,
                          commonstyles.colorBlue,
                          style.mt16,
                          commonstyles.bold,
                          style.textCenter
                        )}
                      >
                        {travelDashboardDetails?.lastMonthTourBooking}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.weekRevenue}>
                <TravelBarChart
                  data2={data2}
                  totalrevenue={totalrevenue}
                  flightPayments={flightPayments}
                  tourPayments={tourPayments}
                />
              </div>
            </div>

            <div className={classNames(commonstyles.col7)}>
              <div className={classNames(commonstyles.flx)}>
                <div className={style.RecentTour}>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold,
                      commonstyles.colorBlue,
                      style.mt8
                    )}
                  >
                    Recent Tour Schedule
                  </p>
                  <div className={style.flages}>
                    {travelDashboardDetails?.recentTourSchedule.map(
                      (item: any) => (
                        <div className={style.flycard}>
                          <div className={style.flxb}>
                            <p
                              className={classNames(
                                commonstyles.fs16,
                                commonstyles.bold,
                                style.mb8
                              )}
                            >
                              {item?.tourId?.departDate
                                ? new Date(
                                    item?.tourId?.departDate
                                  ).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                  })
                                : "__"}
                            </p>
                            <p
                              className={classNames(
                                commonstyles.fs16,
                                commonstyles.bold,
                                style.mb8
                              )}
                            >
                              {item?.tourId?.returnDate
                                ? new Date(
                                    item?.tourId?.returnDate
                                  ).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                  })
                                : "__"}
                            </p>
                          </div>
                          <div
                            className={classNames(style.flxb, style.colorgary)}
                          >
                            <p
                              className={classNames(
                                commonstyles.fs12,
                                commonstyles.medium
                              )}
                            >
                              {item?.tourId?.from}
                            </p>
                            <p
                              className={classNames(
                                commonstyles.fs12,
                                commonstyles.medium
                              )}
                            >
                              {formatDuration(item?.tourDuration)}
                            </p>
                            <p
                              className={classNames(
                                commonstyles.fs12,
                                commonstyles.medium
                              )}
                            >
                              {item?.tourId?.to}
                            </p>
                          </div>
                          <div
                            className={classNames(style.border, style.mt8)}
                          ></div>
                          <div className={classNames(style.flxb, style.mt8)}>
                            <p
                              className={classNames(
                                commonstyles.fs8,
                                commonstyles.bold
                              )}
                            >
                              {/* {item?.agencyId?.} */}
                            </p>
                            <p
                              className={classNames(
                                commonstyles.fs8,
                                commonstyles.bold
                              )}
                            >
                              {item?.actualPrice}
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                {/* <div className={style.RecentFlight}>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold,
                      style.colorGreen,
                      style.mt8
                    )}
                  >
                    Recent Flight Schedule
                  </p>
                  <div className={style.flages}>
                    <div className={style.flycard}>
                      <div className={style.flxb}>
                        <p
                          className={classNames(
                            commonstyles.fs16,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          16:30
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs16,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          17:45
                        </p>
                      </div>
                      <div className={classNames(style.flxb, style.colorgary)}>
                        <p
                          className={classNames(
                            commonstyles.fs12,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          Pakistan
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs12,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          1h 15m
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs12,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          Dubai
                        </p>
                      </div>
                      <div
                        className={classNames(style.border, style.mt8)}
                      ></div>
                      <div className={classNames(style.flxb, style.mt8)}>
                        <p
                          className={classNames(
                            commonstyles.fs8,
                            commonstyles.bold
                          )}
                        >
                          PIA Airlines
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs8,
                            commonstyles.bold
                          )}
                        >
                          154K
                        </p>
                      </div>
                    </div>
                    <div className={style.flycard}>
                      <div className={style.flxb}>
                        <p
                          className={classNames(
                            commonstyles.fs16,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          16:30
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs16,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          17:45
                        </p>
                      </div>
                      <div className={classNames(style.flxb, style.colorgary)}>
                        <p
                          className={classNames(
                            commonstyles.fs12,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          Pakistan
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs12,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          1h 15m
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs12,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          Dubai
                        </p>
                      </div>
                      <div
                        className={classNames(style.border, style.mt8)}
                      ></div>
                      <div className={classNames(style.flxb, style.mt8)}>
                        <p
                          className={classNames(
                            commonstyles.fs8,
                            commonstyles.bold
                          )}
                        >
                          PIA Airlines
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs8,
                            commonstyles.bold
                          )}
                        >
                          154K
                        </p>
                      </div>
                    </div>
                    <div className={style.flycard}>
                      <div className={style.flxb}>
                        <p
                          className={classNames(
                            commonstyles.fs16,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          16:30
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs16,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          17:45
                        </p>
                      </div>
                      <div className={classNames(style.flxb, style.colorgary)}>
                        <p
                          className={classNames(
                            commonstyles.fs12,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          LHR
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs12,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          1h 15m
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs12,
                            commonstyles.bold,
                            style.mb8
                          )}
                        >
                          Dubai
                        </p>
                      </div>
                      <div
                        className={classNames(style.border, style.mt8)}
                      ></div>
                      <div className={classNames(style.flxb, style.mt8)}>
                        <p
                          className={classNames(
                            commonstyles.fs8,
                            commonstyles.bold
                          )}
                        >
                          PIA Airlines
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs8,
                            commonstyles.bold
                          )}
                        >
                          154K
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className={style.overallRevenue}>
                <TravelLineChart data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TravelDashBoard;
