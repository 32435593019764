import React, { useState } from "react";
import styles from "./homeNavBar.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import MainDoctor from "assets/images/doctor-min-1.png";
import FAQ from "assets/images/FAQSImg.png";
import WorldClass from "assets/images/worldClass.png";
import cosmetic from "assets/images/CosmeticandPlasticSurgery.png";
import Aesthetic from "assets/images/Aesthetic.png";
import Hair from "assets/images/HairTransplant.png";
import StemCell from "assets/images/STEMCell.png";
import Fertility from "assets/images/Fertility.png";
import BariatricSurgery from "assets/images/BariatricSurgery.png";
import Erectile from "assets/images/ErectileDysfunction.png";
import GenderSelection from "assets/images/GenderSelection.png";
import FAQS from "shared/components/FAQs";
import { useNavigate } from "react-router-dom";
import Footerr from "./Footer";
import ContactUs from "./ContactUs";
import medi1 from "assets/images/medi.png";
import medi2 from "assets/images/medi2.png";
import Laptop from "assets/images/Laptop.png";
import Worldtour from "assets/images/worldclasstour.png";
import PatientSafety from "assets/images/Patient Safety.png";
import PostTreatment from "assets/images/Post Treatment .png";
import Healthcare from "assets/images/Healthcare.png";
import FacilitiesIcon from "assets/images/facilityIcon.png";
import SaftyIcon from "assets/images/safetyicon.png";
import TourIcon from "assets/images/touricon.png";
import CommunicationIcon from "assets/images/communicationIcon.png";
import treatmentIcon from "assets/images/treamtentIcon.png";
import Communication from "assets/images/communication.png";
import Worldclass from "assets/images/worldclass11.png";
import NavBarr from "./NavBarr";
import HomeMianSlider from "./MainSlider/index";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import TopTreatment from "./HomeSlider/TopTreatments";
import TopPlaces from "./HomeSlider/TopPlaces";
import { IoMdArrowRoundForward } from "react-icons/io";
import { treatmentsData } from "shared/utils";
const HomePage = () => {
  const [selectedTab, setSelectedTab] = useState("WorldclassQuailty");
  const handletabs = (v: string) => {
    setSelectedTab(v);
  };
  const navigate = useNavigate();
  const handleGoAboutUs = () => {
    navigate("/aboutUs");
  };

  const HandleGoFAQS = () => {
    navigate("/FAQs");
  };
  const handleGoDetails = (title: any, ind: number) => {
    treatmentsData?.map((item: any, index: any) => {
      if (item?.mainTitle === title) {
        navigate("/treatment/Details", {
          state: {
            item: item?.list[ind],
            mainIndex: index,
            mainTitle: item.mainTitle,
          },
        });
      }
    });
  };

  return (
    <div className={classNames(styles.scrollbar, styles.mainouter)}>
      <div style={{ position: "relative" }}>
        <HomeMianSlider />
      </div>{" "}
      <div
        style={{
          position: "absolute",
          top: "0px",
          zIndex: "10",
          width: "100%",
        }}
      >
        <NavBarr />
      </div>
      <div className={styles.TourismContainer}>
        <div className={styles.TourismCard}>
          <p className={classNames(styles.colorwhite, commonstyles.semiBold)}>
            MediTour Global
          </p>
          <p className={classNames(commonstyles.semiBold)}>
            in Medical Tourism
          </p>
          <p
            className={classNames(
              styles.mt24,
              commonstyles.medium,
              styles.textJustify,
              styles.Tourismtext
            )}
          >
            Rest assured, we specialize in curating exceptional medical tourism
            experiences tailored to your needs. From cosmetic enhancements to
            complex medical procedures, we connect you with top-tier healthcare
            providers globally. Count on us to facilitate your journey to
            optimal health and well-being with meticulous attention to detail
            and unparalleled expertise.
          </p>
          <button className={styles.About} onClick={handleGoAboutUs}>
            <p>About Us</p>
            <IoArrowForwardCircleSharp className={styles.Iconnns} />
          </button>
        </div>
        <div className={styles.medicalCardsOuter}>
          <div
            className={classNames(styles.medicalCard, styles.mb24)}
            onClick={() => handleGoDetails("FERTILITY", 0)}
          >
            <img alt="" src={Fertility} className={styles.medicalIcons} />
            <p
              className={classNames(
                styles.mt16,
                commonstyles.fs14,
                commonstyles.semiBold,
                styles.textCenter
              )}
            >
              FERTILITY
            </p>
          </div>
          <div
            className={classNames(styles.medicalCard, styles.mb24)}
            onClick={() => handleGoDetails("STEM CELL", 0)}
          >
            <img alt="" src={StemCell} className={styles.medicalIcons} />
            <p
              className={classNames(
                styles.mt16,
                commonstyles.fs14,
                commonstyles.semiBold,
                styles.textCenter
              )}
            >
              STEM CELL
            </p>
          </div>
          <div className={classNames(styles.medicalCard, styles.mb24)}>
            <img
              alt=""
              src={GenderSelection}
              className={styles.medicalIcons}
              onClick={() => handleGoDetails("FERTILITY", 4)}
            />
            <p
              className={classNames(
                styles.mt16,
                commonstyles.fs14,
                commonstyles.semiBold,
                styles.textCenter
              )}
            >
              GENDER SELECTION
            </p>
          </div>
          <div
            className={classNames(styles.medicalCard, styles.mb24)}
            onClick={() => handleGoDetails("AESTHETIC TREATMENTS", 0)}
          >
            <img alt="" src={Aesthetic} className={styles.medicalIcons} />
            <p
              className={classNames(
                styles.mt16,
                commonstyles.fs14,
                commonstyles.semiBold,
                styles.textCenter
              )}
            >
              AESTHETIC TREATMENTS
            </p>
          </div>
          <div
            className={classNames(styles.medicalCard, styles.mbmd24)}
            onClick={() => handleGoDetails("COSMETIC SURGERY", 0)}
          >
            <img alt="" src={cosmetic} className={styles.medicalIcons} />
            <p
              className={classNames(
                styles.mt16,
                commonstyles.fs14,
                commonstyles.semiBold,
                styles.textCenter
              )}
            >
              COSMETIC SURGERY
            </p>
          </div>
          <div
            className={classNames(styles.medicalCard, styles.mbmd24)}
            onClick={() => handleGoDetails("TRANSPLANTS", 3)}
          >
            <img alt="" src={Hair} className={styles.medicalIcons} />
            <p
              className={classNames(
                styles.mt16,
                commonstyles.fs14,
                commonstyles.semiBold,
                styles.textCenter
              )}
            >
              HAIR TRANSPLANT
            </p>
          </div>
          <div
            className={classNames(styles.medicalCard, styles.mbmd24)}
            onClick={() => handleGoDetails("BARIATRIC SURGERY", 0)}
          >
            <img
              alt=""
              src={BariatricSurgery}
              className={styles.medicalIcons}
            />
            <p
              className={classNames(
                styles.mt16,
                commonstyles.fs14,
                commonstyles.semiBold,
                styles.textCenter
              )}
            >
              BARIATRIC SURGERY
            </p>
          </div>
          <div
            className={classNames(styles.medicalCard, styles.mbmd24)}
            onClick={() => handleGoDetails("STEM CELL", 2)}
          >
            <img alt="" src={Erectile} className={styles.medicalIcons} />
            <p
              className={classNames(
                styles.mt16,
                commonstyles.fs14,
                commonstyles.semiBold,
                styles.textCenter
              )}
            >
              ERECTILE DYSFUNCTION
            </p>
          </div>
        </div>
      </div>
      <div className={styles.bgcolor22}>
        <div
          className={classNames(
            commonstyles.col5,
            commonstyles.colmd12,
            commonstyles.colsm12,
            styles.wmdd100
          )}
        >
          <img alt="" src={MainDoctor} className={styles.DocterMian} />
        </div>
        <div
          className={classNames(
            commonstyles.col6,
            commonstyles.colmd12,
            commonstyles.colsm12,
            styles.wmdd100,
            styles.mtmdd50
          )}
        >
          <p className={classNames(commonstyles.fs40, commonstyles.semiBold)}>
            <span>Medical Tourism in </span>
            <span className={styles.colororange}> Pakistan</span>
          </p>
          <p
            className={classNames(
              styles.mt24,
              styles.textJustify,
              styles.textcolor
            )}
          >
            MediTour in Pakistan offers top-tier healthcare with a focus on
            patient satisfaction and innovation. From elective procedures to
            specialized care like fertility treatments and weight loss
            surgeries, we prioritize your well-being. Our skilled professionals
            and advanced infrastructure ensure excellence in healthcare, making
            us your ultimate choice. Discover a blend of world-class medical
            expertise and Pakistan's natural beauty with MediTour. Rest assured,
            when you choose MediTour Global, you're selecting excellence in
            healthcare backed by a team of experts dedicated to your well-being
            and satisfaction.
          </p>{" "}
          <button className={styles.WhyPakistan} onClick={handleGoAboutUs}>
            <p>Why Pakistan?</p>
            <div className={styles.iconOuter}>
              <IoMdArrowRoundForward className={styles.Iconm2} />
            </div>
          </button>
        </div>
      </div>
      <div style={{ margin: "32px 0" }}>
        <div>
          <p
            className={classNames(
              commonstyles.fs32,
              commonstyles.semiBold,
              styles.textcenter
            )}
          >
            <span style={{ color: "#0e54a3" }}>Top </span>
            <span className={styles.colororange}> Tourist Attractions</span>
          </p>
          <div className={styles.center}>
            <p
              className={classNames(
                styles.SevicesText,
                styles.textcolor,
                commonstyles.fs16,
                styles.mxsm20
              )}
            >
              Pakistan is home to a wealth of beautiful tourist attractions that
              offer a blend of natural beauty, historical significance, and
              cultural richness. Here are some highlights. These attractions
              showcase the diverse beauty and cultural heritage of Pakistan,
              making it a captivating destination for travelers.
            </p>
          </div>
        </div>
        <div style={{ marginTop: "50px" }}>
          <TopPlaces />
        </div>
      </div>
      <div style={{ margin: "32px 0" }}>
        <div>
          <p
            className={classNames(
              commonstyles.fs32,
              commonstyles.semiBold,
              styles.textcenter
            )}
          >
            <span style={{ color: "#0e54a3" }}>Top </span>
            <span className={styles.colororange}>Treatments</span>
          </p>
          <div className={styles.center}>
            <p
              className={classNames(
                styles.SevicesText,
                commonstyles.fs16,
                styles.textcolor,
                styles.mxsm20
              )}
            >
              Our comprehensive treatment options cover a wide range of medical
              needs, from routine check-ups to complex surgeries. Your health
              and well-being are our top priorities, and we are committed to
              providing you with a seamless and comfortable medical journey.
            </p>
          </div>
        </div>
        <div style={{ marginTop: "50px" }}>
          <TopTreatment />
        </div>
      </div>
      <div className={classNames(styles.peopleContainer)}>
        <p
          className={classNames(
            commonstyles.fs32,
            commonstyles.semiBold,
            styles.textcenter
          )}
        >
          <span style={{ marginRight: "4px" }}>Our Main </span>
          <span className={styles.colororange}>Goals</span>
        </p>
        <div
          className={classNames(styles.flxBetween, styles.cursor, styles.mt32)}
        >
          <div className={classNames(styles.goalHeading)}>
            <div
              className={classNames(styles.goalsTabs, {
                [styles.activeTab]: selectedTab === "WorldclassQuailty",
              })}
              onClick={() => handletabs("WorldclassQuailty")}
            >
              <img alt="" src={WorldClass} className={styles.goalIocns} />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                World-class Quality
              </p>
            </div>{" "}
            <div
              className={classNames(styles.goalsTabs, {
                [styles.activeTab]: selectedTab === "Easeofcommunication",
              })}
              onClick={() => handletabs("Easeofcommunication")}
            >
              <img
                alt=""
                src={CommunicationIcon}
                className={styles.goalIocns}
              />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Ease of communication
              </p>
            </div>{" "}
            <div
              className={classNames(styles.goalsTabs, {
                [styles.activeTab]: selectedTab === "Tourism",
              })}
              onClick={() => handletabs("Tourism")}
            >
              <img alt="" src={TourIcon} className={styles.goalIocns} />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                World-Class Tourism
              </p>
            </div>{" "}
            <div
              className={classNames(styles.goalsTabs, {
                [styles.activeTab]: selectedTab === "HealthcareFacilities",
              })}
              onClick={() => handletabs("HealthcareFacilities")}
            >
              <img alt="" src={FacilitiesIcon} className={styles.goalIocns} />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                World-Class Healthcare Facilities
              </p>
            </div>{" "}
            <div
              className={classNames(styles.goalsTabs, {
                [styles.activeTab]: selectedTab === "PatientSafety",
              })}
              onClick={() => handletabs("PatientSafety")}
            >
              <img alt="" src={SaftyIcon} className={styles.goalIocns} />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Patient Safety
              </p>
            </div>{" "}
            <div
              className={classNames(styles.goalsTabs, {
                [styles.activeTab]: selectedTab === "PostTreatment",
              })}
              onClick={() => handletabs("PostTreatment")}
            >
              <img alt="" src={treatmentIcon} className={styles.goalIocns} />
              <p
                className={classNames(commonstyles.fs16, commonstyles.semiBold)}
              >
                Post Treatment - Followup
              </p>
            </div>
          </div>

          {selectedTab === "WorldclassQuailty" && (
            <>
              <div className={classNames(styles.goalImgContainer)}>
                <img alt="" src={Worldclass} className={styles.goalImg} />
              </div>
              <div className={classNames(styles.goalText)}>
                <p
                  className={classNames(
                    commonstyles.semiBold,
                    commonstyles.fs18,
                    styles.colororange,
                    styles.textsmCenter
                  )}
                >
                  1 of 6
                </p>
                <p
                  className={classNames(
                    commonstyles.semiBold,
                    commonstyles.fs32,
                    styles.mt8
                  )}
                >
                  World-Class Quality
                </p>
                <p
                  className={classNames(
                    commonstyles.medium,
                    commonstyles.fs16,
                    styles.mt8,
                    styles.textcolor,
                    styles.textJustify,
                    styles.textHight
                  )}
                >
                  Pakistan is dedicated to consistently delivering healthcare
                  services of international standards, ensuring high quality and
                  excellence in patient care.
                </p>
                <button className={styles.About} onClick={handleGoAboutUs}>
                  <p>About More</p>
                  <IoArrowForwardCircleSharp className={styles.Iconnns} />
                </button>
              </div>
            </>
          )}
          {selectedTab === "Easeofcommunication" && (
            <>
              <div className={classNames(styles.goalImgContainer)}>
                <img alt="" src={Communication} className={styles.goalImg} />
              </div>
              <div className={classNames(styles.goalText)}>
                <p
                  className={classNames(
                    commonstyles.semiBold,
                    commonstyles.fs18,
                    styles.colororange,
                    styles.textsmCenter
                  )}
                >
                  2 of 6
                </p>
                <p
                  className={classNames(
                    commonstyles.semiBold,
                    commonstyles.fs32,
                    styles.mt8
                  )}
                >
                  Ease of communication
                </p>
                <p
                  className={classNames(
                    commonstyles.medium,
                    commonstyles.fs16,
                    styles.mt8,
                    styles.textcolor,
                    styles.textJustify,
                    styles.textHight
                  )}
                >
                  Our dedicated interpretation services ensure that language
                  barriers are overcome, allowing for clear and effective
                  communication between patients and healthcare providers. With
                  our comprehensive language support, every patient can feel
                  confident and understood throughout their medical journey.
                </p>
                <button className={styles.About} onClick={handleGoAboutUs}>
                  <p>About More</p>
                  <IoArrowForwardCircleSharp className={styles.Iconm2} />
                </button>
              </div>
            </>
          )}
          {selectedTab === "Tourism" && (
            <>
              <div className={classNames(styles.goalImgContainer)}>
                <img alt="" src={Worldtour} className={styles.goalImg} />
              </div>
              <div className={classNames(styles.goalText)}>
                <p
                  className={classNames(
                    commonstyles.semiBold,
                    commonstyles.fs18,
                    styles.colororange,
                    styles.textsmCenter
                  )}
                >
                  3 of 6
                </p>
                <p
                  className={classNames(
                    commonstyles.semiBold,
                    commonstyles.fs32,
                    styles.mt8
                  )}
                >
                  World-Class Tourism
                </p>
                <p
                  className={classNames(
                    commonstyles.medium,
                    commonstyles.fs16,
                    styles.mt8,
                    styles.textcolor,
                    styles.textJustify,
                    styles.textHight
                  )}
                >
                  Experience world-class tourism with MediTour in Pakistan,
                  where every journey is infused with unparalleled beauty and
                  adventure. You'll experience the best of Pakistani hospitality
                  as our expert guides lead you on unforgettable journeys filled
                  with wonder and discovery.
                </p>
                <button className={styles.About} onClick={handleGoAboutUs}>
                  <p>About More</p>
                  <IoArrowForwardCircleSharp className={styles.Iconm2} />
                </button>
              </div>
            </>
          )}
          {selectedTab === "HealthcareFacilities" && (
            <>
              <div className={classNames(styles.goalImgContainer)}>
                <img alt="" src={Healthcare} className={styles.goalImg} />
              </div>
              <div className={classNames(styles.goalText)}>
                <p
                  className={classNames(
                    commonstyles.semiBold,
                    commonstyles.fs18,
                    styles.colororange,
                    styles.textsmCenter
                  )}
                >
                  4 of 6
                </p>
                <p
                  className={classNames(
                    commonstyles.semiBold,
                    commonstyles.fs32,
                    styles.mt8
                  )}
                >
                  World-Class Healthcare Facilities
                </p>
                <p
                  className={classNames(
                    commonstyles.medium,
                    commonstyles.fs16,
                    styles.mt8,
                    styles.textcolor,
                    styles.textJustify,
                    styles.textHight
                  )}
                >
                  We understand the importance of personalized care and
                  attention to detail. Our patient-centric approach ensures that
                  you receive tailored treatment plans and individualized
                  support throughout your journey. Whether you're seeking
                  elective procedures, specialized treatments, or routine
                  medical care, we strive to exceed your expectations and
                  deliver outcomes that inspire confidence and trust.
                </p>
                <button className={styles.About} onClick={handleGoAboutUs}>
                  <p>About More</p>
                  <IoArrowForwardCircleSharp className={styles.Iconm2} />
                </button>
              </div>
            </>
          )}
          {selectedTab === "PatientSafety" && (
            <>
              <div className={classNames(styles.goalImgContainer)}>
                <img alt="" src={PatientSafety} className={styles.goalImg} />
              </div>
              <div className={classNames(styles.goalText)}>
                <p
                  className={classNames(
                    commonstyles.semiBold,
                    commonstyles.fs18,
                    styles.colororange,
                    styles.textsmCenter
                  )}
                >
                  5 of 6
                </p>
                <p
                  className={classNames(
                    commonstyles.semiBold,
                    commonstyles.fs32,
                    styles.mt8
                  )}
                >
                  Patient Safety
                </p>
                <p
                  className={classNames(
                    commonstyles.medium,
                    commonstyles.fs16,
                    styles.mt8,
                    styles.textcolor,
                    styles.textJustify,
                    styles.textHight
                  )}
                >
                  Your safety is our utmost priority. We are committed to
                  ensuring that every aspect of your medical journey in Pakistan
                  is conducted with the highest standards of safety and care.
                </p>
                <button className={styles.About} onClick={handleGoAboutUs}>
                  <p>About More</p>
                  <IoArrowForwardCircleSharp className={styles.Iconm2} />
                </button>
              </div>
            </>
          )}
          {selectedTab === "PostTreatment" && (
            <>
              <div className={classNames(styles.goalImgContainer)}>
                <img alt="" src={PostTreatment} className={styles.goalImg} />
              </div>
              <div className={classNames(styles.goalText)}>
                <p
                  className={classNames(
                    commonstyles.semiBold,
                    commonstyles.fs18,
                    styles.colororange,
                    styles.textsmCenter
                  )}
                >
                  6 of 6
                </p>
                <p
                  className={classNames(
                    commonstyles.semiBold,
                    commonstyles.fs32,
                    styles.mt8
                  )}
                >
                  Post Treatment - Followup
                </p>
                <p
                  className={classNames(
                    commonstyles.medium,
                    commonstyles.fs16,
                    styles.mt8,
                    styles.textcolor,
                    styles.textJustify,
                    styles.textHight
                  )}
                >
                  Our commitment to your well-being doesn't end after your
                  treatment. We believe in providing comprehensive
                  post-treatment follow-up care to ensure your continued health
                  and satisfaction.
                </p>
                <button className={styles.About} onClick={handleGoAboutUs}>
                  <p>About More</p>
                  <IoArrowForwardCircleSharp className={styles.Iconm2} />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.peopleContainer22}>
        <p
          className={classNames(
            commonstyles.fs32,
            commonstyles.semiBold,
            commonstyles.mt56
          )}
        >
          <span>Why People </span>
          <span className={styles.colororange}>Choose Us</span>
        </p>
        <p
          className={classNames(
            styles.textCenter,
            commonstyles.fs16,
            commonstyles.regular,
            styles.mt8,
            styles.textcolor,
            styles.textJustify
          )}
        >
          Your Gateway to Comprehensive Online Healthcare Tours. Experience
          Seamless Access to Medical Expertise, Wellness Retreats, and Cultural
          Explorations from Anywhere. Empowering Your Health and Journey, One
          Click Away.
        </p>
        <div className={classNames(styles.flxBetween, commonstyles.mt56)}>
          <div className={styles.PeoplesCardDots}>
            <p
              className={classNames(
                commonstyles.fs32,
                commonstyles.semiBold,
                styles.colororange
              )}
            >
              25000+
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.medium,
                styles.mt8
              )}
            >
              Patient’s Consultation
            </p>
          </div>
          <div className={styles.PeoplesCardDots}>
            <p
              className={classNames(
                commonstyles.fs32,
                commonstyles.semiBold,
                styles.colororange
              )}
            >
              24/7
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.medium,
                styles.mt8
              )}
            >
              Medical Support
            </p>
          </div>
          <div className={styles.PeoplesCardDots}>
            <p
              className={classNames(
                commonstyles.fs32,
                commonstyles.semiBold,
                styles.colororange
              )}
            >
              70+
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.medium,
                styles.mt8
              )}
            >
              Accredited Hospitals
            </p>
          </div>
          <div className={styles.PeoplesCardDots}>
            <p
              className={classNames(
                commonstyles.fs32,
                commonstyles.semiBold,
                styles.colororange
              )}
            >
              3500+
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.medium,
                styles.mt8
              )}
            >
              Happy Clients
            </p>
          </div>
          <div className={styles.PeoplesCard}>
            <p
              className={classNames(
                commonstyles.fs32,
                commonstyles.semiBold,
                styles.colororange
              )}
            >
              200+
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.medium,
                styles.mt8
              )}
            >
              Doctors on panel
            </p>
          </div>
        </div>
      </div>
      <div className={styles.peopleContainer} style={{ marginTop: "80px" }}>
        <div className={styles.Frequently}>
          <p className={classNames(commonstyles.fs32, commonstyles.semiBold)}>
            <span> Frequently </span>
            <span className={styles.colororange}> Asked Question</span>
          </p>
        </div>
        <div className={styles.flxxcenter}>
          <p
            className={classNames(
              commonstyles.fs16,
              styles.mt24,
              commonstyles.col8,
              commonstyles.colsm12,
              commonstyles.colmd12,
              styles.textcenter,
              styles.textcolor
            )}
          >
            Unlocking Healthcare Excellence: MediTour's 5 Key Advantages.
            Seamlessly Connect with Top Medical Experts, Discover Wellness
            Retreats, and Explore Cultural Treasures. Elevate Your Health
            Journey with MediTour Today!
          </p>
        </div>
        <div className={classNames(styles.flxFAQ, commonstyles.mt56)}>
          <div
            className={classNames(
              commonstyles.col6,
              commonstyles.colsm12,
              commonstyles.colmd12,
              styles.wmdd100
            )}
          >
            <img alt="" src={FAQ} className={styles.FAQIMG} />
          </div>{" "}
          <div
            className={classNames(
              commonstyles.col6,
              commonstyles.colsm12,
              commonstyles.colmd12,
              styles.wmdd100
            )}
          >
            <FAQS />
            <button className={styles.About} onClick={HandleGoFAQS}>
              {" "}
              <p>See Alls..</p>
              <IoArrowForwardCircleSharp className={styles.Iconnns} />
            </button>
          </div>
        </div>
      </div>{" "}
      <div className={styles.bgcolor}>
        <ContactUs />
        <div>
          <div
            className={classNames(
              styles.flxBetween,
              styles.mt100,
              styles.textsmCenter
            )}
          >
            <div
              className={classNames(
                commonstyles.col5,
                commonstyles.colmd12,
                commonstyles.colsm12
              )}
            >
              <p
                className={classNames(commonstyles.fs32, commonstyles.semiBold)}
              >
                <span> We are on</span>
                <span className={classNames(styles.colororange)}>
                  {" "}
                  all View
                </span>
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.mt24,
                  styles.textcolor
                )}
              >
                Find us on the Play Store and App Store for seamless access to
                our services. Download now to experience convenience at your
                fingertips
              </p>
              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.col10,
                  commonstyles.colsm12,
                  commonstyles.mt56
                )}
              >
                <a
                  href="https://play.google.com/store/apps?hl=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.mediBox}
                >
                  <img alt="" src={medi1} className={styles.medi} />
                  <p className={commonstyles.fs16}>MediTour</p>
                </a>
                <a
                  href="https://www.apple.com/app-store/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.mediBox}
                >
                  <img alt="" src={medi2} className={styles.medi} />
                  <p className={commonstyles.fs16}>MediTour</p>
                </a>
              </div>
            </div>
            <div
              className={classNames(
                commonstyles.col5,
                commonstyles.colmd12,
                commonstyles.colsm12
              )}
            >
              <img alt="" src={Laptop} className={styles.Laptop} />
            </div>
          </div>
        </div>
      </div>
      <Footerr />
    </div>
  );
};

export default HomePage;
