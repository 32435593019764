import React, { useEffect, useState } from "react";
import classNames from "classnames";
import MainDonationStyle from "../../MainDonation.module.css";
import { donationBankSchema } from "shared/utils";
import commonStyles from "shared/utils/common.module.css";
import { donationSignup } from "shared/services/Donation";
import { ArrowBack, CustomInput, PrimaryButton } from "shared/components";
import { useFormik } from "formik";
import labMainStyles from "../../../Laboratory/laboratoryMain.module.css";
import { setDonationUserFormData } from "shared/redux";
import { setDonationUser_ID } from "shared/redux";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ImgPicker from "shared/components/Img-picker";

interface Props {
  handleClickNext: any;
}
const DonationBankDetail = (props: Partial<Props>) => {
  const [TaxFileError, setTaxFileError] = useState(false);
  const { handleClickNext } = props;
  const [loading, setLoading] = useState(false);
  const { fcmToken } = useSelector((state: any) => state.root.common);

  const dispatch = useDispatch();
  const { donationUserFormData } = useSelector(
    (state: any) => state.root.donation
  );
  const formik = useFormik({
    initialValues: {
      incomeTaxNo: "",
      saleTaxNo: "",
      bankName: "",
      accountHolderName: "",
      accountNumber: "",
      TaxFile: "",
    },
    validationSchema: Yup.object(donationBankSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleTaxFileUrl = (url: any) => {
    setTaxFileError(false);
    formik.setFieldValue("TaxFile", url);
  };
  const handleSubmit = async () => {
    dispatch(setDonationUserFormData({ ...formik.values }));
    setLoading(true);
    let currentData = formik.values;
    let params = {
      name: donationUserFormData.name,
      logo: donationUserFormData.logo,
      companyLicenseNo: donationUserFormData.licenseNumber,
      companyLicenseExpiry: donationUserFormData.licenseExpiry,
      licenseImage: donationUserFormData.licenseImage,
      companyEmergencyNo: donationUserFormData.emergencyNumber,
      ownerFirstName: donationUserFormData.ownerFirstName,
      ownerLastName: donationUserFormData.ownerLastName,
      cnicOrPassportNo: donationUserFormData.cnicNumber,
      cnicOrPassportExpiry: donationUserFormData.cnicExpiry,
      cnicImage: donationUserFormData.cnicImage,
      location: {
        lat: donationUserFormData.lat,
        lng: donationUserFormData.lng,
        address: donationUserFormData.address,
        city: donationUserFormData.city,
      },

      ...(donationUserFormData.webUrl && {
        website: donationUserFormData.webUrl,
      }),
      ...(donationUserFormData.twitterUrl && {
        twitter: donationUserFormData.twitterUrl,
      }),

      ...(donationUserFormData.instaUrl && {
        instagram: donationUserFormData.instaUrl,
      }),

      incomeTaxNo: currentData.incomeTaxNo,
      salesTaxNo: currentData.saleTaxNo,
      bankName: currentData.bankName,
      accountHolderName: currentData.accountHolderName,
      accountNumber: currentData.accountNumber,
      taxFileImage: currentData.TaxFile,
      fcmToken,
    };

    donationSignup(params)
      .then((res: any) => {
        handleClickNext();
        if (res.data && res.data.auth) {
          console.log("response.....", res.data);
          handleClickNext();
          dispatch(
            setDonationUserFormData({
              name: "",
              logo: "",
              licenseNumber: "",
              licenseExpiry: "",
              licenseImage: "",
              emergencyNumber: "",
              ownerFirstName: "",
              ownerLastName: "",
              cnicNumber: "",
              cnicExpiry: "",
              cnicImage: "",
              address: "",
              lat: "",
              lng: "",
              city: "",
              //........social....
              fbUrl: "",
              instaUrl: "",
              twitterUrl: "",
              webUrl: "",
            })
          );
          dispatch(setDonationUser_ID(res.data.donation._id));
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.col12, commonStyles.mt56)}>
          <div className={classNames(commonStyles.mb16, MainDonationStyle.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="Income Tax Number (Optional)"
                  id="incomeTaxNo"
                  name="incomeTaxNo"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.incomeTaxNo}
                />
                {/* {formik.touched.incomeTaxNo && formik.errors.incomeTaxNo ? ( */}
                <div className={classNames(commonStyles.error)}>
                  {/* *{formik.errors.incomeTaxNo} */}
                </div>
                {/* ) : null} */}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Sales Tax Reg. No (Optional)"
                  id="saleTaxNo"
                  name="saleTaxNo"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.saleTaxNo}
                />
                {/* {formik.touched.saleTaxNo && formik.errors.saleTaxNo ? ( */}
                <div className={classNames(commonStyles.error)}>
                  {/* *{formik.errors.saleTaxNo} */}
                </div>
                {/* ) : null} */}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <ImgPicker
                  placeholder="Attach Tax File (Optional)"
                  setData={handleTaxFileUrl}
                />
              </div>
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, MainDonationStyle.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Bank Name (Optional)"
                  id="bankName"
                  name="bankName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.bankName}
                />
                {/* {formik.touched.bankName && formik.errors.bankName ? ( */}
                <div className={classNames(commonStyles.error)}>
                  {/* *{formik.errors.bankName} */}
                </div>
                {/* ) : null} */}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Account Holder Name (Optional)"
                  id="accountHolderName"
                  name="accountHolderName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.accountHolderName}
                />
                {/* {formik.touched.accountHolderName && */}
                {/* formik.errors.accountHolderName ? ( */}
                <div className={classNames(commonStyles.error)}>
                  {/* *{formik.errors.accountHolderName} */}
                </div>
                {/* ) : null} */}
              </div>
            </div>
          </div>
          <div className={classNames(MainDonationStyle.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Account Number"
                  id="accountNumber"
                  name="accountNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.accountNumber}
                />
                {formik.touched.accountNumber && formik.errors.accountNumber ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.accountNumber}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(labMainStyles.buttonWidth)}>
          <PrimaryButton
            children={"Next"}
            type="submit"
            colorType={"Donation"}
          />
        </div>
      </form>
    </div>
  );
};

export default DonationBankDetail;
