import * as React from "react";
import tablepay from "./HotelReserveTable.module.css";
import commonstyle from "../../../utils/common.module.css";
import classNames from "classnames";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { Navigate, useNavigate } from "react-router-dom";

interface Props {
  reservations: any;
}
function HotelReserveTable(props: Partial<Props>) {
  const { reservations } = props;
  return (
    <div className={classNames(tablepay.App)}>
      <div className={tablepay.payment}>
        <div className={tablepay.headerOuter}>
          <p className={tablepay.headerclass}>Guest id</p>
          <p className={tablepay.headerclass}>Guest Name</p>
          <p className={tablepay.headerclass}>Contact</p>
          <p className={tablepay.headerclass}>Entry Date</p>
          {/* <p className={tablepay.headerclass}>Arrival time</p> */}
          <p className={tablepay.headerclass}>NO. Of Guest</p>
          <p className={tablepay.headerclass}>Property</p>
        </div>
        <div className={tablepay.tableData}>
          <table
            style={{
              margin: "0px",
            }}
          >
            <tbody className={tablepay.wapper}>
              {reservations.map((val: any, key: any) => {
                return (
                  <tr className={tablepay.tableRow} key={key}>
                    <td className={tablepay.w20}>{val?.userId?.name}</td>
                    <td className={tablepay.w20}>{val?.userId?.phone}</td>
                    <td className={tablepay.w20}>
                      {" "}
                      {val?.entryDate
                        ? new Date(val?.entryDate).toLocaleDateString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          })
                        : "__"}
                    </td>
                    {/* <td className={tablepay.w20}>
                      {val?.arrivalTime?.from
                        ? new Date(val?.arrivalTime?.from).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "__"}
                      -{" "}
                      {val?.arrivalTime?.to
                        ? new Date(val?.arrivalTime?.to).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "__"}
                    </td> */}
                    <td className={tablepay.w20}>{val?.noOfGuest}</td>
                    <td className={tablepay.w20}>
                      {val.Room}
                      <br />
                      {val.size}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default HotelReserveTable;
