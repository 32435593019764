import React, { useEffect, useState } from "react";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import * as Yup from "yup";
import style from "./Parents.module.css";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  CustomInput,
  CustomModal,
  LoadingModal,
  PrimaryButton,
} from "shared/components";
import { IoClose } from "react-icons/io5";
import User from "assets/images/Userr2.jpeg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Checkbox,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  setInsuranceHealthParentsPackage,
  setInsuranceMySelfPackage,
} from "shared/redux";
import {
  insuranceMySelfPackageSchema,
  insuranceParentsPackageSchema,
} from "shared/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { insuranceGETPARENTSINSURANCES } from "shared/services/Insurance";

export default function ParentsMain() {
  const [showParentsModal, setShowParentsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);

  const navigate = useNavigate(); // Declare once

  const handleGoBack = () => {
    navigate("/insurance/Health");
  };
  const handleGoToMyselfDetail = () => {
    navigate("/insurance/Parents/Detail");
  };

  const handleCardParents = () => {
    // Set the state to true to show the modal
    setShowParentsModal(true);
  };

  const fetchParentsInsurances = () => {
    setLoading(true);
    insuranceGETPARENTSINSURANCES()
      .then((res: any) => {
        console.log("response........", res);
        setdata(res?.data?.insurances);
      })
      .catch((err: any) => {
        console.log("error........", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchParentsInsurances();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingModal showModal={loading} />
      ) : (
        <div className={classNames(commonstyles.col12)}>
          <SearchBar />
          <div className={commonstyles.mr87}>
            <div className={classNames(commonstyles.outerContainer)}>
              <div className={commonstyles.flx}>
                <div className={commonstyles.flx}>
                  <IoArrowBack className={style.back} onClick={handleGoBack} />
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold,
                      commonstyles.colorBlue,
                      style.mr56
                    )}
                  >
                    Back{" "}
                  </p>
                </div>
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold,
                    commonstyles.colorBlue
                  )}
                >
                  Health / Parents
                </p>
                <div className={style.outerRefresh} onClick={handleCardParents}>
                  <BiSolidMessageSquareAdd className={style.RefreshIcon} />
                </div>

                <CustomModal
                  showModal={showParentsModal}
                  children={
                    <Parents setShowParentsModal={setShowParentsModal} />
                  }
                />
              </div>
              <div className={style.flxWrap}>
                {data.map((item: any) => (
                  <div
                    className={style.myselfIncCard}
                    onClick={handleGoToMyselfDetail}
                  >
                    <Avatar src={item?.packageLogo} className={style.profile} />
                    <p
                      className={classNames(
                        commonstyles.fs20,
                        commonstyles.semiBold
                      )}
                    >
                      {item?.packageName}
                    </p>
                    <p className={classNames(commonstyles.fs15, style.mt16)}>
                      Hospitalization Limit
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs15,
                        style.mt8,
                        commonstyles.semiBold
                      )}
                    >
                      {item?.hospitalizationLimit?.startLimit} -{" "}
                      {item?.hospitalizationLimit?.endLimit}
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs15,
                        style.mt16,
                        commonstyles.semiBold
                      )}
                    >
                      Claim Payout Ratio
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs15,
                        style.mt8,
                        commonstyles.semiBold
                      )}
                    >
                      {item?.claimPayoutRatio}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const Parents = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Declare once
  const handleGoParentsflow = () => {};
  const { setShowParentsModal, setTests, Tests } = props;

  const handleCloseModal = () => {
    setShowParentsModal(false);
  };

  const formik = useFormik({
    initialValues: {
      parentsAgeStart: "",
      parentsAgeEnd: "",
      hospitalizationStartLimit: "",
      hospitalizationEndLimit: "",
    },
    validationSchema: Yup.object(insuranceParentsPackageSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    console.log("Submit");
    dispatch(setInsuranceHealthParentsPackage({ ...formik.values }));
    navigate("/insurance/Parents");
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ width: "400px" }}>
          <div className={style.header}>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Parents Package
            </p>
            <IoClose className={style.close} onClick={handleCloseModal} />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <Accordion className={style.modelFeilds}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{ color: "#00276D", transform: "rotate(0deg)" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.colorBlue,
                        commonstyles.lightItalic
                      )}
                    >
                      Their age Criteria
                    </p>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className={classNames(commonstyles.flx)}>
                    <div
                      className={classNames(
                        commonstyles.col6,
                        commonstyles.mr32
                      )}
                    >
                      <CustomInput
                        placeholder="Age start"
                        id="parentsAgeStart"
                        name="parentsAgeStart"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.parentsAgeStart}
                      />
                      {formik.touched.parentsAgeStart &&
                      formik.errors.parentsAgeStart ? (
                        <div className={classNames(commonstyles.error)}>
                          *{formik.errors.parentsAgeStart}
                        </div>
                      ) : null}
                    </div>
                    <div className={classNames(commonstyles.col6)}>
                      <CustomInput
                        placeholder="Age end "
                        id="parentsAgeEnd"
                        name="parentsAgeEnd"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.parentsAgeEnd}
                      />
                      {formik.touched.parentsAgeEnd &&
                      formik.errors.parentsAgeEnd ? (
                        <div className={classNames(commonstyles.error)}>
                          *{formik.errors.parentsAgeEnd}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={style.modelFeilds}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{ color: "#00276D", border: "none" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.colorBlue,
                        commonstyles.lightItalic
                      )}
                    >
                      Select Hospitalization Limit (PKR)
                    </p>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className={classNames(commonstyles.flx)}>
                    <div
                      className={classNames(
                        commonstyles.col6,
                        commonstyles.mr32
                      )}
                    >
                      <CustomInput
                        placeholder="Start Limit"
                        id="hospitalizationStartLimit"
                        name="hospitalizationStartLimit"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.hospitalizationStartLimit}
                      />
                      {formik.touched.hospitalizationStartLimit &&
                      formik.errors.hospitalizationStartLimit ? (
                        <div className={classNames(commonstyles.error)}>
                          *{formik.errors.hospitalizationStartLimit}
                        </div>
                      ) : null}
                    </div>
                    <div className={classNames(commonstyles.col6)}>
                      <CustomInput
                        placeholder="End Limit"
                        id="hospitalizationEndLimit"
                        name="hospitalizationEndLimit"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.hospitalizationEndLimit}
                      />
                      {formik.touched.hospitalizationEndLimit &&
                      formik.errors.hospitalizationEndLimit ? (
                        <div className={classNames(commonstyles.error)}>
                          *{formik.errors.hospitalizationEndLimit}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div style={{ margin: "24px" }}>
            <PrimaryButton
              children={"Save & Continue"}
              colorType={"greenOutline"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
