import React from "react";
import style from "./filght.module.css";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import { useNavigate } from "react-router-dom";
import Flight from "assets/images/Flights1.png";
import Tour from "assets/images/Tour1.png";
import CustomizedSwitche from "shared/components/SwitchButton";

export default function TravelingPlan() {
  const navigate = useNavigate(); // Declare once
  const handleGoFlights = () => {
    navigate("/travelAgency/Flight");
  };

  const handleGoToTour = () => {
    navigate("/travelAgency/tourPakages");
  };

  const selectedStyle = {
    borderBottom: "2px solid #00276d",
  };
  return (
    <div className={classNames(commonstyles.col12, style.doctorss)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <div className={commonstyles.flx}>
            {/* <div className={style.FlightsCard} onClick={handleGoFlights}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.bold,
                  commonstyles.colorBlue
                )}
              >
                Flights
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  style.mt16,
                  commonstyles.colorBlue
                )}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard
              </p>
              <img src={Flight} className={style.flightImg} />
            </div> */}
            <div className={style.TourCard} onClick={handleGoToTour}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.bold,
                  commonstyles.colorBlue
                )}
              >
                Tour
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  style.mt16,
                  commonstyles.colorBlue
                )}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard
              </p>
              <img src={Tour} className={style.TourImg} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
