import React, { useEffect } from "react";
import NavBarr from "../NavBarr";
import classNames from "classnames";
import Rent from "assets/images/Comingsoon.png";
import style from "./treatment.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import Footerr from "../Footer";
import { useNavigate } from "react-router-dom";
import { treatmentsData } from "shared/utils";

export default function Treatment() {
  useEffect(() => {
    document.title = "MediTour Global | Treatments";
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();
  const handleGoDetails = (item: any, mainIndex: number) => {
    // navigate("/treatment/Details", { state: arrayOfObjects[0] });
    navigate("/treatment/Details", {
      state: {
        item: item,
        mainIndex: mainIndex,
        mainTitle: treatmentsData[mainIndex].mainTitle,
      },
    });
  };
  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            style.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Medical Treatments
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Medical Treatments
          </p>
        </div>
      </div>
      <div className={style.container}>
        <p
          className={classNames(
            commonstyles.fs24,
            style.colorBlue,
            commonstyles.semiBold,
            style.textCenter
          )}
        >
          MediTour offers the best hospital facilities at Affordable costs for
          treatment.
        </p>
        <div className={classNames(style.flx, commonstyles.mt56)}>
          <div className={commonstyles.colmd5}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              GYNECOLOGISTS
            </p>
            <ul className={style.list}>
              {treatmentsData[0].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 0)}>{d.title}</li>
              ))}
            </ul>
          </div>
          <div className={classNames(style.mtsm24, commonstyles.colmd5)}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              COSMETIC SURGERY
            </p>
            <ul className={style.list}>
              {treatmentsData[1].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 1)}>{d.title}</li>
              ))}
            </ul>
          </div>
          <div
            className={classNames(
              style.mtsm24,
              commonstyles.colmd5,
              style.mtmd24
            )}
          >
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              BARIATRIC SURGERY
            </p>

            <ul className={style.list}>
              {treatmentsData[2].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 2)}>{d.title}</li>
              ))}
            </ul>
          </div>
          <div
            className={classNames(
              style.mtsm24,
              commonstyles.colmd5,
              style.mtmd24
            )}
          >
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              TRANSPLANTS
            </p>
            <ul className={style.list}>
              {treatmentsData[3].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 3)}>{d.title}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classNames(style.flx, style.mt56)}>
          {" "}
          <div className={style.col6}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              OPTHALMOLOGY
            </p>
            <ul className={style.list}>
              {treatmentsData[4].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 4)}>{d.title}</li>
              ))}
            </ul>
          </div>
          <div className={classNames(style.col6, style.mtsm24)}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold,
                commonstyles.col5
              )}
            >
              CANCER
            </p>
            <ul className={style.list}>
              {treatmentsData[5].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 5)}>{d.title}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classNames(style.flx, style.mt56)}>
          {" "}
          <div className={style.col6}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              ORTHOPEDICS
            </p>

            <ul className={style.list}>
              {treatmentsData[6].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 6)}>{d.title}</li>
              ))}
            </ul>
          </div>
          <div className={classNames(style.col6, style.mtsm24)}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold,
                commonstyles.col5
              )}
            >
              STEM CELL
            </p>
            <ul className={style.list}>
              {treatmentsData[7].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 7)}>{d.title}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classNames(style.flx, style.mt56)}>
          {" "}
          <div className={style.col6}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              PEDIATRICS
            </p>

            <ul className={style.list}>
              {treatmentsData[8].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 8)}>{d.title}</li>
              ))}
            </ul>
          </div>
          <div className={classNames(style.col6, style.mtsm24)}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold,
                commonstyles.col5
              )}
            >
              DENTAL
            </p>
            <ul className={style.list}>
              {treatmentsData[9].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 9)}>{d.title}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classNames(style.flx, style.mt56)}>
          {" "}
          <div className={style.col6}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              AESTHETIC TREATMENTS
            </p>

            <ul className={style.list}>
              {treatmentsData[10].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 10)}>{d.title}</li>
              ))}
            </ul>
          </div>
          <div className={classNames(style.col6, style.mtsm24)}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold,
                commonstyles.col5
              )}
            >
              PSYCHIATRY
            </p>
            <ul className={style.list}>
              {treatmentsData[11].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 11)}>{d.title}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classNames(style.flx, style.mt56)}>
          {" "}
          <div className={style.col6}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              LUNGS
            </p>

            <ul className={style.list}>
              {treatmentsData[12].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 12)}>{d.title}</li>
              ))}
            </ul>
          </div>
          <div className={classNames(style.col6, style.mtsm24)}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold,
                commonstyles.col5
              )}
            >
              UROLOGY
            </p>
            <ul className={style.list}>
              {treatmentsData[13].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 13)}>{d.title}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classNames(style.flx, style.mt56)}>
          {" "}
          <div className={style.col6}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              GASTROENTROLOGY
            </p>

            <ul className={style.list}>
              {treatmentsData[14].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 14)}>{d.title}</li>
              ))}
            </ul>
          </div>
          <div className={classNames(style.col6, style.mtsm24)}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold,
                commonstyles.col5
              )}
            >
              NEUROLOGY
            </p>
            <ul className={style.list}>
              {treatmentsData[15].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 15)}>{d.title}</li>
              ))}
            </ul>
          </div>
        </div>{" "}
        <div className={classNames(style.flx, style.mt56)}>
          {" "}
          <div className={style.col6}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              FERTILITY
            </p>

            <ul className={style.list}>
              {treatmentsData[16].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 16)}>{d.title}</li>
              ))}
            </ul>
          </div>
          <div className={classNames(style.col6, style.mtsm24)}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold,
                commonstyles.col5
              )}
            >
              NEPHROLOGY
            </p>
            <ul className={style.list}>
              {treatmentsData[17].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 17)}>{d.title}</li>
              ))}
            </ul>
          </div>
        </div>{" "}
        <div className={classNames(style.flx, style.mt56)}>
          <div className={style.col6}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              E.N.T
            </p>

            <ul className={style.list}>
              {treatmentsData[18].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 18)}>{d.title}</li>
              ))}
            </ul>
          </div>{" "}
          <div className={style.col6}>
            <p
              className={classNames(
                commonstyles.fs18,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              CARDIOVASCULAR & METABOLIC
            </p>

            <ul className={style.list}>
              {treatmentsData[19].list.map((d: any, ind: any) => (
                <li onClick={() => handleGoDetails(d, 19)}>{d.title}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Footerr />
    </div>
  );
}
