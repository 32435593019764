import * as React from "react";
import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Datem from "assets/images/datepicker.png";
import style from "./datePicker.module.css";
import { DesktopDatePicker, DatePickerProps } from "@mui/x-date-pickers";

// Extend dayjs with UTC and Timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

interface DatepickerProps extends DatePickerProps<dayjs.Dayjs> {
  setData?: (date: dayjs.Dayjs | null) => void;
  className?: string;
  placeholder?: string;
  futureDisable?: boolean;
  value?: dayjs.Dayjs | null | undefined;
}

function MuiIcon() {
  return (
    <img src={Datem} alt="Date picker opening icon" className={style.DateImg} />
  );
}

export default function Datepicker({
  setData,
  className,
  placeholder,
  futureDisable,
  value,
  ...rest
}: DatepickerProps) {
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);

  useEffect(() => {
    if (value) {
      setSelectedDate(value);
    }
  }, [value]);

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (futureDisable && date && date.isAfter(dayjs(), "day")) {
      return;
    }
    const adjustedDate = date ? date.startOf("day").utc(true) : null;
    console.log("Selected Date:", adjustedDate);
    setSelectedDate(adjustedDate);
    if (setData) {
      console.log("Date Selected in setData", adjustedDate);
      setData(adjustedDate);
    }
  };

  const maxDate = futureDisable ? dayjs() : undefined;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        defaultValue={dayjs(new Date())}
        label={placeholder}
        value={selectedDate}
        maxDate={maxDate}
        onChange={handleDateChange}
        slots={{ openPickerIcon: MuiIcon }}
        sx={{
          width: "100%",
          "& .MuiStack-root": {
            overflow: "visible",
            textAlign: "left",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            width: "100%",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderRadius: "0px",
            borderBottom: "1px solid #6F6F72",
            padding: "10px 0px",
            fontStyle: "italic",
            fontSize: "14px",
            fontFamily: '"Poppins", sans-serif',
          },
          "& .MuiIconButton-root": {
            padding: "0px",
          },
          "& .MuiOutlinedInput-input": {
            height: "12px",
            color: "#00276d",
            fontStyle: "italic",
            fontFamily: '"Poppins", sans-serif',
            fontSize: "14px",
            paddingLeft: "0px",
          },
          "& .MuiInputLabel-root": {
            fontSize: "14px",
            fontStyle: "italic",
            fontFamily: '"Poppins", sans-serif',
            color: "#00276d",
            position: "absolute",
            left: "-13px",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "0px",
            fontSize: "14px",
            "&:hover": {
              borderColor: "#6F6F72 !important",
            },
          },
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
}
