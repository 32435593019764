import * as React from "react";
import { useState, useEffect } from "react";
import { useRef } from "react";
import style from "./patientDetailTable.module.css";
import Pstyle from "./prescription.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { PiDownloadSimpleBold } from "react-icons/pi";
import ImgPicker from "shared/components/Img-picker";
import CustomModal from "shared/components/Modal";
import { IoClose } from "react-icons/io5";
import { Button, Checkbox } from "@mui/material";
import { doctorGetPrescription } from "shared/services/DoctorService";
import DoctorReferModel from "shared/components/DoctorEmpty/DoctorReferModel";
import Downloader from "shared/components/Downloader";
import logo from "assets/images/smallLogo.png";
interface Props {
  patientDetail: any;
  medicine?: any;
  doctor?: any;
}
function PatientDetailTable(props: Partial<Props>) {
  const { patientDetail, medicine, doctor } = props;
  // console.log("Medicine........................", medicine);
  console.log("patientDetail", patientDetail);
  const [showAddProperty, setShowAddProperty] = useState(false);
  const [appointmentId, setAppointmentId] = useState();

  return (
    <div className={style.payment}>
      <div
        className={classNames(
          doctor == "physiotherists"
            ? style.Physiotherists
            : doctor == "Nutrists"
            ? style.Nutrists
            : doctor == "ParaMedic"
            ? style.ParaMedic
            : doctor == "Psychologists"
            ? style.Psychologists
            : style.headerOuter
        )}
      >
        <p className={style.headerclass}>Date</p>
        <p className={style.headerclass}>Time</p>
        <p className={style.headerclass}>Dr Name</p>
        <p className={style.headerclass}>Status</p>
        <p className={style.headerclass}>Service Name</p>
        <p className={style.headerclass}>Prescription</p>
      </div>
      <div className={style.tableData}>
        <table
          style={{
            margin: "0px",
          }}
        >
          <tbody className={style.wapper}>
            {patientDetail.map((val: any, key: any) => {
              return (
                <tr className={style.tableRow} key={key}>
                  <td className={style.w20}>
                    {val?.appointmentDateAndTime
                      ? new Date(
                          val.appointmentDateAndTime
                        ).toLocaleDateString()
                      : ""}
                  </td>
                  <td className={style.w20}>
                    {val?.appointmentDateAndTime
                      ? new Date(val.appointmentDateAndTime).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )
                      : ""}
                  </td>

                  <td className={style.w20}>{val?.doctorId?.name}</td>
                  <td className={style.w20}>{val?.status}</td>
                  <td className={style.w20}>{val?.appointmentType}</td>
                  <td
                    className={classNames(style.w20, style.Cursor, style.blue)}
                    onClick={() => {
                      setShowAddProperty(true);
                      setAppointmentId(val?.ePrescription._id);
                    }}
                  >
                    View Details
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <CustomModal
          showModal={showAddProperty}
          children={
            <ViewDetail
              patientDetail={patientDetail}
              setShowAddProperty={setShowAddProperty}
              appointmentId={appointmentId}
            />
          }
        />
      </div>
    </div>
  );
}

export default PatientDetailTable;

interface Props {
  setShowAddProperty: any;
  appointmentId: any;
}

const ViewDetail = (props: Partial<Props>) => {
  const [loading, setLoading] = useState(false);
  const { setShowAddProperty, appointmentId, patientDetail } = props;
  console.log(patientDetail, "............patientdeatils here");
  const [patientdata, setPatientData] = useState<any>([]);
  const [doctordata, setDoctorData] = useState<any>([]);

  interface Medicine {
    medicineName: string;
    medicineBrand: string;
    medicineStrength: string;
    dosage: string;
  }
  const [medicine, setMedicine] = useState<Medicine[]>([]);
  interface Tests {
    testName: string;
  }
  const [test, setTest] = useState<Tests[]>([]);
  const handleclose = () => {
    setShowAddProperty(false);
  };

  console.log("appointmentId is ", appointmentId);
  useEffect(() => {
    setLoading(true);
    doctorGetPrescription(appointmentId)
      .then((res: any) => {
        console.log("doctorGetPrescription API Response:", res.data);
        setMedicine(res?.data?.medicines);
        setPatientData(res?.data?.patientId);
        setDoctorData(res?.data?.doctorId);

        setTest(res.data.test);
      })
      .catch((err: any) => {
        console.log("API Error:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [appointmentId]);

  const onButtonClick = () => {};

  return (
    <div>
      {loading ? (
        <DoctorReferModel showModal={loading} />
      ) : (
        <>
          <IoClose onClick={handleclose} className={style.close} />
          <div className={Pstyle.container}>
            <div
              className={classNames(
                Pstyle.row,
                Pstyle.borderBottom,
                Pstyle.padding16
              )}
            >
              <div>
                <p
                  className={classNames(
                    Pstyle.bold,
                    Pstyle.noMargin,
                    Pstyle.largeText
                  )}
                >
                  {doctordata?.name}
                </p>
                <p className={classNames(Pstyle.noMargin)}>
                  {doctordata?.qualifications}
                </p>
                <p className={classNames(Pstyle.noMargin)}>
                  PM&DC Reg: {doctordata?.pmdcNumber}
                </p>
              </div>
              <img src={logo} className={Pstyle.logo} alt="Logo" />
            </div>

            <div className={Pstyle.row}>
              <p
                className={classNames(
                  Pstyle.semibold,
                  Pstyle.mediumText,
                  Pstyle.marginTop16
                )}
              >
                Patient Name:{" "}
                <span className={classNames(Pstyle.regular, Pstyle.smallText)}>
                  {patientdata?.name}
                </span>
              </p>
              <p
                className={classNames(
                  Pstyle.semibold,
                  Pstyle.mediumText,
                  Pstyle.marginTop16
                )}
              >
                M.R No:{" "}
                <span className={classNames(Pstyle.regular, Pstyle.smallText)}>
                  {patientdata?.mrNo}
                </span>
              </p>
            </div>

            <div className={classNames(Pstyle.row, Pstyle.alignItemsCenter)}>
              <p className={classNames(Pstyle.semibold, Pstyle.mediumText)}>
                Date:{" "}
                <span className={classNames(Pstyle.regular, Pstyle.smallText)}>
                  08/27/2024
                </span>
              </p>
              <p className={classNames(Pstyle.semibold, Pstyle.mediumText)}>
                Age:{" "}
                <span className={classNames(Pstyle.regular, Pstyle.smallText)}>
                  30 years old
                </span>
              </p>
            </div>

            {/* <div className={Pstyle.row}>
              <p className={classNames(Pstyle.semibold, Pstyle.mediumText)}>
                Cell:{" "}
                <span className={classNames(Pstyle.regular, Pstyle.smallText)}>
                  {patientdata?.phone}
                </span>
              </p>
            </div> */}

            {/* <p className={classNames(Pstyle.semibold, Pstyle.mediumText)}>
              Address:{" "}
              <span
                className={classNames(
                  Pstyle.regular,
                  Pstyle.smallText,
                  Pstyle.marginRight34
                )}
              >
                {patientdata?.addresses}
              </span>
            </p> */}

            <p className={classNames(Pstyle.semibold, Pstyle.mediumText)}>
              Weight (Kg):{" "}
              <span
                className={classNames(
                  Pstyle.regular,
                  Pstyle.smallText,
                  Pstyle.marginRight34
                )}
              >
                70
              </span>
            </p>

            <p className={classNames(Pstyle.semibold, Pstyle.mediumText)}>
              BP:
              <span
                className={classNames(
                  Pstyle.regular,
                  Pstyle.smallText,
                  Pstyle.marginRight34
                )}
              >
                120/80 mmHg
              </span>
            </p>

            <div
              className={classNames(
                Pstyle.row,
                Pstyle.borderTop,
                Pstyle.borderBottom,
                Pstyle.marginTop16
              )}
            >
              <p
                className={classNames(
                  Pstyle.bold,
                  Pstyle.primaryColor,
                  Pstyle.fontSize22
                )}
              >
                Symptoms
              </p>
              <p
                className={classNames(
                  Pstyle.bold,
                  Pstyle.primaryColor,
                  Pstyle.fontSize22
                )}
              >
                Clinical Findings
              </p>
            </div>

            <div className={Pstyle.row}>
              <p className={classNames(Pstyle.smallText)}>Headache, Fever</p>
              <p className={classNames(Pstyle.smallText, Pstyle.textLeft)}>
                High temperature, Body ache
              </p>
            </div>

            <div
              className={classNames(
                Pstyle.row,
                Pstyle.borderTop,
                Pstyle.borderBottom,
                Pstyle.marginTop16
              )}
            >
              <p
                className={classNames(
                  Pstyle.semibold,
                  Pstyle.primaryColor,
                  Pstyle.fontSize22
                )}
              >
                Laboratory Test
              </p>
            </div>

            {test.map((test, index) => (
              <div key={index}>
                <p className={classNames(commonstyles.fs14, style.mt16)}>
                  {test.testName}
                </p>
                {/* <Downloader link={test?.res} /> */}
              </div>
            ))}

            <div>
              {/* Header Row */}
              <div
                className={classNames(
                  Pstyle.row,
                  Pstyle.borderBottom,
                  Pstyle.alignItemsCenter
                )}
              >
                <p
                  className={classNames(
                    Pstyle.bold,
                    Pstyle.primaryColor,
                    Pstyle.fontSize22
                  )}
                >
                  Medicine Name
                </p>
                <p
                  className={classNames(
                    Pstyle.bold,
                    Pstyle.primaryColor,
                    Pstyle.textCenterPadding,
                    Pstyle.fontSize22
                  )}
                >
                  Dosage
                </p>
                <p
                  className={classNames(
                    Pstyle.bold,
                    Pstyle.primaryColor,
                    Pstyle.textCenterPadding,
                    Pstyle.fontSize22
                  )}
                >
                  Duration
                </p>
              </div>

              {/* Medicine List */}
              {medicine.map((med, index) => (
                <div
                  key={index}
                  className={classNames(
                    Pstyle.row,
                    Pstyle.borderBottom,
                    Pstyle.alignItemsCenter
                  )}
                >
                  <p className={classNames(Pstyle.smallText)}>
                    {med.medicineName}, {med.medicineBrand},{" "}
                    {med.medicineStrength}
                  </p>
                  <p
                    className={classNames(
                      Pstyle.smallText,
                      Pstyle.textCenterPadding
                    )}
                  >
                    {med.dosage}
                  </p>
                  <p
                    className={classNames(
                      Pstyle.smallText,
                      Pstyle.textCenterPadding
                    )}
                  >
                    {/* {med.duration} */}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className={Pstyle.footer}>
            <div className={classNames(commonstyles.flx)}>
              <button
                className={classNames(commonstyles.bgBlue, Pstyle.button)}
              >
                Download Prescription
              </button>
              <button
                className={classNames(commonstyles.btnOrange, Pstyle.button)}
              >
                Download Test Details
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
