import React from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import FilePicker from "shared/components/FilePickeInsurance";
import commonStyles from "shared/utils/common.module.css";
import { insuranceTravelPolicyDocuments } from "shared/utils";
import { PrimaryButton } from "shared/components";
import { setInsuranceTravelFamily } from "shared/redux";
interface Props {
  handleClickNext: any;
}
export default function FamilyPolicy(props: Partial<Props>) {
  const { insuranceTravelFamily } = useSelector(
    (state: any) => state.root.insurance
  );
  console.log("console", insuranceTravelFamily);
  const { handleClickNext } = props;
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      PolicyDocument: "",
    },
    validationSchema: Yup.object(insuranceTravelPolicyDocuments),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    dispatch(
      setInsuranceTravelFamily({
        ...insuranceTravelFamily,
        ...formik.values,
      })
    );
    handleClickNext();
  };
  const handleUrl = (url: any) => {
    formik.setFieldValue("PolicyDocument", url);
  };
  return (
    <div className={commonStyles.col3}>
      <form onSubmit={formik.handleSubmit}>
        <FilePicker setData={handleUrl} />
        {formik.touched.PolicyDocument && formik.errors.PolicyDocument ? (
          <div className={classNames(commonStyles.error)}>
            *{formik.errors.PolicyDocument}
          </div>
        ) : null}
        <div style={{ width: "210px", marginTop: "56px" }}>
          <PrimaryButton
            children={"Next"}
            colorType={"Insurance"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
