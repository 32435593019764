import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { insuranceGetAllIndividualINSURANCE } from "shared/services/Insurance";
import style from "./Myself.module.css";
import { CustomInput } from "shared/components";
import { IoArrowBack } from "react-icons/io5";
import File from "assets/images/filePicker.png";
import { MdDeleteOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { Avatar } from "@mui/material";

export default function MyselfDetail() {
  const navigate = useNavigate();
  const [insuranceData, setInsuranceData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchIndividualInsurances = () => {
    setLoading(true);
    insuranceGetAllIndividualINSURANCE()
      .then((res: any) => {
        console.log("response........", res);
        setInsuranceData(res?.data?.insurances[0]);
      })
      .catch((err: any) => {
        console.log("error........", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchIndividualInsurances();
  }, []);

  const handleGoBack = () => {
    navigate("/insurance/MySelfMian");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!insuranceData) {
    return <div>No data available</div>;
  }

  return (
    <div className={classNames(commonstyles.col12, commonstyles.colorBlue)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={commonstyles.outerContainer}>
          <div className={commonstyles.flxBetween}>
            <div className={commonstyles.flx}>
              <IoArrowBack className={style.back} onClick={handleGoBack} />
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.semiBold,
                  commonstyles.colorBlue,
                  style.mr56
                )}
              >
                Back
              </p>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Health / Myself / 75k - 2Lac
              </p>
            </div>
            {/* <div className={commonstyles.flx}>
              <FiEdit className={style.icons} />
              <MdDeleteOutline className={style.icons} />
            </div> */}
          </div>
          <div className={style.mt24}>
            <Avatar sx={{ height: "80px", width: "80px" }} src={insuranceData.packageLogo} />
            <p className={classNames(commonstyles.fs20, commonstyles.medium)}>
              {insuranceData.heading}
            </p>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt16,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Age criteria"
                value={`Start Age: ${insuranceData.ageCriteria.startAge}, End Age: ${insuranceData.ageCriteria.endAge}`}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Select Hospitalization Limit (PKR)"
                value={`Start Limit: ${insuranceData.hospitalizationLimit?.startLimit ?? "Not Available"}, End Limit: ${insuranceData.hospitalizationLimit?.endLimit ?? "Not Available"}`}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt8,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Hospitalization Per person"
                value={`Start Limit: ${insuranceData.hospitalizationLimit?.startLimit || "Not Available"}, End Limit: ${insuranceData.hospitalizationLimit?.endLimit || "Not Available"}`}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Daily Room & Board Limit"
                value={insuranceData.dailyRoomAndBoardLimit}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt8,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="ICU / CCU"
                value={insuranceData.icuCcuLimits}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Claim Payout Ratio"
                value={insuranceData.claimPayoutRatio}
              />
            </div>
          </div>
          <div className={style.mt24}>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Hospital
            </p>
            <div className={style.flexWrap}>
              {insuranceData.hospitals.map((hospital: string) => (
                <div className={style.cardss} key={hospital}>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold
                    )}
                  >
                    {hospital}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className={style.mt24}>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Laboratory
            </p>
            <div className={style.flexWrap}>
              {insuranceData.laboratories.map((laboratory: string) => (
                <div className={style.cardss} key={laboratory}>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold
                    )}
                  >
                    {laboratory}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <p
            className={classNames(
              commonstyles.fs20,
              style.mt24,
              commonstyles.medium
            )}
          >
            Medical Benefits
          </p>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt16,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="ICU / CCU"
                value={insuranceData.icuCcuLimits}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Additional Limits for Accidental Emergencies"
                value={insuranceData.accidentalEmergencyLimits}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt8,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Ambulance Service Coverage"
                value={insuranceData.ambulanceCoverage}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Coverage of Specialized Investigations"
                value={insuranceData.specializedInvestigationCoverage}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt8,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Waiting Period"
                value={insuranceData.waitingPeriod}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Maternity"
                value={insuranceData.maternity}
              />
            </div>
          </div>
          <div className={style.mt24}>
            <p className={classNames(commonstyles.fs20, commonstyles.medium)}>
              Policy Documents
            </p>
            <div
              className={classNames(
                style.mt16,
                commonstyles.col2,
                style.textcenter
              )}
            >
              <img src={File} className={style.Download} alt="Policy Document" />
              <p
                className={classNames(
                  commonstyles.fs14,
                  commonstyles.medium
                )}
              >
                {insuranceData.policyDocument}
              </p>
            </div>
          </div>
          <div className={style.mt24}>
            <p className={classNames(commonstyles.fs20, commonstyles.medium)}>
              Claim Process
            </p>
            <div
              className={classNames(
                style.mt16,
                commonstyles.col2,
                style.textcenter
              )}
            >
              <img src={File} className={style.Download} alt="Claim Process Document" />
              <p
                className={classNames(
                  commonstyles.fs14,
                  commonstyles.medium
                )}
              >
                {insuranceData.claimProcess}
              </p>
            </div>
          </div>
          <div className={commonstyles.mt56}>
            <p className={classNames(commonstyles.fs20, commonstyles.medium)}>
              More Features
            </p>
            <div className={classNames(commonstyles.col4, style.mt24)}>
              <CustomInput
                placeholder="Waiting Period"
                value={insuranceData.waitingPeriod}
              />
            </div>
            <div className={classNames(commonstyles.col12, style.mt8)}>
              <CustomInput
                placeholder="Waiting Period"
                value={insuranceData.waitingPeriod}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
