import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "../booking.module.css";
import { IoArrowBack } from "react-icons/io5";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Download from "assets/images/downloadicon.png";
import { travelBookingDetails } from "shared/services/TravelAgency";
import { LoadingModal } from "shared/components";
interface REQUEST {
  adult?: number;
  children?: number;
  infant?: number;
  class?: string;
  departure?: string;
  from?: string;
  to?: string;
  requestType?: string;
}

interface USERS {
  name?: string;
}
export default function TicketDetail() {
  const [request, setRequest] = useState<REQUEST>({});
  const [user, setUser] = useState<USERS>({});
  const [travelller, setTravelller] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();
  const handleGoToBack = () => {
    navigate("/travelAgency/Booking");
  };

  const fetchDetails = () => {
    const requestType = "flight";
    if (id) {
      setLoading(true);
      travelBookingDetails(requestType, id)
        .then((res: any) => {
          console.log("travelBookingDetails Done", res);
          setRequest(res?.data?.booking?.requestId);
          setUser(res?.data?.booking?.userId);
          setTravelller(res?.data?.booking?.travellers);

          // setImages(res?.data?.booking?.tourId?.images);
        })
        .catch((err: any) => {
          console.log("send error.nnnnn..", err?.response?.data);
        })
        .finally(() => {
          setLoading(false);
          console.log(".....");
        });
    } else {
      alert("send error");
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingModal showModal={loading} />
      ) : (
        <div className={classNames(commonstyles.col12, commonstyles.colorBlue)}>
          <SearchBar />
          <div className={commonstyles.mr87}>
            <div className={commonstyles.outerContainer}>
              <div className={commonstyles.flx}>
                <IoArrowBack className={style.back} onClick={handleGoToBack} />
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                >
                  Ticket Booked Details
                </p>
              </div>
              <div className={classNames(commonstyles.flxBetween, style.mt24)}>
                <p
                  className={classNames(
                    commonstyles.fs18,
                    commonstyles.semiBold,
                    commonstyles.colorBlue
                  )}
                >
                  User
                </p>
                <p
                  className={classNames(
                    commonstyles.fs18,
                    commonstyles.semiBold,
                    commonstyles.colorBlue
                  )}
                >
                  {request?.requestType}
                </p>
              </div>
              <div className={style.DetailCard}>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.regular,
                    style.colorGray
                  )}
                >
                  {user?.name}
                </p>

                <div
                  className={classNames(commonstyles.flxBetween, style.mt24)}
                >
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.regular,
                        style.colorGray
                      )}
                    >
                      From
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.regular,
                        commonstyles.colorBlue
                      )}
                    >
                      {request?.from}
                    </p>
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.regular,
                        style.colorGray
                      )}
                    >
                      To
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.regular,
                        commonstyles.colorBlue
                      )}
                    >
                      {request?.to}
                    </p>
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.regular,
                        style.colorGray
                      )}
                    >
                      Departure
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.regular,
                        commonstyles.colorBlue
                      )}
                    >
                      {request?.departure
                        ? new Date(request.departure).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "__"}{" "}
                      {request &&
                        request.departure &&
                        new Date(request.departure).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          }
                        )}
                    </p>
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.regular,
                        style.colorGray
                      )}
                    >
                      Class
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.regular,
                        commonstyles.colorBlue
                      )}
                    >
                      {request?.class}
                    </p>
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.regular,
                        style.colorGray
                      )}
                    >
                      Traveler
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.regular,
                        commonstyles.colorBlue
                      )}
                    >
                      adult {request?.adult}, children {request?.children},
                      infant {request?.infant}
                    </p>
                  </div>
                </div>
              </div>{" "}
              {travelller.map((data: any) => (
                <>
                  <p
                    className={classNames(
                      commonstyles.fs18,
                      commonstyles.semiBold,
                      style.mt24,
                      commonstyles.colorBlue
                    )}
                  >
                    Travel
                  </p>
                  <div
                    className={classNames(
                      commonstyles.flxBetween,
                      commonstyles.col3
                    )}
                  >
                    <div className={style.mt24}>
                      <p
                        className={classNames(
                          commonstyles.fs14,
                          commonstyles.regular,
                          style.colorGray
                        )}
                      >
                        Name
                      </p>
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.regular,
                          commonstyles.colorBlue
                        )}
                      >
                        {data?.name}
                      </p>
                    </div>
                    <div className={style.mt24}>
                      <p
                        className={classNames(
                          commonstyles.fs14,
                          commonstyles.regular,
                          style.colorGray
                        )}
                      >
                        Passport No.
                      </p>
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.regular,
                          commonstyles.colorBlue
                        )}
                      >
                        {data?.passportNo}
                      </p>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      commonstyles.flxBetween,
                      commonstyles.col6
                    )}
                  >
                    <div className={style.mt24}>
                      <p
                        className={classNames(
                          commonstyles.fs14,
                          commonstyles.regular,
                          style.colorGray
                        )}
                      >
                        Passport File
                      </p>
                      <div className={style.outerpicker}>
                        <p className={commonstyles.fs12}>File name.png</p>
                        <a href={data?.passportFile} download target="_blank">
                          <img
                            className={style.imgwidth}
                            src={Download}
                            alt="Passport Image"
                          />
                        </a>
                      </div>
                    </div>
                    <div className={style.mt24}>
                      <p
                        className={classNames(
                          commonstyles.fs14,
                          commonstyles.regular,
                          style.colorGray
                        )}
                      >
                        Visa File
                      </p>
                      <div className={style.outerpicker}>
                        <p className={commonstyles.fs12}>File name.png</p>
                        <a href={data?.visaFile} download target="_blank">
                          <img
                            className={style.imgwidth}
                            src={Download}
                            alt="Passport Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
