import React from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "./HotalPayment.module.css";
import { Vendor_Payments } from "shared/components";
import { useSelector } from "react-redux";
const HotalPayment = () => {
  const { hotel } = useSelector((state: any) => state.root.hotel);
  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <Vendor_Payments type={"Hotel"} id={hotel?._id} />
    </div>
  );
};

export default HotalPayment;
