import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import commonstyle from "shared/utils/common.module.css";
import { Avatar } from "@mui/material";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { AiOutlineLogout } from "react-icons/ai";

import {
  labLogout,
  pharmaceutical_Logout,
  pharmacyLogout,
} from "shared/services";
import { useDispatch, useSelector } from "react-redux";
import {
  setHospital,
  setIsLoggedIn,
  setLab,
  setPharmacy,
  setToken,
  setDonation,
  setTravelAgency,
  setRentCar,
  setHotel,
  setAmbulance,
  setParamedic,
  setInsurance,
  setRenderFlag,
  setrenderpharmacyOrderFlag,
  setPharmacyRenderFlag,
  setrenderpharmacyMedicineFlag,
  setrenderLabOrderFlag,
  setrenderLabdashboardFlag,
  setAmbulanceDashboardrenderFlag,
  setAmbulanceRequestFlag,
  setAmbulanceOnrouteRenderFlag,
  setAmbulanceAmbulancerenderFlag,
  setPeramedicPatientHistoryFlag,
  setPeramedicAppointmentFlag,
  setPeramedicRequestsFlag,
  setPeramedicdashboardrenderFlag,
  setHospitaldashboardrenderFlag,
  setHosPatientHistoryRenderFlag,
  setHosAppointmentRenderFlag,
  setCriterionRenderFlag,
  setDepartment,
  set_User,
  setDoctorPatientHistory,
  setDoctorPatientLength,
} from "shared/redux";
import { useNavigate } from "react-router-dom";
import { doctorLogout } from "shared/services/DoctorService";
import { hospitalLogout } from "shared/services/HospitalService";
import { donationLogout } from "shared/services/Donation";
import { travelAgencyLogout } from "shared/services/TravelAgency";
import { rentCarLogout } from "shared/services/RentaCar";
import { hotelLogout } from "shared/services/Hotel";
import { ambulanceLogout } from "shared/services/Ambulance";
import { paraLogout } from "shared/services/Paramedic";
import { insuranceLogout } from "shared/services/Insurance";
import { MdLogout } from "react-icons/md";
import classNames from "classnames";
import LinearProgressWithLabel from "../LinearProgressWithLabel";
import styles from "./menu.module.css";

interface Props {}

export default function Menu(props: Partial<Props>) {
  const [open, setOpen] = React.useState(false);
  const handleProfile = () => {
    console.log("Navigating to Doctor Profile");
    navigate(`/${systemType}/doctorProfile`);
  };
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { systemType, user } = useSelector((state: any) => state.root.common);
  const { lab } = useSelector((state: any) => state.root.lab);
  const { pharmacy } = useSelector((state: any) => state.root.pharmacy);
  const { hospital } = useSelector((state: any) => state.root.hospital);
  const { donation } = useSelector((state: any) => state.root.donation);
  const { travelAgency } = useSelector((state: any) => state.root.travelagency);
  const { rentCar } = useSelector((state: any) => state.root.rentcar);
  const { hotel } = useSelector((state: any) => state.root.hotel);
  const { ambulance } = useSelector((state: any) => state.root.ambulance);
  const { paramedic } = useSelector((state: any) => state.root.paramedic);
  const { insurance } = useSelector((state: any) => state.root.insurance);

  //  Need to set profile Image,Name, Qualification for all doctors

  let logo =
    systemType === "lab"
      ? lab?.logo
      : systemType === "pharmacy"
      ? pharmacy?.pharmLogo
      : systemType === "doctor"
      ? user?.doctorImage
      : systemType === "hospital"
      ? hospital?.hospitalLogo
      : systemType === "donation"
      ? donation?.logo
      : systemType === "travelagency"
      ? travelAgency?.logo
      : systemType === "rentcar"
      ? rentCar?.logo
      : systemType === "hotel"
      ? hotel?.logo
      : systemType === "ambulance"
      ? ambulance?.logo
      : systemType === "physiotherapist"
      ? user?.doctorImage
      : systemType === "nutritionist"
      ? user?.doctorImage
      : systemType === "paramedic"
      ? paramedic?.logo
      : systemType === "psychologist"
      ? user?.doctorImage
      : systemType === "insurance"
      ? insurance?.logo
      : "";

  let name =
    systemType === "laboratory"
      ? lab?.name
      : systemType === "pharmacy"
      ? pharmacy?.name
      : systemType === "doctor"
      ? user?.name
      : systemType === "hospital"
      ? hospital?.name
      : systemType === "donation"
      ? donation?.name
      : systemType === "travelagency"
      ? travelAgency?.name
      : systemType === "rentacar"
      ? rentCar?.name
      : systemType === "hotel"
      ? hotel?.name
      : systemType === "ambulance"
      ? ambulance?.name
      : systemType === "physiotherapist"
      ? user?.name
      : systemType === "nutritionist"
      ? user?.name
      : systemType === "paramedic"
      ? paramedic?.name
      : systemType === "psychologist"
      ? user?.name
      : systemType === "insurance"
      ? insurance?.name
      : systemType === "pharmaceutical"
      ? user?.name
      : "Unknown";

  let qualifications = systemType === "doctor" ? user?.qualifications : "";

  let profilePercentage =
    systemType === "lab"
      ? lab?.profilePercentage
      : systemType === "pharmacy"
      ? pharmacy?.profilePercentage
      : systemType === "doctor"
      ? user?.profilePercentage
      : systemType === "hospital"
      ? hospital?.profilePercentage
      : systemType === "donation"
      ? donation?.profilePercentage
      : systemType === "travelagency"
      ? travelAgency?.profilePercentage
      : systemType === "rentcar"
      ? rentCar?.profilePercentage
      : systemType === "hotel"
      ? hotel?.profilePercentage
      : systemType === "ambulance"
      ? ambulance?.profilePercentage
      : systemType === "physiotherapist"
      ? user?.profilePercentage
      : systemType === "nutritionist"
      ? user?.profilePercentage
      : systemType === "paramedic"
      ? paramedic?.profilePercentage
      : systemType === "psychologist"
      ? user?.profilePercentage
      : systemType === "insurance";

  const dispatch = useDispatch();

  const handleToggle = () => {
    setOpen((prevOpen: any) => !prevOpen);
  };

  const navigate = useNavigate(); // Declare once

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleDataDispatch = (returnUrl: any) => {
    dispatch(setToken(null));
    dispatch(setIsLoggedIn(false));
    dispatch(set_User({}));
    dispatch(setDoctorPatientHistory([]));
    dispatch(setDoctorPatientLength(0));
    navigate(returnUrl);
  };

  const handleLabLogout = () => {
    labLogout()
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          dispatch(setToken(null));
          dispatch(setIsLoggedIn(false));
          dispatch(setLab({}));
          dispatch(setRenderFlag(true));
          dispatch(setrenderLabOrderFlag(true));
          dispatch(setrenderLabdashboardFlag(true));
          dispatch(setRenderFlag(true));
          dispatch(setrenderLabOrderFlag(true));
          navigate("/laboratory/login");
        }
      })
      .catch((err: any) => {})
      .finally();
  };

  const handlePharmacyLogout = () => {
    pharmacyLogout()
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          dispatch(setToken(null));
          // dispatch(setSystemType("lab"));
          dispatch(setIsLoggedIn(false));
          dispatch(setPharmacy({}));
          dispatch(setPharmacyRenderFlag(true));
          dispatch(setrenderpharmacyOrderFlag(true));
          dispatch(setrenderpharmacyMedicineFlag(true));
          navigate("/pharmacy/login");
        }
      })
      .catch((err: any) => {})
      .finally();
  };
  const handlePharmaceuticalLogout = (returnUrl: any) => {
    pharmaceutical_Logout()
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          handleDataDispatch(returnUrl);
        }
      })
      .catch((err: any) => {})
      .finally();
  };
  const handleDoctorLogout = (returnUrl: any) => {
    doctorLogout()
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          handleDataDispatch(returnUrl);
        }
      })
      .catch((err: any) => {})
      .finally();
  };
  const handleHospitalLogout = () => {
    hospitalLogout()
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          handleDataDispatch(`/${systemType}/login`);
        }
      })
      .catch((err: any) => {})
      .finally();
  };
  const handleDonationLogout = () => {
    donationLogout()
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          dispatch(setToken(null));
          dispatch(setIsLoggedIn(false));
          dispatch(setCriterionRenderFlag(true));
          dispatch(setDonation({}));
          navigate("/donation/login");
        }
      })
      .catch((err: any) => {})
      .finally();
  };

  const handleTravelAgencyLogout = () => {
    travelAgencyLogout()
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          dispatch(setToken(null));
          dispatch(setIsLoggedIn(false));
          dispatch(setTravelAgency({}));
          navigate("/traveltourism/travelAgency/login");
        }
      })
      .catch((err: any) => {})
      .finally();
  };

  const handleRentCarLogout = () => {
    rentCarLogout()
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          dispatch(setToken(null));
          dispatch(setIsLoggedIn(false));
          dispatch(setRentCar({}));
          navigate("/traveltourism/rentAcar/login");
        }
      })
      .catch((err: any) => {})
      .finally();
  };

  const handleHotelLogout = () => {
    hotelLogout()
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          dispatch(setToken(null));
          dispatch(setIsLoggedIn(false));
          dispatch(setHotel({}));
          navigate("/traveltourism/hotel/login");
        }
      })
      .catch((err: any) => {})
      .finally();
  };

  const handleAmbulanceLogout = () => {
    ambulanceLogout()
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          dispatch(setToken(null));
          dispatch(setAmbulanceDashboardrenderFlag(true));
          dispatch(setAmbulanceOnrouteRenderFlag(true));
          dispatch(setAmbulanceRequestFlag(true));
          dispatch(setAmbulanceAmbulancerenderFlag(true));
          dispatch(setIsLoggedIn(false));
          dispatch(setAmbulance({}));
          navigate("/homeservices/ambulanceservices/login");
        }
      })
      .catch((err: any) => {})
      .finally();
  };

  const handleParamedicStaffLogout = () => {
    paraLogout()
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          dispatch(setToken(null));
          dispatch(setIsLoggedIn(false));
          dispatch(setParamedic({}));
          dispatch(setPeramedicPatientHistoryFlag(true));
          dispatch(setPeramedicAppointmentFlag(true));
          dispatch(setPeramedicRequestsFlag(true));
          dispatch(setPeramedicdashboardrenderFlag(true));
          navigate("/homeservices/paramedicstaff/login");
        }
      })
      .catch((err: any) => {})
      .finally();
  };

  const handleInsuranceLogout = () => {
    insuranceLogout()
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          dispatch(setToken(null));
          // dispatch(setSystemType("lab"));
          dispatch(setIsLoggedIn(false));
          dispatch(setInsurance({}));
          navigate("/insurance/login");
        }
      })
      .catch((err: any) => {})
      .finally();
  };
  const onLogoutClick = (event: Event | React.SyntheticEvent) => {
    if (systemType === "laboratory") {
      handleLabLogout();
    }
    if (systemType === "pharmacy") {
      handlePharmacyLogout();
    }

    if (systemType === "pharmaceutical") {
      handlePharmaceuticalLogout(`/${systemType}/login`);
    }

    if (
      systemType === "doctor" ||
      systemType === "physiotherapist" ||
      systemType === "nutritionist" ||
      systemType === "psychologist"
    ) {
      handleDoctorLogout(`/${systemType}/login`);
    }

    if (systemType === "hospital") {
      handleHospitalLogout();
    }

    if (systemType === "donation") {
      handleDonationLogout();
    }

    if (systemType === "travelagency") {
      handleTravelAgencyLogout();
    }

    if (systemType === "rentacar") {
      handleRentCarLogout();
    }
    if (systemType === "hotel") {
      handleHotelLogout();
    }
    if (systemType === "ambulance") {
      handleAmbulanceLogout();
    }
    if (systemType === "paramedic") {
      handleParamedicStaffLogout();
    }

    if (systemType === "insurance") {
      handleInsuranceLogout();
    }
    handleClose(event);
  };

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Avatar
            alt="Remy Sharp"
            src={logo}
            onClick={() => console.log("jdshhjsh....")}
          />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{
            "& .MuiPaper-root": {
              width: "400px",
              borderRadius: "8px",
              // color: "#00276d",
              position: "absolute",
              inset: "0px auto auto -345px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              paddingTop: "20px",
              paddingBottom: "40px",
            },
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    {/* Always show section */}
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "#f5f5f5",
                        padding: "16px",
                      }}
                    >
                      <Avatar
                        style={{
                          marginRight: "24px",
                          height: "64px",
                          width: "64px",
                        }}
                        src={logo}
                      />
                      <div>
                        <p
                          className={classNames(
                            commonstyle.semiBold,
                            commonstyle.fs24
                          )}
                        >
                          {name}
                        </p>
                        <p
                          style={{ color: "gray" }}
                          className={classNames(
                            commonstyle.medium,
                            commonstyle.fs14
                          )}
                        >
                          {qualifications}
                        </p>
                      </div>
                    </div>

                    {/* on doctor show*/}
                    {(systemType === "doctor" ||
                      systemType === "physiotherapist" ||
                      systemType === "nutritionist" ||
                      systemType === "psychologist") && (
                      <>
                        <div style={{ padding: "20px" }}>
                          <div>
                            <LinearProgressWithLabel
                              value={profilePercentage || 0}
                            />
                          </div>
                          <p
                            style={{ color: "#FF7631", marginTop: 4 }}
                            className={classNames(
                              commonstyle.regular,
                              commonstyle.fs12
                            )}
                          >
                            Complete Your Profile for better business
                          </p>
                        </div>

                        <div
                          style={{
                            border: "1px solid",
                            borderColor: "#f5f5f5",
                          }}
                        />

                        <div
                          style={{ paddingTop: "20px", paddingBottom: "20px" }}
                        >
                          <div
                            className={classNames(
                              commonstyle.flx,
                              styles.menuItem
                            )}
                            onClick={handleProfile}
                          >
                            <HiOutlineUserCircle
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "16px",
                              }}
                            />
                            <p className={commonstyle.fs18}>Your Profile</p>
                          </div>
                        </div>

                        <div
                          style={{
                            border: "1px solid",
                            borderColor: "#f5f5f5",
                          }}
                        />
                      </>
                    )}

                    {/* Always display the Logout section */}
                    <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                      <div
                        className={classNames(commonstyle.flx, styles.menuItem)}
                        onClick={onLogoutClick}
                      >
                        <AiOutlineLogout
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "16px",
                          }}
                        />
                        <p className={commonstyle.fs18}>Logout</p>
                      </div>
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
