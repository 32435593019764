import React, { useEffect } from "react";
import Footerr from "../Footer";
import styles from "./PrivacyPolicies.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { IoIosArrowForward } from "react-icons/io";
import NavBarr from "../NavBarr";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
import PrivactPolicy2 from "shared/components/PrivacyPolicy/PrivactPolicy";
export default function PrivactPolicys() {
  useEffect(() => {
    document.title = "MediTour Global | Privacy&Policies";
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <div className={styles.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            styles.mianheading
          )}
        >
          Privacy Policy
        </p>

        <div className={styles.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              styles.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, styles.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              styles.mianheading
            )}
          >
            Privacy Policy
          </p>
        </div>
      </div>
      <div className={styles.container}>
        <PrivactPolicy2 />
      </div>

      <Footerr />
    </div>
  );
}
