import InsuranceDashboard from "pages/Insurance/InsuranceDashBoard";
import InsuranceInsurances from "pages/Insurance/InsuranceInsurances";
import HeathMain from "pages/Insurance/InsuranceInsurances/Health";
import HealthMySelfFlow from "pages/Insurance/InsuranceInsurances/Health/Healthflow/HealthMyself";
import HealthFamilyFlow from "pages/Insurance/InsuranceInsurances/Health/Healthflow/HealthFamily";
import HealthParentsFlow from "pages/Insurance/InsuranceInsurances/Health/Healthflow/HealthParents";
import TravelMian from "pages/Insurance/InsuranceInsurances/Travel&Tour";
import TravelflowMain from "pages/Insurance/InsuranceInsurances/Travel&Tour/Travelflow";
import FamilyComponent from "pages/Insurance/InsuranceInsurances/Travel&Tour/Travelflow/FamilyComponent";
import IndividualComponent from "pages/Insurance/InsuranceInsurances/Travel&Tour/Travelflow/IndividulaComponet";
import InsurancePayment from "pages/Insurance/InsurancePayment";
import PaymentDescritionInsurance from "pages/Insurance/InsurancePayment/PaymentDescritionInsurance";
import InsuranceRequest from "pages/Insurance/InsuranceRequest";
import InsuranceSetting from "pages/Insurance/InsuranceSetting";
import Insuredperson from "pages/Insurance/Insuredperson";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SideBar } from "shared/components";
import Chat from "shared/components/Chat";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
import { InsuranceMenu } from "shared/utils";
import InsuredpersonDetail from "pages/Insurance/Insuredperson/InsuredpersonDetail";
import MySeflMain from "pages/Insurance/InsuranceInsurances/Health/Healthflow/HealthMyself/MySeflMain";
import MyselfDetail from "pages/Insurance/InsuranceInsurances/Health/Healthflow/HealthMyself/myselfDetail";
import MyFamilyMain from "pages/Insurance/InsuranceInsurances/Health/Healthflow/HealthFamily/MyFamilyMain";
import MyFamilyDetail from "pages/Insurance/InsuranceInsurances/Health/Healthflow/HealthFamily/MyFamilyDetail";
import ParentsMain from "pages/Insurance/InsuranceInsurances/Health/Healthflow/HealthParents/ParentsMain";
import ParentsDetail from "pages/Insurance/InsuranceInsurances/Health/Healthflow/HealthParents/ParentsDetail";
import SingleMain from "pages/Insurance/InsuranceInsurances/Travel&Tour/Travelflow/IndividulaComponet/SingleMain";
import TravelFamilyMain from "pages/Insurance/InsuranceInsurances/Travel&Tour/Travelflow/FamilyComponent/TravelFamilyMain";
import RequestDetail from "pages/Insurance/InsuranceRequest/RequestDetail";
import FamilyBasicInfoCovering from "pages/Insurance/InsuranceInsurances/Travel&Tour/Travelflow/FamilyComponent/FamilyBasicInfoLimits";
const InsuranceMainRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/insurance/dashboard");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: "0px",
          height: "100vh",
        }}
      >
        <SideBar menuItem={InsuranceMenu} />
      </div>
      <Routes>
        <Route path="insurance/dashboard" element={<InsuranceDashboard />} />
        <Route path="insurance/Category" element={<InsuranceInsurances />} />
        <Route
          path="insuredPerson/Detail/:id"
          element={<InsuredpersonDetail />}
        />
        <Route path="insurance/request" element={<InsuranceRequest />} />
        <Route
          path="insurance/request/Detail/:id"
          element={<RequestDetail />}
        />
        <Route path="insurance/insuredperson" element={<Insuredperson />} />
        <Route path="insurance/payments" element={<InsurancePayment />} />
        <Route
          path="insurance/paymentDetails"
          element={<PaymentDescritionInsurance />}
        />
        <Route path="insurance/setting" element={<InsuranceSetting />} />
        <Route path="insurance/Privacy" element={<PrivacyPolicy />} />
        <Route path="chat/message" element={<Chat />} />
        <Route path="/insurance/Health" element={<HeathMain />} />
        <Route path="/insurance/Travel" element={<TravelMian />} />
        <Route path="/insurance/MySelf" element={<HealthMySelfFlow />} />
        <Route path="/insurance/MySelfMian/*" element={<MySeflMain />} />
        <Route path="/insurance/MySelf/Detail" element={<MyselfDetail />} />
        <Route path="/insurance/Family" element={<HealthFamilyFlow />} />
        <Route path="/insurance/MyFamilyMian/*" element={<MyFamilyMain />} />
        <Route path="/insurance/MyFamily/Detail" element={<MyFamilyDetail />} />
        <Route path="/insurance/Parents" element={<HealthParentsFlow />} />
        <Route path="/insurance/ParentsMian/*" element={<ParentsMain />} />
        <Route path="/insurance/Parents/Detail" element={<ParentsDetail />} />
        <Route
          path="/insurance/TravelingWith/:value"
          element={<TravelflowMain />}
        />

        <Route
          path="/insurance/Travel/Single/:value"
          element={<SingleMain />}
        />
        <Route
          path="/insurance/TravelIndividual/:value"
          element={<IndividualComponent />}
        />
        <Route
          path="/insurance/TravelFamily/:value"
          element={<FamilyComponent />}
        />
        <Route
          path="/insurance/Travel/Family/:value"
          element={<TravelFamilyMain />}
        />
        <Route
          path="/insurance/Travel/Family/FamilyBasicInfoCovering"
          element={<FamilyBasicInfoCovering />}
        />
      </Routes>
    </div>
  );
};
export default InsuranceMainRoutes;
