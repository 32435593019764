import classNames from "classnames";
import React, { useState, useEffect } from "react";
import style from "./ParamedicDashboard.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import User from "assets/images/Userr2.jpeg";
import Arrow from "assets/images/arrow.png";
import Arrow2 from "assets/images/arrow2.png";
import Vector from "assets/images/doctoVecttors.png";
import { useDispatch, useSelector } from "react-redux";
import Doctors from "assets/images/doctorss.png";
import { Avatar } from "@mui/material";
import DashboardTable from "shared/components/MediacalTables/DashboradTable/index";
import CommonDoctorsChart from "shared/components/DoctorsCommomChart";
import {
  paraGRAPH,
  paraGraphDETAILSUpperPortion,
} from "shared/services/Paramedic";
import { LoadingModal } from "shared/components";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { TbRefresh } from "react-icons/tb";
import {
  setPeramedicGraphDetails,
  setPeramedicdashboardrenderFlag,
  setPeramedicupcomingAppointment,
} from "shared/redux";
const data = [
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  // Add more data as needed
];
function ParamedicDashboard() {
  const dispatch = useDispatch();
  const [selectedDur, setSelectedDur] = useState("today");
  const {
    paramedic,
    peramedicdayArray,
    peramedicdayArray2,
    peramedicupcomingAppointment,
    peramedicGraphDetails,
    peramedicdashboardrenderFlag,
  } = useSelector((state: any) => state.root.paramedic);
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [loading, setLoading] = useState(false);

  const parsedAppointmentPercentageChange = parseFloat(
    peramedicGraphDetails.appointmentPercentageChange?.replace("%", "")
  );
  const parsedPatientPercentageChange = parseFloat(
    peramedicGraphDetails.patientPercentageChange?.replace("%", "")
  );

  const parsedWaitingPercentageChange = parseFloat(
    peramedicGraphDetails.waitingPercentageChange?.replace("%", "")
  );

  const parsedCuredPercentageChange = parseFloat(
    peramedicGraphDetails.curedPercentageChange?.replace("%", "")
  );

  peramedicdayArray.forEach(
    (item: { day: string; appointmentCount: number }, index: number) => {
      data[index + 1].name = item.day;
      data[index + 1].value1 = item.appointmentCount;
    }
  );

  peramedicdayArray2.forEach(
    (item: { day: string; appointmentCount: number }, index: number) => {
      data[index + 1].name = item.day;
      data[index + 1].value2 = item.appointmentCount;
    }
  );
  const handleRotate = () => {
    setRotation(rotation - rotationIncrement);
    GraphDetails(selectedDur);
    GraphOnly();
  };
  const handleSelectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedDur(event.target.value);
    GraphDetails(selectedDur);
  };

  const GraphDetails = (dur: string) => {
    console.log("function oprate");
    setLoading(true);
    paraGraphDETAILSUpperPortion(dur)
      .then((res: any) => {
        if (res?.data) {
          dispatch(
            setPeramedicGraphDetails({
              todayAppointCount: res.data.appointmentCount,
              todayPatientCount: res.data.patientCount,
              appointmentPercentageChange: res.data.appointmentPercentageChange,
              patientPercentageChange: res.data.patientPercentageChange,
              waitingPatients: res.data.waitingPatients,
              waitingPercentageChange: res.data.waitingPercentageChange,
              curedPatientCount: res.data.curedPatientCount,
              curedPercentageChange: res.data.curedPercentageChange,
              doctorName: res.data.doctorName,
            })
          );
          dispatch(
            setPeramedicupcomingAppointment({
              _id: res.data.upcomingAppointment._id,
              doctorId: res.data.upcomingAppointment.doctorId,
              patientId: res.data.upcomingAppointment.patientId,
              date: res.data.upcomingAppointment.date,
              createdAt: res.data.upcomingAppointment.createdAt,
              status: res.data.upcomingAppointment.status,
              appointmentType: res.data.upcomingAppointment.appointmentType,
            })
          );
        }
      })
      .catch((err: any) => {
        console.log("API Error:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  interface WeekDataItem {
    date: string;
    appointmentCount: number;
  }
  const GraphOnly = () => {
    setLoading(true);

    paraGRAPH()
      .then((res: any) => {
        if (res?.status === 200) {
          // console.log(">..........", res);
          const currentWeekData: WeekDataItem[] = res.data.currentWeekData;
          const previousWeekData = res.data.previousWeekData;
          // Use map to convert date to day in currentWeekData
          const dayArray = currentWeekData.map((item: WeekDataItem) => {
            const dateObject = new Date(item.date);
            const dayOfWeek = dateObject.toLocaleDateString("en-US", {
              weekday: "long",
            });
            return { day: dayOfWeek, appointmentCount: item.appointmentCount };
          });
          let popped = dayArray.pop();
          // Set values in the data array based on dayArray
          dayArray.forEach(
            (
              item: { day: string; appointmentCount: number },
              index: number
            ) => {
              data[index + 1].name = item.day;
              data[index + 1].value1 = item.appointmentCount;
            }
          );
          // console.log("Modified Data Array", data);

          const dayArray2 = previousWeekData.map((item: WeekDataItem) => {
            const dateObject = new Date(item.date);
            const dayOfWeek = dateObject.toLocaleDateString("en-US", {
              weekday: "long",
            });
            return { day: dayOfWeek, appointmentCount: item.appointmentCount };
          });
          dayArray2.forEach(
            (
              item: { day: string; appointmentCount: number },
              index: number
            ) => {
              data[index + 1].name = item.day;
              data[index + 1].value2 = item.appointmentCount;
            }
          );
          // console.log("Modified Data Array", data);
        }
      })
      .catch((err: any) => {
        console.log("API Error:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("useEffect fired with selectedDur:", selectedDur);
    if (peramedicdashboardrenderFlag) {
      setLoading(true);
      GraphDetails(selectedDur);
      GraphOnly();
      dispatch(setPeramedicdashboardrenderFlag(false));
    }
  }, [selectedDur]);

  return (
    <>
      {loading ? (
        <LoadingModal showModal={loading} />
      ) : (
        <div className={classNames(commonstyles.col12)}>
          <SearchBar />
          <div className={commonstyles.mr87}>
            <div className={commonstyles.flx}>
              <select
                className={style.selections}
                onChange={handleSelectionChange}
                value={selectedDur}
              >
                <option value="today">Today</option>
                <option value="week">Week</option>
              </select>

              <div className={style.outerRefresh}>
                <TbRefresh
                  className={style.RefreshIcon}
                  style={{ transform: `rotate(${rotation}deg)` }}
                  onClick={handleRotate}
                />
              </div>
            </div>
            <div className={classNames(commonstyles.flx)}>
              <div className={style.Cardaa}>
                <div className={classNames(style.flxBetween)}>
                  <div className={classNames(commonstyles.col7)}>
                    <p
                      className={classNames(
                        commonstyles.fs32,
                        commonstyles.semiBold
                      )}
                      style={{ textTransform: "capitalize" }}
                    >
                      Hello {peramedicGraphDetails?.doctorName}
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.medium,
                        style.mt8
                      )}
                    >
                      Here your important notes & task please check your
                      Appointments
                    </p>
                  </div>
                  <div>
                    <img src={Vector} className={style.vecttors} />
                  </div>
                </div>
              </div>
              <div className={style.Cardbb}>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.semiBold
                  )}
                >
                  <div className={classNames(commonstyles.col10)}>
                    <p
                      className={classNames(
                        commonstyles.fs24,
                        commonstyles.mb16
                      )}
                    >
                      Upcoming Appointment
                    </p>
                    <div className={classNames(commonstyles.flx)}>
                      <div className={classNames(commonstyles.col2)}>
                        <Avatar
                          sx={{ width: "50px", height: "50px" }}
                          src={
                            peramedicupcomingAppointment.patientId?.userImage
                          }
                        />
                      </div>
                      <div className={classNames(style.flxcolumn)}>
                        <p
                          className={classNames(
                            commonstyles.fs16,
                            commonstyles.semiBold
                          )}
                          style={{ textTransform: "capitalize" }}
                        >
                          {peramedicupcomingAppointment.patientId?.name}
                        </p>
                        <p
                          className={classNames(
                            commonstyles.fs12,
                            commonstyles.medium,
                            commonstyles.col8,
                            style.mt8
                          )}
                          style={{ textTransform: "capitalize" }}
                        >
                          {peramedicupcomingAppointment?.appointmentType}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={classNames(commonstyles.col2)}>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.mb16
                      )}
                    >
                      Today
                    </p>
                    <p>
                      {new Date(
                        peramedicupcomingAppointment?.createdAt
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={classNames(commonstyles.flxBetween, style.mt24)}>
                <div className={classNames(style.mianCards)}>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold,
                      commonstyles.mb8
                    )}
                  >
                    Total Patient
                  </p>
                  <div className={classNames(commonstyles.flx)}>
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs32,
                          commonstyles.semiBold
                        )}
                      >
                        {peramedicGraphDetails?.patientCount
                          ? peramedicGraphDetails.patientCount
                          : 0}
                      </p>
                    </div>
                    <div>
                      {Number(parsedPatientPercentageChange) > 0 ? (
                        <div className={style.iconsOuterGreen}>
                          <FaArrowUp className={classNames(style.arrowUp)} />
                        </div>
                      ) : (
                        <div className={style.iconsOuterOrange}>
                          <FaArrowDown
                            className={classNames(style.arrowdown)}
                          />
                        </div>
                      )}
                    </div>{" "}
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs18,
                          commonstyles.semiBold,
                          peramedicGraphDetails?.patientPercentageChange < 0
                            ? style.colorOrange
                            : style.colorGreen
                        )}
                      >
                        {peramedicGraphDetails?.patientPercentageChange}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classNames(style.mianCards)}>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold,
                      commonstyles.mb8
                    )}
                  >
                    Total Appointments
                  </p>
                  <div className={classNames(commonstyles.flx)}>
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs32,
                          commonstyles.semiBold
                        )}
                      >
                        {peramedicGraphDetails?.appointmentCount
                          ? peramedicGraphDetails.appointmentCount
                          : 0}
                      </p>
                    </div>{" "}
                    <div>
                      {Number(parsedAppointmentPercentageChange) > 0 ? (
                        <div className={style.iconsOuterGreen}>
                          <FaArrowUp className={classNames(style.arrowUp)} />
                        </div>
                      ) : (
                        <div className={style.iconsOuterOrange}>
                          <FaArrowDown
                            className={classNames(style.arrowdown)}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs18,
                          commonstyles.semiBold,
                          peramedicGraphDetails?.appointmentPercentageChange < 0
                            ? style.colorOrange
                            : style.colorGreen
                        )}
                      >
                        {peramedicGraphDetails?.appointmentPercentageChange}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classNames(style.mianCards)}>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold,
                      commonstyles.mb8
                    )}
                  >
                    Waiting Patients
                  </p>
                  <div className={classNames(commonstyles.flx)}>
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs32,
                          commonstyles.semiBold
                        )}
                      >
                        {peramedicGraphDetails?.waitingPatients
                          ? peramedicGraphDetails.waitingPatients
                          : 0}
                      </p>
                    </div>
                    <div>
                      <div>
                        {Number(parsedWaitingPercentageChange) > 0 ? (
                          <div className={style.iconsOuterGreen}>
                            <FaArrowUp className={classNames(style.arrowUp)} />
                          </div>
                        ) : (
                          <div className={style.iconsOuterOrange}>
                            <FaArrowDown
                              className={classNames(style.arrowdown)}
                            />
                          </div>
                        )}
                      </div>{" "}
                    </div>{" "}
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs18,
                          commonstyles.semiBold,
                          peramedicGraphDetails?.waitingPercentageChange < 0
                            ? style.colorOrange
                            : style.colorGreen
                        )}
                      >
                        {peramedicGraphDetails?.waitingPercentageChange}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classNames(style.mianCards)}>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold,
                      commonstyles.mb8
                    )}
                  >
                    Cured Patients
                  </p>
                  <div className={classNames(commonstyles.flx)}>
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs32,
                          commonstyles.semiBold
                        )}
                      >
                        {peramedicGraphDetails?.curedPatientCount
                          ? peramedicGraphDetails.curedPatientCount
                          : 0}
                      </p>
                    </div>
                    <div>
                      {Number(parsedCuredPercentageChange) > 0 ? (
                        <div className={style.iconsOuterGreen}>
                          <FaArrowUp className={classNames(style.arrowUp)} />
                        </div>
                      ) : (
                        <div className={style.iconsOuterOrange}>
                          <FaArrowDown
                            className={classNames(style.arrowdown)}
                          />
                        </div>
                      )}
                    </div>{" "}
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs18,
                          commonstyles.semiBold,
                          peramedicGraphDetails?.curedPercentageChange < 0
                            ? style.colorOrange
                            : style.colorGreen
                        )}
                      >
                        {peramedicGraphDetails?.curedPercentageChange}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames(style.flx, style.mt24)}>
                <div className={classNames(commonstyles.col9)}>
                  <div className={style.graph}>
                    <CommonDoctorsChart data={data} />
                  </div>
                </div>
                <div className={classNames(commonstyles.col3)}>
                  <Avatar
                    className={style.big_avatar}
                    sx={{ width: "50%", height: "220px" }}
                    src={paramedic?.doctorImage}
                  />

                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.semiBold,
                      style.mt10,
                      style.textcenter,
                      commonstyles.colorBlue
                    )}
                    style={{ textTransform: "capitalize" }}
                  >
                    {paramedic?.name}
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.medium,
                      style.mt16,
                      style.textcenter
                    )}
                  >
                    {paramedic?.email}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ParamedicDashboard;
