import React from "react";
import Carousel from "react-multi-carousel";
import classNames from "classnames";
import "react-multi-carousel/lib/styles.css";
import commonstyles from "shared/utils/common.module.css";
import style from "./layout&Price.module.css";
import Kalam from "assets/images/kalam.webp";
import MalanJaba from "assets/images/malanJaba.jpg";
import WaterFall from "assets/images/waterfalls.webp";
import Neelum from "assets/images/Neelum.jpg";
import BadShahi from "assets/images/badshahi.jpg";
import fasialmasjid from "assets/images/fasialmasjid.jpeg";
import darawer from "assets/images/Daerwarfort.jpg";
import Gawdar from "assets/images/Gwadar-Port.jpg";
import PishinValley from "assets/images/Pishin-Valley-Balochistan.jpg";

const responsive = {
  lgdesktop: {
    breakpoint: { max: 3000, min: 1441 },
    items: 1,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1041 },
    items: 1,
    slidesToSlide: 1,
  },
  // Laptop: {
  //   breakpoint: { max: 1040, min: 769 },
  //   items: 4,
  //   slidesToSlide: 1,
  // },
  // tablet: {
  //   breakpoint: { max: 768, min: 481 },
  //   items: 2,
  //   slidesToSlide: 1,
  // },
  // mobile: {
  //   breakpoint: { max: 480, min: 320 },
  //   items: 1,
  //   slidesToSlide: 1,
  // },
};

const sliderItems = [
  {
    imageUrl: Kalam,
  },
  {
    imageUrl: MalanJaba,
  },
  {
    imageUrl: WaterFall,
  },
  {
    imageUrl: Neelum,
  },
  {
    imageUrl: BadShahi,
  },
  {
    imageUrl: fasialmasjid,
  },
  {
    imageUrl: darawer,
  },
  {
    imageUrl: Gawdar,
  },
  {
    imageUrl: PishinValley,
  },
];

const Layoutslider = () => {
  return (
    <div className="parent">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        showDots={true}
        infinite={true}
        pauseOnHover={false}
        arrows={false}
        dotListClass="custom-dot-list-style"
      >
        {sliderItems.map((item, index) => (
          <div className={style.slider} key={index}>
            <img src={item.imageUrl} className={style.imgs} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Layoutslider;
