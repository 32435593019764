import React, { useState } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import commonStyles from "shared/utils/common.module.css";
import * as Yup from "yup";
import commonstyles from "shared/utils/common.module.css";
import style from "./health.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import MySelfImg from "assets/images/myself.png";
import FamilyImg from "assets/images/family.png";
import ParentsImg from "assets/images/parents.png";
import { CustomInput, CustomModal, PrimaryButton } from "shared/components";
import { IoClose } from "react-icons/io5";
import Empty from "assets/images/empty2.png";
import CustomSelect from "shared/components/CustomSelect";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Typography,
} from "@mui/material";
import {
  insuranceHealthMySelfPackageSchema,
  insuranceHealthParentsPackageSchema,
  insuranceMySelfPackageSchema,
} from "shared/utils";
import {
  setInsuranceHealthFamilyPackage,
  setInsuranceHealthParentsPackage,
  setInsuranceMySelfPackage,
} from "shared/redux";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import InsuranceEmpty from "shared/components/InsuranceEmpty";
const Age = ["your age Criteria", "Karachi", "Multan", "Islambad"];
const SpouseAge = ["Spouse Age Criteria", "Karachi", "Multan", "Islambad"];
const Kidage = ["Kid age", "Karachi", "Multan", "Islambad"];
const SelectHospital = [
  "Select Hospitalization Limit (PKR)",
  "Karachi",
  "Multan",
  "Islambad",
];

export default function HeathMain() {
  const [selectedOption, setSelectedOption] = useState("0"); // Default value
  const [showHealthCategory, setShowHealthCategory] = useState(false);

  const handleCardClick = () => {
    setShowHealthCategory(true);
  };

  const navigate = useNavigate(); // Declare once

  const handleGoBack = () => {
    navigate("/insurance/Category");
  };
  const handleGoToMyself = () => {
    navigate("/insurance/MySelfMian");
  };
  const handleGoToMyFamily = () => {
    navigate("/insurance/MyFamilyMian");
  };
  const handleGoToMyParents = () => {
    navigate("/insurance/ParentsMian");
  };
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
    setShowHealthCategory(false); // Close the modal when an option is selected
  };

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonStyles.mr87}>
        <div className={classNames(commonstyles.outerContainer)}>
          <div className={commonstyles.flx}>
            <div className={commonstyles.flx}>
              <IoArrowBack className={style.back} onClick={handleGoBack} />
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.semiBold,
                  commonstyles.colorBlue,
                  style.mr56
                )}
              >
                Back{" "}
              </p>
            </div>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Health
            </p>
          </div>
          <div className={commonstyles.flx}>
            <div className={style.myselfcard} onClick={handleGoToMyself}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Myself
              </p>
              <p className={classNames(commonstyles.fs16, style.mt16)}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <img src={MySelfImg} className={style.healtImgss} />
            </div>
            <div className={style.myselfcard} onClick={handleGoToMyFamily}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Family
              </p>
              <p className={classNames(commonstyles.fs16, style.mt16)}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <img src={FamilyImg} className={style.familyImg} />
            </div>
            <div className={style.myselfcard} onClick={handleGoToMyParents}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Parents
              </p>
              <p className={classNames(commonstyles.fs16, style.mt16)}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <img src={ParentsImg} className={style.healtImgss} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
