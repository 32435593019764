import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const donationSignup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DONATION_SIGNUP, params);
};

export const donationSendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DONATION_SEND_CODE_TO_EMAIL, params);
};

export const donationConfirmEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DONATION_CONFIRM_EMAIL, params);
};

export const donationCompleteSignup = (params: any, id: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.DONATION_COMPLETE_SIGNUP}?id=${id}`,
    params
  );
};

export const donationLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DONATION_LOGIN, params);
};

export const resetLinkDonation = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DONATION_RESET_LINK, params);
};

export const newPasswordDonation = (params: any, token: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.DONATION_NEWPASSWORD}?token=${token}`,
    params
  );
};
export const donationLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.DONATION_LOGOUT);
};
export const donationGraphDETAILSUpperPortion = () => {
  return HTTP_CLIENT.get(ENDPOINTS.DONATION_DASHBOARDDETAILS);
};
export const donationGRAPH = () => {
  return HTTP_CLIENT.get(ENDPOINTS.DONATION_GRAPH);
};
export const donationGETALLPACK = (type: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.DONATION_GETALLPACKAGES}?criteriaName=${type}`
  );
};

export const donation_GET_ALL_Packages = () => {
  return HTTP_CLIENT.get(`${ENDPOINTS.DONATION_GET_ALL_PACKAGES}`);
};
export const donationGETALLDonations = () => {
  return HTTP_CLIENT.get(ENDPOINTS.DONATION_GETALLDONATIONS);
};
export const donationDonorDetails = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.DONATION_DONORDETAILS}?donationId=${id}`);
};
export const donationPackageDetail = (id: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.DONATION_PACKAGEDETAILS}?packageId=${id}`
  );
};
export const donationPackageDelete = (id: string) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.DONATION_DELETEPACKAGE}?packageId=${id}`
  );
};
export const donationGETALLCriteria = () => {
  return HTTP_CLIENT.get(ENDPOINTS.DONATION_GETALLCRITERIA);
};
export const donationGetCriteria = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.DONATION_GETCRITERIA}?criteriaId=${id}`);
};
export const donationdeleteCriteria = (id: string) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.DONATION_DELETECRITERIA}?criteriaId=${id}`
  );
};
export const donationAddCriteria = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DONATION_ADDCRITERIA, params);
};
export const donationEditCriteria = (id: string, params: any) => {
  return HTTP_CLIENT.put(
    `${ENDPOINTS.DONATION_EDITCRITERIA}?criteriaId=${id}`,
    params
  );
};
export const donationAddPackage = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DONATION_ADDPACKAGE, params);
};

export const donationUpdateProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.DONATION_UPDATEPROFILE, params);
};

export const donationDONORLIST = () => {
  return HTTP_CLIENT.get(ENDPOINTS.DONATION_DONORLIST);
};

export const donationTOPDONORS = () => {
  return HTTP_CLIENT.get(ENDPOINTS.DONATION_TOPDONORS);
};
