import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "@mui/material";
import style from "./apartmentsInfo.module.css";
import CustomSelect from "shared/components/CustomSelect";
import { setHotelAddAppartment } from "shared/redux";
import { hotelAddHomesHomeInfo } from "shared/utils";
import LocationInput from "shared/components/LocationInput";

interface Props {
  handleClickNext: any;
}
export default function ApartmentsInfo(props: Partial<Props>) {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [ManagerMarked, setManagerMarked] = useState<string | null>(null);
  const { handleClickNext } = props;
  const { hotelAddAppartment } = useSelector((state: any) => state.root.hotel);
  console.log(",,,,,,,,,,,", hotelAddAppartment);
  const CountrySelector = (selectedOption: string) => {
    formik.setFieldValue("country", selectedOption);
  };
  const StarRating = (selectedOption: string) => {
    formik.setFieldValue("startRating", selectedOption);
  };
  const handleCheckboxChange = (value: string) => {
    setSelectedOption(value);
    formik.setFieldValue("partOfCompany", value);
  };
  const handleManagerCheckbox = (value: string) => {
    setManagerMarked(value);
    formik.setFieldValue("channelManager", value);
  };

  const formik = useFormik({
    initialValues: {
      propertyName: "",
      name: "",
      contactNo: "",
      alternativeContactNo: "",
      postCode: "",
      propertyAddress: "",
      partOfCompany: "",
      companyName: "",
      channelManager: "",
      managerName: "",
    },
    validationSchema: Yup.object(hotelAddHomesHomeInfo),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  useEffect(() => {
    formik.setValues(hotelAddAppartment);
  }, []);

  const handleSubmit = () => {
    console.log("DONE");
    dispatch(setHotelAddAppartment(formik.values));
    handleClickNext();
  };

  const handleNExt = () => {
    handleClickNext();
  };

  const handleSetAddressData = (value: any) => {
    // console.log("CITYYY................", value.city);
    // console.log("LAT................", value.lat);
    // console.log("LONG..........", value.lng);
    // console.log("LABEL.............", value.label);
    formik.setFieldValue("address", value.label);
    formik.setFieldValue("lat", value.lat);
    formik.setFieldValue("lng", value.lng);
    formik.setFieldValue("city", value.city);
  };

  return (
    <div className={commonstyles.col5}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col12)}>
            <CustomInput
              placeholder="Your property  Name"
              id="propertyName"
              name="propertyName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.propertyName}
            />
            {formik.touched.propertyName && formik.errors.propertyName ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.propertyName}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Name"
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.name}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <CustomInput
              placeholder="Contact No"
              id="contactNo"
              name="contactNo"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.contactNo}
            />
            {formik.touched.contactNo && formik.errors.contactNo ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.contactNo}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Alternative Contact No."
              id="alternativeContactNo"
              name="alternativeContactNo"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.alternativeContactNo}
            />
            {formik.touched.alternativeContactNo &&
            formik.errors.alternativeContactNo ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.alternativeContactNo}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <CustomInput
              placeholder="Post Code"
              id="postCode"
              name="postCode"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.postCode}
            />
            {formik.touched.postCode && formik.errors.postCode ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.postCode}
              </div>
            ) : null}
          </div>
        </div>

        <div className={classNames(commonstyles.flx)}>
          <div className={classNames(commonstyles.col12)}>
            <CustomInput
              placeholder="Property Address"
              id="propertyAddress"
              name="propertyAddress"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.propertyAddress}
            />
            {formik.touched.propertyAddress && formik.errors.propertyAddress ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.propertyAddress}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.mt56)}>
          <p
            className={classNames(
              commonstyles.fs14,
              commonstyles.semiBold,
              commonstyles.colorBlue,
              commonstyles.mb32
            )}
          >
            Do you own multiples homes or are you part of property management
            company or a group?
          </p>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.col6)}>
          <Checkbox
            checked={selectedOption === "Yes"}
            onChange={() => handleCheckboxChange("Yes")}
            className={style.checkbox}
          />
          <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
            Yes
          </p>
        </div>
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.col6,
            commonstyles.mb32
          )}
        >
          <Checkbox
            checked={selectedOption === "No"}
            onChange={() => handleCheckboxChange("No")}
            className={style.checkbox}
          />
          <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
            No
          </p>
        </div>
        {formik.touched.partOfCompany && formik.errors.partOfCompany ? (
          <div className={classNames(commonStyles.error)}>
            *{formik.errors.partOfCompany}
          </div>
        ) : null}
        {selectedOption === "Yes" && (
          <div>
            <CustomInput
              placeholder="Name OF Company (optional)"
              id="companyName"
              name="companyName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.companyName}
            />
          </div>
        )}

        <div className={classNames(commonstyles.mt56)}>
          <p
            className={classNames(
              commonstyles.fs14,
              commonstyles.semiBold,
              commonstyles.colorBlue,
              commonstyles.mb32
            )}
          >
            Do you use a channel manager?
          </p>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.col12)}>
          <Checkbox
            checked={ManagerMarked === "I use a channel manager"}
            onChange={() => handleManagerCheckbox("I use a channel manager")}
            className={style.checkbox}
          />
          <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
            I use a channel manager
          </p>
        </div>

        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.col8,
            commonstyles.mb32
          )}
        >
          <Checkbox
            checked={ManagerMarked === "I don’t use a channel manager"}
            onChange={() =>
              handleManagerCheckbox("I don’t use a channel manager")
            }
            className={style.checkbox}
          />
          <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
            I don’t use a channel manager
          </p>
        </div>

        {formik.touched.channelManager && formik.errors.channelManager ? (
          <div className={classNames(commonStyles.error)}>
            *{formik.errors.channelManager}
          </div>
        ) : null}
        {ManagerMarked === "I use a channel manager" && (
          <div>
            <CustomInput
              placeholder="Channel manager’s name (optional)"
              id="managerName"
              name="managerName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.managerName}
            />
          </div>
        )}

        <div
          style={{ marginTop: "32px", width: "204px", marginBottom: "16px" }}
        >
          <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
}
