import React, { useEffect, useState } from "react";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "../booking.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { travelGETTICKETBOOKINGS } from "shared/services/TravelAgency";
import { TbRefresh } from "react-icons/tb";
interface Props {
  booking?: any;
}
export default function BookingTickets(props: Partial<Props>) {
  const { booking } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleGoToDetail = (id: string) => {
    navigate(`/travelAgency/Booking/TicketDetail/${id}`);
  };

  return (
    <>
      <div className={classNames(style.App)}>
        <div className={style.payment}>
          <div className={style.headerOuter}>
            <p className={style.headerclass3}>Package Name</p>
            <p className={style.headerclass3}>Request Type</p>
            <p className={style.headerclass3}>From</p>
            <p className={style.headerclass3}>To</p>
            <p className={style.headerclass3}>Total User</p>
          </div>
          <div className={style.tableData}>
            <table
              style={{
                margin: "0px",
                // borderCollapse: "separate",
                // borderSpacing: "0 10px",
              }}
            >
              <tbody className={style.wapper}>
                {booking?.map((val: any, key: any) => {
                  return (
                    <tr
                      className={style.tableRow}
                      key={key}
                      onClick={() => handleGoToDetail(val?._id)}
                    >
                      <td className={style.w20}>{val?.packageName}</td>
                      <td className={style.w20}>{val?.requestType}</td>
                      <td className={style.w20}>
                        {val &&
                          val.from &&
                          new Date(val.from).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })}
                      </td>
                      <td className={style.w20}>
                        {val &&
                          val.to &&
                          new Date(val.to).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })}
                      </td>
                      <td className={style.w20}>{val?.totalUser}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
