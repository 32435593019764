import React from "react";
import Carousel from "react-multi-carousel";
import classNames from "classnames";
import "react-multi-carousel/lib/styles.css";
import commonstyles from "shared/utils/common.module.css";
import style from "./homeslider.module.css";
import FERTILITY from "assets/SliderTreamnets/Fertility.jpg";
import STEMCELL from "assets/SliderTreamnets/Stemcell.jpg";
import COSMETICSURGERY from "assets/SliderTreamnets/COSMETICSURGERY.jpg";
import BARIATRICSURGERY from "assets/SliderTreamnets/BARIATRIC SURGERY.jpg";
import Hair from "assets/SliderTreamnets/TRANSPLANTS.jpg";
import AESTHETICTREATMENTS from "assets/SliderTreamnets/AESTHETICTREATMENTS.jpg";
import CARDIOVASCULARMATABOLIC from "assets/SliderTreamnets/CARDIOVASCULARMATABOLIC.jpg";
import ORTHOPEDICS from "assets/SliderTreamnets/ORTHOPEDICS.jpg";
import GYNECOLOGISTS from "assets/SliderTreamnets/GYNECOLOGISTS.jpg";
import CANCER from "assets/SliderTreamnets/cancer.jpg";
import DENTAL from "assets/SliderTreamnets/DENTAL.jpg";
import OPTHALMOLOGY from "assets/SliderTreamnets/OPTHALMOLOGY.jpg";
import LUNGS from "assets/SliderTreamnets/Lungs.jpg";
import PEDIATRICS from "assets/SliderTreamnets/PEDIATRICS.jpg";
import PSYCHIATRY from "assets/SliderTreamnets/PSYCHIATRY.jpg";
import UROLOGY from "assets/SliderTreamnets/UROLOGY.jpg";
import ENT from "assets/SliderTreamnets/ENT.jpg";
import { Laptop } from "@mui/icons-material";

const responsive = {
  lgdesktop: {
    breakpoint: { max: 3000, min: 1441 },
    items: 4,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1041 },
    items: 4,
    slidesToSlide: 1,
  },
  Laptop: {
    breakpoint: { max: 1040, min: 769 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 481 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 480, min: 320 },
    items: 1,
    slidesToSlide: 1,
  },
};

const sliderItems = [
  {
    imageUrl: STEMCELL,
    Heading: "STEM CELL",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: FERTILITY,
    Heading: "FERTILITY ",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: COSMETICSURGERY,
    Heading: "COSMETIC SURGERY",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: BARIATRICSURGERY,
    Heading: "BARIATRIC SURGERY",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: Hair,
    Heading: "HAIR TRANSPLANTS ",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: AESTHETICTREATMENTS,
    Heading: "AESTHETIC TREATMENTS",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: CARDIOVASCULARMATABOLIC,
    Heading: "CARDIOVASCULAR & METABOLIC",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: ORTHOPEDICS,
    Heading: "ORTHOPEDICS",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: GYNECOLOGISTS,
    Heading: "GYNECOLOGISTS",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: CANCER,
    Heading: "CANCER",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: DENTAL,
    Heading: "DENTAL",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: OPTHALMOLOGY,
    Heading: "OPHTHALMOLOGY",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: LUNGS,
    Heading: "LUNGS",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: PEDIATRICS,
    Heading: "PEDIATRICS",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: PSYCHIATRY,
    Heading: "PSYCHIATRY",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: UROLOGY,
    Heading: "UROLOGY",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
  {
    imageUrl: ENT,
    Heading: "E.N.T",
    text: "Lorem Ipsum is simply dummy text of the printing an...",
  },
];

const TopTreatment = () => {
  return (
    <div className="parent">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        centerMode={true}
        showDots={false}
        infinite={true}
        dotListClass="custom-dot-list-style"
      >
        {sliderItems.map((item, index) => (
          <div className={style.slider} key={index}>
            <img src={item.imageUrl} className={style.imgs} />
            <div className={classNames(style.headingouter)}>
              <p className={classNames(style.heading)}>{item.Heading}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default TopTreatment;
