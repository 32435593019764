import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "./tour.module.css";
import moment from "moment";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import commonstyle from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import TOur from "assets/images/tourPakges.png";
import ActionButtons from 'shared/components/ConfirmDeleteModal/ActionButtonProps';
import { IoClose, IoArrowBack } from "react-icons/io5";
import Date from "assets/images/datepicker.png";
import {  deletetourbooking } from "shared/services/TravelAgency";
import { useNavigate } from "react-router-dom";
import deleteIconTour from "assets/images/deleteimage1.png";
import { MdOutlineArrowOutward } from "react-icons/md";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import {
  ArrowBack,
  CustomInput,
  CustomModal,
  CustomStepper,
  LoadingModal,
  PrimaryButton,
} from "shared/components";
import Uplaod from "assets/images/uil_image-upload.png";
import CustomSelect from "shared/components/CustomSelect";
import { Checkbox } from "@mui/material";
import {
  travelADDTOUR,
  travelAgencyGETALLTOURS,
} from "shared/services/TravelAgency";
import {
  setAddtour,
  setTravelTourLenghth,
  setTravelTours,
  setTravelToursRenderFlag,
} from "shared/redux";
import { useDispatch, useSelector } from "react-redux";
import { TbRefresh } from "react-icons/tb";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";
import Datepicker from "shared/components/DatePicker";
import {
  travelAgencyAddTourFacilitiesSchema,
  travelAgencyAddTourPoliciesSchema,
  travelAgencyAddTourPriceSchema,
  travelAgencyAddTourSchema,
} from "shared/utils";
import PropertyPhotoImgPicker from "pages/TravelTourism/Hotels/HotelProperty/Breadcrum/PropertyPhoto/PropertyPhotoImgPicker";
import NewPagination from "shared/components/NewPagination/NewPagination";
const steps = [
  {
    id: "1",
    lable: "Itnerary",
  },

  {
    id: "2",
    lable: "Facilities & Plans",
  },
  {
    id: "3",
    lable: "Polices",
  },
  {
    id: "4",
    lable: "Price",
  },
];


const Passengers = ["Passengers", "Karachi", "Multan", "Islambad"];
const Class = ["Economy Class", "First Class", "Luxury Class"];
const Breakfast = ["How many Breakfast", "Karachi", "Multan", "Islambad"];
const Lunch = ["How many Lunch", "Karachi", "Multan", "Islambad"];
const Stay = ["Driect", "Stay"];
const Dinner = ["Yes", "No"];
export default function TourPakages() {
  const { travelTours, travelToursRenderFlag, travelTourLenghth } = useSelector(
    (state: any) => state.root.travelagency
  );

  console.log("travelTours.............", travelTours);
  const [showAddTourDetail, setShowAddTourDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = travelTourLenghth;
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [selectedTourId, setSelectedTourId] = useState<string | null>(null)
    
  const dispatch = useDispatch();
  const handleModelOpen = () => {
    setShowAddTourDetail(true);
  };
  const navigate = useNavigate(); // Declare once
  const handleGoTourDetail = (id: string) => {
    navigate(`/travelAgency/tourPakagesDetail/${id}`);
  };
  

  const fetchAllTours = (pageno: number) => {
  setLoading(true);
  travelAgencyGETALLTOURS(pageno)
    .then((res: any) => {
      console.log("travelAgencyGETALLTOURS.................", res);
      dispatch(setTravelTours(res?.data?.tours));
      dispatch(setTravelTourLenghth(res?.data?.totalTours));
    })
    .catch((err: any) => {
      console.log("send error.nnnnn..", err?.response?.data);
    })
    .finally(() => {
      setLoading(false);
    });
};


  useEffect(() => {
    if (travelToursRenderFlag) {
      setLoading(true);
      fetchAllTours(currentPage);
      dispatch(setTravelToursRenderFlag(false));
    }
  }, [travelToursRenderFlag, currentPage]);

  const handleRotate = (): void => {
  setRotation(rotation - rotationIncrement);
  fetchAllTours(currentPage);
};


const handleNextPage = () => {
  let itemToRender = currentPage * 10;

  if (travelTourLenghth > itemToRender) {
    setCurrentPage(currentPage + 1);
    setPageno(pageno + 10);
    dispatch(setTravelToursRenderFlag(true));
  }
};

const handlePreviousPage = () => {
  if (currentPage > 1) {
    setCurrentPage(currentPage - 1);
    setPageno(pageno - 10);
    dispatch(setTravelToursRenderFlag(true));
  }
};
const handleDeleteTour = () => {
  if (selectedTourId) {
    setLoading(true); 
    deletetourbooking(selectedTourId)
      .then(() => {
        fetchAllTours(currentPage);
        setShowConfirmDelete(false);
        setSelectedTourId(null);
      })
      .catch((err: any) => {
        console.error("Error deleting tour:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false); 
      });
  } else {
    alert("Error: Invalid tour ID");
  }
};



  const handleConfirmDelete = (tourId: string) => {
    setSelectedTourId(tourId);
    setShowConfirmDelete(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
    setSelectedTourId(null);
  };

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />

      {loading ? (
        <LoadingModal showModal={loading} />
      ) : (
        <div className={commonstyles.mr87}>
          <div className={style.outerContainer}>
            <div className={commonstyles.flx}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Packages
              </p>
              <div className={style.outerRefresh}>
                <TbRefresh
                  className={style.RefreshIcon}
                  style={{ transform: `rotate(${rotation}deg)` }}
                  onClick={handleRotate}
                />
              </div>
              <div className={style.outerRefresh}>
                <BiSolidMessageSquareAdd
                  className={style.RefreshIcon}
                  onClick={handleModelOpen}
                />
              </div>
              <div className={style.pagination}>
                <NewPagination
                  onNext={handleNextPage}
                  onPrevious={handlePreviousPage}
                  startItem={(currentPage - 1) * itemsPerPage + 1}
                  endItem={Math.min(currentPage * itemsPerPage, totalItems)}
                  totalItems={totalItems}
                />
              </div>
              <div>
                <CustomModal
                  showModal={showAddTourDetail}
                  children={
                    <AddTourDetail
                      setShowAddTourDetail={setShowAddTourDetail}
                    />
                  }
                />
              </div>
            </div>
            <div className={style.flex}>
              {travelTours?.map((items: any) => (
                <div
                  key={items?._id}
                  className={style.TourCardss}
                  onClick={() => handleGoTourDetail(items?._id)}
                >
                  <div className={commonstyles.col7}>
                    <p
                      className={classNames(
                        commonstyles.fs20,
                        commonstyles.semiBold
                      )}
                    >
                      {items?.packageName}
                    </p>
                    <div className={commonstyles.flx}>
                      <img src={Date} className={style.DateImg} />
                      <p className={classNames(commonstyles.fs16, style.mt16)}>
                        {items?.packageDuration}
                      </p>
                    </div>
                    <p className={classNames(commonstyles.fs16, style.mt8)}>
                      {items?.pricePerHead}
                    </p>
                    <div className={commonstyles.flx}>
                      <p className={classNames(commonstyles.fs12, style.mt8)}>
                        View Detail
                      </p>
                      <MdOutlineArrowOutward className={style.ArrowOutward} />
                    </div>
                  </div>
                  <div className={commonstyles.col5}>
                    <img src={items?.images[0]} className={style.pagesImg} />
                  </div>
                  <img
                    src={deleteIconTour}
                    alt="Delete"
                    className={style.deleteIconTour}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents triggering other click handlers
                      handleConfirmDelete(items?._id);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

     
       {showConfirmDelete && (
  <ActionButtons
    title="Are you sure you want to delete?"
    onDelete={handleDeleteTour}
    onCancel={handleCancelDelete}
    item={handleDeleteTour!} 
  />
)}
    </div>
  );

}
const AddTourDetail: React.FC<{ setShowAddTourDetail: React.Dispatch<React.SetStateAction<boolean>> }> = (props) => {
  const [screenName, setScreenName] = useState("Itnerary");
  const [selectedStep, setSelectedStep] = useState(0);
  const [formData, setFormData] = useState({
    itinerary: {},
    facilities: {},
    policies: {},
    price: {},
  });

  const handleClickNext = () => {
    if (screenName === "Itnerary") {
      setScreenName("Facilities & Plans");
    } else if (screenName === "Facilities & Plans") {
      setScreenName("Polices");
    } else if (screenName === "Polices") {
      setScreenName("Price");
    }

    if (selectedStep < steps.length - 1) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClickPrev = () => {
    if (screenName === "Price") {
      setScreenName("Polices");
    } else if (screenName === "Polices") {
      setScreenName("Facilities & Plans");
    } else if (screenName === "Facilities & Plans") {
      setScreenName("Itnerary");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };

  const handleCloseModal = () => {
    props.setShowAddTourDetail(false);
  };

  const saveFormData = (newData: any) => {
  setFormData(prevData => ({
    ...prevData,
    [screenName.toLowerCase().replace(/ & /, '')]: newData,
  }));

  
};

  return (
    <div style={{ width: "600px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
       {screenName !== "Itnerary" && (
        <IoArrowBack className={style.arrowBack} onClick={handleClickPrev} />
      )}
        <IoClose className={style.close} onClick={handleCloseModal} />
      </div>
      <div className={classNames(commonstyles.col11)}>
        <CustomStepper steps={steps} selectedStep={selectedStep} />
      </div>
      <div className={classNames(commonstyles.col12, commonstyles.mt40)}>
        {screenName === "Itnerary" && (
          <Itnerary
            handleClickNext={handleClickNext}
            saveFormData={saveFormData}
            formData={formData.itinerary}
          />
        )}
        {screenName === "Facilities & Plans" && (
          <FacilitiesPlans
            handleClickNext={handleClickNext}
            handleClickPrev={handleClickPrev}
            saveFormData={saveFormData}
            formData={formData.facilities}
          />
        )}
        {screenName === "Polices" && (
          <Polices
            handleClickNext={handleClickNext}
            handleClickPrev={handleClickPrev}
            saveFormData={saveFormData}
            formData={formData.policies}
          />
        )}
        {screenName === "Price" && (
          <Price
            handleClickPrev={handleClickPrev}
            setShowAddTourDetail={props.setShowAddTourDetail}
            saveFormData={saveFormData}
            formData={formData.price}
          />
        )}
      </div>
    </div>
  );
};

const Itnerary = (props: any) => {
  const dispatch = useDispatch();
  const [selectedStay, setSelectedStay] = useState<string | null>(null);
  const [imagearray, setImagearray] = useState<any[]>([]);
  const { setShowItneraryModel, handleClickNext } = props;

  const formik = useFormik({
    initialValues: {
      packageName: "",
      packageDuration: "",
      from: "",
      to: "",
      departDate: "",
      departTime: "",
      returnDate: "",
      returnTime: "",
      seatLimit: "",
      class: "",
    },
    validationSchema: Yup.object(travelAgencyAddTourSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const ImageSelector = (url: any) => {
    setImagearray((prevImages) => [...prevImages, url]);
  };

  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    setSelectedStay(selectedOption);
    formik.setFieldValue("class", selectedOption);
  };

  const handleDepartDate = (date: any) => {
    console.log("Date:", date);
    const selectedDate: any = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    const isoString = formattedDate + "T00:00:00.000Z";
    // let str = formattedDate.toString();
    console.log("DateSTRING", isoString);

    formik.setFieldValue("departDate", date);
  };
  const [departTime, setDepartTime] = useState<string | null>(null);

  const handleReturnDate = (date: any) => {
    // const formattedDate = date.toISOString().split("T")[0];
    // console.log("returnDate", formattedDate);
    formik.setFieldValue("returnDate", date);
  };

   const [returnTime, setReturnTime] = useState<string | null>(null);

  const handleReturnTime = (time: any) => {
    console.log("handleReturnTime", time);
    const formattedTime = dayjs(time).format("hh:mm A");
    console.log("Setting", formattedTime);
    formik.setFieldValue("returnTime", time);
    setReturnTime(time);
  

  };
  
  const handleDepartTime = (time: any) => {
    console.log("handleDepartTime", time);
    const formattedTime = dayjs(time).format("hh:mm A");
    console.log("Setting", formattedTime);
    formik.setFieldValue("departTime", time);
      setDepartTime(formattedTime);

  };

  const handleSubmit = () => {
    console.log("Submit", formik.values);

    dispatch(
      setAddtour({
        packageName: formik.values.packageName,
        packageDuration: formik.values.packageDuration,
        from: formik.values.from,
        to: formik.values.to,
        departDate: formik.values.departDate,
        departTime: formik.values.departTime,
        returnDate: formik.values.returnDate,
        returnTime: formik.values.returnTime,
        seatLimit: formik.values.seatLimit,
        class: formik.values.class,
        images: imagearray,
      })
    );
    handleClickNext();
  };
  
  console.log(">>>>>>>>>>>>>>>", imagearray);
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {" "}
        <div
          className={classNames(
            commonstyles.col10,
            commonstyles.flxBetween,
            commonstyles.mb28
          )}
        >
          <div className={classNames(style.col5)}>
            <CustomInput
              placeholder="Package Name"
              id="packageName"
              name="packageName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.packageName}
            />
            {formik.touched.packageName && formik.errors.packageName ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.packageName}
              </div>
            ) : null}
          </div>
          <div className={classNames(style.col5)}>
            <CustomInput
              placeholder="Package Duration"
              id="packageDuration"
              name="packageDuration"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.packageDuration}
            />
            {formik.touched.packageDuration && formik.errors.packageDuration ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.packageDuration}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonstyles.col10,
            commonstyles.flxBetween,
            commonstyles.mb28
          )}
        >
          <div className={classNames(style.col5)}>
            <CustomInput
              placeholder="From"
              id="from"
              name="from"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.from}
            />
            {formik.touched.from && formik.errors.from ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.from}
              </div>
            ) : null}
          </div>
          <div className={classNames(style.col5)}>
            <CustomInput
              placeholder="To"
              id="to"
              name="to"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.to}
            />
            {formik.touched.to && formik.errors.to ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.to}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonstyles.col10,
            commonstyles.flxBetween,
            commonstyles.mb28
          )}
        >
          <div className={classNames(style.col5)}>
            <Datepicker placeholder="Depart Date" setData={handleDepartDate} />
            {formik.touched.departDate && formik.errors.departDate ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.departDate}
              </div>
            ) : null}
          </div>
          <div className={classNames(style.col5)}>
            <CustomTimePicker
              placeholder="Depart Time"
              value={departTime}
              setData={handleDepartTime}
            />
            {formik.touched.departTime && formik.errors.departTime ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.departTime}
              </div>
            ) : null}
          </div>
        </div>{" "}
        <div
          className={classNames(
            commonstyles.col10,
            commonstyles.flxBetween,
            commonstyles.mb28
          )}
        >
          <div className={classNames(style.col5)}>
            <Datepicker placeholder="Return Date" setData={handleReturnDate} />
            {formik.touched.returnDate && formik.errors.returnDate ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.returnDate}
              </div>
            ) : null}
          </div>

          <div className={classNames(style.col5)}>
            <CustomTimePicker
              placeholder="Return Time"
              value={returnTime}
              setData={handleReturnTime}
            />
            {formik.touched.returnTime && formik.errors.returnTime ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.returnTime}
              </div>
            ) : null}
          </div>
        </div>{" "}
        <div
          className={classNames(
            commonstyles.col10,
            commonstyles.flxBetween,
            commonstyles.mb28
          )}
        >
          <div className={classNames(style.col5)}>
            <CustomInput
              placeholder="Seat Limit"
              id="seatLimit"
              name="seatLimit"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.seatLimit}
            />
            {formik.touched.seatLimit && formik.errors.seatLimit ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.seatLimit}
              </div>
            ) : null}
          </div>
          <div className={classNames(style.col5)}>
            <CustomSelect
              options={Class}
              onSelect={handleSelect}
              placeholder="Class"
            />
            {formik.touched.class && formik.errors.class ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.class}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.col10, style.upload)}>
          <div className={classNames(commonstyles.flxCenter)}>
            <img src={Uplaod} className={style.imgs} />
          </div>
          <div
            className={classNames(
              commonstyle.fs14,
              style.mb16,
              commonstyle.medium,
              style.justifycenter
            )}
          >
            <PropertyPhotoImgPicker setData={ImageSelector} />
          </div>{" "}
          <div className={style.center}>
            {imagearray.map((imageUrl, index) => (
              <div key={index}>
                <img src={imageUrl} className={style.Picss} />
              </div>
            ))}
          </div>
        </div>
        <div style={{ width: "204px", marginTop: "32px" }}>
          <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
};

const FacilitiesPlans = (props: any) => {
  const dispatch = useDispatch();
  const { addtour } = useSelector((state: any) => state.root.travelagency);
  console.log("addtour in Plans screen", addtour);

  const { setShowAmenitiesModel, handleClickNext } = props;
  const formik = useFormik({
    initialValues: {
      breakfast: "",
      lunch: "",
      dinner: "",
      dayByDay: "",
    },
    validationSchema: Yup.object(travelAgencyAddTourFacilitiesSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleBreakFast = (selectedOption: string) => {
    formik.setFieldValue("breakfast", selectedOption);
    console.log("Selected Option:", selectedOption);
  };
  const handleLunch = (selectedOption: string) => {
    formik.setFieldValue("lunch", selectedOption);
    console.log("Selected Option:", selectedOption);
  };
  const handleDinner = (selectedOption: string) => {
    formik.setFieldValue("dinner", selectedOption);
    console.log("Selected Option:", selectedOption);
  };
  

  const handleSubmit = () => {
    console.log("Submit", formik.values);
    dispatch(setAddtour({ ...addtour, ...formik.values }));
    handleClickNext();
  };

  return (
    <div className={classNames(commonstyle.col12)}>
      <form onSubmit={formik.handleSubmit}>
        <div
          className={classNames(
            commonstyles.col12,
            commonstyles.flxBetween,
            commonstyles.mb28
          )}
        >
          <div className={classNames(style.col4)}>
            <CustomSelect
              options={Dinner}
              placeholder="Breakfast"
              onSelect={handleBreakFast}
            />
            {formik.touched.breakfast && formik.errors.breakfast ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.breakfast}
              </div>
            ) : null}
          </div>
          <div className={classNames(style.col4)}>
            <CustomSelect
              options={Dinner}
              placeholder="Lunch"
              onSelect={handleLunch}
            />
            {formik.touched.lunch && formik.errors.lunch ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.lunch}
              </div>
            ) : null}
          </div>
          <div className={classNames(style.col4)}>
            <CustomSelect
              options={Dinner}
              placeholder="Dinner"
              onSelect={handleDinner}
            />
            {formik.touched.dinner && formik.errors.dinner ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.dinner}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyle.col12)} style={{ width: '400px' }}>
  <textarea
    placeholder="Please write Day By Day Plans about visit Place and eating etc"
    id="dayByDay"
    name="dayByDay"
    className={classNames(style.PlanTextarea)}
    
    onChange={formik.handleChange}
    value={formik.values.dayByDay}
    rows={4} 
  />
  {formik.touched.dayByDay && formik.errors.dayByDay ? (
    <div className={classNames(commonStyles.error)}>
      *{formik.errors.dayByDay}
    </div>
  ) : null}
</div>

        <div style={{ width: "204px", marginTop: "32px" }}>
          <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
};

const Polices = (props: any) => {
  const dispatch = useDispatch();
  const { addtour } = useSelector((state: any) => state.root.travelagency);
  console.log("addtour in Policies screen", addtour);
  const { setShowFacilitiesPlansModel, handleClickNext } = props;
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };

  const formik = useFormik({
    initialValues: {
      tourPolicy: "",
    },
    validationSchema: Yup.object(travelAgencyAddTourPoliciesSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    console.log("Submit", formik.values);
    dispatch(setAddtour({ ...addtour, ...formik.values }));
    handleClickNext();
  };

  return (
    <div className={classNames(commonstyle.col12)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonstyle.col12, commonstyle.mb28)}>
          <textarea
            placeholder="Write down your recent Tour policy"
            id="tourPolicy"
            name="tourPolicy"
            className={classNames(style.textarea)}
           
            onChange={formik.handleChange}
            value={formik.values.tourPolicy}
            rows={4} 
          />
          {formik.touched.tourPolicy && formik.errors.tourPolicy ? (
            <div className={classNames(commonStyles.error)}>
              *{formik.errors.tourPolicy}
            </div>
          ) : null}
        </div>
        <div style={{ width: "204px", marginTop: "32px" }}>
          <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
};
const Price = (props: any) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { addtour } = useSelector((state: any) => state.root.travelagency);
  console.log("addtour in Price screen", addtour);
  const { setShowPriceModel, setShowAddTourDetail } = props;

  const formik = useFormik({
    initialValues: {
      priceperHead: "",
      priceperCouple: "",
    },
    validationSchema: Yup.object(travelAgencyAddTourPriceSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    if (loading) return;

    setLoading(true);
    let currentdata = formik.values;
    let params = {
      packageName: addtour.packageName,
      packageDuration: addtour.packageDuration,
      from: addtour.from,
      to: addtour.to,
      departTime: addtour.departTime,
      destinationTime: addtour.returnTime,
      departDate: addtour.departDate,
      returnDate: addtour.returnDate,
      limitedSeats: addtour.seatLimit,
      className: addtour.class,
      images: addtour.images,
      breakfastQuantity: addtour.breakfast,
      lunchQuantity: addtour.lunch,
      dinnerQuantity: addtour.dinner,
      dayByDayPlans: addtour.dayByDay,
      recentTourPolicy: addtour.tourPolicy,
      pricePerHead: currentdata.priceperHead,
      pricePerCouple: currentdata.priceperCouple,
    };
    console.log("...........BeforeAPIHitting", params);
    travelADDTOUR(params)
      .then((res: any) => {
        console.log("API travelADDTOUR:", res);

        setShowAddTourDetail(false);
      })
      .catch((err: any) => {
        console.error("Error fetching:", err.response.data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit}>
          {" "}
          <div
            className={classNames(
              commonstyle.col10,
              commonstyle.flx,
              commonstyle.mb32
            )}
          >
            <div className={classNames(style.col5, commonStyles.mr16)}>
              <CustomInput
                placeholder=" Price per Head "
                id="priceperHead"
                name="priceperHead"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.priceperHead}
              />
              {formik.touched.priceperHead && formik.errors.priceperHead ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.priceperHead}
                </div>
              ) : null}
            </div>
            <div className={classNames(style.col5)}>
              <CustomInput
                placeholder="Price per Couple"
                id="priceperCouple"
                name="priceperCouple"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.priceperCouple}
              />
              {formik.touched.priceperCouple && formik.errors.priceperCouple ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.priceperCouple}
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ width: "204px", marginTop: "32px" }}>
            <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
          </div>
        </form>
      </div>
    </>
  );
};