import React, { useState, useEffect } from "react";

import { Doctor_Verification } from "shared/components";

interface Props {
  handleClickNext: any;
}
const DoctorVerification = (props: Partial<Props>) => {
  return (
    <>
      <Doctor_Verification />
    </>
  );
};

export default DoctorVerification;
