import React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./Paramedicappointment.module.css";
import { CustomModal, LoadingModal, PrimaryButton } from "shared/components";
import { LuUser2 } from "react-icons/lu";
import { MdPhone } from "react-icons/md";
import { BsClock } from "react-icons/bs";
import { LuCalendarDays } from "react-icons/lu";
import empty from "assets/images/empty2.png";
import Avaatar from "assets/images/Avatar.png";
import Avatar from "@mui/material/Avatar";
import { GoDeviceCameraVideo } from "react-icons/go";
import { HiOutlineChatAlt } from "react-icons/hi";
import { LuClipboardEdit } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { getAllAppointment } from "../../../../shared/services/DoctorService";
import { paraGetALLAppointments } from "shared/services/Paramedic";
import { TbRefresh } from "react-icons/tb";
import { FaRegClock } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { IoChatboxEllipses, IoVideocam } from "react-icons/io5";
import NewPagination from "shared/components/NewPagination/NewPagination";
import DoctorEmpty from "shared/components/DoctorEmpty";
import ZegoCloudComponent from "shared/components/Zegocloud";
import {
  setPeramedicAppointmentFlag,
  setPeramedicAppointmentLenght,
  setPeramedicAppointments,
  setperamedicAppointmentpateint,
} from "shared/redux";
const request = ["idioe", "udiwnui", "bdiwojo"];
function ParamedicAppointment() {
  const {
    peramedicAppointments,
    peramedicAppointmentFlag,
    peramedicAppointmentLenght,
    peramedicAppointmentpateint,
  } = useSelector((state: any) => state.root.paramedic);
  console.log("::::::::::::::::;", peramedicAppointments);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = peramedicAppointmentLenght;
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [videomodel, setVideomodel] = useState(false);

  const handleVideoModal = () => {
    setVideomodel(true);
  };

  const handleGoAppDetail = (id: string, index: number) => {
    console.log("index no in APPPP", index);

    navigate(`/paramedicStaff/appointment/Prescription/${id}/${index}`);
  };

  const fetchDocAppointment = (pageno: number): void => {
    setLoading(true);
    paraGetALLAppointments(pageno)
      .then((res) => {
        console.log("Doctor Appointment API Hit Response", res);
        if (res?.data?.Appointments.length >= 0) {
          const patientIds = res?.data?.Appointments.map(
            (appointment: any) => appointment.patientId
          );
          dispatch(setPeramedicAppointments(res?.data?.Appointments));
          dispatch(setPeramedicAppointmentLenght(res?.data?.totalAppoints));
          dispatch(setperamedicAppointmentpateint(patientIds));
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (peramedicAppointmentLenght > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setPeramedicAppointmentFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setPeramedicAppointmentFlag(true));
    }
  };

  useEffect(() => {
    if (peramedicAppointmentFlag) {
      setLoading(true);
      fetchDocAppointment(currentPage);
      dispatch(setPeramedicAppointmentFlag(false));
    }
  }, [peramedicAppointmentFlag, currentPage]);

  const handleRotate = (): void => {
    setRotation(rotation - rotationIncrement);
    fetchDocAppointment(currentPage);
  };

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />

      {loading ? (
        <LoadingModal showModal={loading} />
      ) : (
        <>
          <div className={commonstyles.mr87}>
            <div className={styles.outerContainer}>
              <div>
                <div
                  className={classNames(
                    commonstyles.flxBetween,
                    commonstyles.mb32
                  )}
                >
                  <div className={commonstyles.flx}>
                    <p
                      className={classNames(
                        commonstyles.fs24,
                        commonstyles.semiBold,
                        commonstyles.colorBlue
                      )}
                    >
                      Appointment
                    </p>
                    <div className={styles.outerRefresh}>
                      <TbRefresh
                        className={styles.RefreshIcon}
                        style={{ transform: `rotate(${rotation}deg)` }}
                        onClick={handleRotate}
                      />
                    </div>
                  </div>
                  <NewPagination
                    onNext={handleNextPage}
                    onPrevious={handlePreviousPage}
                    startItem={(currentPage - 1) * itemsPerPage + 1}
                    endItem={Math.min(currentPage * itemsPerPage, totalItems)}
                    totalItems={totalItems}
                  />
                </div>
                {loading ? (
                  <LoadingModal showModal={loading} />
                ) : peramedicAppointments &&
                  peramedicAppointments.length > 0 ? (
                  <div className={styles.payment}>
                    <div className={styles.headerOuter}>
                      <p className={styles.headerclassStart}>Patient Name</p>
                      <p className={styles.headerclass}>Patient No.</p>
                      <p className={styles.headerclass}>Appt. Date</p>
                      <p className={styles.headerclass}>Appt.Time</p>
                      <p className={styles.headerclass}>Type</p>
                      <p className={styles.headerclass}>Action</p>
                    </div>
                    <div className={styles.tableData}>
                      <table style={{ margin: "0%" }}>
                        {peramedicAppointments.map((apt: any, index: any) => (
                          <tbody className={styles.wapper} key={index}>
                            <tr
                              className={styles.tableRow}
                              key={index}
                              onClick={() => handleGoAppDetail(apt?._id, index)}
                            >
                              <td className={styles.w20}>
                                <div className={styles.flxStart}>
                                  <Avatar
                                    className={styles.aavaatr}
                                    src={apt.patientId?.userImage}
                                  />
                                  <p style={{ textTransform: "capitalize" }}>
                                    {apt.patientId?.name}
                                  </p>
                                </div>
                              </td>
                              <td className={styles.w20}>
                                <div className={styles.flxCenter}>
                                  <MdPhone className={styles.iconss} />
                                  <p>{apt.patientId?.phone}</p>
                                </div>
                              </td>
                              <td className={styles.w20}>
                                <div className={styles.flxCenter}>
                                  <LuCalendarDays className={styles.iconss} />
                                  <p>
                                    {new Date(apt.updatedAt).toLocaleDateString(
                                      "en-US",
                                      {
                                        month: "2-digit",
                                        day: "2-digit",
                                        year: "numeric",
                                      }
                                    )}
                                  </p>
                                </div>
                              </td>
                              <td className={styles.w20}>
                                <div className={styles.flxCenter}>
                                  <FaRegClock className={styles.iconss} />
                                  <p>
                                    {apt.appointmentDateAndTime
                                      ? new Date(
                                          apt.appointmentDateAndTime
                                        ).toLocaleTimeString("en-US", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })
                                      : "__"}
                                  </p>
                                </div>
                              </td>

                              <td
                                className={styles.w20}
                                style={{ textTransform: "capitalize" }}
                              >
                                {apt.appointmentType}
                              </td>
                              <td className={styles.w20}>
                                <div className={commonstyles.flxCenter}>
                                  <div
                                    className={styles.iconBox}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleVideoModal();
                                    }}
                                  >
                                    <IoVideocam className={styles.Icccons} />
                                  </div>
                                  <div className={styles.iconBox}>
                                    <IoChatboxEllipses
                                      className={styles.Icccons}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className={classNames(styles.emptyOuter)}>
                    <DoctorEmpty />
                    {/* <img src={Empty} className={classNames(styles.empty3)} /> */}
                  </div>
                )}
              </div>
            </div>
          </div>

          <CustomModal showModal={videomodel} children={<VideoCall />} />
        </>
      )}
    </div>
  );
}

export default ParamedicAppointment;

export const VideoCall = () => {
  return <div>{/* <ZegoCloudComponent /> */}</div>;
};
