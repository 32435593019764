import classNames from "classnames";
import React from "react";
import SearchBar from "../Searchbar";
import commonstyles from "shared/utils/common.module.css";
import { IoArrowBack } from "react-icons/io5";
import Style from "./styles.module.css";
import moment from "moment";
import LabOrderItems from "../LabOrderItems";
import { useSelector } from "react-redux";
import Medicine_Table from "../Tables/MedicineTable";

const Order_Details = (props: any) => {
  const { data, handleGoToBack, title } = props;
  console.log("🚀 ~ data:", data);
  const { systemType } = useSelector((state: any) => state.root.common);
  const g_Total = systemType == "laboratory" ? data?.grandTotal : data?.amount;
  const payable_Amount =
    systemType == "laboratory" ? data?.totalAmount : data?.amount;

  return (
    <div className={commonstyles.mr87}>
      <div className={classNames(commonstyles.flxBetween, commonstyles.mb32)}>
        <div className={classNames(commonstyles.flx)}>
          <IoArrowBack className={Style.ArrowBack} onClick={handleGoToBack} />
          <p className={classNames(commonstyles.fs24)}>{title} Details</p>
        </div>
        <p className={classNames(commonstyles.fs18, Style.colorGreen)}>
          Order ID: {data?.orderId}
        </p>
      </div>

      <div
        className={classNames(
          commonstyles.flxBetween,
          Style.outerContainer,
          commonstyles.mb24
        )}
      >
        <div>
          <div className={classNames(commonstyles.flxBetween)}>
            <div
              className={classNames(commonstyles.flxBetween, commonstyles.mb24)}
              style={{ width: "450px" }}
            >
              <p className={classNames(commonstyles.fs14, commonstyles.medium)}>
                Order At
              </p>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
                style={{ marginLeft: "100px" }}
              >
                {moment(data?.createdAt).format("MM-DD-YYYY hh:mm A")}
              </p>
            </div>
          </div>

          {systemType == "laboratory" && (
            <>
              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.mb8
                )}
                style={{ width: "450px" }}
              >
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                >
                  MR No.
                </p>
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                  style={{ marginLeft: "100px" }}
                >
                  {data?.MR_NO}
                </p>
              </div>

              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.mb8
                )}
                style={{ width: "450px" }}
              >
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                >
                  Patient Name
                </p>
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                  style={{ marginLeft: "100px" }}
                >
                  {data?.customerName}
                </p>
              </div>
              <div
                className={classNames(commonstyles.flxBetween)}
                style={{ width: "450px" }}
              >
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                >
                  Preference
                </p>
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                  style={{ marginLeft: "100px" }}
                >
                  {data?.preference}
                </p>
              </div>
              <div
                className={classNames(commonstyles.flxBetween)}
                style={{ width: "450px" }}
              >
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                >
                  Address
                </p>
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                  style={{ marginLeft: "100px" }}
                >
                  {data?.currentLocation?.address}
                </p>
              </div>
            </>
          )}
        </div>

        <div>
          <div className={classNames(commonstyles.flxBetween)}>
            <div
              className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
              style={{ width: "450px" }}
            >
              <p className={classNames(commonstyles.fs14, commonstyles.medium)}>
                Grand Total
              </p>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
                style={{ marginLeft: "100px" }}
              >
                {g_Total}
              </p>
            </div>
          </div>

          {systemType === "laboratory" && (
            <div
              className={classNames(commonstyles.flxBetween, commonstyles.mb24)}
              style={{ width: "450px" }}
            >
              <p className={classNames(commonstyles.fs14, commonstyles.medium)}>
                Discount
              </p>
              <p
                className={classNames(commonstyles.fs14, commonstyles.medium)}
                style={{ marginLeft: "100px" }}
              >
                {data?.discount}
              </p>
            </div>
          )}

          <div className={classNames(commonstyles.mb8, Style.line)}></div>

          <div
            className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
            style={{ width: "450px" }}
          >
            <p className={classNames(commonstyles.fs14, commonstyles.medium)}>
              Payable Amount
            </p>
            <p
              className={classNames(commonstyles.fs14, commonstyles.medium)}
              style={{ marginLeft: "100px" }}
            >
              {payable_Amount}
            </p>
          </div>
        </div>
      </div>

      <div>
        {systemType === "laboratory" ? (
          <LabOrderItems data={data?.items} />
        ) : (
          <Medicine_Table
            data={[]}
            //  handleDelete={deleteEntry}
          />
        )}
      </div>
    </div>
  );
};

export default Order_Details;
