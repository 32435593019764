import classNames from "classnames";
import React, { useState } from "react";
import style from "./complete.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import { FaCheck } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { CustomModal, PrimaryButton } from "shared/components";
import { RxHome } from "react-icons/rx";
import { Navigate, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { Checkbox } from "@mui/material";

export default function CompleteHotel() {
  const [showAddProperty, setShowAddProperty] = useState(false);
  const navigate = useNavigate(); // Declare once
  const handleclose = () => {
    setShowAddProperty(false);
  };
  const handleGoMianPage = () => {
    navigate("/hotel/property/Main/Page");
  };
  return (
    <div className={classNames(commonstyles.colorBlue)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.firstcard}>
          <FaCheck className={style.check} />
          <p>
            Congratulation, it’s time to relax and put your feet up as you’ve
            completed your registration. For your records, we have also sent you
            an email confirming your registration, along with a separat email
            containing a copy of your agreement
          </p>
        </div>
        <div className={style.seconCard}>
          <p className={classNames(commonstyles.fs24, commonstyles.semiBold)}>
            You have done
          </p>
          <p className={classNames(commonstyles.fs16, style.mt16)}>
            You’ve just finished registering your property with Meditour.com, so
            take it easy and let us do the rest of the work. From here, the next
            steps are:
          </p>
          <div className={classNames(commonstyles.flx, style.mt16)}>
            <FaCheckCircle className={style.check} />
            <p>Your registration at meditour.co.</p>
          </div>
          <div className={classNames(commonstyles.flx, style.mt16)}>
            <FaCheckCircle className={style.check} />
            <p>We are reviewing your property.</p>
          </div>
          <p
            style={{ fontWeight: "600" }}
            className={classNames(commonstyles.fs16, style.mt32)}
          >
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.
          </p>
        </div>
        <div className={style.seconCard}>
          <div className={classNames(commonstyles.flx)}>
            <RxHome className={style.home} />
            <div>
              <p>Do you have other properties at different addresses?</p>
              <div style={{ width: "210px", marginTop: "16px" }}>
                <PrimaryButton
                  children={"Add another property"}
                  colorType={"blueOutline"}
                  onClick={() => setShowAddProperty(!showAddProperty)}
                />
              </div>
            </div>
            <CustomModal showModal={showAddProperty}>
              <div style={{ width: "600px" }}>
                <div
                  className={classNames(commonstyles.flx, style.boderbottom)}
                >
                  <p
                    className={classNames(
                      commonstyles.fs20,
                      commonstyles.semiBold
                    )}
                  >
                    Add another property
                  </p>
                  <IoClose onClick={handleclose} className={style.close} />
                </div>
                <p className={classNames(commonstyles.fs14, style.mt16)}>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived
                  not only five centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged.
                </p>

                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.col12,
                    commonstyles.colorBlue,
                    style.mt32
                  )}
                >
                  <Checkbox className={style.checkbox} />
                  <div className={classNames(commonstyles.flx)}>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mr8
                      )}
                    >
                      Cancellation policy
                    </p>
                    <p className={classNames(commonstyles.fs14)}>
                      All booking are 100% refundable
                    </p>
                  </div>
                </div>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.col12,
                    commonstyles.colorBlue
                  )}
                >
                  <Checkbox className={style.checkbox} />
                  <div className={classNames(commonstyles.flx)}>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mr8
                      )}
                    >
                      Check-in
                    </p>
                    <p className={classNames(commonstyles.fs14)}>
                      12:00 - 12:00
                    </p>
                  </div>
                </div>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.col12,
                    commonstyles.colorBlue
                  )}
                >
                  <Checkbox className={style.checkbox} />
                  <div className={classNames(commonstyles.flx)}>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mr8
                      )}
                    >
                      Check-out
                    </p>
                    <p className={classNames(commonstyles.fs14)}>11:00</p>
                  </div>
                </div>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.col12,
                    commonstyles.colorBlue
                  )}
                >
                  <Checkbox className={style.checkbox} />
                  <div className={classNames(commonstyles.flx)}>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mr8
                      )}
                    >
                      Guest payment options
                    </p>
                    <p className={classNames(commonstyles.fs14)}>
                      We will inform guest that you only accept cash.
                    </p>
                  </div>
                </div>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.col12,
                    commonstyles.colorBlue
                  )}
                >
                  <Checkbox className={style.checkbox} />
                  <div className={classNames(commonstyles.flx)}>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mr8
                      )}
                    >
                      Pets
                    </p>
                    <p className={classNames(commonstyles.fs14)}>
                      Pets are not allowed
                    </p>
                  </div>
                </div>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.col12,
                    commonstyles.colorBlue
                  )}
                >
                  <Checkbox className={style.checkbox} />
                  <div className={classNames(commonstyles.flx)}>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        style.mr8
                      )}
                    >
                      Language you (or your staff) speak
                    </p>
                    <p className={classNames(commonstyles.fs14)}>English</p>
                  </div>
                </div>
                <div className={classNames(commonstyles.flx, style.mt32)}>
                  <div className={style.mr16}>
                    <PrimaryButton
                      children={"Cancel"}
                      colorType={"blueOutline"}
                      onClick={handleclose}
                    />
                  </div>
                  <PrimaryButton
                    children={"Countinue"}
                    colorType={"blueOutline"}
                  />
                </div>
              </div>
            </CustomModal>
          </div>
        </div>
        <div style={{ width: "210px", marginTop: "16px" }}>
          <PrimaryButton
            children={"Main Page"}
            colorType={"greenOutline"}
            onClick={handleGoMianPage}
          />
        </div>
      </div>
    </div>
  );
}
