import React, { useEffect } from "react";
import classNames from "classnames";
import labMainStyles from "../../laboratoryMain.module.css";
import commonStyles from "shared/utils/common.module.css";
import { PrimaryButton } from "shared/components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomInput } from "shared/components";
import { labSocialSchema } from "shared/utils";
import { useDispatch, useSelector } from "react-redux";
import { setLabUserFormData } from "shared/redux";

interface Props {
  handleClickNext: any;
}
const Social = (props: Partial<Props>) => {
  const dispatch = useDispatch();
  const { labUserFormData } = useSelector((state: any) => state.root.lab);

  const { handleClickNext } = props;
  const formik = useFormik({
    initialValues: {
      fbUrl: "",
      instaUrl: "",
      twitterUrl: "",
      webUrl: "",
    },
    validationSchema: Yup.object(labSocialSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  useEffect(() => {
    formik.setValues(labUserFormData);
  }, []);
  const handleSubmit = async () => {
    await dispatch(setLabUserFormData({ ...formik.values }));
    handleClickNext();
  };

  return (
    <div className={classNames(commonStyles.col12)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, labMainStyles.flx)}>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Facebook Link             optional"
              id="fbUrl"
              name="fbUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.fbUrl}
            />
            {formik.touched.fbUrl && formik.errors.fbUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.fbUrl}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Instagram Link             optional"
              id="instaUrl"
              name="instaUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.instaUrl}
            />
            {formik.touched.instaUrl && formik.errors.instaUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.instaUrl}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(labMainStyles.flx, commonStyles.mb16)}>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Twitter Link               optional"
              id="twitterUrl"
              name="twitterUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.twitterUrl}
            />
            {formik.touched.twitterUrl && formik.errors.twitterUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.twitterUrl}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Website Link              optional"
              id="webUrl"
              name="webUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.webUrl}
            />
            {formik.touched.webUrl && formik.errors.webUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.webUrl}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(labMainStyles.buttonWidth)}>
          <PrimaryButton children={"Next"} type="submit" />
        </div>
      </form>
    </div>
  );
};

export default Social;
