import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import styles from "./test.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import SearchBar from "shared/components/Searchbar/index";
import commonStyles from "shared/utils/common.module.css";
import commonstyle from "shared/utils/common.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import style from "./test.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import {
  ArrowBack,
  CustomInput,
  CustomModal,
  CustomStepper,
  LoadingModal,
  PrimaryButton,
  RingLoader,
} from "shared/components";
import { IoClose } from "react-icons/io5";
import { BiSolidEdit } from "react-icons/bi";
import { Typography } from "@mui/material";
import CustomSelect from "shared/components/CustomSelect";
import {
  LabGetTestCategory,
  LabTestDELETE,
  LabTestDetails,
  LabTestEdit,
} from "shared/services";
import { labAddTestInfo, labAddTestPrice } from "shared/utils";
import { useDispatch, useSelector } from "react-redux";
import { setTestsss } from "shared/redux";
const steps = [
  {
    id: "1",
    lable: "Test Info",
  },
  {
    id: "2",
    lable: "Test price",
  },
];

interface Props {
  setShowAddModal: any;
  id: any;
}
const TestConfirmDelete = (props: Partial<Props>) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { tests } = useSelector((state: any) => state.root.lab);

  const dispatch = useDispatch();

  const deleteTest = () => {
    let test_clone = JSON.parse(JSON.stringify(tests));

    setLoading(true);
    const testId = id || "";
    LabTestDELETE(testId)
      .then((res: any) => {
        if (res?.status === 200) {
          test_clone?.map((item: any, ind: any) => {
            if (id == item?._id) {
              test_clone.splice(ind, 1);
            }
          });
          dispatch(setTestsss(test_clone));
          navigate("/laboratory/test");
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const { setShowAddModal, id } = props;
  return (
    <div className={styles.modelwidth}>
      <div className={styles.end}>
        <IoClose
          className={styles.close}
          onClick={() => setShowAddModal(false)}
        />
      </div>
      <p
        className={classNames(
          commonstyle.fs16,
          commonstyle.semiBold,
          styles.mt16,
          commonstyle.colorBlue,
          styles.textjustifys
        )}
      >
        Are you sure you want to delete this Test?
      </p>
      <div className={classNames(commonstyle.flxBetween, styles.mt32)}>
        <button
          disabled={loading}
          onClick={() => setShowAddModal(false)}
          className={styles.cancel}
        >
          Cancel
        </button>

        <button
          className={styles.btnDelete}
          onClick={deleteTest}
          disabled={loading}
        >
          {loading ? <RingLoader size={35} color={"#fff"} /> : "Delete"}
        </button>
      </div>
    </div>
  );
};

interface EditProps {
  setShowEditModel: any;
  editData?: any;
  TestDetails: TestiDetails | null;
  id?: any;
  setTestDetails?: any;
}

const TestEdit = (props: Partial<EditProps>) => {
  const [loading, setLoading] = useState(false);
  const { setShowEditModel, editData, TestDetails, id, setTestDetails } = props;
  const [screenName, setScreenName] = useState("TestInfo");
  const [Test, setTest] = useState([]);
  const [selectedStep, setSelectedStep] = useState(0);
  const [addData, setAddData] = useState({});
  const [testcategory, setTestCategory] = useState([]);

  const handleClickNext = () => {
    if (screenName === "TestInfo") {
      setScreenName("Testprice");
    }
    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "Testprice") {
      setScreenName("TestInfo");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };

  const handleCloseModal = () => {
    setShowEditModel(false);
  };
  const fetchTests = () => {
    setLoading(true);
    LabGetTestCategory()
      .then((res: any) => {
        // console.log("data...........................", res);
        if (res?.data?.auth) {
          const categoryNames = res.data.testCategories.map(
            (category: any) => category.categoryName
          );
          setTestCategory(categoryNames);
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
        // setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTests();
  }, []);
  return loading ? (
    <LoadingModal showModal={loading} />
  ) : (
    <Typography
      id="modal-modal-description"
      sx={{ textAlign: "center", color: "#001F57" }}
    >
      <div className={commonstyle.flx}>
        <div className={commonstyle.flx} style={{ width: "97%" }}>
          <ArrowBack onClick={handleClicKPrev} />
          <p className={classNames(commonstyle.semiBold, commonstyle.fs16)}>
            Previous
          </p>
        </div>
        <div className={styles.end}>
          <button className={styles.close} onClick={handleCloseModal}>
            &#10006;
          </button>
        </div>
      </div>

      <div style={{ marginTop: "30px" }}>
        <CustomStepper steps={steps} selectedStep={selectedStep} />
      </div>
      <div>
        {screenName == "TestInfo" && (
          <TestInfo
            handleClickNext={handleClickNext}
            setAddData={setAddData}
            TestDetails={TestDetails}
            testcategory={testcategory}
          />
        )}
        {screenName == "Testprice" && (
          <Testprice
            handleClickNext={handleClickNext}
            addData={addData}
            setAddData={setAddData}
            setShowEditModel={setShowEditModel}
            TestDetails={TestDetails}
            setTestDetails={setTestDetails}
            id={id}
          />
        )}
      </div>
      <div
        style={{ width: "210px", marginTop: "56px" }}
        className={styles.start}
      >
        {/* <button
        onClick={handleClickNext}
        style={{ fontSize: "16px", fontWeight: "600" }}
        className={styles.btnx}
      >
        Next
      </button> */}
      </div>
    </Typography>
  );
};

interface Props {
  handleClickNext: any;
  setAddData: any;
  addData: any;
  setShowEditModel: any;
  TestDetails?: TestiDetails | null; // Add this line
  testcategory: any;
}
const TestInfo = (props: Partial<Props>) => {
  const {
    handleClickNext,
    setAddData,
    setShowEditModel,
    TestDetails,
    testcategory,
  } = props;
  const formik = useFormik({
    initialValues: {
      testAddTest_TestName: TestDetails?.testName || "",
      testAddTest_TestCategory: TestDetails?.categoryName || "",
      testAddTest_TestDescription: TestDetails?.testDescription || "",
    },
    validationSchema: Yup.object(labAddTestInfo),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = async () => {
    console.log("Submit");
    const currentData = formik.values;
    handleClickNext();
    setAddData({
      testName: currentData.testAddTest_TestName,
      categoryName: currentData.testAddTest_TestCategory,
      testDescription: currentData.testAddTest_TestDescription,
    });
  };

  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("testAddTest_TestCategory", selectedOption);
  };
  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ marginTop: "56px" }} className={styles.flx}>
            <div style={{ width: "213px" }}>
              <CustomInput
                placeholder="Test Name"
                id="testAddTest_TestName"
                name="testAddTest_TestName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.testAddTest_TestName}
              />

              {formik.touched.testAddTest_TestName &&
              formik.errors.testAddTest_TestName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.testAddTest_TestName}
                </div>
              ) : null}
            </div>
            <div style={{ width: "213px", marginLeft: "83px" }}>
              <CustomSelect
                placeholder={
                  TestDetails ? TestDetails?.categoryName : "TestCategory"
                }
                options={testcategory}
                onSelect={handleSelect}
                value={formik.values.testAddTest_TestCategory}
              />
              {formik.touched.testAddTest_TestCategory &&
              formik.errors.testAddTest_TestCategory ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.testAddTest_TestCategory}
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.description}>
            <textarea
              style={{ resize: "none", borderRadius: "8px" }}
              placeholder="Test Description"
              id="testAddTest_TestDescription"
              name="testAddTest_TestDescription"
              onChange={formik.handleChange}
              value={formik.values.testAddTest_TestDescription}
            ></textarea>

            {formik.touched.testAddTest_TestDescription &&
            formik.errors.testAddTest_TestDescription ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.testAddTest_TestDescription}
              </div>
            ) : null}
          </div>
          <div style={{ marginTop: "56px", width: "210px" }}>
            <PrimaryButton
              children={"Next Step"}
              type="submit"
              colorType={"blue"}
            />
          </div>
        </form>
      </div>
    </>
  );
};

interface Props {
  handleClickNext: any;
  addData: any;
  setShowEditModel: any;
  Test: any;
  setTest: any;
  TestDetails?: TestiDetails | null;
  setTestDetails?: any;
}
const Testprice = (props: Partial<Props>) => {
  const {
    handleClickNext,
    addData,
    Test,
    setShowEditModel,
    setTest,
    TestDetails,
    setTestDetails,
    id,
  } = props;
  const { tests } = useSelector((state: any) => state.root.lab);
  const ID = TestDetails?._id || "";
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      testprice_TestPrice: TestDetails?.price || "",
      testprice_TestDuration: TestDetails?.duration || "",
      testprice_PriceForMeditour: TestDetails?.priceForMeditour || "",
    },
    validationSchema: Yup.object(labAddTestPrice),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async () => {
    let test_clone = JSON.parse(JSON.stringify(tests));
    const curr_data = formik.values;
    handleClickNext();
    setLoading(true);

    let tempMed = Array.isArray(Test) ? [...Test] : [];

    let params = {
      ...addData,
      price: curr_data.testprice_TestPrice,
      duration: curr_data.testprice_TestDuration,
      priceForMeditour: curr_data.testprice_PriceForMeditour,
    };

    LabTestEdit(ID || "", params)
      .then((res: any) => {
        handleClickNext();
        if (res.status === 200) {
          test_clone?.map((item: any, ind: any) => {
            setTestDetails(res?.data?.test);

            if (id == item?._id) {
              test_clone[ind] = res?.data?.test;
            }
          });
          dispatch(setTestsss(test_clone));
        }
        setShowEditModel(false);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ marginTop: "56px" }} className={styles.flx}>
            <div style={{ width: "213px" }}>
              <CustomInput
                placeholder="Price"
                id="testprice_TestPrice"
                name="testprice_TestPrice"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.testprice_TestPrice}
              />

              {formik.touched.testprice_TestPrice &&
              formik.errors.testprice_TestPrice ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.testprice_TestPrice}
                </div>
              ) : null}
            </div>
            <div style={{ width: "213px", marginLeft: "83px" }}>
              <CustomInput
                placeholder="Duration"
                id="testprice_TestDuration"
                name="testprice_TestDuration"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.testprice_TestDuration}
              />

              {formik.touched.testprice_TestDuration &&
              formik.errors.testprice_TestDuration ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.testprice_TestDuration}
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ marginTop: "32px" }} className={styles.flx}>
            <div style={{ width: "213px" }}>
              <CustomInput
                placeholder="Price For Meditour"
                id="testprice_PriceForMeditour"
                name="testprice_PriceForMeditour"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.testprice_PriceForMeditour}
              />

              {formik.touched.testprice_PriceForMeditour &&
              formik.errors.testprice_PriceForMeditour ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.testprice_PriceForMeditour}
                </div>
              ) : null}
            </div>
          </div>

          <div style={{ marginTop: "56px", width: "210px" }}>
            <PrimaryButton
              children={loading ? "loading..." : "Save"}
              disabled={loading ? true : false}
              type="submit"
              colorType={"green"}
            />
          </div>
        </form>
      </div>
    </>
  );
};

interface TestiDetails {
  testCode: number;
  testName: string;
  priceForMeditour: number;
  price: number;
  testDescription: string;
  categoryName: string;
  duration: number;
  _id: string;
}

export default function TestDetail(props: Partial<Props>) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const handleBackToTestTable = () => {
    navigate("/laboratory/test");
  };

  const handleOpenModal = () => {
    setShowAddModal(true);
  };
  const handleEditModel = () => {
    setShowEditModel(true);
  };

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [TestDetails, setTestDetails] = useState<TestiDetails | null>(null);

  const TestDetail = () => {
    setLoading(true);
    if (id === undefined) {
      console.error("ID is undefined");
      setLoading(false);
      return;
    }
    if (id) {
      // console.log("ID value:", id);

      LabTestDetails(id)
        .then((res: any) => {
          console.log("API Response:::::::::::::::::", res);
          setTestDetails(res?.data.test);
        })
        .catch((err: any) => {
          console.log("API Error:", err?.response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
    }
  };

  useEffect(() => {
    TestDetail();
  }, []);

  const navigate = useNavigate();
  const handleGoToBack = () => {
    navigate("/ambulance/ambulances");
  };
  return (
    <>
      {loading ? (
        <LoadingModal showModal={loading} />
      ) : (
        <div className={classNames(commonstyle.col12)}>
          <SearchBar />

          <div className={classNames(commonstyle.mr87, commonstyle.colorBlue)}>
            <div className={styles.outerContainer}>
              <div className={commonstyle.flx}>
                <div className={commonstyle.flx}>
                  <IoMdArrowRoundBack
                    className={style.back}
                    onClick={handleBackToTestTable}
                  />
                  <p
                    className={classNames(
                      commonstyle.fs20,
                      commonstyle.semiBold
                    )}
                  >
                    Back
                  </p>
                </div>
                <div
                  style={{ marginLeft: "auto" }}
                  className={classNames(commonstyle.flx, style.end)}
                >
                  <div className={styles.buttoons}>
                    <BiSolidEdit
                      className={styles.reset}
                      onClick={handleEditModel}
                    />
                  </div>
                  <div className={styles.buttoons}>
                    <MdDeleteOutline
                      className={styles.reset}
                      onClick={handleOpenModal}
                    />
                  </div>
                </div>
              </div>
              <CustomModal
                showModal={showAddModal}
                children={
                  <TestConfirmDelete
                    setShowAddModal={setShowAddModal}
                    id={id}
                  />
                }
              />
              <CustomModal
                showModal={showEditModel}
                children={
                  <TestEdit
                    setShowEditModel={setShowEditModel}
                    TestDetails={TestDetails}
                    id={id}
                    setTestDetails={setTestDetails}
                  />
                }
              />

              <div className={classNames(style.mt32)}>
                <p
                  className={classNames(commonstyle.fs24, commonstyle.semiBold)}
                >
                  Test Detail
                </p>
              </div>
              <div
                className={classNames(
                  style.mt32,
                  commonstyle.flx,
                  commonstyle.fs20
                )}
              >
                <div className={classNames(commonstyle.col6)}>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Test Code:
                    </p>
                    <p>{TestDetails?.testCode}</p>
                  </div>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Test Name:
                    </p>
                    <p>{TestDetails?.testName}</p>
                  </div>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Test Category:
                    </p>
                    <p>{TestDetails?.categoryName}</p>
                  </div>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Meditour Price:
                    </p>
                    <p>{TestDetails?.priceForMeditour}</p>
                  </div>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Test Price:
                    </p>
                    <p>{TestDetails?.price}</p>
                  </div>
                </div>
              </div>
              <div className={style.mt32}>
                <p
                  className={classNames(commonstyle.fs24, commonstyle.semiBold)}
                >
                  Test Description
                </p>
                <p className={classNames(commonstyle.fs16, style.mt16)}>
                  {TestDetails?.testDescription}
                </p>
              </div>
              {/* <div className={style.btnWidth}>
              <PrimaryButton children={"Save"} colorType={"green"} />
            </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
