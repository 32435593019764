import React, { useEffect } from "react";
import classNames from "classnames";
import phrMainStyles from "../../pharmacyMain.module.css";
import commonStyles from "shared/utils/common.module.css";
import { pharmacySocialSchema } from "shared/utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "shared/components";
import { ArrowBack, CustomInput } from "shared/components";
import { useDispatch, useSelector } from "react-redux";
import { setPharmacyUserFormData } from "shared/redux";
interface Props {
  handleClickNext: any;
}

const PharmacySocial = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const { pharmacyUserFormData } = useSelector(
    (state: any) => state.root.pharmacy
  );
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      pharmacyfbUrl: "",
      pharmacyinstaUrl: "",
      pharmacytwitterUrl: "",
      pharmacywebUrl: "",
    },
    validationSchema: Yup.object(pharmacySocialSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  useEffect(() => {
    formik.setValues(pharmacyUserFormData);
  }, []);
  const handleSubmit = async () => {
    await dispatch(setPharmacyUserFormData({ ...formik.values }));
    handleClickNext();
  };

  return (
    <div className={classNames(commonStyles.col12)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, phrMainStyles.flx)}>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Facebook Link  (optinal)"
              id="pharmacyfbUrl"
              name="pharmacyfbUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.pharmacyfbUrl}
            />

            {formik.touched.pharmacyfbUrl && formik.errors.pharmacyfbUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.pharmacyfbUrl}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Instagram Link  (optinal)"
              id="pharmacyinstaUrl"
              name="pharmacyinstaUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.pharmacyinstaUrl}
            />
            {formik.touched.pharmacyinstaUrl &&
            formik.errors.pharmacyinstaUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.pharmacyinstaUrl}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(phrMainStyles.flx)}>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Twitter Link  (optinal)"
              id="pharmacytwitterUrl"
              name="pharmacytwitterUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.pharmacytwitterUrl}
            />

            {formik.touched.pharmacytwitterUrl &&
            formik.errors.pharmacytwitterUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.pharmacytwitterUrl}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Website Link  (optinal)"
              id="pharmacywebUrl"
              name="pharmacywebUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.pharmacywebUrl}
            />

            {formik.touched.pharmacywebUrl && formik.errors.pharmacywebUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.pharmacywebUrl}
              </div>
            ) : null}
          </div>
        </div>
        <div className={phrMainStyles.buttonWidth}>
          <PrimaryButton children={"Next Step"} type="submit"  colorType={"SkyBlueOutine"}/>
        </div>
      </form>
    </div>
  );
};

export default PharmacySocial;
