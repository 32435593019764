import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Login, Signup } from "pages/Laboratory";
import ForgetPassword from "pages/Laboratory/Forgetpassword/forgetpassword";
import LabUpdatePassword from "pages/Laboratory/Forgetpassword/LabUpdatePassword";

const LaboratoryAuthRoutes = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route path="/update-password" element={<LabUpdatePassword />} />
    </Routes>
  );
};

export default LaboratoryAuthRoutes;
