import { setIsLoggedIn, setToken, setUser, store } from "shared/redux";
import axios, { AxiosInstance } from "axios";
import { BASE_URL } from "shared/utils";

export const HTTP_CLIENT: AxiosInstance = axios.create({
  baseURL: BASE_URL,
});

export const interceptorConfig = (setLogOutFlag: any) => {
  HTTP_CLIENT.interceptors.request.use(
    (config: any) => {
      const { token, isLoggedIn } = store.getState().root.common;
      // const { authToken } = store.getState().root.user;

      // config.headers = {
      //   "x-access-token": "PLASTK",
      // };

      if (isLoggedIn) {
        // console.log("Token...........", token);
        config.headers.Authorization = `Bearer ${token}`;
      }
      // console.log("config..........", config);
      return config;
    },
    (err: any) => {
      Promise.reject(err);
    }
  );

  HTTP_CLIENT.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (error: any) => {
      // Handle error responses
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(`Error: ${error.response.status}`);

        if (error.response.status == 401) {
          setLogOutFlag(true);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error: No response received");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
      }

      return Promise.reject(error);
    }
  );
};
