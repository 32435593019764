import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import { CustomInput, CustomModal, PrimaryButton } from "shared/components";
import { CheckBox } from "@mui/icons-material";
import empty from "assets/images/empty2.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "@mui/material";
import { orange } from "@mui/material/colors";
import style from "./layout&Price.module.css";
import { PiPlusSquareFill } from "react-icons/pi";
import CustomSelect from "shared/components/CustomSelect";
import { setHotelAddAppartment } from "shared/redux";
import { hotelAddAppartmentSchema, hotelAddHomeLayout } from "shared/utils";
import { IoClose } from "react-icons/io5";
import ImagePickerNew from "shared/components/FilePickeInsurance/ImagePickerNew";
import ImgPicker from "shared/components/Img-picker";
import Layoutslider from "./slider";
import ShortImagePicker from "shared/components/FilePickeInsurance/ShortImageFilePicker";
const BedsOptions = [
  "Single bed(s)  / 90-130 cm wide ",
  "Double bed(s) / 131-150 cm wide",
  "Large bed(s) (King size) / 151-180 cm wide",
  "Extra-large double bed(s) (Super-King size) / 181-210 cm wide",
  "Bunk bed(s) / Variable Size",
  "Sofa bed(s) / Variable Size",
  "Futon Mat(s) / Variable Size",
];
const Apartments = [
  "Apartment - Ground Floor  No.2",
  "Apartment - Split Level  No.3",
  "Apartment - Shared Bathroom  No.4",
  "Apartment - with Balcony  No.5",
  "Apartment - with Garden View  No.6",
  "Apartment - with Lake View  No.7",
  "Apartments - Mountain View  No.8",
];
const Numberofbed = ["1", "2", "3", "4", "5"];
const livingrooms = ["1", "2", "3", "4", "5"];
const bathroom = ["1", "2", "3", "4", "5"];
const rooms = ["1", "2", "3", "4", "5"];
const Singlebed = ["1", "2", "3", "4", "5"];
const numberofbed = ["1", "2", "3", "4", "5"];
const guest = ["Yes", "No"];
const Square = ["1", "2", "3", "4", "5"];
const Sofabeds = ["1", "2", "3", "4", "5"];
const manyguest = ["1", "2", "3", "4", "5"];
interface Props {
  handleClickNext: any;
}
interface Apartment {
  appartmentNo: string;
  appartmentName: string;
  numberOfBedroom: string;
  numberOfDiningrooms: string;
  numberOfBathroom: string;
  kitchens: string;
  numberOfAppartments: string;
  beds: { type: string }[];
  totalStayingGuests: number;
  privateBathroom: boolean;
  breakfast: string;
  numberOfSofaBed: string;
  noOfStayingGuestsInCommonSpace: string;
  appartmentSize: string;
  basePricePerNight: string;
  priceForMeditour: string;
}

export default function LayoutPrice(props: Partial<Props>) {
  const { hotelAddAppartment } = useSelector((state: any) => state.root.hotel);
  const dispatch = useDispatch();
  const [error, setError] = React.useState("");
  const [departmentCard, setDepartmentCard] = useState<Apartment[]>([]);
  const [showPackageModal, setShowPackageModal] = useState(false);
  const { handleClickNext } = props;

  const handleModelOpen = () => {
    setShowPackageModal(true);
  };

  useEffect(() => {
    console.log("departmentCard..............", departmentCard);
  }, [departmentCard]);

  const handleNextScreen = () => {
    if (departmentCard.length === 0) {
      alert("Please Add Apartment");
    } else {
      dispatch(
        setHotelAddAppartment({
          ...hotelAddAppartment,
          apartments: departmentCard,
        })
      );
      handleClickNext();
    }
  };

  return (
    <div className={classNames(commonstyles.col12)}>
      <div className={classNames(commonstyles.flx)}>
        {departmentCard.length > 0 &&
          departmentCard.map((data, index) => (
            <div key={index} className={style.layoutCard}>
              <Layoutslider />
              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.fs14,
                  style.mt24
                )}
              >
                <div>
                  <p className={commonstyles.regular}>Apartment No</p>
                  <p className={commonstyles.medium}>{data.appartmentNo}</p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p className={commonstyles.regular}>Custom Name</p>
                  <p className={commonstyles.medium}>{data.appartmentNo}</p>
                </div>
                <div style={{ textAlign: "end" }}>
                  <p className={commonstyles.regular}>Bed Rooms</p>
                  <p className={commonstyles.medium}>
                    {data.appartmentNo} rooms
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.fs14,
                  style.mt24
                )}
              >
                <div>
                  <p className={commonstyles.regular}>Dining rooms</p>
                  <p className={commonstyles.medium}>
                    {data.numberOfDiningrooms} rooms
                  </p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p className={commonstyles.regular}>Kitchen</p>
                  <p className={commonstyles.medium}>{data.kitchens}</p>
                </div>
                <div style={{ textAlign: "end" }}>
                  <p className={commonstyles.regular}>Bathroom</p>
                  <p className={commonstyles.medium}>{data.numberOfBathroom}</p>
                </div>
              </div>
              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.fs14,
                  style.mt24
                )}
              >
                <div>
                  <p className={commonstyles.regular}>Total Apartments</p>
                  <p className={commonstyles.medium}>
                    {data.numberOfAppartments}
                  </p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p className={commonstyles.regular}>Available Beds</p>
                  <p className={classNames(commonstyles.medium)}>
                    {data.beds.map((bed, index) => (
                      <span key={index}>{bed.type} </span>
                    ))}
                  </p>
                </div>
                <div style={{ textAlign: "end" }}>
                  <p className={commonstyles.regular}>No. of Beds</p>
                  <p className={commonstyles.medium}>{data.numberOfBedroom}</p>
                </div>
              </div>
              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.fs14,
                  style.mt24
                )}
              >
                <div>
                  <p className={commonstyles.regular}>Total Guest stay</p>
                  <p className={commonstyles.medium}>
                    {data.totalStayingGuests}
                  </p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p className={commonstyles.regular}>Private Bathroom</p>
                  <p className={commonstyles.medium}>
                    {data.privateBathroom ? "Yes" : "No"}
                  </p>
                </div>
                <div style={{ textAlign: "end" }}>
                  <p className={commonstyles.regular}>Breakfast</p>
                  <p className={commonstyles.medium}>{data.breakfast}</p>
                </div>
              </div>
              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.fs18,
                  style.mt24
                )}
              >
                <p className={commonstyles.semiBold}>Common Space</p>
                <p className={commonstyles.semiBold}>Size</p>
              </div>
              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.fs14,
                  style.mt8
                )}
              >
                <div>
                  <p className={commonstyles.regular}>No of Sofa beds</p>
                  <p className={commonstyles.medium}>{data.numberOfSofaBed}</p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p className={commonstyles.regular}>Guest can stay</p>
                  <p className={commonstyles.medium}>
                    {data.noOfStayingGuestsInCommonSpace}
                  </p>
                </div>
                <div style={{ textAlign: "end" }}>
                  <p className={commonstyles.regular}>Apartment Size</p>
                  <p className={commonstyles.medium}>
                    {data.appartmentSize} sq
                  </p>
                </div>
              </div>
              <div className={classNames(commonstyles.fs18, style.mt24)}>
                <p className={commonstyles.semiBold}>Price Per Night</p>
              </div>
              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.fs14,
                  style.mt8
                )}
              >
                <div>
                  <p className={commonstyles.regular}>Basic Price per Night</p>
                  <p className={commonstyles.medium}>
                    {data.basePricePerNight}/- per night
                  </p>
                </div>
                <div style={{ textAlign: "end" }}>
                  <p className={commonstyles.regular}>Price for Meditour</p>
                  <p className={commonstyles.medium}>
                    {data.priceForMeditour}/- per night
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div>
        <div className={style.buttonWidth}>
          <PrimaryButton
            children={"Add Apartment"}
            colorType={"blue"}
            onClick={handleModelOpen}
          />
        </div>
        <div className={style.buttonNext}>
          <PrimaryButton
            children={"Next"}
            colorType={"blue"}
            onClick={handleNextScreen}
          />
        </div>
      </div>

      <CustomModal
        showModal={showPackageModal}
        children={
          <Apartment
            setShowPackageModal={setShowPackageModal}
            setDepartmentCard={setDepartmentCard}
          />
        }
      />
    </div>
  );
}

interface PacProps {
  setShowPackageModal: any;
  setDepartmentCard: any;
}
const Apartment = (props: Partial<PacProps>) => {
  const [error, setError] = React.useState("");
  const [imageURLs, setImageURLs] = useState<string[]>([]);
  const dispatch = useDispatch();
  const [selectedBeds, setSelectedBeds] = useState<
    { availableBeds: string; noOfBeds: string }[]
  >([]);
  const { hotelAddAppartment } = useSelector((state: any) => state.root.hotel);
  // console.log("layout Price issss", hotelAddAppartment);
  const [isPrivateBathroom, setIsPrivateBathroom] = useState(false);
  const { setShowPackageModal, setDepartmentCard } = props;

  const handleCloseModal = () => {
    setShowPackageModal(false);
  };

  const handleCheckboxChange = (event: any) => {
    setIsPrivateBathroom(event.target.checked);
    formik.setFieldValue("privateBathroom", isPrivateBathroom);
  };
  const handleHomeSelect = (selectedOption: string) => {
    formik.setFieldValue("appartmentNo", selectedOption);
  };
  const handleBedRoomSelect = (selectedOption: string) => {
    formik.setFieldValue("numberofBedRoom", selectedOption);
  };
  const handleDiningRoomSelect = (selectedOption: string) => {
    formik.setFieldValue("numberofDiningRoom", selectedOption);
  };
  const handleBathRoomSelect = (selectedOption: string) => {
    formik.setFieldValue("numberofBathRoom", selectedOption);
  };
  const handleKitchensSelect = (selectedOption: string) => {
    formik.setFieldValue("howmanyKitchens", selectedOption);
  };
  const handlenoofApartments = (selectedOption: string) => {
    formik.setFieldValue("noofApartments", selectedOption);
  };
  const handleGuestStayinthisAppartment = (selectedOption: string) => {
    formik.setFieldValue("guestStayinthisAppartment", Number(selectedOption));
  };
  const handleBreakfastInclude = (selectedOption: string) => {
    formik.setFieldValue("breakfastInclude", selectedOption);
  };
  const handleCommonSofaSelect = (selectedOption: string) => {
    formik.setFieldValue("numberofSofabedinAppartment", selectedOption);
  };
  const handleCommonGuestSelect = (selectedOption: string) => {
    formik.setFieldValue("totalGuestStay", selectedOption);
  };
  const handleAppartmentSizeSelect = (selectedOption: string) => {
    formik.setFieldValue("appartmentSize", selectedOption);
  };
  // const handleBedsOptions = (selectedOption: string) => {
  //   console.log("Selected Option:", selectedOption);
  //   formik.setFieldValue("selectBeds", selectedOption);
  // };
  // const handlenoofBeds = (selectedOption: string) => {
  //   formik.setFieldValue("noOfBeds", selectedOption);
  // };

  const firstImageURL = (url: any) => {
    console.log("......URL IS......", url);
    if (imageURLs.length < 3) {
      setImageURLs((prevURLs) => [...prevURLs, url]);
    } else {
      setError("Maximum limit reached. Can't add more Image.");
    }
  };

  const handleAddBed = () => {
    if (selectedBeds.length < 3) {
      setSelectedBeds((prevSelectedBeds) => [
        ...prevSelectedBeds,
        { availableBeds: "", noOfBeds: "" },
      ]);
    }
  };

  const handleBedKindSelect = (selectedOption: string, index: number) => {
    const updatedBeds = [...selectedBeds];
    updatedBeds[index].availableBeds = selectedOption;
    setSelectedBeds(updatedBeds);
  };

  const handleNoOfBedsSelect = (selectedOption: string, index: number) => {
    const updatedBeds = [...selectedBeds];
    updatedBeds[index].noOfBeds = selectedOption;
    setSelectedBeds(updatedBeds);
  };
  const formik = useFormik({
    initialValues: {
      appartmentNo: "",
      customName: "",
      numberofBedRoom: "",
      numberofDiningRoom: "",
      numberofBathRoom: "",
      howmanyKitchens: "",
      noofApartments: "",
      guestStayinthisAppartment: "",
      breakfastInclude: "",
      privateBathroom: "",
      numberofSofabedinAppartment: "",
      totalGuestStay: "",
      appartmentSize: "",
      basicpricePerNight: "",
      // pricepernightformeditour: "",
    },
    validationSchema: Yup.object(hotelAddAppartmentSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    console.log("Submit");
    const CurrentData = formik.values;

    let params = {
      appartmentNo: CurrentData.appartmentNo,
      appartmentName: CurrentData.customName,
      numberOfBedroom: CurrentData.numberofBedRoom,
      numberOfDiningrooms: CurrentData.numberofDiningRoom,
      numberOfBathroom: CurrentData.numberofBathRoom,
      kitchens: CurrentData.howmanyKitchens,
      numberOfAppartments: CurrentData.noofApartments,
      beds: selectedBeds,
      totalStayingGuests: CurrentData.guestStayinthisAppartment,
      breakfast: CurrentData.breakfastInclude,
      privateBathroom: CurrentData.privateBathroom,
      numberOfSofaBed: CurrentData.numberofSofabedinAppartment,
      noOfStayingGuestsInCommonSpace: CurrentData.totalGuestStay,
      appartmentSize: CurrentData.appartmentSize,
      basePricePerNight: CurrentData.basicpricePerNight,
      // priceForMeditour: CurrentData.pricepernightformeditour,
      appartmentImages: imageURLs,
    };
    if (setDepartmentCard) setDepartmentCard(params);
    setShowPackageModal(false);

    console.log("Formik values", formik.values);
  };

  useEffect(() => {
    console.log("Add Data.............", formik.values);
    console.log("selectedBeds..............", selectedBeds);
  }, [formik.values, selectedBeds]);
  return (
    <div style={{ width: "907px" }}>
      <div className={style.mainDivModal}>
        <div className={style.modelHeader}>
          <p>Apartment </p>
          <IoClose className={style.end} onClick={handleCloseModal} />
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
            <div className={commonstyles.col4}>
              <div style={{ marginRight: "12px" }}>
                <CustomSelect
                  options={Apartments}
                  placeholder="Apartments No"
                  onSelect={handleHomeSelect}
                />
                {formik.touched.appartmentNo && formik.errors.appartmentNo ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.appartmentNo}
                  </div>
                ) : null}
              </div>
            </div>

            <div className={classNames(commonstyles.col4)}>
              <div style={{ marginRight: "12px" }}>
                <CustomInput
                  placeholder="Custom Name"
                  id="customName"
                  name="customName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.customName}
                />
                {formik.touched.customName && formik.errors.customName ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.customName}
                  </div>
                ) : null}
              </div>
            </div>

            <div className={classNames(commonstyles.col4)}>
              <div style={{ marginRight: "12px" }}>
                <CustomSelect
                  options={Numberofbed}
                  placeholder="Number of bed rooms"
                  onSelect={handleBedRoomSelect}
                />
                {formik.touched.numberofBedRoom &&
                formik.errors.numberofBedRoom ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.numberofBedRoom}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
            <div className={commonstyles.col4}>
              <div style={{ marginRight: "12px" }}>
                <CustomSelect
                  options={livingrooms}
                  placeholder="Number of dining rooms"
                  onSelect={handleDiningRoomSelect}
                />
                {formik.touched.numberofDiningRoom &&
                formik.errors.numberofDiningRoom ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.numberofDiningRoom}
                  </div>
                ) : null}
              </div>
            </div>

            <div className={classNames(commonstyles.col4)}>
              <div style={{ marginRight: "12px" }}>
                <CustomSelect
                  options={bathroom}
                  placeholder="Number of bathroom"
                  onSelect={handleBathRoomSelect}
                />
                {formik.touched.numberofBathRoom &&
                formik.errors.numberofBathRoom ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.numberofBathRoom}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={commonstyles.col4}>
              <div style={{ marginRight: "12px" }}>
                <CustomSelect
                  options={rooms}
                  placeholder="How many kitchens"
                  onSelect={handleKitchensSelect}
                />
                {formik.touched.howmanyKitchens &&
                formik.errors.howmanyKitchens ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.howmanyKitchens}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
            <div className={commonstyles.col4}>
              <div style={{ marginRight: "12px" }}>
                <CustomSelect
                  options={livingrooms}
                  placeholder="No of Apartments"
                  onSelect={handlenoofApartments}
                />
                {formik.touched.noofApartments &&
                formik.errors.noofApartments ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.noofApartments}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            {selectedBeds.length < 3 ? (
              <div
                className={classNames(
                  commonstyles.flx,
                  style.mt8,
                  style.justifyContent
                )}
                onClick={handleAddBed}
              >
                <PiPlusSquareFill className={style.plus} />{" "}
                <p
                  className={classNames(
                    commonstyles.fs12,
                    commonstyles.semiBold,
                    style.colorOrange
                  )}
                >
                  Add kind of beds
                </p>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          {selectedBeds.map((bed, index) => (
            <>
              <div className={classNames(commonstyles.col12, style.mt16)}>
                <CustomSelect
                  options={BedsOptions}
                  placeholder="Single bed(s)  / 90-130 cm wide "
                  onSelect={(selectedOption) =>
                    handleBedKindSelect(selectedOption, index)
                  }
                />
                {formik.touched[
                  selectedBeds[index]
                    ?.availableBeds as keyof typeof formik.touched
                ] &&
                formik.errors[
                  selectedBeds[index]
                    ?.availableBeds as keyof typeof formik.errors
                ] ? (
                  <div className={classNames(commonstyles.error)}>
                    *
                    {
                      formik.errors[
                        selectedBeds[index]
                          ?.availableBeds as keyof typeof formik.errors
                      ]
                    }
                  </div>
                ) : null}
              </div>

              <div className={classNames(commonstyles.col12, style.mt16)}>
                <CustomSelect
                  options={numberofbed}
                  placeholder="Select the number of bed"
                  onSelect={(selectedOption) =>
                    handleNoOfBedsSelect(selectedOption, index)
                  }
                />
                {formik.touched[
                  selectedBeds[index]?.noOfBeds as keyof typeof formik.touched
                ] &&
                formik.errors[
                  selectedBeds[index]?.noOfBeds as keyof typeof formik.errors
                ] ? (
                  <div className={classNames(commonstyles.error)}>
                    *
                    {
                      formik.errors[
                        selectedBeds[index]
                          ?.noOfBeds as keyof typeof formik.errors
                      ]
                    }
                  </div>
                ) : null}
              </div>
            </>
          ))}

          <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
            <div className={commonstyles.col8}>
              <div style={{ marginRight: "12px" }}>
                <CustomSelect
                  options={numberofbed}
                  placeholder="Total guest can stay in this apartment "
                  onSelect={handleGuestStayinthisAppartment}
                />
                {formik.touched.guestStayinthisAppartment &&
                formik.errors.guestStayinthisAppartment ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.guestStayinthisAppartment}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={commonstyles.col4}>
              <CustomSelect
                options={guest}
                placeholder="Breakfast include "
                onSelect={handleBreakfastInclude}
              />
              {formik.touched.breakfastInclude &&
              formik.errors.breakfastInclude ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.breakfastInclude}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col8)}>
            <Checkbox
              checked={isPrivateBathroom}
              onChange={handleCheckboxChange}
              className={style.checkbox}
            />
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Private bathroom in this Apartment
            </p>
          </div>
          <div className={classNames(style.flx)}>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.semiBold,
                commonstyles.colorBlue,
                commonstyles.mb28,
                commonstyles.mt32
              )}
            >
              Common space 1
            </p>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.semiBold,
                commonstyles.colorBlue,
                commonstyles.mb28,
                commonstyles.mt32,
                commonstyles.mr15
              )}
            >
              Apartment size(optional)
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
            <div className={commonstyles.col4}>
              <div style={{ marginRight: "12px" }}>
                <CustomSelect
                  options={Sofabeds}
                  placeholder="Number of Sofa beds in the Apartment"
                  onSelect={handleCommonSofaSelect}
                />
                {formik.touched.numberofSofabedinAppartment &&
                formik.errors.numberofSofabedinAppartment ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.numberofSofabedinAppartment}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={commonstyles.col4}>
              <div style={{ marginRight: "12px" }}>
                <CustomSelect
                  placeholder="Total guest can stay "
                  options={manyguest}
                  onSelect={handleCommonGuestSelect}
                />
                {formik.touched.totalGuestStay &&
                formik.errors.totalGuestStay ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.totalGuestStay}
                  </div>
                ) : null}
              </div>
            </div>

            <div className={commonstyles.col4}>
              <div style={{ marginRight: "12px" }}>
                <CustomSelect
                  placeholder="0 Square metres"
                  options={Square}
                  onSelect={handleAppartmentSizeSelect}
                />
                {formik.touched.appartmentSize &&
                formik.errors.appartmentSize ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.appartmentSize}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <p
            className={classNames(
              commonstyles.fs20,
              commonstyles.semiBold,
              commonstyles.colorBlue,
              commonstyles.mb28,
              commonstyles.mt32
            )}
          >
            Base price per night
          </p>
          <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
            <div className={commonstyles.col4}>
              <div style={{ marginRight: "12px" }}>
                <CustomInput
                  placeholder="Basic price per night  "
                  id="basicpricePerNight"
                  name="basicpricePerNight"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.basicpricePerNight}
                />
                {formik.touched.basicpricePerNight &&
                formik.errors.basicpricePerNight ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.basicpricePerNight}
                  </div>
                ) : null}
              </div>
            </div>
            {/* <div className={commonstyles.col4}>
              <div style={{ marginRight: "12px" }}>
                <CustomInput
                  placeholder="Price per night for mediTour  "
                  id="pricepernightformeditour"
                  name="pricepernightformeditour"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.pricepernightformeditour}
                />
                {formik.touched.pricepernightformeditour &&
                formik.errors.pricepernightformeditour ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pricepernightformeditour}
                  </div>
                ) : null}
              </div>
            </div> */}
            <div className={classNames(commonstyles.col4, commonstyles.flx)}>
              <div style={{ marginRight: "12px" }}>
                <ShortImagePicker setData={firstImageURL} />
              </div>
              {imageURLs.map((url: string, index: number) => (
                <img
                  key={index} // Ensure each image has a unique key
                  src={url}
                  alt={`Image ${index}`} // Provide a meaningful alt text
                  style={{ width: "56px", height: "56px" }} // Adjust width and height as needed
                />
              ))}
            </div>
          </div>
          <div
            style={{ marginTop: "32px", width: "204px", marginBottom: "16px" }}
          >
            <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};
