import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { doctorConfirmPasswordSchema } from "shared/utils";
import commonStyles from "shared/utils/common.module.css";
import { resetPassworddoctor } from "shared/services/DoctorService";
import { CustomModal, PrimaryButton, RingLoader } from "shared/components";
import Logo from "assets/images/hospitalLock.png";
import { ArrowBack, CustomInput } from "shared/components";
import { EmailSendModel } from "shared/components/CustomEmailModel/emailSendModel";
import { useSelector } from "react-redux";

const DoctorUpdatePassword = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [SuccessModel, setSuccessModel] = useState(false);
  const { systemType } = useSelector((state: any) => state.root.common);

  const formik = useFormik({
    initialValues: {
      desiredPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object(doctorConfirmPasswordSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");
    if (token) {
      localStorage.setItem("token", token);
    }
    console.log(token);
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    let params = {
      newPassword: formik.values.confirmPassword,
    };
    const token = localStorage.getItem("token");

    if (token !== null) {
      resetPassworddoctor(params, token)
        .then((res: any) => {
          console.log("Doctor's Confirm Password Link API Hit", res);
          if (res.status === 200) {
            setSuccessModel(true);

            setTimeout(() => {
              navigate("/");
            }, 1000);
          }
        })
        .catch((err: any) => {
          console.log("send error of Confirm API", err?.response?.data);
          setError(err?.response?.data?.message || "An unknown error occurred");
          console.log(token);
          console.log(params);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // Handle the case where the token is null
      console.error("Token is null");
    }
  };

  return (
    <div className={classNames(commonStyles.MianOuter)}>
      {error && <div className={classNames(commonStyles.error)}>*{error}</div>}
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.flx, commonStyles.flxBetween)}>
          <div className={classNames(commonStyles.col3)}>
            <p
              className={classNames(
                commonStyles.fs40,
                commonStyles.semiBold,
                commonStyles.colorBlue,
                commonStyles.mb64
              )}
            >
              Reset Password
            </p>
            <div className={classNames(commonStyles.mb28, commonStyles.col12)}>
              <CustomInput
                placeholder="Desired Password"
                id="desiredPassword"
                name="desiredPassword"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.desiredPassword}
              />
              {formik.touched.desiredPassword &&
              formik.errors.desiredPassword ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.desiredPassword}
                </div>
              ) : null}
            </div>
            <div className={classNames(commonStyles.mb28, commonStyles.col12)}>
              <CustomInput
                placeholder="Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
              />
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.confirmPassword}
                </div>
              ) : null}
            </div>
            <div className={classNames(commonStyles.mb56, commonStyles.mt56)}>
              <PrimaryButton
                children={
                  loading ? (
                    <RingLoader size={35} color={"#fff"} />
                  ) : (
                    "Password Update"
                  )
                }
                colorType={"MedicalService"}
              />
            </div>
          </div>
          <div className={classNames(commonStyles.colsm12, commonStyles.col4)}>
            <img src={Logo} className={commonStyles.LoginVector} />
          </div>
        </div>
      </form>
      <CustomModal
        showModal={SuccessModel}
        children={
          <EmailSendModel
            setSuccessModel={setSuccessModel}
            showText="Password Successfully Updated, Please Login"
          />
        }
      />
    </div>
  );
};

export default DoctorUpdatePassword;
