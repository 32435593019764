import classNames from "classnames";
import React, { useEffect, useState } from "react";
import style from "./hotelrooms.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import HotelReserveTable from "shared/components/HotelTables/HotelReserveTable";
import PerviousNextButton from "shared/components/Pagination";
import Pagination from "shared/components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { hotelGETALLRESERVATION } from "shared/services/Hotel";
import {
  setHotelReservation,
  setHotelReservationLength,
  setHotelReservationRenderFlag,
} from "shared/redux";
import { TbRefresh } from "react-icons/tb";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { LoadingModal } from "shared/components";
function HotelReserve() {
  const [loading, setLoading] = useState(false);
  const [length, setLength] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [pageno, setPageno] = useState(1);
  const {
    hotelReservation,
    hotelReservationRenderFlag,
    hotelReservationLength,
  } = useSelector((state: any) => state.root.hotel);
  console.log("orders length", hotelReservationLength);
  const itemsPerPage = 10;
  const totalItems = hotelReservationLength;
  const fetchOrders = (pageno: number) => {
    setLoading(true);
    hotelGETALLRESERVATION(pageno)
      .then((res: any) => {
        console.log("pas.............", res);

        if (res?.data?.bookings.length >= 0) {
          dispatch(setHotelReservation(res?.data?.bookings));
          dispatch(setHotelReservationLength(res?.data?.distinctUserCount));
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
        // setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (hotelReservationLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setHotelReservationRenderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setHotelReservationRenderFlag(true));
    }
  };

  const handleStatusChange = () => {
    fetchOrders(currentPage);
  };

  useEffect(() => {
    if (hotelReservationRenderFlag) {
      setLoading(true);
      fetchOrders(currentPage);
      dispatch(setHotelReservationRenderFlag(false));
    }
  }, [hotelReservationRenderFlag, currentPage]);

  // useEffect(() => {
  //   fetchOrders(currentPage);
  // }, []);

  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />

      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <div className={classNames(commonstyles.flxBetween)}>
            <div className={classNames(commonstyles.flx)}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue,
                  commonstyles.mb32
                )}
              >
                Reservation
              </p>
              <div className={style.outerRefresh}>
                <TbRefresh
                  className={style.RefreshIcon}
                  onClick={() => fetchOrders(pageno)}
                />
              </div>
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>

          {loading ? (
            <LoadingModal showModal={loading} />
          ) : (
            <HotelReserveTable reservations={hotelReservation} />
          )}
        </div>
      </div>
    </div>
  );
}

export default HotelReserve;
