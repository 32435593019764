import React from "react";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
export default function ParentsDetail() {
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={classNames(commonstyles.outerContainer)}>
          <h1>Parents Detail</h1>
        </div>
      </div>
    </div>
  );
}
