import React, { useEffect, useRef, useState } from "react";
import styles from "../../Laboratory/Tests/test.module.css";
import commomstyles from "../../../shared/utils/common.module.css";
import classNames from "classnames";
import { FaEdit } from "react-icons/fa";
import Tabletest from "../../../shared/components/Tables/TableTest/index";
import SearchBar from "shared/components/Searchbar";
import commonStyles from "shared/utils/common.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { labAddTestInfo } from "../../../shared/utils";
import { labAddTestPrice } from "../../../shared/utils";
import {
  LabGetTestCategory,
  getAllLabTests,
  getAllTests,
} from "shared/services";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { getAddTest } from "shared/services";
import { PrimaryButton } from "shared/components";
import { setRenderFlag, setTestsss, setTestLength } from "shared/redux";
import { TbRefresh } from "react-icons/tb";
import {
  ArrowBack,
  CustomInput,
  CustomModal,
  CustomStepper,
  Loader,
  LoadingModal,
} from "shared/components";
import Typography from "@mui/material/Typography";
import CustomSelect from "shared/components/CustomSelect";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { useNavigate } from "react-router-dom";
const steps = [
  {
    id: "1",
    lable: "Test Info",
  },
  {
    id: "2",
    lable: "Test price",
  },
];

function Tests() {
  // const [Tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const { tests, renderFlag, lengthh } = useSelector(
    (state: any) => state.root.lab
  );
  console.log("LENGTH", lengthh);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const itemsPerPage = 10;
  const totalItems = lengthh;
  const fetchTests = (pageno: number) => {
    setLoading(true);
    getAllLabTests(pageno)
      .then((res: any) => {
        console.log("🚀 ~ .then ~ res:", res);

        if (res?.data?.auth) {
          if (res?.data?.tests?.length > 0) {
            dispatch(setTestsss(res?.data?.tests));
            dispatch(setTestLength(res?.data?.totalTests));
          } else {
            dispatch(setTestLength(0));
          }
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (lengthh > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setRenderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setRenderFlag(true));
    }
  };

  useEffect(() => {
    if (renderFlag) {
      setLoading(true);
      fetchTests(currentPage);
      dispatch(setRenderFlag(false));
    }
  }, [renderFlag, currentPage]);

  const [rotation, setRotation] = useState(0);
  const rotationIncrement = 90;

  const handleRotate = () => {
    setRotation(rotation - rotationIncrement);
  };

  return (
    <div className={classNames(commomstyles.col12)}>
      <SearchBar />
      <>
        <div className={commomstyles.mr87}>
          <div className={styles.outerContainer}>
            <div className={classNames(styles.flxBetween, commomstyles.mb32)}>
              <div className={classNames(commomstyles.flx)}>
                <p
                  className={classNames(
                    commomstyles.fs24,
                    commomstyles.semiBold,
                    commomstyles.colorBlue
                  )}
                >
                  All Test
                </p>

                {tests?.length >= 0 && (
                  <div className={styles.outerRefresh}>
                    <BiSolidMessageSquareAdd
                      className={styles.addIcon}
                      // onClick={() => setShowAddModal(true)}
                      onClick={() => navigate("/laboratory/AddTest")}
                    />
                  </div>
                )}
                <div className={styles.outerRefresh}>
                  <TbRefresh
                    className={styles.RefreshIcon}
                    style={{ transform: `rotate(${rotation}deg)` }}
                    onClick={() => {
                      fetchTests(pageno);
                      handleRotate();
                    }}
                  />
                </div>
              </div>
              <NewPagination
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                startItem={(currentPage - 1) * itemsPerPage + 1}
                endItem={Math.min(currentPage * itemsPerPage, totalItems)}
                totalItems={totalItems}
              />
            </div>

            <div className={styles.tabless}>
              <Tabletest tests={tests} setShowModal={setShowAddModal} />
            </div>

            <CustomModal
              showModal={showAddModal}
              children={
                <AddTest
                  setShowAddModal={setShowAddModal}
                  // setTests={setTests}
                  Tests={tests}
                />
              }
            />
          </div>
        </div>
        {loading && <LoadingModal showModal={loading} />}
      </>
    </div>
  );
}

export default Tests;

const AddTest = (props: any) => {
  const [loading, setLoading] = useState(false);
  const { setShowAddModal, setTests, Tests } = props;
  const [screenName, setScreenName] = useState("TestInfo");
  const [selectedStep, setSelectedStep] = useState(0);
  const [addData, setAddData] = useState({});
  // const [testcategory, setTestCategory] = useState([]);

  const handleClickNext = () => {
    if (screenName === "TestInfo") {
      setScreenName("Testprice");
    }

    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "Testprice") {
      setScreenName("TestInfo");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  return (
    <Typography
      id="modal-modal-description"
      sx={{ textAlign: "center", color: "#001F57" }}
    >
      <div className={commomstyles.flx}>
        {/* <div className={commomstyles.flx} style={{ width: "97%" }}>
          <ArrowBack onClick={handleClicKPrev} />
          <p className={classNames(commomstyles.semiBold, commomstyles.fs16)}>
            Previous
          </p>
        </div> */}
        <div className={styles.end}>
          <button className={styles.close} onClick={handleCloseModal}>
            &#10006;
          </button>
        </div>
      </div>

      <div style={{ marginTop: "30px" }}>
        <CustomStepper steps={steps} selectedStep={selectedStep} />
      </div>
      <div>
        {screenName === "TestInfo" && (
          <TestInfo
            handleClickNext={handleClickNext}
            setAddData={setAddData}
            // testcategory={testcategory}
          />
        )}
        {screenName === "Testprice" && (
          <TestPrice
            handleClickNext={handleClickNext}
            addData={addData}
            setAddData={setAddData}
            setShowAddModal={setShowAddModal}
            setTests={setTests}
            Tests={Tests}
          />
        )}
      </div>
      <div className={styles.start}>
        {/* <button
            onClick={handleClickNext}
            style={{ fontSize: "16px", fontWeight: "600" }}
            className={styles.btnx}
          >
            Next
          </button> */}
      </div>
    </Typography>
  );
};

interface Props {
  handleClickNext: any;
  setAddData: any;
  addData: any;
  setShowAddModal: any;
  setTests: any;
  Tests: any;
  testcategory: any;
}
const TestInfo = (props: Partial<Props>) => {
  const [loading, setLoading] = useState(false);
  const [testcategory, setTestCategory] = useState([]);
  const { handleClickNext, setAddData } = props;

  const fetchTests = () => {
    setLoading(true);
    LabGetTestCategory()
      .then((res: any) => {
        // console.log("data...........................", res);
        if (res?.data?.auth) {
          const categoryNames = res.data.testCategories.map(
            (category: any) => category.categoryName
          );
          setTestCategory(categoryNames);
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
        // setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTests();
  }, []);
  const formik = useFormik({
    initialValues: {
      testAddTest_TestName: "",
      testAddTest_TestCategory: "",
      // testAddTest_TestCode: "",
      testAddTest_TestDescription: "",
    },
    validationSchema: Yup.object(labAddTestInfo),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = async () => {
    console.log("Submit");
    const currentData = formik.values;
    handleClickNext();
    setAddData({
      testName: currentData.testAddTest_TestName,
      categoryName: currentData.testAddTest_TestCategory,
      testDescription: currentData.testAddTest_TestDescription,
    });
  };
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("testAddTest_TestCategory", selectedOption);
  };

  return (
    <>
      <div style={{ width: "500px" }}>
        <form onSubmit={formik.handleSubmit}>
          <div
            style={{ marginTop: "56px" }}
            className={commomstyles.flxBetween}
          >
            <div style={{ width: "233px" }}>
              <CustomInput
                placeholder="Test Name"
                id="testAddTest_TestName"
                name="testAddTest_TestName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.testAddTest_TestName}
              />

              {formik.touched.testAddTest_TestName &&
              formik.errors.testAddTest_TestName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.testAddTest_TestName}
                </div>
              ) : null}
            </div>
            <div style={{ width: "233px" }}>
              <CustomSelect
                options={testcategory}
                placeholder="Test Type"
                onSelect={handleSelect}
              />
              {formik.touched.testAddTest_TestCategory &&
              formik.errors.testAddTest_TestCategory ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.testAddTest_TestCategory}
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.description}>
            <textarea
              style={{ resize: "none", borderRadius: "8px" }}
              placeholder="Test Description"
              id="testAddTest_TestDescription"
              name="testAddTest_TestDescription"
              onChange={formik.handleChange}
              value={formik.values.testAddTest_TestDescription}
            ></textarea>

            {formik.touched.testAddTest_TestDescription &&
            formik.errors.testAddTest_TestDescription ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.testAddTest_TestDescription}
              </div>
            ) : null}
          </div>
          <div style={{ marginTop: "56px", width: "210px" }}>
            <PrimaryButton
              children={"Next Step"}
              type="submit"
              colorType={"Linear"}
            />
          </div>
        </form>
      </div>
    </>
  );
};

const TestPrice = (props: Partial<Props>) => {
  const { handleClickNext, addData, setShowAddModal, setTests, Tests } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      testprice_TestPrice: "",
      testprice_TestDuration: "",
      testprice_PriceForMeditour: "",
    },
    validationSchema: Yup.object(labAddTestPrice),
    onSubmit: (values) => {
      handleSubmit();
      console.log("Submit");
    },
  });
  const handleSubmit = async () => {
    const curr_data = formik.values;
    let tempTest = [...Tests];

    setLoading(true);

    let params = {
      ...addData,
      price: curr_data.testprice_TestPrice,
      duration: curr_data.testprice_TestDuration,
      priceForMeditour: curr_data.testprice_PriceForMeditour,
    };
    getAddTest(params)
      .then((res: any) => {
        if (res.data.auth) {
          dispatch(setRenderFlag(true));
          setShowAddModal(false);
          handleClickNext();
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div style={{ width: "500px" }}>
        <form onSubmit={formik.handleSubmit}>
          <div
            style={{ marginTop: "56px" }}
            className={commomstyles.flxBetween}
          >
            <div style={{ width: "233px" }}>
              <CustomInput
                placeholder="Price"
                id="testprice_TestPrice"
                name="testprice_TestPrice"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.testprice_TestPrice}
              />

              {formik.touched.testprice_TestPrice &&
              formik.errors.testprice_TestPrice ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.testprice_TestPrice}
                </div>
              ) : null}
            </div>
            <div style={{ width: "233px" }}>
              <CustomInput
                placeholder="Duration"
                id="testprice_TestDuration"
                name="testprice_TestDuration"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.testprice_TestDuration}
              />

              {formik.touched.testprice_TestDuration &&
              formik.errors.testprice_TestDuration ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.testprice_TestDuration}
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ marginTop: "32px" }} className={styles.flx}>
            <div style={{ width: "233px" }}>
              <CustomInput
                placeholder="Price For Meditour"
                id="testprice_PriceForMeditour"
                name="testprice_PriceForMeditour"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.testprice_PriceForMeditour}
              />

              {formik.touched.testprice_PriceForMeditour &&
              formik.errors.testprice_PriceForMeditour ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.testprice_PriceForMeditour}
                </div>
              ) : null}
            </div>
          </div>

          <div style={{ marginTop: "56px", width: "210px" }}>
            <PrimaryButton
              children={loading ? "loading..." : "Save"}
              disabled={loading ? true : false}
              type="submit"
              colorType={"Linear"}
            />
          </div>
        </form>
      </div>
    </>
  );
};
