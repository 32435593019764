import React from "react";
import classNames from "classnames";
import CommonStyles from "shared/utils/common.module.css";
import LoginStyles from "./Uselogin.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import PasswordInput from "shared/components/PasswordInput";
import { Checkbox } from "@mui/material";
import Logo from "assets/images/loginLogo.png";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

interface FormValues {
  email: string;
  password: string;
}

const UserLogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),
    onSubmit: (values: FormValues) => {
      handleLogin(values);
    },
  });

  const handleLogin = (values: FormValues) => {
    setLoading(true);
    setError("");

    // Mock login process
    setTimeout(() => {
      setLoading(false);
      navigate("/dashboard"); // Replace with actual login logic
    }, 2000);
  };

  const handleForgetPassword = () => {
    navigate("/user/Forget_Password");
  };

  const handleRegisterForm = () => {
    navigate('/DoctorServices');
  };

  return (
    <div
      className={classNames(
        LoginStyles.paretns,
        CommonStyles.flx,
        CommonStyles.flxWrap
      )}
    >
      <div
        className={classNames(
          CommonStyles.col6,
          CommonStyles.colmd12,
          CommonStyles.colsm12,
          LoginStyles.centerContent
        )}
      >
        <form
          className={LoginStyles.loginFormContainer}
          style={{ marginTop: "10%", marginBottom: "10%" }}
          onSubmit={formik.handleSubmit}
        >
          <p
            className={classNames(
              CommonStyles.fs40,
              CommonStyles.semiBold,
              CommonStyles.colorBlue
            )}
          >
            Login
          </p>
          <p
            className={classNames(
              CommonStyles.fs16,
              CommonStyles.semiBold,
              CommonStyles.colorBlue
            )}
          >
            Login to access MediTour
          </p>
          {error && (
            <div className={classNames(CommonStyles.error)}>*{error}</div>
          )}
          <div className={CommonStyles.mt24}>
            <CustomInput
              placeholder="Please enter Email"
              id="email"
              name="email"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className={classNames(CommonStyles.error)}>
                *{formik.errors.email}
              </div>
            ) : null}
          </div>
          <div className={CommonStyles.mt16}>
            <PasswordInput
              placeholder="Enter Your Password"
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className={classNames(CommonStyles.error)}>
                *{formik.errors.password}
              </div>
            ) : null}

            <div
              className={classNames(
                LoginStyles.flxBetween,
                CommonStyles.mb40,
                LoginStyles.mt8
              )}
            >
              <div className={classNames(CommonStyles.flx)}>
                <Checkbox className={CommonStyles.checkBoxx} />
                <p
                  className={classNames(
                    CommonStyles.colorBlue,
                    CommonStyles.fs14
                  )}
                >
                  Remember me
                </p>
              </div>
              <a
                className={classNames(
                  CommonStyles.colorBlue,
                  CommonStyles.fs14,
                  CommonStyles.flxEnd,
                  LoginStyles.cursor
                )}
                onClick={handleForgetPassword}
              >
                Forgot Password
              </a>
            </div>

            <div className={classNames(CommonStyles.mb16)}>
              <PrimaryButton
                type="submit"
                children={loading ? "Loading..." : "Log In"}
                colorType={"btnBlue"}
              />
            </div>
            <div>
              <p
                className={classNames(
                  CommonStyles.regular,
                  CommonStyles.fs14,
                  CommonStyles.textCenter
                )}
              >
                New to this platform?{" "}
                <a
                  className={classNames(
                    CommonStyles.colorBlue,
                    LoginStyles.cursor
                  )}
                  onClick={handleRegisterForm}
                >
                  Register
                </a>
              </p>
            </div>
          </div>
        </form>
      </div>
      <div
        className={classNames(
          LoginStyles.imgBackgrond,
          CommonStyles.col6,
          CommonStyles.colmd12,
          CommonStyles.colsm12
        )}
      >
        <div className={classNames(LoginStyles.centerContent)}>
          <img src={Logo} alt="Logo" className={LoginStyles.logoImage} />
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
