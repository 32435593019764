import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CustomInput, PrimaryButton } from "shared/components";
import ImgPicker from "shared/components/Img-picker";
import CustomSelect from "shared/components/CustomSelect";
import { insurancePriceSchema } from "shared/utils";
import { insuranceAddHealth_Parents } from "shared/services/Insurance";
const Price = ["Hospitalization Per person ", "Karachi", "Multan", "Islambad"];
export default function PriceInsurance() {
  const { insuranceHealthParentPackage } = useSelector(
    (state: any) => state.root.insurance
  );
  const navigate = useNavigate();
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("perYear", selectedOption);
  };
  console.log("............", insuranceHealthParentPackage);
  const formik = useFormik({
    initialValues: {
      actualPrice: "",
      // meditourPrice: "",
      perYear: "",
    },
    validationSchema: Yup.object(insurancePriceSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    console.log("Submit");
    let currentData = formik.values;

    let params = {
      ageCriteria: {
        startAge: insuranceHealthParentPackage.parentsAgeStart,
        endAge: insuranceHealthParentPackage.parentsAgeEnd,
      },
      hospitalizationLimit: {
        startLimit: insuranceHealthParentPackage.hospitalizationStartLimit,
        endLimit: insuranceHealthParentPackage.hospitalizationEndLimit,
      },
      packageName: insuranceHealthParentPackage.packageName,
      packageLogo: insuranceHealthParentPackage.packageLogo,
      hospitalizationPerPerson:
        insuranceHealthParentPackage.hospitalizationPerPerson,
      dailyRoomAndBoardLimit: insuranceHealthParentPackage.dailyRoomBoardLimit,
      claimPayoutRatio: insuranceHealthParentPackage.claimPayoutRatio,
      hospitals: insuranceHealthParentPackage.hospitals,
      laboratories: insuranceHealthParentPackage.labs,
      icuCcuLimits: insuranceHealthParentPackage.icu,
      accidentalEmergencyLimits: insuranceHealthParentPackage.additionalLimit,
      ambulanceCoverage: insuranceHealthParentPackage.ambulanceService,
      specializedInvestigationCoverage:
        insuranceHealthParentPackage.investigation,
      waitingPeriod: insuranceHealthParentPackage.weeks,
      maternity: insuranceHealthParentPackage.maternity,
      policyDocument: insuranceHealthParentPackage.policyDocument,
      claimProcess: insuranceHealthParentPackage.claimProcess,
      heading: insuranceHealthParentPackage.heading,
      description: insuranceHealthParentPackage.packageDescription,
      //CurrentData
      actualPrice: currentData.actualPrice,
      // meditourPrice: currentData.meditourPrice,
      perYear: currentData.perYear,
    };

    insuranceAddHealth_Parents(params)
      .then((res: any) => {
        console.log("Final response", res);
        navigate("/insurance/ParentsMian");
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        console.log("....");
      });
  };
  // useEffect(() => {
  //   formik.setValues(insuranceHealthParentPackage);
  //   console.log(insuranceHealthParentPackage);
  // }, []);
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.col6)}>
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="Actual Price "
                id="actualPrice"
                name="actualPrice"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.actualPrice}
              />
              {formik.touched.actualPrice && formik.errors.actualPrice ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.actualPrice}
                </div>
              ) : null}
            </div>
          </div>
          {/* <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="MediTour Price"
                id="meditourPrice"
                name="meditourPrice"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.meditourPrice}
              />
              {formik.touched.meditourPrice && formik.errors.meditourPrice ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.meditourPrice}
                </div>
              ) : null}
            </div>
          </div> */}
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="Per Year"
                id="perYear"
                name="perYear"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.perYear}
              />
              {formik.touched.perYear && formik.errors.perYear ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.perYear}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={commonStyles.BtnWidth}>
          <PrimaryButton children={"Submit"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
}
