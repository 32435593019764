import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "./donors.module.css";
import DonorsTable from "shared/components/DonationTables/Donors";
import PerviousNextButton from "shared/components/Pagination";
import { donationGETALLDonations } from "shared/services/Donation";
import { LoadingModal } from "shared/components";
import Pagination from "shared/components/Pagination/Pagination";
function Donors() {
  const [loading, setLoading] = useState(false);
  const [donors, setdonors] = useState([]);
  const FetchAllDonations = () => {
    setLoading(true);
    donationGETALLDonations()
      .then((res: any) => {
        if (res.data.auth === true) {
          console.log("..........", res);
          setdonors(res.data.donations);
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
        // setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    FetchAllDonations();
  }, []);

  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={commonstyles.outerContainer}>
          {/* <div className={classNames(commonstyles.fs12, commonstyles.flx)}>
          <p>Today|</p>
          <p>This week|</p>
          <p>This Month|</p>
          <p>This Year</p>
        </div> */}
          <div
            className={classNames(commonstyles.mb32, commonstyles.flxBetween)}
          >
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Listing
            </p>
            <Pagination />
          </div>

          {loading ? (
            <LoadingModal showModal={loading} />
          ) : (
            <>
              <DonorsTable donors={donors} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Donors;
