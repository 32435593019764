import React from "react";
import AdminNavBar from "../../../Components/AdminNavBar";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import Styles from "./RentaCar.module.css";
import { TbRefresh } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { CiSearch } from "react-icons/ci";
import { BiFilterAlt } from "react-icons/bi";
import { RiSearchLine } from "react-icons/ri";
import SearchFilter from "pages/AdminPanel/Components/SearchFilter";
import moment from "moment";
const data = [
  {
    labId: "4 Feb 2019 05:30 PM",
    labName: "kP02365",
    phoneNumber: "Salim Qureshi",
    labAddress: "25632",
  },
  {
    labId: "4 Feb 2019 05:30 PM",
    labName: "KL02365",
    phoneNumber: "Zubair Ahmed",
    labAddress: "56489",
  },
];

export default function RentaCarVender() {
  const { state } = useLocation();
  console.log(",,,,,,,,,,,,,,,pharmacy state", state);
  const navigate = useNavigate();
  const handleGoToDetail = (index: any) => {
    const result = state.map((v: any, i: any) => {
      if (i == index) {
        return v;
      }
    });
    navigate("/admin/Users/VendorDetail", {
      state: { data: state[index], type: "Rent A Car" },
    });
  };
  return (
    <div className={classNames(commonStyles.col12)}>
      <div className={Styles.Navouter}>
        <AdminNavBar labelText="Users" />
      </div>
      <div className={classNames(Styles.mainOuter)}>
        <div className={classNames(Styles.flxBetween)}>
          <div className={classNames(commonStyles.flx, commonStyles.colsm12)}>
            <p
              className={classNames(
                commonStyles.fs22,
                Styles.primarycolor,
                commonStyles.semiBold
              )}
            >
              Rent a Car
            </p>
            <TbRefresh className={Styles.refresh} />
            <SearchFilter vender={false} />
          </div>
          <div className={classNames(commonStyles.flx)}>
            <NewPagination />
          </div>
        </div>
        <div>
          <div className={Styles.payment}>
            <div className={classNames(Styles.headerOuter, commonStyles.bold)}>
              <p className={Styles.headerclass}>VENDOR ID</p>
              <p className={Styles.headerclass}>NAME</p>
              <p className={Styles.headerclass}>PHONE NUMBER</p>
              <p className={Styles.headerclass}>ADDRESS</p>
            </div>
            <div className={Styles.tableData}>
              <table
                style={{
                  margin: "0px",
                  borderCollapse: "separate",
                  borderSpacing: "0 4px",
                  fontFamily: '"Poppins", sans-serif',
                }}
              >
                <tbody className={Styles.wapper}>
                  {state?.map((val: any, rowIndex: any) => {
                    const Date = moment(val?.createdAt).format(
                      "MM-DD-YYYY,  h:mm a"
                    );

                    return (
                      <tr
                        className={Styles.tableRow}
                        key={rowIndex}
                        onClick={() => handleGoToDetail(rowIndex)}
                      >
                        <td className={Styles.w20}>{val?.vendorId}</td>
                        <td className={Styles.w20}>{val?.name}</td>
                        <td className={Styles.w20}>{val?.phoneNumber}</td>
                        <td className={Styles.w20}>{val?.location?.address}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
