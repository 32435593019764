import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./ticketRequest.module.css";
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CustomInput,
  CustomModal,
  LoadingModal,
  PrimaryButton,
} from "shared/components";
import ImgPicker from "shared/components/Img-picker";
import commonStyles from "shared/utils/common.module.css";
import Datepicker from "shared/components/DatePicker";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Typography,
} from "@mui/material";
import CustomSelect from "shared/components/CustomSelect";
import { MdAdd, MdOutlineDeleteOutline } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { flightAddSchema, travelAgencyBidSchema } from "shared/utils";
import { useParams } from "react-router-dom";
import {
  travelAgencyADDBID,
  travelGETRequestDetails,
} from "shared/services/TravelAgency";
import { FaMinus } from "react-icons/fa6";
import { useSelector } from "react-redux";
interface Flight {
  fightType?: string;
  from?: string;
  to?: string;
  departureDate?: string;
  departureTime?: string;
  arrivalDate?: string;
  arrivalTime?: string;
  flightNo?: string;
  amenities?: [];
  // Add more fields if needed
}

interface Details {
  from?: string;
  to?: string;
  departure?: string;
  adult?: number;
  infant?: number;
  children?: number;
  class?: string;
  requestType?: string;
}
interface User {
  name?: string;
}
interface Bid {
  companyName?: string;
  ticketPrice?: number;
  requestType?: string;
}
interface Agency {
  name?: string;
  email?: string;
  ownerFirstName?: string;
}
export default function TicketRequestDetail() {
  const { id } = useParams();
  console.log("id...........", id);
  const [error, setError] = React.useState("");
  const { ticketRequests } = useSelector((state: any) => state.root.travelagency);
  console.log("🚀 ~ TicketRequestDetail ~ ticketRequest:", ticketRequests)
  const [modal, setModalOpen] = useState(false);
  const [showbitCard, setShowbitCard] = useState(false);
  const [showSendModel, setShowSendModel] = useState(false);
  const [addflight, setAddFlight] = useState<Flight[]>([]);
  const [details, setDetails] = useState<Details>({});
  const [user, setUser] = useState<User>({});
  const [loading, setLoading] = useState(false);
  const [bid, setBid] = useState<Bid>({});
  const [agencyId, setAgencyID] = useState<Agency>({});

  const addFlight = () => {
    setModalOpen(true);
    // setFlights([...flights, {}]);
  };

  const handleOpenModel = () => {
    setShowSendModel(true);

    setTimeout(() => {
      setShowSendModel(false);
    }, 1500);
  };
  const navigate = useNavigate();

  const handleAddTicket = () => {
  if (details?.requestType === 'round') {
    navigate('/flights/Bid/round/:id');
  } else if (details?.requestType === 'oneWay') {
    navigate('/flights/Bid/one-way/:id');
  } else {
    // Handle other cases or show an error
    console.error('Unknown request type:', details?.requestType);
  }
};
  const handleShowbitcard = () => {
    setShowbitCard(true);
  };

  const handleCompanyLogo = (url: any) => {
    formik.setFieldValue("companyLogo", url);
  };

  const formik = useFormik({
    initialValues: {
      companyLogo: "",
      companyName: "",
      totalpriceforTraveler: "",
      noofHandbag: "",
      baggageWeight: "",
      cancelationDuration: "",
      cancelationDetuct: "",
      ticketPrice: "",
    },
    validationSchema: Yup.object(travelAgencyBidSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const fetchBookingTickets = () => {
    setLoading(true);
    if (typeof id === "string") {
      travelGETRequestDetails(id)
        .then((res: any) => {
          console.log(".......getrequestDetails....", res);
          setDetails(res?.data?.request);
          setUser(res?.data?.request?.userId);
          setBid(res?.data?.bid);
          setAgencyID(res?.data?.bid?.agencyId);
        })
        .catch((err: any) => {
          console.log("send error.nnnnn..", err.response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (addflight.length === 0) {
      setError("Please Add Flight");
    } else {
      const currentData = formik.values;
      let params = {
        requestId: id,
        companyLogo: currentData.companyLogo,
        companyName: currentData.companyName,
        flightDetails: [addflight],
        flightPolicies: {
          noOfHandbag: currentData.noofHandbag,
          baggageWeight: currentData.baggageWeight,
          cancelationDuration: currentData.cancelationDuration,
          cancelationDeduction: currentData.cancelationDetuct,
        },
        ticketPrice: currentData.ticketPrice,
      };

      travelAgencyADDBID(params)
        .then((res: any) => {
          console.log("API Response of travelAgencyADDBID:", res);
          setShowbitCard(false);
          fetchBookingTickets();
        })
        .catch((err: any) => {
          console.log(
            "Error fetching appointments:",
            err.response.data.message
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const handleDeleteFlight = (index: number) => {
    const updatedFlights = [...addflight];
    updatedFlights.splice(index, 1); // Remove the flight at the specified index
    setAddFlight(updatedFlights); // Update the state to re-render and reflect the deletion
  };

 useEffect(() => {
    if (ticketRequests && Array.isArray(ticketRequests)) {
      const requestDetails = ticketRequests.find((request: any) => request._id === id);
      if (requestDetails) {
        const flightDetails = requestDetails.flights && requestDetails.flights.length > 0 ? requestDetails.flights[0] : {};
        setDetails({
          from: flightDetails.from,
          to: flightDetails.to,
          departure: flightDetails.departure,
          adult: requestDetails.adult,
          infant: requestDetails.infant,
          children: requestDetails.children,
          class: requestDetails.flightClass,
          requestType: requestDetails.requestType,
        });
        setUser({
          name: requestDetails.userId?.name,
        });
      } else {
        console.error("Request not found");
      }
    } else {
      console.error("ticketRequests is not defined or not an array");
    }
  }, [id, ticketRequests]);


  return loading ? (
    <LoadingModal showModal={loading} />
  ) : (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={styles.outerContainer}>
          <div className={commonstyles.flx}>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Request detail
            </p>
          </div>
          <div className={classNames(commonstyles.flxBetween, styles.mt24)}>
            <p
              className={classNames(
                commonstyles.fs18,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              User
            </p>
            <p
              className={classNames(
                commonstyles.fs18,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              {details?.requestType}
            </p>
          </div>
          <div className={styles.DetailCard}>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.regular,
                styles.colorGray
              )}
            >
              {user?.name}
            </p>

            <div className={classNames(commonstyles.flxBetween, styles.mt24)}>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.regular,
                    styles.colorGray
                  )}
                >
                  From
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.regular,
                    commonstyles.colorBlue
                  )}
                >
                  {details?.from}
                </p>
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.regular,
                    styles.colorGray
                  )}
                >
                  To
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.regular,
                    commonstyles.colorBlue
                  )}
                >
                  {details?.to}
                </p>
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.regular,
                    styles.colorGray
                  )}
                >
                  Departure
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.regular,
                    commonstyles.colorBlue
                  )}
                >
                  {details?.departure
                    ? new Date(details.departure).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })
                    : "__"}
                </p>
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.regular,
                    styles.colorGray
                  )}
                >
                  Class
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.regular,
                    commonstyles.colorBlue
                  )}
                >
                  {details?.class}
                </p>
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.regular,
                    styles.colorGray
                  )}
                >
                  Traveler
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.regular,
                    commonstyles.colorBlue
                  )}
                >
                  Adults: {details?.adult} children: {details?.children} Infant:{" "}
                  {details?.infant}
                </p>
              </div>
               
              <div>
                

              </div>
              
            </div>
          </div>
          <div className={classNames(commonstyles.flxEnd, styles.mt24)}>
      <button
        className={classNames(styles.advancedButton)}
        onClick={handleAddTicket}
      >
        Add Ticket
      </button>
    </div>
          {bid == null && (
            <div className={styles.bitBtn}>
              <PrimaryButton
                children={"Bid"}
                colorType={"Travel"}
                onClick={handleShowbitcard}
              />
            </div>
          )}
          {bid !== null && (
            <>
              <div>
                <p
                  className={classNames(commonstyles.fs18)}
                  style={{
                    textAlign: "start",
                  }}
                >
                  Bid Details
                </p>
              </div>

              <div
                className={classNames(
                  commonstyles.flx,
                  styles.justify,
                  styles.mt7
                )}
              >
                <div>
                  <div className={classNames(commonstyles.flx)}>
                    <p className={styles.title}> Company Name:</p>
                    <p className={classNames(styles.fs14, styles.colorGray)}>
                      {bid?.companyName}
                    </p>
                  </div>
                  <div className={classNames(commonstyles.flx, styles.mt7)}>
                    <p className={styles.title}> requestType :</p>
                    <p className={classNames(styles.fs14, styles.colorGray)}>
                      {bid?.requestType}
                    </p>
                  </div>
                  <div className={classNames(commonstyles.flx, styles.mt7)}>
                    <p className={styles.title}>ticketPrice:</p>
                    <p className={classNames(styles.fs14, styles.colorGray)}>
                      {bid?.ticketPrice}
                    </p>
                  </div>
                </div>
                <div className={styles.mr20}>
                  <div className={classNames(commonstyles.flx)}>
                    <p className={styles.title}>Travel Agency Name:</p>
                    <p className={classNames(styles.fs14, styles.colorGray)}>
                      {agencyId?.name}
                    </p>
                  </div>
                  <div className={classNames(commonstyles.flx, styles.mt7)}>
                    <p className={styles.title}>Email:</p>
                    <p className={classNames(styles.fs14, styles.colorGray)}>
                      {agencyId?.email}
                    </p>
                  </div>
                  <div className={classNames(commonstyles.flx, styles.mt7)}>
                    <p className={styles.title}>Travel Agency Owner Name:</p>
                    <p className={classNames(styles.fs14, styles.colorGray)}>
                      {agencyId?.ownerFirstName}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          {showbitCard && (
            <form onSubmit={formik.handleSubmit}>
              <>
                <p
                  className={classNames(
                    commonstyles.fs22,
                    commonstyles.semiBold,
                    commonstyles.colorBlue,
                    styles.mb16,
                    styles.mt24
                  )}
                >
                  Flight Details
                </p>
                <div className={styles.DetailCard}>
                  <div className={classNames(commonstyles.flx)}>
                    <div className={styles.w25}>
                      <ImgPicker
                        placeholder="Company Logo"
                        setData={handleCompanyLogo}
                      />
                      {formik.touched.companyLogo &&
                      formik.errors.companyLogo ? (
                        <div className={classNames(commonstyles.error)}>
                          *{formik.errors.companyLogo}
                        </div>
                      ) : null}
                    </div>
                    <div className={styles.w25}>
                      <CustomInput
                        placeholder="Company name"
                        id="companyName"
                        name="companyName"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.companyName}
                      />
                      {formik.touched.companyName &&
                      formik.errors.companyName ? (
                        <div className={classNames(commonstyles.error)}>
                          *{formik.errors.companyName}
                        </div>
                      ) : null}
                    </div>
                    <div className={styles.w25}>
                      <CustomInput
                        placeholder="Total Price for Traveler"
                        id="totalpriceforTraveler"
                        name="totalpriceforTraveler"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.totalpriceforTraveler}
                      />
                      {formik.touched.totalpriceforTraveler &&
                      formik.errors.totalpriceforTraveler ? (
                        <div className={classNames(commonstyles.error)}>
                          *{formik.errors.totalpriceforTraveler}
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={classNames(commonstyles.flx)}
                      onClick={addFlight}
                    >
                      <MdAdd className={styles.addicon} />
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.colorBlue
                        )}
                      >
                        Add Flight
                      </p>
                    </div>
                  </div>

                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs18,
                        commonstyles.semiBold,
                        commonstyles.colorBlue,
                        styles.mt24
                      )}
                    >
                      Flight Policies
                    </p>
                    <div className={classNames(commonstyles.flxBetween)}>
                      <div className={styles.w25}>
                        <CustomInput
                          placeholder="No. of handbag"
                          id="noofHandbag"
                          name="noofHandbag"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.noofHandbag}
                        />
                        {formik.touched.noofHandbag &&
                        formik.errors.noofHandbag ? (
                          <div className={classNames(commonstyles.error)}>
                            *{formik.errors.noofHandbag}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles.w25}>
                        <CustomInput
                          placeholder="Baggage weight"
                          id="baggageWeight"
                          name="baggageWeight"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.baggageWeight}
                        />
                        {formik.touched.baggageWeight &&
                        formik.errors.baggageWeight ? (
                          <div className={classNames(commonstyles.error)}>
                            *{formik.errors.baggageWeight}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles.w25}>
                        <CustomInput
                          placeholder="Cancelation duration"
                          id="cancelationDuration"
                          name="cancelationDuration"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.cancelationDuration}
                        />
                        {formik.touched.cancelationDuration &&
                        formik.errors.cancelationDuration ? (
                          <div className={classNames(commonstyles.error)}>
                            *{formik.errors.cancelationDuration}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles.w25}>
                        <CustomInput
                          placeholder="Cancelation deduction"
                          id="cancelationDetuct"
                          name="cancelationDetuct"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.cancelationDetuct}
                        />
                        {formik.touched.cancelationDetuct &&
                        formik.errors.cancelationDetuct ? (
                          <div className={classNames(commonstyles.error)}>
                            *{formik.errors.cancelationDetuct}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={styles.w25}>
                      <CustomInput
                        placeholder="Ticket Price"
                        id="ticketPrice"
                        name="ticketPrice"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.ticketPrice}
                      />
                      {formik.touched.ticketPrice &&
                      formik.errors.ticketPrice ? (
                        <div className={classNames(commonstyles.error)}>
                          *{formik.errors.ticketPrice}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {addflight.length > 0 && (
                    <div className={styles.accordion}>
                      {addflight.map((flight, index) => (
                        <Accordion key={index}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                          >
                            <Typography
                              className={classNames(
                                commonstyles.fs18,
                                commonstyles.semiBold,
                                commonstyles.colorBlue,
                                styles.mt24
                              )}
                            >
                              Flight no {index + 1}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div
                              className={classNames(commonstyles.flxBetween)}
                            >
                              <div className={styles.w22}>
                                <Typography>Flight Type</Typography>
                              </div>
                              <div className={styles.w25}>
                                <Typography>{flight?.fightType}</Typography>
                              </div>
                              <div className={styles.w22}>
                                <Typography>Departure Time</Typography>
                              </div>
                              <div className={styles.w25}>
                                <Typography>{flight?.departureTime}</Typography>
                              </div>
                              <div className={styles.w22}>
                                <Typography>Amenities</Typography>
                              </div>
                              <div className={styles.w25}>
                                <Typography>{flight?.amenities}</Typography>
                              </div>
                              <div className={styles.w22}>
                                <Typography>Arrival Date</Typography>
                              </div>
                              <div className={styles.w25}>
                                <Typography>{flight?.arrivalDate}</Typography>
                              </div>
                              <div className={styles.w22}>
                                <Typography>Arrival Time</Typography>
                              </div>
                              <div className={styles.w25}>
                                <Typography>{flight?.arrivalTime}</Typography>
                              </div>
                              <div
                                className={styles.deleteIcon}
                                onClick={() => handleDeleteFlight(index)}
                              >
                                <MdOutlineDeleteOutline
                                  className={styles.deleteIcon}
                                />
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </div>
                  )}
                </div>
              </>

              {error && (
                <div className={classNames(commonStyles.error)}>*{error}</div>
              )}
              <div className={styles.submit}>
                <PrimaryButton
                  children={"Submit"}
                  colorType={"green"}
                  type="submit"
                />
              </div>
            </form>
          )}
        </div>
      </div>

      <CustomModal
        showModal={modal}
        children={
          <Flight setModalOpen={setModalOpen} setAddFlight={setAddFlight} />
        }
      />

      <CustomModal showModal={showSendModel}>
        <div style={{ width: "420px" }}>
          <FaCheckCircle className={styles.done} />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
            style={{ textAlign: "center" }}
          >
            Your Message has been Successfully Sent
          </p>
        </div>
      </CustomModal>
    </div>
  );
}

interface FlightProps {
  setModalOpen: any;
  setAddFlight: any;

  // setAmbulance: any;
}
const Flight = (props: Partial<FlightProps>) => {
  const [markAmenities, setMarkAmenities] = useState<string[]>([]);
  const { setModalOpen, setAddFlight } = props;

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const formikFlight = useFormik({
    initialValues: {
      flightType: "",
      from: "",
      to: "",
      departDate: "",
      departTime: "",
      arrivalDate: "",
      arrivalTime: "",
      flightNo: "",
    },
    validationSchema: Yup.object(flightAddSchema),
    onSubmit: (values) => {
      FlighthandleSubmit();
    },
  });

  const handleCheckboxChange = (value: string) => {
    if (!markAmenities.includes(value)) {
      setMarkAmenities([...markAmenities, value]);
    } else {
      setMarkAmenities(markAmenities.filter((item: any) => item !== value));
    }
  };

  const FlighthandleSubmit = () => {
    if (markAmenities.length === 0) {
      alert("MArk Amenties");
    } else {
      console.log("Submit");

      const currentData = formikFlight.values;
      let params = {
        fightType: currentData.flightType,
        from: currentData.from,
        to: currentData.to,
        departureDate: currentData.departDate,
        departureTime: currentData.departTime,
        arrivalDate: currentData.arrivalDate,
        arrivalTime: currentData.arrivalTime,
        flightNo: currentData.flightNo,
        amenities: markAmenities,
      };
      console.log("PARAMS", params);
      setAddFlight((prevFlights: Flight[]) => [...prevFlights, params]);
      setModalOpen(false);
    }
  };

  const handleDepartureDate = (date: any) => {
    const formattedDate = dayjs(date).format("DD-MM-YYYY");
    console.log("departDate", formattedDate);
    formikFlight.setFieldValue("departDate", formattedDate);
  };

  const handleArrivalDate = (date: any) => {
    const formattedDate = dayjs(date).format("DD-MM-YYYY");
    console.log("arrivalDate", formattedDate);
    formikFlight.setFieldValue("arrivalDate", formattedDate);
  };

  const handleDepartureTime = (newTime: any) => {
    console.log("departTime", newTime);
    formikFlight.setFieldValue("departTime", newTime);
  };

  const handleArrivalTime = (newTime: any) => {
    console.log("arrivalTime", newTime);
    formikFlight.setFieldValue("arrivalTime", newTime);
  };
  return (
    <div>
      <form onSubmit={formikFlight.handleSubmit}>
        <div className={styles.end}>
          <button className={styles.close} onClick={handleCloseModal}>
            &#10006;
          </button>
        </div>
        <div className={classNames(commonstyles.flxBetween, commonstyles.mt56)}>
          <p
            className={classNames(
              commonstyles.fs18,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Flight
          </p>
        </div>
        <div>
          <div className={classNames(commonstyles.flx)}>
            <div className={styles.w25}>
              <CustomInput
                placeholder="Flight Type"
                id="flightType"
                name="flightType"
                type="text"
                onChange={formikFlight.handleChange}
                value={formikFlight.values.flightType}
              />
              {formikFlight.touched.flightType &&
              formikFlight.errors.flightType ? (
                <div className={classNames(commonStyles.error)}>
                  *{formikFlight.errors.flightType}
                </div>
              ) : null}
            </div>{" "}
            <div className={styles.w25}>
              <CustomInput
                placeholder="From"
                id="from"
                name="from"
                type="text"
                onChange={formikFlight.handleChange}
                value={formikFlight.values.from}
              />
              {formikFlight.touched.from && formikFlight.errors.from ? (
                <div className={classNames(commonStyles.error)}>
                  *{formikFlight.errors.from}
                </div>
              ) : null}
            </div>{" "}
            <div className={styles.w25}>
              <CustomInput
                placeholder="To"
                id="to"
                name="to"
                type="text"
                onChange={formikFlight.handleChange}
                value={formikFlight.values.to}
              />
              {formikFlight.touched.to && formikFlight.errors.to ? (
                <div className={classNames(commonStyles.error)}>
                  *{formikFlight.errors.to}
                </div>
              ) : null}
            </div>{" "}
            <div className={styles.w25}>
              <Datepicker
                placeholder="Departure date"
                setData={handleDepartureDate}
              />
              {formikFlight.touched.departDate &&
              formikFlight.errors.departDate ? (
                <div className={classNames(commonStyles.error)}>
                  *{formikFlight.errors.departDate}
                </div>
              ) : null}
            </div>{" "}
            <div className={styles.w25}>
              <CustomTimePicker
                placeholder="Departure  time"
                setData={handleDepartureTime}
              />
              {formikFlight.touched.departTime &&
              formikFlight.errors.departTime ? (
                <div className={classNames(commonStyles.error)}>
                  *{formikFlight.errors.departTime}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classNames(commonstyles.flx)}>
            <div className={classNames(styles.w25, styles.mt7)}>
              <Datepicker
                placeholder="Arrival date"
                setData={handleArrivalDate}
              />
              {formikFlight.touched.arrivalDate &&
              formikFlight.errors.arrivalDate ? (
                <div className={classNames(commonStyles.error)}>
                  *{formikFlight.errors.arrivalDate}
                </div>
              ) : null}
            </div>{" "}
            <div className={classNames(styles.w25, styles.mt7)}>
              <CustomTimePicker
                placeholder="Arrival time"
                setData={handleArrivalTime}
              />
              {formikFlight.touched.arrivalTime &&
              formikFlight.errors.arrivalTime ? (
                <div className={classNames(commonStyles.error)}>
                  *{formikFlight.errors.arrivalTime}
                </div>
              ) : null}
            </div>{" "}
            <div className={classNames(styles.w25, styles.mt7)}>
              <CustomInput
                placeholder="Flight no"
                id="flightNo"
                name="flightNo"
                type="text"
                onChange={formikFlight.handleChange}
                value={formikFlight.values.flightNo}
              />
              {formikFlight.touched.flightNo && formikFlight.errors.flightNo ? (
                <div className={classNames(commonStyles.error)}>
                  *{formikFlight.errors.flightNo}
                </div>
              ) : null}
            </div>
          </div>

          <p
            className={classNames(
              commonstyles.fs18,
              commonstyles.semiBold,
              commonstyles.colorBlue,
              styles.mb16,
              styles.mt24
            )}
          >
            Flight Amenities
          </p>
          <div className={commonstyles.flx}>
            <div className={styles.amenties}>
              <Checkbox
                className={styles.checkbox}
                value="In-Flight Entertainment"
                onChange={() => handleCheckboxChange("In-Flight Entertainment")}
              />
              <p>In-Flight Entertainment</p>
            </div>{" "}
            <div className={styles.amenties}>
              <Checkbox
                className={styles.checkbox}
                value="Wi-Fi"
                onChange={() => handleCheckboxChange("Wi-Fi")}
              />
              <p>Wi-Fi Access</p>
            </div>{" "}
            <div className={styles.amenties}>
              <Checkbox
                className={styles.checkbox}
                value="Beverage"
                onChange={() => handleCheckboxChange("Beverage")}
              />
              <p>Beverage</p>
            </div>{" "}
            <div className={styles.amenties}>
              <Checkbox
                className={styles.checkbox}
                value="Reading Material"
                onChange={() => handleCheckboxChange("Reading Material")}
              />
              <p>Reading Material</p>
            </div>
          </div>
          <div className={classNames(commonstyles.flx)}>
            <div className={styles.amenties}>
              <Checkbox
                className={styles.checkbox}
                value="Comfort Items"
                onChange={() => handleCheckboxChange("Comfort Items")}
              />
              <p>Comfort Items</p>
            </div>{" "}
            <div className={styles.amenties}>
              <Checkbox
                className={styles.checkbox}
                value="Basic Toiletries"
                onChange={() => handleCheckboxChange("Basic Toiletries")}
              />
              <p>Basic Toiletries</p>
            </div>{" "}
          </div>
        </div>
        <div className={styles.submit}>
          <PrimaryButton
            children={"Add Flight"}
            colorType={"green"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
