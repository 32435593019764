import React from "react";
import { Route, Routes } from "react-router-dom";
import CategoryProperty from "./categoryProperty";
import MainProperty from "./mainProperty";
import commonstyles from "shared/utils/common.module.css";
import Breadcurm from "./Breadcrum";
import ApartmentsList from "./ApartmentsList";
import ApartmentYourListing from "./ApartmentYourListing";
import ApartmentPropertylisting from "./ApartmentPropertylisting";
import HomeGuestbook from "./HomeGuestbook";
import Homepropertycategory from "./Homeproperty category";
import HomeYourListing from "./HomeYourListing";
import HomeBreadcrum from "./HomeBreadcrum";
import HotelBreadcrum from "./HotelBreadcrum";
import ApartmentBreadcrum from "./HotelBreadcrum";
import CompleteHotel from "./Breadcrum/complete/completeHotel";
import MianPageProperty from "./HotelBreadcrum/MainPageProperty";

export default function HotelProperty() {
  return (
    <div className={commonstyles.col12}>
      <Routes>
        <Route path="/mainProperty" element={<MainProperty />} />
        <Route path="/categoryProperty" element={<CategoryProperty />} />
        <Route path="/breadcrum/:name" element={<Breadcurm />} />
        <Route path="/complete/Hotel" element={<CompleteHotel />} />
        <Route path="/Apartment/complete" element={<CompleteHotel />} />
        <Route path="/home/complete" element={<CompleteHotel />} />
        <Route path="/Main/Page" element={<MianPageProperty />} />
        <Route path="/Description" element={<MianPageProperty />} />

        <Route
          path="/apartmentPropertylisting"
          element={<ApartmentPropertylisting />}
        />
        <Route path="/homeGuestbook" element={<HomeGuestbook />} />
        <Route
          path="/homePropertyCategory"
          element={<Homepropertycategory />}
        />
        <Route path="/homeBreadcrum" element={<HomeBreadcrum />} />
        <Route path="/apartmentBreadcrum" element={<ApartmentBreadcrum />} />
      </Routes>
    </div>
  );
}
