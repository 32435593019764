import React, { useState } from "react";
import MainTravelStyles from "../../mainTravel.module.css";
import { ArrowBack, CustomStepper, PrimaryButton } from "shared/components";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import HotelVerfication from "./HotelVerfication/Index";
import HotelSocial from "./HotelSocial/Index";
import HotelBasicInfo from "./HotelBasicInfo/Index";
import HotelBankDetail from "./HotelBankDetail/Index";
import { useNavigate } from "react-router-dom";
import Logo from "assets/images/Hotel 2.png";
import { IoArrowBack } from "react-icons/io5";

const steps = [
  {
    id: "1",
    lable: "Basic Info",
  },
  {
    id: "2",
    lable: "Social",
  },
  {
    id: "3",
    lable: "Bank Detail",
  },
  {
    id: "4",
    lable: "Verification",
  },
];
const HotelSignup = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/traveltourism/hotel/login");
  };
  const [screenName, setScreenName] = useState("Basic Info");
  const [selectedStep, setSelectedStep] = useState(0);

  const handleClickNext = () => {
    if (screenName == "Basic Info") {
      setScreenName("Social");
    }

    if (screenName == "Social") {
      setScreenName("Bank Detail");
    }

    if (screenName == "Bank Detail") {
      setScreenName("Verification");
    }

    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "Basic Info") {
      handleGoBack();
    }
    if (screenName == "Social") {
      setScreenName("Basic Info");
    }

    if (screenName == "Bank Detail") {
      setScreenName("Social");
    }

    if (screenName == "Verification") {
      setScreenName("Bank Detail");
    }
    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };

  return (
    <div
      className={classNames(MainTravelStyles.flxSign, commonStyles.container)}
    >
      <div
        className={classNames(
          commonStyles.col7,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <div className={classNames(commonStyles.flx)}>
          <div className={classNames(commonStyles.mr24)}>
            <IoArrowBack
              onClick={handleClicKPrev}
              className={MainTravelStyles.BackIcon}
            />
          </div>
          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs14,
              MainTravelStyles.mr203,
              commonStyles.colorBlue
            )}
          >
            Previous
          </p>

          <p
            className={classNames(
              commonStyles.semiBold,
              commonStyles.fs24,
              commonStyles.m0
            )}
          >
            Sign Up
          </p>
        </div>
        <div className={classNames(commonStyles.col11, commonStyles.mt40)}>
          <CustomStepper steps={steps} selectedStep={selectedStep} />
        </div>
        <div className={classNames(commonStyles.col12, commonStyles.mt40)}>
          {screenName == "Basic Info" && (
            <HotelBasicInfo handleClickNext={handleClickNext} />
          )}
          {screenName == "Social" && (
            <HotelSocial handleClickNext={handleClickNext} />
          )}
          {screenName == "Bank Detail" && (
            <HotelBankDetail handleClickNext={handleClickNext} />
          )}
          {screenName == "Verification" && <HotelVerfication />}
        </div>
      </div>
      <div
        className={classNames(
          commonStyles.col4,
          commonStyles.colsm12,
          commonStyles.colmd12
        )}
      >
        <img src={Logo} className={commonStyles.loginVector} />
      </div>
    </div>
  );
};

export default HotelSignup;
