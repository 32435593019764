import React, { useState } from "react";
import sidebar from "./sidebar.module.css";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { FaUserXmark } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "assets/images/m-logo.png";
import { FiMenu } from "react-icons/fi";
interface Props {
  menuItem: any;
}

const Sidebar = (props: Partial<Props>) => {
  const { menuItem } = props;
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const { systemType } = useSelector((state: any) => state.root.common);
  let initial = `${systemType}/dashboard`;

  const [activeMenuItem, setActiveMenuItem] = useState(initial);

  const handleMenuItemClick = (path: string) => {
    setActiveMenuItem(path);
  };
  const toggleSidebarVisibility = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <div className={sidebar.container}>
      <div className={classNames(sidebar.sidebar)}>
        <div className={sidebar.centerlogo}>
          <img src={logo} className={sidebar.logo} />
          <FiMenu className={sidebar.menu} onClick={toggleSidebarVisibility} />
        </div>
        <div className={sidebar.conatiner}>
          {menuItem.map((item: any, index: any) => (
            <NavLink
              to={item.path}
              key={index}
              className={classNames(sidebar.link, {
                [sidebar.active]: activeMenuItem === item.path,
              })}
              onClick={() => handleMenuItemClick(item.path)}
            >
              <div className={classNames(sidebar.icon)}>{item.icon}</div>
              <div className={sidebar.sidebarNames}>
                <div
                  className={classNames(sidebar.fs10, commonStyles.semiBold)}
                >
                  {item.name}
                </div>
              </div>
            </NavLink>
          ))}
        </div>
        {isSidebarVisible && (
          <div
            style={{
              position: "absolute",
              zIndex: "1",
              backgroundColor: "white",
              padding: "16px 0 0spx 0",
              borderRadius: "10px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            }}
          >
            {menuItem.map((item: any, index: any) => (
              <NavLink
                to={item.path}
                key={index}
                className={classNames(sidebar.link2, {
                  [sidebar.active2]: activeMenuItem === item.path,
                })}
                onClick={() => handleMenuItemClick(item.path)}
              >
                <div className={classNames(sidebar.icons2)}>{item.icon}</div>
                <div className={sidebar.sidebarNames}>
                  <div
                    className={classNames(
                      commonStyles.fs10,
                      commonStyles.semiBold
                    )}
                  >
                    {item.name}
                  </div>
                </div>
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
