import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./faqs.module.css";
import commonstyles from "shared/utils/common.module.css";
import NavBarr from "../NavBarr";
import { IoIosArrowForward } from "react-icons/io";
import Footerr from "../Footer";
export default function FAQpage() {
  useEffect(() => {
    document.title = "MediTour Global | FAQ's";
    window.scrollTo(0, 0);
  });
  const [selected, setSelected] = useState("PATIENTS");

  const handleSelect = (text: any) => {
    setSelected(text);
  };
  return (
    <div>
      <div className={styles.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            styles.fs48,
            commonstyles.semiBold,
            styles.mianheading
          )}
        >
          Frequently Asked Question
        </p>
        <div className={styles.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              styles.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, styles.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              styles.mianheading
            )}
          >
            FAQ's
          </p>
        </div>
      </div>
      <div className={styles.container}>
        <div>
          <div className={styles.flexText}>
            <p
              style={{ cursor: "pointer" }}
              className={
                selected === "PATIENTS" ? styles.underline : styles.HeaderSelect
              }
              onClick={() => handleSelect("PATIENTS")}
            >
              PATIENTS
            </p>
            <p
              style={{ cursor: "pointer" }}
              className={
                selected === "PROVIDER" ? styles.underline : styles.HeaderSelect
              }
              onClick={() => handleSelect("PROVIDER")}
            >
              PROVIDER
            </p>
          </div>
          <div>
            {selected === "PATIENTS" && (
              <div>
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    What if I need follow-up care after returning home?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    We coordinate with your healthcare provider abroad to ensure
                    you have a comprehensive follow-up care plan. Additionally,
                    we can facilitate communication between your home doctor and
                    the treating physician to manage your recovery process.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    How do I know if I am a good candidate for medical tourism?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    During your initial consultation, we will assess your
                    medical needs and personal circumstances to determine if
                    medical tourism is a suitable option for you. Factors
                    considered include the type of treatment required, your
                    overall health, and travel feasibility.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    How Does MediTour Global Ensure Patient Privacy And Data
                    Security?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    During your initial consultation, we will assess your
                    medical needs and personal circumstances to determine if
                    medical tourism is a suitable option for you. Factors
                    considered include the type of treatment required, your
                    overall health, and travel feasibility.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    How Does MediTour Global Ensure Patient Privacy And Data
                    Security?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    MediTour Global takes patient privacy and data security very
                    seriously and has implemented a range of measures to protect
                    patient information. Here are some of the ways that MediTour
                    Global ensures patient privacy and data security: Compliance
                    with international standards: MediTour Global complies with
                    international standards for data privacy and security,
                    including the General Data Protection Regulation (GDPR) and
                    the Health Insurance Portability and Accountability Act
                    (HIPAA). Data encryption: MediTour Global uses encryption
                    technology to protect patient data during transmission and
                    storage.
                    <br /> <strong>Access control:</strong> MediTour Global
                    restricts access to patient data to authorized personnel
                    only and implements access control measures to prevent
                    unauthorized access.
                    <br /> <strong>Confidentiality agreements:</strong>
                    MediTour Global requires all its employees and partners to
                    sign confidentiality agreements to ensure the protection of
                    patient data. <br /> <strong>Regular training:</strong>
                    MediTour Global provides regular training to its employees
                    and partners on data privacy and security best practices.{" "}
                    <br /> <strong> Risk assessments: </strong>
                    MediTour Global conducts regular risk assessments to
                    identify potential vulnerabilities and implement mitigation
                    measures. Incident response plan: MediTour Global has an
                    incident response plan in place to handle data breaches or
                    other security incidents.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    What kind of support does MediTour Global offer patients
                    during their medical tourism journey?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    MediTour Global provides comprehensive support to patients
                    throughout their medical tourism journey. The platform's
                    goal is to make the experience as smooth and stress-free as
                    possible for patients, so they can focus on their recovery.
                    <br />
                    Here are some of the ways in which MediTour Global supports
                    patients during their medical tourism journey: <br />{" "}
                    <strong>Personalized assistance</strong> : Each patient is
                    assigned to a coordinator who provides personalized support
                    and guidance throughout the entire process. The coordinator
                    is the patient's point of contact and is available to answer
                    questions, address concerns, and help whenever needed.
                    <br /> <strong>Pre-operative preparation:</strong> MediTour
                    Global's network of medical centers provides patients with
                    detailed instructions on how to prepare for their procedure.
                    This may include virtual consultation, price quotation,
                    travel logistics, and any assistance that can help optimize
                    the experience. <br /> <strong>Travel arrangements:</strong>{" "}
                    MediTour Global can guide patients working with medical
                    centers to make all necessary travel arrangements, including
                    arranging ground transportation, and securing
                    accommodations.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    How long does it typically take to schedule a medical
                    procedure through MediTour Global?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    The time it takes for a patient to schedule a medical the
                    procedure through MediTour Global can vary depending on
                    several factors. However, the platform strives to ensure
                    that the process is as quick and efficient as possible, so
                    patients can receive the care they need without unnecessary
                    delays.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    How does MediTour Global handle emergencies or complications
                    during medical procedures?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    In the event of an emergency or complication during a
                    medical procedure, medical centers have established
                    protocols in place to ensure that patients receive prompt
                    and appropriate medical attention.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    What if I need follow-up care after returning home?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    We coordinate with your healthcare provider abroad to ensure
                    you have a comprehensive follow-up care plan. Additionally,
                    we can facilitate communication between your home doctor and
                    the treating physician to manage your recovery process.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    Can MediTour Global provide references or testimonials from
                    other patients who have used their services?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    Yes, MediTour Global can provide references and testimonials
                    from other patients who have used their medical tourism
                    services. The platform has a section on their website where
                    patients can read reviews and testimonials from other
                    patients who have undergone medical procedures through
                    MediTour Global's network of medical centers.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    What should I pack for my medical trip?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    We provide a detailed checklist tailored to your specific
                    treatment and destination. Generally, it includes personal
                    identification, medical records, medications, comfortable
                    clothing, and any specific items recommended by your
                    healthcare provider.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    Can I bring a companion with me?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    Yes, we can arrange travel and accommodation for a companion
                    to accompany you. Having a companion can provide emotional
                    support and help you feel more comfortable during your
                    treatment and recovery.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    Can MediTour Global provide a breakdown of costs, including
                    fees for medical services, travel, and accommodation?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    MediTour Global can provide a detailed breakdown of costs,
                    including fees for medical services, travel, and
                    accommodation, to ensure that clients have a clear
                    understanding of all the expenses involved in their medical
                    tourism experience.
                  </p>
                </div>
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    What happens in case of an emergency during my medical trip?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    We ensure you have access to emergency services at your
                    treatment destination. Our team is available 24/7 to assist
                    you in case of any emergencies, ensuring you receive prompt
                    and effective care.
                  </p>
                </div>
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    How Can I Partner With MediTour Global To Offer My Medical
                    Services?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    Healthcare providers looking to partner with MediTour Global
                    to offer their medical services, register themselves and
                    their medical services through MediTour Global MobileApp or
                    website.
                  </p>
                </div>
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    How Do I Communicate With Patients?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    MediTour Global Mobile App and Web Portal has the most
                    innovative and secure direct patient and provider
                    communication platform. Providers will communicate via a
                    secure CRM system that has built-in messenger, email, phone
                    , and video calls. Our App and Web Portal assures digital
                    transparency and safety for all parties involved. MediTour
                    Global does not allow any outside-the-platform
                    communications.
                  </p>
                </div>
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    Does MediTour Global Provide Customer Support?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    MediTour Global provides 24/7 account management customer
                    support to the providers.
                  </p>
                </div>
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    What Is Case Management Fees And How Does It Work?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    Like any platform, MediTour Global charges only a service
                    fee from Doctors, Hospitals, or any other service provider.
                    MediTour doesn’t charge the patient.
                  </p>
                </div>
              </div>
            )}
            {selected === "PROVIDER" && (
              <div>
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    How Can I Partner With MediTour Global To Offer My Medical
                    Services?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    Healthcare providers looking to partner with MediTour Global
                    to offer their medical services, register themselves and
                    their medical services through MediTour Global MobileApp or
                    website.
                  </p>
                </div>
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    How Do I Communicate With Patients?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    MediTour Global Mobile App and Web Portal has the most
                    innovative and secure direct patient and provider
                    communication platform. Providers will communicate via a
                    secure CRM system which has built-in messenger, email, phone
                    , and video calls. Our App and Web Portal assures digital
                    transparency and safety for all parties involved. MediTour
                    Global does not allow any outside-the-platform
                    communications.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    Does MediTour Global Provide Customer Support?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    MediTour Global provides 24/7 account management customer
                    support to the providers.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    What Is Case Management Fees And How Does It Work?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    Like any platform, MediTour Global charges only a service
                    fee from Doctors, Hospitals, or any other service provider.
                    MediTour doesn’t charge the patient.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    What Are The Eligibility Requirements For Medical Providers
                    To Work With MediTour Global?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    The eligibility requirements for medical providers to work
                    with MediTour Global are as follows:
                    <br /> <strong> Quality standards:</strong> Medical
                    providers must meet the international quality standards of
                    their respective countries. This includes holding valid
                    licenses and certifications and maintaining high standards
                    of patient care and safety.
                    <br /> <strong> English language proficiency:</strong>
                    Medical providers should have a good command of English
                    language, as this is the primary language used for
                    communication with MediTour Global and its clients. <br />{" "}
                    <strong> Experience and expertise:</strong>
                    Medical providers should have extensive experience in their
                    respective medical fields and be experts in their
                    specialties.
                    <br /> <strong>Availability: </strong> Medical providers
                    must be available to respond to patient inquiries and
                    provide medical consultations within a reasonable time
                    frame.
                    <br /> <strong>Customer service skills: </strong>
                    Medical providers should have strong customer service skills
                    and be able to provide personalized attention to their
                    patients. Technology: Medical providers should have the
                    necessary technology and infrastructure.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    What Are The Benefits Of Partnering With MediTour Global?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    Partnering with MediTour Global offers several benefits for
                    healthcare providers, including:
                    <br /> <strong>Increased visibility: </strong> MediTour
                    Global provides healthcare providers with increased
                    visibility and exposure to Local and international patients
                    who are seeking medical treatment options. Access to a wider
                    patient pool: By partnering with MediTour Global, Healthcare
                    providers can expand their patient pool beyond their local
                    area to reach patients from around the world. <br />{" "}
                    <strong>Cost savings:</strong>
                    MediTour Global can help healthcare providers reduce their
                    marketing and promotional expenses by providing access to
                    its global network of patients. <br />{" "}
                    <strong> Personalized assistance:</strong>
                    MediTour Global provides healthcare providers with account
                    managers who assist them throughout patient journeys.
                    <br /> <strong>Quality assurance:</strong> MediTour Global
                    ensures that healthcare providers meet international quality
                    standards and provides ongoing monitoring to maintain the
                    quality of care provided. <br />{" "}
                    <strong> Competitive advantage:</strong> By partnering with
                    MediTour Global, healthcare providers can gain a competitive
                    advantage by offering a broader range of medical treatments
                    and procedures to their patients.
                    <br /> <strong> Increased revenue: </strong> Partnering with
                    MediTour Global can help healthcare providers increase their
                    revenue by attracting more patients and offering a broader
                    range of medical services. Overall, partnering with MediTour
                    Global can help healthcare providers expand their patient
                    pool, increase their revenue, and improve the quality of
                    care provided to their patients.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    How does MediTour Global ensure the safety and quality of
                    medical procedures and treatments?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    MediTour Global takes the safety and quality of medical
                    procedures and treatments very seriously and has implemented
                    several measures to ensure that patients receive safe and
                    effective care. Here are some ways that MediTour Global
                    ensures the safety and quality of medical procedures and
                    treatments: <br />{" "}
                    <strong>
                      Partnering with accredited and certified medical
                      providers:
                    </strong>{" "}
                    MediTour Global partners only with qualified medical
                    providers who are accredited and certified by local and
                    international organizations. <br />{" "}
                    <strong>Screening of medical providers:</strong> MediTour
                    Global conducts screening of medical providers before
                    partnering with them. This includes reviewing licenses,
                    certifications, and credentials, as well as checking
                    customer reviews. The screening process ensures that the
                    medical providers meet MediTour Global's standards for
                    quality and safety. <br />{" "}
                    <strong>Patient feedback:</strong> MediTour Global collects
                    feedback from patients who have used its services, including
                    reviews of medical providers and facilities. This
                    information is used to evaluate the quality of services
                    offered and to identify areas for improvement.
                    <br /> <strong>Ongoing monitoring:</strong> MediTour Global
                    monitors its medical providers and services regularly to
                    ensure that they maintain high standards of quality. This
                    includes monitoring patient outcomes, reviewing compliance
                    with international standards, and overall customer
                    experience. <br /> <strong>Quality assurance team:</strong>
                    MediTour Global's quality assurance team is responsible for
                    ensuring that medical providers and facilities meet the
                    company's standards for quality and safety. The team
                    regularly conducts audits to ensure compliance with
                    international quality standards and identifies areas for
                    improvement.
                    <br />{" "}
                    <strong>Compliance with international standards:</strong>
                    MediTour Global complies with international standards for
                    data privacy and security.
                    <br /> <strong> Professionalism and ethics:</strong>
                    MediTour Global ensures that its medical providers adhere to
                    ethical and professional standards in the delivery of their
                    services.
                  </p>
                </div>{" "}
                <div className={classNames(styles.mt40)}>
                  <p
                    className={classNames(
                      styles.fs28,
                      commonstyles.semiBold,
                      styles.colorBlue
                    )}
                  >
                    What are the eligibility requirements for using MediTour
                    Global's services?
                  </p>{" "}
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      styles.textcolor,
                      styles.mt8
                    )}
                  >
                    There are no specific eligibility requirements for using
                    MediTour Global's medical tourism services. The platform is
                    open to all patients seeking medical care abroad, regardless
                    of their age, gender, nationality, or medical condition.
                    <br />
                    However, it is essential to note that patients must be
                    medically stable and healthy enough to travel and undergo
                    the medical procedure. In some cases, medical centers may
                    require patients to undergo a pre-operative assessment to
                    evaluate their health status and ensure that they are fit
                    for the procedure.
                    <br />
                    Additionally, International patients must have valid travel
                    documents, including passports and visas, if necessary, to
                    enter the country where the medical procedure is taking
                    place. Our Visa Services can assist them in getting the visa
                    too.
                    <br />
                    MediTour Global's International patient services are
                    particularly beneficial for patients who are facing long
                    wait times or high treatment costs in their home countries.
                    Patients who require specialized medical treatments or
                    procedures that are not available in their home countries
                    can also benefit from MediTour Global's network of medical
                    providers and facilities.
                  </p>
                </div>{" "}
              </div>
            )}
          </div>
        </div>
        <div></div>
      </div>

      <Footerr />
    </div>
  );
}
