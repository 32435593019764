import React, { useEffect, useState } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import style from "./CustomerDetail.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { rentcarCUSTOMERDETAIL } from "shared/services/RentaCar";

const CustomerDescription = () => {
  interface USER {
    age?: number;
    cnic?: string;
    createdAt?: string;
    dropoffDateTime?: string;
    dropoffLocation?: string;
    name?: string;
    phone?: string;
    pickupDateTime?: string;
    pickupLocation?: string;
    rentACarId?: string;
    status?: string;
    totalAmount?: number;
    updatedAt?: string;
    userId?: {
      _id?: string;
      name?: string;
      email?: string;
      gender?: string;
      mrNo?: string;
      phone?: string;
      dateOfBirth?: string;
      password?: string;
      blocked?: boolean;
      fcmToken?: string;
      addresses?: any[];
      favourites?: any[];
      carRentalDetails?: any[];
      createdAt?: string;
      updatedAt?: string;
      __v?: number;
      userImage?: string;
    };
    vehicleModel?: string;
    orderId?: string;
    withDriver?: boolean;
  }
  interface VEHICLE {
    vehicleName?: string;
    vehicleModel?: string;
    vehicleVinNo?: string;
    vehicleColour?: string;
  }

  const { state } = useLocation();
  const id = state?.id;
  const type = state?.type;
  const [Data, setData] = useState<USER>({});
  const [vehicle, setVehicle] = useState<VEHICLE>({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const isOrderPage = location.pathname;
  console.log(currentPath, ".............currentPath");
  const textToShow = currentPath.includes("rentacar/history")
    ? "History"
    : "Order Details";

  const handleGoBack = () => {
    if (type === "order") {
      navigate("/rentacar/order");
    } else {
      navigate("/rentacar/history");
    }
  };

  const fetchDetails = () => {
    if (id) {
      rentcarCUSTOMERDETAIL(id)
        .then((res: any) => {
          console.log("rentcarCUSTOMERDETAIL", res);
          setData(res?.data?.customerDetail || {});
          setVehicle(res?.data?.customerDetail?.vehicleId || {});
        })
        .catch((err: any) => {
          console.log("send error.nnnnn..", err?.response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [id]);

  return (
    <div className={classNames(commonstyles.col12, commonstyles.colorBlue)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={classNames(commonstyles.flxBetween, commonstyles.mb32)}>
          <div className={commonstyles.flx}>
            <IoArrowBack
              className={style.back}
              onClick={handleGoBack}
              style={{ marginRight: 20 }}
            />
            <p className={classNames(commonstyles.fs24, commonstyles.semiBold)}>
              {textToShow}
            </p>
          </div>

          <div className={classNames(commonstyles.flx)}>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.mr8
              )}
            >
              Order Id:
            </p>
            <p className={classNames(commonstyles.fs24)}>{Data?.orderId}</p>
          </div>
        </div>

        <div
          className={classNames(
            commonstyles.flxBetween,
            commonstyles.mt56,
            commonstyles.mb8
          )}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Customer Name:
          </p>

          <p>{Data?.name}</p>
        </div>

        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Customer Phone No:
          </p>

          <p>{Data?.phone}</p>
        </div>

        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb40)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Id Card No:
          </p>

          <p>{Data?.cnic}</p>
        </div>

        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Pick up Date:
          </p>

          <p>
            {Data?.pickupDateTime
              ? new Date(Data.pickupDateTime).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              : "__"}
          </p>
        </div>

        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Pick up Time:
          </p>

          {Data?.pickupDateTime ? (
            <p>
              {new Date(Data.pickupDateTime).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </p>
          ) : null}
        </div>

        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb40)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Pick up Location:
          </p>

          <p className={classNames(commonstyles.flxEnd)}>
            {Data?.pickupLocation}
          </p>
        </div>

        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Drop Off Date:
          </p>

          <p>
            {Data?.dropoffDateTime
              ? new Date(Data.dropoffDateTime).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              : "__"}
          </p>
        </div>

        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Drop Off Time:
          </p>

          {Data?.dropoffDateTime ? (
            <p>
              {new Date(Data.dropoffDateTime).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </p>
          ) : null}
        </div>

        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb40)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Drop Off Location:
          </p>

          <p>{Data?.dropoffLocation}</p>
        </div>

        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Vehicle Name:
          </p>

          <p>{vehicle?.vehicleName}</p>
        </div>

        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Vehicle Model:
          </p>

          <p>{vehicle?.vehicleModel}</p>
        </div>

        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Vehicle No:
          </p>

          <p>{vehicle?.vehicleVinNo}</p>
        </div>

        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            Vehicle Color:
          </p>

          <p>{vehicle?.vehicleColour}</p>
        </div>
        <div
          className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
          style={{ width: "70%", alignItems: "center" }}
        >
          <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
            With Driver:
          </p>

          <p>{Data?.withDriver ? "Yes" : "No"}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomerDescription;
