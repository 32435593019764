import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './ticketRequest.module.css';

const FlightRoundDetailForm: React.FC = () => {
  const formik = useFormik({
    initialValues: {
      companyName: '',
      flightType: 'Direct',
      from: '',
      to: '',
      departureDate: '',
      departureTime: '',
      arrivalDate: '',
      arrivalTime: '',
      flightNo: '',
      readingMaterial: false,
      wifiAccess: false,
      inflightEntertainment: false,
      beverages: false,
      comfortItems: false,
      basicToiletries: false,
      lightMeal: false,
      noOfHandbag: '',
      baggageWeight: '',
      cancellationDuration: '',
      cancellationDeduction: '',
      ticketPrice: ''
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required('Required'),
      from: Yup.string().required('Required'),
      to: Yup.string().required('Required'),
      departureDate: Yup.string().required('Required'),
      arrivalDate: Yup.string().required('Required'),
      flightNo: Yup.string().required('Required'),
      ticketPrice: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      console.log(values);
      // Handle form submission here
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={styles.formContainer}>
      <div className={styles.userInfo}>
        <h2 className={styles.heading}>User</h2>
        <div className={styles.userDetails}>
          <p><strong>Salim Qureshi</strong></p>
          <div className={styles.flightSummary}>
            <div className={styles.flightInfo}>
              <p>From</p>
              <p>Lahore</p>
              <p>To</p>
              <p>Saudi Arabia</p>
              <p>Departure</p>
              <p>17, Feb 2024</p>
            </div>
            <div className={styles.flightInfo}>
              <p>From</p>
              <p>Saudi Arabia</p>
              <p>To</p>
              <p>Lahore</p>
              <p>Departure</p>
              <p>17, Feb 2024</p>
            </div>
            <div className={styles.flightOtherInfo}>
              <p>Class</p>
              <p>Economy</p>
              <p>Traveler</p>
              <p>adult 1, children 1, infant 1</p>
            </div>
          </div>
        </div>
      </div>

      <h2 className={styles.heading}>Flight Details</h2>
      <div className={styles.flightDetails}>
        <div className={styles.flightRow}>
          <div className={styles.fieldGroup}>
            <label htmlFor="companyName">Company Name</label>
            <input
              id="companyName"
              type="text"
              {...formik.getFieldProps('companyName')}
            />
            {formik.touched.companyName && formik.errors.companyName ? (
              <div className={styles.error}>{formik.errors.companyName}</div>
            ) : null}
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="flightType">Direct</label>
            <select
              id="flightType"
              {...formik.getFieldProps('flightType')}
            >
              <option value="Direct">Direct</option>
              <option value="Indirect">Indirect</option>
            </select>
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="from">From</label>
            <input
              id="from"
              type="text"
              {...formik.getFieldProps('from')}
            />
            {formik.touched.from && formik.errors.from ? (
              <div className={styles.error}>{formik.errors.from}</div>
            ) : null}
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="to">To</label>
            <input
              id="to"
              type="text"
              {...formik.getFieldProps('to')}
            />
            {formik.touched.to && formik.errors.to ? (
              <div className={styles.error}>{formik.errors.to}</div>
            ) : null}
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="departureDate">Departure date</label>
            <input
              id="departureDate"
              type="date"
              {...formik.getFieldProps('departureDate')}
            />
            {formik.touched.departureDate && formik.errors.departureDate ? (
              <div className={styles.error}>{formik.errors.departureDate}</div>
            ) : null}
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="departureTime">Departure time</label>
            <input
              id="departureTime"
              type="time"
              {...formik.getFieldProps('departureTime')}
            />
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="arrivalDate">Arrival date</label>
            <input
              id="arrivalDate"
              type="date"
              {...formik.getFieldProps('arrivalDate')}
            />
            {formik.touched.arrivalDate && formik.errors.arrivalDate ? (
              <div className={styles.error}>{formik.errors.arrivalDate}</div>
            ) : null}
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="arrivalTime">Arrival time</label>
            <input
              id="arrivalTime"
              type="time"
              {...formik.getFieldProps('arrivalTime')}
            />
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="flightNo">Flight no</label>
            <input
              id="flightNo"
              type="text"
              {...formik.getFieldProps('flightNo')}
            />
            {formik.touched.flightNo && formik.errors.flightNo ? (
              <div className={styles.error}>{formik.errors.flightNo}</div>
            ) : null}
          </div>
        </div>

        <h3 className={styles.subHeading}>Flight Amenities</h3>
        <div className={styles.flightAmenities}>
          <div className={styles.checkboxGroup}>
            <input
              id="readingMaterial"
              type="checkbox"
              {...formik.getFieldProps('readingMaterial')}
            />
            <label htmlFor="readingMaterial">Reading Material</label>
          </div>
          <div className={styles.checkboxGroup}>
            <input
              id="wifiAccess"
              type="checkbox"
              {...formik.getFieldProps('wifiAccess')}
            />
            <label htmlFor="wifiAccess">Wi-Fi Access</label>
          </div>
          <div className={styles.checkboxGroup}>
            <input
              id="inflightEntertainment"
              type="checkbox"
              {...formik.getFieldProps('inflightEntertainment')}
            />
            <label htmlFor="inflightEntertainment">In-flight entertainment</label>
          </div>
          <div className={styles.checkboxGroup}>
            <input
              id="beverages"
              type="checkbox"
              {...formik.getFieldProps('beverages')}
            />
            <label htmlFor="beverages">Beverages</label>
          </div>
          <div className={styles.checkboxGroup}>
            <input
              id="comfortItems"
              type="checkbox"
              {...formik.getFieldProps('comfortItems')}
            />
            <label htmlFor="comfortItems">Comfort Items</label>
          </div>
          <div className={styles.checkboxGroup}>
            <input
              id="basicToiletries"
              type="checkbox"
              {...formik.getFieldProps('basicToiletries')}
            />
            <label htmlFor="basicToiletries">Basic Toiletries</label>
          </div>
          <div className={styles.checkboxGroup}>
            <input
              id="lightMeal"
              type="checkbox"
              {...formik.getFieldProps('lightMeal')}
            />
            <label htmlFor="lightMeal">Light meal</label>
          </div>
        </div>

        <h3 className={styles.subHeading}>Flight Policies</h3>
        <div className={styles.flightPolicies}>
          <div className={styles.fieldGroup}>
            <label htmlFor="noOfHandbag">No. of Handbag</label>
            <input
              id="noOfHandbag"
              type="number"
              {...formik.getFieldProps('noOfHandbag')}
            />
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="baggageWeight">Baggage weight</label>
            <input
              id="baggageWeight"
              type="number"
              {...formik.getFieldProps('baggageWeight')}
            />
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="cancellationDuration">Cancellation duration</label>
            <input
              id="cancellationDuration"
              type="number"
              {...formik.getFieldProps('cancellationDuration')}
            />
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="cancellationDeduction">Cancellation deduction %</label>
            <input
              id="cancellationDeduction"
              type="number"
              {...formik.getFieldProps('cancellationDeduction')}
            />
          </div>
        </div>

        <div className={styles.totalPrice}>
          <h3 className={styles.subHeading}>Total Price for Traveler</h3>
          <div className={styles.fieldGroup}>
            <label htmlFor="ticketPrice">Ticket Price</label>
            <input
              id="ticketPrice"
              type="text"
              placeholder="PKR"
              {...formik.getFieldProps('ticketPrice')}
            />
            {formik.touched.ticketPrice && formik.errors.ticketPrice ? (
              <div className={styles.error}>{formik.errors.ticketPrice}</div>
            ) : null}
          </div>
        </div>

        <button type="submit" className={styles.bidButton}>
          Bid
        </button>
      </div>
    </form>
  );
};

export default FlightRoundDetailForm;
