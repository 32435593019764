import ParamedicStaffForget from "pages/HomeServices/ParamedicStaff/Forgetpassword/ParamedicStaffForget";
import ParamedicStaffForget1 from "pages/HomeServices/ParamedicStaff/Forgetpassword/ParamedicStaffForget";
import ParamedicStaffupdatepassword from "pages/HomeServices/ParamedicStaff/Forgetpassword/ParamedicStaffupdatepassword";
import ParamedicStaffForget2 from "pages/HomeServices/ParamedicStaff/Forgetpassword/ParamedicStaffupdatepassword";
import ParamedicStafflogin from "pages/HomeServices/ParamedicStaff/login";
import ParamedicStaffSignup from "pages/HomeServices/ParamedicStaff/signup";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

const ParamedicStaffauth = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<ParamedicStafflogin />} />
      <Route path="/signup" element={<ParamedicStaffSignup />} />
      <Route path="/forgot-password" element={<ParamedicStaffForget />} />
      <Route
        path="/update-password"
        element={<ParamedicStaffupdatepassword />}
      />
    </Routes>
  );
};

export default ParamedicStaffauth;
