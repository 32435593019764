import React, { useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Checkbox, Switch } from "@mui/material";
import MainTravelStyle from "../../../../mainTravel.module.css";
import style from "./Privacy.module.css";
import CustomSelect from "shared/components/CustomSelect";
import CustomizedSwitche from "shared/components/SwitchButton";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";
import { useSelector } from "react-redux";
import { hotelPrivacy } from "shared/utils";
import { Navigate, useNavigate } from "react-router-dom";
import { hotelAddBnB } from "shared/services/Hotel";

const Rating = ["6 PM", "7 PM", "8 PM", "9 PM"];
const Yes = ["Yes", "No"];
const ChargesMayApply = ["Yes", "No"];
const lastNight = [
  "last Night",
  "Before 1 Day",
  "Before 2 Day",
  "Before 3 Day",
];
const Chargesapply = ["Yes", "No"];
interface Props {
  handleClickNext: any;
  name: any;
}
export default function Privacy(props: Partial<Props>) {
  const navigate = useNavigate();
  const { handleClickNext, name } = props;
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState("");
  const [chargesornot, setChargesorNot] = useState("");
  const { hotelB_B } = useSelector((state: any) => state.root.hotel);
  console.log("data is final screen.............", hotelB_B);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleSelect = (selectedOption: string) => {
    setSelectedOption(selectedOption);
    formik.setFieldValue("advanceCancelfreeofCharge", selectedOption);
  };

  const handleChargesMAyApplyorNot = (selectedOption: string) => {
    setChargesorNot(selectedOption);
    formik.setFieldValue("chargesMayApplyorNot", selectedOption);
  };

  const handleSelectPets = (selectedOptionValue: string) => {
    formik.setFieldValue("pets", selectedOptionValue);
  };

  const handleSelectCharges = (selectedOption: string) => {
    setSelectedOption(selectedOption);
    formik.setFieldValue("chargesofPetApply", selectedOption);
  };
  const SwitchHandle = (value: any) => {
    console.log(value);
    formik.setFieldValue("switch", value);
  };
  const CheckInFrom = (time: string) => {
    formik.setFieldValue("checkinFrom", time);
  };
  const CheckInToSelector = (time: string) => {
    formik.setFieldValue("checkinTo", time);
  };
  const CheckOutFromSelector = (time: string) => {
    formik.setFieldValue("checkOutFrom", time);
  };
  const CheckOutToSelector = (time: string) => {
    formik.setFieldValue("checkOutTo", time);
  };
  const handleCheckboxChange = (string: any) => {
    setIsChecked(string);
    formik.setFieldValue("children", string);
  };
  const formik = useFormik({
    initialValues: {
      advanceCancelfreeofCharge: "",
      switch: "",
      checkinFrom: "",
      checkinTo: "",
      checkOutFrom: "",
      checkOutTo: "",
      children: "",
      childrenAgeFrom: "",
      childrenAgeTo: "",
      chargesMayApplyorNot: "",
      chargesofChild: "",
      pets: "",
      chargesofPetApply: "",
      chargesofPets: "",
    },
    validationSchema: Yup.object(hotelPrivacy),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    console.log("Formik values", formik.values);
    let currentData = formik.values;
    let params = {
      category: name,
      propertyName: hotelB_B.propertyName,
      starRating: hotelB_B.startRating,
      customName: hotelB_B.userName,
      contactNumber: hotelB_B.contactNo,
      alternativeContactNo: hotelB_B.alternativeCellNo,
      postCode: hotelB_B.postCode,
      location: {
        lng: 74.3587,
        lat: 31.5204,
        address: hotelB_B.propertyAddress,
        city: hotelB_B.propertyAddress,
      },
      rooms: hotelB_B.rooms,
      parkingAvailability: hotelB_B.parkingAvailability,
      priceOfParking: hotelB_B.priceForParking,
      language: hotelB_B.spokenLanguageYourStaff,
      facilities: hotelB_B.facilities,
      propertySurroundings: hotelB_B.propertySurroundings,
      extraBedAvailability: hotelB_B.extraBedAvailability.toString(),
      noOfExtraBeds: hotelB_B.noOfExtraBeds,
      guestsInExtraBeds: hotelB_B.guestsInExtraBeds,
      amenities: hotelB_B.amenities,
      propertyphoto: hotelB_B.propertyphotos,

      advanceCancelfreeofCharge: currentData.advanceCancelfreeofCharge,
      accidentalBookingPolicy: currentData.switch,
      policies: {
        checkInFrom: currentData.checkinFrom,
        checkInTo: currentData.checkinTo,
        checkOutFrom: currentData.checkOutFrom,
        checkOutTo: currentData.checkOutTo,
      },
      accomodateChildren: currentData.children,
      childrenAgeFrom: currentData.childrenAgeFrom,
      childrenAgeTo: currentData.childrenAgeTo,
      applyCharges: currentData.chargesMayApplyorNot,
      charges: currentData.chargesofChild,
      pets: currentData.pets.toString(),
      stayOfPets: currentData.chargesofPetApply,
      chargesOfPets: currentData.chargesofPets,
    };

    console.log("PARAMS BEFOR API HITTING", params);
    hotelAddBnB(params)
      .then((res: any) => {
        console.log("Response of API Hit", res);
        navigate("/hotel/property/home/complete");
      })
      .catch((err: any) => {
        console.log("Error:", err);
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className={commonstyles.colorBlue}>
      <form onSubmit={formik.handleSubmit}>
        <p className={classNames(commonstyles.fs20, commonstyles.semiBold)}>
          Polices
        </p>
        <p
          className={classNames(
            commonstyles.fs16,
            commonstyles.semiBold,
            style.mt8
          )}
        >
          Specify some basic policies. Do you allow children or pets? How
          flexible are you with cancellations?
        </p>
        <div className={classNames(style.mt16)}>
          <div className={classNames(commonstyles.col6)}>
            <div>
              <div className={classNames(commonstyles.col10)}>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold,
                    style.mt32
                  )}
                >
                  How many days in advance can guests cancel free of charge?
                </p>
                <div className={classNames(commonstyles.mb28, style.mt8)}>
                  <CustomSelect
                    placeholder="Day of arrival (6 pm)"
                    options={Rating}
                    onSelect={handleSelect}
                  />
                  {formik.touched.advanceCancelfreeofCharge &&
                  formik.errors.advanceCancelfreeofCharge ? (
                    <div className={classNames(commonStyles.error)}>
                      *{formik.errors.advanceCancelfreeofCharge}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={commonstyles.flx}>
          <div className={commonstyles.col3}>
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Policy Against Accidental Booking
            </p>
          </div>
          <CustomizedSwitche setData={SwitchHandle} />
          {formik.touched.switch && formik.errors.switch ? (
            <div className={classNames(commonStyles.error)}>
              *{formik.errors.switch}
            </div>
          ) : null}
        </div>
        <div className={classNames(commonstyles.col10, style.mt24)}>
          <p className={classNames(commonstyles.fs16)}>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
        </div>
        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            style.mt32
          )}
        >
          Polices
        </p>
        <div className={classNames(commonstyles.col5, style.flx, style.mt32)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomTimePicker
              placeholder="Check In From"
              setData={CheckInFrom}
              onTimeSelect={(selectedTime) => {
                // Handle selected time here
                console.log("Selected time:", selectedTime);
                // You can set selected time to state or perform any other action
              }}
            />
            {formik.touched.checkinFrom && formik.errors.checkinFrom ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.checkinFrom}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonstyles.col6)}>
            <CustomTimePicker
              placeholder="Check In To"
              setData={CheckInToSelector}
              onTimeSelect={(selectedTime) => {
                // Handle selected time here
                console.log("Selected time:", selectedTime);
                // You can set selected time to state or perform any other action
              }}
            />
            {formik.touched.checkinTo && formik.errors.checkinTo ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.checkinTo}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.col5, style.flx, style.mt32)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomTimePicker
              placeholder="Check Out From"
              setData={CheckOutFromSelector}
              onTimeSelect={(selectedTime) => {
                // Handle selected time here
                console.log("Selected time:", selectedTime);
                // You can set selected time to state or perform any other action
              }}
            />
            {formik.touched.checkOutFrom && formik.errors.checkOutFrom ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.checkOutFrom}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonstyles.col6)}>
            <CustomTimePicker
              placeholder="Check Out To"
              setData={CheckOutToSelector}
              onTimeSelect={(selectedTime) => {
                // Handle selected time here
                console.log("Selected time:", selectedTime);
                // You can set selected time to state or perform any other action
              }}
            />
            {formik.touched.checkOutTo && formik.errors.checkOutTo ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.checkOutTo}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <p
            className={classNames(
              commonstyles.fs20,
              commonstyles.semiBold,
              style.mt32
            )}
          >
            Children
          </p>
          <p className={classNames(commonstyles.fs16, style.mt16)}>
            Can you accommodate children? (You can specify the ages and prices
            later)
          </p>
          <div
            className={classNames(
              commonstyles.flx,
              commonstyles.col3,
              style.mt16
            )}
          >
            <div className={classNames(commonstyles.flx, commonstyles.col6)}>
              <Checkbox
                checked={isChecked === "Yes"}
                onChange={() => handleCheckboxChange("Yes")}
                className={style.chexkbox}
              />
              <p>Yes</p>
            </div>
            <div className={classNames(commonstyles.flx, commonstyles.col6)}>
              <Checkbox
                checked={isChecked === "No"}
                onChange={() => handleCheckboxChange("No")}
                className={style.chexkbox}
              />
              <p>No</p>
            </div>
          </div>
          {formik.touched.children && formik.errors.children ? (
            <div className={classNames(commonStyles.error)}>
              *{formik.errors.children}
            </div>
          ) : null}
        </div>

        {isChecked === "Yes" && (
          <div className={classNames(commonstyles.flx)}>
            <div className={classNames(commonstyles.col4, commonstyles.mr32)}>
              <CustomInput
                placeholder="childrenAgeFrom"
                name="childrenAgeFrom"
                id="childrenAgeFrom"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.childrenAgeFrom}
              />
            </div>
            <div className={classNames(commonstyles.col4, commonstyles.mr32)}>
              <CustomInput
                placeholder="childrenAgeTo"
                name="childrenAgeTo"
                id="childrenAgeTo"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.childrenAgeTo}
              />
            </div>
            <div className={classNames(commonstyles.col4, commonstyles.mr32)}>
              <CustomSelect
                placeholder="chargesMayApply"
                options={ChargesMayApply}
                onSelect={handleChargesMAyApplyorNot}
              />
            </div>
            {chargesornot === "Yes" && (
              <div className={classNames(commonstyles.col4, commonstyles.mr32)}>
                <CustomInput
                  placeholder="Charges "
                  name="chargesofChild"
                  id="chargesofChild"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.chargesofChild}
                />
              </div>
            )}
          </div>
        )}

        <div>
          <p
            className={classNames(
              commonstyles.fs20,
              commonstyles.semiBold,
              style.mt32
            )}
          >
            Pets
          </p>
          <p className={classNames(commonstyles.fs16, style.mt16)}>
            Some guest like to travel with their furry friends, Indicates if you
            allow pets and if any charges apply.
          </p>
          <div
            className={classNames(
              commonstyles.flx,
              commonstyles.col6,
              style.mt16
            )}
          >
            <div className={classNames(commonstyles.col4, commonstyles.mr32)}>
              <CustomSelect
                onSelect={handleSelectPets}
                options={Yes}
                placeholder="Select"
              />
              {formik.touched.pets && formik.errors.pets ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.pets}
                </div>
              ) : null}
            </div>

            <div className={classNames(commonstyles.col4, commonstyles.mr32)}>
              <CustomSelect
                onSelect={handleSelectCharges}
                placeholder="Charges May Apply"
                options={Chargesapply}
              />
              {formik.touched.chargesofPetApply &&
              formik.errors.chargesofPetApply ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.chargesofPetApply}
                </div>
              ) : null}
            </div>

            {selectedOption === "Yes" && (
              <div className={classNames(commonstyles.col4)}>
                <CustomInput
                  placeholder="Charges Of Pets"
                  name="chargesofPets"
                  id="chargesofPets"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.chargesofPets}
                />
                {formik.touched.chargesofPets && formik.errors.chargesofPets ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.chargesofPets}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
        <div
          style={{ marginTop: "32px" }}
          className={classNames(MainTravelStyle.buttonWidth)}
        >
          <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
}
