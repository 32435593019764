import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './ticketRequest.module.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import commonstyles from "shared/utils/common.module.css"
import {
  travelAgencyADDBID,
  travelGETRequestDetails,
} from "shared/services/TravelAgency";

interface Flight {
  id?: number;
  from?: string;
  to?: string;
  departureDate?: string;
  departureTime?: string;
  arrivalDate?: string;
  arrivalTime?: string;
  flightNo?: string;
  forik: string;
}

interface Details {
  from?: string;
  to?: string;
  departure?: string;
  adult?: number;
  infant?: number;
  children?: number;
  class?: string;
  requestType?: string;
}

interface User {
  name?: string;
}

const FlightDetailsForm: React.FC = () => {
  const [flightType, setFlightType] = useState('Direct');
  const params = useParams();
  console.log("Params:", params); // Check what params is returning

  const { id } = params;
  console.log("Current ID from useParams:", id);  // Debugging step
  const [flights, setFlights] = useState<Flight[]>([{ id: 1, from: '', to: '', departureDate: '', departureTime: '', arrivalDate: '', arrivalTime: '', flightNo: '', forik: '' }]);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const [details, setDetails] = useState<Details>({});
  const [user, setUser] = useState<User>({});
  const [submitted, setSubmitted] = useState(false);

  const { ticketRequests } = useSelector((state: any) => state.root.travelagency);

  const handleFlightTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFlightType(event.target.value);
    setSubmitted(false);
  };

  const fetchBookingTickets = () => {
    setLoading(true);
    if (typeof id === "string") {
      travelGETRequestDetails(id)
        .then((res: any) => {
          console.log(".......getrequestDetails....", res);
          setDetails(res?.data?.request);
          setUser(res?.data?.request?.userId);

        })
        .catch((err: any) => {
          console.log("send error.nnnnn..", err.response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
      setLoading(false);
    }
  };

  const handleInputChange = <K extends keyof Flight>(index: number, field: K, value: Flight[K]) => {
    const newFlights = [...flights];
    newFlights[index][field] = value;
    setFlights(newFlights);
  };

  const addMoreFlights = () => {
    setFlights([...flights, { id: flights.length + 1, from: '', to: '', departureDate: '', departureTime: '', arrivalDate: '', arrivalTime: '', flightNo: '', forik: '' }]);
  };

  const removeFlight = (id: number) => {
    setFlights(flights.filter((flight) => flight.id !== id));
  };

  const validateFields = () => {
    const newErrors: any = {};

    flights.forEach((flight, index) => {
      if (!flight.from) newErrors[`from-${index}`] = 'This field is required';
      if (!flight.to) newErrors[`to-${index}`] = 'This field is required';
      if (!flight.departureDate) newErrors[`departureDate-${index}`] = 'This field is required';
      if (!flight.departureTime) newErrors[`departureTime-${index}`] = 'This field is required';
      if (!flight.arrivalDate) newErrors[`arrivalDate-${index}`] = 'This field is required';
      if (!flight.arrivalTime) newErrors[`arrivalTime-${index}`] = 'This field is required';
      if (!flight.flightNo) newErrors[`flightNo-${index}`] = 'This field is required';
      if (!flight.forik) newErrors[`forik-${index}`] = 'This field is required';
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveClick = () => {
    setSubmitted(true);
    if (validateFields()) {
      alert("All fields are valid, you can proceed with saving.");
      // Proceed with the save logic
    }
  };

  const handleBidClick = () => {
    setSubmitted(true);
    if (validateFields()) {
      alert("All fields are valid, you can proceed with bidding.");
      // Proceed with the bid logic
    }
  };

  useEffect(() => {
    if (ticketRequests && Array.isArray(ticketRequests)) {
      console.log("🚀 ~ useEffect ~ ticketRequests:", ticketRequests);

      const requestDetails = ticketRequests.find((request: any) => String(request._id) === String(id));

      if (requestDetails) {
        const flightDetails = requestDetails.flights && requestDetails.flights.length > 0 ? requestDetails.flights[0] : {};
        console.log("🚀 ~ useEffect ~ requestDetails:", requestDetails);

        setDetails({
          from: flightDetails.from,
          to: flightDetails.to,
          departure: flightDetails.departure,
          adult: requestDetails.adult,
          infant: requestDetails.infant,
          children: requestDetails.children,
          class: requestDetails.flightClass,
          requestType: requestDetails.requestType,
        });
        setUser({
          name: requestDetails.userId?.name,
        });
      } else {
        console.error("Request not found with ID:", id);
      }
    } else {
      console.error("ticketRequests is not defined or not an array");
    }
  }, [id, ticketRequests]);

  return (
    <div className={styles.container}>
      <div className={styles.userDetailsCard}>
        <p className={classNames(styles.userName)}>{user?.name}</p>
        <div className={styles.userFlightInfo}>
          <div>
            <p className={styles.label}>From</p>
            <p className={styles.value}>{details.from}</p>
          </div>
          <div>
            <p className={styles.label}>To</p>
            <p className={styles.value}>{details.to || 'N/A'}</p>
          </div>
          <div>
            <p className={styles.label}>Departure</p>
            <p className={styles.value}>{details.departure || 'N/A'}</p>
          </div>
          <div>
            <p className={styles.label}>Class</p>
            <p className={styles.value}>{details.class || 'N/A'}</p>
          </div>
          <div>
            <p className={styles.label}>Traveler</p>
            <p className={styles.value}>Adult: {details.adult || 0}, Children: {details.children || 0}, Infant: {details.infant || 0}</p>
          </div>
        </div>
      </div>

      <div className={styles.flightDetails}>
        <p className={styles.sectionTitle}>Flight Details</p>
        <div className={styles.formGrid}>
          <div className={styles.formGroup}>
            <label className={styles.formLabel}>Company Logo</label>
            <input type="file" className={styles.fileInput} required />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.formLabel}>Company name</label>
            <input type="text" className={styles.textInput} required />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.formLabel}>Flight Type</label>
            <select
              className={styles.selectInput}
              value={flightType}
              onChange={handleFlightTypeChange}
              required
            >
              <option value="Direct">Direct</option>
              <option value="Stay">Stay</option>
            </select>
          </div>
        </div>

        {flightType === 'Direct' ? (
          <>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>From</label>
                <input
                  type="text"
                  className={styles.textInput}
                  value={flights[0].from}
                  onChange={(e) => handleInputChange(0, 'from', e.target.value)}
                  required
                />
                {submitted && errors['from-0'] && (
                  <p className={classNames(styles.errorText, commonstyles.error)}>{errors['from-0']}</p>
                )}
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>To</label>
                <input
                  type="text"
                  className={styles.textInput}
                  value={flights[0].to}
                  onChange={(e) => handleInputChange(0, 'to', e.target.value)}
                  required
                />
                {submitted && errors['to-0'] && (
                  <p className={classNames(styles.errorText, commonstyles.error)}>{errors['to-0']}</p>
                )}
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>Departure date</label>
                <input
                  type="date"
                  className={styles.dateInput}
                  value={flights[0].departureDate}
                  onChange={(e) => handleInputChange(0, 'departureDate', e.target.value)}
                  required
                />
                {submitted && errors['departureDate-0'] && (
                  <p className={classNames(styles.errorText, commonstyles.error)}>{errors['departureDate-0']}</p>
                )}
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>Departure time</label>
                <input
                  type="time"
                  className={styles.timeInput}
                  value={flights[0].departureTime}
                  onChange={(e) => handleInputChange(0, 'departureTime', e.target.value)}
                  required
                />
                {submitted && errors['departureTime-0'] && (
                  <p className={classNames(styles.errorText, commonstyles.error)}>{errors['departureTime-0']}</p>
                )}
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>Arrival date</label>
                <input
                  type="date"
                  className={styles.dateInput}
                  value={flights[0].arrivalDate}
                  onChange={(e) => handleInputChange(0, 'arrivalDate', e.target.value)}
                  required
                />
                {submitted && errors['arrivalDate-0'] && (
                  <p className={classNames(styles.errorText, commonstyles.error)}>{errors['arrivalDate-0']}</p>
                )}
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>Arrival time</label>
                <input
                  type="time"
                  className={styles.timeInput}
                  value={flights[0].arrivalTime}
                  onChange={(e) => handleInputChange(0, 'arrivalTime', e.target.value)}
                  required
                />
                {submitted && errors['arrivalTime-0'] && (
                  <p className={classNames(styles.errorText, commonstyles.error)}>{errors['arrivalTime-0']}</p>
                )}
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>Flight no</label>
                <input
                  type="text"
                  className={styles.textInput}
                  value={flights[0].flightNo}
                  onChange={(e) => handleInputChange(0, 'flightNo', e.target.value)}
                  required
                />
                {submitted && errors['flightNo-0'] && (
                  <p className={classNames(styles.errorText, commonstyles.error)}>{errors['flightNo-0']}</p>
                )}
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>Forik</label>
                <input
                  type="text"
                  className={styles.textInput}
                  value={flights[0].forik}
                  onChange={(e) => handleInputChange(0, 'forik', e.target.value)}
                  required
                />
                {submitted && errors['forik-0'] && (
                  <p className={classNames(styles.errorText, commonstyles.error)}>{errors['forik-0']}</p>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            {flights.map((flight, index) => (
              <React.Fragment key={flight.id}>
                <div className={styles.flightTitleContainer}>
                  <p className={styles.sectionTitle}>Flight {index + 1}</p>
                </div>
                <div className={styles.formGrid}>
                  <div className={styles.formGroup}>
                    <label className={styles.formLabel}>From</label>
                    <input
                      type="text"
                      className={styles.textInput}
                      value={flight.from}
                      onChange={(e) => handleInputChange(index, 'from', e.target.value)}
                      required
                    />
                    {submitted && errors[`from-${index}`] && (
                      <p className={classNames(styles.errorText, commonstyles.error)}>{errors[`from-${index}`]}</p>
                    )}
                  </div>
                  <div className={styles.formGroup}>
                    <label className={styles.formLabel}>To</label>
                    <input
                      type="text"
                      className={styles.textInput}
                      value={flight.to}
                      onChange={(e) => handleInputChange(index, 'to', e.target.value)}
                      required
                    />
                    {submitted && errors[`to-${index}`] && (
                      <p className={classNames(styles.errorText, commonstyles.error)}>{errors[`to-${index}`]}</p>
                    )}
                  </div>
                  <div className={styles.formGroup}>
                    <label className={styles.formLabel}>Departure date</label>
                    <input
                      type="date"
                      className={styles.dateInput}
                      value={flight.departureDate}
                      onChange={(e) => handleInputChange(index, 'departureDate', e.target.value)}
                      required
                    />
                    {submitted && errors[`departureDate-${index}`] && (
                      <p className={classNames(styles.errorText, commonstyles.error)}>{errors[`departureDate-${index}`]}</p>
                    )}
                  </div>
                  <div className={styles.formGroup}>
                    <label className={styles.formLabel}>Departure time</label>
                    <input
                      type="time"
                      className={styles.timeInput}
                      value={flight.departureTime}
                      onChange={(e) => handleInputChange(index, 'departureTime', e.target.value)}
                      required
                    />
                    {submitted && errors[`departureTime-${index}`] && (
                      <p className={classNames(styles.errorText, commonstyles.error)}>{errors[`departureTime-${index}`]}</p>
                    )}
                  </div>
                  <div className={styles.formGroup}>
                    <label className={styles.formLabel}>Arrival date</label>
                    <input
                      type="date"
                      className={styles.dateInput}
                      value={flight.arrivalDate}
                      onChange={(e) => handleInputChange(index, 'arrivalDate', e.target.value)}
                      required
                    />
                    {submitted && errors[`arrivalDate-${index}`] && (
                      <p className={classNames(styles.errorText, commonstyles.error)}>{errors[`arrivalDate-${index}`]}</p>
                    )}
                  </div>
                  <div className={styles.formGroup}>
                    <label className={styles.formLabel}>Arrival time</label>
                    <input
                      type="time"
                      className={styles.timeInput}
                      value={flight.arrivalTime}
                      onChange={(e) => handleInputChange(index, 'arrivalTime', e.target.value)}
                      required
                    />
                    {submitted && errors[`arrivalTime-${index}`] && (
                      <p className={classNames(styles.errorText, commonstyles.error)}>{errors[`arrivalTime-${index}`]}</p>
                    )}
                  </div>
                  <div className={styles.formGroup}>
                    <label className={styles.formLabel}>Flight no</label>
                    <input
                      type="text"
                      className={styles.textInput}
                      value={flight.flightNo}
                      onChange={(e) => handleInputChange(index, 'flightNo', e.target.value)}
                      required
                    />
                    {submitted && errors[`flightNo-${index}`] && (
                      <p className={classNames(styles.errorText, commonstyles.error)}>{errors[`flightNo-${index}`]}</p>
                    )}
                  </div>
                  <div className={styles.formGroup}>
                    <label className={styles.formLabel}>Forik</label>
                    <input
                      type="text"
                      className={styles.textInput}
                      value={flight.forik}
                      onChange={(e) => handleInputChange(index, 'forik', e.target.value)}
                      required
                    />
                    {submitted && errors[`forik-${index}`] && (
                      <p className={classNames(styles.errorText, commonstyles.error)}>{errors[`forik-${index}`]}</p>
                    )}
                  </div>
                </div>
                {index > 0 && (
                  <div className={styles.buttonContainer}>
                    <button
                      type="button"
                      className={styles.removeButton}
                      onClick={() => removeFlight(flight.id!)}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </React.Fragment>
            ))}
            <div className={styles.buttonContainer}>
              <button type="button" className={styles.addMoreButton} onClick={addMoreFlights}>
                + Add More
              </button>
            </div>
            <div className={styles.buttonContainer}>
              <button className={styles.saveButton} onClick={handleSaveClick}>
                Save
              </button>
            </div>
          </>
        )}

        <p className={styles.sectionTitle}>Flight Amenities</p>
        <div className={styles.amenitiesGrid}>
          <label className={styles.checkboxLabel}>
            <input type="checkbox" className={styles.checkboxInput} required />
            Reading Material
          </label>
          <label className={styles.checkboxLabel}>
            <input type="checkbox" className={styles.checkboxInput} required />
            Wi-Fi Access
          </label>
          <label className={styles.checkboxLabel}>
            <input type="checkbox" className={styles.checkboxInput} required />
            In-flight entertainment
          </label>
          <label className={styles.checkboxLabel}>
            <input type="checkbox" className={styles.checkboxInput} required />
            Beverages
          </label>
          <label className={styles.checkboxLabel}>
            <input type="checkbox" className={styles.checkboxInput} required />
            Comfort Items
          </label>
          <label className={styles.checkboxLabel}>
            <input type="checkbox" className={styles.checkboxInput} required />
            Basic Toiletries
          </label>
          <label className={styles.checkboxLabel}>
            <input type="checkbox" className={styles.checkboxInput} required />
            Light meal
          </label>
        </div>

        <p className={styles.sectionTitle}>Flight Policies</p>
        <div className={styles.formGrid}>
          <div className={styles.formGroup}>
            <label className={styles.formLabel}>No. of handbag</label>
            <input type="number" className={styles.textInput} required />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.formLabel}>Baggage weight</label>
            <input type="number" className={styles.textInput} required />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.formLabel}>Cancellation duration</label>
            <input type="text" className={styles.textInput} required />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.formLabel}>Cancellation deduction</label>
            <input type="text" className={styles.textInput} required />
          </div>
        </div>

        <p className={styles.sectionTitle}>Total Price for Traveler</p>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Ticket Price</label>
          <input type="text" className={styles.textInput} required />
        </div>

        <div className={styles.buttonContainer}>
          <button className={styles.bidButton} type="button" onClick={handleBidClick}>
            Bid
          </button>
        </div>
      </div>
    </div>
  );
};

export default FlightDetailsForm;
