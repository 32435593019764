import classNames from "classnames";
import React from "react";
import style from "./categoryProperty.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import HomeStay from "assets/images/Homestay.png";
import Home from "assets/images/house.png";
import Buliding from "assets/images/buildings.png";
import Places from "assets/images/places.png";
import Bed from "assets/images/bed.png";
import Farm from "assets/images/farm.png";
import CondoHotel from "assets/images/CondoHotel.png";
import Capsule from "assets/images/capsule.png";
import Hostal from "assets/images/hostelp.png";
import More from "assets/images/more.png";
import Country from "assets/images/country.png";
import { PrimaryButton } from "shared/components";
import { Navigate, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
function CategoryProperty() {
  const navigate = useNavigate(); // Declare once

  const handleGoBack = () => {
    navigate("/hotel/property/mainProperty");
  };
  const handleGoToDetail = (name: string) => {
    navigate(`/hotel/property/breadcrum/${name}`);
  };

  return (
    <div className={classNames(commonstyles.colorBlue)}>
      <SearchBar />
      <div className={classNames(commonstyles.mr87)}>
        <div className={classNames(commonstyles.outerContainer)}>
          <div className={classNames(commonstyles.flx)}>
            <IoArrowBack className={style.back} onClick={handleGoBack} />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Back
            </p>
          </div>
          <div className={commonstyles.mt32}>
            <p className={classNames(commonstyles.fs24, commonstyles.semiBold)}>
              Which category is the best for your place? choose your confort
              zone
            </p>
          </div>
          <div className={style.flxw}>
            <div
              className={style.caard}
              onClick={() => handleGoToDetail("hotel")}
            >
              <img src={Buliding} className={style.homes} />
              <div className={style.mt8}>
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                >
                  Hotels
                </p>
              </div>
              <div className={style.mt24}>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Accommodation for travelers often with restaurants meeting
                  rooms and other guest services
                </p>
              </div>
            </div>
            <div
              className={style.caard}
              onClick={() => handleGoToDetail("guestHouse")}
            >
              <img src={Home} className={style.homes} />
              <div className={style.mt8}>
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                >
                  Guest House
                </p>
              </div>
              <div className={style.mt24}>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Private home with separate living facilities for host and
                  guest
                </p>
              </div>
            </div>
            <div
              className={style.caard}
              onClick={() => handleGoToDetail("hostel")}
            >
              <img src={Hostal} className={style.homes} />
              <div className={style.mt8}>
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                >
                  Hostels
                </p>
              </div>
              <div className={style.mt24}>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Budget accommodations with mostly dor-style and social
                  atmosphere
                </p>
              </div>
            </div>
            <div
              className={style.caard}
              onClick={() => handleGoToDetail("bedAndBreakfast")}
            >
              <img src={Bed} className={style.homes} />
              <div className={style.mt8}>
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                >
                  Bed & Breakfast
                </p>
              </div>
              <div className={style.mt24}>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Indulge in luxury and comfort with our curated hotel
                  selection.
                </p>
              </div>
            </div>
            <div
              className={style.caard}
              onClick={() => handleGoToDetail("farmHouse")}
            >
              <img src={HomeStay} className={style.homes} />
              <div className={style.mt8}>
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                >
                  Farm House
                </p>
              </div>
              <div className={style.mt24}>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Accommodation for travelers often with restaurants meeting
                  rooms and other guest services
                </p>
              </div>
            </div>

            <div
              className={style.caard}
              onClick={() => handleGoToDetail("condoHotel")}
            >
              <img src={Farm} className={style.homes} />
              <div className={style.mt8}>
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                >
                  Condo Hotel
                </p>
              </div>
              <div className={style.mt24}>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Indulge in luxury and comfort with our curated hotel
                  selection.
                </p>
              </div>
            </div>
          </div>

          {/* <div className={style.btncenter}>
          <PrimaryButton
            children={"Next"}
            colorType={"blue"}
            onClick={handleGoToDetail}
          />
        </div> */}
        </div>
      </div>
    </div>
  );
}

export default CategoryProperty;
