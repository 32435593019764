import React, { useRef, useState } from "react";
import styles from "./navBarr.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import Logo from "assets/images/whiteLogo.png";
import { Avatar } from "@mui/material";
import { FaBars } from "react-icons/fa6";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";

export default function NavBarr() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleGoToVender = () => {
    navigate("/joinVender");
  };
  const handleGoToServices = () => {
    navigate("/ourSevices");
  };
  const handleGoToHome = () => {
    navigate("/");
  };
  const handleGoToAboutUs = () => {
    navigate("/aboutUs");
  };
  const handleGoToContactUs = () => {
    navigate("/contactUs");
  };
  const handleGoToUserLogin = () => {
    navigate("/user/login");
  };
  const handleGoToTreatments = () => {
    navigate("/treatment");
  };
  const handleGoToPatientGuide = () => {
    navigate("/patientGuide");
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isActive = (path: string) => location.pathname === path;

  const [hoveredItem, setHoveredItem] = useState<any>(null);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = (item: any) => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }
    setHoveredItem(item);
  };

  const handleMouseLeave = (item: any) => {
    hideTimeoutRef.current = setTimeout(() => {
      if (hoveredItem === item) {
        setHoveredItem(null);
      }
    }, 500); // 1 second delay
  };
  return (
    <div>
      <div className={styles.navContainer}>
        <div>
          <img src={Logo} className={styles.logo} alt="Logo" />
        </div>
        <div className={classNames(commonstyles.flx, styles.NavBar)}>
          <li
            className={classNames(commonstyles.semiBold, {
              [styles.activeLink]: isActive("/"),
            })}
            onClick={handleGoToHome}
          >
            Home
          </li>{" "}
          <li
            className={classNames(commonstyles.semiBold, {
              [styles.activeLink]: isActive("/aboutUs"),
            })}
            onClick={handleGoToAboutUs}
          >
            About us
          </li>
          <li
            className={classNames(commonstyles.semiBold, {
              [styles.activeLink]: isActive("/ourSevices"),
            })}
            onClick={handleGoToServices}
            onMouseEnter={() => handleMouseEnter("services")}
            onMouseLeave={() => handleMouseLeave("services")}
          >
            Services
            {/* {hoveredItem === "services" && (
              <div
                className={styles.hoverCard}
                onMouseEnter={() => handleMouseEnter("services")}
                onMouseLeave={() => handleMouseLeave("services")}
              >
                <p className={styles.listIetm}>Free Medical Second Opinion</p>
                <p className={styles.listIetm}>
                  Travel and Accommodation Organization
                </p>
                <p className={styles.listIetm}>
                  Assistance during/after Hospital Stay
                </p>
                <p className={styles.listIetm}>
                  Professional Translation Interpreting
                </p>
              </div>
            )} */}
          </li>
          <li
            className={classNames(commonstyles.semiBold, {
              [styles.activeLink]: isActive("/treatment"),
            })}
            onClick={handleGoToTreatments}
            onMouseEnter={() => handleMouseEnter("treatment")}
            onMouseLeave={() => handleMouseLeave("treatment")}
          >
            Treatments
            {/* {hoveredItem === "treatment" && (
              <div
                className={styles.hoverCard}
                onMouseEnter={() => handleMouseEnter("treatment")}
                onMouseLeave={() => handleMouseLeave("treatment")}
              >
                <p className={styles.listIetm}>Cosmetic Surgery</p>
                <p className={styles.listIetm}>Aesthetic</p>
                <p className={styles.listIetm}>Hair Transplant</p>
                <p className={styles.listIetm}>Stem Cell</p>
                <p className={styles.listIetm}> Fertility</p>
                <p className={styles.listIetm}>Bariatric Surgery</p>
              </div>
            )} */}
          </li>
          <li
            className={classNames(commonstyles.semiBold, {
              [styles.activeLink]: isActive("/patientGuide"),
            })}
            onClick={handleGoToPatientGuide}
            onMouseEnter={() => handleMouseEnter("patientGuide")}
            onMouseLeave={() => handleMouseLeave("patientGuide")}
          >
            Patient Guide
            {/* {hoveredItem === "patientGuide" && (
              <div
                className={styles.hoverCard}
                onMouseEnter={() => handleMouseEnter("patientGuide")}
                onMouseLeave={() => handleMouseLeave("patientGuide")}
              >
                <p className={styles.listIetm}>Why MediTour</p>
                <p className={styles.listIetm}>Why Pakistan</p>{" "}
                <p className={styles.listIetm}>Before You Travel</p>
              </div>
            )} */}
          </li>
          <li
            className={classNames(commonstyles.semiBold, {
              [styles.activeLink]: isActive("/contactUs"),
            })}
            onClick={handleGoToContactUs}
          >
            Contact us
          </li>
        </div>
        <p
          className={classNames(styles.join, {
            [styles.joinactive]: isActive("/joinVender"),
          })}
          onClick={handleGoToVender}
        >
          Join As a Vendor
        </p>
        <div className={styles.profile}>
          <p
            className={`${commonstyles.fs14} ${
              isActive("/UserLogin") ? styles.joinactive : ""
            }`}
            onClick={handleGoToUserLogin}
          >
            Log in
          </p>
          <Avatar />
        </div>

        <div>
          <FaBars
            className={styles.barbtn}
            style={{ color: "black" }}
            onClick={toggleMenu}
          />
          {isMenuOpen && (
            <>
              <div
                className={`${styles.mobileMenu} ${isMenuOpen ? "open" : ""}`}
              >
                <div className={styles.menuHeader}>
                  <img src={Logo} className={styles.Headerlogo} alt="Logo" />
                  <IoClose
                    className={styles.Headerclose}
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  />
                </div>
                <div
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Link
                    className={styles.menulist}
                    to="/"
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    Home
                  </Link>
                  <Link
                    className={styles.menulist}
                    to="/aboutUs"
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    About us
                  </Link>
                  <Link
                    className={styles.menulist}
                    to="/ourSevices"
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    Services
                  </Link>
                  <Link
                    className={styles.menulist}
                    to="/treatment"
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    Treatments
                  </Link>{" "}
                  <Link
                    className={styles.menulist}
                    to="/patientGuide"
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    Patient Guide
                  </Link>
                  <Link
                    className={styles.menulist}
                    to="/contactUs"
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    Contact us
                  </Link>
                  <Link
                    className={styles.menulist}
                    to="/joinVender"
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    Join As a Vendor
                  </Link>
                  <Link
                    className={styles.menulist}
                    to="/"
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    UserLogin
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
