import React, { useState } from "react";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import styles from "./AdVender.module.css";
import { useNavigate } from "react-router-dom";
import { getUsers } from "shared/services";
export default function AdminVender() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [vendor, setVendor] = useState([]);
  const fetchUsers = (type: any) => {
    setLoading(true);
    const page = 1;
    getUsers(page, type, "")
      .then((res: any) => {
        // console.log("ress vendor.....", res);
        setVendor(res?.data.vendors);

        if (type == "Pharmacy") {
          navigate("/admin/Users/Pharmacies", { state: res?.data.vendors });
        }

        if (type == "Laboratory") {
          navigate("/admin/Users/Laboratories", { state: res?.data.vendors });
        }

        if (type == "Hospital") {
          navigate("/admin/Users/Hospitals", { state: res?.data.vendors });
        }

        if (type == "Doctors") {
          navigate("/admin/Users/Doctors", { state: res?.data.vendors });
        }

        if (type == "Travel Agency") {
          navigate("/admin/Users/TravelAgency", { state: res?.data.vendors });
        }

        if (type == "Rent A Car") {
          navigate("/admin/Users/RentaCar", { state: res?.data.vendors });
        }
        if (type == "Donations") {
          navigate("/admin/Users/Donation", { state: res?.data.vendors });
        }
        if (type == "Insurance") {
          navigate("/admin/Users/Insurance", { state: res?.data.vendors });
        }
        if (type == "Hotels") {
          navigate("/admin/Users/Properties", { state: res?.data.vendors });
        }
      })
      .catch((err: any) => {
        console.log("err.....", err?.response?.data);
      })
      .finally(() => setLoading(false));
  };
  // const handleGoToDetail = (index: any) => {
  //   const result = Data.map((v: any, i: any) => {
  //     if (i == index) {
  //       return v;
  //     }
  //   });
  //   navigate("/admin/Users/Customer/Details", { state: result[0] });
  // };
  const handleGoNext = (type: any) => {
    fetchUsers(type);
  };

  return (
    <div className={styles.flx}>
      <div
        className={styles.PharmacyCard}
        onClick={() => handleGoNext("Pharmacy")}
      >
        <p
          className={classNames(
            commonStyles.fs22,
            commonStyles.semiBold,
            styles.mtauto
          )}
        >
          Pharmacies
        </p>
      </div>
      <div
        className={styles.LabCard}
        onClick={() => handleGoNext("Laboratory")}
      >
        <p className={classNames(commonStyles.fs22, commonStyles.semiBold)}>
          Laboratories
        </p>
      </div>
      <div
        className={styles.DoctorCard}
        onClick={() => handleGoNext("Doctors")}
      >
        <p className={classNames(commonStyles.fs22, commonStyles.semiBold)}>
          Doctors
        </p>
      </div>
      <div
        className={styles.HospitalCard}
        onClick={() => handleGoNext("Hospital")}
      >
        <p className={classNames(commonStyles.fs22, commonStyles.semiBold)}>
          Hospitals
        </p>
      </div>
      <div
        className={styles.TravelCard}
        onClick={() => handleGoNext("Travel Agency")}
      >
        <p className={classNames(commonStyles.fs22, commonStyles.semiBold)}>
          Travel Agency
        </p>
      </div>
      <div
        className={styles.RentCard}
        onClick={() => handleGoNext("Rent A Car")}
      >
        <p className={classNames(commonStyles.fs22, commonStyles.semiBold)}>
          Rent a car
        </p>
      </div>
      <div
        className={styles.PropertiesCard}
        onClick={() => handleGoNext("Hotels")}
      >
        <p className={classNames(commonStyles.fs22, commonStyles.semiBold)}>
          Properties
        </p>
      </div>
      <div
        className={styles.DonationCard}
        onClick={() => handleGoNext("Donations")}
      >
        <p className={classNames(commonStyles.fs22, commonStyles.semiBold)}>
          Donation
        </p>
      </div>
      <div
        className={styles.InsuranceCard}
        onClick={() => handleGoNext("Insurance")}
      >
        <p className={classNames(commonStyles.fs22, commonStyles.semiBold)}>
          Insurance
        </p>
      </div>{" "}
      <div
        className={styles.AmbulanceCard}
        // onClick={() => handleGoNext("Insurance")}
      >
        <p className={classNames(commonStyles.fs22, commonStyles.semiBold)}>
          Ambulane Pending
        </p>
      </div>
    </div>
  );
}
