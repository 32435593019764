import React, { useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import { Checkbox, Switch } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { orange } from "@mui/material/colors";
import style from "./Privacy.module.css";
import CustomSelect from "shared/components/CustomSelect";
import { DatePicker } from "@mui/x-date-pickers";
import Datepicker from "shared/components/DatePicker";
import CustomizedSwitche from "shared/components/SwitchButton";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";
import { hotelAddAppartmentPrivacy, hotelAddHomePrivacy } from "shared/utils";
import { hotelADDAPPARTMENT } from "shared/services/Hotel";
const arrival = [
  "1 Day before arrival",
  "2 Day before arrival",
  "3 Day before arrival",
];
const States = ["Lahore", "Karachi", "Multan", "Islambad"];
const pet = ["Yes", "No"];
const PetCharges = ["Yes", "No"];
const staynight = ["2 night", "3 night", "4 night", "5 night"];
const Staes = ["Lahore", "Karachi", "Multan", "Islambad"];
interface Props {
  handleClickNext: any;
}
export default function Privacy(props: Partial<Props>) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { handleClickNext } = props;
  const [isChecked, setIsChecked] = useState(null);
  const [children, setChildren] = useState(null);
  const { hotelAddAppartment } = useSelector((state: any) => state.root.hotel);
  console.log(",,,,,,,,,,,", hotelAddAppartment);
  const [selectedPet, setSelectedPet] = useState<string | null>(null);
  const [chargesMayApply, setChargesMayApply] = useState<string | null>(null);
  const [petCharges, setpetCharges] = useState<string | null>(null);
  const handleAdvanced = (selectedOption: string) => {
    formik.setFieldValue("advanceCancelfreeofCharge", selectedOption);
  };
  const SwitchHandle = (value: any) => {
    console.log(value);
    formik.setFieldValue("accidentalBookingPolicy", value);
  };
  const CheckInFrom = (time: string) => {
    formik.setFieldValue("checkInFrom", time);
  };
  const CheckInToSelector = (time: string) => {
    formik.setFieldValue("checkInTo", time);
  };
  const CheckOutFromSelector = (time: string) => {
    formik.setFieldValue("checkOutFrom", time);
  };
  const CheckOutToSelector = (time: string) => {
    // const dateObj = new Date(time);
    // const hours = dateObj.getHours();
    // const minutes = dateObj.getMinutes();
    // const seconds = dateObj.getSeconds();
    // // Format the time
    // const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
    //   minutes < 10 ? "0" : ""
    // }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    // // Set the formatted time to the form field
    formik.setFieldValue("checkOutTo", time);
  };
  const handleCheckboxChange = (value: any) => {
    setIsChecked(value);
    formik.setFieldValue("smoking", value);
  };
  const handleChildrenCheckboxChange = (value: any) => {
    setChildren(value);
    formik.setFieldValue("accomodateChildren", value);
  };
  const handlePetSelect = (selectedOption: string) => {
    setSelectedPet(selectedOption);
    formik.setFieldValue("pets", selectedOption);
  };
  const handleCharges = (selectedOption: string) => {
    setpetCharges(selectedOption);
    formik.setFieldValue("stayeofPets", selectedOption);
  };
  const hadleNightStay = (selectedOption: string) => {
    formik.setFieldValue("minimumStay", selectedOption);
  };

  const ChargesMyApply = (selectedOption: string) => {
    setChargesMayApply(selectedOption);
    formik.setFieldValue("chargesMayApply", selectedOption);
  };
  const formik = useFormik({
    initialValues: {
      advanceCancelfreeofCharge: "",
      accidentalBookingPolicy: "",
      checkInFrom: "",
      checkInTo: "",
      checkOutFrom: "",
      checkOutTo: "",
      smoking: "",
      accomodateChildren: "",
      childrenAgeTo: "",
      childrenAgeFrom: "",
      chargesMayApply: "",
      charges: "",
      pets: "",
      stayeofPets: "",
      chargesOfPets: "",
      minimumStay: "",
    },
    validationSchema: Yup.object(hotelAddAppartmentPrivacy),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    console.log("formik values", formik.values);
    setLoading(true);
    let currentData = formik.values;
    let params = {
      propertyName: hotelAddAppartment.propertyName,
      customName: hotelAddAppartment.name,
      contactNumber: hotelAddAppartment.contactNo,
      alternativeContactNo: hotelAddAppartment.alternativeContactNo,
      postCode: hotelAddAppartment.postCode,
      location: {
        lng: -123.123456,
        lat: 49.123456,
        address: hotelAddAppartment.propertyAddress,
        city: hotelAddAppartment.propertyAddress,
      },
      partOfCompany: hotelAddAppartment.partOfCompany,
      nameOfCompany: hotelAddAppartment.companyName,
      channelManager: hotelAddAppartment.channelManager,
      nameOfManager: hotelAddAppartment.managerName,
      apartments: [hotelAddAppartment.apartments],
      amenities: hotelAddAppartment.amenities,
      parkingAvailability: hotelAddAppartment.parking,
      priceOfParking: "22",
      propertySurroundings: hotelAddAppartment.propertySurroundings,
      language: hotelAddAppartment.LanguageSpoken,
      facilities: hotelAddAppartment.facilities,
      propertyphoto: hotelAddAppartment.propertyphotos,
      advanceCancelfreeofCharge: currentData.advanceCancelfreeofCharge,
      accidentalBookingPolicy: currentData.accidentalBookingPolicy,
      policies: {
        checkInFrom: currentData.checkInFrom,
        checkInTo: currentData.checkInTo,
        checkOutFrom: currentData.checkOutFrom,
        checkOutTo: currentData.checkOutTo,
      },
      smoking: currentData.smoking,
      accomodateChildren: currentData.accomodateChildren,
      childrenAgeTo: currentData.childrenAgeTo,
      childrenAgeFrom: currentData.childrenAgeFrom,
      applyCharges: currentData.chargesMayApply,
      charges: currentData.charges,
      pets: currentData.pets,
      stayOfPets: currentData.stayeofPets,
      chargesOfPets: Number(currentData.chargesOfPets),
      minimumStay: currentData.minimumStay,
    };
    console.log("PARAMS are..............", params);
    hotelADDAPPARTMENT(params)
      .then((res: any) => {
        console.log("Reponse is", res);
      })
      .catch((err: any) => {
        console.log("Error is", err);
      })
      .finally(() => {
        setLoading(false);
      });
    handleClickNext();
  };
  return (
    <div className={commonstyles.colorBlue}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <p className={classNames(commonstyles.fs20, commonstyles.semiBold)}>
            Polices
          </p>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              commonstyles.mb32,
              style.mt8
            )}
          >
            Specify some basic policies. Do you allow children or pets? How
            flexible are you with cancellations?
          </p>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              commonstyles.mb32
            )}
          >
            How many days in advance can guests cancel free of charge?
          </p>
          <div className={classNames(commonstyles.col4)}>
            <CustomSelect
              placeholder="Advance Cancel Free of Charge"
              options={arrival}
              onSelect={handleAdvanced}
            />
            {formik.touched.advanceCancelfreeofCharge &&
            formik.errors.advanceCancelfreeofCharge ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.advanceCancelfreeofCharge}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, style.mt32)}>
          <div className={commonstyles.col3}>
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Policy Against Accidental Booking
            </p>
          </div>
          <CustomizedSwitche setData={SwitchHandle} />
        </div>
        <div className={classNames(commonstyles.col10, style.mt24)}>
          <p className={classNames(commonstyles.fs16)}>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
        </div>
        <div className={classNames(style.flx, style.mt32)}>
          <div className={classNames(commonstyles.col4, commonstyles.mr32)}>
            <div>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.mb32,
                  style.mt32
                )}
              >
                Polices
              </p>
            </div>
            <div className={commonstyles.flx}>
              <div
                className={classNames(
                  commonstyles.mb28,
                  commonstyles.col7,
                  commonstyles.mr32
                )}
              >
                <CustomTimePicker
                  placeholder="Check In From"
                  setData={CheckInFrom}
                  onTimeSelect={(selectedTime) => {
                    // Handle selected time here
                    console.log("Selected time:", selectedTime);
                    // You can set selected time to state or perform any other action
                  }}
                />
                {formik.touched.checkInFrom && formik.errors.checkInFrom ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.checkInFrom}
                  </div>
                ) : null}
              </div>
              <div className={classNames(commonstyles.mb28, commonstyles.col7)}>
                <CustomTimePicker
                  placeholder="Check In To"
                  setData={CheckInToSelector}
                  onTimeSelect={(selectedTime) => {
                    // Handle selected time here
                    console.log("Selected time:", selectedTime);
                    // You can set selected time to state or perform any other action
                  }}
                />
                {formik.touched.checkInTo && formik.errors.checkInTo ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.checkInTo}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={commonstyles.flx}>
              <div
                className={classNames(
                  commonstyles.mb28,
                  commonstyles.col7,
                  commonstyles.mr32
                )}
              >
                <CustomTimePicker
                  placeholder="Check Out From"
                  setData={CheckOutFromSelector}
                  onTimeSelect={(selectedTime) => {
                    // Handle selected time here
                    console.log("Selected time:", selectedTime);
                    // You can set selected time to state or perform any other action
                  }}
                />
                {formik.touched.checkOutFrom && formik.errors.checkOutFrom ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.checkOutFrom}
                  </div>
                ) : null}
              </div>
              <div className={classNames(commonstyles.mb28, commonstyles.col7)}>
                <CustomTimePicker
                  placeholder="Check Out To"
                  setData={CheckOutToSelector}
                  onTimeSelect={(selectedTime) => {
                    // Handle selected time here
                    console.log("Selected time:", selectedTime);
                    // You can set selected time to state or perform any other action
                  }}
                />
                {formik.touched.checkOutTo && formik.errors.checkOutTo ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.checkOutTo}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.mt56
          )}
        >
          Smoking
        </p>
        <p
          className={classNames(
            commonstyles.fs16,
            style.mt16,
            commonstyles.semiBold
          )}
        >
          Do you allow smoking at your property?
        </p>
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.col4,
            style.mt32
          )}
        >
          <div className={classNames(commonstyles.flx, commonstyles.col3)}>
            <Checkbox
              checked={isChecked === "Yes"}
              onChange={() => handleCheckboxChange("Yes")}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Yes
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col3)}>
            <Checkbox
              checked={isChecked === "No"}
              onChange={() => handleCheckboxChange("No")}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              No
            </p>
          </div>
        </div>
        {formik.touched.smoking && formik.errors.smoking ? (
          <div className={classNames(commonStyles.error)}>
            *{formik.errors.smoking}
          </div>
        ) : null}
        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.mt56
          )}
        >
          Children
        </p>
        <p
          className={classNames(
            commonstyles.fs16,
            style.mt16,
            commonstyles.semiBold
          )}
        >
          Can you accommodate children? (You can specify the ages and prices
          later)
        </p>
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.col4,
            style.mt32
          )}
        >
          <div className={classNames(commonstyles.flx, commonstyles.col3)}>
            <Checkbox
              checked={children === "Yes"}
              onChange={() => handleChildrenCheckboxChange("Yes")}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              Yes
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.col3)}>
            <Checkbox
              checked={children === "No"}
              onChange={() => handleChildrenCheckboxChange("No")}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
              No
            </p>
          </div>
        </div>

        {children === "Yes" && (
          <div className={classNames(commonstyles.flx)}>
            <div className={classNames(commonstyles.col4, commonstyles.mr32)}>
              <CustomInput
                placeholder="childrenAgeFrom"
                name="childrenAgeFrom"
                id="childrenAgeFrom"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.childrenAgeFrom}
              />
            </div>
            <div className={classNames(commonstyles.col4, commonstyles.mr32)}>
              <CustomInput
                placeholder="childrenAgeTo"
                name="childrenAgeTo"
                id="childrenAgeTo"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.childrenAgeTo}
              />
            </div>
            <div className={classNames(commonstyles.col4, commonstyles.mr32)}>
              <CustomSelect
                placeholder="charges May Apply"
                options={pet}
                onSelect={ChargesMyApply}
              />
            </div>
            {chargesMayApply === "Yes" && (
              <div className={classNames(commonstyles.col4)}>
                <CustomInput
                  placeholder="Charges"
                  id="charges"
                  name="charges"
                  onChange={formik.handleChange}
                  value={formik.values.charges}
                />
              </div>
            )}
          </div>
        )}

        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.mt56
          )}
        >
          Pets
        </p>
        <p
          className={classNames(
            commonstyles.fs16,
            style.mt16,
            commonstyles.semiBold
          )}
        >
          Some guest like to travel with their furry friends, Indicates if you
          allow pets and if any charges apply.
        </p>
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.col4,
            style.mt32
          )}
        >
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomSelect onSelect={handlePetSelect} options={pet} />
            {formik.touched.pets && formik.errors.pets ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.pets}
              </div>
            ) : null}
          </div>
          {selectedPet === "Yes" && (
            <div className={classNames(commonstyles.col6)}>
              <CustomSelect
                onSelect={handleCharges}
                placeholder="Charges may apply"
                options={PetCharges}
              />
            </div>
          )}
          {petCharges === "Yes" && (
            <div className={classNames(commonstyles.col6)}>
              <CustomInput
                placeholder="Charges"
                id="chargesOfPets"
                name="chargesOfPets"
                onChange={formik.handleChange}
                value={formik.values.chargesOfPets}
              />
            </div>
          )}
        </div>

        <p
          className={classNames(
            commonstyles.fs20,
            commonstyles.semiBold,
            commonstyles.mt56
          )}
        >
          Minimum stay
        </p>
        <p
          className={classNames(
            commonstyles.fs16,
            style.mt16,
            commonstyles.semiBold
          )}
        >
          Do you require a minimum stay for your guest?
        </p>
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.col8,
            style.mt32
          )}
        >
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomSelect
              placeholder="2 Nights"
              onSelect={hadleNightStay}
              options={staynight}
            />
            {formik.touched.minimumStay && formik.errors.minimumStay ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.minimumStay}
              </div>
            ) : null}
          </div>
          <div className={classNames(style.infoCard)}>
            <p>
              After you complete registration you’ll be able to make changes to
              your listing before it goes live
            </p>
          </div>
        </div>
        <div
          style={{ marginTop: "32px", width: "204px", marginBottom: "16px" }}
        >
          <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
}
