import React, { useEffect } from "react";
import NavBarr from "../NavBarr";
import { debounce } from "lodash";
import classNames from "classnames";
import Rent from "assets/images/Comingsoon.png";
import style from "./ourServices.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import Footerr from "../Footer";
import { Navigate, useNavigate } from "react-router-dom";
export default function OurSevices() {
  useEffect(() => {
    document.title = "MediTour Global | Sevices";
    window.scrollTo(0, 0);
  });
  const naviagte = useNavigate();

  const handleNavigate = () => {
    naviagte("/services/doctor");
  };
  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Our Services
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Our Sevices
          </p>
        </div>
        <div className={style.container}>
          <div className={style.flexBetween}>
            <div
              className={classNames(style.ServicesCards, style.Doctor)}
              onClick={handleNavigate}
            >
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Doctor
              </p>
            </div>
            <div className={classNames(style.ServicesCards, style.Hospital)}>
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Hospital
              </p>
            </div>
            <div className={classNames(style.ServicesCards, style.lab)}>
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Laboratory
              </p>
            </div>
            <div className={classNames(style.ServicesCards, style.phrmacy)}>
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Pharmacy
              </p>
            </div>
            <div
              className={classNames(style.ServicesCards, style.Physiotherapist)}
            >
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Physiotherapist
              </p>
            </div>
            <div
              className={classNames(style.ServicesCards, style.Nutritionist)}
            >
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Nutritionist
              </p>
            </div>
            <div className={classNames(style.ServicesCards, style.Paramedic)}>
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Paramedic Staff
              </p>
            </div>
            <div
              className={classNames(style.ServicesCards, style.Psychologist)}
            >
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Psychologist
              </p>
            </div>
            <div className={classNames(style.ServicesCards, style.travel)}>
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Travel Agency
              </p>
            </div>
            <div className={classNames(style.ServicesCards, style.hotel)}>
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Hotel
              </p>
            </div>
            <div className={classNames(style.ServicesCards, style.Amblance)}>
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Ambulance
              </p>
            </div>
            <div className={classNames(style.ServicesCards, style.RentAcar)}>
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Rent A Car
              </p>
            </div>{" "}
            <div className={classNames(style.empty)}></div>
            <div className={classNames(style.ServicesCards, style.Donation)}>
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Donation
              </p>
            </div>
            <div className={classNames(style.ServicesCards, style.Insurance)}>
              <p
                style={{ marginTop: "auto" }}
                className={classNames(commonstyles.fs24, commonstyles.medium)}
              >
                Insurance
              </p>
            </div>{" "}
            <div className={classNames(style.empty)}></div>
          </div>
        </div>
        <Footerr />
      </div>
    </div>
  );
}
