import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./bankdetail.module.css";
import labMainStyles from "../../laboratoryMain.module.css";
import commonStyles from "shared/utils/common.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import { useDispatch, useSelector } from "react-redux";
import { setLabUserFormData } from "shared/redux";
import { setLabUser_ID } from "shared/redux/reducers/laboratory/labReducer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { labBankSchema } from "shared/utils";
import { labSignup } from "shared/services";
import ImgPicker from "shared/components/Img-picker";
interface Props {
  handleClickNext: any;
}

const BankDetail = (props: Partial<Props>) => {
  const [TaxFileData, setTaxFileData] = useState();
  const [TaxFileError, setTaxFileError] = useState(false);
  const { handleClickNext } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { labUserFormData } = useSelector((state: any) => state.root.lab);
  const { fcmToken } = useSelector((state: any) => state.root.common);
  const formik = useFormik({
    initialValues: {
      incomeTaxNo: "",
      saleTaxNo: "",
      bankName: "",
      accountHolderName: "",
      accountNumber: "",
      TaxFile: "",
    },
    validationSchema: Yup.object(labBankSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleTaxFileUrl = (url: any) => {
    setTaxFileData(url);
    console.log(url);
    setTaxFileError(false);

    formik.setFieldValue("TaxFile", url);
  };
  useEffect(() => {
    formik.setValues(labUserFormData);
  }, []);
  const handleSubmit = async () => {
    // dispatch(setLabUserFormData({ ...formik.values }));
    // handleClickNext();
    setLoading(true);
    let currentData = formik.values;

    let params = {
      name: labUserFormData.name,
      labLicenseNumber: labUserFormData.labLicenseNumber,
      licenseExpiry: labUserFormData.labLicenseExpiry,
      ownerFirstName: labUserFormData.labOwnerFirstName,
      ownerLastName: labUserFormData.labOwnerLastName,
      emergencyNo: labUserFormData.labEmergencyNumber,
      cnicOrPassportNo: labUserFormData.labCnicNumber,
      cnicOrPassportExpiry: labUserFormData.labCnicExpiryDate,
      fcmToken: fcmToken,
      location: {
        lat: labUserFormData.lat,
        lng: labUserFormData.lng,
        address: labUserFormData.address,
        city: labUserFormData.city,
      },
      description: labUserFormData.labDescription,
      openTime: labUserFormData.labOpenTime,
      closeTime: labUserFormData.labCloseTime,

      logo: labUserFormData.labLogo,
      labLicenseImage: labUserFormData.labLicenseImage,
      cnicImage: labUserFormData.labCnicImage,
      ...(labUserFormData.webUrl && { website: labUserFormData.webUrl }),
      ...(labUserFormData.twitterUrl && {
        twitter: labUserFormData.twitterUrl,
      }),
      ...(labUserFormData.fbUrl && { facebook: labUserFormData.fbUrl }),
      ...(labUserFormData.instaUrl && { instagram: labUserFormData.instaUrl }),

      incomeTaxNo: currentData.incomeTaxNo,
      salesTaxNo: currentData.saleTaxNo,
      bankName: currentData.bankName,
      accountHolderName: currentData.accountHolderName,
      accountNumber: currentData.accountNumber,
      taxFileImage: currentData.TaxFile,
    };

    if (!formik.values.TaxFile) {
      setTaxFileError(true);
    } else {
      labSignup(params)
        .then((res: any) => {
          console.log("Response:", res);
          handleClickNext();
          if (res.data.auth) {
            console.log("response.....", res.data);
            handleClickNext();
            dispatch(setLabUserFormData({}));
            dispatch(setLabUser_ID(res.data.laboratory._id));
          }
        })
        .catch((err: any) => {
          console.log("Error:", err);
          console.log("send error.nnnnn..", err.response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className={classNames(commonStyles.col12)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, labMainStyles.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Income Tax Number (Optional)"
                id="incomeTaxNo"
                name="incomeTaxNo"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.incomeTaxNo}
              />
              {/* {formik.touched.incomeTaxNo && formik.errors.incomeTaxNo ? ( */}
              <div className={classNames(commonStyles.error)}>
                {/* *{formik.errors.incomeTaxNo} */}
              </div>
              {/* ) : null} */}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Sales Tax Number (Optional)"
                id="saleTaxNo"
                name="saleTaxNo"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.saleTaxNo}
              />
              {/* {formik.touched.saleTaxNo && formik.errors.saleTaxNo ? ( */}
              <div className={classNames(commonStyles.error)}>
                {/* *{formik.errors.saleTaxNo} */}
              </div>
              {/* ) : null} */}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={classNames(commonStyles.mr32)}>
              <ImgPicker
                placeholder="Attach Tax File (Optional)"
                setData={handleTaxFileUrl}
              />
            </div>
            {/* {TaxFileError && (
              <div className={classNames(commonStyles.error)}>*Required</div>
            )} */}
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, labMainStyles.flx)}>
          {/* <div
          className={classNames(
            commonStyles.col4,
            commonStyles.colsm12,
            commonStyles.mtsm28
          )}
        >
          <div className={classNames(commonStyles.mr32)}>
            <CustomInput placeholder="Tax exemption Certificate" />
          </div>
        </div> */}
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Bank Name (Optional)"
                id="bankName"
                name="bankName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.bankName}
              />
              {formik.touched.bankName && formik.errors.bankName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.bankName}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Account Holder Name (Optional)"
                id="accountHolderName"
                name="accountHolderName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.accountHolderName}
              />
              {/* {formik.touched.accountHolderName && */}
              {/* formik.errors.accountHolderName ? ( */}
              <div className={classNames(commonStyles.error)}>
                {/* *{formik.errors.accountHolderName} */}
              </div>
              {/* ) : null} */}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, labMainStyles.flx)}>
          <div
            className={classNames(
              commonStyles.col12,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Bank Account Number (Optional)"
                id="accountNumber"
                name="accountNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.accountNumber}
              />
              {/* {formik.touched.accountNumber && formik.errors.accountNumber ? ( */}
              <div className={classNames(commonStyles.error)}>
                {/* *{formik.errors.accountNumber} */}
              </div>
              {/* ) : null} */}
            </div>
          </div>
        </div>
        <div className={classNames(labMainStyles.buttonWidth)}>
          <PrimaryButton
            colorType={"Linear"}
            disabled={!loading ? false : true}
            children={loading ? "loading..." : "Next"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default BankDetail;
