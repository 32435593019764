import { useEffect, useState } from "react";
import React from "react";
import Doctor_Dashboard from "shared/components/Doctor_Dashboard";

function DoctorDashboard() {
  return (
    <>
      <Doctor_Dashboard />
    </>
  );
}

export default DoctorDashboard;
