import React from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import RentACarPay from "shared/components/RentacarTables/InsurancePay";
import PerviousNextButton from "shared/components/Pagination";
import style from "./RentAcarPayment.module.css";
import { Vendor_Payments } from "shared/components";
import { useSelector } from "react-redux";

const Rent_A_Car_Payment = () => {
  const { rentcar } = useSelector((state: any) => state.root.rentcar);
  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <Vendor_Payments type={"Rent A Car"} id={rentcar?._id} />
    </div>
  );
};

export default Rent_A_Car_Payment;
