import React from "react";
import { Routes, Route } from "react-router-dom";
import UserLogin from "pages/Home/Userlogin";
import LoginEmail from "pages/Home/Userlogin/Email_Verify"; // Import your LoginEmail component
import Register_Form from "pages/Home/Userlogin/Register_Form";
import Forget_Password from "pages/Home/Userlogin/Forget_Password";

const UserAuthRoutes = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<UserLogin />} />
      <Route path="/Email_Verify" element={<LoginEmail />} />
      <Route path="/Register_Form" element={<Register_Form />} />
      <Route path="/Forget_Password" element={<Forget_Password />} />


   
    </Routes>
  );
};

export default UserAuthRoutes;
