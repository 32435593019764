import React, { useEffect } from "react";
import classNames from "classnames";
import styles from "./social.module.css";
import { useFormik } from "formik";
import { ambulanceSocialSchema } from "shared/utils";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import MainHomeServices from "../../../mainHomeServices.module.css";
import commonStyles from "shared/utils/common.module.css";
import Check from "assets/check.png";
import { setAmbulanceUserFormData } from "../../../../../shared/redux/reducers/ambulance/ambulanceReducer";
import { PrimaryButton } from "shared/components";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { CustomInput } from "shared/components";

interface Props {
  handleClickNext: any;
}
const AmbulanceSocial = (props: Partial<Props>) => {
  const dispatch = useDispatch();
  const { handleClickNext } = props;
  const { ambulanceUserFormData } = useSelector(
    (state: any) => state.root.ambulance
  );

  const formik = useFormik({
    initialValues: {
      ambulancefbUrl: "",
      ambulanceistinstaUrl: "",
      ambulancetwitterUrl: "",
      ambulancewebUrl: "",
    },
    validationSchema: Yup.object(ambulanceSocialSchema),
    onSubmit: (values) => {
      console.log("hey");
      console.log("vale", formik.values);
      handleSubmit();
    },
  });

  useEffect(() => {
    formik.setValues(ambulanceUserFormData);
  }, []);
  const handleSubmit = async () => {
    await dispatch(setAmbulanceUserFormData({ ...formik.values }));
    console.log("social", setAmbulanceUserFormData);
    handleClickNext();
  };
  return (
    <div className={classNames(commonStyles.col8, commonStyles.mt56)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Facebook Link             optional"
              id="ambulancefbUrl"
              name="ambulancefbUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.ambulancefbUrl}
            />
            {formik.touched.ambulancefbUrl && formik.errors.ambulancefbUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.ambulancefbUrl}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Instagram Link             optional"
              id="ambulanceistinstaUrl"
              name="ambulanceistinstaUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.ambulanceistinstaUrl}
            />
            {formik.touched.ambulanceistinstaUrl &&
            formik.errors.ambulanceistinstaUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.ambulanceistinstaUrl}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.mr32,
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Twitter Link               optional"
              id="ambulancetwitterUrl"
              name="ambulancetwitterUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.ambulancetwitterUrl}
            />
            {formik.touched.ambulancetwitterUrl &&
            formik.errors.ambulancetwitterUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.ambulancetwitterUrl}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.col6,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Webiste Link              optional"
              id="ambulancewebUrl"
              name="ambulancewebUrl"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.ambulancewebUrl}
            />
            {formik.touched.ambulancewebUrl && formik.errors.ambulancewebUrl ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.ambulancewebUrl}
              </div>
            ) : null}
          </div>
        </div>
        <div className={MainHomeServices.buttonWidth}>
          <PrimaryButton
            children={"Next"}
            type="submit"
            colorType={"Ambulance"}
          />
        </div>
      </form>
    </div>
  );
};

export default AmbulanceSocial;
