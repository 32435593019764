import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Style from "./PiechartDonation.module.css";

const data = [
  { name: "Gerenal", value: 400 },
  { name: "Medical", value: 300 },
  { name: "Mission Trip", value: 300 },
  { name: "Food", value: 200 },
];

const COLORS = ["#0F718C", "#71D3EE", "#BDF1FF", "#DDF7FF"];

interface CustomTooltipProps {
  active?: boolean;
  payload?: { name: string; value: number }[];
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className={Style.tooltip}>
        <p
          style={{ color: "#00276d", marginRight: "16px", fontWeight: "700" }}
          className={`${Style.tooltip}-label`}
        >{`${payload[0]?.name}:`}</p>
        <p className={`${Style.tooltip}-value`}>{`${payload[0]?.value}`}</p>
      </div>
    );
  }
  return null;
};

const DonationPieChart: React.FC = () => {
  return (
    <ResponsiveContainer width="98%" height={400}>
      <PieChart>
        <Pie
          data={data}
          cx={145}
          cy={90}
          innerRadius={50}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend
          content={
            <ul
              style={{
                padding: 0,
                textAlign: "center",
                position: "absolute",
                bottom: "80px",
                left: "17%",
              }}
            >
              {data.map((entry, index) => (
                <div className={Style.lists}>
                  <li
                    key={`item-${index}`}
                    style={{
                      color: COLORS[index % COLORS.length],
                    }}
                  >
                    {entry.name}
                  </li>
                </div>
              ))}
            </ul>
          }
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonationPieChart;
