import React from "react";
import { Routes, Route } from "react-router-dom";
import DoctorRoutes from "./Doctour_Routes/DoctorRoutes";

const ServiceRoutes = () => {
  return (
    <Routes>
      <Route path="doctor/*" element={<DoctorRoutes />} />
    </Routes>
  );
};

export default ServiceRoutes;
