import React, { useState } from "react";
import classNames from "classnames";
import MainHomeServices from "../../../mainHomeServices.module.css";
import commonStyles from "shared/utils/common.module.css";
import { ArrowBack, CustomInput } from "shared/components";
import { PiUploadSimpleFill } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";
import { paramedicStaffBasicInfoSchema } from "shared/utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "shared/components";
import { setParamedicUserFormData } from "shared/redux/reducers/paramedic/paramedicReducer";
import Upload from "../../../../../assets/images/upload.png";
import Loaction from "../../../../../assets/images/loaction.png";
import CustomSelect from "shared/components/CustomSelect";
import ImgPicker from "shared/components/Img-picker";
import { useDispatch, useSelector } from "react-redux";
import Datepicker from "shared/components/DatePicker";
import LocationInput from "shared/components/LocationInput";
const options = ["Lahore", "Karachi", "Multan", "Islambad"];
const Specialty = ["MBBS", "MPhil"];
const Experience = ["1 Year", "2 Year", "3 Year", "4 Year", "5 Year"];
const options2 = [
  "United States",
  "Canada",
  "United Kingdom",
  "Germany",
  "France",
  "Italy",
  "Australia",
  "Brazil",
  "India",
  "Japan",
  "China",
  "South Korea",
  "Mexico",
  "Russia",
  "South Africa",
  "Nigeria",
  "Argentina",
  "Spain",
  "Turkey",
  "Saudi Arabia",
];
interface Props {
  handleClickNext: any;
}

const ParamedicStaffBasicInfo = (props: Partial<Props>) => {
  const [drimage, setDrImage] = useState(false);
  const [cnicimage, setCnicImage] = useState(false);
  const [cliniclogo, setClinicLogo] = useState(false);
  const [pmdcimage, setPmdcImage] = useState(false);
  const dispatch = useDispatch();
  const { paramedicUserFormData } = useSelector(
    (state: any) => state.root.paramedic
  );

  const { handleClickNext } = props;
  const formik = useFormik({
    initialValues: {
      paramedicdrName: "",
      paramedicdrImage: "",
      paramedicCnic: "",
      paramedicCnicImage: "",
      paramedicQualification: "",
      paramedicSpeciality: "",
      paramedicService: "",
      paramedicClinicExperiences: "",
      paramedicClinicName: "",
      paramedicClinicLogo: "",
      paramedicClinicAddress: "",
      paramedicPmdcNumber: "",
      paramedicPmdcImage: "",
      paramedicEmergencyNumber: "",
    },
    validationSchema: Yup.object(paramedicStaffBasicInfoSchema),
    onSubmit: (values) => {
      console.log("hey");
      handleSubmit();
    },
  });
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };

  const handleSelectClinicExperience = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("paramedicClinicExperiences", selectedOption);
  };
  const handleSelectSpeciality = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("paramedicSpeciality", selectedOption);
  };

  const handleDrImageUrl = (url: any) => {
    console.log("paramedicdrImage", url);
    setDrImage(false);
    formik.setFieldValue("paramedicdrImage", url);
  };
  const handleCnicImageUrl = (url: any) => {
    console.log("paramedicCnicImage", url);
    setCnicImage(false);
    formik.setFieldValue("paramedicCnicImage", url);
  };
  const handleClinicLogoUrl = (url: any) => {
    console.log("paramedicClinicLogo", url);
    setClinicLogo(false);
    formik.setFieldValue("paramedicClinicLogo", url);
  };
  const handlePmdcImageUrl = (url: any) => {
    console.log("paramedicPmdcImage", url);
    setPmdcImage(false);
    formik.setFieldValue("paramedicPmdcImage", url);
  };
  const handleSubmit = async () => {
    if (!formik.values.paramedicdrImage) {
      setDrImage(true);
    } else if (!formik.values.paramedicCnicImage) {
      setCnicImage(true);
    } else if (!formik.values.paramedicClinicLogo) {
      setClinicLogo(true);
    } else if (!formik.values.paramedicPmdcImage) {
      setPmdcImage(true);
    } else {
      await dispatch(setParamedicUserFormData(formik.values));
      handleClickNext();
      setDrImage(false);
      setCnicImage(false);
      setClinicLogo(false);
      setPmdcImage(false);
    }
  };
  return (
    <div className={classNames(commonStyles.col12, commonStyles.mt56)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              {" "}
              <CustomInput
                placeholder="Dr. Name"
                id="paramedicdrName"
                name="paramedicdrName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.paramedicdrName}
              />
              {formik.touched.paramedicdrName &&
              formik.errors.paramedicdrName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.paramedicdrName}
                </div>
              ) : null}
            </div>
          </div>

          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              <CustomInput placeholder=" Last Name" />
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="CNIC / Passport Number (Optinal)"
                id="paramedicCnic"
                name="paramedicCnic"
                type="text"
                onChange={formik.handleChange}
                // value={formik.values.paramedicCnic}
              />

              {/* {formik.touched.paramedicCnic && formik.errors.paramedicCnic ? ( */}
                <div className={classNames(commonStyles.error)}>
                  {/* *{formik.errors.paramedicCnic} */}
                </div>
              {/* ) : null} */}
            </div>
          </div>

          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              {" "}
              <ImgPicker
                placeholder="CNIC / Passport Image (Optinal)"
                setData={handleCnicImageUrl}
              />
              {/* {formik.touched.paramedicCnicImage && */}
              {/* formik.errors.paramedicCnicImage ? ( */}
                <div className={classNames(commonStyles.error)}>
                  {/* *{formik.errors.paramedicCnicImage} */}
                </div>
              {/* ) : null} */}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              {" "}
              <Datepicker placeholder="Qualification" />
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col8,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              {" "}
              <CustomInput
                placeholder="Qualification"
                id="paramedicQualification"
                name="paramedicQualification"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.paramedicQualification}
              />
            </div>
            {formik.touched.paramedicQualification &&
            formik.errors.paramedicQualification ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.paramedicQualification}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              <CustomSelect
                options={Specialty}
                placeholder="Specialty"
                onSelect={handleSelectSpeciality}
              />

              {formik.touched.paramedicSpeciality &&
              formik.errors.paramedicSpeciality ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.paramedicSpeciality}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col8,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="Clinic Name"
                id="paramedicClinicName"
                name="paramedicClinicName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.paramedicClinicName}
              />
              {formik.touched.paramedicClinicName &&
              formik.errors.paramedicClinicName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.paramedicClinicName}
                </div>
              ) : null}
            </div>
          </div>

          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              <CustomSelect
                options={Experience}
                placeholder="Experience"
                onSelect={handleSelectClinicExperience}
              />
              {formik.touched.paramedicClinicExperiences &&
              formik.errors.paramedicClinicExperiences ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.paramedicClinicExperiences}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.col12,
            commonStyles.colsm12,
            commonStyles.mtsm28,
            commonStyles.mb16
          )}
        >
          {" "}
          <div className={commonStyles.mr32}>
            {/* <LocationInput placeholder="Clinic Address" /> */}
            {/* <CustomInput
                placeholder="Clinic Address"
                id="paramedicClinicAddress"
                name="paramedicClinicAddress"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.paramedicClinicAddress}
              />
              {formik.touched.paramedicClinicAddress &&
              formik.errors.paramedicClinicAddress ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.paramedicClinicAddress}
                </div>
              ) : null} */}
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="PMDC Number "
                id="paramedicPmdcNumber"
                name="paramedicPmdcNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.paramedicPmdcNumber}
              />
              {formik.touched.paramedicPmdcNumber &&
              formik.errors.paramedicPmdcNumber ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.paramedicPmdcNumber}
                </div>
              ) : null}
            </div>
          </div>

          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              {" "}
              <ImgPicker
                placeholder="PMDC Image(optional)"
                setData={handlePmdcImageUrl}
              />
              {formik.touched.paramedicPmdcImage &&
              formik.errors.paramedicPmdcImage ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.paramedicPmdcImage}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              {" "}
              <Datepicker placeholder="Dr. Emergency number" />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            MainHomeServices.flx,
            commonStyles.col12,
            commonStyles.colsm12
          )}
        >
          <div
            className={classNames(
              commonStyles.col12,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              <LocationInput
                placeholder="Clinic Address"
                setData={handleClickNext}
               
              />
            </div>
          </div>
        </div>
        <div className={classNames(MainHomeServices.buttonWidth)}>
          <PrimaryButton
            children={"Next"}
            type="submit"
            colorType={"ParamedicStaff"}
          />
        </div>
      </form>
    </div>
  );
};

export default ParamedicStaffBasicInfo;
