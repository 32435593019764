import React, { useState, ChangeEvent, useEffect } from "react";
import styles from "./order.module.css";

interface Props {
  setSelectedValue: (value: string) => void;
  initialValue: string;
  error?: boolean;
}

export default function CustomRentCarOrder({
  setSelectedValue,
  initialValue,
  error,
}: Props) {
  const [selectedOption, setSelectedOption] = useState(initialValue); // Set initial state to initialValue

  useEffect(() => {
    setSelectedOption(initialValue); // Update selected option when initialValue changes
  }, [initialValue]);

  // useEffect(() => {
  //   if (error) {
  //     setSelectedOption(initialValue);
  //   }
  // }, [error]);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
    setSelectedValue(newValue); // Pass selected value to the parent component
  };

  const getSelectClassName = () => {
    switch (selectedOption) {
      case "pending":
        return styles.pendingSelect;
      case "completed":
        return styles.completeSelect;
      case "OnRoute":
        return styles.inProcessSelect;
      default:
        return "";
    }
  };

  return (
    <div className={styles.CustomSelectOrder}>
      <select
        style={{ fontWeight: "700" }}
        className={getSelectClassName()}
        value={selectedOption}
        onChange={handleSelectChange}
      >
        <option value="pending">Pending</option>
        <option value="OnRoute">OnRoute</option>
        <option value="completed">Completed</option>
      </select>
    </div>
  );
}
