import React, { useEffect, useState } from "react";
import styles from "./order.module.css";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { PharmacyGetOrderDetail, getOrderDetails } from "shared/services";
import { LoadingModal, Order_Details } from "shared/components";
interface OrderDetails {
  discount?: number;
  grandTotal?: number;
  totalAmount?: number;
  paidByUserAmount?: any;
  currentLocation: {
    address: string;
  };
  address: string;
  testCode: number;
  MR_NO: string;
  customerName: string;
  orderId: string;
  updatedAt: string;
  _id: string;
}
interface UserDetails {
  phone: number;
}
interface QuantityDetails {
  quantity: number;
}
interface Item {
  generic: string;
  medicineName: string;
  medCode: number;
  actualPrice: number;
}
export default function PharOrderDetail() {
  const [orderdetail, setOrderdetail] = useState<OrderDetails | null>(null);
  const [user, setuser] = useState<UserDetails | null>(null);
  const [itemAmount, setItemAmount] = useState<Item[]>([]);
  const [quantities, setQuantities] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const handleGoToBack = () => {
    navigate("/pharmacy/order");
  };

  const orderDetail = () => {
    setLoading(true);
    if (id === undefined) {
      console.error("ID is undefined");
      setLoading(false);
      return;
    }
    if (id) {
      console.log("ID value:", id);

      PharmacyGetOrderDetail(id)
        .then((res: any) => {
          console.log("API Response:::::::::::::::::", res);
          setOrderdetail(res.data.order);
          setuser(res.data.order.userId);
          // setItemAmount(res.data.order.items);
          const itemIds = res.data.order.items.map((item: any) => item.itemId);
          console.log("Item IDs:", itemIds);

          setItemAmount(itemIds);

          const quantitiesArray = res.data.order.items.map(
            (item: any) => item.quantity
          );
          setQuantities(quantitiesArray);
        })
        .catch((err: any) => {
          console.log("API Error:", err?.response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
    }
  };

  useEffect(() => {
    orderDetail();
  }, []);
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <Order_Details
        data={orderdetail}
        handleGoToBack={handleGoToBack}
        title={"Order"}
      />
    </div>
  );
}
