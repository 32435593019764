import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

//...........Authentication...........

export const docSignup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DOC_SIGNUP, params);
};

export const docSendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DOC_SEND_CODE_TO_EMAIL, params);
};

export const docVerifyEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DOC_VERIFY_EMAIL, params);
};
export const docCompleteSignup = (params: any, id: any) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.DOC_COMPLETE_SIGNUP}?id=${id}`, params);
};

export const docLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DOC_LOGIN, params);
};

// DOC_LOGIN

export const getAllAppointment = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_ALL_APPOINTMENT}?page=${pageno}`);
};

export const getAllPatients = () => {
  return HTTP_CLIENT.get(ENDPOINTS.GET_ALL_PATIENTS);
};
export const getRequests = (pagenoo: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_REQUESTS}?page=${pagenoo}`);
};

export const doctorLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.DOC_LOGOUT);
};
export const getAvailability = () => {
  return HTTP_CLIENT.get(ENDPOINTS.DOC_AVAILABILITY);
};

export const resetLinkdoctor = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DOC_RESET_LINK, params);
};

export const resetPassworddoctor = (params: any, token: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.DOC_RESET_PASSWORD}?token=${token}`,
    params
  );
};
export const DocAcceptRequest = (id: string) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.DOC_ACCEPTREQUEST}?bookingId=${id}`);
};

export const DocRejectRequest = (rejectId: string) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.DOC_REJECTREQUEST}?bookingId=${rejectId}`
  );
};

export const DocGetAllPateints = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.DOC_GETALLPATIENTS}?page=${pageno}`);
};

export const DocPatientHistory = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.DOC_PATIENTHISTORY}?id=${id}`);
};

export const DocGRAPH = () => {
  return HTTP_CLIENT.get(ENDPOINTS.DOC_GRAPHHHH);
};
export const DoctGraphDETAILSUpperPortion = (dur: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.DOC_DASHBOARDDETAILS}?duration=${dur}`);
};

export const DoctorAddOnsiteAvailability = (paramss: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DOC_ADDAVAILABILITY, paramss);
};
export const DoctorGetAvailibility = () => {
  return HTTP_CLIENT.get(ENDPOINTS.DOC_GETAVAILIBILITY);
};

export const DoctorAddAvailabilityPrice = (paramss: any) => {
  return HTTP_CLIENT.patch(ENDPOINTS.DOC_AVAILABILITY_PRICE, paramss);
};

export const DoctorUpdateProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.DOC_UPDATEPROFILE, params);
};
export const DoctorGetSingleAppointment = (id: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.DOC_GETSINGLEAPPOINMENT}?appointmentId=${id}`
  );
};
export const DoctorAddAppointmentHistory = (body: any, params: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.DOC_ADDHISTORY}?patientId=${params.patientId}&appointmentId=${params.appointmentId}`,
    body
  );
};

export const DoctorAddAppointmentPrescription = (
  id: string,
  patientid: string,
  params: any
) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.DOC_ADDPRESCRIPTION}?appointmentId=${id}&patientId=${patientid}`,
    params
  );
};

export const getDoctorSearch = (searchInput: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.DOC_SEARCHDOCTOR}?name=${searchInput}`);
};

export const ReferPatientToDoctor = (id: string, patientid: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.DOC_REFERDOCTOR}?referredDoctorId=${id}&patientId=${patientid}`
  );
};
export const doctorGetAllHospitals = () => {
  return HTTP_CLIENT.get(ENDPOINTS.DOC_GETALLHOSPITALS);
};

export const doctorGetSingleHospitalAvailability = (id: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.DOC_GETSINGLEHOSPITALAVAILABILITY}?hospitalId=${id}`
  );
};
export const doctorGetPrescription = (appointmentId: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.DOC_GETPRESCRIPTION}?prescriptionId=${appointmentId}`
  );
};

export const getAllSpecialities = (page: number, search: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_SPECIALITIES}?page=${page}&search=${search}`
  );
};

// REFER_DOCTOR

export const ReferToDoctor = (body: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.REFER_DOCTOR, body);
};

export const getAllHospitals = (page: number, search: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_HOSPITAL}?page=${page}&search=${search}`
  );
};

export const closeAppointment = (id: string) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.CLOSE_APPOINTMENT}?appointmentId=${id}`);
};

export const doctorSearchProduct = (keyword: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.DOC_SEARCH_PRODUCTS}?keyword=${keyword}`);
};

export const addCustomSpeciality = (body: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DOC_ADD_SPECIALITY, body);
};

export const DocGetAllTreatments = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.DOC_GET_ALL_TREATMENT}?page=${pageno}`);
};

export const getAllTreatmentCategories = (search: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.DOC_GET_TREATMENT_CATEGORIES}?keyword=${search}`
  );
};

export const addTreatmentPackage = (body: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DOC_ADD_TREATMENT, body);
};

export const DocGetMainCategories = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.DOC_GET_CATEGORIES}?page=${pageno}`);
};
