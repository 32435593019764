import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Style from "./phrPieChart.module.css";

const data = [
  { name: "Lahore", value: 200 },
  { name: "Kharchi", value: 150 },
  { name: "Islambad", value: 300 },
  { name: "Others", value: 500 },
];

const COLORS = ["#00276d", "#BAEDBD", "#95A4FC", "#B1E3FF"];
const total = data.reduce((acc, entry) => acc + entry.value, 0);
interface CustomTooltipProps {
  active?: boolean;
  payload?: { name: string; value: number }[];
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const total = data.reduce((acc, entry) => acc + entry.value, 0);
    const percentage = ((payload[0]?.value / total) * 100).toFixed(2);

    return (
      <div className={Style.tooltip}>
        <p
          style={{ color: "#00276d", marginRight: "16px", fontWeight: "700" }}
          className={`${Style.tooltip}-label`}
        >
          {`${payload[0]?.name}:`}
        </p>
        <p
          style={{ fontSize: "16px", fontWeight: "700", color: "#00276d" }}
          className={`${Style.tooltip}-value`}
        >{`${percentage}%`}</p>
      </div>
    );
  }
  return null;
};

const PhrPieChart: React.FC = () => {
  return (
    <ResponsiveContainer width="100%" height={180}>
      <PieChart>
        <Pie
          data={data}
          cx={80}
          cy={90}
          innerRadius={50}
          outerRadius={80}
          cornerRadius={8}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend
          content={
            <ul
              style={{
                padding: 0,
                textAlign: "center",
                position: "absolute",
                bottom: "10px",
                left: "57%",
              }}
            >
              {data.map((entry, index) => (
                <div className={Style.lists}>
                  <li
                    key={`item-${index}`}
                    style={{
                      color: COLORS[index % COLORS.length],
                    }}
                  >
                    {`${entry.name} - ${Math.round(
                      (entry.value / total) * 100
                    )}%`}
                  </li>
                </div>
              ))}
            </ul>
          }
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PhrPieChart;
