import classNames from "classnames";
import React from "react";
import style from "./ApartmentPropertylisting.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import Checkbox from "@mui/material/Checkbox";
import { PrimaryButton } from "shared/components";
import { orange } from "@mui/material/colors";
import { IoArrowBack } from "react-icons/io5";
import { Navigate, useNavigate } from "react-router-dom";
function ApartmentPropertylisting() {
  const navigate = useNavigate(); // Declare once

  const handleGoBack = () => {
    navigate("/hotel/property/apartmentYourListing");
  };

  const handleGoToDetail = () => {
    navigate("/hotel/property/yourListing");
  };
  return (
    <div className={classNames(commonstyles.colorBlue)}>
      <SearchBar />
      <div className={classNames(commonstyles.mr87)}>
        <div className={classNames(style.mt32, commonstyles.flx)}>
          <IoArrowBack className={style.back} onClick={handleGoBack} />
          <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
            Back
          </p>
        </div>
        <div className={style.mt32}>
          <p className={classNames(commonstyles.fs24, commonstyles.semiBold)}>
            Where else is your property listed?
          </p>
        </div>
        <div>
          <div className={classNames(commonstyles.flx, style.mt32)}>
            <div>
              <Checkbox
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
            </div>
            <p className={classNames(commonstyles.fs14, commonstyles.semiBold)}>
              Airbnb
            </p>
          </div>
          <div className={classNames(commonstyles.flx, style.mt24)}>
            <div>
              <Checkbox
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
            </div>
            <p className={classNames(commonstyles.fs14, commonstyles.semiBold)}>
              TripAdvisor
            </p>
          </div>
          <div className={classNames(commonstyles.flx, style.mt24)}>
            <div>
              <Checkbox
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
            </div>
            <p className={classNames(commonstyles.fs14, commonstyles.semiBold)}>
              Vrbo
            </p>
          </div>
          <div className={classNames(commonstyles.flx, style.mt24)}>
            <div>
              <Checkbox
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
            </div>
            <p className={classNames(commonstyles.fs14, commonstyles.semiBold)}>
              Another website
            </p>
          </div>
          <div className={classNames(commonstyles.flx, style.mt24)}>
            <div>
              <Checkbox
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
            </div>
            <p className={classNames(commonstyles.fs14, commonstyles.semiBold)}>
              My property isn’t listed on any other website
            </p>
          </div>
        </div>
        <div className={style.btncenter}>
          <PrimaryButton
            children={"Next"}
            colorType={"blue"}
            onClick={handleGoToDetail}
          />
        </div>
      </div>
    </div>
  );
}

export default ApartmentPropertylisting;
