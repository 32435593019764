import React, { useState } from "react";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { CustomInput, PrimaryButton } from "shared/components";
import ImgPicker from "shared/components/Img-picker";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSelect from "shared/components/CustomSelect";
import { insuranceTravelPriceSchema } from "shared/utils";
import { insuranceAddTravel_Individual } from "shared/services/Insurance";
import { useNavigate, useParams } from "react-router-dom";
interface Props {
  handleClickNext: any;
}
export default function Price(props: Partial<Props>) {
  const { value } = useParams();
  const [loading, setLoading] = useState(false);
  const { insuranceTravelIndividual } = useSelector(
    (state: any) => state.root.insurance
  );
  console.log("console in Price Panel", insuranceTravelIndividual);
  const { handleClickNext } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/insurance/Travel");
  };
  const formik = useFormik({
    initialValues: {
      actualPrice: "",
      // meditourPrice: "",
      perYear: "",
    },
    validationSchema: Yup.object(insuranceTravelPriceSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    let currentData = formik.values;
    let params = {
      packageName: insuranceTravelIndividual.packageName,
      packageLogo: insuranceTravelIndividual.packageLogo,
      packageDescription: insuranceTravelIndividual.packageDescription,
      medicalCover: insuranceTravelIndividual.medicalCover,
      coveringUpto: insuranceTravelIndividual.coveringUpTo,
      packageCategory: insuranceTravelIndividual.packageCategory,
      repatriationCoverage:
        insuranceTravelIndividual.repatriationofMortalRemains,
      medExpensesHospitalizationCoverage:
        insuranceTravelIndividual.medicalExpense,
      returnOfDependentChildrenCoverage:
        insuranceTravelIndividual.returnofDependentChildren,
      repatriationIllnessInjuryCoverage:
        insuranceTravelIndividual.repatriationInCaseIllness,
      emergencyReturnHomeCoverage:
        insuranceTravelIndividual.emergencyReturnHome,
      medicineDeliveryCoverage: insuranceTravelIndividual.deliveryOfMedicine,
      flightDelay: insuranceTravelIndividual.flghtDelay,
      passportLoss: insuranceTravelIndividual.lossofPassport,
      luggageArrivalDelay: insuranceTravelIndividual.delayinArrival,
      baggageLoss: insuranceTravelIndividual.lossOfBaggage,
      policyDocument: insuranceTravelIndividual.PolicyDocument,
      actualPrice: currentData.actualPrice,
      // meditourPrice: currentData.meditourPrice,
      perYear: currentData.perYear,
      tripType: value,
      countrySelection: insuranceTravelIndividual.CountryName,
    };

    insuranceAddTravel_Individual(params)
      .then((res: any) => {
        console.log("response of insuranceAddTravel_Individual.....", res.data);
        handleNavigate();
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });

    handleClickNext();
  };

  return (
    <div className={classNames(commonStyles.col12)}>
      <form onSubmit={formik.handleSubmit}>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6)}>
            <CustomInput
              placeholder="Price "
              id="actualPrice"
              name="actualPrice"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.actualPrice}
            />
            {formik.touched.actualPrice && formik.errors.actualPrice ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.actualPrice}
              </div>
            ) : null}
          </div>
        </div>
        {/* <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6)}>
            <CustomInput
              placeholder="MediTour Price"
              id="meditourPrice"
              name="meditourPrice"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.meditourPrice}
            />
            {formik.touched.meditourPrice && formik.errors.meditourPrice ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.meditourPrice}
              </div>
            ) : null}
          </div>
        </div> */}
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6)}>
            <CustomInput
              placeholder="Per Year"
              id="perYear"
              name="perYear"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.perYear}
            />
            {formik.touched.perYear && formik.errors.perYear ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.perYear}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ width: "210px", marginTop: "56px" }}>
          <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
}
