import ParamedicAvailability from "pages/HomeServices/ParamedicStaff/Availability";
import AvailabilityCategory from "pages/HomeServices/ParamedicStaff/Availability/AvailabilityCategory";
import InHouse from "pages/HomeServices/ParamedicStaff/Availability/InHouse";
import ParamedicAppointment from "pages/HomeServices/ParamedicStaff/ParamedicAppointment";
import PrescriptionParamedic from "pages/HomeServices/ParamedicStaff/ParamedicAppointment/PrescriptionParamedic";
import ParamedicDashboard from "pages/HomeServices/ParamedicStaff/ParamedicAppointment/ParamedicDashboard";
import ParamedicPatientHistory from "pages/HomeServices/ParamedicStaff/ParamedicPatientHistory";
import ParamedicPatientDetail from "pages/HomeServices/ParamedicStaff/ParamedicPatientHistory/ParamedicPatientDetail";
import ParapatientPrescription from "pages/HomeServices/ParamedicStaff/ParamedicPatientHistory/ParapatientPrescription";
import ParamedicPayment from "pages/HomeServices/ParamedicStaff/ParamedicPayment";
import ParaPaymentDetail from "pages/HomeServices/ParamedicStaff/ParamedicPayment/ParaPaymentDetail";
import ParamedicRequests from "pages/HomeServices/ParamedicStaff/ParamedicRequests";
import ParamedicSetting from "pages/HomeServices/ParamedicStaff/ParamedicStaffSetting";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SideBar } from "shared/components";
import Chat from "shared/components/Chat";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
import { ParamedicStaffMenu } from "shared/utils";
import Onsite from "pages/HomeServices/ParamedicStaff/Availability/onsite";
import VideoConsultancy from "pages/HomeServices/ParamedicStaff/Availability/videoConsultancy";
import ParaMedicHospital from "pages/HomeServices/ParamedicStaff/Availability/ParamedicHospital";
import ParaMedicHositalList from "pages/HomeServices/ParamedicStaff/Availability/ParamedicHospital/ParaMedicHospitalList";
import Profile from "shared/components/Profile";
const ParamedicStaffMainRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/paramedicStaff/dashboard");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: "0px",
          height: "100vh",
        }}
      >
        <SideBar menuItem={ParamedicStaffMenu} />
      </div>
      <Routes>
        <Route
          path="paramedicStaff/dashboard"
          element={<ParamedicDashboard />}
        />
        <Route
          path="paramedicStaff/availabilityCategory"
          element={<AvailabilityCategory />}
        />
        <Route
          path="/paramedicStaff/availability"
          element={<ParamedicAvailability />}
        />
        <Route
          path="/paramedicStaff/availability/InHouse"
          element={<InHouse />}
        />
        <Route
          path="/paramedicStaff/availability/clinic"
          element={<Onsite />}
        />
        <Route
          path="/paramedicStaff/availability/paraMedicHospital/:id"
          element={<ParaMedicHospital />}
        />
        <Route
          path="/paramedicStaff/availability/paraMedicHositalList"
          element={<ParaMedicHositalList />}
        />
        <Route
          path="/paramedicStaff/availability/VideoConsultancy"
          element={<VideoConsultancy />}
        />
        <Route path="paramedicStaff/requests" element={<ParamedicRequests />} />
        <Route
          path="paramedicStaff/appointment"
          element={<ParamedicAppointment />}
        />
        <Route
          path="/paramedicStaff/appointment/Prescription/:id/:index"
          element={<PrescriptionParamedic />}
        />
        <Route
          path="paramedicStaff/history"
          element={<ParamedicPatientHistory />}
        />
        <Route
          path="/patientPrescription"
          element={<ParapatientPrescription />}
        />
        <Route path="/patientDetail/:id" element={<ParamedicPatientDetail />} />
        <Route path="paramedicStaff/payment" element={<ParamedicPayment />} />
        <Route path="/payment/Description" element={<ParaPaymentDetail />} />
        <Route path="paramedicStaff/setting" element={<ParamedicSetting />} />
        <Route path="paramedicStaff/Privacy" element={<PrivacyPolicy />} />
        <Route path="chat/message" element={<Chat />} />
        <Route path="/profile" Component={Profile} />
      </Routes>
    </div>
  );
};

export default ParamedicStaffMainRoutes;
