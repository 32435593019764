import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Hos_BasicInfo } from "shared/components";
import { setHospitalFormData } from "shared/redux";

interface Props {
  handleClickNext: any;
}
const HospitalBasicInfo = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const dispatch = useDispatch();
  const handleNext = (data: any) => {
    dispatch(setHospitalFormData(data));
    handleClickNext();
  };
  return <Hos_BasicInfo handleNext={handleNext} />;
};

export default HospitalBasicInfo;
