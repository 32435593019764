import React, { useState } from "react";
import TwoWayTable from "./TwowayTable";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "../filght.module.css";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  ArrowBack,
  CustomInput,
  CustomModal,
  CustomStepper,
  PrimaryButton,
} from "shared/components";
import { IoClose } from "react-icons/io5";
import PerviousNextButton from "shared/components/Pagination";
import SearchBar from "shared/components/Searchbar";
import ImgPicker from "shared/components/Img-picker";
import CustomSelect from "shared/components/CustomSelect";
import { Checkbox } from "@mui/material";
import Pagination from "shared/components/Pagination/Pagination";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
const steps = [
  {
    id: "1",
    lable: "Itnerary",
  },
  {
    id: "2",
    lable: "Tickets Info",
  },
  {
    id: "3",
    lable: "Return Info",
  },
  {
    id: "4",
    lable: "Amenities",
  },
  {
    id: "5",
    lable: "Carry & Polices ",
  },
  {
    id: "6",
    lable: "Price",
  },
];
const Passengers = ["Passengers", "Karachi", "Multan", "Islambad"];
const Class = ["Class", "Karachi", "Multan", "Islambad"];
const Infant = ["Infant", "6 months", "1 year", "1.5 Year", "2 year", "No"];
const Stay = ["Driect", "Stay"];
const HandBag = ["Hand Bag", "Karachi", "Multan", "Islambad"];
const Children = [
  "Children",
  "2 year",
  "3 year",
  "4 year",
  "5 year",
  "6 year",
  "7 year",
  "8 year",
  "9 year",
  "10 year",
  "11 year",
  "12 year",
  "No",
];
const Adult = ["Adult", "Above 12 years", "NO"];
export default function TwoWay() {
  const navigate = useNavigate(); // Declare once
  const handleGoBack = () => {
    navigate("/travelAgency/Flight");
  };
  const [showAddTicketsDetail, setShowAddTicketsDetail] = useState(false);

  const handleModelOpen = () => {
    setShowAddTicketsDetail(true);
  };
  return (
    <div className={classNames(commonstyle.col12)}>
      <SearchBar />
      <div className={commonstyle.mr87}>
        <div className={style.outerContainer}>
          <div className={classNames(commonstyle.flxBetween, commonstyle.mb32)}>
            <div className={commonstyle.flx}>
              <div>
                <IoArrowBack className={style.back} onClick={handleGoBack} />
              </div>
              <p
                className={classNames(
                  commonstyle.fs24,
                  commonstyle.semiBold,
                  commonstyle.colorBlue
                )}
              >
                Traveling Plan / Flights / Two way Trip
              </p>
              <BiSolidMessageSquareAdd
                className={style.AddIcon}
                onClick={handleModelOpen}
              />
            </div>
            <Pagination />
          </div>{" "}
          <div>
            <CustomModal
              showModal={showAddTicketsDetail}
              children={
                <AddTicketsDetail
                  setShowAddTicketsDetail={setShowAddTicketsDetail}
                />
              }
            />
          </div>
          <div>
            <TwoWayTable />
          </div>
        </div>
      </div>
    </div>
  );
}
const AddTicketsDetail = (props: any) => {
  const [screenName, setScreenName] = useState("Itnerary");
  const [selectedStep, setSelectedStep] = useState(0);
  console.log("screenName....", screenName);

  const handleClickNext = () => {
    if (screenName == "Itnerary") {
      setScreenName("Tickets Info");
    }
    if (screenName == "Tickets Info") {
      setScreenName("Return Info");
    }
    if (screenName == "Return Info") {
      setScreenName("Amenities");
    }
    if (screenName == "Amenities") {
      setScreenName("Carry & Polices");
    }
    if (screenName == "Carry & Polices") {
      setScreenName("Price");
    }
    if (selectedStep < 5) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName == "Tickets Info") {
      setScreenName("Itnerary");
    }
    if (screenName == "Return Info") {
      setScreenName("Tickets Info");
    }
    if (screenName == "Amenities") {
      setScreenName("Return Info");
    }
    if (screenName == "Carry & Polices") {
      setScreenName("Amenities");
    }
    if (screenName == "Price") {
      setScreenName("Carry & Polices");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };
  const { setShowAddTicketsDetail } = props;

  const handleCloseModal = () => {
    setShowAddTicketsDetail(false);
  };

  return (
    <div style={{ width: "600px" }}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <IoClose className={style.close} onClick={handleCloseModal} />
      </div>
      <div
        style={{ marginBottom: "16px" }}
        className={classNames(commonstyle.flx, commonstyle.col6)}
      >
        <div className={classNames(commonstyle.mr24)}>
          <IoArrowBack
            onClick={handleClicKPrev}
            style={{ height: "24px", width: "24px", color: "#00276d" }}
          />
        </div>
        <p
          className={classNames(
            commonstyle.semiBold,
            commonstyle.fs16,
            commonstyle.colorBlue
          )}
        >
          Back
        </p>
      </div>
      <div className={classNames(commonstyle.col11)}>
        <CustomStepper steps={steps} selectedStep={selectedStep} />
      </div>
      <div className={classNames(commonstyle.col12, commonstyle.mt40)}>
        {screenName == "Itnerary" && <Itnerary />}
        {screenName == "Tickets Info" && <TicketsInfo />}
        {screenName == "Return Info" && <ReturnInfo />}
        {screenName == "Amenities" && <Amenities />}
        {screenName == "Carry & Polices" && <CarryPolices />}
        {screenName == "Price" && <Price />}
      </div>
      <div style={{ width: "204px", marginTop: "32px" }}>
        <PrimaryButton
          children={"Next"}
          colorType={"blue"}
          onClick={handleClickNext}
        />
      </div>
    </div>
  );
};
const Itnerary = (props: any) => {
  const [selectedStay, setSelectedStay] = useState<string | null>(null);

  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    setSelectedStay(selectedOption);
  };
  const { setShowItneraryModel } = props;
  return (
    <div>
      {" "}
      <div
        className={classNames(
          commonstyle.col12,
          commonstyle.flx,
          commonstyle.mb28
        )}
      >
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="Company Name" />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="Flights No" />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <ImgPicker placeholder="Company logo" />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          commonstyle.col12,
          commonstyle.flx,
          commonstyle.mb28
        )}
      >
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="Flight Type" />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomSelect
              options={Stay}
              placeholder="Stay"
              onSelect={handleSelect}
            />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomSelect
              options={Class}
              placeholder="Class"
              onSelect={handleSelect}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          commonstyle.col12,
          commonstyle.flx,
          commonstyle.mb28
        )}
      >
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomSelect
              options={Infant}
              placeholder="Infant"
              onSelect={handleSelect}
            />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomSelect
              options={Children}
              placeholder="Children"
              onSelect={handleSelect}
            />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomSelect
              options={Adult}
              placeholder="Adult"
              onSelect={handleSelect}
            />
          </div>
        </div>
      </div>
      {selectedStay === "Stay" && (
        <>
          <div
            className={classNames(
              commonstyle.col12,
              commonstyle.flx,
              commonstyle.mb28
            )}
          >
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomInput placeholder="Stay Designation" />
              </div>
            </div>
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomInput placeholder="Stay Duration" />
              </div>
            </div>
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomInput placeholder="Next Flight No" />
              </div>
            </div>
          </div>
          <div
            className={classNames(
              commonstyle.col12,
              commonstyle.flx,
              commonstyle.mb28
            )}
          >
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomInput placeholder="Depart Time" />
              </div>
            </div>
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomInput placeholder="Designation Time" />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const TicketsInfo = (props: any) => {
  const [selectedStay, setSelectedStay] = useState<string | null>(null);

  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    setSelectedStay(selectedOption);
  };
  const { setShowTicketsInfoModel } = props;
  return (
    <div>
      {" "}
      <div
        className={classNames(
          commonstyle.col12,
          commonstyle.flx,
          commonstyle.mb28
        )}
      >
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="From" />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="To" />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="Depart Date" />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          commonstyle.col12,
          commonstyle.flx,
          commonstyle.mb28
        )}
      >
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="Depart Time" />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="Designation Time" />
          </div>
        </div>
      </div>
    </div>
  );
};
const ReturnInfo = (props: any) => {
  const [selectedStay, setSelectedStay] = useState<string | null>(null);

  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    setSelectedStay(selectedOption);
  };
  const { setShowReturnInfoModel } = props;
  return (
    <div>
      {" "}
      <div
        className={classNames(
          commonstyle.col12,
          commonstyle.flx,
          commonstyle.mb28
        )}
      >
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="From" />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="To" />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="Depart Date" />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          commonstyle.col12,
          commonstyle.flx,
          commonstyle.mb28
        )}
      >
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="Depart Time" />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="Depart Time" />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomSelect
              options={Class}
              placeholder="State"
              onSelect={handleSelect}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          commonstyle.col12,
          commonstyle.flx,
          commonstyle.mb28
        )}
      >
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomSelect
              options={Stay}
              placeholder="Stay"
              onSelect={handleSelect}
            />
          </div>
        </div>
      </div>
      {selectedStay === "Stay" && (
        <>
          <div
            className={classNames(
              commonstyle.col12,
              commonstyle.flx,
              commonstyle.mb28
            )}
          >
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomInput placeholder="Stay Designation" />
              </div>
            </div>
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomInput placeholder="Stay Duration" />
              </div>
            </div>
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomInput placeholder="Next Flight No" />
              </div>
            </div>
          </div>
          <div
            className={classNames(
              commonstyle.col12,
              commonstyle.flx,
              commonstyle.mb28
            )}
          >
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomInput placeholder="Depart Time" />
              </div>
            </div>
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomInput placeholder="Designation Time" />
              </div>
            </div>
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomInput placeholder="From" />
              </div>
            </div>
          </div>
          <div
            className={classNames(
              commonstyle.col12,
              commonstyle.flx,
              commonstyle.mb28
            )}
          >
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomInput placeholder="to" />
              </div>
            </div>
            <div className={classNames(commonstyle.col4)}>
              <div className={classNames(commonstyle.mr32)}>
                <CustomSelect options={Class} onSelect={handleSelect} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const Amenities = (props: any) => {
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };
  const { setShowAmenitiesModel } = props;
  return (
    <div className={classNames(commonstyle.col12, commonstyle.flx)}>
      <div className={classNames(commonstyle.col6)}>
        <div className={classNames(commonstyle.flx)}>
          <Checkbox className={style.checkbox} />
          <p>Winglets</p>
        </div>
        <div className={classNames(commonstyle.flx)}>
          <Checkbox className={style.checkbox} />
          <p>Basic web browsing (free)</p>
        </div>
        <div className={classNames(commonstyle.flx)}>
          <Checkbox className={style.checkbox} />
          <p>Streaming entertainment</p>
        </div>
        <div className={classNames(commonstyle.flx)}>
          <Checkbox className={style.checkbox} />
          <p>Light meal availble</p>
        </div>
      </div>
      <div className={classNames(commonstyle.col6)}>
        <div className={classNames(commonstyle.flx)}>
          <Checkbox className={style.checkbox} />
          <p>Winglets</p>
        </div>
        <div className={classNames(commonstyle.flx)}>
          <Checkbox className={style.checkbox} />
          <p>Basic web browsing (free)</p>
        </div>
        <div className={classNames(commonstyle.flx)}>
          <Checkbox className={style.checkbox} />
          <p>Streaming entertainment</p>
        </div>
        <div className={classNames(commonstyle.flx)}>
          <Checkbox className={style.checkbox} />
          <p>Light meal availble</p>
        </div>
      </div>
    </div>
  );
};
const CarryPolices = (props: any) => {
  const { setShowCarryPolicesModel } = props;
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };
  return (
    <div className={classNames(commonstyle.col12)}>
      <div
        className={classNames(
          commonstyle.col12,
          commonstyle.flx,
          commonstyle.mb28
        )}
      >
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomSelect
              options={HandBag}
              onSelect={handleSelect}
              placeholder="Hand Bag"
            />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="Baggage Weight" />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          commonstyle.col12,
          commonstyle.flx,
          commonstyle.mb28
        )}
      >
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="Cancelation Duration" />
          </div>
        </div>
        <div className={classNames(commonstyle.col4)}>
          <div className={classNames(commonstyle.mr32)}>
            <CustomInput placeholder="Cancelation Deduction" />
          </div>
        </div>
      </div>
      <div className={classNames(commonstyle.col8, commonstyle.mb28)}>
        <div className={classNames(commonstyle.mr32)}>
          <CustomInput placeholder="How many tickets you have same this information" />
        </div>
      </div>
      <div className={classNames(commonstyle.col12)}>
        <div className={classNames(commonstyle.mr32)}>
          <CustomInput placeholder="Cancel Policy Description" />
        </div>
      </div>
    </div>
  );
};
const Price = (props: any) => {
  const { setShowPriceModel } = props;
  return (
    <div className={classNames(commonstyle.col12, commonstyle.flx)}>
      <div className={classNames(commonstyle.col6, commonstyle.mr32)}>
        <CustomInput placeholder="Actual Price" />
      </div>
      <div className={classNames(commonstyle.col6)}>
        <CustomInput placeholder="Price For Meditour" />
      </div>
    </div>
  );
};
