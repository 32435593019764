import React, { useState } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import style from "./Travel.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import Single from "assets/images/single.png";
import Multi from "assets/images/multi.png";
import Student from "assets/images/student.png";
import { CustomModal } from "shared/components";

export default function TravelMian() {
  const navigate = useNavigate(); // Declare once

  const handleGoBack = () => {
    navigate("/insurance/Category");
  };
  const handleGoNext = (value: any) => {
    console.log(".valuer........", value);
    navigate(`/insurance/TravelingWith/${value}`);
  };

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={commonstyles.outerContainer}>
          <div className={commonstyles.flx}>
            <div className={commonstyles.flx}>
              <IoArrowBack className={style.back} onClick={handleGoBack} />
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.semiBold,
                  commonstyles.colorBlue,
                  style.mr56
                )}
              >
                Back{" "}
              </p>
            </div>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Travel
            </p>
          </div>
          <div className={commonstyles.flx}>
            <div
              className={style.myselfcard}
              onClick={() => handleGoNext("singleTrip")}
            >
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Single Trip
              </p>
              <p className={classNames(commonstyles.fs16, style.mt16)}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <img src={Single} className={style.healtImgss} />
            </div>
            <div
              className={style.myselfcard}
              onClick={() => handleGoNext("multipleTrips")}
            >
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Multi - Travel
              </p>
              <p className={classNames(commonstyles.fs16, style.mt16)}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <img src={Multi} className={style.familyImg} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
