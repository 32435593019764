import DonationDashBoard from "pages/Donation/DonationDashBoard";
import DonationPayment from "pages/Donation/DonationPayment";
import DonationSetting from "pages/Donation/DonationSetting";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SideBar } from "shared/components";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
import { DonationMenu } from "shared/utils";
import Donors from "pages/Donation/Donors";
import PaymentDescrition from "pages/Donation/DonationPayment/PaymentDescrition";
import Chat from "shared/components/Chat";
import DonationDetail from "pages/Donation/Donationpackages/DonationDetail";
import DonorDetail from "pages/Donation/Donors/DonorDetail";
import DonationCategories from "pages/Donation/Donationpackages/DonationCateorige";
import DonationCruteria from "pages/Donation/DonationCriteria";
import CriteriaDetail from "pages/Donation/DonationCriteria/CriteriaDetail";

const DonationMainRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/donation/dashboard");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: "0px",
          height: "100vh",
        }}
      >
        <SideBar menuItem={DonationMenu} />
      </div>
      <Routes>
        <Route path="donation/dashboard" element={<DonationDashBoard />} />
        <Route path="donation/criteria" element={<DonationCruteria />} />
        <Route
          path="/donation/criteria/Detail/:id"
          element={<CriteriaDetail />}
        />
        <Route path="donation/packages" element={<DonationCategories />} />
        <Route path="/donation/Details/:id" element={<DonationDetail />} />
        <Route path="donation/donors" element={<Donors />} />
        <Route path="/donorDetail/:id" element={<DonorDetail />} />
        <Route path="donation/payment" element={<DonationPayment />} />
        <Route path="donation/paymentDetails" element={<PaymentDescrition />} />
        <Route path="donation/setting" element={<DonationSetting />} />
        <Route path="donation/Privacy" element={<PrivacyPolicy />} />
        <Route path="chat/message" element={<Chat />} />
      </Routes>
    </div>
  );
};

export default DonationMainRoutes;
