import HotelForget from "pages/TravelTourism/Hotels/Forgetpassword/HotelForget";
import HotelUpdatepassword from "pages/TravelTourism/Hotels/Forgetpassword/HotelUpdatepassword ";
import { HotelLogin, HotelSignup } from "pages/TravelTourism/Hotels/Signup";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

const Hotelauth = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<HotelLogin />} />
      <Route path="/signup" element={<HotelSignup />} />
      <Route path="/forgot-password" element={<HotelForget />} />
      <Route path="/update-password" element={<HotelUpdatepassword />} />
    </Routes>
  );
};

export default Hotelauth;
