import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./pharmacyVerification.module.css";
import phrMainStyles from "../../pharmacyMain.module.css";
import commonStyles from "shared/utils/common.module.css";
import Check from "assets/check.png";
import { pharmacyVerifySchema } from "shared/utils";
import { setIsPhEmailVerified } from "shared/redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaCheck } from "react-icons/fa";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import {
  CustomInput,
  PrimaryButton,
  SuccessModal,
  VerifyModal,
} from "shared/components";
import CustomSelect from "shared/components/CustomSelect";
import {
  pharmacyCompleteSignup,
  pharmacyConfirmEmail,
  pharmacySendCodeToEmail,
} from "shared/services";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PasswordInput from "shared/components/PasswordInput";

const countryCodes = [
  "+92",
  "+1", // United States
  "+1", // Canada
  "+44", // United Kingdom
  "+49", // Germany
  "+33", // France
  "+39", // Italy
  "+61", // Australia
  "+55", // Brazil
  "+91", // India
  "+81", // Japan
  "+86", // China
  "+82", // South Korea
  "+52", // Mexico
  "+7", // Russia
  "+27", // South Africa
  "+234", // Nigeria
  "+54", // Argentina
  "+34", // Spain
  "+90", // Turkey
  "+966", // Saudi Arabia
  "+92", // pakistan
  // ... add more country codes as needed
];

interface Props {
  handleClickNext: any;
}
const PhrVerification = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [verifyError, setVerifyError] = React.useState("");
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const dispatch = useDispatch();
  const {
    phUser_id,
    isPhEmailVerified,
    isPhPhoneVerified,
    pharmacyUserFormData,
  } = useSelector((state: any) => state.root.pharmacy);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      pharmacyPhoneNo: "",
      pharmacyEmail: "",
      pharmacyPassword: "",
      pharmacyConfirmPassword: "",
    },
    validationSchema: Yup.object(pharmacyVerifySchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  useEffect(() => {
    formik.setValues(pharmacyUserFormData);
  }, []);
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const inputType = passwordVisible ? "text" : "password";
  const inputPassword = showPassword ? "text" : "password";

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const SendCodeToEmail = () => {
    let params = {
      email: formik.values.pharmacyEmail,
    };
    setVerifyLoading(true);
    pharmacySendCodeToEmail(params)
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          setMinutes(1);
          setSeconds(59);
          setShowVerifyModal(true);
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
        setVerifyError(err.response.data.message);
      })
      .finally(() => {
        setVerifyLoading(false);
      });
  };

  const handleSubmitCode = () => {
    let params = {
      email: formik.values.pharmacyEmail,
      code: code,
    };

    if (code.length == 6) {
      pharmacyConfirmEmail(params)
        .then(async (res: any) => {
          if (res.data.status) {
            setSuccessMessage(res.data.message);
            dispatch(setIsPhEmailVerified(true));
            setVerifyError("");
          }
        })
        .catch((err: any) => {
          setCodeError(err.response.data.message);
        })
        .finally(() => {
          setVerifyLoading(false);
        });
    }
  };

  const handleCloseModal = () => {
    setShowVerifyModal(false);
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/");
  };

  const handleSubmit = async () => {
    let values = formik.values;
    let params = {
      phoneNumber: values.pharmacyPhoneNo,
      email: values.pharmacyEmail,
      password: values.pharmacyPassword,
      confirmPassword: values.pharmacyConfirmPassword,
    };

    console.log("id....", phUser_id);

    if (isPhEmailVerified) {
      pharmacyCompleteSignup(params, phUser_id)
        .then((res: any) => {
          console.log("pas....", res);
          if (res.status == "200" && res.statusText == "OK") {
            setShowSuccessModal(true);
          }
        })
        .catch((err: any) => {
          console.log("send error.nnnnn..", err?.response?.data);
        })
        .finally(() => {
          setVerifyLoading(false);
          dispatch(setIsPhEmailVerified(false));
        });
    } else {
      setVerifyError("Please verify email and phone number first.");
    }
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(styles.w80, commonStyles.colsm12)}>
          {verifyError && (
            <div className={classNames(commonStyles.error)}>*{verifyError}</div>
          )}
          <div className={classNames(commonStyles.mb16, commonStyles.flx)}>
            <div className={classNames(commonStyles.col2)}>
              <div>
                <CustomSelect
                  options={countryCodes}
                  placeholder="+92"
                  onSelect={handleSelect}
                />
              </div>
            </div>
            <div className={classNames(commonStyles.col10)}>
              <CustomInput
                placeholder="Phone Number"
                id="pharmacyPhoneNo"
                name="pharmacyPhoneNo"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.pharmacyPhoneNo}
              />

              {formik.touched.pharmacyPhoneNo &&
              formik.errors.pharmacyPhoneNo ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.pharmacyPhoneNo}
                </div>
              ) : null}

              {/* <FaCheck className={styles.check} /> */}
            </div>
            <div>
              <button
                disabled={
                  formik.touched.pharmacyPhoneNo &&
                  !formik.errors.pharmacyPhoneNo
                    ? false
                    : true
                }
                type="button"
                className={
                  formik.touched.pharmacyPhoneNo &&
                  !formik.errors.pharmacyPhoneNo
                    ? commonStyles.VerifyActive
                    : commonStyles.Verify
                }
              >
                Verify
              </button>
            </div>
          </div>
          <div className={classNames(commonStyles.flx)}>
            <div
              className={classNames(commonStyles.col12, commonStyles.mtsm28)}
            >
              <CustomInput
                placeholder="Email Address"
                id="pharmacyEmail"
                name="pharmacyEmail"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.pharmacyEmail}
              />

              {formik.touched.pharmacyEmail && formik.errors.pharmacyEmail ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.pharmacyEmail}
                </div>
              ) : null}
              {/* <FaCheck className={styles.check2} /> */}
            </div>
            <div className={commonStyles.mr32}>
              {isPhEmailVerified ? (
                <button
                  type="button"
                  disabled={true}
                  className={commonStyles.Verified}
                  // onClick={handleSendCodeToEmail}
                >
                  Verified
                </button>
              ) : (
                <button
                  type="button"
                  disabled={
                    !verifyLoading &&
                    formik.touched.pharmacyEmail &&
                    !formik.errors.pharmacyEmail
                      ? false
                      : true
                  }
                  className={
                    formik.touched.pharmacyEmail && !formik.errors.pharmacyEmail
                      ? commonStyles.VerifyActive
                      : commonStyles.Verify
                  }
                  onClick={SendCodeToEmail}
                >
                  {verifyLoading ? "loading..." : "Verify"}
                </button>
              )}
              {/* <button
                type="button"
                disabled={
                  !verifyLoading &&
                  formik.touched.pharmacyEmail &&
                  !formik.errors.pharmacyEmail
                    ? false
                    : true
                }
                className={
                  formik.touched.pharmacyEmail && !formik.errors.pharmacyEmail
                    ? commonStyles.VerifyActive
                    : commonStyles.Verify
                }
                onClick={SendCodeToEmail}
              >
                {verifyLoading ? "loading..." : "Verify"}
              </button> */}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.col8, commonStyles.colsm12)}>
          <div className={classNames(commonStyles.mb16, commonStyles.flx)}>
            <div
              className={classNames(commonStyles.col12, commonStyles.mtsm28)}
            >
              <PasswordInput
                type={inputType}
                placeholder="Password"
                id="pharmacyPassword"
                name="pharmacyPassword"
                onChange={formik.handleChange}
                value={formik.values.pharmacyPassword}
              />

              {formik.touched.pharmacyPassword &&
              formik.errors.pharmacyPassword ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.pharmacyPassword}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, commonStyles.flx)}>
            <div
              className={classNames(commonStyles.col12, commonStyles.mtsm28)}
            >
              <PasswordInput
                type={inputPassword}
                placeholder="Re-Password"
                id="pharmacyConfirmPassword"
                name="pharmacyConfirmPassword"
                onChange={formik.handleChange}
                value={formik.values.pharmacyConfirmPassword}
              />

              {formik.touched.pharmacyConfirmPassword &&
              formik.errors.pharmacyConfirmPassword ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.pharmacyConfirmPassword}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.col12, commonStyles.mtsm28)}>
          <div className={classNames(commonStyles.flx)}>
            <Checkbox
              size="small"
              style={{
                color: "#ff7631",
                marginLeft: -10,
              }}
            />

            <div>
              <p
                className={classNames(commonStyles.regular, commonStyles.fs10)}
              >
                I agree meditour{" "}
                <a
                  className={classNames(commonStyles.colorOrange)}
                  href="/terms-conditions"
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  className={classNames(commonStyles.colorOrange)}
                  href="/privacy-policy"
                >
                  Privacy policy
                </a>
              </p>
            </div>
          </div>
          
          {/* <div className={classNames(commonStyles.flx, commonStyles.mb28)}> */}
            {/* <Checkbox
              size="small"
              style={{
                color: "#ff7631",
                marginLeft: -10,
              }}
            /> */}

            {/* <div>
              <p
                className={classNames(commonStyles.regular, commonStyles.fs10)}
              >
                Please contact via phone or email to assist my inquery
              </p>
            </div> */}
          {/* </div> */}

        </div>
        <div style={{ width: "210px" }}>
          <PrimaryButton
            children={"Submit"}
            type="submit"
            colorType={"SkyBlueOutine"}
          />
        </div>
      </form>
      <VerifyModal
        showModal={showVerifyModal}
        handleSubmit={handleSubmitCode}
        code={code}
        codeError={codeError}
        setCodeError={setCodeError}
        setCode={setCode}
        minutes={minutes}
        seconds={seconds}
        handleSendCodeToEmail={SendCodeToEmail}
        successMessage={successMessage}
        hanldeCloseModal={handleCloseModal}
      />
      <SuccessModal
        showModal={showSuccessModal}
        successMessage={"Signup Completed Successfully!"}
        hanldeCloseModal={handleCloseSuccessModal}
      />
    </div>
  );
};

export default PhrVerification;
