import React from "react";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { CustomInput, PrimaryButton } from "shared/components";
import ImgPicker from "shared/components/Img-picker";
import CustomSelect from "shared/components/CustomSelect";
import {
  insuranceTravelFamilyBenefitsSchema,
  insuranceTravelIndividualBenefitsSchema,
} from "shared/utils";
import { setInsuranceTravelFamily } from "shared/redux";
const Flight = ["Flight delay", "Karachi", "Multan", "Islambad"];
const LossofPassport = ["Loss of Passport:", "Karachi", "Multan", "Islambad"];
const lossofPassport = ["Yes", "No"];

const LossofBaggage = ["Loss of Baggage:", "Karachi", "Multan", "Islambad"];
interface Props {
  handleClickNext: any;
}
export default function FamilyTravelBenefits(props: Partial<Props>) {
  const { insuranceTravelFamily } = useSelector(
    (state: any) => state.root.insurance
  );
  console.log("console in travel", insuranceTravelFamily);
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };
  const dispatch = useDispatch();
  const { handleClickNext } = props;
  const formik = useFormik({
    initialValues: {
      tripCancel: "",
      delayinArrival: "",
      flightDelay: "",
      travelandStay: "",
      lossofPassport: "",
      lossOfBaggage: "",
    },
    validationSchema: Yup.object(insuranceTravelFamilyBenefitsSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    dispatch(
      setInsuranceTravelFamily({
        ...insuranceTravelFamily,
        ...formik.values,
      })
    );
    handleClickNext();
  };

  const tripCancelHandle = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("tripCancel", selectedOption);
  };
  const delayinArrival = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("delayinArrival", selectedOption);
  };
  const flightDelayhandle = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("flightDelay", selectedOption);
  };
  const travelAndStayHandle = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("travelandStay", selectedOption);
  };

  const lossofPassporthandle = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("lossofPassport", selectedOption);
  };

  const lossofBaggegHandle = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("lossOfBaggage", selectedOption);
  };
  return (
    <div className={classNames(commonStyles.col6)}>
      <form onSubmit={formik.handleSubmit}>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6, commonStyles.mr24)}>
            <CustomSelect
              onSelect={tripCancelHandle}
              placeholder="Trip Cancelation"
              options={lossofPassport}
            />
            {formik.touched.tripCancel && formik.errors.tripCancel ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.tripCancel}
              </div>
            ) : null}
          </div>

          <div className={classNames(commonStyles.col6)}>
            <CustomSelect
              placeholder="Delay in the arrival of luggage"
              onSelect={delayinArrival}
              options={LossofPassport}
            />
            {formik.touched.delayinArrival && formik.errors.delayinArrival ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.delayinArrival}
              </div>
            ) : null}
          </div>
        </div>

        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6, commonStyles.mr24)}>
            <CustomSelect
              onSelect={flightDelayhandle}
              placeholder="Flight delay"
              options={lossofPassport}
            />
            {formik.touched.flightDelay && formik.errors.flightDelay ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.flightDelay}
              </div>
            ) : null}
          </div>

          <div className={classNames(commonStyles.col6)}>
            <CustomSelect
              placeholder="Travel and stay over one family member "
              onSelect={travelAndStayHandle}
              options={LossofBaggage}
            />
            {formik.touched.travelandStay && formik.errors.travelandStay ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.travelandStay}
              </div>
            ) : null}
          </div>
        </div>

        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6, commonStyles.mr24)}>
            <CustomSelect
              onSelect={lossofPassporthandle}
              placeholder="Loss of passport"
              options={lossofPassport}
            />
            {formik.touched.lossofPassport && formik.errors.lossofPassport ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.lossofPassport}
              </div>
            ) : null}
          </div>

          <div className={classNames(commonStyles.col6)}>
            <CustomSelect
              placeholder="Loss of baggege"
              onSelect={lossofBaggegHandle}
              options={LossofBaggage}
            />
            {formik.touched.lossOfBaggage && formik.errors.lossOfBaggage ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.lossOfBaggage}
              </div>
            ) : null}
          </div>
        </div>

        <div style={{ width: "210px", marginTop: "56px" }}>
          <PrimaryButton
            children={"Next"}
            colorType={"Insurance"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
