import classNames from "classnames";
import React, { useState } from "react";
import style from "./homepropertycategory.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import Checkbox from "@mui/material/Checkbox";
import { PrimaryButton } from "shared/components";
import { orange } from "@mui/material/colors";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import CustomSelect from "shared/components/CustomSelect";
import { setHotelAddRoom } from "shared/redux";

function Homepropertycategory() {
  const [error, setError] = React.useState("");
  const { hotelAddRoom } = useSelector((state: any) => state.root.hotel);
  console.log(",,,,,,,,,,,", hotelAddRoom);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const handleCheckboxChange = (value: string) => {
    setSelectedOption(value);
  };
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/hotel/property/homeGuestbook");
  };
  // const handleGoToDetail = () => {
  //   if (selectedOption === null) {
  //     setError("Required");
  //   } else {
  //     dispatch(
  //       setHotelAddRoom({
  //         ...hotelAddRoom,
  //         similarPropertyCategory: selectedOption,
  //       })
  //     );
  //     navigate("/hotel/property/homeBreadcrum");
  //   }
  // };
  return (
    <div className={classNames(commonstyles.colorBlue)}>
      <SearchBar />
      <div className={classNames(commonstyles.mr87)}>
        <div className={classNames(commonstyles.flx)}>
          <IoArrowBack className={style.back} onClick={handleGoBack} />
          <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
            Back
          </p>
        </div>
        <div>
          <div className={style.mt32}>
            <p className={classNames(commonstyles.fs24, commonstyles.semiBold)}>
              Which property category is most similar to your place?
            </p>
          </div>
          <div
            className={classNames(
              commonstyles.flx,
              commonstyles.col6,
              commonstyles.mt56
            )}
          >
            <Checkbox
              checked={selectedOption === "Apartment"}
              onChange={() => handleCheckboxChange("Apartment")}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16)}>Apartment</p>
          </div>
          <div
            className={classNames(
              commonstyles.flx,
              commonstyles.col6,
              style.mt16
            )}
          >
            <Checkbox
              checked={selectedOption === "Holiday home"}
              onChange={() => handleCheckboxChange("Holiday home")}
              className={style.checkbox}
            />
            <p className={classNames(commonstyles.fs16)}>Holiday home</p>
          </div>
          <div>
            <div
              className={classNames(
                commonstyles.flx,
                commonstyles.col6,
                style.mt16
              )}
            >
              <Checkbox
                checked={selectedOption === "Villa"}
                onChange={() => handleCheckboxChange("Villa")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p className={classNames(commonstyles.fs16)}>Villa</p>
            </div>
            <div
              className={classNames(
                commonstyles.flx,
                commonstyles.col6,
                style.mt16
              )}
            >
              <Checkbox
                checked={selectedOption === "Aparthotel"}
                onChange={() => handleCheckboxChange("Aparthotel")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p className={classNames(commonstyles.fs16)}>Aparthotel</p>
            </div>
            <div
              className={classNames(
                commonstyles.flx,
                commonstyles.col6,
                style.mt16
              )}
            >
              <Checkbox
                checked={selectedOption === "Chalet"}
                onChange={() => handleCheckboxChange("Chalet")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p className={classNames(commonstyles.fs16)}>Chalet</p>
            </div>
            <div
              className={classNames(
                commonstyles.flx,
                commonstyles.col6,
                style.mt16
              )}
            >
              <Checkbox
                checked={selectedOption === "Holiday park"}
                onChange={() => handleCheckboxChange("Holiday park")}
                sx={{
                  color: orange[800],
                  "&.Mui-checked": {
                    color: orange[800],
                  },
                }}
                className={style.checkbox}
              />
              <p className={classNames(commonstyles.fs16)}>Holiday park</p>
            </div>
          </div>
        </div>
        {error && (
          <div className={classNames(commonStyles.error)}>*{error}</div>
        )}
        <div className={style.btncenter}>
          <PrimaryButton
            children={"Next"}
            colorType={"blue"}
            // onClick={handleGoToDetail}
          />
        </div>
      </div>
    </div>
  );
}

export default Homepropertycategory;
