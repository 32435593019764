import React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import { getAllPatients } from "../../../../shared/services/DoctorService";
import style from "./PatientHistory.module.css";
import { useNavigate } from "react-router-dom";
import PerviousNextButton from "shared/components/Pagination";
import ParaPatientHistoryTable from "shared/components/ParamedicStaffTables/patientHistoryTable";
import { getAllPatientHistory } from "shared/services/Paramedic";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { TbRefresh } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModal } from "shared/components";
import {
  setDoctorPatientHistoryFlag,
  setPeramedicPatientHistory,
  setPeramedicPatientHistoryFlag,
  setPeramedicPatientLength,
} from "shared/redux";
function ParamedicPatientHistory() {
  const dispatch = useDispatch();
  const {
    peramedicPatientHistory,
    peramedicPatientHistoryFlag,
    peramedicPatientLength,
  } = useSelector((state: any) => state.root.paramedic);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = peramedicPatientLength;

  const fetchPatienHistory = (pagono: number) => {
    setLoading(true);

    getAllPatientHistory(pageno)
      .then((res: any) => {
        console.log("Doctor...........................................", res);
        if (res?.data?.Patients.length > 0) {
          dispatch(setPeramedicPatientHistory(res?.data?.Patients));
          dispatch(setPeramedicPatientLength(res.data.patientsLength));
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (peramedicPatientLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setDoctorPatientHistoryFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setPeramedicPatientHistoryFlag(true));
    }
  };
  useEffect(() => {
    if (peramedicPatientHistoryFlag) {
      setLoading(true);
      fetchPatienHistory(currentPage);
      dispatch(setPeramedicPatientHistoryFlag(false));
    }
  }, [peramedicPatientHistoryFlag, currentPage]);
  const handleRotate = (): void => {
    setRotation(rotation - rotationIncrement);
    fetchPatienHistory(currentPage);
  };

  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <div
            className={classNames(commonstyles.mb32, commonstyles.flxBetween)}
          >
            <div className={commonstyles.flx}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                All Patient
              </p>
              <div className={style.outerRefresh}>
                <TbRefresh
                  className={style.RefreshIcon}
                  style={{ transform: `rotate(${rotation}deg)` }}
                  onClick={handleRotate}
                />
              </div>
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          {loading ? (
            <LoadingModal showModal={loading} />
          ) : (
            <>
              <div>
                <ParaPatientHistoryTable
                  patientHistory={peramedicPatientHistory}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ParamedicPatientHistory;
