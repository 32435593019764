import classNames from "classnames";
import React from "react";
import styles from "./primarybutton.module.css";
import commonStyles from "shared/utils/common.module.css";

interface Props {
  variant: any;
  onClick: any;
  disabled: boolean;
  children: any;
  colorType: any;
  type: any;
}
const PrimaryButton = (props: Partial<Props>) => {
  const { colorType, type } = props;
  return (
    <button
      className={classNames(
        colorType == "green"
          ? commonStyles.btngreen
          : colorType == "blue"
          ? commonStyles.bgBlue
          : colorType == "blueOutline"
          ? styles.BlueOutines
          : colorType == "SkyBlueOutine"
          ? styles.SkyBlueOutine
          : colorType == "MedicalService"
          ? styles.MedicalService
          : colorType == "Ambulance"
          ? styles.Ambulance
          : colorType == "Linear"
          ? styles.Linear
          : colorType == "greenOutline"
          ? styles.GreenOutine
          : colorType == "physiotherapist"
          ? styles.physiotherapist
          : colorType == "Nutritionist"
          ? styles.Nutritionist
          : colorType == "ParamedicStaff"
          ? styles.ParamedicStaff
          : colorType == "Psychologists"
          ? styles.Psychologists
          : colorType == "Travel"
          ? styles.Travel
          : colorType == "Donation"
          ? styles.Donation
          : colorType == "Insurance"
          ? styles.Insurance
          : colorType == "Red"
          ? styles.Red
          : colorType == "admin"
          ? styles.admin
          : commonStyles.bgOrange,
        styles.container,
        styles.cursor,
        commonStyles.colorWhite,
        commonStyles.fs16,
        commonStyles.semiBold
      )}
      onClick={props.onClick}
      disabled={props.disabled}
      type={type}
    >
      {props.children}
    </button>
  );
};

export default PrimaryButton;
