import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const adminLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADMIN_LOGIN, params);
};
export const adminLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.ADMIN_LOGOUT);
};

export const getAllAppointmentsRequests = (
  pageno: number,
  isOpd: boolean,
  search: string
) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_APT_REQUEST}?page=${pageno}&isOpd=${isOpd}&mrNo=${search}`
  );
};
export const getAllAppointments = (pageno: number, search: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_APT}?page=${pageno}&mrNo=${search}`
  );
};
export const getSingleAppointment = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.AD_GET_Single_APT}?id=${id}`);
};

export const getSingleAppointmentRequest = (id: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.AD_GET_SINGLE_REQUEST}?id=${id}`);
};
export const getAllOrder = (page: number, type: string, search: string) => {
  console.log("orders.....", type);
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_ORDER}?page=${page}&type=${type}&mrNo=${search}`
  );
};

export const acceptAppointmentRequest = (params: any, id: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.AD_ACCEPT_APPOINTMENT_REQUEST}?bookingId=${id}`,
    params
  );
};
export const getbookingRentAcar = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.AD_GET_BOOKING_RENTACAR}?page=${pageno}`);
};
export const getHotelRequests = (pageno: number, search: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_REQUESTS_Hotel}?page=${pageno}&mrNo=${search}`
  );
};
export const acceptHotelBookingRequest = (id: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.AD_ACCEPT_HOTEL_BOOKING_REQUEST}?requestId=${id}`
  );
};

export const getbookingHotel = (pageno: number, search: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_BOOKING_Hotel}?page=${pageno}&mrNo=${search}`
  );
};
export const getbookingInsurance = (pageno: number) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_BOOKING_INSURANCE}?page=${pageno}`
  );
};
export const getbookingFlights = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.AD_GET_BOOKING_Flight}?page=${pageno}`);
};
export const getbookingFlightsBID = (requestId: number) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_BOOKED_BID}?requestId=${requestId}`
  );
};
export const getbookingTour = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.AD_GET_BOOKING_Tour}?page=${pageno}`);
};
export const getbookingAmbulance = (pageno: number) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_BOOKING_Ambulance}?page=${pageno}`
  );
};
export const getDonation = (pageno: number, donationId: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_DONATIONS}?page=${pageno}&donationId=${donationId}`
  );
};
export const getAllPaymentOrder = (
  type: string,
  paidToVendor: boolean,
  page: number,
  completedOrders: boolean,
  vendorId: any
) => {
  // console.log(
  //   "paidToVendor",
  //   type,
  //   paidToVendor,
  //   page,
  //   completedOrders,
  //   vendorId
  // );

  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_PAYMENT_ORDER}?page=${page}&type=${type}&paidToVendor=${paidToVendor}&completedOrders=${completedOrders}&vendorId=${vendorId}`
  );
};
export const getAllPaymentAppointment = (
  appointmentType: string,
  paidToVendor: boolean,
  page: number
) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_PAYMENT_APPOINTMENT}?appointmentType=${appointmentType}&paidToVendor=${paidToVendor}&page=${page} `
  );
};
export const getPaymentDonation = (pageno: number, mrNo: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_PAYMENT_DONATION}?page=${pageno}&mrNo=${mrNo}`
  );
};
export const getPaymentRentACar = (pageno: number, mrNo: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_PAYMENT_RENTCAR}?page=${pageno}&mrNo=${mrNo}`
  );
};
export const getPaymentFlight = (pageno: number, mrNo: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_PAYMENT_FLIGHT}?page=${pageno}&mrNo=${mrNo}`
  );
};
export const getPaymentTour = (pageno: number, mrNo: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_PAYMENT_TOUR}?page=${pageno}&mrNo=${mrNo}`
  );
};
export const getPaymentInsurance = (pageno: number, mrNo: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_PAYMENT_INSURANCE}?page=${pageno}&mrNo=${mrNo}`
  );
};
export const getPaymentHotel = (pageno: number, mrNo: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_PAYMENT_HOTEL}?page=${pageno}&mrNo=${mrNo}`
  );
};
export const getPaymentAmbulance = (pageno: number, mrNo: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_PAYMENT_AMBULANCE}?page=${pageno}&mrNo=${mrNo}`
  );
};
export const getPaymentComplete = (
  vendorModelType: any,
  requestType?: any,
  id?: string,
  pageno?: number
) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AD_GET_PAYMENT_COMPLETE}?vendorModelType=${vendorModelType}&requestType=${requestType}&id=${id}&page=${pageno}`
  );
};

export const getUsers = (page: number, vendorType: any, search: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_USERS}?page=${page}&vendorType=${vendorType}&search=${search}`
  );
};
export const UserBlock = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.Users_Block, params);
};
export const payToVendor = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.AD_PAY_TO_VENDOR, params);
};

export const getMedicineRequests = (pageno: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_MEDICINE_REQUESTS}?page=${pageno}`);
};

export const acceptMedicineBid = (id: any) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.AD_ACCEPT_MED_BID}?bidRequestId=${id}`);
};

export const getMedicineBid = (id: any, page: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.AD_GET_MED_BID}?requestId=${id}`);
};

// AD_GET_MED_BID
