import React from "react";
import style from "./ParamedicAvailability.module.css";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import { useNavigate } from "react-router-dom";

export default function AvailabilityCategory() {
  const navigate = useNavigate(); // Declare once

  const handleGoClinic = () => {
    navigate("/paramedicStaff/availability/clinic");
  };

  const handleGoToInhouse = () => {
    navigate("/paramedicStaff/availability/InHouse");
  };
  const handleGoToVideoConsultancy = () => {
    navigate("/paramedicStaff/availability/VideoConsultancy");
  };
  const handleGoToHospital = () => {
    navigate("/paramedicStaff/availability/paraMedicHositalList");
  };
  return (
    <div className={classNames(commonstyles.col12, style.doctorss)}>
      <SearchBar />{" "}
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <p
            className={classNames(
              commonstyles.fs24,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Availability category
          </p>
          <div className={style.flxWrap}>
            <div className={style.clinicCard} onClick={handleGoClinic}>
              <div className={style.texts}>
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold
                  )}
                >
                  Clinic
                </p>
              </div>
            </div>
            <div className={style.InhouseCard} onClick={handleGoToInhouse}>
              <div className={style.texts}>
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold
                  )}
                >
                  In-House
                </p>
              </div>
            </div>
            <div className={style.HospitalCard} onClick={handleGoToHospital}>
              <div className={style.texts}>
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold
                  )}
                >
                  Hospital
                </p>
              </div>
            </div>
            <div
              className={style.ConsultancyCard}
              onClick={handleGoToVideoConsultancy}
            >
              <div className={style.texts}>
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold
                  )}
                >
                  Video-Consultancy
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
