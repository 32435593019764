import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const ambSignup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.AMB_SIGNUP, params);
};

export const ambSendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.AMB_SEND_CODE_TO_EMAIL, params);
};

export const ambVerifyEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.AMB_CONFIRM_EMAIL, params);
};
export const ambCompleteSignup = (params: any, id: any) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.AMB_COMPLETE_SIGNUP}?id=${id}`, params);
};

export const ambLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.AMB_LOGIN, params);
};

export const getAllAmbulance = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.AMB_GET_ALL_AMBULANCES}?page=${pageno}`);
};

export const addAmbulance = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.AMB_ADD_AMBULANCE, params);
};

export const resetLinkAmbulance = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.AMB_RESET_LINK, params);
};

export const resetPasswordAmbulance = (params: any, token: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.AMB_RESET_PASSWORD}?token=${token}`,
    params
  );
};
export const ambulanceLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.AMB_LOGOUT);
};

export const getAmbulanceDetails = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.AMB_GETAMBULANCE}?ambulanceId=${id}`);
};

export const delAmbulanceDelete = (id: string) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.AMB_DELETEAMBULANCE}?ambulanceId=${id}`
  );
};

export const getAllBookingRequest = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.AMB_GETALLREQUESTS}?page=${pageno}`);
};

export const ambulanceADDBIDREQUEST = (params: any) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.AMB_ADDBIDREQUEST}`, params);
};

export const getAmbulanceGETSINGLEREQUEST = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.AMB_GETSINGLEREQUEST}?requestId=${id}`);
};

export const ambulanceAcceptBookingRequest = (id: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.AMB_ACCEPTBOOKINGREQUEST}?bookingId=${id}`
  );
};

export const ambulanceRejectBookingRequest = (rejectId: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.AMB_REJECTBOOKINGREQUEST}?bookingId=${rejectId}`
  );
};

export const getAllAmbulanceOnRoute = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.AMB_ALLONROUTES}?page=${pageno}`);
};

export const AmbulanceEdit = (id: string, params: any) => {
  return HTTP_CLIENT.put(
    `${ENDPOINTS.AMB_EDIT_AMBULANCE}?ambulanceId=${id}`,
    params
  );
};

export const AmbulanceDASHBOARD = () => {
  return HTTP_CLIENT.get(ENDPOINTS.AMB_DASHBOARD);
};

export const AmbulanceUpdateProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.AMB_UPDATEPROFILE, params);
};
export const AmbulanceRecentBooking = () => {
  return HTTP_CLIENT.get(ENDPOINTS.AMB_RECENTBOOKINGS);
};
export const AmbulanceOnRouteStatusChange = (id: string, status: string) => {
  return HTTP_CLIENT.put(
    `${ENDPOINTS.AMB_STATUSCHANGE}?bookingId=${id}&status=${status}`
  );
};
