import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import mainTravelstyle from "../../pages/TravelTourism/mainTravel.module.css";
import classNames from "classnames";
import Ambulanceauth from "./AmbulanceRoutes/AmbulanceAuthRoutes";
import ParamedicStaffauth from "./ParamedicStaffRoutes/ParamedicStaffAuth";
import { useDispatch, useSelector } from "react-redux";
import { setHomeServiceSelectedRoute } from "shared/redux";

function HomeServicesAuthRoutes(props: any) {
  const { homeServiceSelectedRoute } = useSelector(
    (state: any) => state.root.common
  );
  const dispatch = useDispatch();

  const setSelectedOption = (route: any) => {
    dispatch(setHomeServiceSelectedRoute(route));
  };

  return (
    <>
      <nav
        className={classNames(mainTravelstyle.navbar, {
          [mainTravelstyle.ambulance]:
            homeServiceSelectedRoute === "ambulanceservices/login",
          [mainTravelstyle.ambulancesign]:
            homeServiceSelectedRoute === "ambulanceservices/signup",
          [mainTravelstyle.physiotherapist]:
            homeServiceSelectedRoute === "physiotherapist/login",
          [mainTravelstyle.physiotherapistsign]:
            homeServiceSelectedRoute === "physiotherapist/signup",
          [mainTravelstyle.nutritionist]:
            homeServiceSelectedRoute === "nutritionist/login",
          [mainTravelstyle.nutritionistsign]:
            homeServiceSelectedRoute === "nutritionist/signup",
          [mainTravelstyle.paramedicstaff]:
            homeServiceSelectedRoute === "paramedicstaff/login",
          [mainTravelstyle.paramedicstaffsign]:
            homeServiceSelectedRoute === "paramedicstaff/signup",
          [mainTravelstyle.psychologist]:
            homeServiceSelectedRoute === "psychologist/login",
          [mainTravelstyle.psychologistsign]:
            homeServiceSelectedRoute === "psychologist/signup",
        })}
      >
        <Link
          className={classNames(mainTravelstyle.navvlink, {
            [mainTravelstyle.selected]:
              homeServiceSelectedRoute === "ambulanceservices/login",
          })}
          to="ambulanceservices/login"
          onClick={() => setSelectedOption("ambulanceservices/login")}
        >
          Ambulance Services
        </Link>
        <Link
          className={classNames(mainTravelstyle.navvlink, {
            [mainTravelstyle.selected]:
              homeServiceSelectedRoute === "physiotherapist/login",
          })}
          to="physiotherapist/login"
          onClick={() => setSelectedOption("physiotherapist/login")}
        >
          Physiotherapist
        </Link>
        <Link
          className={classNames(mainTravelstyle.navvlink, {
            [mainTravelstyle.selected]:
              homeServiceSelectedRoute === "nutritionist/login",
          })}
          to="nutritionist/login"
          onClick={() => setSelectedOption("nutritionist/login")}
        >
          Nutritionist
        </Link>
        <Link
          className={classNames(mainTravelstyle.navvlink, {
            [mainTravelstyle.selected]:
              homeServiceSelectedRoute === "paramedicstaff/login",
          })}
          to="paramedicstaff/login"
          onClick={() => setSelectedOption("paramedicstaff/login")}
        >
          Paramedic Staff
        </Link>
        <Link
          className={classNames(mainTravelstyle.navvlink, {
            [mainTravelstyle.selected]:
              homeServiceSelectedRoute === "psychologist/login",
          })}
          to="psychologist/login"
          onClick={() => setSelectedOption("psychologist/login")}
        >
          Psychologist
        </Link>
        <Outlet />
      </nav>
      <Routes>
        <Route path="ambulanceservices/*" element={<Ambulanceauth />} />
        <Route path="paramedicstaff/*" element={<ParamedicStaffauth />} />
      </Routes>
    </>
  );
}

export default HomeServicesAuthRoutes;
