import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { TbRefresh } from "react-icons/tb";
import NewPagination from "shared/components/NewPagination/NewPagination";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DocGetAllTreatments } from "shared/services/DoctorService";
import { AdminTable, RingLoader } from "shared/components";

const titles = [
  { id: 1, title: "Category" },
  { id: 2, title: "Name" },
  { id: 3, title: "Cost" },
];
const DoctorTreatments = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [length, setLength] = useState(0);
  const itemsPerPage = 10;
  const totalItems = length;
  const navigate = useNavigate();
  const { systemType } = useSelector((state: any) => state.root.common);
  const [loading, setLoading] = useState(false);
  const [treatments, setTreatments] = useState([]);
  const [treatmentsForTable, setTreatmentsForTable] = useState([]);

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (totalItems > itemTorender) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    fetchTreatments(1);
  }, []);

  const handleRefresh = () => {
    fetchTreatments(1);
  };

  const handleTableData = (data: any) => {
    let tempData: any = [];

    if (data?.length > 0) {
      data.map((v: any, ind: any) => {
        // let date = moment(v?.createdAt).format("MM-DD-YYYY h:mm a");

        tempData.push([
          v?.treatmentId?.categoryId?.categoryName,
          v?.treatmentId?.subCategory,
          v?.totalAmount,
        ]);
      });

      setTreatmentsForTable(tempData);
    } else {
      setTreatmentsForTable([]);
    }
  };

  const fetchTreatments = (pageno: number) => {
    setLoading(true);
    DocGetAllTreatments(pageno)
      .then((res: any) => {
        console.log("🚀 ~ .then ~ res:", res?.data?.treatments);
        handleTableData(res?.data?.treatments);
        setTreatments(res?.data?.treatments);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoToDetail = () => {};
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />

      <div className={commonstyles.mr87}>
        <div className={classNames(commonstyles.flxBetween, commonstyles.mb32)}>
          <div className={classNames(commonstyles.flx)}>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Your Treatment Packages
            </p>

            <div className={styles.outerRefresh}>
              <BiSolidMessageSquareAdd
                className={styles.addIcon}
                onClick={() => navigate(`/${systemType}/addTreatment`)}
              />
            </div>

            <div className={styles.outerRefresh}>
              {loading ? (
                <div className={styles.loader}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <TbRefresh className={styles.refresh} onClick={handleRefresh} />
              )}
            </div>
          </div>
          <NewPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            startItem={(currentPage - 1) * itemsPerPage + 1}
            endItem={Math.min(currentPage * itemsPerPage, totalItems)}
            totalItems={totalItems}
          />
        </div>

        {/* <div className={styles.tabless}>
              <Tabletest tests={tests} setShowModal={setShowAddModal} />
            </div> */}
        <div>
          <AdminTable
            titles={titles}
            data={treatmentsForTable}
            handleGoToDetail={handleGoToDetail}
            headerWidth={"31.44%"}
            itemWidth={"28.66%"}
          />
        </div>
      </div>
    </div>
  );
};

export default DoctorTreatments;
