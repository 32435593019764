import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Login, Signup } from "pages/Pharmacy";
import PharmacyPasswordUpdate from "../../pages/Pharmacy/ForgetPassword/pharmacyPaswordUpdate";
import PhramcyForgetPassword from "pages/Pharmacy/ForgetPassword/pharmacyForgePassword";

const PharmacyAuthRoutes = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<PhramcyForgetPassword />} />
      <Route path="/update-password" element={<PharmacyPasswordUpdate />} />
    </Routes>
  );
};

export default PharmacyAuthRoutes;
