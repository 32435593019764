import React, { useState, useEffect } from "react";
import classNames from "classnames";
import MainHomeServices from "../../../mainHomeServices.module.css";
import commonStyles from "shared/utils/common.module.css";
import { ArrowBack, CustomInput } from "shared/components";
import { PiUploadSimpleFill } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";
import ImgPicker from "shared/components/Img-picker";
import { setAmbulanceUserFormData } from "shared/redux/reducers/ambulance/ambulanceReducer";
import Loaction from "../../../../../assets/images/loaction.png";
import { ambulanceBasicInfoSchema } from "shared/utils";
import { PrimaryButton } from "shared/components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import LocationInput from "shared/components/LocationInput";
import CustomSelect from "shared/components/CustomSelect";
import Datepicker from "shared/components/DatePicker";
import moment from "moment";
const options = ["Lahore", "Karachi", "Multan", "Islambad"];
const options2 = [
  "United States",
  "Canada",
  "United Kingdom",
  "Germany",
  "France",
  "Italy",
  "Australia",
  "Brazil",
  "India",
  "Japan",
  "China",
  "South Korea",
  "Mexico",
  "Russia",
  "South Africa",
  "Nigeria",
  "Argentina",
  "Spain",
  "Turkey",
  "Saudi Arabia",
];
interface Props {
  handleClickNext: any;
}
const AmbulanceBasicInfo = (props: Partial<Props>) => {
  const dispatch = useDispatch();
  const [amblogo, setAmbLogo] = useState(false);
  const [cnicLogo, setCnicLogo] = useState(false);
  const [regImg, setRegImg] = useState(false);
  const [cnic, setCnic] = useState(false);

  const { handleClickNext } = props;

  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };

  // const handleSubmit = async () => {
  //   console.log("test.....");

  //   handleClickNext();
  // }
  const formik = useFormik({
    initialValues: {
      ambulanceAmbulanceName: "",
      ambulanceLogo: "",
      ambulanceRegistrationNumber: "",
      ambulanceRegistrationExpiry: "",
      ambulanceRegistrationImage: "",
      ambulanceFirstOwnerName: "",
      ambulanceLastOwnerName: "",
      ambulanceEmergencyNumber: "",
      // ambulanceCnicNumber: "",
      // ambulanceCnicExpiryDate: "",
      // ambulanceCnicImage: "",
      address: "",
      lat: "",
      lng: "",
      city: "",
    },
    validationSchema: Yup.object(ambulanceBasicInfoSchema),
    onSubmit: (values) => {
      console.log("hey");
      console.log("vale", formik.values);
      handleSubmit();
    },
  });

  const handleAmbulanceLogoUrl = (url: any) => {
    console.log("ambulanceLogo", url);
    setAmbLogo(false);
    formik.setFieldValue("ambulanceLogo", url);
  };
  const handleRegistrationImageUrl = (url: any) => {
    console.log("ambulanceRegistrationImage", url);
    setRegImg(false);
    formik.setFieldValue("ambulanceRegistrationImage", url);
  };
  const handleCnicImageUrl = (url: any) => {
    console.log("ambulanceCnicImage", url);
    setCnic(false);
    formik.setFieldValue("ambulanceCnicImage", url);
  };

  const handleRegistrationExpiry = (date: any) => {
    let date1 = moment(date).toDate();
    const formattedDate = date1?.toISOString()?.split("T")[0];

    console.log("ambulanceRegistrationExpiry", formattedDate);
    formik.setFieldValue("ambulanceRegistrationExpiry", formattedDate);
  };
  const handleCnicExpiry = (date: any) => {
    let date1 = moment(date).toDate();

    const formattedDate = date1.toISOString().split("T")[0];
    console.log("ambulanceCnicExpiryDate", formattedDate);
    // formik.setFieldValue("ambulanceCnicExpiryDate", formattedDate);
  };
  const handleSetAddressData = (value: any) => {
    // console.log("CITYYY................", value.city);
    // console.log("LAT................", value.lat);
    // console.log("LONG..........", value.lng);
    // console.log("LABEL.............", value.label);
    formik.setFieldValue("address", value.label);
    formik.setFieldValue("lat", value.lat);
    formik.setFieldValue("lng", value.lng);
    formik.setFieldValue("city", value.city);
  };

  const handleSubmit = async () => {
    if (!formik.values.ambulanceLogo) {
      setAmbLogo(true);
    } else if (!formik.values.ambulanceRegistrationImage) {
      setRegImg(true);
      // } else if (!formik.values.ambulanceCnicImage) {
      //   setCnic(true);
    } else {
      await dispatch(setAmbulanceUserFormData(formik.values));
      console.log("values of amb info", setAmbulanceUserFormData);
      handleClickNext();
      console.log("values of amb info", setAmbulanceUserFormData);
      setAmbLogo(false);
      setRegImg(false);
      setCnic(false);
    }
  };
  const handleSetData = () => {};
  return (
    <div className={classNames(commonStyles.col12, commonStyles.mt56)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div className={classNames(commonStyles.colsm12, commonStyles.col8)}>
            <div className={commonStyles.mr32}>
              {" "}
              <CustomInput
                placeholder="Ambulance Name"
                id="ambulanceAmbulanceName"
                name="ambulanceAmbulanceName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ambulanceAmbulanceName}
              />
              {formik.touched.ambulanceAmbulanceName &&
              formik.errors.ambulanceAmbulanceName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceAmbulanceName}
                </div>
              ) : null}
            </div>
          </div>

          <div
            className={classNames(
              commonStyles.colsm12,
              commonStyles.col4,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              {" "}
              <ImgPicker
                placeholder="Ambulance Logo"
                setData={handleAmbulanceLogoUrl}
              />
              {formik.touched.ambulanceLogo && formik.errors.ambulanceLogo ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceLogo}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              {" "}
              <CustomInput
                placeholder="Registration number"
                id="ambulanceRegistrationNumber"
                name="ambulanceRegistrationNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ambulanceRegistrationNumber}
              />
              {formik.touched.ambulanceRegistrationNumber &&
              formik.errors.ambulanceRegistrationNumber ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceRegistrationNumber}
                </div>
              ) : null}
            </div>
          </div>

          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              <Datepicker
                placeholder="Registration Expiry"
                setData={handleRegistrationExpiry}
              />
              {formik.touched.ambulanceRegistrationExpiry &&
              formik.errors.ambulanceRegistrationExpiry ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceRegistrationExpiry}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              {" "}
              <ImgPicker
                placeholder="Registration Image"
                setData={handleRegistrationImageUrl}
              />
              {formik.touched.ambulanceRegistrationImage &&
              formik.errors.ambulanceRegistrationImage ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceRegistrationImage}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              {" "}
              <CustomInput
                placeholder="Owner First Name"
                id="ambulanceFirstOwnerName"
                name="ambulanceFirstOwnerName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ambulanceFirstOwnerName}
              />
              {formik.touched.ambulanceFirstOwnerName &&
              formik.errors.ambulanceFirstOwnerName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceFirstOwnerName}
                </div>
              ) : null}
            </div>
          </div>

          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              {" "}
              <CustomInput
                placeholder="Owner Last Name"
                id="ambulanceLastOwnerName"
                name="ambulanceLastOwnerName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ambulanceLastOwnerName}
              />
              {formik.touched.ambulanceLastOwnerName &&
              formik.errors.ambulanceLastOwnerName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceLastOwnerName}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              {" "}
              <CustomInput
                placeholder="Emergency Number"
                id="ambulanceEmergencyNumber"
                name="ambulanceEmergencyNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ambulanceEmergencyNumber}
              />
              {formik.touched.ambulanceEmergencyNumber &&
              formik.errors.ambulanceEmergencyNumber ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceEmergencyNumber}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="CNIC / Passport Number (Optional)"
                id="ambulanceCnicNumber"
                name="ambulanceCnicNumber"
                type="text"
                // onChange={formik.handleChange}
                // value={formik.values.ambulanceCnicNumber}
              />
              {/* {formik.touched.ambulanceCnicNumber && (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceCnicNumber}
                </div>
              )} */}
            </div>
          </div>

          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              {" "}
              <Datepicker
                placeholder="CNIC / Passport Expiry (Optinal)"
                // setData={handleCnicExpiry}
              />
              {/* {formik.touched.ambulanceCnicExpiryDate && (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceCnicExpiryDate}
                </div>
              )} */}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              {" "}
              <ImgPicker
                placeholder="CNIC / Passport Image (Optinal)"
                // setData={handleCnicImageUrl}
              />
              {/* {formik.touched.ambulanceCnicImage &&
              formik.errors.ambulanceCnicImage ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceCnicImage}
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
        <div className={classNames(MainHomeServices.flx)}>
          <div className={classNames(commonStyles.col12, commonStyles.mtsm28)}>
            <div className={commonStyles.mr32}>
              <LocationInput
                placeholder="Company Address "
                setData={handleSetAddressData}
                
              />
              {formik.touched.address && formik.errors.address ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.address}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={MainHomeServices.buttonWidth}>
          <PrimaryButton
            children={"Next"}
            type="submit"
            colorType={"Ambulance"}
          />
        </div>
      </form>
    </div>
  );
};

export default AmbulanceBasicInfo;
