import React, { useEffect } from "react";

import { Doctor_SocialInfo } from "shared/components";

import { setRentCarUserFormData } from "shared/redux";
import { useDispatch } from "react-redux";

interface Props {
  handleClickNext: any;
}

const RentACarSocial = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const dispatch = useDispatch();

  const handleNext = (data: any) => {
    console.log("dd.....", data);
    dispatch(setRentCarUserFormData(data));
    handleClickNext();
  };

  return <Doctor_SocialInfo handleNext={handleNext} />;
};

export default RentACarSocial;
