import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SideBar } from "shared/components";
import PharmcyDashboard from "pages/Pharmacy/Pharmcydashborad/index";
import Pharmcyorder from "pages/Pharmacy/PharmcyOrder/index";
import Medicines from "pages/Pharmacy/Medicines/index";
import PharmcyPayment from "pages/Pharmacy/PharmayPayment/index";
import { pharmacyMenu } from "shared/utils";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
// import PharmacySetting from "pages/Pharmacy/PharmacySetting";
import Chat from "shared/components/Chat";
import MedicinesDetail from "pages/Pharmacy/Medicines/MedicinesDetail";
import PharOrderDetail from "pages/Pharmacy/PharmcyOrder/OrderDetail";
import Profile from "shared/components/Profile";
import Request from "pages/Pharmacy/Request";
import { Pharmacy_Payments_Details } from "pages";
const PharmacyMainRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/pharmacy/dashboard");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: "0px",
          height: "100vh",
        }}
      >
        <SideBar menuItem={pharmacyMenu} />
      </div>
      <Routes>
        <Route path="pharmacy/dashboard" element={<PharmcyDashboard />} />
        {/* <Route path="pharmacy/medicine" element={<Medicines />} /> */}
        <Route path="/medicinesDetail/:id" element={<MedicinesDetail />} />
        <Route path="pharmacy/order" element={<Pharmcyorder />} />
        <Route path="pharmacy/orderDetails/:id" element={<PharOrderDetail />} />
        <Route path="pharmacy/payment" element={<PharmcyPayment />} />
        <Route path="pharmacy/Request" element={<Request />} />

        <Route
          path="/pharmacy/paymentDetails"
          element={<Pharmacy_Payments_Details />}
        />
        {/* <Route path="pharmacy/setting" element={<PharmacySetting />} /> */}
        <Route path="pharmacy/privacy" element={<PrivacyPolicy />} />
        <Route path="chat/message" element={<Chat />} />
        <Route path="/profile" Component={Profile} />
      </Routes>
    </div>
  );
};

export default PharmacyMainRoutes;
