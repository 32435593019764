import classNames from "classnames";
import React, { useState } from "react";
import MainHomeServices from "../../../mainHomeServices.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ambulanceBankSchema } from "shared/utils";
import ImgPicker from "shared/components/Img-picker";
import commonStyles from "shared/utils/common.module.css";
import { GrAttachment } from "react-icons/gr";
import Attach from "../../../../../assets/attach.png";
import { useDispatch, useSelector } from "react-redux";
import { ambSignup } from "shared/services/Ambulance";
import {
  setAmbulanceUserFormData,
  setAmbulanceUser_ID,
} from "shared/redux/reducers/ambulance/ambulanceReducer";
import { CustomInput, PrimaryButton } from "shared/components";

interface Props {
  handleClickNext: any;
}
const AmbulanceBankDetail = (props: Partial<Props>) => {
  const [TaxFileError, setTaxFileError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { ambulanceUserFormData } = useSelector(
    (state: any) => state.root.ambulance
  );
  const { fcmToken } = useSelector((state: any) => state.root.common);
  const { handleClickNext } = props;
  // const handleSubmit = async () => {
  //   console.log("test.....");
  //   handleClickNext();
  // }

  const formik = useFormik({
    initialValues: {
      ambulanceIncomeTextNumber: "",
      ambulanceTaxRegistrationNumber: "",
      ambulanceTaxFile: "",
      ambulanceBankName: "",
      ambulanceAccountHolderName: "",
      ambulanceAccountNumber: "",
    },
    validationSchema: Yup.object(ambulanceBankSchema),
    onSubmit: (values) => {
      console.log("hey");
      handleSubmit();
    },
  });
  const handleTaxFileUrl = (url: any) => {
    setTaxFileError(false);
    formik.setFieldValue("ambulanceTaxFile", url);
  };

  const handleSubmit = async () => {
    console.log("hitt........");
    dispatch(setAmbulanceUserFormData({ ...formik.values }));
    handleClickNext();
    setLoading(true);
    let currentData = formik.values;

    let params = {
      name: ambulanceUserFormData.ambulanceAmbulanceName,
      registrationNumber: ambulanceUserFormData.ambulanceRegistrationNumber,
      registrationExpiry: ambulanceUserFormData.ambulanceRegistrationExpiry,
      ownerFirstName: ambulanceUserFormData.ambulanceFirstOwnerName,
      ownerLastName: ambulanceUserFormData.ambulanceLastOwnerName,
      emergencyNo: ambulanceUserFormData.ambulanceEmergencyNumber,
      cnicOrPassportNo: ambulanceUserFormData.ambulanceCnicNumber,
      cnicOrPassportExpiry: ambulanceUserFormData.ambulanceCnicExpiryDate,
      logo: ambulanceUserFormData.ambulanceLogo,
      registrationImage: ambulanceUserFormData.ambulanceRegistrationImage,
      cnicOrPassportImage: ambulanceUserFormData.ambulanceCnicImage,
      location: {
        lat: ambulanceUserFormData.lat,
        lng: ambulanceUserFormData.lng,
        address: ambulanceUserFormData.address,
        city: ambulanceUserFormData.city,
      },
      //......Social
      ...(ambulanceUserFormData.ambulancewebUrl && {
        website: ambulanceUserFormData.ambulancewebUrl,
      }),
      ...(ambulanceUserFormData.ambulancetwitterUrl && {
        twitter: ambulanceUserFormData.ambulancetwitterUrl,
      }),
      ...(ambulanceUserFormData.ambulanceistinstaUrl && {
        instagram: ambulanceUserFormData.ambulanceistinstaUrl,
      }),
      ...(ambulanceUserFormData.ambulancefbUrl && {
        facebook: ambulanceUserFormData.ambulancefbUrl,
      }),
      incomeTaxNo: currentData.ambulanceIncomeTextNumber,
      salesTaxNo: currentData.ambulanceTaxRegistrationNumber,
      taxFileImage: currentData.ambulanceTaxFile,
      bankName: currentData.ambulanceBankName,
      accountHolderName: currentData.ambulanceAccountHolderName,
      accountNumber: currentData.ambulanceAccountNumber,
      fcmToken: fcmToken,
    };

    ambSignup(params)
      .then((res: any) => {
        handleClickNext();
        if (res.data.auth) {
          console.log("response.....", res?.data);
          handleClickNext();
          dispatch(setAmbulanceUserFormData({}));
          dispatch(setAmbulanceUser_ID(res.data.ambulance._id));
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classNames(commonStyles.col12, commonStyles.mt56)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Income Text Number"
                id="ambulanceIncomeTextNumber"
                name="ambulanceIncomeTextNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ambulanceIncomeTextNumber}
              />
              {formik.touched.ambulanceIncomeTextNumber &&
              formik.errors.ambulanceIncomeTextNumber ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceIncomeTextNumber}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="Sales Tax Registration Number"
                id="ambulanceTaxRegistrationNumber"
                name="ambulanceTaxRegistrationNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ambulanceTaxRegistrationNumber}
              />
              {formik.touched.ambulanceTaxRegistrationNumber &&
              formik.errors.ambulanceTaxRegistrationNumber ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceTaxRegistrationNumber}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              <ImgPicker
                setData={handleTaxFileUrl}
                placeholder="Attach Tax File"
              />
              {formik.touched.ambulanceTaxFile &&
              formik.errors.ambulanceTaxFile ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceTaxFile}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="Bank Name"
                id="ambulanceBankName"
                name="ambulanceBankName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ambulanceBankName}
              />
              {formik.touched.ambulanceBankName &&
              formik.errors.ambulanceBankName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceBankName}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="Account Holder Name"
                id="ambulanceAccountHolderName"
                name="ambulanceAccountHolderName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ambulanceAccountHolderName}
              />
              {formik.touched.ambulanceAccountHolderName &&
              formik.errors.ambulanceAccountHolderName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceAccountHolderName}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col12,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="Account Number"
                id="ambulanceAccountNumber"
                name="ambulanceAccountNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ambulanceAccountNumber}
              />
              {formik.touched.ambulanceAccountNumber &&
              formik.errors.ambulanceAccountNumber ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceAccountNumber}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={MainHomeServices.buttonWidth}>
          <PrimaryButton
            children={"Next"}
            type="submit"
            colorType={"Ambulance"}
          />
        </div>
      </form>
    </div>
  );
};

export default AmbulanceBankDetail;
