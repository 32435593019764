import React from "react";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import CustomSelect from "shared/components/CustomSelect";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { PrimaryButton } from "shared/components";
import {
  insuranceTravelFamilyMedicalInfoSchema,
  insuranceTravelIndividualMedicalInfoSchema,
} from "shared/utils";
import { setInsuranceTravelFamily } from "shared/redux";
const Accidental = ["Yes", "No"];
const Repatriation = ["Yes", "No"];
const Deliveryofmedicine = ["Yes", "No"];
const MedicalExpenses = ["Yes", "No"];
const Return = ["Yes", "No"];
const Repatriationillnes = ["Yes", "No"];
const Emergency = ["Yes", "No"];
const EmergencyDental = ["Yes", "No"];
const Covid = ["Covid - 19", "Karachi", "Multan", "Islambad"];
interface Props {
  handleClickNext: any;
}
export default function FamilyMedicalBenefits(props: Partial<Props>) {
  const { insuranceTravelFamily } = useSelector(
    (state: any) => state.root.insurance
  );
  console.log("console", insuranceTravelFamily);
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };
  const dispatch = useDispatch();
  const { handleClickNext } = props;
  const formik = useFormik({
    initialValues: {
      accidentalDisability: "",
      repatriationofMortalRemains: "",
      expensesHospitalization: "",
      emergencyReturnHome: "",
    },
    validationSchema: Yup.object(insuranceTravelFamilyMedicalInfoSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const Accidental = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("accidentalDisability", selectedOption);
  };
  const RepatriationofMortalRemains = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("repatriationofMortalRemains", selectedOption);
  };
  const ExpensesHospitalization = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("expensesHospitalization", selectedOption);
  };
  const EmergencyReturnHome = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("emergencyReturnHome", selectedOption);
  };

  const handleSubmit = () => {
    dispatch(
      setInsuranceTravelFamily({
        ...insuranceTravelFamily,
        ...formik.values,
      })
    );
    handleClickNext();
  };
  return (
    <div className={classNames(commonStyles.col6)}>
      <form onSubmit={formik.handleSubmit}>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6, commonStyles.mr24)}>
            <CustomSelect
              onSelect={Accidental}
              options={MedicalExpenses}
              placeholder="Accidental Death & Disability:"
            />
            {formik.touched.accidentalDisability &&
            formik.errors.accidentalDisability ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.accidentalDisability}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonStyles.col6)}>
            <CustomSelect
              onSelect={RepatriationofMortalRemains}
              options={Repatriation}
              placeholder="Repatriation of mortal remains:"
            />
            {formik.touched.repatriationofMortalRemains &&
            formik.errors.repatriationofMortalRemains ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.repatriationofMortalRemains}
              </div>
            ) : null}
          </div>
        </div>

        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6, commonStyles.mr24)}>
            <CustomSelect
              onSelect={ExpensesHospitalization}
              options={Repatriationillnes}
              placeholder="Medical Expenses & Hospitalization"
            />
            {formik.touched.expensesHospitalization &&
            formik.errors.expensesHospitalization ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.expensesHospitalization}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonStyles.col6)}>
            <CustomSelect
              onSelect={EmergencyReturnHome}
              placeholder="Emergency return home:"
              options={Return}
            />
            {formik.touched.emergencyReturnHome &&
            formik.errors.emergencyReturnHome ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.emergencyReturnHome}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ width: "210px", marginTop: "56px" }}>
          <PrimaryButton
            children={"Next"}
            colorType={"Insurance"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
