import React from "react";
import { useDispatch } from "react-redux";
import { Doctor_SocialInfo } from "shared/components";
import { setDoctorFormData } from "shared/redux";

interface Props {
  handleClickNext: any;
}
const DoctorSocial = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const dispatch = useDispatch();

  const handleNext = (data: any) => {
    console.log("dd.....", data);
    dispatch(setDoctorFormData(data));
    handleClickNext();
  };

  return <Doctor_SocialInfo handleNext={handleNext} />;
};

export default DoctorSocial;
