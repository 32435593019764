import React, { useState } from "react";
import AdminNavBar from "../../Components/AdminNavBar";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import Styles from "./adInsurance.module.css";
import { TbRefresh } from "react-icons/tb";
import { RxFileText } from "react-icons/rx";
import commonstyle from "shared/utils/common.module.css";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaMinus, FaPlus } from "react-icons/fa";
import moment from "moment";
import { useLocation } from "react-router-dom";
interface FAQData {
  header: string;
}

export default function InsuranDetail() {
  const [expanded, setExpanded] = useState<number | null>(null);

  const handleAccordionChange =
    (panelIndex: number) =>
    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panelIndex : null);
    };
  const { state } = useLocation();
  console.log("next....state.....Insurance", state);
  let date = moment(state?.createdAt).format("MM-DD-YYYY h:mm a");
  const accordionData: FAQData[] = [
    {
      header: "MEDICAL BENEFITS ",
    },
    {
      header: "POLICY DETAILS",
    },
  ];
  const handleDownload = () => {
    if (state?.insuranceId?.policyDocument) {
      const link = document.createElement("a");
      link.href = state.insuranceId.policyDocument;
      link.download = "policy-document.jpg"; // replace with the desired download file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("No policy document available.");
    }
  };
  return (
    <div className={classNames(commonStyles.col12)}>
      <div className={Styles.Navouter}>
        <AdminNavBar labelText="Booking" />
      </div>
      <div className={classNames(Styles.mainOuter)}>
        <div className={classNames(Styles.flxBetween)}>
          <div className={classNames(commonStyles.flx)}>
            <p
              className={classNames(
                commonStyles.fs22,
                Styles.primarycolor,
                commonStyles.semiBold
              )}
            >
              Insurance Details
            </p>
            <TbRefresh className={Styles.refresh} />
          </div>
          <div className={classNames(commonStyles.flx)}>
            <p
              style={{ color: "#00B69B" }}
              className={classNames(commonStyles.f18, commonStyles.semiBold)}
            >
              Booking ID: ABC1234
            </p>
          </div>
        </div>

        <div className={classNames(commonStyles.flxBetween, Styles.mt24)}>
          <div className={classNames(Styles.DetailCard)}>
            <div className={Styles.headerCard}>
              <p
                className={classNames(commonStyles.fs18, commonStyles.semiBold)}
              >
                COMPANY
              </p>
            </div>
            <div className={Styles.headerBody}>
              <div className={classNames(commonStyles.flxBetween)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Company ID:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  PRO1234
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Name:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.insuranceCompanyId?.name}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Email
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.insuranceCompanyId?.email}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Contact:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.insuranceCompanyId?.phoneNumber}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Address:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.insuranceCompanyId?.location?.address}
                </p>
              </div>
            </div>
          </div>
          <div className={classNames(Styles.DetailCard)}>
            <div className={Styles.headerCard}>
              <p
                className={classNames(commonStyles.fs18, commonStyles.semiBold)}
              >
                USER
              </p>
            </div>
            <div className={Styles.headerBody}>
              <div className={classNames(commonStyles.flxBetween)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  MR No.:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.userId?.mrNo}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Name:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.userId?.name}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Contact:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.userId?.phone}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Email:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.userId?.email}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Identity No. :
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.cnic}
                </p>
              </div>
            </div>
          </div>
          <div className={classNames(Styles.DetailCard)}>
            <div className={Styles.headerCard}>
              <p
                className={classNames(commonStyles.fs18, commonStyles.semiBold)}
              >
                TOTAL AMOUNT
              </p>
            </div>
            <div className={Styles.headerBody}>
              <div className={classNames(commonStyles.flxBetween)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Duration:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  {state?.insuranceId?.perYear}
                </p>
              </div>
              <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Actual Amount:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  Rs. {state?.amount} /-
                </p>
              </div>
              {/* <div className={classNames(commonStyles.flxBetween, Styles.mt8)}>
                <p
                  className={classNames(
                    commonStyles.fs14,
                    commonStyles.semiBold
                  )}
                >
                  Meditour Amount:
                </p>
                <p className={classNames(commonStyles.fs14, Styles.colorGray)}>
                  Rs. 2600
                </p>
              </div> */}
            </div>
          </div>
        </div>

        <div className={classNames(Styles.mt24)}>
          <p
            className={classNames(
              Styles.mt24,
              Styles.mb16,
              commonstyle.fs24,
              commonstyle.semiBold,
              Styles.primarycolor
            )}
          >
            Package Details
          </p>
          <div className={Styles.container}>
            {accordionData.map((data, index) => (
              <div key={index} className={classNames(Styles.mt8)}>
                <Accordion
                  className={Styles.dropdown}
                  expanded={expanded === index}
                  onChange={handleAccordionChange(index)}
                >
                  <AccordionSummary
                    style={{ padding: "0px 16px" }}
                    expandIcon={
                      expanded === index ? (
                        <FaMinus style={{ color: "#393a44" }} />
                      ) : (
                        <FaPlus style={{ color: "#393a44" }} />
                      )
                    }
                    aria-controls={`panel${index + 1}-content`}
                    id={`panel${index + 1}-header`}
                  >
                    <Typography style={{ fontWeight: "600" }}>
                      <p
                        className={classNames(commonStyles.fs18)}
                        style={{
                          textAlign: "start",
                          color: "#393A44",
                        }}
                      >
                        {data.header}
                      </p>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "8px" }}>
                    <Typography>
                      <div className={Styles.colorGray}>
                        {" "}
                        {index == 0 && (
                          <>
                            <div style={{ marginLeft: "32px" }}>
                              <ul className={Styles.detailLIst}>
                                <li>ICU / CCU</li>
                                <li>
                                  Additional Limits for Accidental Emergencies
                                  (Rs 10000)
                                </li>
                                <li>Ambulance Service Coverage</li>
                                <li>
                                  Coverage of Specialized Investigations
                                  (Covered (Sub limit - Rs. 10,000)
                                </li>
                                <li>Waiting Period (2 weeks)</li>
                                <li>
                                  Maternity (Pay additional Rs 6,188 and get)
                                </li>
                              </ul>
                            </div>
                            <div style={{ marginLeft: "16px" }}>
                              <p
                                className={classNames(
                                  commonStyles.fs14,
                                  commonStyles.medium,
                                  Styles.primarycolor,
                                  Styles.mt16
                                )}
                              >
                                More Features
                              </p>
                              <p
                                className={classNames(
                                  commonStyles.fs14,
                                  Styles.colorGray,
                                  Styles.mt8
                                )}
                              >
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </p>
                            </div>
                          </>
                        )}
                        {index == 1 && (
                          <div style={{ marginLeft: "8px" }}>
                            <p
                              className={classNames(
                                commonStyles.fs14,
                                Styles.colorGray,
                                Styles.mt16,
                                Styles.dowmload
                              )}
                              onClick={handleDownload}
                            >
                              Download Policy Document
                            </p>

                            <div className={Styles.mt16}>
                              <p
                                className={classNames(
                                  commonStyles.fs14,
                                  commonStyles.medium,
                                  Styles.primarycolor
                                )}
                              >
                                More Features
                              </p>
                              <p
                                className={classNames(
                                  commonStyles.fs14,
                                  Styles.colorGray
                                )}
                              >
                                {state?.insuranceId?.heading} <br />
                                {state?.insuranceId?.description}
                              </p>
                            </div>
                            <div className={Styles.mt16}>
                              <p
                                className={classNames(
                                  commonStyles.fs14,
                                  commonStyles.medium,
                                  Styles.primarycolor
                                )}
                              >
                                About
                              </p>
                              <p
                                className={classNames(
                                  commonStyles.fs14,
                                  Styles.colorGray
                                )}
                              >
                                {state?.insuranceCompanyId?.companyDescription}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
