import React, { FC } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Style from "./rentpiechart.module.css";

interface DataItem {
  name: string;
  value: number;
}

interface RentAcarPieChartProps {
  data2: DataItem[];
}

const COLORS = ["#FF947A", "#D83C3C", "#4679F9"];

const RentAcarPieChart: FC<RentAcarPieChartProps> = ({ data2 }) => {
  console.log(".................................", data2);
  // Calculate total for normalization
  const total = data2.reduce((acc, entry) => acc + entry.value, 0);

  return (
    <ResponsiveContainer width="100%" height={100}>
      <PieChart>
        <Pie
          data={data2}
          cx={50}
          cy={45}
          innerRadius={25}
          outerRadius={50}
          fill="#8884d8"
          dataKey="value"
        >
          {data2.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          content={
            <ul
              style={{
                padding: 0,
                textAlign: "center",
                position: "absolute",
                bottom: "1px",
                left: "35%",
              }}
            >
              {data2.map((entry, index) => (
                <div className={Style.lists} key={`legend-item-${index}`}>
                  <li
                    style={{
                      color: COLORS[index % COLORS.length],
                      width: "200px",
                    }}
                  >
                    {`${entry.name} ${entry.value}`}
                  </li>
                </div>
              ))}
            </ul>
          }
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default RentAcarPieChart;
