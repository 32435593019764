import React, { useState } from "react";
import styles from "./faq.module.css";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaPlus } from "react-icons/fa";

interface FAQData {
  header: string;
  Content: string;
}

export default function FAQS() {
  const accordionData: FAQData[] = [
    {
      header: "What is MediTour Global?",
      Content:
        "MediTour Global is a leading global Medical Tourism platform that connects patients with medical centers (hospitals, clinics, doctors, Laboratories, Pharmacies, Paramedic, Physiotherapists, Psychologists, Nutritionists, Hotel & Tourism, Health & Travel Insurance companies, etc.) from Pakistan. MediTour Global platform provides end-to-end solutions from online consultation to in-patient management, Registering to Visas, travel and stay arrangements, and Treatment to follow-up all in one system.",
    },
    {
      header: "How can I trust the quality of care abroad?",
      Content:
        "We partner with internationally accredited hospitals and highly qualified doctors. Each facility and professional undergoes rigorous evaluation to meet global healthcare standards, ensuring you receive the highest quality care.",
    },
    {
      header: "How do I start my journey with MediTour?",
      Content:
        "To begin your medical journey with MediTour, simply browse our website to explore various treatment options and destinations. Once you've identified your needs, you can submit an inquiry, and our dedicated team will guide you through the process, including appointment scheduling, travel arrangements, and accommodation.",
    },
    {
      header: "How do I start the process of booking a medical tour?",
      Content:
        "Simply contact us through our website or MobileApp. We will guide you through the initial consultation, help you choose the best healthcare provider, and assist with all necessary arrangements including travel and accommodation.",
    },
    {
      header: "Are there any risks involved in medical tourism?",
      Content:
        "While all medical procedures carry some level of risk, we minimize these risks by partnering with reputable, accredited medical facilities and experienced professionals. We also encourage thorough pre-treatment consultations and provide comprehensive post-treatment follow-up care.",
    },
  ];

  const [expanded, setExpanded] = useState<number | null>(null);

  const handleAccordionChange =
    (panelIndex: number) =>
    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panelIndex : null);
    };

  return (
    <div className={styles.container}>
      {accordionData.map((data, index) => (
        <div key={index} className={styles.mt8}>
          <Accordion
            className={styles.dropdown}
            expanded={expanded === index}
            onChange={handleAccordionChange(index)}
          >
            <AccordionSummary
              sx={{
                ".MuiAccordionSummary-expandIconWrapper": {
                  transform: "none",
                  transition: " none",
                },
              }}
              style={{ padding: "10px 0px" }}
              expandIcon={
                <FaPlus
                  className={classNames(styles.icon, {
                    [styles.rotate]: expanded === index,
                    [styles.colorChanged]: expanded === index,
                  })}
                />
              }
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography style={{ fontWeight: "700" }}>
                <div>
                  <p
                    className={classNames(commonstyle.fs16)}
                    style={{
                      color: expanded === index ? "#ff7631" : "#0e54a3",
                      textAlign: "start",
                    }}
                  >
                    {data.header}
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                padding: "8px 0px 16px",
                textAlign: "start",
                textJustify: "none",
              }}
            >
              <Typography>
                <div>
                  <p className={classNames(commonstyle.fs16)}>{data.Content}</p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
}
