import React, { useEffect, useState } from "react";

import { Doctor_Verification } from "shared/components";
import { useDispatch, useSelector } from "react-redux";
import { set_IsEmailVerified } from "shared/redux";

import { completeSignup, sendCodeToEmail, verifyEmail } from "shared/services";

const Verification = () => {
  const dispatch = useDispatch();
  const { labuser_id } = useSelector((state: any) => state.root.lab);

  const handleSendCodeToEmail = (
    params: any,
    setVerifyLoading: any,
    setVerifyError: any,
    handleTimer: any
  ) => {
    sendCodeToEmail(params)
      .then((res: any) => {
        handleTimer();
      })
      .catch((err: any) => {
        setVerifyError(err.response.data.message);
      })
      .finally(() => {
        setVerifyLoading(false);
      });
  };

  const handleSubmitCode = (
    params: any,
    setVerifyLoading: any,
    setCodeError: any,
    handleVerifySuccess: any
  ) => {
    verifyEmail(params)
      .then((res: any) => {
        handleVerifySuccess(res);
      })
      .catch((err: any) => {
        setCodeError(err.response.data.message);
      })
      .finally(() => {
        setVerifyLoading(false);
      });
  };

  const handleSubmit = async (params: any, handleSignupSuccess: any) => {
    completeSignup(params, labuser_id)
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          handleSignupSuccess();
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        // setLoading(false);
        dispatch(set_IsEmailVerified(false));
      });
  };

  return (
    <>
      <Doctor_Verification
        handle_SendCodeToEmail={handleSendCodeToEmail}
        handle_SubmitCode={handleSubmitCode}
        handle_CompleteSignup={handleSubmit}
      />
    </>
  );
};

export default Verification;
