import InsuranceForget from "pages/Insurance/Forgetpassword/InsuranceForget1";
import InsuranceUpdatePassword from "pages/Insurance/Forgetpassword/InsuranceUpdatepassword";
import InsuranceLogin from "pages/Insurance/Login";
import InsuranceSigup from "pages/Insurance/Signup";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

const InsuranceAuthRoutes = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<InsuranceLogin />} />
      <Route path="/signup" element={<InsuranceSigup />} />
      <Route path="/forgot-password" element={<InsuranceForget />} />
      <Route path="/update-password" element={<InsuranceUpdatePassword />} />
    </Routes>
  );
};

export default InsuranceAuthRoutes;
