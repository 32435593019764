import { DonationLogin, DonationSignup } from "pages/Donation";
import DonationForget from "pages/Donation/Forgetpassword/donationForget";
import DonationUpdatepassword from "pages/Donation/Forgetpassword/donationUpdatepassword";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

const DonationAuthRoutes = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<DonationLogin />} />
      <Route path="/signup" element={<DonationSignup />} />
      <Route path="/forgot-password" element={<DonationForget />} />
      <Route path="/update-password" element={<DonationUpdatepassword />} />
    </Routes>
  );
};

export default DonationAuthRoutes;
