import React, { useEffect, useState } from "react";
import commonStyles from "shared/utils/common.module.css";
import MainTravelStyle from "../../../mainTravel.module.css";
import labMainStyles from "../../../../Laboratory/laboratoryMain.module.css";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { hotelSignup } from "shared/services/Hotel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "shared/components";
import { CustomInput } from "shared/components";
import { setHotelUserFormData, setHotelUser_ID } from "shared/redux";
import ImgPicker from "shared/components/Img-picker";
import { hotelBankSchema } from "shared/utils";
interface Props {
  handleClickNext: any;
}

function HotelBankDetail(props: Partial<Props>) {
  const { handleClickNext } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { hotelUserFormData } = useSelector((state: any) => state.root.hotel);
  const { fcmToken } = useSelector((state: any) => state.root.common);

  const formik = useFormik({
    initialValues: {
      incomeTaxNo: "",
      saleTaxNo: "",
      bankName: "",
      accountHolderName: "",
      accountNumber: "",
      TaxFile: "",
    },
    validationSchema: Yup.object(hotelBankSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleTaxFileUrl = (url: any) => {
    formik.setFieldValue("TaxFile", url);
  };
  const handleSubmit = async () => {
    dispatch(setHotelUserFormData({ ...formik.values }));
    setLoading(true);
    let currentData = formik.values;
    let params = {
      name: hotelUserFormData.name,
      logo: hotelUserFormData.logo,
      companyLicenseNo: hotelUserFormData.licenseNumber,
      companyLicenseExpiry: hotelUserFormData.licenseExpiry,
      licenseImage: hotelUserFormData.licenseImage,
      companyEmergencyNo: hotelUserFormData.emergencyNumber,
      ownerFirstName: hotelUserFormData.ownerFirstName,
      ownerLastName: hotelUserFormData.ownerLastName,
      cnicOrPassportNo: hotelUserFormData.cnicNumber,
      cnicOrPassportExpiry: hotelUserFormData.cnicExpiry,
      cnicImage: hotelUserFormData.cnicImage,
      location: {
        lng: hotelUserFormData.lng,
        lat: hotelUserFormData.lat,
        address: hotelUserFormData.address,
        city: hotelUserFormData.city,
      },
      ...(hotelUserFormData.webUrl && { website: hotelUserFormData.webUrl }),
      ...(hotelUserFormData.twitterUrl && {
        twitter: hotelUserFormData.twitterUrl,
      }),
      ...(hotelUserFormData.instaUrl && {
        instagram: hotelUserFormData.instaUrl,
      }),

      incomeTaxNo: currentData.incomeTaxNo,
      salesTaxNo: currentData.saleTaxNo,
      bankName: currentData.bankName,
      accountHolderName: currentData.accountHolderName,
      accountNumber: currentData.accountNumber,
      taxFileImage: currentData.TaxFile,
      fcmToken,
    };

    hotelSignup(params)
      .then((res: any) => {
        if (res.data && res.data.auth) {
          handleClickNext();
          dispatch(setHotelUserFormData({}));
          dispatch(setHotelUser_ID(res.data.hotel._id));
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.col12, commonStyles.mt56)}>
          <div className={classNames(commonStyles.mb16, MainTravelStyle.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Income Tax Number"
                  id="incomeTaxNo"
                  name="incomeTaxNo"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.incomeTaxNo}
                />
                {formik.touched.incomeTaxNo && formik.errors.incomeTaxNo ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.incomeTaxNo}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="Sales Tax Reg. No"
                  id="saleTaxNo"
                  name="saleTaxNo"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.saleTaxNo}
                />
                {formik.touched.saleTaxNo && formik.errors.saleTaxNo ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.saleTaxNo}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <ImgPicker
                  placeholder="Attach Tax File"
                  setData={handleTaxFileUrl}
                />
                {formik.touched.TaxFile && formik.errors.TaxFile ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.TaxFile}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, MainTravelStyle.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="Bank Name "
                  id="bankName"
                  name="bankName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.bankName}
                />
                {formik.touched.bankName && formik.errors.bankName ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.bankName}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Account Holder Name"
                  id="accountHolderName"
                  name="accountHolderName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.accountHolderName}
                />
                {formik.touched.accountHolderName &&
                formik.errors.accountHolderName ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.accountHolderName}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={classNames(MainTravelStyle.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="Account Number"
                  id="accountNumber"
                  name="accountNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.accountNumber}
                />
                {formik.touched.accountNumber && formik.errors.accountNumber ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.accountNumber}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(labMainStyles.buttonWidth)}>
          <PrimaryButton
            disabled={loading}
            children={loading ? "loading..." : "Next"}
            type="submit"
            colorType={"Travel"}
          />
        </div>
      </form>
    </div>
  );
}

export default HotelBankDetail;
