import React from "react";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import styles from "./payment.module.css";

import commonstyles from "shared/utils/common.module.css";
import { Vendor_PaymentDetails } from "shared/components";

const Pharmacy_Payments_Details = () => {
  return (
    <div className={classNames(commonstyles.col12, styles.outer)}>
      <SearchBar />
      <Vendor_PaymentDetails />
    </div>
  );
};

export default Pharmacy_Payments_Details;
