import React from "react";
import styles from "./input.module.css";
import classNames from "classnames";
import commonStyles from "shared/utils/common.module.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
interface Props {
  id?: any;
  name?: string;
  placeholder?: string;
  type?: any;
  value?: any;
  onChange?: any;
  ref?: any;
}
const CustomInput = (props: Partial<Props>) => {
  const { id, name, type, placeholder, value, onChange } = props;
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default behavior of Enter key
    }
  };
  return (
    <div>
      <Box
        component="form"
        sx={{
          color: "#00276d",
          fontStyle: "italic",
          fontFamily: '"Poppins", sans-serif',
          fontSize: "14px",
          "& > :not(style)": { m: 1, width: "100%", font: "inherit" },
          "& .MuiInput-input": {
            color: "#00276d",
            font: "italic",
            fontFamily: '"Poppins", sans-serif',
            fontSize: "14px",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          sx={{
            color: "#00276d",
            fontStyle: "italic",
            fontFamily: '"Poppins", sans-serif',
            fontSize: "14px",
            "&::placeholder": {
              fontFamily: '"Poppins", sans-serif',
              fontSize: "14px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontStyle: "italic",
              fontFamily: '"Poppins", sans-serif',
              color: "#00276d",
            },

            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottom: "1px solid #6F6F72", // Set this to the original border style if needed
            },
            "& .MuiInput-underline:after": {
              borderBottom: "1px solid #6F6F72", // Maintains the underline style after input is focused or filled
            },
            "& .MuiInput-underline:before": {
              borderBottom: "1px solid #6F6F72", // Default underline style
            },
          }}
          variant="standard"
          id={id}
          label={placeholder}
          type={type}
          value={value}
          onChange={onChange}
          style={{ margin: "0px" }}
          className={styles.inputStyle}
          onKeyDown={handleKeyDown}
        />
      </Box>
    </div>
    // <div className={styles.custominput}>
    //   <input
    //     id={id}
    //     name={name}
    //     type={type}
    //     placeholder={placeholder}
    //     value={value}
    //     onChange={onChange}
    //     className={classNames(
    //       styles["input-element"],
    //       commonStyles.fs14,
    //       commonStyles.semiBold
    //     )}
    //   />
    // </div>
  );
};

export default CustomInput;
