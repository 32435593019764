import React from "react";
import email from "./email.module.css";
import Tickmark from "assets/images/success.png";
import classNames from "classnames";

export const EmailSendModel = (props) => {
  const { SuccessModel, setSuccessModel, showText } = props;
  console.log("OPEN");
  setTimeout(() => {
    setSuccessModel(false);
  }, 2000);
  return (
    <>
      <div className={classNames(email.outer)}>
        <img className={classNames(email.imgWidth)} src={Tickmark} />

        <div className={classNames(email.p)}>
          <p>{showText}</p>
        </div>
      </div>
    </>
  );
};
