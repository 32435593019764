import classNames from "classnames";
import React, { useState } from "react";
import SearchBar from "shared/components/Searchbar";
import commonStyles from "shared/utils/common.module.css";
import MainTravelStyle from "../../../mainTravel.module.css";
import { ArrowBack, CustomStepper, PrimaryButton } from "shared/components";
import Facilitiesservices from "./Facilitiesservices";
import Amenities from "./Amenities";
import PropertyPhoto from "./PropertyPhoto";
import Payments from "./Payments";
import Privacy from "./Privacy";
import ApartmentsInfo from "./ApartmentsInfo";
import LayoutPrice from "./Layout&Price";
import { Navigate, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
const steps = [
  {
    id: "1",
    lable: "Home Info ",
  },
  {
    id: "2",
    lable: "Layout & Price",
  },
  {
    id: "3",
    lable: "Facilities Services",
  },
  {
    id: "4",
    lable: "Amenities",
  },
  {
    id: "5",
    lable: "Property Photo",
  },
  {
    id: "6",
    lable: "Privacy",
  },
];
const HomeBreadcrum = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/hotel/property/homeGuestbook");
  };
  const handleGoNext = () => {
    navigate("/hotel/property/home/complete");
  };

  const [screenName, setScreenName] = useState("Home Info");
  const [selectedStep, setSelectedStep] = useState(0);
  console.log("screenName....", screenName);

  const handleClickNext = () => {
    if (screenName == "Home Info") {
      setScreenName("Layout & Price");
    }

    if (screenName == "Layout & Price") {
      setScreenName("Facilities Services");
    }

    if (screenName == "Facilities Services") {
      setScreenName("Amenities");
    }
    if (screenName == "Amenities") {
      setScreenName("Property Photo");
    }

    if (screenName == "Property Photo") {
      setScreenName("Privacy");
    }
    if (screenName === "Privacy") {
      handleGoNext();
    }
    if (selectedStep < 6) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "Home Info") {
      handleGoBack();
    }

    if (screenName == "Layout & Price") {
      setScreenName("Home Info");
    }

    if (screenName == "Facilities Services") {
      setScreenName("Layout & Price");
    }

    if (screenName == "Amenities") {
      setScreenName("Facilities Services");
    }

    if (screenName == "Property Photo") {
      setScreenName("Amenities");
    }

    if (screenName == "Privacy") {
      setScreenName("Property Photo");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };
  return (
    <div>
      <div style={{ position: "sticky" }}>
        <SearchBar />
      </div>
      <div className={classNames(commonStyles.mr87)}>
        <div className={classNames(commonStyles.outerContainer)}>
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col6,
              commonStyles.colsm11,
              commonStyles.colmd10
            )}
          >
            <div className={classNames(commonStyles.mr24)}>
              <IoArrowBack
                onClick={handleClicKPrev}
                style={{ width: "20px", height: "20px", color: "#00276d " }}
              />
            </div>
            <p
              className={classNames(
                commonStyles.semiBold,
                commonStyles.fs16,
                MainTravelStyle.mr203,
                commonStyles.colorBlue
              )}
            >
              Back
            </p>
          </div>
          <div
            className={classNames(
              commonStyles.col8,
              commonStyles.colsm9,
              commonStyles.colms10,
              commonStyles.mt40
            )}
          >
            <CustomStepper steps={steps} selectedStep={selectedStep} />
          </div>
          <div
            className={classNames(
              commonStyles.col12,
              commonStyles.colsm11,
              commonStyles.colmd9,
              commonStyles.mt56
            )}
          >
            {screenName == "Home Info" && (
              <ApartmentsInfo handleClickNext={handleClickNext} />
            )}
            {screenName == "Layout & Price" && (
              <LayoutPrice handleClickNext={handleClickNext} />
            )}
            {screenName == "Facilities Services" && (
              <Facilitiesservices handleClickNext={handleClickNext} />
            )}
            {screenName == "Amenities" && (
              <Amenities handleClickNext={handleClickNext} />
            )}
            {screenName == "Property Photo" && (
              <PropertyPhoto handleClickNext={handleClickNext} />
            )}
            {screenName == "Privacy" && <Privacy />}
          </div>

          {/* <div
          style={{ marginTop: "32px" }}
          className={classNames(MainTravelStyle.buttonWidth)}
        >
          <PrimaryButton
            children={"Next"}
            colorType={"blue"}
            onClick={handleClickNext}
          />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default HomeBreadcrum;
