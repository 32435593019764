import React, { useState, useEffect, memo } from "react";
import styles from "../Dashboard/dashborad.module.css";
import commonstyle from "../../../shared/utils/common.module.css";
import classNames from "classnames";
import style from "./dashborad.module.css";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "shared/components/Searchbar";
import { FaRegUser } from "react-icons/fa";
import LabPieChart from "shared/components/LabCharts/LabPieChart";
import LabLineChart from "shared/components/LabCharts/LabLineChart";
import {
  LabGRAPH,
  LabGetNotifications,
  LabGraphDETAILSUpperPortion,
} from "shared/services";
import { FaArrowDown } from "react-icons/fa6";
import { GrRefresh } from "react-icons/gr";
import { FaArrowUp } from "react-icons/fa6";
import { LoadingModal } from "shared/components";
import {
  setLabGraphDetails,
  setLabNotication,
  setLabdayArray,
  setLabdayArray2,
  setrenderLabdashboardFlag,
} from "shared/redux";
import { TbRefresh } from "react-icons/tb";
const data = [
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  // Add more data as needed
];
function Dashboard() {
  const { lab, dayArray, dayArray2, labGraphDetails, renderLabdashboardFlag } =
    useSelector((state: any) => state.root.lab);
  // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", labGraphDetails);
  const lab_ID = lab._id;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const parsedNewOrdersPercentageChange = parseFloat(
    labGraphDetails.newOrdersPercentageChange.replace("%", "")
  );
  const parsedPendingPercentageChange = parseFloat(
    labGraphDetails.pendingPercentageChange.replace("%", "")
  );
  const parsedComingOrdersPercentageChange = parseFloat(
    labGraphDetails.comOrdersPercentageChange.replace("%", "")
  );
  dayArray.forEach(
    (item: { day: string; ordersCount: number }, index: number) => {
      data[index + 1].name = item.day;
      data[index + 1].value1 = item.ordersCount;
    }
  );
  dayArray2.forEach(
    (item: { day: string; ordersCount: number }, index: number) => {
      data[index + 1].name = item.day;
      data[index + 1].value2 = item.ordersCount;
    }
  );
  const GraphDetails = () => {
    setLoading(true);
    LabGraphDETAILSUpperPortion()
      .then((res: any) => {
        console.log("UPPER PORTION", res);
        if (res?.data) {
          dispatch(
            setLabGraphDetails({
              comOrdersPercentageChange: res.data.comOrdersPercentageChange,
              completeTodayOrdersCount: res.data.completeTodayOrdersCount,
              newOrdersPercentageChange: res.data.newOrdersPercentageChange,
              pendingPercentageChange: res.data.pendingPercentageChange,
              pendingYesOrdersCount: res.data.pendingYesOrdersCount,
              todayOrdersCount: res.data.todayOrdersCount,
            })
          );
        }
      })
      .catch((err: any) => {
        console.log("API Error:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  interface WeekDataItem {
    date: string;
    ordersCount: number;
  }
  const GraphOnly = () => {
    setLoading(true);

    LabGRAPH()
      .then((res: any) => {
        console.log("GRAPH DATA", res);
        if (res?.status === 200) {
          const currentWeekData: WeekDataItem[] = res.data.currentWeekData;
          const previousWeekData = res.data.previousWeekData;
          // Use map to convert date to day in currentWeekData
          const dayArray = currentWeekData.map((item: WeekDataItem) => {
            const dateObject = new Date(item.date);
            const dayOfWeek = dateObject.toLocaleDateString("en-US", {
              weekday: "short",
            });
            return { day: dayOfWeek, ordersCount: item.ordersCount };
          });
          let popped = dayArray.pop();
          dispatch(setLabdayArray(dayArray));
          // Set values in the data array based on dayArray
          // dayArray.forEach(
          //   (item: { day: string; ordersCount: number }, index: number) => {
          //     data[index + 1].name = item.day;
          //     data[index + 1].value1 = item.ordersCount;
          //   }
          // );
          // console.log("Modified Data Array", data);

          const dayArray2 = previousWeekData.map((item: WeekDataItem) => {
            const dateObject = new Date(item.date);
            const dayOfWeek = dateObject.toLocaleDateString("en-US", {
              weekday: "short",
            });
            return { day: dayOfWeek, ordersCount: item.ordersCount };
          });
          dispatch(setLabdayArray2(dayArray2));
          // dayArray2.forEach(
          //   (item: { day: string; ordersCount: number }, index: number) => {
          //     data[index + 1].name = item.day;
          //     data[index + 1].value2 = item.ordersCount;
          //   }
          // );
        }
      })
      .catch((err: any) => {
        console.log("API Error:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // console.log(data);

  const Notification = () => {
    LabGetNotifications(lab_ID)
      .then((res: any) => {
        console.log("Lab Notification...........................", res);
        dispatch(setLabNotication(res.data.notifications));
      })
      .catch((err: any) => {
        console.log("API Error:", err);
      })
      .finally(() => {
        setLoading(false);
      });

    // console.log("API Notification");
  };

  // useEffect(() => {
  //   GraphOnly();
  //   GraphDetails();
  //   Notification();
  // }, []);
  useEffect(() => {
    if (renderLabdashboardFlag) {
      setLoading(true);
      GraphOnly();
      GraphDetails();
      Notification();
      dispatch(setrenderLabdashboardFlag(false));
    }
  }, [renderLabdashboardFlag]);
  return (
    <>
      {loading ? (
        <LoadingModal showModal={loading} />
      ) : (
        <div className={classNames(commonstyle.col12)}>
          <SearchBar />
          <div className={classNames(styles.outerContainer)}>
            <div className={classNames(commonstyle.mr87)}>
              <div className={classNames(style.mb24, style.flx_coloumn)}>
                <div className={commonstyle.flx}>
                  <p
                    className={classNames(
                      commonstyle.fs32,
                      commonstyle.semiBold,
                      style.mb4
                    )}
                  >
                    Hello {lab?.name}!
                  </p>
                  <div className={style.outerRefresh}>
                    <TbRefresh
                      className={styles.RefreshIcon}
                      onClick={() => {
                        GraphDetails();
                        GraphOnly();
                        Notification();
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p
                    className={classNames(
                      commonstyle.fs12,
                      commonstyle.semiBold,
                      style.gray
                    )}
                  >
                    This is what we've got for you today
                  </p>
                </div>
              </div>
              <div className={classNames(styles.flxp)}>
                <div
                  className={classNames(
                    styles.w20,
                    styles.colsm12,
                    styles.colmd6
                  )}
                >
                  <div className={classNames(styles.Maincards, styles.mtmd10)}>
                    <p
                      className={classNames(commonstyle.fs14, commonstyle.bold)}
                    >
                      New Test Order
                    </p>
                    <div
                      className={classNames(
                        commonstyle.flx,
                        styles.col12,
                        styles.pt20
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyle.fs32,
                            commonstyle.semiBold
                          )}
                        >
                          {labGraphDetails.todayOrdersCount
                            ? labGraphDetails.todayOrdersCount
                            : 0}
                        </p>
                      </div>

                      <div>
                        {Number(parsedNewOrdersPercentageChange) > 0 ? (
                          <div className={style.iconsOuterGreen}>
                            <FaArrowUp className={classNames(style.arrowUp)} />
                          </div>
                        ) : (
                          <div className={style.iconsOuterOrange}>
                            <FaArrowDown
                              className={classNames(style.arrowdown)}
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <p
                          className={classNames(
                            commonstyle.fs18,
                            commonstyle.semiBold,
                            labGraphDetails?.newOrdersPercentageChange < 0
                              ? style.colorOrange
                              : style.colorGreen
                          )}
                        >
                          {labGraphDetails.newOrdersPercentageChange}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.w20,
                    styles.colsm12,
                    styles.colmd6
                  )}
                >
                  <div className={classNames(styles.Maincards, styles.mrmd10)}>
                    <p
                      className={classNames(commonstyle.fs14, commonstyle.bold)}
                    >
                      Test Order Pending{" "}
                    </p>
                    <div
                      className={classNames(
                        commonstyle.flx,
                        styles.col12,
                        styles.pt20
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyle.fs32,
                            commonstyle.semiBold
                          )}
                        >
                          {labGraphDetails.pendingYesOrdersCount
                            ? labGraphDetails.pendingYesOrdersCount
                            : 0}
                        </p>
                      </div>
                      <div>
                        {Number(parsedPendingPercentageChange) > 0 ? (
                          <div className={style.iconsOuterGreen}>
                            <FaArrowUp className={classNames(style.arrowUp)} />
                          </div>
                        ) : (
                          <div className={style.iconsOuterOrange}>
                            <FaArrowDown
                              className={classNames(style.arrowdown)}
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <p
                          className={classNames(
                            commonstyle.fs18,
                            commonstyle.semiBold,
                            labGraphDetails?.pendingPercentageChange < 0
                              ? style.colorOrange
                              : style.colorGreen
                          )}
                        >
                          {labGraphDetails.pendingPercentageChange}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.w20,
                    styles.colsm12,
                    styles.colmd6
                  )}
                >
                  <div className={classNames(styles.Maincards, styles.mtmd10)}>
                    <p
                      className={classNames(commonstyle.fs14, commonstyle.bold)}
                    >
                      Test Complete
                    </p>
                    <div
                      className={classNames(
                        commonstyle.flx,
                        styles.col12,
                        styles.pt20
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyle.fs32,
                            commonstyle.semiBold
                          )}
                        >
                          {labGraphDetails.completeTodayOrdersCount
                            ? labGraphDetails.completeTodayOrdersCount
                            : 0}
                        </p>
                      </div>
                      <div>
                        {Number(parsedComingOrdersPercentageChange) > 0 ? (
                          <div className={style.iconsOuterGreen}>
                            <FaArrowUp className={classNames(style.arrowUp)} />
                          </div>
                        ) : (
                          <div className={style.iconsOuterOrange}>
                            <FaArrowDown
                              className={classNames(style.arrowdown)}
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <p
                          className={classNames(
                            commonstyle.fs18,
                            commonstyle.semiBold,
                            labGraphDetails?.comOrdersPercentageChange < 0
                              ? style.colorOrange
                              : style.colorGreen
                          )}
                        >
                          {labGraphDetails.comOrdersPercentageChange}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.w20,
                    styles.colsm12,
                    styles.colmd6
                  )}
                >
                  <div className={classNames(styles.Maincards, styles.mrmd10)}>
                    <p
                      className={classNames(commonstyle.fs14, commonstyle.bold)}
                    >
                      Test Result
                    </p>
                    <div
                      className={classNames(
                        commonstyle.flx,
                        styles.col12,
                        styles.pt20
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyle.fs32,
                            commonstyle.semiBold
                          )}
                        >
                          10
                        </p>
                      </div>
                      <div className={style.iconsOuterGreen}>
                        <FaArrowUp className={classNames(style.arrowUp)} />
                      </div>
                      <div>
                        <p
                          className={classNames(
                            commonstyle.fs18,
                            commonstyle.semiBold,
                            style.colorGreen
                          )}
                        >
                          +6.08%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.w20,
                    styles.colsm12,
                    styles.colmd6
                  )}
                >
                  <div className={classNames(styles.Maincards, styles.mtmd10)}>
                    <p
                      className={classNames(commonstyle.fs14, commonstyle.bold)}
                    >
                      Total Payment{" "}
                    </p>
                    <div
                      className={classNames(
                        commonstyle.flx,
                        styles.col12,
                        styles.pt20
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyle.fs32,
                            commonstyle.semiBold
                          )}
                        >
                          239K
                        </p>
                      </div>
                      <div className={style.iconsOuterOrange}>
                        <FaArrowUp className={classNames(style.arrowdown)} />
                      </div>
                      <div>
                        <p
                          className={classNames(
                            commonstyle.fs18,
                            commonstyle.semiBold,
                            style.colorOrange
                          )}
                        >
                          -6.08%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames(styles.flx)}>
                <div
                  className={classNames(
                    styles.col8,
                    styles.colsm12,
                    styles.colmd12
                  )}
                >
                  <div className={styles.graph}>
                    <LabLineChart data={data} />
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.col4,
                    styles.colsm12,
                    styles.colmd12
                  )}
                >
                  <div className={styles.graph3}>
                    <div
                      className={classNames(commonstyle.flx, commonstyle.fs24)}
                      style={{ textAlign: "center" }}
                    >
                      <p className={commonstyle.col7}>Total Accounts</p>
                      <p
                        className={commonstyle.col3}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        150
                      </p>
                      <div
                        className={commonstyle.col2}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <FaRegUser style={{ color: "#ff7617" }} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.graph2}>
                    <p
                      className={classNames(commonstyle.fs14, commonstyle.bold)}
                      style={{ paddingBottom: "35px" }}
                    >
                      Most Test by Location
                    </p>
                    <LabPieChart />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default memo(Dashboard);
