import React, { useState } from "react";
import classNames from "classnames";
import styles from "./laboratoryLogin.module.css";
import labMainStyles from "../laboratoryMain.module.css";
import commonStyles from "shared/utils/common.module.css";
import {
  CustomInput,
  PrimaryButton,
  SuccessModal,
  LoadingModal,
} from "shared/components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { labLoginSchema } from "shared/utils";
import { login } from "shared/services";
import { useDispatch, useSelector } from "react-redux";
import { setLab, setSystemType } from "shared/redux";
import { setIsLoggedIn, setToken } from "shared/redux/reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import PasswordInput from "shared/components/PasswordInput";
import { CheckBox } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import { FaChevronLeft } from "react-icons/fa";
import Logo from "assets/images/Lab 1.png";
const LaboratoryLogin = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { fcmToken } = useSelector((state: any) => state.root.common);
  const [loading, setLoading] = useState(false);
  const [notificationError] = useState("");
  const [error, setError] = React.useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGoToHome = () => {
    navigate("/joinVender");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object(labLoginSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = async () => {
  setLoading(true);
  setError(""); 

  try {
    // Check notification permission
    const permission = await checkNotificationPermission();
    if (permission === "denied") {
      // If permission is denied, alert the user and stop
      alert("To continue with the login process, please enable notifications.");
      setLoading(false);
      return;
    }

    // Proceed with the login process
    let params = {
      email: formik.values.email,
      password: formik.values.password,
      fcmToken: fcmToken,
    };
    console.log("pppp........", params);

    login(params)
      .then((res: any) => {
        if (res.status === 200 && res.statusText === "OK") {
          if (res?.data?.auth) {
            dispatch(setToken(res?.data?.token));
            dispatch(setIsLoggedIn(true));
            dispatch(setLab(res?.data?.lab));
            setShowSuccessModal(true);
            navigate("/laboratory/dashboard");
          }
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
        setError(err?.response?.data?.message || "An unexpected error occurred.");
      })
      .finally(() => {
        setLoading(false);
      });
  } catch (err: any) {
    console.error("Error during notification permission check:", err);
    setError("An error occurred while checking notification permissions.");
    setLoading(false);
  }
};

// Function to check notification permission
const checkNotificationPermission = () => {
  return new Promise<string>((resolve) => {
    if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        resolve(permission);
      });
    } else {
      resolve(Notification.permission);
    }
  });
};


  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const inputType = passwordVisible ? "text" : "password";
  return (
    <div className={classNames(commonStyles.container)}>
      <div className={classNames(labMainStyles.flx)}>
        <div
          className={classNames(
            commonStyles.col7,
            commonStyles.colmd7,
            commonStyles.colsm12
          )}
        >
          <div
            className={classNames(
              commonStyles.col8,
              commonStyles.colmd12,
              commonStyles.colsm12
            )}
          >
            <div
              className={classNames(commonStyles.flx, styles.cursor)}
              onClick={handleGoToHome}
            >
              <FaChevronLeft className={styles.BackIcon} />
              <p
                className={classNames(
                  commonStyles.colorBlue,
                  commonStyles.fs14,
                  commonStyles.semiBold
                )}
              >
                Back to Home
              </p>
            </div>
            <p
              className={classNames(
                commonStyles.fs40,
                styles.mt100,
                commonStyles.semiBold,
                commonStyles.colorBlue
              )}
            >
              Login
            </p>
            <p
              className={classNames(
                commonStyles.fs16,
                styles.mt16,
                commonStyles.semiBold,
                commonStyles.colorBlue
              )}
            >
              Login to access at Laboratory account
            </p>
             {error && (
              <div className={classNames(commonStyles.error)}>*{error}</div>
            )}
            {notificationError && (
              <div className={classNames(commonStyles.error)}>*{notificationError}</div>
            )}
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.mt50}>
                <CustomInput
                  placeholder="Enter Your Email"
                  id="email"
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.email}
                  </div>
                ) : null}
              </div>

              <div className={styles.mt24}>
                <PasswordInput
                  placeholder="Enter Your Password"
                  id="password"
                  name="password"
                  type={inputType}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.password}
                  </div>
                ) : null}
                <div
                  className={classNames(
                    styles.flxBetween,
                    commonStyles.mb40,
                    styles.mt8
                  )}
                >
                  <div className={classNames(commonStyles.flx)}>
                    <Checkbox className={styles.checkBoxx} />
                    <p
                      className={classNames(
                        commonStyles.colorBlue,
                        commonStyles.fs14
                      )}
                    >
                      Remember me
                    </p>
                  </div>
                  <a
                    className={classNames(
                      commonStyles.colorOrange,
                      commonStyles.fs14,
                      commonStyles.flxEnd,
                      styles.cursor
                    )}
                    onClick={() => navigate("/laboratory/forgot-password")}
                  >
                    Forgot Password
                  </a>
                </div>
                <div className={classNames(commonStyles.mb16)}>
                  <PrimaryButton
                    children={"LogIn"}
                    type="submit"
                    colorType={"Linear"}
                  />
                </div>

                <div>
                  <p
                    className={classNames(
                      commonStyles.regular,
                      styles.textCenter,
                      commonStyles.fs14
                    )}
                  >
                    If you haven't account{" "}
                    <a
                      className={classNames(
                        commonStyles.colorOrange,
                        styles.cursor
                      )}
                      onClick={() => navigate("/laboratory/signup")}
                    >
                      Sign Up
                    </a>{" "}
                  </p>
                </div>
              </div>
            </form>
            <SuccessModal
              showModal={showSuccessModal}
              successMessage={"LogIn Successful!"}
              hanldeCloseModal={handleCloseSuccessModal}
            />
            <LoadingModal
              showModal={loading}
              // hanldeCloseModal={handleCloseSuccessModal}
            />
          </div>
        </div>
        <div className={classNames(commonStyles.colsm12, commonStyles.col4)}>
          <img src={Logo} className={commonStyles.loginVector} />
        </div>
      </div>
    </div>
  );
};

export default LaboratoryLogin;
