import styles from "./ambulances.module.css";
import commomstyles from "shared/utils/common.module.css";
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import { addAmbulance } from "shared/services/Ambulance";
import dayjs from "dayjs";
import style from "./ambulances.module.css";
import commonstyle from "shared/utils/common.module.css";
import { ambulanceInfoSchema } from "shared/utils";
import commonStyles from "shared/utils/common.module.css";
import {
  setAmbulanceAmbulancerenderFlag,
  setAmbulanceLength,
  setAmbulances,
  setCurrentPage,
  setPageno,
  store,
} from "shared/redux";
import { ambulancePriceSchema } from "shared/utils";
import { getAllAmbulance } from "../../../../shared/services/Ambulance";
import VehicleList from "shared/components/AmbulanceTables/Vehicle List";
import { Typography } from "@mui/material";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { PrimaryButton } from "shared/components";
import CustomSelect from "shared/components/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ArrowBack,
  CustomInput,
  CustomModal,
  CustomStepper,
  Loader,
  LoadingModal,
} from "shared/components";
import Pagination from "shared/components/Pagination/Pagination";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { TbRefresh } from "react-icons/tb";
import CustomMultiSelect from "shared/components/CustomMultiSelect";
import Datepicker from "shared/components/DatePicker";

const Ambulancestypes = ["Car ", "Small SUV", "Truck style", "Van"];
const ambulanceFacilities = [
  "Air Conditioned",
  "Oxygen Gas",
  "Wheel Chair",
  "ICU facilities",
  "Emergency Kit",
  "Medical consumables",
];
const steps = [
  {
    id: "1",
    lable: " Ambulance Info",
  },
  {
    id: "2",
    lable: "Ambulance price",
  },
];

function Ambulances() {
  const {
    ambulanceAmbulancerenderFlag,
    ambulances,
    ambulanceLength,
    pageno,
    currentPage,
  } = useSelector((state: any) => state.root.ambulance);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [pageno, setPageno] = useState(1);
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const totalItems = ambulanceLength;
  const [rotation, setRotation] = useState(0);
  const rotationIncrement = 90;
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const handleOpenModal = () => {
    setShowAddModal(true);
  };
  const handleRotate = () => {
    fechAllAmbulances(currentPage);
    setRotation(rotation - rotationIncrement);
  };

  const fechAllAmbulances = (pageno: number) => {
    console.log("PAGE NO", pageno);
    setLoading(true);
    getAllAmbulance(pageno)
      .then((res: any) => {
        console.log("get All Ambulance Hit Response", res);
        if (res?.data?.auth) {
          dispatch(setAmbulances(res?.data?.ambulances));
          dispatch(setAmbulanceLength(res.data.totalAmbulance));
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
        // setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (ambulanceLength > itemTorender) {
      dispatch(setCurrentPage(currentPage + 1));
      // setCurrentPage(currentPage + 1);
      dispatch(setPageno(pageno + 10));
      // setPageno(pageno + 10);
      dispatch(setAmbulanceAmbulancerenderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
      // setCurrentPage(currentPage - 1);
      dispatch(setPageno(pageno - 10));
      // setPageno(pageno - 10);
      dispatch(setAmbulanceAmbulancerenderFlag(true));
    }
  };

  useEffect(() => {
    if (ambulanceAmbulancerenderFlag) {
      setLoading(true);
      fechAllAmbulances(currentPage);
      dispatch(setAmbulanceAmbulancerenderFlag(false));
    }
  }, [ambulanceAmbulancerenderFlag]);

  return (
    <div className={classNames(commonstyle.col12, style.outer)}>
      <SearchBar />

      <div className={commonstyle.mr87}>
        <div className={style.outerContainer}>
          <div className={classNames(commonstyle.flxBetween, style.mb24)}>
            <div className={commonstyle.flx}>
              <p
                className={classNames(
                  commonstyle.fs24,
                  commonstyle.semiBold,
                  commonstyle.colorBlue
                )}
              >
                Vehicle List
              </p>
              <div className={style.outerRefresh}>
                <BiSolidMessageSquareAdd
                  className={style.RefreshIcon}
                  onClick={handleOpenModal}
                />
              </div>
              <div className={style.outerRefresh}>
                <TbRefresh
                  className={styles.RefreshIcon}
                  style={{ transform: `rotate(${rotation}deg)` }}
                  onClick={() => {
                    handleRotate();
                  }}
                />
              </div>
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          {loading ? (
            // <Loader height={50} width={50} color="#001F57" />
            <LoadingModal
              showModal={loading}
              // hanldeCloseModal={handleCloseSuccessModal}
            />
          ) : (
            <VehicleList
              ambulance={ambulances}
              setShowModal={setShowAddModal}
            />
          )}
          <CustomModal
            showModal={showAddModal}
            children={
              <AddAmbulance
                setShowAddModal={setShowAddModal}
                ambulance={ambulances}
                fechAllAmbulances={fechAllAmbulances}
                // setAmbulance={setAmbulance}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}
export default Ambulances;

interface AmbProps {
  setShowAddModal: any;
  ambulance: any;
  fechAllAmbulances: any;

  // setAmbulance: any;
}
const AddAmbulance = (props: Partial<AmbProps>) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { setShowAddModal, ambulance, fechAllAmbulances } = props;
  const [screenName, setScreenName] = useState("AmbulanceInfo");
  const [selectedStep, setSelectedStep] = useState(0);
  const [addData, setAddData] = useState({});

  const handleClickNext = () => {
    if (screenName === "AmbulanceInfo") {
      setScreenName("Ambulanceprice");
    }

    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "Ambulanceprice") {
      setScreenName("AmbulanceInfo");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  return (
    <Typography
      id="modal-modal-description"
      sx={{ textAlign: "center", color: "#001F57" }}
    >
      <div className={commonstyle.flx}>
        {/* <div className={commonstyle.flx} style={{ width: "97%" }}>
          <ArrowBack onClick={handleClicKPrev} />
          <p className={classNames(commonstyle.semiBold, commonstyle.fs16)}>
            Previous
          </p>
        </div> */}
        <div className={styles.end}>
          <button className={styles.close} onClick={handleCloseModal}>
            &#10006;
          </button>
        </div>
      </div>

      <div style={{ marginTop: "30px" }}>
        <CustomStepper steps={steps} selectedStep={selectedStep} />
      </div>
      <div>
        {screenName == "AmbulanceInfo" && (
          <AmbulanceInfo
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            handleClickNext={handleClickNext}
            setAddData={setAddData}
          />
        )}
        {screenName == "Ambulanceprice" && (
          <Ambulanceprice
            selectedOptions={selectedOptions}
            handleClickNext={handleClickNext}
            addData={addData}
            setAddData={setAddData}
            setShowAddModal={setShowAddModal}
            ambulance={ambulance}
            fechAllAmbulances={fechAllAmbulances}
          />
        )}
      </div>
      <div
        style={{ width: "210px", marginTop: "56px" }}
        className={styles.start}
      >
        {/* <button
          onClick={handleClickNext}
          style={{ fontSize: "16px", fontWeight: "600" }}
          className={styles.btnx}
        >
          Next
        </button> */}
      </div>
    </Typography>
  );
};

interface Props {
  handleClickNext: any;
  setAddData: any;
  addData: any;
  setShowAddModal: any;
  setSelectedOptions: any;
  selectedOptions: any;
}
const AmbulanceInfo = (props: Partial<Props>) => {
  const { handleClickNext, setAddData, setSelectedOptions, selectedOptions } =
    props;
  const selectedOptionsString = selectedOptions.join(", ");
  const [error, setError] = React.useState("");
  const formik = useFormik({
    initialValues: {
      vehicleType: "",
      vehicleName: "",
      vehicleModel: "",
      vehicleYear: "",
      vehicleColor: "",
      vehicleRegistrationNumber: "",
      vehicleRegistrationDate: "",
    },
    validationSchema: Yup.object(ambulanceInfoSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("vehicleType", selectedOption);
  };
  const handleRegistrationExpiry = (date: any) => {
    const selectedDate = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    console.log("Registration Date", formattedDate);
    formik.setFieldValue("vehicleRegistrationDate", formattedDate);
  };

  const handleSelectQualification = (values: string[]) => {
    if (values.length > 0) {
      // console.log(`Selected: ${values}`);
    } else {
      // console.log(`Unselected: ${selectedOptions}`);
    }
    setSelectedOptions(values);
  };
  const handleSubmit = async () => {
    if (selectedOptions.length === 0) {
      setError("Required field");
    } else {
      console.log("Submit");
      const currentData = formik.values;
      handleClickNext();
      setAddData({
        vehicleType: currentData.vehicleType,
        vehicleName: currentData.vehicleName,
        vehicleModel: currentData.vehicleModel,
        vehicleYear: currentData.vehicleYear,
        vehicleColor: currentData.vehicleColor,
        registrationNo: currentData.vehicleRegistrationNumber,
        registrationDate: currentData.vehicleRegistrationDate,
      });
    }
  };
  // useEffect(() => {
  //   console.log("SELECTED FINAL", selectedOptions);
  // }, [selectedOptions]);
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ marginTop: "24px" }} className={commonstyle.flx}>
          <div style={{ width: "210px" }}>
            <CustomSelect
              options={Ambulancestypes}
              placeholder="Vehicle Type"
              onSelect={handleSelect}
            />

            {formik.touched.vehicleType && formik.errors.vehicleType ? (
              <div className={classNames(commonstyle.error)}>
                *{formik.errors.vehicleType}
              </div>
            ) : null}
          </div>
          <div style={{ width: "210px", marginLeft: "86px" }}>
            <CustomInput
              placeholder="Vehicle Name"
              id="vehicleName"
              name="vehicleName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleName}
            />

            {formik.touched.vehicleName && formik.errors.vehicleName ? (
              <div className={classNames(commonstyle.error)}>
                *{formik.errors.vehicleName}
              </div>
            ) : null}
          </div>
        </div>

        <div style={{ marginTop: "24px" }} className={commonstyle.flx}>
          <div style={{ width: "210px" }}>
            <CustomInput
              placeholder="Vehicle Model"
              id="vehicleModel"
              name="vehicleModel"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleModel}
            />

            {formik.touched.vehicleModel && formik.errors.vehicleModel ? (
              <div className={classNames(commonstyle.error)}>
                *{formik.errors.vehicleModel}
              </div>
            ) : null}
          </div>
          <div style={{ width: "210px", marginLeft: "86px" }}>
            <CustomInput
              placeholder="Vehicle Year"
              id="vehicleYear"
              name="vehicleYear"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleYear}
            />

            {formik.touched.vehicleYear && formik.errors.vehicleYear ? (
              <div className={classNames(commonstyle.error)}>
                *{formik.errors.vehicleYear}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ marginTop: "24px" }} className={commonstyle.flx}>
          <div style={{ width: "210px" }}>
            <CustomInput
              placeholder="Vehicle Color"
              id="vehicleColor"
              name="vehicleColor"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleColor}
            />

            {formik.touched.vehicleColor && formik.errors.vehicleColor ? (
              <div className={classNames(commonstyle.error)}>
                *{formik.errors.vehicleColor}
              </div>
            ) : null}
          </div>
          <div style={{ width: "210px", marginLeft: "86px" }}>
            <CustomMultiSelect
              placeholder="Vehicle Facilities for patient"
              options={ambulanceFacilities}
              onSelect={(value) => handleSelectQualification(value)}
              selectedOptions={selectedOptions}
            />
            {error && (
              <div className={classNames(commonStyles.error)}>*{error}</div>
            )}
          </div>
        </div>

        <div style={{ marginTop: "24px" }} className={commonstyle.flx}>
          <div style={{ width: "210px" }}>
            <CustomInput
              placeholder="Registration Number"
              id="vehicleRegistrationNumber"
              name="vehicleRegistrationNumber"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleRegistrationNumber}
            />

            {formik.touched.vehicleRegistrationNumber &&
            formik.errors.vehicleRegistrationNumber ? (
              <div className={classNames(commonstyle.error)}>
                *{formik.errors.vehicleRegistrationNumber}
              </div>
            ) : null}
          </div>
          <div style={{ width: "210px", marginLeft: "86px" }}>
            <Datepicker
              placeholder="Registration Date"
              setData={handleRegistrationExpiry}
              futureDisable={true}
            />

            {formik.touched.vehicleRegistrationDate &&
            formik.errors.vehicleRegistrationDate ? (
              <div className={classNames(commonstyle.error)}>
                *{formik.errors.vehicleRegistrationDate}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ width: "210px", marginTop: "56px" }}>
          <PrimaryButton
            children={"Next Step"}
            type="submit"
            colorType={"blue"}
          />
        </div>
      </form>
    </div>
  );
};

interface Props {
  handleClickNext: any;
  addData: any;
  setShowAddModal: any;
  ambulance: any;
  setAmbulance: any;
  fechAllAmbulances: any;
}
const Ambulanceprice = (props: Partial<Props>) => {
  const {
    handleClickNext,
    addData,
    setShowAddModal,
    ambulance,
    setAmbulance,
    fechAllAmbulances,
    selectedOptions,
  } = props;
  console.log("AAAAAAAADDDDDDDD DATA", selectedOptions);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      actualPrice: "",
      priceForMeditour: "",
    },
    validationSchema: Yup.object(ambulancePriceSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = async () => {
    console.log("Submit");
    const curr_data = formik.values;
    handleClickNext();
    setLoading(true);

    let tempAmb = [...ambulance];

    let params = {
      ...addData,
      vehicleFacilities: selectedOptions,
      actualPrice: curr_data.actualPrice,
      priceForMeditour: curr_data.priceForMeditour,
    };

    console.log("PARAMS", params);
    addAmbulance(params)
      .then((res: any) => {
        handleClickNext();
        if (res.data.auth) {
          console.log("response.....", res.data);
          // tempAmb.push(res?.data?.Ambulance);
          // setAmbulance(tempAmb);
          setShowAddModal(false);
          fechAllAmbulances();
          // handleClickNext();
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ width: "528px" }}>
          <div style={{ marginTop: "24px" }} className={commonstyle.flx}>
            <div style={{ marginRight: "12px" }} className={commonstyle.col6}>
              <CustomInput
                placeholder="Actual Price"
                id="actualPrice"
                name="actualPrice"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.actualPrice}
              />

              {formik.touched.actualPrice && formik.errors.actualPrice ? (
                <div className={classNames(commonstyle.error)}>
                  *{formik.errors.actualPrice}
                </div>
              ) : null}
            </div>
            <div style={{ marginLeft: "12px" }} className={commonstyle.col6}>
              <CustomInput
                placeholder="Price For MediTour"
                id="priceForMeditour"
                name="priceForMeditour"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.priceForMeditour}
              />

              {formik.touched.priceForMeditour &&
              formik.errors.priceForMeditour ? (
                <div className={classNames(commonstyle.error)}>
                  *{formik.errors.priceForMeditour}
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ width: "110px", marginTop: "56px" }}>
            <PrimaryButton
              children={loading ? "loading..." : "Save"}
              disabled={loading ? true : false}
              type="submit"
              colorType={"green"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
