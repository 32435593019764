import React from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./docterPayment.module.css";
import { Vendor_PaymentDetails } from "shared/components";
const DoctorPaymentDetails = () => {
  return (
    <div className={classNames(commonstyles.col12, styles.outer)}>
      <SearchBar />
      <Vendor_PaymentDetails />
    </div>
  );
};

export default DoctorPaymentDetails;
