import React, { useState } from "react";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import Style from "./payment.module.css";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Payment from "assets/images/PaymentSS.png";

const Vendor_PaymentDetails = () => {
  const { state } = useLocation();
  console.log("Sss.....", state);
  let date = moment(state?.createdAt).format("MM-DD-YYYY h:mm a");

  return (
    <div className={classNames(commonstyles.col12)}>
      <div className={commonstyles.mr87}>
        <div className={classNames(commonstyles.flxBetween, commonstyles.mb32)}>
          <p className={classNames(commonstyles.fs24)}>Payment Details</p>
          <p className={classNames(commonstyles.fs18, Style.colorGreen)}>
            Payment ID: {state?.paymentId}
          </p>
        </div>

        <div className={Style.outerContainer}>
          <div className={classNames(commonstyles.flxBetween)}>
            <div
              className={classNames(commonstyles.flxBetween, commonstyles.mb24)}
              style={{ width: "450px" }}
            >
              <p className={classNames(commonstyles.fs14, commonstyles.medium)}>
                Payment At
              </p>
              <p
                className={classNames(commonstyles.fs14, commonstyles.semiBold)}
                style={{ marginLeft: "100px" }}
              >
                {date}
              </p>
            </div>
            <p
              className={classNames(
                commonstyles.fs14,
                commonstyles.medium,
                commonstyles.clickable
              )}
            >
              Download Receipt
            </p>
          </div>

          <div
            className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
            style={{ width: "450px" }}
          >
            <p className={classNames(commonstyles.fs14, commonstyles.medium)}>
              Total Orders
            </p>
            <p
              className={classNames(commonstyles.fs14, commonstyles.medium)}
              style={{ marginLeft: "100px" }}
            >
              {state?.noOfitems}
            </p>
          </div>

          <div
            className={classNames(commonstyles.flxBetween, commonstyles.mb8)}
            style={{ width: "450px" }}
          >
            <p className={classNames(commonstyles.fs14, commonstyles.medium)}>
              Total Amount
            </p>
            <p
              className={classNames(commonstyles.fs14, commonstyles.medium)}
              style={{ marginLeft: "100px" }}
            >
              {state?.totalAmount}
            </p>
          </div>

          <div
            className={classNames(commonstyles.flxBetween, commonstyles.mb24)}
            style={{ width: "450px" }}
          >
            <p className={classNames(commonstyles.fs14, commonstyles.medium)}>
              Tax.
            </p>
            <p
              className={classNames(commonstyles.fs14, commonstyles.medium)}
              style={{ marginLeft: "100px" }}
            >
              {state?.totalTax}
            </p>
          </div>

          <div
            className={classNames(commonstyles.flxBetween)}
            style={{ width: "450px" }}
          >
            <p className={classNames(commonstyles.fs14, commonstyles.bold)}>
              Paid Amount
            </p>
            <p
              className={classNames(commonstyles.fs14, commonstyles.bold)}
              style={{ marginLeft: "100px" }}
            >
              {state?.payableAmount}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendor_PaymentDetails;
