import React, { useEffect, useState } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import style from "./single.module.css";
import { IoArrowBack, IoClose } from "react-icons/io5";
import User from "assets/images/Userr2.jpeg";
import { CustomModal, PrimaryButton } from "shared/components";
import { Avatar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { insuranceGETNDIVIDUALTRAVEL } from "shared/services/Insurance";
import { setTravelsingleInsurance } from "shared/redux";
import { useDispatch, useSelector } from "react-redux";
export default function SingleMain() {
  const { travelsingleInsurance } = useSelector(
    (state: any) => state.root.insurance
  );

  console.log("travelsingleInsurance............", travelsingleInsurance);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { value } = useParams();

  const handleGoAddData = () => {
    navigate(`/insurance/TravelIndividual/${value}`);
  };
  const handleGoToMyselfDetail = () => {
    navigate("/insurance/MySelf/Detail");
  };

  const handleback = () => {
    navigate("/insurance/Travel");
  };
  const fetchAllSingleTravel = () => {
    insuranceGETNDIVIDUALTRAVEL()
      .then((res: any) => {
        console.log("response of get travel.....", res.data);
        dispatch(setTravelsingleInsurance(res?.data?.insurances));
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAllSingleTravel();
  }, []);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={commonstyles.outerContainer}>
          <div className={commonstyles.flx}>
            <div className={commonstyles.flx}>
              <IoArrowBack className={style.back} onClick={handleback} />
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.semiBold,
                  commonstyles.colorBlue,
                  style.mr56
                )}
              >
                Back{" "}
              </p>
            </div>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Travel
            </p>
            <div className={style.outerRefresh} onClick={handleGoAddData}>
              <BiSolidMessageSquareAdd className={style.RefreshIcon} />
            </div>
          </div>

          <div className={style.flxWrap}>
            {travelsingleInsurance.map((item: any) => (
              <div className={style.myselfIncCard}>
                <Avatar src={item?.packageLogo} className={style.profile} />
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold
                  )}
                >
                  {item?.packageName}
                </p>
                <p className={classNames(commonstyles.fs15, style.mt16)}>
                  {item?.packageDescription}
                </p>
                <p
                  className={classNames(
                    commonstyles.fs15,
                    style.mt8,
                    commonstyles.semiBold
                  )}
                >
                  75k - 2Lac
                </p>
                <p
                  className={classNames(
                    commonstyles.fs15,
                    style.mt16,
                    commonstyles.semiBold
                  )}
                >
                  {item?.tripType}
                </p>
                <p
                  className={classNames(
                    commonstyles.fs15,
                    style.mt8,
                    commonstyles.semiBold
                  )}
                >
                  80%
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
