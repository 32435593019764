import React, { useState, useEffect } from "react";
import classNames from "classnames";
import phrMainStyles from "../../../Pharmacy/pharmacyMain.module.css";
import commonStyles from "shared/utils/common.module.css";
import { useFormik } from "formik";
import { pharmaceuticalInfoSchema, pharmacyInfoSchema } from "shared/utils";
import * as Yup from "yup";
import { PrimaryButton, RingLoader } from "shared/components";
import { CustomInput } from "shared/components";
import ImgPicker from "shared/components/Img-picker";
import { useDispatch, useSelector } from "react-redux";
import { set_User_ID, setPharmacyUserFormData } from "shared/redux";
import Datepicker from "shared/components/DatePicker";
import LocationInput from "shared/components/LocationInput";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";
import dayjs from "dayjs";
import { pharmaceutical_Signup } from "shared/services";

interface Props {
  handleClickNext: any;
}
const Pharmaceutical_BasicInfo = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const [logoError, setLogoError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { fcmToken } = useSelector((state: any) => state.root.common);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      logo: "",
      ownerFirstName: "", ////////
      ownerLastName: "", ////////
      emergencyNumber: "", ////////
      address: "", ////////
      lat: "", ////////
      lng: "", ////////
      city: "", ////////
    },
    validationSchema: Yup.object(pharmaceuticalInfoSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handlePharmacyLogoUrl = (url: any) => {
    console.log("lll....", url);
    formik.setFieldValue("logo", url);
  };

  const handleSetAddressData = (value: any) => {
    formik.setFieldValue("address", value.label);
    formik.setFieldValue("lat", value.lat);
    formik.setFieldValue("lng", value.lng);
    formik.setFieldValue("city", value.city);
  };
  const handleSubmit = async () => {
    let currentValues = formik.values;
    let params = {
      name: currentValues.name,
      logo: currentValues.logo,
      firstName: currentValues.ownerFirstName,
      lastName: currentValues.ownerLastName,
      emergencyNo: currentValues.emergencyNumber,
      location: {
        lng: currentValues.lng,
        lat: currentValues.lat,
        address: currentValues.address,
        city: currentValues.city,
      },
      fcmToken: fcmToken,
    };
    setLoading(true);
    pharmaceutical_Signup(params)
      .then((res: any) => {
        console.log("ress....", res?.data);
        dispatch(set_User_ID(res?.data?.pharmacuetical._id));
        handleClickNext();
      })
      .catch((err: any) => {
        console.log("error...", err?.response?.data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.col12)}>
          <div className={classNames(commonStyles.mb16, phrMainStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col8,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="Name"
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />

                {formik.touched.name && formik.errors.name ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.name}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={commonStyles.mr32}>
                <ImgPicker placeholder="Logo" setData={handlePharmacyLogoUrl} />
                {formik.touched.logo && formik.errors.logo ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.logo}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className={classNames(commonStyles.mb16, phrMainStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Pharmacy License Number"
                  id="pharmacyLicenseNumber"
                  name="pharmacyLicenseNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.pharmacyLicenseNumber}
                />
                {formik.touched.pharmacyLicenseNumber &&
                formik.errors.pharmacyLicenseNumber ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyLicenseNumber}
                  </div>
                ) : null}
              </div>
            </div>

            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <ImgPicker
                  placeholder="License Image"
                  setData={handlePharmacyLicenseUrl}
                />
                {formik.touched.pharmacyLicenseImg &&
                formik.errors.pharmacyLicenseImg ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyLicenseImg}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={commonStyles.mr32}>
                <Datepicker
                  placeholder="License Expiry "
                  setData={handleLicenseExpiry}
                />
                {formik.touched.pharmacyLicenseExpiry &&
                formik.errors.pharmacyLicenseExpiry ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyLicenseExpiry}
                  </div>
                ) : null}
              </div>
            </div>
          </div> */}

          <div className={classNames(commonStyles.mb16, phrMainStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Owner First Name"
                  id="ownerFirstName"
                  name="ownerFirstName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.ownerFirstName}
                />
                {formik.touched.ownerFirstName &&
                formik.errors.ownerFirstName ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.ownerFirstName}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Owner Last Name"
                  id="ownerLastName"
                  name="ownerLastName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.ownerLastName}
                />
                {formik.touched.ownerLastName && formik.errors.ownerLastName ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.ownerLastName}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="Emergency No."
                  id="emergencyNumber"
                  name="emergencyNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.emergencyNumber}
                />
                {formik.touched.emergencyNumber &&
                formik.errors.emergencyNumber ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.emergencyNumber}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className={classNames(commonStyles.mb16, phrMainStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="CNIC / Passport Number"
                  id="pharmacyCnicNumber"
                  name="pharmacyCnicNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.pharmacyCnicNumber}
                />
                {formik.touched.pharmacyCnicNumber &&
                formik.errors.pharmacyCnicNumber ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyCnicNumber}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <ImgPicker
                  placeholder="CNIC / Passport Image "
                  setData={handlePharmacyCnicUrl}
                />

                {formik.touched.pharmacyCnicImg &&
                formik.errors.pharmacyCnicImg ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyCnicImg}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <Datepicker
                  placeholder="CNIC / Passport Expiry "
                  setData={handleCnicExpiry}
                />
                {formik.touched.pharmacyCnicExpiry &&
                formik.errors.pharmacyCnicExpiry ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyCnicExpiry}
                  </div>
                ) : null}
              </div>
            </div>
          </div> */}

          <div
            className={classNames(
              commonStyles.mb16,
              phrMainStyles.flx,
              commonStyles.col12
            )}
          >
            <div
              className={classNames(
                commonStyles.col12,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={classNames(commonStyles.mr32)}>
                <LocationInput
                  placeholder="Address"
                  setData={handleSetAddressData}
               
                  // setLoc={handleSetLatLong}
                />
                {formik.touched.address && formik.errors.address ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.address}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div
            className={classNames(
              commonStyles.flx,
              commonStyles.mb16,
              commonStyles.col12
            )}
          >
            <div
              className={classNames(
                commonStyles.col12,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={classNames(commonStyles.mr32)}>
                <CustomInput
                  placeholder="Description"
                  id="pharmacyDescription"
                  name="pharmacyDescription"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.pharmacyDescription}
                />
                {formik.touched.pharmacyDescription &&
                formik.errors.pharmacyDescription ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyDescription}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.mb16,
              phrMainStyles.flx,
              commonStyles.col12
            )}
          >
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={classNames(commonStyles.mr32)}>
                <CustomTimePicker
                  placeholder="Pharmacy Open Time"
                  setData={handlePharmacyOpenTime}
                  value={formik.values.pharamcyOpenTime}
                />
                {formik.touched.pharamcyOpenTime &&
                formik.errors.pharamcyOpenTime ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharamcyOpenTime}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={classNames(commonStyles.mr32)}>
                <CustomTimePicker
                  placeholder="Pharmacy Close Time"
                  setData={handlePharmacyCloseTime}
                  value={formik.values.pharmacyCloseTime}
                />
                {formik.touched.pharmacyCloseTime &&
                formik.errors.pharmacyCloseTime ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyCloseTime}
                  </div>
                ) : null}
              </div>
            </div>
          </div> */}
        </div>

        <div className={phrMainStyles.buttonWidth}>
          <PrimaryButton
            children={
              loading ? <RingLoader size={35} color={"#fff"} /> : "Next"
            }
            type="submit"
            colorType={"SkyBlueOutine"}
          />
        </div>
      </form>
    </div>
  );
};

export default Pharmaceutical_BasicInfo;
