import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "./payment.module.css";
import commonstyles from "shared/utils/common.module.css";
import TablePay from "shared/components/Tables/tablePay";
import { getPaymentComplete } from "shared/services";
import { useSelector } from "react-redux";
import RingLoader from "../RingLoader";
import { TbRefresh } from "react-icons/tb";
import NewPagination from "../NewPagination/NewPagination";
import { useNavigate } from "react-router-dom";

interface Props {
  type: string;
  id?: string;
}
const Vendor_Payments = (props: Props) => {
  const { type, id } = props;
  const { systemType } = useSelector((state: any) => state.root.common);
  console.log("tt.......", systemType);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [length, setLength] = useState(0);
  const navigate = useNavigate();

  const itemsPerPage = 10;
  const totalItems = length;
  useEffect(() => {
    fetchPayments(1);
  }, []);

  const fetchPayments = (pageno: number) => {
    getPaymentComplete(type, "", id, pageno)
      .then((res: any) => {
        console.log("ress.....", res?.data);
        setPayments(res?.data?.payments);
        setLength(res?.data?.paymentsLength);
      })
      .catch((err: any) => {
        console.log("error....", err?.response?.data);
      })
      .finally(() => setLoading(false));
  };

  const handleRefresh = () => {
    setLoading(true);
    fetchPayments(1);
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (length > itemTorender) {
      setCurrentPage(currentPage + 1);
      fetchPayments(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fetchPayments(currentPage - 1);
    }
  };

  const onPressItem = (item: any) => {
    navigate(`/${systemType}/paymentDetails`, {
      state: item,
    });
  };
  return (
    <div className={commonstyles.mr87}>
      <div
        className={classNames(commonstyles.flxBetween)}
        style={{ marginBottom: "24px" }}
      >
        <div className={classNames(commonstyles.flx, commonstyles.colsm12)}>
          <p className={classNames(commonstyles.fs22, commonstyles.semiBold)}>
            Received Payments
          </p>
          {loading ? (
            <div className={style.loader}>
              <RingLoader color={"#0D47A1"} size={30} />
            </div>
          ) : (
            <TbRefresh
              className={commonstyles.refresh}
              onClick={handleRefresh}
            />
          )}
        </div>
        <div className={classNames(commonstyles.flx)}>
          <NewPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            startItem={(currentPage - 1) * itemsPerPage + 1}
            endItem={Math.min(currentPage * itemsPerPage, length)}
            totalItems={totalItems}
          />
        </div>
      </div>

      <div className={style.tabless}>
        <TablePay data={payments} onPressItem={onPressItem} />
      </div>
    </div>
  );
};

export default Vendor_Payments;
