import React from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import style from "./travelflow.module.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import Single from "assets/images/individual.png";
import Family from "assets/images/travelFamily.png";
export default function TravelflowMain() {
  const navigate = useNavigate(); // Declare once
  const { value } = useParams();

  console.log("................value", value);
  const handleGoBack = () => {
    navigate("/insurance/Travel");
  };
  const handleGoIndividual = () => {
    navigate(`/insurance/Travel/Single/${value}`);
  };
  const handleGoFamily = () => {
    navigate(`/insurance/Travel/Family/${value}`);
  };
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.outerContainer}>
        <div className={commonstyles.flx}>
          <div className={commonstyles.flx}>
            <IoArrowBack className={style.back} onClick={handleGoBack} />
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.semiBold,
                commonstyles.colorBlue,
                style.mr56
              )}
            >
              Back{" "}
            </p>
          </div>
          <p
            className={classNames(
              commonstyles.fs20,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Travel
          </p>
        </div>
        <div className={commonstyles.flx}>
          <div className={style.travelcards} onClick={handleGoIndividual}>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.bold,
                commonstyles.colorBlue
              )}
            >
              Individual
            </p>
            <p className={classNames(commonstyles.fs16, style.mt16)}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
            <img src={Single} className={style.SingleTravel} />
          </div>
          <div className={style.travelcards} onClick={handleGoFamily}>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.bold,
                commonstyles.colorBlue
              )}
            >
              Family
            </p>
            <p className={classNames(commonstyles.fs16, style.mt16)}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
            <img src={Family} className={style.familyTravel} />
          </div>
        </div>
      </div>
    </div>
  );
}
