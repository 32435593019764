import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  travelAgencyCompleteSignup,
  travelAgencySendCodeToEmail,
  travelAgencyConfirmEmail,
} from "shared/services/TravelAgency";
import { travelAgencyVerifySchema } from "shared/utils";
import MainTravelStyle from "../../../mainTravel.module.css";
import commonStyles from "shared/utils/common.module.css";
import Check from "assets/check.png";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import {
  CustomInput,
  PrimaryButton,
  SuccessModal,
  VerifyModal,
} from "shared/components";
import CustomSelect from "shared/components/CustomSelect";
import PasswordInput from "shared/components/PasswordInput";
const countryCodes = [
  "+92",
  "+1", // United States
  "+1", // Canada
  "+44", // United Kingdom
  "+49", // Germany
  "+33", // France
  "+39", // Italy
  "+61", // Australia
  "+55", // Brazil
  "+91", // India
  "+81", // Japan
  "+86", // China
  "+82", // South Korea
  "+52", // Mexico
  "+7", // Russia
  "+27", // South Africa
  "+234", // Nigeria
  "+54", // Argentina
  "+34", // Spain
  "+90", // Turkey
  "+966", // Saudi Arabia
  // ... add more country codes as needed
];
interface Props {
  handleClickNext: any;
}
const TravelAgencyVerificatio = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [verifyError, setVerifyError] = React.useState("");
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [istravelagencyEmailVerified, setTravelAgencyIsEmailVerified] =
    useState(false);
  const dispatch = useDispatch();
  const { travelagencyuser_id } = useSelector(
    (state: any) => state.root.travelagency
  );
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      travelAgencyPhoneNo: "",
      travelAgencyEmail: "",
      travelAgencyPassword: "",
      travelAgencyConfirmPassword: "",
    },
    validationSchema: Yup.object(travelAgencyVerifySchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async () => {
    let values = formik.values;
    let params = {
      phoneNumber: values.travelAgencyPhoneNo,
      email: values.travelAgencyEmail,
      password: values.travelAgencyPassword,
      confirmPassword: values.travelAgencyConfirmPassword,
    };

    console.log("id....", travelagencyuser_id);

    if (istravelagencyEmailVerified) {
      travelAgencyCompleteSignup(params, travelagencyuser_id)
        .then((res: any) => {
          console.log("pas....", res);
          if (res.status == "200" && res.statusText == "OK") {
            setShowSuccessModal(true);
            setTravelAgencyIsEmailVerified(false);
          }
        })
        .catch((err: any) => {
          console.log("send error.nnnnn..", err?.response?.data);
          setTravelAgencyIsEmailVerified(false);
        })
        .finally(() => {
          setVerifyLoading(false);
        });
    } else {
      setVerifyError("Please verify email and phone number first.");
    }
  };
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState("");
  const [repassword, setRePassword] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const SendCodeToEmail = () => {
    let params = {
      email: formik.values.travelAgencyEmail,
    };
    setVerifyLoading(true);
    travelAgencySendCodeToEmail(params)
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          setMinutes(1);
          setSeconds(59);
          setShowVerifyModal(true);
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
        setVerifyError(err.response.data.message);
      })
      .finally(() => {
        setVerifyLoading(false);
      });
  };

  const handleSubmitCode = () => {
    let params = {
      email: formik.values.travelAgencyEmail,
      code: code,
    };

    if (code.length == 6) {
      travelAgencyConfirmEmail(params)
        .then(async (res: any) => {
          if (res.data.status) {
            setTravelAgencyIsEmailVerified(true);
            setSuccessMessage(res.data.message);
            setVerifyError("");
          }
        })
        .catch((err: any) => {
          setCodeError(err.response.data.message);
        })
        .finally(() => {
          setVerifyLoading(false);
        });
    }
  };

  const handleCloseModal = () => {
    setShowVerifyModal(false);
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/");
  };
  const inputType = passwordVisible ? "text" : "password";
  const inputPassword = showPassword ? "text" : "password";
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.col8, commonStyles.colsm12)}>
          <div className={classNames(commonStyles.mb16, commonStyles.flx)}>
            <div className={classNames(commonStyles.col2)}>
              <div>
                <CustomSelect
                  options={countryCodes}
                  placeholder="+92"
                  onSelect={handleSelect}
                />
              </div>
            </div>
            <div className={classNames(commonStyles.col10)}>
              <CustomInput
                placeholder="Phone Number"
                id="travelAgencyPhoneNo"
                name="travelAgencyPhoneNo"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.travelAgencyPhoneNo}
              />

              {formik.touched.travelAgencyPhoneNo &&
              formik.errors.travelAgencyPhoneNo ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.travelAgencyPhoneNo}
                </div>
              ) : null}
            </div>
            <div>
              <button
                disabled={
                  formik.touched.travelAgencyPhoneNo &&
                  !formik.errors.travelAgencyPhoneNo
                    ? false
                    : true
                }
                type="button"
                className={
                  formik.touched.travelAgencyPhoneNo &&
                  !formik.errors.travelAgencyPhoneNo
                    ? commonStyles.VerifyActive
                    : commonStyles.Verify
                }
              >
                Verify
              </button>
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, commonStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col12,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <CustomInput
                placeholder="Email Address"
                id="travelAgencyEmail"
                name="travelAgencyEmail"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.travelAgencyEmail}
              />

              {formik.touched.travelAgencyEmail &&
              formik.errors.travelAgencyEmail ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.travelAgencyEmail}
                </div>
              ) : null}
            </div>
            <div className={commonStyles.mr32}>
              {istravelagencyEmailVerified ? (
                <button
                  type="button"
                  disabled={true}
                  className={commonStyles.Verified}
                  // onClick={handleSendCodeToEmail}
                >
                  Verified
                </button>
              ) : (
                <button
                  type="button"
                  disabled={
                    !verifyLoading &&
                    formik.touched.travelAgencyEmail &&
                    !formik.errors.travelAgencyEmail
                      ? false
                      : true
                  }
                  className={
                    formik.touched.travelAgencyEmail &&
                    !formik.errors.travelAgencyEmail
                      ? commonStyles.VerifyActive
                      : commonStyles.Verify
                  }
                  onClick={SendCodeToEmail}
                >
                  {verifyLoading ? "loading..." : "Verify"}
                </button>
              )}
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, commonStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col12,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <PasswordInput
                type={inputType}
                placeholder="Password"
                id="travelAgencyPassword"
                name="travelAgencyPassword"
                onChange={formik.handleChange}
                value={formik.values.travelAgencyPassword}
              />

              {formik.touched.travelAgencyPassword &&
              formik.errors.travelAgencyPassword ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.travelAgencyPassword}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, commonStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col12,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <PasswordInput
                type={inputPassword}
                placeholder="Re-Password"
                id="travelAgencyConfirmPassword"
                name="travelAgencyConfirmPassword"
                onChange={formik.handleChange}
                value={formik.values.travelAgencyConfirmPassword}
              />

              {formik.touched.travelAgencyConfirmPassword &&
              formik.errors.travelAgencyConfirmPassword ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.travelAgencyConfirmPassword}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        
        <div className={classNames(commonStyles.col12)}>
          <div className={classNames(commonStyles.flx)}>
            <Checkbox
              size="small"
              style={{
                color: "#ff7631",
                marginLeft: -10,
              }}
            />

            <div>
              <p
                className={classNames(commonStyles.regular, commonStyles.fs10)}
              >
                I agree meditour{" "}
                <a
                  className={classNames(commonStyles.colorOrange)}
                  href="/terms-conditions"
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  className={classNames(commonStyles.colorOrange)}
                  href="/privacy-policy"
                >
                  Privacy policy
                </a>
              </p>
            </div>
          </div>

          {/* <div className={classNames(commonStyles.flx, commonStyles.mb28)}> */}
            {/* <Checkbox
              size="small"
              style={{
                color: "#ff7631",
                marginLeft: -10,
              }}
            /> */}

            {/* <div>
              <p
                className={classNames(commonStyles.regular, commonStyles.fs10)}
              >
                Please contact via phone or email to assist my inquery
              </p>
            </div> */}
          {/* </div> */}


        </div>
        <div style={{ width: "210px" }}>
          <PrimaryButton
            children={"Submit"}
            type="submit"
            colorType={"Travel"}
          />
        </div>
      </form>
      <VerifyModal
        showModal={showVerifyModal}
        handleSubmit={handleSubmitCode}
        code={code}
        codeError={codeError}
        setCodeError={setCodeError}
        setCode={setCode}
        minutes={minutes}
        seconds={seconds}
        handleSendCodeToEmail={SendCodeToEmail}
        successMessage={successMessage}
        hanldeCloseModal={handleCloseModal}
      />
      <SuccessModal
        showModal={showSuccessModal}
        successMessage={"Signup Completed Successfully!"}
        hanldeCloseModal={handleCloseSuccessModal}
      />
    </div>
  );
};

export default TravelAgencyVerificatio;
