import React, { FC } from "react";
import commonstyle from "shared/utils/common.module.css";
import { GoDotFill } from "react-icons/go";
import classNames from "classnames";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import style from "./commonChart.module.css";
import { common } from "@mui/material/colors";

// Define types for data and tooltip
interface DataItem {
  name: string;
  value1: number;
  value2: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

interface CustomLineChartDonationProps {
  data: DataItem[];
}

const CommonDoctorsChart: FC<CustomLineChartDonationProps> = ({ data }) => {
  const CustomTooltip: FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      const date = label;
      const value1 = payload[0].payload.value1;
      const value2 = payload[0].payload.value2;

      return (
        <div style={{ padding: "20px" }} className={style.customTooltip}>
          <div>
            <p className={style.value}>{`Current Week : ${value1}`}</p>
            <p className={style.value}>{`Previous Week : ${value2}`}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  // Dynamically calculate Y-axis domain based on data values
  const maxYValue = Math.max(
    ...data.map((item) => Math.max(item.value1, item.value2))
  );

  const yDomain = [0, maxYValue]; // Add some buffer for better visualization
  const xDomain = [0, maxYValue + 2];
  return (
    <div>
      <div className={classNames(style.flx, style.main)}>
        <p className={style.Appointments}>Total Appointments</p>
        <p className={style.Patients}>Patients</p>
        <p className={style.Patients}>|</p>

        <div className={commonstyle.flx}>
          <GoDotFill className={style.iconss} />
          <p className={style.Current}>Current Week</p>
        </div>
        <div className={commonstyle.flx}>
          <GoDotFill className={style.iconss2} />
          <p className={style.Previous}>Previous Week</p>
        </div>
      </div>
      <ResponsiveContainer width="97%" height={230}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="value1Gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="25%" stopColor="#00276d" stopOpacity={0.8} />
              <stop offset="75%" stopColor="#00276d" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="value2Gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#0b7328" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#0b7328" stopOpacity={0} />
            </linearGradient>
          </defs>

          <CartesianGrid stroke="3 3" vertical={false} />
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <YAxis domain={yDomain} axisLine={false} tickLine={false} />
          <Tooltip content={<CustomTooltip />} />

          {/* First Line */}
          <Area
            strokeWidth={4}
            type="monotone"
            dataKey="value1"
            stroke="#00276d"
            fill="url(#value1Gradient)"
            activeDot={{ r: 8, stroke: "#A7AEFF", fill: "#00276d" }}
            dot={{ r: 5, stroke: "#A7AEFF", fill: "#00276d" }}
          />

          {/* Second Line */}
          <Area
            strokeWidth={4}
            type="monotone"
            dataKey="value2"
            stroke="#0b7328"
            fill="url(#value2Gradient)"
            activeDot={{ r: 8, stroke: "#62ca80", fill: "#0b7328" }}
            dot={{ r: 5, stroke: "#62ca80", fill: "#0b7328" }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CommonDoctorsChart;
