import classNames from "classnames";
import React, { useState } from "react";
import Style from "./Amenities.module.css";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import { IoClose } from "react-icons/io5";
import { CustomInput, CustomModal, PrimaryButton } from "shared/components";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setHotelAddAppartment } from "shared/redux";
interface Props {
  handleClickNext: any;
}
export default function Amenities(props: Partial<Props>) {
  const { handleClickNext } = props;
  const dispatch = useDispatch();
  const [showAddModal, setShowAddModal] = useState(false);
  const { hotelAddAppartment } = useSelector((state: any) => state.root.hotel);
  console.log("Aminities  issss", hotelAddAppartment);
  const [error, setError] = React.useState("");
  const [markAmenities, setMarkAmenities] = useState<string[]>([]);

  const handleclose = () => {
    setShowAddModal(false);
  };
  const handleCheckboxChange = (value: string) => {
    if (!markAmenities.includes(value)) {
      setMarkAmenities([...markAmenities, value]);
    } else {
      setMarkAmenities(markAmenities.filter((item: any) => item !== value));
    }
  };
  const HandleClickNextScreen = () => {
    if (markAmenities.length === 0) {
      setError("Please select");
    } else {
      console.log("DONE");
      dispatch(
        setHotelAddAppartment({
          ...hotelAddAppartment,
          amenities: markAmenities,
        })
      );
      handleClickNext();
    }
  };
  return (
    <div className={commonstyles.col6}>
      <div className={classNames(commonstyles.flx, Style.mt16)}>
        <div className={commonstyles.col8}>
          <CustomInput
            placeholder={
              markAmenities === null ? "Tell Us About Your Amenities" : ""
            }
            value={markAmenities !== null ? markAmenities.join(", ") : ""}
          />
          {markAmenities.length == 0 && error && (
            <div className={classNames(commonStyles.error)}>*{error}</div>
          )}
        </div>
        <p
          className={Style.texts}
          onClick={() => setShowAddModal(!showAddModal)}
        >
          Show all Amenities{" "}
        </p>
        <CustomModal showModal={showAddModal}>
          <div style={{ width: "400px", color: "#00276d" }}>
            <div className={commonstyles.flx}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Amenities
              </p>
              <IoClose onClick={handleclose} className={Style.close} />
            </div>
            <div className={classNames(commonstyles.flx, Style.mt32)}>
              <div className={classNames(commonstyles.flx, commonstyles.col6)}>
                <Checkbox
                  value="Air Conditioning"
                  onChange={() => handleCheckboxChange("Air Conditioning")}
                  className={Style.checkbox}
                />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Air conditioning
                </p>
              </div>
              <div className={classNames(commonstyles.flx, commonstyles.col6)}>
                <Checkbox
                  value="Bath Tub"
                  onChange={() => handleCheckboxChange("Bath Tub")}
                  className={Style.checkbox}
                />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Bath Tub
                </p>
              </div>
            </div>
            <div className={classNames(commonstyles.flx)}>
              <div className={classNames(commonstyles.flx, commonstyles.col6)}>
                <Checkbox
                  value="Spa Tub"
                  onChange={() => handleCheckboxChange("Spa Tub")}
                  className={Style.checkbox}
                />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Spa-tub
                </p>
              </div>
              <div className={classNames(commonstyles.flx, commonstyles.col6)}>
                <Checkbox
                  value="Flat Screen TV"
                  onChange={() => handleCheckboxChange("Flat Screen TV")}
                  className={Style.checkbox}
                />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Flat-Screen TV
                </p>
              </div>
            </div>
            <div className={classNames(commonstyles.flx)}>
              <div className={classNames(commonstyles.flx, commonstyles.col6)}>
                <Checkbox
                  value="Electric Kettle"
                  onChange={() => handleCheckboxChange("Electric Kettle")}
                  className={Style.checkbox}
                />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Electric Kattle
                </p>
              </div>
              <div className={classNames(commonstyles.flx, commonstyles.col6)}>
                <Checkbox
                  value="Balcony"
                  onChange={() => handleCheckboxChange("Balcony")}
                  className={Style.checkbox}
                />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Balcony
                </p>
              </div>
            </div>
            <div className={classNames(commonstyles.flx)}>
              <div className={classNames(commonstyles.flx, commonstyles.col6)}>
                <Checkbox
                  value="Terrace"
                  onChange={() => handleCheckboxChange("Terrace")}
                  className={Style.checkbox}
                />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Terrace
                </p>
              </div>
              <div className={classNames(commonstyles.flx, commonstyles.col6)}>
                <Checkbox
                  value="Microwave"
                  onChange={() => handleCheckboxChange("Microwave")}
                  className={Style.checkbox}
                />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Microwave
                </p>
              </div>
            </div>
            <div className={classNames(commonstyles.flx)}>
              <div className={classNames(commonstyles.flx, commonstyles.col6)}>
                <Checkbox
                  value="Refrigerator"
                  onChange={() => handleCheckboxChange("Refrigerator")}
                  className={Style.checkbox}
                />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Refrigerator
                </p>
              </div>
            </div>
            <div style={{ marginTop: "32px" }}>
              <PrimaryButton
                children={"save"}
                colorType={"green"}
                onClick={() => setShowAddModal(!showAddModal)}
              />
            </div>
          </div>
        </CustomModal>
      </div>
      <div style={{ marginTop: "32px", width: "204px", marginBottom: "16px" }}>
        <PrimaryButton
          children={"Next"}
          colorType={"blue"}
          onClick={HandleClickNextScreen}
        />
      </div>
    </div>
  );
}
