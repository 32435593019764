import React, { useState, useEffect } from "react";
import classNames from "classnames";
import MainHomeServices from "../../../mainHomeServices.module.css";
import labMainStyles from "../../laboratoryMain.module.css";
import {
  paraSendCodeToEmail,
  paraVerifyEmail,
  paraCompleteSignup,
} from "shared/services/Paramedic";
import { SuccessModal, VerifyModal } from "shared/components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setIsParamedicEmailVerified } from "shared/redux";
import { paramedicStaffVerifySchema } from "shared/utils";
import commonStyles from "shared/utils/common.module.css";
import Check from "assets/check.png";
import { useNavigate } from "react-router-dom";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { CustomInput, PrimaryButton } from "shared/components";
import CustomSelect from "shared/components/CustomSelect";
import PasswordInput from "shared/components/PasswordInput";
const countryCodes = [
  "+92",
  "+1", // United States
  "+1", // Canada
  "+44", // United Kingdom
  "+49", // Germany
  "+33", // France
  "+39", // Italy
  "+61", // Australia
  "+55", // Brazil
  "+91", // India
  "+81", // Japan
  "+86", // China
  "+82", // South Korea
  "+52", // Mexico
  "+7", // Russia
  "+27", // South Africa
  "+234", // Nigeria
  "+54", // Argentina
  "+34", // Spain
  "+90", // Turkey
  "+966", // Saudi Arabia
  // ... add more country codes as needed
];
interface Props {
  handleClickNext: any;
}

const ParamedicStaffVerification = (props: Partial<Props>) => {
  const { isParamedicEmailVerified, paramedicUser_id } = useSelector(
    (state: any) => state.root.paramedic
  );
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [verifyError, setVerifyError] = React.useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const { handleClickNext } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      paramedicStaffPhoneNo: "",
      paramedicStaffEmail: "",
      paramedicStaffPassword: "",
      paramedicStaffConfirmPassword: "",
    },
    validationSchema: Yup.object(paramedicStaffVerifySchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState("");
  const [repassword, setRePassword] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const inputType = passwordVisible ? "text" : "password";
  const inputPassword = showPassword ? "text" : "password";

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  const handleSendCodeToEmail = () => {
    setVerifyLoading(true);
    let params = {
      email: formik.values.paramedicStaffEmail,
    };

    paraSendCodeToEmail(params)
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          setMinutes(1);
          setSeconds(59);
          setShowVerifyModal(true);
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
        setVerifyError(err.response.data.message);
      })
      .finally(() => {
        setVerifyLoading(false);
      });
  };

  const handleSubmitCode = () => {
    let params = {
      email: formik.values.paramedicStaffEmail,
      code: code,
    };

    if (code.length == 6) {
      paraVerifyEmail(params)
        .then((res: any) => {
          if (res.data.status) {
            setSuccessMessage(res.data.message);
            dispatch(setIsParamedicEmailVerified(true));
            setVerifyError("");
          }
        })
        .catch((err: any) => {
          setCodeError(err.response.data.message);
        })
        .finally(() => {
          setVerifyLoading(false);
        });
    }
  };

  const handleSubmit = async () => {
    let values = formik.values;
    let params = {
      phoneNumber: values.paramedicStaffPhoneNo,
      email: values.paramedicStaffEmail,
      password: values.paramedicStaffPassword,
      confirmPassword: values.paramedicStaffConfirmPassword,
    };

    if (isParamedicEmailVerified) {
      paraCompleteSignup(params, paramedicUser_id)
        .then((res: any) => {
          console.log("pas....", res);
          if (res.status == "200" && res.statusText == "OK") {
            setShowSuccessModal(true);
          }
        })
        .catch((err: any) => {
          console.log("send error.nnnnn..", err?.response?.data);
        })
        .finally(() => {
          setVerifyLoading(false);
          dispatch(setIsParamedicEmailVerified(false));
        });
    } else {
      setVerifyError("Please verify email and phone number first.");
    }
  };
  const handleCloseModal = () => {
    setShowVerifyModal(false);
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/");
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div
          className={classNames(
            commonStyles.col8,
            commonStyles.colsm12,
            commonStyles.mt56
          )}
        >
          <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
            <div className={classNames(commonStyles.col2)}>
              <div>
                <CustomSelect
                  options={countryCodes}
                  placeholder="+92"
                  onSelect={handleSelect}
                />
              </div>
            </div>
            <div className={classNames(commonStyles.col10)}>
              <CustomInput
                placeholder="Phone Number"
                id="paramedicStaffPhoneNo"
                name="paramedicStaffPhoneNo"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.paramedicStaffPhoneNo}
              />
              {formik.touched.paramedicStaffPhoneNo &&
              formik.errors.paramedicStaffPhoneNo ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.paramedicStaffPhoneNo}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
            <div
              className={classNames(
                commonStyles.col12,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <CustomInput
                placeholder="Email Address"
                id="paramedicStaffEmail"
                name="paramedicStaffEmail"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.paramedicStaffEmail}
              />
              {formik.touched.paramedicStaffEmail &&
              formik.errors.paramedicStaffEmail ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.paramedicStaffEmail}
                </div>
              ) : null}
            </div>
            {isParamedicEmailVerified ? (
              <button
                type="button"
                disabled={true}
                className={commonStyles.Verified}
                // onClick={handleSendCodeToEmail}
              >
                Verified
              </button>
            ) : (
              <button
                type="button"
                disabled={
                  !verifyLoading &&
                  formik.touched.paramedicStaffEmail &&
                  !formik.errors.paramedicStaffEmail
                    ? false
                    : true
                }
                className={
                  formik.touched.paramedicStaffEmail &&
                  !formik.errors.paramedicStaffEmail
                    ? commonStyles.VerifyActive
                    : commonStyles.Verify
                }
                onClick={handleSendCodeToEmail}
              >
                {verifyLoading ? "loading..." : "Verify"}
              </button>
            )}
          </div>
          <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
            <div
              className={classNames(
                commonStyles.col12,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <PasswordInput
                type={inputType}
                placeholder="Password"
                id="paramedicStaffPassword"
                name="paramedicStaffPassword"
                onChange={formik.handleChange}
                value={formik.values.paramedicStaffPassword}
              />
              {formik.touched.paramedicStaffPassword &&
              formik.errors.paramedicStaffPassword ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.paramedicStaffPassword}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
            <div
              className={classNames(
                commonStyles.col12,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <PasswordInput
                type={inputPassword}
                placeholder="Re-Password"
                id="paramedicStaffConfirmPassword"
                name="paramedicStaffConfirmPassword"
                onChange={formik.handleChange}
                value={formik.values.paramedicStaffConfirmPassword}
              />
              {formik.touched.paramedicStaffConfirmPassword &&
              formik.errors.paramedicStaffConfirmPassword ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.paramedicStaffConfirmPassword}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.col12)}>
          <div className={classNames(commonStyles.flx)}>
            <Checkbox
              size="small"
              style={{
                color: "#ff7631",
                marginLeft: -10,
              }}
            />

            <div>
              <p
                className={classNames(commonStyles.regular, commonStyles.fs10)}
              >
                I agree meditour{" "}
                <a
                  className={classNames(commonStyles.colorOrange)}
                  href="/terms-conditions"
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  className={classNames(commonStyles.colorOrange)}
                  href="/privacy-policy"
                >
                  Privacy policy
                </a>
              </p>
            </div>
          </div>
          {/* <div className={classNames(commonStyles.flx, commonStyles.mb28)}>
            <Checkbox
              size="small"
              style={{
                color: "#ff7631",
                marginLeft: -10,
              }}
            /> */}

            {/* <div>
              <p
                className={classNames(commonStyles.regular, commonStyles.fs10)}
              >
                Please contact via phone or email to assist my inquery
              </p>
            </div> */}
          {/* </div> */}
        </div>
        <div className={MainHomeServices.buttonWidth}>
          {" "}
          <PrimaryButton
            children={"Next Step"}
            type="submit"
            colorType={"ParamedicStaff"}
          />
        </div>
      </form>
      <VerifyModal
        showModal={showVerifyModal}
        handleSubmit={handleSubmitCode}
        code={code}
        codeError={codeError}
        setCodeError={setCodeError}
        setCode={setCode}
        minutes={minutes}
        seconds={seconds}
        handleSendCodeToEmail={handleSendCodeToEmail}
        successMessage={successMessage}
        hanldeCloseModal={handleCloseModal}
      />
      <SuccessModal
        showModal={showSuccessModal}
        successMessage={"Signup Completed Successfully!"}
        hanldeCloseModal={handleCloseSuccessModal}
      />
    </div>
  );
};

export default ParamedicStaffVerification;
