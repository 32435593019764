import React, { useState, useEffect } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "./PatientHistory.module.css";
import { FaRegEdit } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { FaRegFileAlt } from "react-icons/fa";
import Avatar from "@mui/material/Avatar";
import download from "assets/images/download.png";
import avatar from "assets/images/Avatar.png";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { setYear } from "date-fns";
import { parsPatientHistory } from "shared/services/Paramedic";
import PatientDetailTable from "shared/components/ParamedicStaffTables/ParapatientDetailTable";
import { string } from "yup";
import { RxFileText } from "react-icons/rx";
import { LoadingModal } from "shared/components";

interface ParaPatientHistoryTable {
  name: string;
  userName: string;
  email: string;
  mrNo: string;
  gender: string;
  dateOfBirth: string;
  phone: string;
  _id: string;
  userImage: string;
}
function ParamedicPatientDetail() {
  const [patientDetail, setPatientDetail] = useState([]);
  const [medicine, setMedicine] = useState([]);
  const navigate = useNavigate();
  // const handleGoToPrescription = () => {
  //   console.log("hitt.......");
  //   navigate("/latestPrescription");
  // };
  console.log("medicine in MAin", medicine);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [patienthistory, setPatientHistory] =
    useState<ParaPatientHistoryTable | null>(null);

  const handleGoBack = () => {
    navigate("/patientPrescription");
  };

  const DocPatDetails = () => {
    setLoading(true);
    if (id === undefined) {
      console.error("ID is undefined");
      setLoading(false);
      return;
    }
    if (id) {
      console.log("ID value:", id);

      parsPatientHistory(id)
        .then((res: any) => {
          console.log("API Response:", res);
          setPatientHistory(res?.data.patient);
          setPatientDetail(res?.data.Appointments);
          const medicines = res?.data.Appointments.map(
            (appointment: any) => appointment.ePrescription?.medicines
          );
          setMedicine(medicines);
        })
        .catch((err: any) => {
          console.log("API Error:", err?.response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
    }
  };

  useEffect(() => {
    DocPatDetails();
  }, []);
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      {loading ? (
        <LoadingModal showModal={loading} />
      ) : (
        <div className={commonstyles.mr87}>
          <div className={style.outerContainer}>
            <div
              style={{ width: "100%" }}
              className={classNames(style.flx, style.mt24)}
            >
              <div className={classNames(commonstyles.col8)}>
                <div className={classNames(style.box, commonstyles.flx)}>
                  <div className={style.user}>
                    <Avatar
                      src={patienthistory?.userImage}
                      sx={{ width: 82, height: 82 }}
                      style={{ margin: "0 auto" }}
                    />
                    <div style={{ marginTop: "16px" }}>
                      <p
                        className={classNames(
                          commonstyles.fs24,
                          commonstyles.semiBold,
                          style.textcenter
                        )}
                      >
                        {patienthistory?.name}
                      </p>
                      <p
                        className={classNames(
                          commonstyles.fs1,
                          style.textcenter,
                          style.mt8
                        )}
                      >
                        {patienthistory?.email}
                      </p>
                    </div>
                  </div>
                  <div className={style.user2}>
                    <p
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.semiBold
                      )}
                    >
                      Appointment
                    </p>
                    <div className={classNames(commonstyles.flx, style.mt16)}>
                      <div style={{ width: "33%" }}>
                        <p
                          className={classNames(
                            commonstyles.fs14,
                            commonstyles.semiBold
                          )}
                        >
                          MR No.
                        </p>
                        <p className={classNames(commonstyles.fs12)}>
                          {patienthistory?.mrNo}
                        </p>
                      </div>
                    </div>

                    <div className={classNames(commonstyles.flx, style.mt24)}>
                      <div style={{ width: "33%" }}>
                        <p
                          className={classNames(
                            commonstyles.fs14,
                            commonstyles.semiBold
                          )}
                        >
                          Gender
                        </p>
                        <p className={classNames(commonstyles.fs12)}>
                          {patienthistory?.gender}
                        </p>
                      </div>
                      <div style={{ width: "33%" }}>
                        <p
                          className={classNames(
                            commonstyles.fs14,
                            commonstyles.semiBold
                          )}
                        >
                          Age
                        </p>
                        <p className={classNames(commonstyles.fs12)}>
                          {patienthistory?.dateOfBirth}
                        </p>
                      </div>{" "}
                      <div style={{ width: "33%" }}>
                        <p
                          className={classNames(
                            commonstyles.fs14,
                            commonstyles.semiBold
                          )}
                        >
                          Cell No.
                        </p>
                        <p className={classNames(commonstyles.fs12)}>
                          {patienthistory?.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={classNames(commonstyles.col4)}>
                <div className={style.card2}>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold,
                      style.mb8
                    )}
                  >
                    Lab Test
                  </p>
                  <div className={style.alltests}>
                    <div
                      style={{ marginTop: "8px" }}
                      className={commonstyles.flx}
                    >
                      <RxFileText className={style.file} />
                      <p>Check up Result.pdf 123kb</p>
                      <img src={download} className={style.download} />
                    </div>
                    <div
                      style={{ marginTop: "8px" }}
                      className={commonstyles.flx}
                    >
                      <RxFileText className={style.file} />
                      <p>Check up Result.pdf 123kb</p>
                      <img src={download} className={style.download} />
                    </div>
                    <div
                      style={{ marginTop: "8px" }}
                      className={commonstyles.flx}
                    >
                      <RxFileText className={style.file} />
                      <p>Check up Result.pdf 123kb</p>
                      <img src={download} className={style.download} />
                    </div>
                    <div
                      style={{ marginTop: "8px" }}
                      className={commonstyles.flx}
                    >
                      <RxFileText className={style.file} />
                      <p>Check up Result.pdf 123kb</p>
                      <img src={download} className={style.download} />
                    </div>
                    <div
                      style={{ marginTop: "8px" }}
                      className={commonstyles.flx}
                    >
                      <RxFileText className={style.file} />
                      <p>Check up Result.pdf 123kb</p>
                      <img src={download} className={style.download} />
                    </div>
                    <div
                      style={{ marginTop: "8px" }}
                      className={commonstyles.flx}
                    >
                      <RxFileText className={style.file} />
                      <p>Check up Result.pdf 123kb</p>
                      <img src={download} className={style.download} />
                    </div>
                    <div
                      style={{ marginTop: "8px" }}
                      className={commonstyles.flx}
                    >
                      <RxFileText className={style.file} />
                      <p>Check up Result.pdf 123kb</p>
                      <img src={download} className={style.download} />
                    </div>
                    <div
                      style={{ marginTop: "8px" }}
                      className={commonstyles.flx}
                    >
                      <RxFileText className={style.file} />
                      <p>Check up Result.pdf 123kb</p>
                      <img src={download} className={style.download} />
                    </div>
                    <div
                      style={{ marginTop: "8px" }}
                      className={commonstyles.flx}
                    >
                      <RxFileText className={style.file} />
                      <p>Check up Result.pdf 123kb</p>
                      <img src={download} className={style.download} />
                    </div>
                    <div
                      style={{ marginTop: "8px" }}
                      className={commonstyles.flx}
                    >
                      <RxFileText className={style.file} />
                      <p>Check up Result.pdf 123kb</p>
                      <img src={download} className={style.download} />
                    </div>
                    <div
                      style={{ marginTop: "8px" }}
                      className={commonstyles.flx}
                    >
                      <RxFileText className={style.file} />
                      <p>Check up Result.pdf 123kb</p>
                      <img src={download} className={style.download} />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <p
              className={classNames(
                style.mt24,
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              All Appointments
            </p>
            <div className={style.mt16}>
              <PatientDetailTable
                patientDetail={patientDetail}
                medicine={medicine}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ParamedicPatientDetail;
