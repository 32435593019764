import DoctorServices from "pages/Services/DoctarServices";
import React from "react";
import { Routes, Route } from "react-router-dom";

const DoctorRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DoctorServices />}/>
    </Routes>
  );
};

export default DoctorRoutes;
