import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const pharmaceutical_Signup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PHARMACEUTICAL_REGISTER, params);
};

export const pharmaceutical_SendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PHARMACEUTICAL_SEND_CODE_EMAIL, params);
};

export const pharmaceutical_ConfirmEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PHARMACEUTICAL_CONFIRM_CODE, params);
};

export const pharmaceutical_CompleteSignup = (params: any, id: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.PHARMACEUTICAL_COMPLETE_SIGNUP}?id=${id}`,
    params
  );
};

export const pharmaceutical_Login = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PHARMACEUTICAL_LOGIN, params);
};

export const pharmaceutical_Logout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.PHARMACEUTICAL_LOGOUT);
};

export const pharmaceutical_getAllMedicines = (pageno: number) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.PHARMACEUTICAL_GET_ALL_MEDS}?page=${pageno}`
  );
};

export const pharmaceutical_AddMed = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PHARMACEUTICAL_ADD_MEDS, params);
};

export const pharmaceutical_DeleteMed = (id: any) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.PHARMACEUTICAL_DELETE_MED}?medId=${id}`
  );
};
